import PropTypes from "prop-types";
import Lomake from "components/02-organisms/Lomake";
import { useChangeObjectsByAnchorWithoutUnderRemoval } from "stores/muutokset";
import education from "i18n/definitions/education";
import { useIntl } from "react-intl";

const constants = {
  formLocations: {
    oppilaitokset: ["taiteenPerusopetus", "oppilaitokset"]
  }
};

const Oppilaitokset = ({
  isPreviewModeOn,
  maaraykset,
  mode = constants.mode
}) => {
  const { formatMessage } = useIntl();
  const sectionId = "oppilaitokset";

  const [changeObjects] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: sectionId
  });

  return (
    <Lomake
      anchor={sectionId}
      data={{
        maaraykset
      }}
      changeObjects={changeObjects}
      formTitle={formatMessage(education.oppilaitokset)}
      isInExpandableRow={false}
      isPreviewModeOn={isPreviewModeOn}
      mode={mode}
      noPadding={true}
      path={constants.formLocations.oppilaitokset}></Lomake>
  );
};

Oppilaitokset.propTypes = {
  isPreviewModeOn: PropTypes.bool,
  maaraykset: PropTypes.array,
  mode: PropTypes.string
};

export default Oppilaitokset;
