import { LocalizedRouter, LocalizedSwitch } from "modules/i18n/index";
import { getRouteUrl, ROUTE_PATH } from "./helpers/routes";
import { Organisation, User } from "koodistodatanTyypit";
import { BrowserRouter, Route } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { ReactElement, Suspense } from "react";
import { defaults } from "react-sweet-state";
import { AppLayout } from "./AppLayout.jsx";
import { AppRoute } from "routes";

import JarjestamisJaYllapitamisluvat from "scenes/JarjestamisJaYllapitamisluvat";
import Saavutettavuusseloste from "scenes/Saavutettavuusseloste";
import CasAuthenticated from "scenes/CasAuthenticated";
import LogOutWithLocale from "scenes/LogOutWithLocale";
import AuthWithLocale from "scenes/AuthWithLocale";
import Yhteydenotto from "scenes/Yhteydenotto";
import Sivukartta from "scenes/Sivukartta";
import Tilastot from "scenes/Tilastot";
import Logout from "scenes/LogOut";
import Home from "scenes/Home";

import "@reach/skip-nav/styles.css";

defaults.devtools = true;

const Oiva = ({
  messages,
  organisation,
  user
}: {
  messages: Record<string, Record<string, string>>;
  organisation: Organisation;
  user: User;
}): ReactElement => {
  const routes = {
    jarjestamisJaYllapitamisluvat: getRouteUrl(
      ROUTE_PATH.JARJESTAMIS_JA_YLLAPITAJAMISLUVAT
    ),
    saavutettavuusseloste: getRouteUrl(ROUTE_PATH.SAAVUTETTAVUUSSELOSTE),
    sivukartta: getRouteUrl(ROUTE_PATH.SIVUKARTTA),
    tilastot: getRouteUrl(ROUTE_PATH.TILASTOT),
    yhteydenotto: getRouteUrl(ROUTE_PATH.YHTEYDENOTTO)
  };

  return (
    <LocalizedRouter localesByLang={messages} RouterComponent={BrowserRouter}>
      <AppLayout
        localesByLang={messages}
        organisation={organisation}
        user={user}>
        <LocalizedSwitch>
          <Route exact path={AppRoute.CasAuth} component={AuthWithLocale} />
          <Route exact path={AppRoute.CasLogOut} component={LogOutWithLocale} />
          <Route exact path={AppRoute.LogOut} component={Logout} />
          <Route
            exact
            path={AppRoute.CasReady}
            render={() => (
              <CasAuthenticated organisation={organisation} user={user} />
            )}
          />
          <Route exact path={AppRoute.Home}>
            <Suspense fallback={<CircularProgress />}>
              <Home />
            </Suspense>
          </Route>
          {routes.jarjestamisJaYllapitamisluvat && (
            <Route path={routes.jarjestamisJaYllapitamisluvat}>
              <Suspense fallback={<CircularProgress />}>
                <JarjestamisJaYllapitamisluvat />
              </Suspense>
            </Route>
          )}
          {routes.tilastot && (
            <Route exact path={routes.tilastot}>
              <Tilastot />
            </Route>
          )}
          {routes.yhteydenotto && (
            <Route path={routes.yhteydenotto}>
              <Yhteydenotto />
            </Route>
          )}
          {routes.saavutettavuusseloste && (
            <Route path={routes.saavutettavuusseloste}>
              <Saavutettavuusseloste />
            </Route>
          )}
          {routes.sivukartta && (
            <Route path={routes.sivukartta}>
              <Sivukartta />
            </Route>
          )}
          <Route path="*">Oivan etusivu</Route>
        </LocalizedSwitch>
      </AppLayout>
    </LocalizedRouter>
  );
};

export default Oiva;
