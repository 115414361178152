import { defineMessages } from "react-intl";

export default defineMessages({
  genetiivi: {
    id: "taiteenPerusopetus.genetiivi",
    defaultMessage: "Taiteen perusopetuksen"
  },
  kebabCase: {
    id: "taiteenPerusopetus.kebabCase",
    defaultMessage: "taiteen-perusopetus"
  },
  kuvausteksti: {
    id: "taiteenPerusopetus.kuvausteksti",
    defaultMessage:
      "Taiteen perusopetus... (Käännösavain: taiteenPerusopetus.kuvausteksti)"
  },
  lyhytKuvaus: {
    id: "taiteenPerusopetus.lyhytKuvaus",
    defaultMessage:
      "Taiteen perusopetuksen järjestäminen edellyttää opetus- ja kulttuuriministeriön myöntämää koulutuksen järjestämislupaa. Järjestämislupa voidaan myöntää hakemuksesta kuntayhtymälle, rekisteröidylle yhteisölle tai säätiölle. Tästä pääset tarkastelemaan taiteen perusopetuksen järjestäjiä ja heidän järjestämislupiaan"
  },
  paasivunOtsikko: {
    id: "taiteenPerusopetus.paasivunOtsikko",
    defaultMessage: "Taiteen perusopetuksen oppilaitosten ylläpitäjät"
  },
  esittelyteksti: {
    id: "taiteenPerusopetus.esittelyteksti",
    defaultMessage:
      "Tälle sivulle on koottu oppilaitoksen ylläpitämisluvan perustiedot. Ylläpitämislupa löytyy alkuperäisenä kokonaisuudessaan Päätökset-välilehdeltä."
  },
  voimassaOlevatYllapitamisluvat: {
    id: "taiteenPerusopetus.voimassaOlevatYllapitamisluvat",
    defaultMessage: "Voimassa olevat ylläpitämisluvat {count} kpl"
  },
  voimassaOlevatYllapitamisluvatSuluissa: {
    id: "taiteenPerusopetus.voimassaOlevatYllapitamisluvatSuluissa",
    defaultMessage: "Voimassa olevat ylläpitämisluvat ({amount} kpl)"
  }
});
