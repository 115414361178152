import { CellFn } from "graphHandling/graphTypes";
import { CellId } from "processes/CellId";

const currentCellId = CellId.INITIALIZE_LISAA_PAINIKE;

export const update_lisaaPainike: CellFn = storeFunctions => {
  storeFunctions.addModification(currentCellId, {
    isHidden: false
  });

  return true;
};
