/**
 * Ankkurit ilmaisevat tiedon sijainnin lomakkeella.
 * @module
 */

import {
  compose,
  join,
  lensIndex,
  remove,
  split,
  tail,
  take,
  update,
  view
} from "ramda";

/**
 * Ankkurin alkuosaa kuvaava säännöllinen lauseke:
 * `/^([a-zA-Z0-9]+[_]{1})*[a-zA-Z0-9]+$/`
 */
export const anchorBaseRegexp =
  /^[a-zA-Z0-9]{1}[a-zA-Z0-9-]*((_[a-zA-Z0-9]{1}[a-zA-Z0-9-]*)*)$/;

/**
 * Ankkuria kuvaava säännöllinen lauseke:
 * `/^([a-zA-Z0-9]+[_]{1})*[a-zA-Z0-9]+\.([a-zA-Z0-9]+[.]{1})*[a-zA-Z0-9]+$/`
 */
export const anchorRegexp =
  /^[a-zA-Z0-9]{1}[a-zA-Z0-9-]*((_[a-zA-Z0-9]{1}[a-zA-Z0-9-]*)*)((\.[a-zA-Z0-9]{1}[a-zA-Z0-9-]*){1,})$/;

export type Anchor = string;
export type AnchorBase = string;

/**
 * Erotinmerkki, jota käytetään ankkurin alkuosassa.
 */
export const delimiter1 = "_";

/**
 * Erotinmerkki, jota käytetään ankkurin loppuosassa.
 */
export const delimiter2 = ".";

/**
 * Palauttaa ankkurin perusosan.
 * @param Ankkurin alkuosa
 */
export function getAnchorBase(anchor: Anchor): string {
  const anchorBase: AnchorBase = anchor.split(delimiter2)[0];
  return anchorBase;
}

/**
 * Tarkistaa, onko parametrina annettu merkkijono muodoltaan ankkurin alkuosa.
 * @param Ankkurin alkuosa
 */
export function isAnchorBase(string: string): boolean {
  return anchorBaseRegexp.test(string);
}

/**
 * Tarkistaa, onko parametrina annettu merkkijono muodoltaan ankkuri.
 * @param anchor
 */
export function isAnchor(string: string): boolean {
  return anchorRegexp.test(string);
}

/**
 * Palauttaa ankkurin perusosan taulukkomuodossa.
 * @param anchorBaseOrAnchor
 */
export function getAnchorBaseParts(anchorBaseOrAnchor: string): Array<string> {
  const anchorBase = getAnchorBase(anchorBaseOrAnchor);
  if (!isAnchorBase(anchorBase)) {
    return [];
  }
  return anchorBase.split(delimiter1);
}

/**
 * Palauttaa ankkurin loppuosan taulukkomuodossa.
 * @param anchor
 */
export function getAnchorTailParts(anchor: string): Array<string> {
  if (!isAnchor(anchor)) {
    return [];
  }
  return tail(anchor.split(delimiter2));
}

/**
 * Palauttaa ankkurin loppuosan.
 * @param anchor
 */
export function getAnchorTail(anchor: string): string {
  const anchorTailParts = getAnchorTailParts(anchor);
  return join(delimiter2, anchorTailParts);
}

/**
 * Poistaa osan ankkurista.
 * @param anchor Ankkuri.
 * @param index Numero, joka kertoo, monesko osa ankkurista tulee poistaa.
 * @param separator Ankurissa käytössä oleva välimerkki.
 * @returns Ankkuri, josta osa on poistettu.
 */
export function removeAnchorPart(
  anchor: Anchor,
  index: number,
  separator: string = delimiter2
): Anchor {
  return compose(join(separator), remove(index, 1), split(separator))(anchor);
}

/**
 * Poistaa useita osioa ankkurista.
 * @param anchor Ankkuri.
 * @param amount Ankkurin loppupäästä poistettavien osien lukumäärä.
 * @param separator Ankurissa käytössä oleva välimerkki.
 * @returns Ankkuri, josta osat on poistettu.
 */
export function removeAnchorPartsFromTheEndOfTheAnchor(
  anchor: Anchor,
  amount: number,
  separator: string = delimiter2
): Anchor {
  const anchorParts = split(separator, anchor);
  return compose(
    join(separator),
    take(anchorParts.length - amount)
  )(anchorParts);
}

/**
 * Muodostaa ankkurin, jossa index-parametrin ilmaisema osa korvattu
 * parametrin replaceWith arvolla.
 * @param anchor Ankkuri.
 * @param index Korvattavan ankkurin osan indeksi ankkurissa. Indeksointi
 * lähtee nollasta.
 * @param replaceWith Merkkijono, jolla ankkurin osa korvataan.
 * @returns Ankkuri, jossa index-parametrin ilmaisema osa korvattu
 * parametrin replaceWith arvolla.
 */
export const replaceAnchorPartWith = (
  anchor: Anchor,
  index: number,
  replaceWith: string
): string => {
  if (!isAnchor(anchor)) {
    return "";
  } else {
    return compose(
      join(delimiter2),
      update(index, replaceWith),
      split(delimiter2)
    )(anchor);
  }
};

/**
 * Poistaa ankkurista viimeisen osan.
 * @param anchor Ankkuri.
 * @returns Ankkuri, josta viimeinen osa on poistettu.
 */
export const getAnchorInit = (anchor: Anchor): string =>
  removeAnchorPart(anchor, -1);

/**
 * Palauttaa osan ankkurista.
 * @param anchor Ankkuri.
 * @param index Numero, joka kertoo palautettavan osan sijainnin ankkurissa.
 * @param separator Ankkurissa käytettävä erotinmerkki.
 * @returns Ankkurin osa.
 */
export function getAnchorPart(
  anchor: Anchor,
  index: number,
  separator: string = delimiter2
): string | undefined {
  return compose(view(lensIndex(index)), split(separator))(anchor);
}

/**
 * Palauttaa ankkurin taulukkomuodossa.
 * @param anchor Ankkuri.
 * @returns Ankkurin osat taulukossa.
 */
export function getAnchorParts(anchor: Anchor): Array<string> {
  if (!isAnchor(anchor)) {
    return [];
  } else {
    return split(delimiter2, anchor);
  }
}
