import {
  append,
  assocPath,
  concat,
  filter,
  flatten,
  includes,
  isNil,
  map,
  path,
  prop,
  reject,
  uniq
} from "ramda";
import { getChildNodes } from "./getChildNodes";
import {
  ChangeObject,
  ChangeObjects,
  createChangeObject,
  deleteChangeObject,
  filterOutNonDeprecatedChangeObjects,
  getChangeObjByAnchor,
  modifyChangeObjectInArray
} from "utils/muutokset";
import { ComponentWithMetadata, Properties } from "utils/lomakkeet";

/**
 * Käsittelee yksittäisen checkboxin / radio buttonin ruksin poistamisen muokaten
 * muutosobjektien joukkoa.
 * @param componentWithMetadata Elementti, josta ruksia ollaan poistamassa.
 * @param changeObjects Taulukollinen muutosobjekteja.
 * @returns Taulukollinen muutosobjekteja.
 */
export function uncheckNode(
  componentWithMetadata: ComponentWithMetadata,
  changeObjects: ChangeObjects
): ChangeObject | null {
  // Etsitään komponenttia koskeva muutosobjekti
  const changeObj = getChangeObjByAnchor(
    componentWithMetadata.fullAnchor,
    changeObjects
  );

  if (changeObj) {
    // Jos muutosobjekti on olemassa, tarkistetaan tuleeko elementti olemaan sen myötä ruksattu (isChecked: true).
    if (changeObj.properties.isChecked !== false) {
      // Jos muutosobjekti asettaa elementin ruksatuksi, selvitetään seuraavaksi, onko elementti oletusarvoisesti
      // ruksattu.
      if (componentWithMetadata.properties.isChecked) {
        // Koska elementti on ruksattu oletusarvoisesti ja sitä koskeva muutosobjekti on olemassa,
        // muokataan muutosobjektia siten, että se asettaa elementin ruksaamattomaksi.
        const properties: Properties = { isChecked: false };
        if (componentWithMetadata.properties.isIndeterminate !== false) {
          // Koska elementin isIndeterminate-asetuksen arvo on jotain muuta kuin false,
          // lisätään false-arvo muutosobjektiin.
          properties.isIndeterminate = false;
        }
        return { ...changeObj, properties };
      } else {
        // Koska elementti ei ole oletuksena ruksattu, muutosobjektin poistaminen riittää.
        return assocPath(["properties", "isDeprecated"], true, changeObj);
      }
    } else {
      // Muutosobjekti asettaa elementin ruksaamattomaksi (isChecked=false).
      if (componentWithMetadata.properties.isChecked) {
        // Koska elementti on oletusarvoisesti ruksattu ei toimenpiteitä tarvita.
        return changeObj;
      } else {
        // Koska elementtiä ei ole oletusarvoisesti ruksattu, on muutosobjekti tarpeeton.
        return null;
      }
    }
  } else {
    // Jos muutosobjektia ei ole olemassa, tarkistetaan, onko checkbox ruksattu oletusarvoisesti.
    if (componentWithMetadata.properties.isChecked) {
      // Koska elementti on ruksattu oletusarvoisesti, on luotava muutosobjekti, joka
      // asettaa elementin ruksaamattomaksi.
      const properties: Properties = reject(isNil, {
        isChecked: false,
        metadata: path(["properties", "forChangeObject"], componentWithMetadata)
      });
      if (componentWithMetadata.properties.isIndeterminate !== false) {
        // Koska elementin isIndeterminate-asetuksen arvo on jotain muuta kuin false,
        // lisätään false-arvo muutosobjektiin.
        properties.isIndeterminate = false;
      }
      return createChangeObject(componentWithMetadata.fullAnchor, properties);
    } else {
      // Muutosobjektia ei ole olemassa, eikä elementtiä ole oletusarvoisesti ruksattu, joten
      // kaikki on kunnossa ilman lisäoperaatioita.
      return null;
    }
  }
}

/**
 * Käsittelee yksittäisen checkboxin / radio buttonin ruksin poistamisen muokaten
 * muutosobjektien joukkoa.
 * @param componentWithMetadata Elementti, josta ruksia ollaan poistamassa.
 * @param changeObjects Taulukollinen muutosobjekteja.
 * @returns Taulukollinen muutosobjekteja.
 */
export function uncheckNodeAndReturnArray(
  componentWithMetadata: ComponentWithMetadata,
  changeObjects: ChangeObjects
): ChangeObjects {
  // Etsitään komponenttia koskeva muutosobjekti
  const changeObj = getChangeObjByAnchor(
    componentWithMetadata.fullAnchor,
    changeObjects
  );

  if (changeObj) {
    // Jos muutosobjekti on olemassa, tarkistetaan tuleeko elementti olemaan sen myötä ruksattu (isChecked: true).
    if (changeObj.properties.isChecked !== false) {
      // Jos muutosobjekti asettaa elementin ruksatuksi, selvitetään seuraavaksi, onko elementti oletusarvoisesti
      // ruksattu.
      if (componentWithMetadata.properties.isChecked) {
        // Koska elementti on ruksattu oletusarvoisesti ja sitä koskeva muutosobjekti on olemassa,
        // muokataan muutosobjektia siten, että se asettaa elementin ruksaamattomaksi.
        const properties: Properties = { isChecked: false };
        if (componentWithMetadata.properties.isIndeterminate !== false) {
          // Koska elementin isIndeterminate-asetuksen arvo on jotain muuta kuin false,
          // lisätään false-arvo muutosobjektiin.
          properties.isIndeterminate = false;
        }
        return modifyChangeObjectInArray(
          changeObj.anchor,
          properties,
          changeObjects
        );
      } else {
        // Koska elementti ei ole oletuksena ruksattu, muutosobjektin poistaminen riittää.
        return deleteChangeObject(changeObj.anchor, changeObjects);
      }
    } else {
      // Muutosobjekti asettaa elementin ruksaamattomaksi (isChecked=false).
      if (componentWithMetadata.properties.isChecked) {
        // Koska elementti on oletusarvoisesti ruksattu ei toimenpiteitä tarvita.
        return changeObjects;
      } else {
        // Koska elementtiä ei ole oletusarvoisesti ruksattu, on muutosobjekti tarpeeton.
        return deleteChangeObject(changeObj.anchor, changeObjects);
      }
    }
  } else {
    // Jos muutosobjektia ei ole olemassa, tarkistetaan, onko checkbox ruksattu oletusarvoisesti.
    if (componentWithMetadata.properties.isChecked) {
      // Koska elementti on ruksattu oletusarvoisesti, on luotava muutosobjekti, joka
      // asettaa elementin ruksaamattomaksi.
      const properties: Properties = reject(isNil, {
        isChecked: false,
        metadata: path(["properties", "forChangeObject"], componentWithMetadata)
      });
      if (componentWithMetadata.properties.isIndeterminate !== false) {
        // Koska elementin isIndeterminate-asetuksen arvo on jotain muuta kuin false,
        // lisätään false-arvo muutosobjektiin.
        properties.isIndeterminate = false;
      }
      const changeObj = createChangeObject(
        componentWithMetadata.fullAnchor,
        properties
      );
      // Lisätään muutosobjekti muiden muutosobjektien joukkoon.
      return append(changeObj, changeObjects);
    } else {
      // Muutosobjektia ei ole olemassa, eikä elementtiä ole oletusarvoisesti ruksattu, joten
      // kaikki on kunnossa ilman lisäoperaatioita.
      return changeObjects;
    }
  }
}

/**
 * Poistaa ruksin elementistä (checkbox / radio button) ja sen jälkeläisistä / sisarelementeistä.
 * @param componentWithMetadata Metadatalla höystetty komponenttimerkkaus.
 * @param componentsWithMetadata Taulukollinen metadatalla höystettyjä komponenttimerkkauksia.
 * @param changeObjects Taulukollinen muutosobjekteja.
 * @returns Taulukollinen muutosobjekteja.
 */
export function deactivateNodeAndItsDescendants(
  componentWithMetadata: ComponentWithMetadata,
  componentsWithMetadata: Array<ComponentWithMetadata>,
  changeObjects: ChangeObjects = [],
  level = 0
): ChangeObjects {
  // Poistetaan ruksi elementistä.
  const changeObjOrNull = uncheckNode(componentWithMetadata, changeObjects);

  // Jos muutosobjekti syntyi tai sellaista muokattiin uncheckNode-funktion
  // toimesta, lisätään muutosobjekti tuoreiden muutosobjektien listalle.
  // const updatedFreshChangeObjects = changeObjOrNull
  //   ? append(changeObjOrNull, freshChangeObjects)
  //   : freshChangeObjects;

  const childNodes = componentWithMetadata.hasDescendants
    ? getChildNodes(componentWithMetadata, componentsWithMetadata, [
        "CheckboxWithLabel",
        "RadioButtonWithLabel"
      ])
    : [];

  let freshChangeObjects: ChangeObjects = [];

  // Asetetaan jokainen seuraavan tason checkbox- tai radio button -elementti
  // ruksaamattomaksi.
  if (childNodes.length) {
    freshChangeObjects = uniq(
      flatten(
        map(childNode => {
          return deactivateNodeAndItsDescendants(
            childNode,
            componentsWithMetadata,
            changeObjects,
            level + 1
          );
        }, childNodes)
      )
    );
  }

  if (changeObjOrNull) {
    freshChangeObjects = append(changeObjOrNull, freshChangeObjects);
  }

  if (level > 0) {
    return freshChangeObjects;
  } else {
    // Huomioidaan vielä lopuksi, etteivät olemassa olevat, muita elementtejä koskevat
    // muutosobjektit katoa. Eli yhdistetään tuoreet muutokset olemassa oleviin.
    // 1. Selvitetään uusien tai juuri muokattujen muutosobjektien ankkurit.
    const anchorsOfTheFreshChanges = map(prop("anchor"), freshChangeObjects);

    // 2. Muodostetaan taulukollinen muutosobjekteja siten, että
    // olemassa olevien muutosobjektien joukosta poistetaan ne, joiden
    // ankkuri on yksi tuoreiden muutosobjektien ankkureista. Toisin sanoen
    // poistetaan vanhentuneet muutosobjektit.
    const filteredChangeObjects = filter(changeObj => {
      return !includes(changeObj.anchor, anchorsOfTheFreshChanges);
    }, changeObjects);

    // Nyt kun tiedossa ovat sekä nykyiset ajantasalla olevat muutosobjektit
    // että tuoreet muutosobjektit, yhdistetään ne samaan taulukkoon ja palautetaan
    // tulos.
    return concat(
      filterOutNonDeprecatedChangeObjects(filteredChangeObjects),
      filterOutNonDeprecatedChangeObjects(freshChangeObjects)
    );
  }
}
