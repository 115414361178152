import { backendRoutes } from "stores/utils/backendRoutes";
import { CellFn } from "graphHandling/graphTypes";
import { getRaw } from "basedata";

export const fetch_luvat: CellFn = async (
  storeFunctions,
  intl,
  eventPayload,
  result,
  customParams
) => {
  if (!customParams) {
    throw new Error("Määritä koulutustyyppi, jotta luvat voidaan noutaa");
  }
  const luvat = await getRaw(
    "luvat",
    `${backendRoutes.luvat.path}${
      customParams.koulutustyyppi
        ? `?koulutustyyppi=${customParams.koulutustyyppi}`
        : ""
    }`,
    [],
    <number>customParams?.timeout
  );

  return luvat;
};
