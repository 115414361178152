import {
  append,
  endsWith,
  filter,
  find,
  flatten,
  isEmpty,
  isNil,
  map,
  nth,
  pathEq,
  pipe,
  prop,
  reject,
  split,
  startsWith
} from "ramda";
import { getAnchorPart, removeAnchorPart } from "utils/anchor";
import Lisatiedot from "../../lisatiedot";
import { createEsikatseluHTML } from "helpers/esikatselu";
import { __ } from "i18n-for-browser";

export const previewOfErityisenKoulutustehtavanRahoitus = (
  { lomakedata, rajoitteet = [], maaraykset },
  booleans,
  locale
) => {
  if (!lomakedata || !lomakedata.length) {
    return null;
  }

  let structure = [];

  const checkedNodes = filter(
    pathEq(["properties", "isChecked"], true),
    lomakedata
  );

  const anchorsOfCheckedNodes = map(prop("anchor"), checkedNodes);

  if (anchorsOfCheckedNodes.length) {
    /**
     * Yhtä checkbox-valintaa kohden voi olla useita kuvauksia. Etsitään ne.
     */
    const kuvausNodes = flatten(
      map(anchor => {
        /**
         * Palauta koulutustehtävän ankkuriin liittyvät kuvaus nodet.
         */
        return filter(node => {
          return (
            startsWith(`${removeAnchorPart(anchor, 2)}.`, node.anchor) &&
            endsWith(".kuvaus", node.anchor)
          );
        }, lomakedata);
      }, anchorsOfCheckedNodes).filter(Boolean)
    );

    const items = reject(
      isNil,
      map(node => {
        const anchorParts = split(".", node.anchor);
        const koodiarvo = getAnchorPart(node.anchor, 1);
        const kuvausnumero = getAnchorPart(node.anchor, 2);
        const maarays = find(
          maarays =>
            maarays.koodiarvo === koodiarvo &&
            maarays.koodisto === "lukioerityinenkoulutustehtavauusi" &&
            maarays.kohde?.tunniste === "erityisenkoulutustehtavanrahoitus",
          maaraykset
        );
        const lkm = find(
          pipe(prop("anchor"), endsWith(`${koodiarvo}.lkm`)),
          lomakedata
        );
        const kuvausteksti = node?.properties?.value || node.properties?.title;
        const opiskelijamaara = ` ${
          lkm?.properties?.value || maarays?.arvo || 0
        } ${__("education.opiskelijaa")}`;

        const html = createEsikatseluHTML(
          maarays,
          `${koodiarvo}-${kuvausnumero}`,
          rajoitteet,
          locale,
          "nimi",
          kuvausteksti + opiskelijamaara
        );

        if (isEmpty(html)) {
          return null;
        }

        return {
          anchor: koodiarvo,
          components: [
            {
              anchor: nth(2, anchorParts),
              name: "HtmlContent",
              properties: {
                content: html
              }
            }
          ]
        };
      }, kuvausNodes)
    );

    if (items.length) {
      structure = append(
        {
          anchor: "erityisen-koulutustehtavan-rahoitus",
          components: [
            {
              anchor: "info",
              name: "StatusTextRow",
              properties: {
                title: __("education.erityisenKoulutustehtavanRahoitusInfo")
              }
            }
          ],
          styleClasses: ["mb-4"]
        },
        structure
      );

      structure = append(
        {
          anchor: "valitut",
          components: [
            {
              anchor: "listaus",
              name: "List",
              properties: {
                isDense: true,
                items: items
              }
            }
          ]
        },
        structure
      );
    }
  }

  const lisatiedotNode = find(
    node => endsWith(".lisatiedot.1", node.anchor),
    lomakedata
  );

  if (lisatiedotNode && lisatiedotNode.properties.value) {
    structure = append(Lisatiedot(lisatiedotNode.properties.value), structure);
  }

  return structure;
};
