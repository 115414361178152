import { CellFn } from "graphHandling/graphTypes";
import { CellId } from "processes/CellId";

export const paivitaOrganisaatiovalikonTila: CellFn = storeFunctions => {
  storeFunctions.updateGraph(
    ["components", CellId.INITIALIZE_ORGANISAATIOVALIKKO, "properties"],
    {
      isDisabled: false,
      values: []
    }
  );

  return true;
};
