import { Fragment, useEffect, useState } from "react";
import {
  addIndex,
  filter,
  find,
  hasPath,
  isEmpty,
  length,
  map,
  path,
  propEq,
  sortBy,
  toUpper
} from "ramda";
import { useIntl } from "react-intl";
import education from "../../../../i18n/definitions/education";
import Typography from "@mui/material/Typography";
import { getRajoitteetFromMaarays } from "utils/rajoitteetUtils";
import { getLocalizedProperty } from "services/lomakkeet/utils";
import LisatiedotHtmlLupa from "components/03-templates/HtmlLupanakyma/Lisatiedot";
import rajoitteet from "../../../../i18n/definitions/rajoitteet";
import { PropTypes } from "prop-types";
import { PaikallisenTietovarastonAvain } from "enums";
import localForage from "localforage";

export default function ErityisetKoulutustehtavatHtml({ maaraykset }) {
  const intl = useIntl();
  const localeUpper = toUpper(intl.locale);
  const [
    erityisetKoulutustehtavatKoodisto,
    setErityisetKoulutustehtavatKoodisto
  ] = useState([]);

  /** Fetch opetuksenJarjestamismuodot from storage */
  useEffect(() => {
    localForage
      .getItem(
        PaikallisenTietovarastonAvain.LUKIO_ERITYINEN_KOULUTUSTEHTAVA_UUSI
      )
      .then(erityisetKoulutustehtavat =>
        setErityisetKoulutustehtavatKoodisto(erityisetKoulutustehtavat)
      )
      .catch(err => {
        console.error(err);
      });
  }, []);

  const erityisetKoulutustehtavatMaaraykset = sortBy(
    m => parseFloat(`${m.koodiarvo}.${path(["meta", "ankkuri"], m)}`),
    filter(
      maarays =>
        maarays.kohde.tunniste === "erityinenkoulutustehtava" &&
        maarays.koodisto === "lukioerityinenkoulutustehtavauusi",
      maaraykset
    )
  );

  const lisatietomaarays = find(
    maarays =>
      maarays.kohde.tunniste === "erityinenkoulutustehtava" &&
      maarays.koodisto === "lisatietoja",
    maaraykset
  );

  return !isEmpty(erityisetKoulutustehtavatMaaraykset) &&
    !isEmpty(erityisetKoulutustehtavatKoodisto) ? (
    <div className="mt-4">
      <Typography component="h3" variant="h3">
        {intl.formatMessage(education.erityisetKoulutustehtavat)}
      </Typography>
      <p className="mb-4">
        {intl.formatMessage(education.erityinenKoulutustehtavaInfo)}
      </p>

      <ul className="ml-8 list-disc mb-4">
        {addIndex(map)((maarays, index) => {
          let naytettavaArvo = path(["meta", "kuvaus"], maarays);

          if (!naytettavaArvo) {
            const koodistosta = find(
              propEq("koodiarvo", maarays.koodiarvo),
              erityisetKoulutustehtavatKoodisto
            );

            naytettavaArvo = getLocalizedProperty(
              koodistosta.metadata,
              localeUpper,
              "kuvaus"
            );
          }

          const result = (
            <Fragment key={`${maarays.koodiarvo}-${index}`}>
              <li
                className={`leading-bulletList ${
                  length(maarays.aliMaaraykset) ? "mb-2" : ""
                }`}>
                {naytettavaArvo}
                {length(maarays.aliMaaraykset)
                  ? getRajoitteetFromMaarays(
                      filter(
                        aliMaarays =>
                          !hasPath(
                            ["meta", "valtakunnallinenKehittamistehtava"],
                            aliMaarays
                          ),
                        maarays.aliMaaraykset
                      ),
                      localeUpper,
                      intl.formatMessage(rajoitteet.ajalla),
                      "kuvaus"
                    )
                  : ""}
              </li>
            </Fragment>
          );
          return result;
        }, erityisetKoulutustehtavatMaaraykset)}
      </ul>
      <LisatiedotHtmlLupa lisatietomaarays={lisatietomaarays} />
    </div>
  ) : null;
}

ErityisetKoulutustehtavatHtml.propTypes = {
  maaraykset: PropTypes.array
};
