import { ToimintaAluePikavalinnat, Toiminto } from "enums";
import { Maarays } from "koodistodatanTyypit";
import {
  append,
  assocPath,
  dissocPath,
  filter,
  find,
  init,
  mergeAll,
  path,
  prop,
  propEq,
  map,
  equals,
  reject,
  isEmpty,
  isNil,
  has,
  flatten,
  mapObjIndexed,
  values,
  forEach,
  startsWith,
  length
} from "ramda";
import { TietueProps } from "graphHandling/components/multiTextBox/MultiTextBox";
import {
  GraphComponent,
  IChip,
  IGroupedChipCollection,
  IGroupedChipCollections,
  Properties
} from "utils/lomakkeet";

export function getStatusCode(
  onkoMaaraysta: boolean,
  backendMuutos:
    | false
    | {
        tila: Toiminto;
      },
  frontendMuutos?: {
    tila: Toiminto;
  }
): number {
  const binary = `${Number(onkoMaaraysta)}${Number(
    backendMuutos && backendMuutos.tila === Toiminto.POISTO
  )}${Number(backendMuutos && backendMuutos.tila === Toiminto.LISAYS)}${Number(
    frontendMuutos?.tila === Toiminto.POISTO
  )}${Number(frontendMuutos?.tila === Toiminto.LISAYS)}}`;
  return parseInt(binary, 2);
}

/**
 * Palauttaa ajantasaisen määreen arvon ottaen huomioon
 * mahdollisen määreeseen kohdistuneen muutoksen.
 * @param propertyName Määreen nimi.
 * @param componentDefinition Komponentti jonka määre palautetaan.
 * @param modificationFlags Totuusarvot bMods- ja fMods -määreille.
 * @returns Määreen nykyinen arvo.
 */
export function getCurrentValue(
  propertyName: string,
  componentDefinition: GraphComponent,
  modificationFlags: { bMods?: boolean; fMods?: boolean } = {
    bMods: true,
    fMods: true
  }
): unknown {
  if (componentDefinition) {
    let value = prop(propertyName, componentDefinition.properties);
    const { modifications } = componentDefinition;
    if (modifications) {
      if (modificationFlags.bMods && modifications.backend) {
        const bPropValue = prop(propertyName, modifications.backend);
        value = bPropValue !== undefined ? bPropValue : value;
      }
      if (modificationFlags.fMods && modifications.frontend) {
        const fPropValue = prop(
          propertyName,
          mergeAll(modifications.frontend || [])
        );
        value = fPropValue !== undefined ? fPropValue : value;
      }
    }

    return value;
  }
  return undefined;
}

/**
 * Palauttaa komponentin ajantasaiset määreet.
 * @param properties Objekti jonka tietoja tarkastellaan.
 * @param fMods Huomioidaanko frontend-muutokset
 * @returns Properties-objekti.
 */
export function getCurrentProps(
  componentDefinition: GraphComponent,
  bMods = true,
  fMods = true
): Properties {
  if (!componentDefinition) {
    return {};
  }
  const { modifications } = componentDefinition;
  if (modifications) {
    return Object.assign(
      {},
      componentDefinition.properties,
      bMods ? modifications.backend || {} : {},
      fMods ? mergeAll(modifications.frontend || []) : {}
    );
  }
  return componentDefinition.properties as unknown as Properties;
}

export function isInInitialState(component: GraphComponent): boolean {
  return (
    !has("modifications", component) ||
    isNil(component.modifications) ||
    isEmpty(component.modifications)
  );
}

export function isGChipCsInInitialState(
  componentDefinition: IGroupedChipCollections
): boolean {
  let isInitial = true;
  if (componentDefinition) {
    mapObjIndexed(gChipC => {
      const parentMods = gChipC.properties.parentChip.modifications;
      const isParentChipInInitialState = !parentMods || isEmpty(parentMods);
      if (isParentChipInInitialState) {
        const chipsNotInitial = gChipC.properties.chipCollection.properties
          ? filter(chip => {
              return !!chip.modifications && !isEmpty(chip.modifications);
            }, gChipC.properties.chipCollection.properties.chips)
          : [];
        if (length(chipsNotInitial)) {
          isInitial = false;
        }
      } else {
        isInitial = false;
      }
    }, componentDefinition.properties.gChipCs);
  }
  return isInitial;
}

/**
 * Funktiossa ravistellaan moniulotteisesta objektista (tree) pois
 * tyhjät taulukot ja objektit sekä suoritetaan mahdolliset poistamista
 * edeltävät operaatiot.
 * @param p Polku, joka käydään läpi aloittaen polun perältä
 * @param branch Objekti (oksa), joka käydään läpi. Voi olla koko puu.
 */
export const recursiveTreeShake = (
  p: Array<string> = [],
  branch: Record<string, unknown> = {}
): Record<string, unknown> => {
  const value = path(p, branch);
  let updatedBranch = { ...branch };
  if (isEmpty(value) || isNil(value)) {
    updatedBranch = dissocPath(p, branch);
    const nextPath = init(p);
    if (!isEmpty(nextPath)) {
      return recursiveTreeShake(init(p), updatedBranch);
    }
    return updatedBranch;
  }
  return updatedBranch;
};

export function addNewModGroup(
  componentDefinition: GraphComponent
): GraphComponent {
  const modPath = ["modifications", "frontend"];
  if (!componentDefinition.modifications?.frontend) {
    return assocPath(modPath, [], componentDefinition);
  } else {
    return assocPath(
      modPath,
      append({}, path(modPath, componentDefinition) as Array<Properties>),
      componentDefinition
    );
  }
}

export function getChangesFromToimintaAlue(
  state: Record<string, GraphComponent>,
  maaraykset: Array<Maarays>
): {
  addition: {
    quickFilterChanges: string;
    additionalInfoChange: string;
    provinceChanges: Array<Properties>;
    municipalityChanges: Array<Properties>;
    ulkomaat: Array<TietueProps>;
  };
  lisatiedotOrig: string;
  lisatiedotCurrent: string;
  isEiMaariteltyaToimintaaluettaSelected: boolean;
  removal: {
    maaraysProvinceChanges: Array<Properties>;
    maaraysMunicipalityChanges: Array<Properties>;
    ulkomaat: Array<TietueProps>;
  };
} {
  const groupedChipCollections = state.gChipCs || [];
  const groupKeys: Array<string> = Object.keys(groupedChipCollections);
  const isSuomiCheckboxChecked = !!getCurrentValue(
    "isChecked",
    state.suomiCheckbox
  );
  const isUlkomaatCheckboxChecked = getCurrentValue(
    "isChecked",
    state.ulkomaatCheckbox
  );

  const isEiMaariteltyaToimintaaluettaSelected =
    !isSuomiCheckboxChecked && !isUlkomaatCheckboxChecked;

  // ULKOMAAT
  const ulkomaat = {
    lisaykset: [] as Array<TietueProps>,
    maaraykset: filter(
      maarays => maarays.koodisto === "kunta" && maarays.koodiarvo === "200",
      maaraykset
    ),
    poistot: [] as Array<TietueProps>
  };

  const tietueet = getCurrentProps(state.multiTextBox)?.tietueet as Record<
    string,
    TietueProps
  >;

  // Ulkomaan kohteita koskevien määräysten läpikäynti, joista
  // lisäykset ja poistot.
  forEach(maarays => {
    const tietue = prop(maarays.uuid as string, tietueet);
    if (tietue) {
      if (tietue.value === "" || tietue.isDeleted) {
        // Lisätään poistettaviin
        ulkomaat.poistot.push(tietue);
      } else {
        const origTietue = prop(
          maarays.uuid,
          state.multiTextBox.properties.tietueet
        );
        if (tietue.value !== origTietue.value) {
          // Lisätään lisättäviin
          ulkomaat.lisaykset.push(tietue);
        }
      }
    }
  }, ulkomaat.maaraykset);

  // Täysin uusien ulkomaita koskevien tietueiden läpikäynti, joista lisäykset.
  mapObjIndexed(tietue => {
    if (startsWith("multiTextBox-", tietue.id)) {
      ulkomaat.lisaykset.push(tietue);
    }
  }, tietueet);

  let additionalInfoChange = "";
  if (isEiMaariteltyaToimintaaluettaSelected) {
    additionalInfoChange = getCurrentValue(
      "value",
      state.eiMaariteltyKuvaus
    ) as string;
  }

  const radioValue = getCurrentValue("value", state.rBC) as string;

  let provinceChanges = isEiMaariteltyaToimintaaluettaSelected
    ? []
    : filter(
        (province: Properties | null) => province !== null,
        reject(
          isNil,
          map(key => {
            const provinceChip = path(
              [key, "properties", "parentChip"],
              groupedChipCollections
            ) as IChip;
            const props = getCurrentProps(provinceChip);
            // Jos käyttäjä on valinnut koko maakunnan
            return equals(props.isChecked && !props.isIndeterminate, true) &&
              has("isChecked", provinceChip.modifications)
              ? props
              : null;
          }, groupKeys)
        )
      );

  const groupKeysFiltered: Array<string> = reject((key: string): boolean => {
    return (find(provinceChange => {
      const provinceId = path(
        [key, "properties", "parentChip", "properties", "id"],
        groupedChipCollections
      ) as string;
      return (
        has("id", provinceChange) && propEq("id", provinceId, provinceChange)
      );
    }, provinceChanges) && true) as boolean;
  }, groupKeys);

  let municipalityChanges = isEiMaariteltyaToimintaaluettaSelected
    ? []
    : flatten(
        reject(isEmpty)(
          map(key => {
            const municipalityChips: Array<IChip> | undefined = path(
              [key, "properties", "chipCollection", "properties", "chips"],
              groupedChipCollections
            );
            return municipalityChips
              ? filter(
                  (municipalityChip: Properties) =>
                    municipalityChip.isChecked === true &&
                    !municipalityChip.isLineThroughable,
                  map(checkbox => {
                    return getCurrentProps(checkbox);
                  }, municipalityChips)
                )
              : [];
          }, groupKeysFiltered)
        )
      );

  if (radioValue === ToimintaAluePikavalinnat.MILTEI_KOKO_MAA) {
    provinceChanges = [];
    municipalityChanges = [];
  }

  const maakuntaMaaraykset = filter(
    propEq("koodisto", "maakunta"),
    maaraykset || []
  );
  const maaraysProvinceChanges = filter(
    (province: Properties | null) => province !== null,
    reject(
      isNil,
      map(maakuntaMaarays => {
        const gChipC = find(group => {
          const provinceId = path(
            ["properties", "parentChip", "properties", "id"],
            group
          );
          return provinceId === maakuntaMaarays.koodiarvo;
        }, values(groupedChipCollections)) as IGroupedChipCollection;
        const parentChipProps = getCurrentProps(gChipC.properties.parentChip);
        return equals(
          !parentChipProps.isChecked && !parentChipProps.isIndeterminate,
          true
        )
          ? parentChipProps
          : null;
      }, maakuntaMaaraykset)
    )
  );

  const kuntamaaraykset = filter(propEq("koodisto", "kunta"), maaraykset || []);

  const maaraysMunicipalityChanges = flatten(
    reject(isEmpty)(
      map(key => {
        const municipalityChips: Array<IChip> | undefined = path(
          [key, "properties", "chipCollection", "properties", "chips"],
          groupedChipCollections
        );
        return map(kuntamaarays => {
          return municipalityChips
            ? filter(
                (props: Properties) => {
                  return (
                    (isEiMaariteltyaToimintaaluettaSelected ||
                      !isSuomiCheckboxChecked ||
                      !!prop("isLineThrough", props) ||
                      radioValue ===
                        ToimintaAluePikavalinnat.MILTEI_KOKO_MAA) &&
                    props.value === kuntamaarays.koodiarvo
                  );
                },
                map(checkbox => {
                  return getCurrentProps(checkbox);
                }, municipalityChips)
              )
            : [];
        }, kuntamaaraykset);
      }, groupKeys)
    )
  );

  return {
    addition: {
      quickFilterChanges: radioValue,
      additionalInfoChange,
      provinceChanges,
      municipalityChanges,
      ulkomaat: ulkomaat.lisaykset
    },
    isEiMaariteltyaToimintaaluettaSelected,
    lisatiedotOrig: state.lisatiedot.properties.value,
    lisatiedotCurrent: getCurrentValue("value", state.lisatiedot) as string,
    removal: {
      maaraysProvinceChanges,
      maaraysMunicipalityChanges,
      ulkomaat: ulkomaat.poistot
    }
  };
}
