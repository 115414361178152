import { useEffect, Fragment, useState, useRef } from "react";
import {
  filter,
  find,
  map,
  head,
  lensIndex,
  values,
  addIndex,
  length,
  slice,
  sortBy,
  compose,
  prop,
  reverse,
  view,
  toUpper,
  includes,
  isNil,
  reject,
  isEmpty,
  findIndex,
  propEq
} from "ramda";
import { useIntl } from "react-intl";
import common from "i18n/definitions/common";
import { Link, useHistory } from "react-router-dom";
import { matchSorter } from "match-sorter";
import { Input, FormLabel } from "@mui/material";
import { localizeRouteKey } from "utils/common";
import { AppRoute } from "routes";
import { PropTypes } from "prop-types";
import PageNavigator from "components/01-molecules/PageNavigator";
import PageRowIndicator from "components/01-molecules/PageRowIndicator";
import Table from "components/02-organisms/Table/separatedTableSections";
import education from "i18n/definitions/education";
import { resolveVSTOppilaitosNameFromLupa } from "modules/helpers";
import Dropdown from "components/00-atoms/Dropdown";

// Define a default UI for filtering
function DefaultColumnFilter({ column, intl }) {
  return (
    <FormLabel>
      <Input
        id={`filter-${column.id}`}
        type="search"
        value={column.filterValue || ""}
        onChange={e => {
          column.setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={intl.formatMessage(common.filterRows)}
        style={{ fontSize: "0.875rem" }}
      />
    </FormLabel>
  );
}

DefaultColumnFilter.propTypes = {
  column: PropTypes.object,
  intl: PropTypes.object
};

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val;

function Jarjestajaluettelo({
  koulutusmuoto,
  luvat,
  pageNo,
  setPageNo,
  searchBy,
  setSearchBy,
  vstOppilaitostyyppiFilter,
  setVstOppilaitostyyppiFilter,
  vstTypeOptions,
  vstTyypit
}) {
  const history = useHistory();
  const intl = useIntl();
  const { formatMessage } = intl;
  const [pageSize, setPageSize] = useState(20);
  const [visibleRowCount, setVisibleRowCount] = useState();
  const [sortedBy, setSortedBy] = useState({ columnIndex: 0, order: "asc" });
  const [totalPages, setTotalPages] = useState(
    luvat.length > 0 ? Math.ceil(luvat.length / pageSize) : 0
  );

  const onOppilaitostyyppiSelectionChange = (_, { selectedOption }) => {
    setVstOppilaitostyyppiFilter(selectedOption);
    setSearchBy(prevState => {
      const nextSearchBy = {
        ...prevState,
        oppilaitostyyppi: {
          columnIndex: findIndex(
            propEq("accessor", "oppilaitostyyppi"),
            columns
          ),
          searchKey: "oppilaitostyyppi",
          searchValue: selectedOption,
          reRender: true
        }
      };
      return nextSearchBy;
    });
  };

  useEffect(() => {
    const oppilaitostyyppiColumnIndex = findIndex(
      propEq("accessor", "oppilaitostyyppi"),
      columns
    );
    const searchByItem = find(
      propEq("columnIndex", oppilaitostyyppiColumnIndex),
      values(searchBy)
    );
    let nextValueOfDropdown = "";
    if (searchByItem) {
      if (
        includes(
          toUpper(searchByItem.searchValue),
          map(compose(toUpper, prop("value")), vstTypeOptions)
        )
      ) {
        nextValueOfDropdown = searchByItem.searchValue;
      }
    }
    if (vstOppilaitostyyppiFilter !== nextValueOfDropdown) {
      setVstOppilaitostyyppiFilter(nextValueOfDropdown);
    }
  }, [searchBy]);

  const columns = [
    {
      accessor: "nimi",
      Header: intl.formatMessage(education.opetuksenJarjestaja),
      Cell
    },
    {
      accessor: "oppilaitos",
      Header: intl.formatMessage(common.VSTOppilaitosTitle)
    },
    {
      accessor: "oppilaitostyyppi",
      Header: intl.formatMessage(common.oppilaitostyyppi),
      disableFilters: true
    }
  ];

  useEffect(() => {
    const searchKeys = Object.keys(searchBy);
    setVisibleRowCount(
      length(
        filter(row => {
          return (
            length(
              filter(
                key =>
                  includes(
                    toUpper(searchBy[key].searchValue),
                    toUpper(row[key] || "")
                  ),
                searchKeys
              )
            ) === length(searchKeys)
          );
        }, data)
      )
    );
  }, [data, searchBy]);

  useEffect(() => {
    const totalPages = isEmpty(searchBy)
      ? Math.ceil(data.length / pageSize)
      : Math.ceil(visibleRowCount / pageSize);

    setTotalPages(totalPages);
  }, [data, pageSize, visibleRowCount, searchBy]);
  const [data] = useState(() =>
    map(lupa => {
      const { jarjestaja } = lupa;
      const localeUpper = toUpper(intl.locale);
      const oppilaitostyyppiKoodistosta = find(
        tyyppi =>
          tyyppi.koodiarvo === lupa.oppilaitostyyppi ||
          tyyppi.koodiArvo === lupa.oppilaitostyyppi,
        vstTyypit
      );
      return {
        nimi: jarjestaja.nimi[intl.locale] || head(values(jarjestaja.nimi)),
        oppilaitos: resolveVSTOppilaitosNameFromLupa(lupa, intl.locale),
        oppilaitostyyppi: oppilaitostyyppiKoodistosta
          ? oppilaitostyyppiKoodistosta.metadata[localeUpper].nimi
          : "",
        toiminnot: ["info"],
        ytunnus: lupa.jarjestajaYtunnus,
        lupaUuid: lupa.uuid
      };
    }, luvat)
  );

  const [tableHeader] = useState({
    role: "thead",
    rowGroups: [
      {
        rows: [
          {
            cells: map(item => {
              return {
                isSortable: !(item.isSortable === false),
                isSearchable: !!item.isSearchable,
                columnKey: item.columnKey,
                searchPlaceholder: formatMessage(common.filterRows),
                truncate: true,
                styleClasses: [item.widthClass],
                text: item.text
              };
            })(
              map(o => {
                return {
                  widthClass: "w-2/12",
                  isSearchable: true,
                  isSortable: true,
                  columnKey: o.accessor,
                  text: o.Header
                };
              }, columns)
            )
          }
        ]
      }
    ]
  });

  const [tableBody, setTableBody] = useState();

  useEffect(() => {
    const searchKeys = Object.keys(searchBy);
    const tableBody = {
      role: "tbody",
      rowGroups: [
        {
          rows: (() => {
            const rowObjects = reject(
              isNil,
              addIndex(map)(row => {
                // Tarkistetaan näytetäänkö riviä.
                const hits = filter(key => {
                  return includes(
                    toUpper(searchBy[key].searchValue),
                    toUpper(row[key] || "")
                  );
                }, Object.keys(searchBy));

                return isEmpty(searchBy) || length(hits) === length(searchKeys)
                  ? {
                      id: row.lupaUuid,
                      onClick: async (row, action, event) => {
                        const route = localizeRouteKey(
                          intl.locale,
                          AppRoute.Jarjestamislupa,
                          intl,
                          {
                            id: row.id,
                            koulutusmuoto: koulutusmuoto.kebabCase
                          }
                        );

                        if (
                          event.button === 0 &&
                          (event.ctrlKey || event.altKey)
                        ) {
                          window.open(route);
                        } else {
                          history.push(route);
                        }
                      },
                      cells: addIndex(map)(
                        col => {
                          return {
                            truncate: false,
                            styleClasses: [],
                            text: col.text
                          };
                        },
                        [
                          { text: row.nimi },
                          {
                            text: row.oppilaitos
                          },
                          {
                            text: row.oppilaitostyyppi
                          }
                        ]
                      )
                    }
                  : null;
              }, data)
            );

            const allRowsInAscendingOrder = sortBy(
              compose(
                prop("text"),
                view(lensIndex(sortedBy.columnIndex)),
                prop("cells")
              )
            )(rowObjects);

            const allRowsInOrder =
              sortedBy.order === "desc"
                ? reverse(allRowsInAscendingOrder)
                : allRowsInAscendingOrder;

            const rowsToShow = slice(
              (pageNo - 1) * pageSize,
              (pageNo - 1) * pageSize + pageSize,
              allRowsInOrder
            );

            return rowsToShow;
          })()
        }
      ]
    };

    setTableBody(tableBody);
  }, [data, pageNo, pageSize, searchBy, sortedBy]);

  const Cell = ({ row }) => {
    return (
      <Link
        className="underline"
        to={localizeRouteKey(intl.locale, AppRoute.Jarjestamislupa, intl, {
          id: row.original.oid,
          koulutusmuoto: koulutusmuoto.kebabCase
        })}
        title={intl.formatMessage(common.siirryKJnTarkempiinTietoihin, {
          nimi: row.values.nimi
        })}>
        {row.values.nimi}
      </Link>
    );
  };

  Cell.propTypes = {
    row: PropTypes.object
  };

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.
  const skipResetRef = useRef(false);

  // After data changes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  useEffect(() => {
    skipResetRef.current = false;
  }, [data]);

  return (
    <div className="mx-auto w-full mb-16">
      <p className="mt-4 mb-8">
        {intl.formatMessage(common.kjSivuinfo, { kpl: luvat.length })}
      </p>
      <Fragment>
        <PageRowIndicator
          totalCount={visibleRowCount}
          pageNo={pageNo}
          pageSize={pageSize}
        />
        <div className="flex items-center justify-between my-2 lg:mt-0 lg:mr-2">
          <div className="w-2/6">
            <Dropdown
              onChanges={onOppilaitostyyppiSelectionChange}
              isClearable={true}
              options={vstTypeOptions}
              value={vstOppilaitostyyppiFilter}
              fullWidth={true}
              label={intl.formatMessage(common.filterByOppilaitostyyppi)}
              isTall={false}
              className="w-full lg:w-20"
              emptyMessage={intl.formatMessage(common.noSelection)}
            />
          </div>
        </div>
        {tableHeader && tableBody && (
          <Table
            tableBody={tableBody}
            tableHeader={tableHeader}
            sortedBy={sortedBy}
            setSortedBy={setSortedBy}
            sortEnabled={true}
            searchBy={searchBy}
            setSearchBy={setSearchBy}
          />
        )}
        <PageNavigator
          pageNo={pageNo}
          pageSize={pageSize}
          totalPages={totalPages}
          setPageNo={setPageNo}
          setPageSize={setPageSize}
        />
      </Fragment>
    </div>
  );
}

Jarjestajaluettelo.propTypes = {
  koulutusmuoto: PropTypes.object,
  luvat: PropTypes.array,
  vstTyypit: PropTypes.array,
  pageNo: PropTypes.number,
  setPageNo: PropTypes.func,
  searchBy: PropTypes.object,
  setSearchBy: PropTypes.func,
  vstOppilaitostyyppiFilter: PropTypes.string,
  setVstOppilaitostyyppiFilter: PropTypes.func,
  vstTypeOptions: PropTypes.array
};

export default Jarjestajaluettelo;
