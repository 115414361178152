import { toast } from "react-toastify";
import informUser from "../../../../../../../i18n/definitions/informUser";

// muutospyynto.paatoskirje.tallennus.epaonnistui
export const epaonnistui = formatMessage => ({
  label: "Päätöskirjeen haku epäonnistui",
  input: ["output"],
  run: async () => {
    toast.error(formatMessage(informUser.paatoskirjeTallennusEpaonnistui), {
      autoClose: 10000,
      position: toast.POSITION.TOP_RIGHT
    });
    return true;
  }
});
