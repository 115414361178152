import { SimpleButton } from "utils/lomakkeet";
import { ComponentType } from "graphHandling/components/componentType";
import { ComponentEvent } from "graphHandling/graphTypes";
import { SimpleButtonProps } from "components/00-atoms/SimpleButton/SimpleButton";

export function defineSimpleButton(
  properties: SimpleButtonProps,
  onClick: ComponentEvent
): SimpleButton {
  return {
    name: ComponentType.SIMPLE_BUTTON,
    onClick,
    properties
  };
}
