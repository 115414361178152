import { getRaw } from "basedata";
import { CellFn } from "graphHandling/graphTypes";
import { backendRoutes } from "stores/utils/backendRoutes";

export const fetch_kohteet: CellFn = async (
  storeFunctions,
  intl,
  payload,
  result,
  customParams
) => {
  if (!customParams) {
    throw new Error("Määritä koulutustyyppi, jotta kohteet voidaan noutaa.");
  }

  try {
    return await getRaw(
      "kohteet",
      `${backendRoutes.kohteet.path}${
        customParams.koulutustyyppi
          ? "?koulutustyyppi=" + customParams.koulutustyyppi
          : ""
      }`
    );
  } catch (err) {
    throw new Error(err as string);
  }
};
