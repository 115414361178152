import { getChildNodes } from "./getChildNodes";
import { isEveryChildNodeChecked } from "./isEveryChildNodeChecked";
import { updateChangeObjectsArray } from "./updateChangeObjectsArray";
import { filter, length } from "ramda";
import { ComponentWithMetadata } from "utils/lomakkeet";
import { ChangeObjects } from "utils/muutokset";
import { findIsIndeterminateNodes } from "./findIsIndeterminateNodes";

/**
 *
 * @param componentWithMetadata Metadatalla höystetty komponenttimääritys.
 * @param componentsWithMetadata Taulukollinen metadatalla höystettyjä
 * komponenttimäärityksiä.
 * @param changeObjects Taulukollinen muutosobjekteja.
 * @returns Taulukollinen muutosobjekteja.
 */
export function modifyNode(
  componentWithMetadata: ComponentWithMetadata,
  componentsWithMetadata: Array<ComponentWithMetadata>,
  changeObjects: ChangeObjects = []
): ChangeObjects {
  const childNodes = getChildNodes(
    componentWithMetadata,
    componentsWithMetadata
  );

  // Määritetään parametrina annetun elementin isIndeterminate-asetuksen arvo.
  const isIndeterminate = !childNodes.length
    ? componentWithMetadata.properties.isIndeterminate
    : !isEveryChildNodeChecked(
        componentWithMetadata,
        componentsWithMetadata,
        changeObjects
      ) || length(findIsIndeterminateNodes(childNodes, changeObjects)) > 0;

  if (
    !componentWithMetadata.properties.isChecked ||
    componentWithMetadata.properties.isIndeterminate !== isIndeterminate
  ) {
    changeObjects = updateChangeObjectsArray(
      componentWithMetadata,
      { isChecked: true, isIndeterminate },
      changeObjects
    );
  } else {
    changeObjects = filter(
      changeObj => changeObj.anchor !== componentWithMetadata.fullAnchor,
      changeObjects
    );
  }

  return changeObjects;
}
