type Attachment = {
  filename: string;
  new: boolean;
  removed: boolean;
  tiedosto: null | typeof Blob;
  tiedostoId: string;
};

export const createMuutospyyntoOutput = (
  objectToSave: Record<string, unknown>,
  attachments?: Array<Attachment>
): FormData => {
  const data = new FormData();
  const muutos = new Blob([JSON.stringify(objectToSave)], {
    type: "application/json"
  });
  data.append("muutospyynto", muutos, "muutospyynnön json-data");

  if (attachments) {
    attachments.map(item => {
      if (!item.removed && item.new && item.tiedosto instanceof Blob) {
        data.append(item.tiedostoId, item.tiedosto, item.filename);
        item.tiedosto = null;
      }
      return null;
    });
  }
  return data;
};

export const asianumeroRegExp = /^VN\/[0-9]{1,9}\/[0-9]{4}$/;
