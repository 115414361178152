/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { has, join, replace } from "ramda";
import { IntlShape } from "react-intl";

export function getLocalizedPath(
  path: Array<string>,
  locale: string,
  formatMessage: (
    id: Record<string, unknown>,
    params?: Record<string, unknown>
  ) => string
): string | Array<string> {
  let localizedPath: string | Array<string> = [];
  switch (typeof path) {
    case "object":
      localizedPath = path.map(
        (key: string) => `/${locale}` + formatMessage({ id: key })
      );
      break;
    default:
      if (path === "*") {
        localizedPath = path;
      } else {
        localizedPath = `/${locale}${replace(
          /}/g,
          "",
          replace(/{/g, ":", path)
        )}`;
      }
  }

  return localizedPath;
}

/**
 *
 * @param path
 * @returns Lokalisoitu merkkijono tai taulukko
 */
export function localizeRoutePath(
  path: Array<string> | string,
  locale: string,
  intl: IntlShape
): string {
  let localizedPath = "";
  switch (typeof path) {
    case "object":
      localizedPath = join(
        "",
        path.map(
          (key: string) => `/${locale}` + intl.formatMessage({ id: key })
        )
      );
      break;
    default:
      localizedPath =
        path === "*" ? path : `/${locale}` + intl.formatMessage({ id: path });
  }

  return localizedPath;
}

export function hasMultiplePaths(
  path: string | string[] | undefined,
  messages: { [key: string]: string }
): boolean {
  return !!(
    path &&
    Array.isArray(path) &&
    path.some(messageId => has(messageId, messages))
  );
}
