import { getRaw } from "basedata";
import { PaikallisenTietovarastonAvain } from "enums";
import { CellFn } from "graphHandling/graphTypes";
import { backendRoutes } from "stores/utils/backendRoutes";
import { CellId } from "../../../processes/CellId";

export const fetch_organisation: CellFn = async (
  storeFunctions,
  intl,
  payload,
  result
) => {
  const lupa = result[CellId.FETCH_LUPA_BY_UUID];
  if (!lupa) {
    throw new Error(
      "Lupa, jolta organisaation ID katsotaan, ei ole käytettävissä."
    );
  }

  try {
    return await getRaw(
      PaikallisenTietovarastonAvain.ORGANISAATIO,
      `${backendRoutes.organisaatio.path}${lupa.jarjestajaOid}`
    );
  } catch (errText) {
    throw new Error(errText as string);
  }
};
