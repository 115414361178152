import { ReactElement } from "react";
import { getVoimassaoloaika } from "utils/common";
import { useIntl } from "react-intl";

type Props = {
  alkupvm: string;
  koodi: string;
  loppupvm: string;
  nimi: string;
  selite: string;
};

const MuuMaarays = ({
  alkupvm,
  koodi,
  loppupvm,
  nimi,
  selite
}: Props): ReactElement => {
  const intl = useIntl();
  return (
    <div className="mt-4 mb-4">
      <p>{selite}</p>
      <div className="flex ml-8">
        <div className="flex-1">
          {koodi ? koodi + " " : null}
          {nimi}
        </div>
        <div>{getVoimassaoloaika(intl, alkupvm, loppupvm)}</div>
      </div>
    </div>
  );
};

export default MuuMaarays;
