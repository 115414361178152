export const LUPA_SECTIONS = {
  1: {
    tunniste: "tutkinnotjakoulutukset",
    headingNumber: 1
  },
  2: {
    tunniste: "opetusjatutkintokieli",
    headingNumber: 2
  },
  3: {
    tunniste: "toimintaalue",
    headingNumber: 3
  },
  4: {
    tunniste: "opiskelijavuodet",
    headingNumber: 4
  },
  5: {
    tunniste: "muut",
    headingNumber: 5
  }
};

export const ISO_DATE_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT = "D.M.YYYY";
export const TIME_FORMAT = "HH:mm:ss";

export const KOHTEET = {
  TUTKINNOT: "tutkinnotjakoulutukset",
  KIELI: "opetusjatutkintokieli",
  TOIMIALUE: "toimintaalue",
  OPISKELIJAVUODET: "opiskelijavuodet",
  MUUT: "muut"
};

export const KOODISTOT = {
  KOULUTUS: "koulutus",
  OIVA_TYOVOIMAKOULUTUS: "oivatyovoimakoulutus",
  TEHTAVAAN_VALMISTAVA_KOULUTUS: "tehtavaanvalmistavakoulutus",
  AMMATILLISEEN_TEHTAVAAN_VALMISTAVA_KOULUTUS:
    "ammatilliseentehtavaanvalmistavakoulutus",
  KULJETTAJAKOULUTUS: "kuljettajakoulutus",
  KIELI: "kieli",
  OSAAMISALA: "osaamisala",
  KIELIKOODISTO_OPH: "kielikoodistoopetushallinto",
  LISATIETOJA: "lisatietoja",
  OPETUSTEHTAVAKOODISTO: "opetustehtavakoodisto",
  OPETUSTEHTAVAT: "opetustehtavat",
  OPPILAITOKSENOPETUSKIELI: "oppilaitoksenopetuskieli",
  OIVA_MUUT: "oivamuutoikeudetvelvollisuudetehdotjatehtavat"
};

export const koulutustyypitMap = {
  AMMATILLINEN_KOULUTUS: "",
  ESI_JA_PERUSOPETUS: "1",
  LUKIO: "2",
  VAPAASIVISTYSTYO: "3",
  TAITEEN_PERUSOPETUS: "4",
  TUVA: "5"
};

export const oppilaitostyypit = {
  KANSANOPISTO: "1",
  KANSALAISOPISTO: "2",
  OPINTOKESKUS: "3",
  KESAYLIOPISTO: "4",
  LIIKUNNAN_KOULUTUSKESKUS: "5",
  MUUT_OPPILAITOKSET: "6"
};

// https://wiki.eduuni.fi/pages/viewpage.action?pageId=351631679
// Voidaan käyttää tunnistamaan koodiarvot, jotka liittyvät vaativaan tukeen ammatillisessa koulutuksessa.
// Koodiarvon lisäksi kategoria eli koodiston pitää yleensä täsmätä.
// Tämä voi rikkoontua mikäli opintopolkuun tai opintopolun koodiarvoihin tehdään muutoksia.
export const VAATIVA_TUKI_KOODIARVOT = [
  "2",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "23",
  "3",
  "12"
];
