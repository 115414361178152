import {
  compose,
  endsWith,
  filter,
  find,
  flatten,
  length,
  map,
  path,
  pathEq,
  prop,
  propEq
} from "ramda";
import {
  createMaarayksiaVastenLuodutRajoitteetDynaamisilleTekstikentilleBEObjects,
  getRajoitteetByValueStartsWith
} from "utils/rajoitteetUtils";
import { getMaarayksetByTunniste } from "helpers/lupa";
import {
  createDynamicTextBoxBeChangeObjects,
  createBECheckboxChangeObjectsForDynamicTextBoxes
} from "services/lomakkeet/dynamic";
import { luoMuutosobjektitLisatietokentasta } from "helpers/lisatiedot";
import { PaikallisenTietovarastonAvain } from "enums";
import localForage from "localforage";

export const defineBackendChangeObjects = async (
  changeObjects = [],
  maaraystyypit,
  lupaMaaraykset,
  kohteet,
  locale,
  _kohde
) => {
  const { rajoitteetByRajoiteId } = changeObjects;

  const kohde = _kohde || find(propEq("tunniste", "muutehdot"), kohteet);

  const maaraykset = await getMaarayksetByTunniste(
    kohde.tunniste,
    lupaMaaraykset
  );

  const maarayksiaVastenLuodutRajoitteet =
    createMaarayksiaVastenLuodutRajoitteetDynaamisilleTekstikentilleBEObjects(
      maaraykset,
      rajoitteetByRajoiteId,
      kohteet,
      maaraystyypit,
      kohde
    );

  const maaraystyyppi = find(propEq("tunniste", "OIKEUS"), maaraystyypit);
  const muutEhdot = await localForage.getItem(
    PaikallisenTietovarastonAvain.TPO_MUUT_EHDOT
  );

  const muutokset = map(ehto => {
    // Checkbox-kenttien muutokset
    const checkboxChangeObj = find(
      compose(endsWith(`.${ehto.koodiarvo}.valintaelementti`), prop("anchor")),
      changeObjects.muutEhdot
    );

    const ehtoonLiittyvatMaaraykset = filter(
      m =>
        propEq("koodiarvo", ehto.koodiarvo, m) &&
        propEq("koodisto", "tpomuutehdot", m),
      maaraykset
    );

    const isCheckboxChecked =
      (!!ehtoonLiittyvatMaaraykset.length && !checkboxChangeObj) ||
      (checkboxChangeObj &&
        pathEq(["properties", "isChecked"], true, checkboxChangeObj));

    // Kuvauskenttien muutokset kohdassa (muu ehto)
    const kuvausChangeObjects = filter(changeObj => {
      return (
        ehto.koodiarvo ===
          path(["metadata", "koodiarvo"], changeObj.properties) &&
        endsWith(".kuvaus", changeObj.anchor)
      );
    }, changeObjects.muutEhdot);

    let checkboxBEchangeObject = null;
    let kuvausBEchangeObjects = [];

    const rajoitteetByRajoiteIdAndKoodiarvo = getRajoitteetByValueStartsWith(
      `${ehto.koodiarvo}-`,
      rajoitteetByRajoiteId
    );

    if (length(kuvausChangeObjects) && isCheckboxChecked) {
      kuvausBEchangeObjects = createDynamicTextBoxBeChangeObjects(
        kuvausChangeObjects,
        ehtoonLiittyvatMaaraykset,
        isCheckboxChecked,
        ehto,
        maaraystyyppi,
        maaraystyypit,
        rajoitteetByRajoiteIdAndKoodiarvo,
        checkboxChangeObj,
        kohde,
        kohteet
      );
    } else {
      return createBECheckboxChangeObjectsForDynamicTextBoxes(
        checkboxChangeObj,
        ehto,
        rajoitteetByRajoiteIdAndKoodiarvo,
        kohteet,
        kohde,
        maaraystyypit,
        maaraystyyppi,
        ehtoonLiittyvatMaaraykset,
        isCheckboxChecked,
        locale,
        "muuEhto"
      );
    }

    return [checkboxBEchangeObject, kuvausBEchangeObjects];
  }, muutEhdot);

  const lisatietomuutokset = await luoMuutosobjektitLisatietokentasta(
    kohde,
    changeObjects.muutEhdot,
    maaraystyyppi
  );

  const objects = flatten([
    muutokset,
    lisatietomuutokset,
    maarayksiaVastenLuodutRajoitteet
  ]).filter(Boolean);

  return objects;
};
