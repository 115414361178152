import { ITextBoxProps } from "components/00-atoms/TextBox/TextBox";
import { getCurrentProps, getCurrentValue } from "graphHandling/graphUtils";
import { concat, map, values } from "ramda";
import { Component, IChip, IMultiTextBox } from "utils/lomakkeet";

export default function getKunnat2(
  isReadOnly: boolean,
  osionData: {
    ulkomaat: IMultiTextBox;
    valitutKunnatJaMaakunnat: Array<IChip>;
  },
  locale: string,
  isMulti: boolean,
  inputId: string
): Array<Component> {
  const tietueet = getCurrentValue("tietueet", osionData.ulkomaat) as Record<
    string,
    ITextBoxProps
  >;

  return [
    {
      anchor: "komponentti",
      name: "Autocomplete",
      styleClasses: ["w-4/5", "xl:w-2/3", "mb-6"],
      properties: {
        forChangeObject: {
          section: "opetustaAntavatKunnat"
        },
        inputId,
        isMulti,
        isReadOnly,
        options: concat(
          map(chip => {
            const currentProps = getCurrentProps(chip);
            return currentProps.isChecked && !currentProps.isLineThrough
              ? {
                  label: chip.properties.label,
                  value: chip.properties.value
                }
              : null;
          }, osionData.valitutKunnatJaMaakunnat),
          map(tietue => {
            return {
              label: tietue.value,
              value: tietue.id
            };
          }, values(tietueet))
        ).filter(Boolean),
        value: ""
      }
    }
  ];
}
