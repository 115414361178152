import { createStore, createHook } from "react-sweet-state";
import { execute } from "./utils/loadFromBackend";

const Store = createStore({
  initialState: {},
  actions: {
    load:
      (jarjestajaOid, koulutusmuoto, oppilaitostyyppi, kieli, oppilaitosOid) =>
      ({ getState, setState }) => {
        const params = [];
        if (koulutusmuoto.koulutustyyppi) {
          params.push({
            key: "koulutustyyppi",
            value: koulutusmuoto.koulutustyyppi
          });
        }
        if (oppilaitostyyppi) {
          params.push({ key: "oppilaitostyyppi", value: oppilaitostyyppi });
        }
        if (kieli) {
          params.push({ key: "kieli", value: kieli });
        }
        if (oppilaitosOid) {
          params.push({ key: "oppilaitosOid", value: oppilaitosOid });
        }
        return execute(
          { getState, setState },
          {
            key: "lupahistoria",
            urlEnding: jarjestajaOid,
            queryParameters: params
          },
          {
            jarjestajaOid,
            koulutusmuoto,
            oppilaitostyyppi
          }
        );
      }
  },
  name: "Lupahistoria"
});

export const useLupahistoria = createHook(Store);
