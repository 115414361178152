import { luoFronttiystavallinenVersioKoodistotietueesta } from "helpers/koodistodatanKasittely";
import { Koodistotietue } from "koodistodatanTyypit";
import { FKoodistotietue } from "koodistodatanTyypitFrontille";
import { Lupamaarays } from "Lupa";
import { groupBy, map, prop } from "ramda";

/**
 * Luo fronttiystävälliset versiot opetuskielistä.
 * @param opetuskielet Koodistopalvelussa listatut opetuskielet (suomi, ruotsi, saame).
 * @param maaraykset Opetuskieliä koskevat määräykset.
 * @returns Taulukollinen fronttiystävällisiä versioita opetuskielistä.
 * Versiot sisältävät kuhunkin opetuskieleen liittyvän määräyksen alimääräyksineen.
 */
export const initializeOpetuskielet = (
  opetuskielet: Array<Koodistotietue>,
  maaraykset: Array<Lupamaarays> = []
): Array<FKoodistotietue> => {
  const maarayksetByOpetuskieli = groupBy(prop("koodiarvo"), maaraykset);
  return opetuskielet
    ? map(koodistotietue => {
        let opetuskieli =
          luoFronttiystavallinenVersioKoodistotietueesta(koodistotietue);
        const maaraykset: Array<Lupamaarays> =
          maarayksetByOpetuskieli[opetuskieli.koodiarvo];

        if (maaraykset && maaraykset.length > 0) {
          // Koska on yleisesti tiedossa, että yhtä opetuskieltä kohden on
          // korkeintaan yksi määräys, käsitellään vain ensimmäistä taulukon
          // arvoa.

          // Liitetään opetuskielelle siihen kuuluva määräys alimääräyksineen.
          opetuskieli = { ...opetuskieli, maarays: maaraykset[0] };
        }

        return opetuskieli;
      }, opetuskielet)
    : [];
};
