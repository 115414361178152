import { filter, pathEq, propEq } from "ramda";
import { PaikallisenTietovarastonAvain } from "enums";
import { Lupamaarays } from "Lupa";
import { Lupa } from "Lupa";
import localForage from "localforage";

/**
 * Etsii ja palauttaa ne määräykset, joiden kohdalla predicate-funktio
 * palauttaa arvon true.
 * @param predicate Funktio, joka valikoi määräysten joukosta palautettavat
 * määräykset.
 * @param maaraykset Taulukollinen määräyksiä.
 * @returns Taulukollinen määräyksiä.
 */
async function getMaarayksetOfPredicate(
  predicate: (obj: Lupamaarays) => boolean,
  maaraykset?: Array<Lupamaarays>
): Promise<Lupamaarays[]> {
  const lupa: Lupa | null = await localForage.getItem(
    PaikallisenTietovarastonAvain.LUPA
  );
  return filter(predicate, maaraykset || lupa?.maaraykset || []);
}

/**
 * Palauttaa määräykset kohteen tunnisteen perusteella. Tunniste voi olla
 * esimerkiksi "toimintaalue".
 * @param tunniste Ilmaisee mitä luvan osa-aluetta määräys koskee.
 * @param maaraykset Taulukollinen määräyksiä.
 * @returns Taulukollinen määräyksiä, joiden tunniste on yhtä kuin
 * parametrina annettu tunniste.
 */
export async function getMaarayksetByTunniste(
  tunniste: string,
  maaraykset: Array<Lupamaarays>
): Promise<Lupamaarays[]> {
  return getMaarayksetOfPredicate(
    pathEq(["kohde", "tunniste"], tunniste),
    maaraykset
  );
}

/**
 * Palauttaa määräykset koodiston perusteella. Tunniste voi olla
 * esimerkiksi "oivamuutoikeudetvelvollisuudetehdotjatehtavat".
 * @param koodisto Koodiston yksilöivä merkkijono.
 * @param maaraykset Taulukollinen määräyksiä.
 * @returns Taulukollinen määräyksiä, joiden koodisto on yhtä
 * kuin parametrina annettu koodisto.
 */
export async function getMaarayksetByKoodisto(
  koodisto: string,
  maaraykset?: Array<Lupamaarays>
): Promise<Lupamaarays[]> {
  return getMaarayksetOfPredicate(propEq("koodisto", koodisto), maaraykset);
}
