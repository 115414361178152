import { useState, useCallback } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FormHelperText, TextField } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import DateFnsUtils from "@date-io/date-fns";
import fiLocale from "date-fns/locale/fi";
import svLocale from "date-fns/locale/sv";
import enLocale from "date-fns/locale/en-GB";
import format from "date-fns/format";
import { COLORS } from "../../../modules/styles";
import { append } from "ramda";

const styles = createStyles(theme => ({
  root: {
    "& input:focus + fieldset": {
      borderColor: "green !important"
    },
    "& .Mui-disabled": {
      color: COLORS.OIVA_TEXT,
      marginTop: "0.6em",
      padding: 0
    },
    "& label.Mui-disabled": {
      transform: "translate(0, -0.8em) scale(0.75)"
    },
    "& input:disabled + fieldset": {
      borderColor: "transparent !important"
    },
    "& label": {
      color: COLORS.OIVA_TEXT + " !important"
    }
  },
  requiredVisited: {
    "& input + fieldset ": {
      borderColor: COLORS.OIVA_ORANGE,
      borderWidth: 2
    },
    "& input:focus + fieldset": {
      borderColor: "green !important"
    },
    "& label": {
      color: COLORS.OIVA_ORANGE_TEXT + " !important"
    }
  },
  dense: {
    marginTop: theme.spacing(2)
  }
}));

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "d. MMMM", { locale: this.locale });
  }
}

const Datepicker = ({
  ariaLabel,
  classes,
  clearable,
  disableFuture,
  disablePast,
  error,
  forChangeObject,
  fullAnchor,
  fullWidth,
  inputId,
  invalidLabel,
  isDisabled,
  isHidden,
  isReadOnly,
  isRequired,
  label,
  locale,
  minDate,
  maxDate,
  messages,
  onChanges,
  placeholder,
  requiredMessage,
  showTodayButton,
  showValidationErrors,
  value,
  width
}) => {
  const [selectedDate, setSelectedDate] = useState(value);
  const [isVisited, setIsVisited] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const localeMap = {
    en: enLocale,
    fi: fiLocale,
    sv: svLocale
  };

  const handleDateChange = useCallback(
    date => {
      if (date) {
        onChanges({ forChangeObject, fullAnchor }, { value: date });
        setSelectedDate(date);
      } else {
        onChanges({ forChangeObject, fullAnchor }, { value: "" });
        setSelectedDate(null);
      }
    },
    [forChangeObject, fullAnchor, onChanges]
  );

  return (
    <LocalizationProvider
      adapterLocale={localeMap[locale]}
      dateAdapter={AdapterDateFns}
      utils={LocalizedUtils}>
      <DatePicker
        aria-label={ariaLabel}
        cancelLabel={messages.cancel}
        clearable={clearable}
        className={`${isHidden ? "hidden" : ""} 
            ${
              (isVisited || showValidationErrors) &&
              isRequired &&
              !value &&
              !isFocused
                ? classes.requiredVisited
                : classes.root
            } 
        `}
        clearLabel={messages.clear}
        componentsProps={{
          actionBar: {
            // The actions will be the same between desktop and mobile
            actions: showTodayButton ? append("today", ["clear"]) : ["clear"]
          }
        }}
        disabled={isDisabled}
        disableFuture={disableFuture}
        disablePast={disablePast}
        error={error}
        fullWidth={width ? false : fullWidth}
        inputFormat="d.M.yyyy" // Always is Finnish format
        InputProps={{
          className: classes.input,
          id: inputId
        }}
        InputAdornmentProps={{
          taikoid: fullAnchor ? fullAnchor.split(".")[1] : ""
        }}
        inputVariant="outlined"
        invalidDateMessage={messages.dateinvalid}
        invalidLabel={invalidLabel}
        label={label}
        margin="dense"
        disableMaskedInput={true}
        maxDate={maxDate}
        maxDateMessage={messages.datemax}
        minDate={minDate}
        minDateMessage={messages.datemin}
        okLabel={messages.ok}
        onBlur={() => setIsFocused(false)}
        onBlurCapture={() =>
          !selectedDate ? setIsVisited(true) : setIsVisited(false)
        }
        onChange={handleDateChange}
        onFocus={() => setIsFocused(true)}
        placeholder={isDisabled || isReadOnly || label ? "" : placeholder}
        readOnly={isReadOnly}
        renderInput={props => <TextField label={label} {...props} />}
        required={isRequired}
        style={width ? { width } : {}}
        todayLabel={messages.today}
        value={selectedDate || null}
        width={width}
      />
      {showValidationErrors && requiredMessage && (
        <FormHelperText
          id="component-message-text"
          style={{
            marginTop: "0.1em",
            paddingLeft: "1.2em",
            marginBottom: "0.5em",
            color: COLORS.OIVA_ORANGE_TEXT
          }}>
          {isVisited && !selectedDate && requiredMessage}
        </FormHelperText>
      )}
    </LocalizationProvider>
  );
};

Datepicker.defaultProps = {
  ariaLabel: "Datepicker",
  label: null,
  delay: 300,
  id: `datepicker-${Math.random()}`,
  isDisabled: false,
  isHidden: false,
  error: false,
  width: "",
  forChangeObject: {},
  fullWidth: true,
  clearable: true,
  showTodayButton: true,
  disablePast: false,
  disableFuture: false
};

Datepicker.propTypes = {
  ariaLabel: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.string,
  id: PropTypes.string,
  isReadOnly: PropTypes.bool,
  inputId: PropTypes.string,
  isDisabled: PropTypes.bool,
  isHidden: PropTypes.bool,
  /** Is called with the payload and the value. */
  onChanges: PropTypes.func,
  /** Custom object defined by user. */
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  width: PropTypes.string,
  forChangeObject: PropTypes.object,
  fullAnchor: PropTypes.string,
  fullWidth: PropTypes.bool,
  value: PropTypes.any,
  clearable: PropTypes.bool,
  showTodayButton: PropTypes.bool,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  locale: PropTypes.string,
  messages: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,
  isReadonly: PropTypes.bool,
  invalidLabel: PropTypes.string,
  requiredMessage: PropTypes.string,
  showValidationErrors: PropTypes.bool
};

export default withStyles(styles)(Datepicker);
