import { CellImplementations } from "graphHandling/graphTypes";
import { fetch_luvat } from "./cellImplementations/fetch_luvat";
import { CellId } from "processes/CellId";

export const processDefinition = {
  cells: {
    [CellId.INITIALIZE_PROCESS_ESI_JA_PERUSOPETUS]: {
      isFirstCell: true,
      name: "Esi ja perusopetus",
      out: {
        [CellId.FETCH_LUVAT]: {}
      }
    },
    [CellId.FETCH_LUVAT]: {
      name: "Fetch: Luvat"
    }
  }
};

export const cellImplementations: CellImplementations = {
  [CellId.FETCH_LUVAT]: fetch_luvat
};
