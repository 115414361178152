import { Fragment, ReactElement } from "react";
import Button from "@mui/material/Button";
import { FaEdit, FaPlus } from "react-icons/fa";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EastIcon from "@mui/icons-material/East";
import { ComponentEvent } from "graphHandling/graphTypes";

export enum Color {
  PRIMARY = "primary",
  SECONDARY = "secondary"
}

export enum Size {
  SMALL = "small",
  LARGE = "large"
}

export enum Variant {
  CONTAINED = "contained",
  OUTLINED = "outlined",
  TEXT = "text",
  INFO = "info"
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    info: true;
  }
}

const defaultProps = {
  buttonStyles: {},
  disabled: false,
  icon: null,
  iconStyles: {},
  isDisabled: false,
  isHidden: false,
  isReadOnly: false,
  styleAsLink: false,
  text: ""
};

export interface SimpleButtonProps {
  ariaLabel: string;
  buttonStyles?: Record<string, number | string>;
  color?: Color;
  icon?: string | null;
  iconStyles?: object;
  id: string;
  isDisabled?: boolean;
  isHidden?: boolean;
  isReadOnly?: boolean;
  size?: Size;
  styleAsALink?: boolean;
  text?: string;
  variant?: Variant;
  className?: string;
}

interface SimpleButtonPropsAndEvents extends SimpleButtonProps {
  onClick: ComponentEvent;
}

const SimpleButton = ({
  className = defaultProps.text,
  ariaLabel,
  color = Color.PRIMARY,
  icon = defaultProps.icon,
  iconStyles = defaultProps.iconStyles,
  id,
  isDisabled = defaultProps.isDisabled,
  isHidden = defaultProps.isHidden,
  isReadOnly = defaultProps.isReadOnly,
  onClick,
  size = Size.LARGE,
  styleAsALink = defaultProps.styleAsLink,
  text = defaultProps.text,
  variant = Variant.CONTAINED
}: SimpleButtonPropsAndEvents): ReactElement => {
  const baseClasses =
    "inline-block no-underline text-white hover:text-gray-100 normal-case font-normal";

  return (
    <Fragment>
      {!isReadOnly && !isHidden && (
        <Button
          className={className}
          endIcon={icon && icon === "Arrow" && <EastIcon />}
          aria-label={ariaLabel}
          color={color}
          disabled={isDisabled}
          disableElevation
          disableRipple
          id={id}
          onClick={event => {
            onClick({
              changeProps: {},
              mouseEvent: event
            });
          }}
          size={size}
          variant={variant}>
          {icon && (
            <span className="mr-2">
              {icon === "FaEdit" && <FaEdit style={iconStyles} />}
              {icon === "FaPlus" && <FaPlus style={iconStyles} />}
              {icon === "ClearIcon" && <ClearIcon style={iconStyles} />}
              {icon === "Delete" && <DeleteIcon style={iconStyles} />}
            </span>
          )}
          {styleAsALink ? <span className={baseClasses}>{text}</span> : text}
        </Button>
      )}
    </Fragment>
  );
};

export default SimpleButton;
