import { getRaw } from "basedata";
import { PaikallisenTietovarastonAvain } from "enums";
import { CellFn } from "graphHandling/graphTypes";
import { backendRoutes } from "stores/utils/backendRoutes";

export const fetch_organisation: CellFn = async (
  storeFunctions,
  intl,
  payload,
  result,
  customParams
) => {
  if (!customParams) {
    throw new Error("Organisaation oid ei ole käytettävissä.");
  }

  const organisation = await getRaw(
    PaikallisenTietovarastonAvain.ORGANISAATIO,
    `${backendRoutes.organisaatio.path}${customParams.oid}`
  );

  return organisation;
};
