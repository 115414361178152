import { CellImplementations } from "graphHandling/graphTypes";
import { fetch_lupaJaMuutospyynto } from "graphs/fetch_lupaJaMuutospyynto";
import { action_kumoaMuutokset } from "./cellImplementations/action_kumoaMuutokset";
import { fetch_maakuntakunnat } from "./cellImplementations/fetch_maakuntakunnat";
import { initialize_groupedChipCollections } from "./cellImplementations/initialize_groupedChipCollections";
import { add_chip } from "./cellImplementations/add_chip";
import { CellId } from "processes/CellId";

import initialize_autocomplete from "./cellImplementations/initialize_autocomplete";
import initialize_restrictions from "./cellImplementations/initialize_restrictions";

const mainProcessId = "000";

export const graphDefinition = {
  cells: {
    [CellId.INITIALIZE_PROCESS_MAAKUNNAT_JA_KUNNAT]: {
      isFirstCell: true,
      name: "Start2",
      out: {
        [CellId.FETCH_LUPA_JA_MUUTOSPYYNTO]: {},
        [CellId.FETCH_MAAKUNTAKUNNAT]: {}
      }
    },
    [CellId.ADD_CHIP]: {
      name: "Add: Chip",
      out: {
        [CellId.UPDATE_MAIN_PROCESS]: {
          processId: mainProcessId
        }
      }
    },
    [CellId.FETCH_LUPA_JA_MUUTOSPYYNTO]: {
      name: "Fetch: Lupa ja muutospyyntö"
    },
    [CellId.FETCH_MAAKUNTAKUNNAT]: {
      name: "Fetch: Maakuntakunnat",
      out: {
        [CellId.INITIALIZE_AUTOCOMPLETE]: {},
        [CellId.INITIALIZE_GROUPED_CHIP_COLLECTIONS]: {},
        [CellId.INITIALIZE_RESTRICTIONS]: {}
      }
    },
    [CellId.INITIALIZE_AUTOCOMPLETE]: {
      name: "Initialize: Autocomplete",
      out: {
        [CellId.ADD_CHIP]: {
          events: ["onChange"]
        }
      }
    },
    [CellId.INITIALIZE_GROUPED_CHIP_COLLECTIONS]: {
      name: "Initialize: Grouped Chip Collections (GChipCs)",
      out: {
        [CellId.UPDATE_MAIN_PROCESS]: {
          events: ["onClick", "onInit"],
          processId: mainProcessId
        }
      }
    },
    [CellId.ACTION_KUMOA_MUUTOKSET]: {
      name: "Action: Kumoa muutokset",
      out: {
        [CellId.UPDATE_MAIN_PROCESS]: {
          processId: mainProcessId
        }
      }
    }
  }
};

export const cellImplementations: CellImplementations = {
  [CellId.ADD_CHIP]: add_chip,
  [CellId.FETCH_LUPA_JA_MUUTOSPYYNTO]: fetch_lupaJaMuutospyynto,
  [CellId.FETCH_MAAKUNTAKUNNAT]: fetch_maakuntakunnat,
  // Autocomplete-kenttä.
  [CellId.INITIALIZE_AUTOCOMPLETE]: initialize_autocomplete,
  // Maakuntien ja niihin kuuluvien kuntien alustaminen.
  [CellId.INITIALIZE_GROUPED_CHIP_COLLECTIONS]:
    initialize_groupedChipCollections,
  // Maakuntiin ja kuntiin liittyvien rajoitteiden alustaminen.
  [CellId.INITIALIZE_RESTRICTIONS]: initialize_restrictions,
  [CellId.ACTION_KUMOA_MUUTOKSET]: action_kumoaMuutokset
};
