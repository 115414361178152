import { toast } from "react-toastify";
import { map, prop } from "ramda";
import informUser from "../../../../../../i18n/definitions/informUser";

// muutospyynnot.tilanmuutos.epaonnistui
export const epaonnistui = formatMessage => ({
  label: "Ilmoitus epäonnistumisesta",
  input: ["output"],
  run: async ({ output }) => {
    if (output.result.errors && output.result.errors.length > 0) {
      toast.error(
        `${formatMessage(informUser.muutospyynnonValidointiVirhe)}\n` +
          map(reason => {
            return `- ${formatMessage(informUser[reason])}`;
          }, map(prop("reason"), output.result.errors)).join("\n"),
        {
          autoClose: 10000,
          position: toast.POSITION.TOP_RIGHT
        }
      );
    } else {
      toast.error(
        formatMessage(informUser.muutospyynonTilanMuuttaminenEpaonnistui),
        {
          autoClose: 5000,
          position: toast.POSITION.TOP_RIGHT
        }
      );
    }
    return true;
  }
});
