import { CellImplementations } from "graphHandling/graphTypes";
import { initialize_radioButtons } from "./cellImplementations/initialize_radioButtons";
import { update_radioButtonCollection } from "./cellImplementations/update_radioButtonCollection";
import { action_kumoaMuutokset } from "./cellImplementations/action_kumoaMuutokset";
import { fetch_lupaJaMuutospyynto } from "graphs/fetch_lupaJaMuutospyynto";
import { CellId } from "processes/CellId";

const mainProcessId = "000";

export const graphDefinition = {
  cells: {
    [CellId.INITIALIZE_SUOMI_PROCESS]: {
      isFirstCell: true,
      name: "Start",
      out: {
        [CellId.INITIALIZE_KUMOA_MUUTOKSET]: {},
        [CellId.FETCH_MAAKUNTAKUNNAT]: {},
        [CellId.FETCH_LUPA_JA_MUUTOSPYYNTO]: {}
      }
    },
    [CellId.ACTION_KUMOA_MUUTOKSET]: {
      name: "Action: Kumoa muutokset",
      out: {
        [CellId.UPDATE_MAIN_PROCESS]: {
          processId: mainProcessId
        }
      }
    },
    [CellId.FETCH_LUPA_JA_MUUTOSPYYNTO]: {
      name: "Fetch: Lupa ja muutospyyntö",
      out: {
        [CellId.INITIALIZE_RADIO_BUTTON_COLLECTION]: {}
      }
    },
    [CellId.FETCH_MAAKUNTAKUNNAT]: {
      name: "Fetch: Maakuntakunnat"
    },
    [CellId.INITIALIZE_KUMOA_MUUTOKSET]: {
      name: "Initialize: Kumoa muutokset",
      out: {
        [CellId.ACTION_KUMOA_MUUTOKSET_SUOMI]: {
          events: ["onClick"],
          processId: "maakunnatJaKunnat"
        }
      }
    },
    [CellId.INITIALIZE_RADIO_BUTTON_COLLECTION]: {
      name: "Initialize: Radio button collection",
      out: {
        [CellId.UPDATE_RADIO_BUTTONS]: {
          events: ["onChange"]
        },
        [CellId.UPDATE_MAIN_PROCESS]: {
          processId: mainProcessId
        }
      }
    },
    [CellId.UPDATE_RADIO_BUTTONS]: {
      name: "Update: Radio button collection",
      out: {
        [CellId.UPDATE_MAIN_PROCESS]: {
          processId: mainProcessId
        }
      }
    }
  }
};

export const cellImplementations: CellImplementations = {
  [CellId.ACTION_KUMOA_MUUTOKSET]: action_kumoaMuutokset,
  [CellId.FETCH_LUPA_JA_MUUTOSPYYNTO]: fetch_lupaJaMuutospyynto,
  [CellId.INITIALIZE_RADIO_BUTTON_COLLECTION]: initialize_radioButtons,
  [CellId.UPDATE_RADIO_BUTTONS]: update_radioButtonCollection
};
