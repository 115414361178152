import { Fragment } from "react";
import {
  addIndex,
  filter,
  find,
  isEmpty,
  length,
  map,
  path,
  pathEq,
  toUpper
} from "ramda";
import { useIntl } from "react-intl";
import education from "i18n/definitions/education";
import Typography from "@mui/material/Typography";
import { getRajoitteetFromMaarays } from "../../../utils/rajoitteetUtils";
import opiskelijamaara from "i18n/definitions/opiskelijamaara";
import LisatiedotHtmlLupa from "components/03-templates/HtmlLupanakyma/Lisatiedot";
import rajoitteet from "i18n/definitions/rajoitteet";
import { PropTypes } from "prop-types";
import { getVoimassaOlevatOppilasmaaraMaaraykset } from "helpers/muut";

export default function Opiskelijamaarat({ maaraykset }) {
  const intl = useIntl();
  const locale = toUpper(intl.locale);

  const opiskelijamaaraMaaraykset = filter(
    maarays =>
      pathEq(["kohde", "tunniste"], "opiskelijamaarat", maarays) &&
      maarays.koodisto === "kujalisamaareet",
    maaraykset
  );

  const voimassaOlevatMaaraykset = getVoimassaOlevatOppilasmaaraMaaraykset(
    opiskelijamaaraMaaraykset
  );

  const hasKokonaisopiskelijamaararajoite = !!find(
    maarays => path(["meta", "tyyppi"], maarays) === "kokonaismaara",
    voimassaOlevatMaaraykset
  );

  const lisatietomaarays = find(
    maarays =>
      pathEq(["kohde", "tunniste"], "opiskelijamaarat", maarays) &&
      maarays.koodisto === "lisatietoja",
    maaraykset
  );

  return !isEmpty(voimassaOlevatMaaraykset) ? (
    <div className="mt-4">
      <Typography component="h3" variant="h3">
        {intl.formatMessage(education.oppilasOpiskelijamaarat)}
      </Typography>
      {!hasKokonaisopiskelijamaararajoite && (
        <ul key="kokonaisopiskelijamaara-ei-rajattu" className="ml-8 list-disc">
          <li className="leading-bulletList">
            {intl.formatMessage(opiskelijamaara.kokonaismaaraEiRajattu)}
          </li>
        </ul>
      )}
      <ul className="ml-8 list-disc mb-4">
        {addIndex(map)(
          (maarays, index) => [
            <li
              key={"opiskelijamaara-"}
              className={`leading-bulletList ${
                length(maarays.aliMaaraykset) ? "mb-2" : ""
              }`}>
              {maarays.meta.tyyppi === "yksittainen"
                ? intl.formatMessage(
                    opiskelijamaara.yksittainenKohdennus,
                    locale
                  )
                : intl.formatMessage(opiskelijamaara.kokonaismaara, locale)}
              {": "}
              {path(
                ["nimi"],
                find(
                  metadata => metadata.kieli === locale,
                  path(["koodi", "metadata"], maarays)
                )
              )}{" "}
              {maarays.arvo}
              <ul key={maarays.arvo + "-" + index} className="list-disc">
                <Fragment>
                  {length(maarays.aliMaaraykset)
                    ? getRajoitteetFromMaarays(
                        maarays.aliMaaraykset,
                        locale,
                        intl.formatMessage(rajoitteet.ajalla)
                      )
                    : ""}
                </Fragment>
              </ul>
            </li>
          ],
          voimassaOlevatMaaraykset || []
        )}
      </ul>

      <LisatiedotHtmlLupa lisatietomaarays={lisatietomaarays} />
    </div>
  ) : null;
}

Opiskelijamaarat.propTypes = {
  maaraykset: PropTypes.array
};
