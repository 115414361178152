import { useEffect, useState } from "react";
import {
  filter,
  find,
  includes,
  map,
  toUpper,
  isEmpty,
  propEq,
  path,
  length,
  addIndex,
  pathEq
} from "ramda";
import { useIntl } from "react-intl";
import common from "../../../../i18n/definitions/common";
import Typography from "@mui/material/Typography";
import { getRajoitteetFromMaarays } from "../../../../utils/rajoitteetUtils";
import LisatiedotHtmlLupa from "components/03-templates/HtmlLupanakyma/Lisatiedot";
import rajoitteet from "i18n/definitions/rajoitteet";
import { PropTypes } from "prop-types";
import { PaikallisenTietovarastonAvain } from "enums";
import localForage from "localforage";
import { sortByLocale } from "../../../../helpers/html";

export default function OpetuskieletHtml({ maaraykset }) {
  const intl = useIntl();
  const locale = toUpper(intl.locale);
  const [kieletOPH, setKieletOPH] = useState([]);

  /** Fetch kieletOPH from storage */
  useEffect(() => {
    localForage
      .getItem(PaikallisenTietovarastonAvain.KIELET_OPH)
      .then(kielet => setKieletOPH(kielet))
      .catch(err => {
        console.error(err);
      });
  }, []);

  const ensisijaisetOpetuskielet = sortByLocale(
    locale,
    filter(
      maarays =>
        pathEq(["kohde", "tunniste"], "opetuskieli", maarays) &&
        maarays.koodisto === "kielikoodistoopetushallinto" &&
        includes("ensisijaiset", maarays.meta.changeObjects[0].anchor),
      maaraykset
    )
  );

  const lisatietomaarays = find(
    maarays =>
      pathEq(["kohde", "tunniste"], "opetuskieli", maarays) &&
      maarays.koodisto === "lisatietoja",
    maaraykset
  );

  return (
    !isEmpty(ensisijaisetOpetuskielet) &&
    !isEmpty(kieletOPH) && (
      <div className="mt-4">
        <Typography component="h3" variant="h3">
          {intl.formatMessage(common.opetuskieli)}
        </Typography>
        {getOpetuskieletHtml(ensisijaisetOpetuskielet, kieletOPH, locale, intl)}
        <LisatiedotHtmlLupa lisatietomaarays={lisatietomaarays} />
      </div>
    )
  );
}

OpetuskieletHtml.propTypes = {
  maaraykset: PropTypes.array
};

const getOpetuskieletHtml = (opetuskielet, kieletOPH, locale, intl) => {
  return (
    <ul className="ml-8 list-disc mb-4">
      {addIndex(map)(
        (opetuskieli, index) => [
          <li key={opetuskieli.koodiarvo} className="leading-bulletList">
            {path(
              ["metadata", locale, "nimi"],
              find(
                propEq("koodiarvo", toUpper(opetuskieli.koodiarvo)),
                kieletOPH
              )
            )}
          </li>,
          <div key={"div-" + index}>
            {length(opetuskieli.aliMaaraykset)
              ? getRajoitteetFromMaarays(
                  opetuskieli.aliMaaraykset,
                  locale,
                  intl.formatMessage(rajoitteet.ajalla)
                )
              : ""}
          </div>
        ],
        opetuskielet || []
      )}
    </ul>
  );
};
