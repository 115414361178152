import { CellFn } from "graphHandling/graphTypes";
import { koulutustyypitMap } from "utils/constants";
import { CellId } from "processes/CellId";
import { backendRoutes } from "stores/utils/backendRoutes";
import { getRaw } from "basedata";
import { path, prop } from "ramda";

export const paaluvanNoutaminen: CellFn = async storeFunctions => {
  const components = storeFunctions.readPath(["components"]);

  const koulutustyyppi: string | undefined = path(
    [CellId.INITIALIZE_KOULUTUSMUOTOVALIKKO, "properties", "value"],
    components
  );

  if (koulutustyyppi !== undefined) {
    const valitunOrganisaationOid = path(
      [
        CellId.INITIALIZE_ORGANISAATIOVALIKKO,
        "properties",
        "values",
        0,
        "value"
      ],
      components
    );
    const valitunKielenLyhenne = path(
      [CellId.INITIALIZE_LOKALISAATIOVALIKKO, "properties", "value"],
      components
    );

    const routeMap = {
      [koulutustyypitMap.LUKIO]: `${backendRoutes.viimeisinLupa.path}${valitunOrganisaationOid}/viimeisin?koulutustyyppi=${koulutustyyppi}&kieli=${valitunKielenLyhenne}`,
      [koulutustyypitMap.AMMATILLINEN_KOULUTUS]: `${backendRoutes.viimeisinLupa.path}${valitunOrganisaationOid}/viimeisin`
    };

    const backendRoute =
      prop(koulutustyyppi, routeMap) ||
      `${backendRoutes.viimeisinLupa.path}${valitunOrganisaationOid}/viimeisin?koulutustyyppi=${koulutustyyppi}`;

    const paalupa = await getRaw("viimeisinLupa", backendRoute, [], 0);

    storeFunctions.updateGraph(["custom", "viimeisinPaalupa"], paalupa);
  }

  return true;
};
