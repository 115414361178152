import { Fragment } from "react";
import { useIntl } from "react-intl";
import Typography from "@mui/material/Typography";
import common from "i18n/definitions/common";
import tuva from "i18n/definitions/tuva";
import OpetustaAntavatKunnat from "components/03-templates/HtmlLupanakyma/OpetustaAntavatKunnat";
import { PropTypes } from "prop-types";
import { filter, pathEq, toLower } from "ramda";

export default function TuvaToimintaalue({ maaraykset, parentKoulutusmuoto }) {
  const { formatMessage } = useIntl();

  const toimintaaluemaaraykset = filter(
    pathEq(["kohde", "tunniste"], "toimintaalue"),
    maaraykset
  );

  return !toimintaaluemaaraykset || !toimintaaluemaaraykset.length ? (
    <Fragment>
      <Typography component="h3" variant="h3">
        {formatMessage(common.lupaSectionToimintaAlueMainTitle)}
      </Typography>

      <p>
        {formatMessage(tuva.maaratyllaToimintaalueella, {
          koulutusmuoto: toLower(parentKoulutusmuoto.genetiivi)
        })}
      </p>
    </Fragment>
  ) : (
    <OpetustaAntavatKunnat maaraykset={maaraykset} tunniste="toimintaalue" />
  );
}

TuvaToimintaalue.propTypes = {
  maaraykset: PropTypes.array,
  parentKoulutusmuoto: PropTypes.object
};
