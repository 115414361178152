import { memo, Fragment } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import common from "i18n/definitions/common";
import education from "i18n/definitions/education";
import OpetuksenJarjestamismuoto from "./lomakeosiot/4-OpetuksenJarjestamismuoto";
import ErityisetKoulutustehtavat from "./lomakeosiot/5-ErityisetKoulutustehtavat";
import Opiskelijamaarat from "./lomakeosiot/6-Opiskelijamaarat";
import MuutEhdot from "./lomakeosiot/7-MuutEhdot";
import {
  assoc,
  filter,
  find,
  includes,
  map,
  path,
  pathEq,
  propEq
} from "ramda";
import equal from "react-fast-compare";
import { useLomakedata } from "stores/lomakedata";
import AsianumeroYmsKentat from "./lomakeosiot/0-AsianumeroYmsKentat";
import Rajoitteet from "components/02-organisms/Rajoitteet/index";
import { useChangeObjectsByAnchorWithoutUnderRemoval } from "stores/muutokset";
import Opetustehtavat from "./lomakeosiot/1-Opetustehtavat";
import Opetuskieli from "./lomakeosiot/3-Opetuskieli";
import ToimintaAlue from "./lomakeosiot/ToimintaAlue";
import {
  Process_opetuskieletContainer,
  Process_toimintaalueContainer
} from "graphs/storeHandling";
import { getRajoitteetBySection } from "utils/rajoitteetUtils";

// Kohdevaihtoehtoja käytetään rajoitteita tehtäessä.
// Kohteet vaihtelevat koulutusmuodoittain.
const rajoitteidenKohdevaihtoehdot = [
  {
    label: "Opetus, jota lupa koskee",
    value: "opetustehtavat"
  },
  {
    label: "Kunnat, joissa opetusta järjestetään",
    value: "toimintaalue"
  },
  { label: "Opetuskieli", value: "opetuskielet" },
  {
    label: "Opetuksen järjestämismuodot",
    value: "opetuksenJarjestamismuodot"
  },
  {
    label: "Erityinen koulutustehtävä",
    value: "erityisetKoulutustehtavat"
  },
  {
    label: "Opiskelijamäärät",
    value: "opiskelijamaarat"
  },
  {
    label: "Muut koulutuksen järjestämiseen liittyvät ehdot",
    value: "muutEhdot"
  },
  {
    label: "Oppilaitokset",
    value: "oppilaitokset"
  }
];

function filterByTunniste(tunniste, maaraykset = []) {
  return filter(pathEq(["kohde", "tunniste"], tunniste), maaraykset);
}

/**
 * Tämä lupanäkymä sisältää kaikki PO-lomakkeen osiot soveltuen siksi
 * erinomaisesti myös esikatselunäkymäksi.
 * @param {*} param0
 */
const Lupanakyma = memo(
  ({
    isPreviewModeOn,
    isRestrictionsModeOn,
    koulutustyyppi,
    maaraykset,
    notInitial,
    pc,
    rajoitemaaraykset,
    unsaved,
    validationErrors
  }) => {
    const intl = useIntl();

    const [rajoitepoistot] = useChangeObjectsByAnchorWithoutUnderRemoval({
      anchor: "rajoitepoistot"
    });

    const rajoitepoistoIds = map(
      rajoitepoisto => path(["properties", "rajoiteId"], rajoitepoisto),
      rajoitepoistot
    );

    const maarayksetRajoitepoistotFiltered = map(maarays => {
      /** Opiskelijamäärärajoitteen poisto poistaa koko määräyksen */
      if (
        maarays.koodisto === "kujalisamaareet" &&
        path(["maaraystyyppi", "tunniste"], maarays) === "RAJOITE" &&
        includes(path(["meta", "rajoiteId"], maarays), rajoitepoistoIds)
      ) {
        return null;
      }

      /** Muissa tapauksissa poistetaan vain alimääräykset */
      const alimaaraykset = filter(
        alimaarays =>
          !includes(path(["meta", "rajoiteId"], alimaarays), rajoitepoistoIds),
        maarays.aliMaaraykset || []
      );
      return assoc("aliMaaraykset", alimaaraykset, maarays);
    }, maaraykset || []).filter(Boolean);

    const [rajoitteetStateObj] = useLomakedata({ anchor: "rajoitteet" });

    const paattymispvm = path(
      ["properties", "value"],
      find(
        cObj => cObj.anchor === "paatoksentiedot.paattymispaivamaara.A",
        path(
          ["0"],
          useChangeObjectsByAnchorWithoutUnderRemoval({
            anchor: "paatoksentiedot"
          })
        ) || []
      )
    );

    const rajoitteetListausChangeObj = find(
      propEq("anchor", "rajoitteet.listaus.A"),
      rajoitteetStateObj
    );

    const rajoiteChangeObjsByRajoiteId = path(
      ["properties", "rajoitteet"],
      rajoitteetListausChangeObj
    );

    const opetustehtavamaaraykset = filterByTunniste(
      "opetusjotalupakoskee",
      maarayksetRajoitepoistotFiltered
    );

    const opetuksenJarjestamismuotomaaraykset = filterByTunniste(
      "opetuksenjarjestamismuoto",
      maarayksetRajoitepoistotFiltered
    );

    const opetuskieltenRajoitteet = getRajoitteetBySection(
      "opetuskielet",
      rajoiteChangeObjsByRajoiteId
    );

    const opetustehtavatRajoitteet = getRajoitteetBySection(
      "opetustehtavat",
      rajoiteChangeObjsByRajoiteId
    );

    const opetuksenJarjestamismuodotRajoitteet = getRajoitteetBySection(
      "opetuksenJarjestamismuodot",
      rajoiteChangeObjsByRajoiteId
    );

    const erityisetKoulutustehtavatRajoitteet = getRajoitteetBySection(
      "erityisetKoulutustehtavat",
      rajoiteChangeObjsByRajoiteId
    );

    const opiskelijamaaraRajoitteet = getRajoitteetBySection(
      "opiskelijamaarat",
      rajoiteChangeObjsByRajoiteId
    );

    const muutEhdotRajoitteet = getRajoitteetBySection(
      "muutEhdot",
      rajoiteChangeObjsByRajoiteId
    );

    const toimintaalueenRajoitteet = getRajoitteetBySection(
      "toimintaalue",
      rajoiteChangeObjsByRajoiteId
    );

    const asianumeroYmsClasses = isPreviewModeOn
      ? "md:w-1/2 xxl:w-1/3 pr-6 mb-6 mt-3"
      : "md:w-1/2 xxl:w-1/3 my-12";

    return (
      <Fragment>
        <form className={`bg-white ${isPreviewModeOn ? "" : ""}`}>
          {!(isPreviewModeOn && !paattymispvm) && (
            <div className="max-w-7xl mx-auto bg-white pb-8">
              <div className={asianumeroYmsClasses}>
                <AsianumeroYmsKentat
                  isPreviewModeOn={isPreviewModeOn}
                  validationErrors={validationErrors}
                />
              </div>
            </div>
          )}

          <Rajoitteet
            maaraykset={filter(
              maarays =>
                maarays.aliMaaraykset ||
                (maarays.koodisto === "kujalisamaareet" &&
                  path(["maaraystyyppi", "tunniste"], maarays) === "RAJOITE"),
              maaraykset || []
            )}
            pc={pc}
            rajoitemaaraykset={rajoitemaaraykset}
            isPreviewModeOn={isPreviewModeOn}
            isRestrictionsModeOn={isRestrictionsModeOn}
            kohdevaihtoehdot={rajoitteidenKohdevaihtoehdot}
            koulutustyyppi={koulutustyyppi}
            sectionId="rajoitteet"
            render={() => {
              return (
                <Fragment>
                  <div className="max-w-7xl mx-auto bg-white pb-8">
                    <Opetustehtavat
                      code="1"
                      isPreviewModeOn={isPreviewModeOn}
                      maaraykset={opetustehtavamaaraykset}
                      sectionId="opetustehtavat"
                      rajoitteet={opetustehtavatRajoitteet}
                    />
                  </div>

                  <div className="max-w-7xl mx-auto bg-white pb-8">
                    <Process_toimintaalueContainer>
                      <ToimintaAlue
                        isPreviewModeOn={isPreviewModeOn}
                        notInitial={notInitial}
                        pc={pc}
                        rajoitteet={toimintaalueenRajoitteet}
                        unsaved={unsaved}
                      />
                    </Process_toimintaalueContainer>
                  </div>

                  <Process_opetuskieletContainer scope="opetuskielet">
                    <Opetuskieli
                      code="3"
                      isPreviewModeOn={isPreviewModeOn}
                      pc={pc}
                      rajoitteet={opetuskieltenRajoitteet}
                      title={intl.formatMessage(common.opetuskieli)}
                    />
                  </Process_opetuskieletContainer>

                  <div className="max-w-7xl mx-auto bg-white pb-8">
                    <OpetuksenJarjestamismuoto
                      code="4"
                      isPreviewModeOn={isPreviewModeOn}
                      maaraykset={opetuksenJarjestamismuotomaaraykset}
                      rajoitteet={opetuksenJarjestamismuodotRajoitteet}
                      sectionId={"opetuksenJarjestamismuodot"}
                      title={intl.formatMessage(
                        education.opetuksenJarjestamismuoto
                      )}
                    />
                  </div>

                  <div className="max-w-7xl mx-auto bg-white pb-8">
                    <ErityisetKoulutustehtavat
                      code="5"
                      isPreviewModeOn={isPreviewModeOn}
                      maaraykset={filterByTunniste(
                        "erityinenkoulutustehtava",
                        maarayksetRajoitepoistotFiltered
                      )}
                      rajoitteet={erityisetKoulutustehtavatRajoitteet}
                      sectionId={"erityisetKoulutustehtavat"}
                      title={intl.formatMessage(
                        common.VSTLupaSectionTitleSchoolMissionSpecial
                      )}
                    />
                  </div>

                  <div className="max-w-7xl mx-auto bg-white pb-8">
                    <Opiskelijamaarat
                      code="6"
                      isPreviewModeOn={isPreviewModeOn}
                      maaraykset={filterByTunniste(
                        "oppilasopiskelijamaara",
                        maarayksetRajoitepoistotFiltered
                      )}
                      rajoitteet={opiskelijamaaraRajoitteet}
                      sectionId={"opiskelijamaarat"}
                      title={intl.formatMessage(
                        education.oppilasOpiskelijamaarat
                      )}
                    />
                  </div>

                  <div className="max-w-7xl mx-auto bg-white pb-8">
                    <MuutEhdot
                      code="7"
                      isPreviewModeOn={isPreviewModeOn}
                      maaraykset={filterByTunniste(
                        "muutkoulutuksenjarjestamiseenliittyvatehdot",
                        maarayksetRajoitepoistotFiltered
                      )}
                      rajoitteet={muutEhdotRajoitteet}
                      sectionId={"muutEhdot"}
                      title={intl.formatMessage(education.muutEhdotTitle)}
                    />
                  </div>
                </Fragment>
              );
            }}
          />
        </form>
      </Fragment>
    );
  },
  (cp, np) => {
    return equal(cp, np);
  }
);

Lupanakyma.propTypes = {
  isPreviewModeOn: PropTypes.bool,
  isRestrictionsModeOn: PropTypes.bool,
  koulutustyyppi: PropTypes.string,
  maaraykset: PropTypes.array,
  notInitial: PropTypes.array,
  OpetustaAntavatKunnatJSX: PropTypes.func,
  pc: PropTypes.object,
  rajoitemaaraykset: PropTypes.array,
  unsaved: PropTypes.array,
  valtakunnallinenMaarays: PropTypes.object,
  validationErrors: PropTypes.array
};

Lupanakyma.displayName = "Lupanäkymä (Esi- ja perusopetus).";

export default Lupanakyma;
