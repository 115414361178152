import { getRaw } from "basedata";
import { CellFn } from "graphHandling/graphTypes";
import { backendRoutes } from "stores/utils/backendRoutes";
import { CellId } from "processes/CellId";
import { assoc, prop } from "ramda";
import { initializeRajoitteet } from "../../../helpers/alimaaraykset";

export const fetch_lupaByUuid: CellFn = async (
  storeFunctions,
  intl,
  payload,
  result
) => {
  const muutospyynto = result[CellId.FETCH_MUUTOSPYYNTO];
  if (!muutospyynto || !muutospyynto.lupaUuid) {
    return null;
  }
  try {
    let lupa = await getRaw(
      "lupaByUuid",
      `${backendRoutes.lupaByUuid.path}${muutospyynto.lupaUuid}?with=all&useKoodistoVersions=false`,
      [],
      0.5
    );
    lupa = assoc(
      "rajoitteet",
      initializeRajoitteet(prop("maaraykset", lupa)),
      lupa
    );
    return lupa;
  } catch (err) {
    console.info(err);
    throw new Error(err as string);
  }
};
