import { toast } from "react-toastify";
import common from "i18n/definitions/common";

// muutospyynnot.tilanmuutos.korjaustilaanSiirtyminenEpaonnistui
export const korjaustilaanSiirtyminenEpaonnistui = formatMessage => ({
  label: "Ilmoitus epäonnistumisesta",
  input: ["output"],
  run: async ({ output }) => {
    if (output.status === 400 && output.result.code === "preparation") {
      toast.error(formatMessage(common.avoinMuutosasia), {
        autoClose: 10000,
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      toast.error(formatMessage(common.luvanKorjausEpaonnistui), {
        autoClose: 10000,
        position: toast.POSITION.TOP_RIGHT
      });
    }
    return true;
  }
});
