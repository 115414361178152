import PropTypes from "prop-types";
import { ReactElement } from "react";

const HtmlContent = ({ content }: { content: string }): ReactElement => {
  return (
    <div
      className="htmlContent"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

HtmlContent.propTypes = {
  content: PropTypes.string
};

export default HtmlContent;
