import moment from "moment";
import {
  compose,
  dissoc,
  find,
  flatten,
  groupBy,
  includes,
  last,
  map,
  nth,
  path,
  prop,
  propEq,
  split
} from "ramda";
import * as muutEhdotHelper from "helpers/muutEhdotTPO";
import * as opetustaAntavatKunnatHelper from "helpers/opetustaAntavatKunnat";
import * as opetuskieletHelper from "helpers/opetuskielet";
import * as oppilaitoksetHelper from "helpers/oppilaitokset";
import * as luvanTaiteenalatHelper from "helpers/luvanTaiteenalat";
import { ISO_DATE_FORMAT, koulutustyypitMap } from "utils/constants";
import { createBeObjsForRajoitepoistot } from "helpers/rajoitteetHelper";
import { getRajoitteetByKohde } from "utils/rajoitteetUtils";

export async function createObjectToSave(
  locale,
  organisation,
  lupa,
  changeObjects,
  uuid,
  kohteet,
  maaraystyypit,
  alkupera = "KJ",
  muutospyynnonTila
) {
  const allAttachmentsRaw = [];
  const koulutustyyppi = koulutustyypitMap.TAITEEN_PERUSOPETUS;

  // ... without tiedosto-property
  const allAttachments = map(attachment => {
    return dissoc("tiedosto", attachment);
  }, allAttachmentsRaw);

  const rajoitteetByRajoiteId = groupBy(
    compose(last, split("_"), nth(0), split("."), prop("anchor")),
    changeObjects.rajoitteet
  );

  // OPPILAITOKSET
  const oppilaitosmuutokset =
    await oppilaitoksetHelper.defineBackendChangeObjects(
      {
        oppilaitokset: changeObjects.oppilaitokset
      },
      find(propEq("tunniste", "oppilaitokset"), kohteet),
      maaraystyypit,
      lupa.maaraykset,
      locale,
      kohteet
    );

  // 1. LUVAN TAITEENALAT
  const luvanTaiteenalat =
    await luvanTaiteenalatHelper.defineBackendChangeObjects(
      {
        luvanTaiteenalat: changeObjects.luvanTaiteenalat,
        rajoitteetByRajoiteId: getRajoitteetByKohde(
          "luvanTaiteenalat",
          rajoitteetByRajoiteId
        )
      },
      find(propEq("tunniste", "luvantaiteenalat"), kohteet),
      maaraystyypit,
      lupa.maaraykset,
      locale,
      kohteet
    );

  // 2. KUNNAT, JOISSA OPETUSTA JÄRJESTETÄÄN
  let categoryFilterChangeObj =
    find(
      propEq("anchor", "toimintaalue.categoryFilter"),
      changeObjects.toimintaalue || []
    ) || {};

  const opetustaAntavatKunnat =
    await opetustaAntavatKunnatHelper.defineBackendChangeObjects(
      {
        quickFilterChanges: path(
          ["properties", "quickFilterChanges"],
          categoryFilterChangeObj
        ),
        changesByProvince: path(
          ["properties", "changesByProvince"],
          categoryFilterChangeObj
        ),
        lisatiedot: find(
          compose(includes(".lisatiedot."), prop("anchor")),
          changeObjects.toimintaalue || []
        ),
        rajoitteetByRajoiteId: getRajoitteetByKohde(
          "toimintaalue",
          rajoitteetByRajoiteId
        )
      },
      find(propEq("tunniste", "kunnatjoissaopetustajarjestetaan"), kohteet),
      maaraystyypit,
      lupa.maaraykset,
      kohteet,
      "kunnatjoissaopetustajarjestetaan"
    );

  // 2. MUUT KUNNAT, JOISTA OPPILAITA

  categoryFilterChangeObj =
    find(
      propEq("anchor", "muutKunnatJoistaOppilaita.categoryFilter"),
      changeObjects.muutKunnatJoistaOppilaita || []
    ) || {};

  const muutKunnatJoistaOppilaita =
    await opetustaAntavatKunnatHelper.defineBackendChangeObjects(
      {
        quickFilterChanges: path(
          ["properties", "quickFilterChanges"],
          categoryFilterChangeObj
        ),
        changesByProvince: path(
          ["properties", "changesByProvince"],
          categoryFilterChangeObj
        ),
        lisatiedot: find(
          compose(includes(".lisatiedot."), prop("anchor")),
          changeObjects.muutKunnatJoistaOppilaita || []
        ),
        rajoitteetByRajoiteId: getRajoitteetByKohde(
          "muutKunnatJoistaOppilaita",
          rajoitteetByRajoiteId
        )
      },
      find(propEq("tunniste", "kunnatjoissaopetustajarjestetaan"), kohteet),
      maaraystyypit,
      lupa.maaraykset,
      kohteet,
      "kunnatjoissaopetustajarjestetaan",
      "muutKunnatJoistaOppilaita"
    );

  // 3. OPETUSKIELET
  const opetuskielet = await opetuskieletHelper.defineBackendChangeObjects(
    {
      opetuskielet: changeObjects.opetuskielet,
      rajoitteetByRajoiteId: getRajoitteetByKohde(
        "opetuskielet",
        rajoitteetByRajoiteId
      )
    },
    find(propEq("tunniste", "opetuskieli"), kohteet),
    maaraystyypit,
    lupa.maaraykset,
    kohteet
  );

  // 4. MUUT EHDOT
  const muutEhdot = await muutEhdotHelper.defineBackendChangeObjects(
    {
      muutEhdot: changeObjects.muutEhdot,
      rajoitteetByRajoiteId: getRajoitteetByKohde(
        "muutEhdot",
        rajoitteetByRajoiteId
      )
    },
    maaraystyypit,
    lupa.maaraykset,
    kohteet,
    locale,
    find(propEq("tunniste", "muutehdot"), kohteet)
  );

  /** Luodaan rajoitepoistoihin liittyvät Backend muutosobjektit */
  const rajoitepoistot = createBeObjsForRajoitepoistot(
    changeObjects.rajoitepoistot,
    lupa.maaraykset,
    kohteet,
    maaraystyypit
  );

  let objectToSave = {
    alkupera,
    koulutustyyppi,
    jarjestajaOid: organisation.oid,
    jarjestajaYtunnus: organisation.ytunnus,
    luoja: sessionStorage.getItem("username"),
    // luontipvm: moment().valueOf(),
    luontipvm: moment().format(ISO_DATE_FORMAT),
    lupaUuid: lupa.uuid,
    // uuid: lupa.asiatyyppi.uuid,
    tila:
      muutospyynnonTila ||
      (alkupera === "ESITTELIJA" && uuid ? "VALMISTELUSSA" : "LUONNOS"),
    paivittaja: "string",
    paivityspvm: null,
    voimassaalkupvm: null,
    voimassaloppupvm: null, // TODO: find the correct value somehow,
    liitteet: allAttachments,
    meta: {},
    muutokset: flatten([
      luvanTaiteenalat,
      muutEhdot,
      muutKunnatJoistaOppilaita,
      opetuskielet,
      opetustaAntavatKunnat,
      oppilaitosmuutokset,
      rajoitepoistot
    ]),
    uuid
  };

  const asianumeroObj = find(
    propEq("anchor", "paatoksentiedot.asianumero.A"),
    changeObjects.paatoksentiedot || []
  );

  objectToSave.asianumero = asianumeroObj ? asianumeroObj.properties.value : "";

  const diaarinumeroObj = find(
    propEq("anchor", "paatoksentiedot.diaarinumero.A"),
    changeObjects.paatoksentiedot || []
  );

  objectToSave.diaarinumero = diaarinumeroObj
    ? diaarinumeroObj.properties.value
    : "";

  const paatospaivaObj = find(
    propEq("anchor", "paatoksentiedot.paatospaiva.A"),
    changeObjects.paatoksentiedot || []
  );

  objectToSave.paatospvm =
    paatospaivaObj && paatospaivaObj.properties.value
      ? moment(paatospaivaObj.properties.value).format(ISO_DATE_FORMAT)
      : "";
  const voimaantulopaivaObj = find(
    propEq("anchor", "paatoksentiedot.voimaantulopaiva.A"),
    changeObjects.paatoksentiedot || []
  );
  objectToSave.voimassaalkupvm =
    voimaantulopaivaObj && voimaantulopaivaObj.properties.value
      ? moment(voimaantulopaivaObj.properties.value).format(ISO_DATE_FORMAT)
      : "";
  const paattymispaivamaaraObj = find(
    propEq("anchor", "paatoksentiedot.paattymispaivamaara.A"),
    changeObjects.paatoksentiedot || []
  );
  objectToSave.voimassaloppupvm =
    paattymispaivamaaraObj && paattymispaivamaaraObj.properties.value
      ? moment(paattymispaivamaaraObj.properties.value).format(ISO_DATE_FORMAT)
      : "";

  // This helps the frontend to initialize the first four fields on form load.
  objectToSave.meta.paatoksentiedot = changeObjects.paatoksentiedot;

  return objectToSave;
}
