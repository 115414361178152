import "../i18n-config";
import { __ } from "i18n-for-browser";
import { find, propEq } from "ramda";

export default async function getFormStructureOfOppilaitoksenKoulutustehtava(
  { maaraykset },
  { isPreviewModeOn, isReadOnly }
) {
  const _isReadOnly = isPreviewModeOn || isReadOnly;
  const maarays = find(propEq("koodisto", "koulutustehtava"), maaraykset);
  return {
    isValid: true,
    structure: [
      {
        anchor: "oppilaitoksenKoulutustehtava",
        components: [
          {
            anchor: "A",
            name: "TextBox",
            properties: {
              isPreviewModeOn,
              isReadOnly: _isReadOnly,
              label: __("common.asianumero"),
              placeholder: __("common.VSTLupaSectionTitleSchoolMission"),
              value: maarays ? maarays.meta.kuvaus : ""
            }
          }
        ]
      }
    ]
  };
}
