import {
  append,
  compose,
  dropLast,
  equals,
  filter,
  isNil,
  reject,
  lensIndex,
  map,
  not,
  propEq,
  view
} from "ramda";
import { ComponentWithMetadata } from "utils/lomakkeet";
import { ChangeObjects, getChangeObjByAnchor } from "utils/muutokset";

/**
 * Asettaa isChecked-määreen arvoksi false jokaisen parametrina
 * annetun komponentin osalta.
 * @param componentsWithMetadata Taulukollinen metadatalla höystettyjä komponentteja.
 * Näiden komponenttien isChecked-määreet käydään läpi.
 * @param allComponentsWithMetadata Taulukollinen metadatalla höystettyjä komponentteja.
 * @param changeObjects Taulukollinen muutosobjekteja.
 * @param index Numero, jota käytetään funktion kutsuessa itseään. Oletusarvo 0.
 * @returns Taulukollinen muutosobjekteja.
 */
export function uncheckNodes(
  componentsWithMetadata: Array<ComponentWithMetadata>,
  allComponentsWithMetadata: Array<ComponentWithMetadata>,
  changeObjects: ChangeObjects = [],
  index = 0
): ChangeObjects {
  const node: ComponentWithMetadata = view(
    lensIndex(index),
    componentsWithMetadata
  );
  const categoryAnchor = dropLast(1, node.anchorParts);
  const childcomponentsWithMetadata = filter(_node => {
    return equals(dropLast(2, _node.anchorParts), categoryAnchor);
  }, allComponentsWithMetadata);
  const changeObj = getChangeObjByAnchor(node.fullAnchor, changeObjects);
  if (changeObj) {
    if (changeObj.properties.isChecked) {
      // Jos muutosobjekti on olemassa ja sen isChecked-määre on arvoltaan truthy, ja jos
      // komponentin alkuperäinen isChecked-määreen oletusarvo on falsy, tulee
      // muutosobjekti poistaa.
      if (!node.properties.isChecked) {
        changeObjects = filter(compose(not, propEq("anchor")(node.fullAnchor)))(
          changeObjects
        );
      } else {
        // Jos komponentin alkuperäinen isChecked-määreen arvo on truthy, on komponentin
        // muutosobjekti asetettava seuraavasti.
        changeObjects = map(_changeObj => {
          if (_changeObj.anchor !== changeObj.anchor) {
            return _changeObj;
          } else {
            // Komponentin muutosobjekti asetetaan tässä.
            return {
              anchor: changeObj.anchor,
              properties: { isChecked: false }
            };
          }
        }, changeObjects);
      }
    }
  } else if (node.properties.isChecked) {
    // Mikäli muutosobjektia ei ole olemassa ja komponentti on ruksattu, tulee luoda
    // uusi muutosobjekti määreellä isChecked: false.
    changeObjects = append(
      {
        anchor: node.fullAnchor,
        properties: reject(isNil)({
          metadata: node.properties.forChangeObject,
          isChecked: false
        })
      },
      changeObjects
    );
  }

  // Komponentin ns. jälkeläiset tulee huomioida myös, joten kutsutaan niille tätä
  // samaa funktiota.
  if (childcomponentsWithMetadata.length) {
    changeObjects = uncheckNodes(
      childcomponentsWithMetadata,
      allComponentsWithMetadata,
      changeObjects
    );
  }

  // Koska käsiteltäviä komponentteja on taulukollinen eli koska niitä voi olla useita,
  // kasvatetaan indexin arvoa ja käsitellään järjestyksessä seuraava komponentti kutsuen
  // tätä samaa funktiota sille.
  if (index < componentsWithMetadata.length - 1) {
    return uncheckNodes(
      componentsWithMetadata,
      allComponentsWithMetadata,
      changeObjects,
      index + 1
    );
  }

  return changeObjects;
}
