import { Fragment, ReactElement, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import common from "i18n/definitions/common";
import esiJaPerusopetus from "i18n/definitions/esiJaPerusopetus";
import moment from "moment";
import Typography from "@mui/material/Typography";
import PoOpetusJotaLupaKoskeeHtml from "./opetusJotaLupaKoskee";
import PoOpetuksenJarjestamismuotoHtml from "./opetuksenJarjestamismuoto";
import PoOpiskelijamaaratHtml from "./opiskelijamaarat";
import PoOpetuksenErityisetKoulutustehtavatHtml from "./erityisetKoulutustehtavat";
import PoOpetuksenMuutEhdotHtml from "./muutEhdot";
import { getVoimassaOlevatMaaraykset } from "helpers/muut";
import LupaLinkkiHTML, {
  LupaLinkkiTyyppi
} from "components/03-templates/HtmlLupanakyma/LupaLinkki";
import { DATE_FORMAT } from "utils/constants";
import { Lupa } from "Lupa";
import { IProcessCollection } from "graphHandling/graphProcessHandling";
import { CellId } from "processes/CellId";
import {
  cellImplementations,
  processDefinition
} from "graphs/jarjestajamislupaHtml/processDefinition";
import { concat } from "ramda";
import { trackingFunction } from "scenes/Koulutusmuodot/EsiJaPerusopetus";
import Loading from "scenes/Loading";
import OpetustaAntavatKunnat from "./OpetustaAntavatKunnat";
import Opetuskielet from "./Opetuskielet";

const fadeOutTime = 0;

type Props = {
  lupa: Lupa;
  pc: IProcessCollection;
};

/**
 * Funktio rakentaa esi- ja perusopetuksen HTML-lupanäkymän.
 * @param {*} lupa - Lupa, jonka tietoja hyödyntäen lupanäkymä muodostetaan.
 */
const JarjestamislupaJSX = ({ lupa, pc }: Props): ReactElement => {
  const { formatMessage } = useIntl();
  const [readyList, setReadyList] = useState<Array<any>>([]);
  const [results, setResults] = useState();
  const [fadeOut, setFadeOut] = useState<number>(0);
  const voimassaOlevatMaaraykset = getVoimassaOlevatMaaraykset(lupa.maaraykset);

  useEffect(() => {
    pc.addProcess(
      {
        actions: {},
        CellId,
        cellImplementations,
        customParams: {
          koulutustyyppi: "1"
        },
        processDefinition,
        trackingFunction: cellFnResults => {
          const reactElement = trackingFunction(
            cellFnResults,
            processDefinition
          );
          setReadyList(prevState => concat(prevState, reactElement));
        }
      },
      "JarjestamislupaHtml"
    );

    const runProcess = async () => {
      const outcome = await pc.handleIncomingProcessToken(
        CellId.INITIALIZE_PROCESS_JARJESTAMISLUPA_HTML,
        "JarjestamislupaHtml"
      );

      setFadeOut(fadeOutTime);

      setTimeout(() => {
        setResults(outcome);
      }, fadeOutTime);
    };

    runProcess();
  }, []);

  return results ? (
    <Fragment>
      <Typography component="h2" variant="h2">
        {formatMessage(common.htmlLuvanOtsikko, {
          date: moment().format(DATE_FORMAT),
          koulutusmuodon: formatMessage(
            esiJaPerusopetus.genetiivi
          ).toLowerCase()
        })}
      </Typography>
      {lupa.loppupvm && (
        <p className="mb-4">
          {formatMessage(common.onVoimassa, {
            loppupvm: moment(lupa.loppupvm).format(DATE_FORMAT)
          })}
        </p>
      )}
      <p className="mb-4">{formatMessage(esiJaPerusopetus.esittelyteksti)}</p>
      <LupaLinkkiHTML maaraavaLupa={lupa} tyyppi={LupaLinkkiTyyppi.ALILUPA} />
      <PoOpetusJotaLupaKoskeeHtml
        maaraykset={voimassaOlevatMaaraykset}
        opetustehtavat={results[CellId.KASITTELE_OPETUSTEHTAVAT]}
        opetustehtavatKoodisto={
          results[CellId.KASITTELE_KOODISTO_OPETUSTEHTAVAT]
        }
      />
      <OpetustaAntavatKunnat maaraykset={voimassaOlevatMaaraykset} />
      <Opetuskielet
        kieletOPH={results[CellId.KASITTELE_KIELET_OPH]}
        maaraykset={voimassaOlevatMaaraykset}
      />
      <PoOpetuksenJarjestamismuotoHtml maaraykset={voimassaOlevatMaaraykset} />
      <PoOpiskelijamaaratHtml maaraykset={lupa.maaraykset} />
      <PoOpetuksenErityisetKoulutustehtavatHtml
        erityisetKoulutustehtavatKoodisto={
          results[CellId.KASITTELE_KOODISTO_PO_ERITYISET_KOULUTUSTEHTAVAT]
        }
        maaraykset={voimassaOlevatMaaraykset}
      />
      <PoOpetuksenMuutEhdotHtml
        maaraykset={voimassaOlevatMaaraykset}
        muutEhdotKoodisto={results[CellId.KASITTELE_KOODISTO_PO_MUUT_EHDOT]}
      />
    </Fragment>
  ) : (
    <Loading fadeOut={fadeOut} readyList={readyList} />
  );
};

JarjestamislupaJSX.propTypes = {
  lupa: PropTypes.object.isRequired
};

export default JarjestamislupaJSX;
