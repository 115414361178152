import { ReactElement, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import ExpandableRow from "components/02-organisms/ExpandableRowRoot/ExpandableRow";
import { renderMultiTextBox } from "graphHandling/components/multiTextBox/rendering";
import { renderSimpleButton } from "graphHandling/components/simpleButton/rendering";
import { GraphStoreFunctions } from "graphHandling/graphTypes";
import { CellId } from "processes/CellId";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { path } from "ramda";
import { renderUndo3 } from "graphHandling/components/undo";
import { IProcessCollection } from "graphHandling/graphProcessHandling";
import {
  cellImplementations,
  processDefinition
} from "graphs/ammatillinen-koulutus/processDefinition";
import { useProcess_muutMuuMaarays } from "graphs/storeHandling";

const useStyles = makeStyles(() => ({
  noPadding: {
    padding: 0
  }
}));

const processId = "muutMuuMaarays";

const MuuMaarays = ({
  pc,
  items,
  localeUpper
}: {
  pc: IProcessCollection;
  items: Array<unknown>;
  localeUpper: string;
  maarayksetByKoodiarvo: Record<string, unknown>;
  storeFunctions: GraphStoreFunctions;
}): null | ReactElement => {
  const classes = useStyles();
  const [, actions] = useProcess_muutMuuMaarays();
  const [isExpanded, setIsExpanded] = useState(false);

  function toggleExpandableRow() {
    setIsExpanded(!isExpanded);
  }

  useEffect(() => {
    if (pc) {
      pc.addProcess(
        {
          actions,
          CellId,
          cellImplementations,
          processDefinition
        },
        processId
      );

      pc.handleIncomingProcessToken(CellId.INITIALIZE_GRAPH, processId);
    }

    return () => {
      actions.initialize();
    };
  }, []);

  const process = pc?.getProcess(processId);

  if (process) {
    const components = process.actions.readPath(["components"]);
    const kumoaMuutokset = components[CellId.INITIALIZE_KUMOA_MUUTOKSET];
    const multiTextBox = components[CellId.INITIALIZE_MULTI_TEXTBOX];
    const lisaaPainike = components[CellId.INITIALIZE_LISAA_PAINIKE];
    return kumoaMuutokset && multiTextBox && lisaaPainike ? (
      <ExpandableRow onToggle={toggleExpandableRow} isToggledOpen={isExpanded}>
        <Typography
          classes={{ root: classes.noPadding }}
          component="h4"
          variant="h4"
          data-slot="title">
          <span>{path([0, "metadata", localeUpper, "nimi"], items)}</span>
        </Typography>
        <div data-slot="info" className="mr-4">
          {renderUndo3(kumoaMuutokset, {
            [CellId.INITIALIZE_MULTI_TEXTBOX]: multiTextBox
          })}
        </div>
        <div data-slot="content" className="m-8">
          {renderMultiTextBox(multiTextBox)}

          <div className="mt-4">{renderSimpleButton(lisaaPainike)}</div>
        </div>
      </ExpandableRow>
    ) : null;
  }
  return null;
};

MuuMaarays.propTypes = {
  isReadOnly: PropTypes.bool,
  items: PropTypes.array,
  localeUpper: PropTypes.string,
  mode: PropTypes.string,
  pc: PropTypes.object,
  sectionId: PropTypes.string
};

export default MuuMaarays;
