import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import common from "i18n/definitions/common";
import { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import Lomake from "components/02-organisms/Lomake";
import PropTypes from "prop-types";
import {
  useChangeObjects,
  useChangeObjectsByAnchor,
  useChangeObjectsByAnchorWithoutUnderRemoval
} from "stores/muutokset";
import {
  all,
  endsWith,
  equals,
  filter,
  replace,
  split,
  join,
  remove,
  compose,
  find,
  forEach,
  partition,
  isEmpty,
  includes,
  map,
  path,
  pathEq,
  isNil
} from "ramda";
import {
  removeAnchorPart,
  getAnchorTailParts,
  getAnchorInit
} from "../../../utils/anchor";

const constants = {
  formLocation: ["rajoitteet", "rajoitedialogi"]
};

const Rajoitedialogi = ({
  kohdevaihtoehdot,
  koulutustyyppi,
  osioidenData,
  sectionId,
  parentSectionId,
  restrictionId
}) => {
  const lomakeAnchor = `${sectionId}_${restrictionId}`;
  const [state, actions] = useChangeObjects();
  const [canSaveRestrictions, setCanSaveRestrictions] = useState(false);
  const intl = useIntl();

  const [rajoitelomakeChangeObjs] = useChangeObjectsByAnchor({
    anchor: sectionId
  });

  const [changeObjects, { setChanges }] =
    useChangeObjectsByAnchorWithoutUnderRemoval({
      anchor: sectionId
    });

  useEffect(() => {
    // Rajoitteiden hyväksymisessä tarkistetaan onko rajoitteille sopivamäärä valintoja ja valinnoille arvoja
    // Tämä hoidetaan tarkistamalla hyväksyminen seuraavilla tiedoilla
    // 1. Löytyy vähintään yksi 1 muutosobjekti kohteelle tai valikolle
    // 2. Kaikissa muutos objekteissa pitää löytyä arvo value kentästä poislukien alkamis- ja päättymispäivä
    // 3. Jokaiselle arvo/valikko muutosobjektille löytyy vastakkainen arvo muutosobjekti

    const [changeObjectsWithDate, otherChangeObjects] = partition(cb => {
      return (
        cb.anchor.includes("alkamispaiva") ||
        cb.anchor.includes("paattymispaiva")
      );
    }, changeObjects);

    const [alkamispaivaChangeObjects, paattymispaivaChangeObjects] = partition(
      cb => {
        return includes("alkamispaiva", path(["anchor"], cb));
      },
      changeObjectsWithDate
    );

    // Päivämäärä muutoksilla täytyy olla vähintään alkamis- tai päättymispäivämäärä
    const isAllDateChangeObjectPairsValid = all(
      equals(true),
      map(date => {
        const anchorInit = getAnchorInit(date.anchor);
        const paattymispaivaChangeObject = find(
          pathEq(["anchor"], `${anchorInit}.paattymispaiva`),
          paattymispaivaChangeObjects
        );
        const alkamispaivaChangeObject = find(
          pathEq(["anchor"], `${anchorInit}.alkamispaiva`),
          alkamispaivaChangeObjects
        );
        const alkupvmValue = path(
          ["properties", "value"],
          alkamispaivaChangeObject
        );
        const loppupvmValue = path(
          ["properties", "value"],
          paattymispaivaChangeObject
        );
        return (
          (!isNil(alkupvmValue) && !isEmpty(alkupvmValue)) ||
          (!isNil(loppupvmValue) && !isEmpty(loppupvmValue))
        );
      }, changeObjectsWithDate)
    );

    const kohdeOrValikkoChangeObjects = otherChangeObjects.filter(cb => {
      const anchorSplitted = getAnchorTailParts(cb.anchor);
      const lastPart = anchorSplitted[anchorSplitted.length - 1];
      return lastPart === "kohde" || lastPart === "valikko";
    });

    const tarkenninChangeObjects = otherChangeObjects.filter(cb => {
      return (
        cb.anchor.includes("tarkennin.komponentti") ||
        cb.anchor.includes("tarkennin.lukumaara")
      );
    });

    const dateObjectsPairsCount =
      !isEmpty(changeObjectsWithDate) && isAllDateChangeObjectPairsValid
        ? 1
        : 0;

    const changeObjectWithValues = otherChangeObjects.filter(cb => {
      return Array.isArray(cb.properties.value)
        ? cb.properties.value.length > 0
        : cb.properties.value !== "";
    });

    const kohdeAChangeObjects = otherChangeObjects.filter(cb => {
      return cb.anchor.includes("kohde.A");
    });

    const equalAmountOfTarkkenninAndKohdeChangeObjects =
      tarkenninChangeObjects.length + dateObjectsPairsCount ===
      kohdeOrValikkoChangeObjects.length;

    const atleastSingleAsetuksetChangeObject =
      otherChangeObjects.filter(cb => {
        const anchorSplitted = getAnchorTailParts(cb.anchor);
        const lastPart = anchorSplitted[anchorSplitted.length - 1];
        return lastPart === "kohde";
      }).length > 0;

    const equalAmountOfChangeObjectWithValuesAndChangeObjects =
      changeObjectWithValues.length + kohdeAChangeObjects.length ===
      otherChangeObjects.length;

    setCanSaveRestrictions(
      isAllDateChangeObjectPairsValid &&
        atleastSingleAsetuksetChangeObject &&
        equalAmountOfChangeObjectWithValuesAndChangeObjects &&
        equalAmountOfTarkkenninAndKohdeChangeObjects
    );
  }, [changeObjects, setCanSaveRestrictions]);

  const lisaaKohdennus = useCallback(
    ({ metadata }) => {
      actions.lisaaKohdennus(
        sectionId,
        metadata.kohdennusId,
        metadata.rajoiteId,
        metadata.kohdennusindeksipolku
      );
    },
    [actions, sectionId]
  );

  const onAddCriterion = useCallback(
    ({ metadata }) => {
      actions.addCriterion(
        sectionId,
        metadata.kohdennusId,
        metadata.rajoiteId,
        metadata.kohdennusindeksipolku
      );
    },
    [actions, sectionId]
  );

  const onRemoveCriterion = useCallback(
    anchor => {
      actions.removeCriterion(sectionId, anchor);
    },
    [actions, sectionId]
  );

  const handleSaveChanges = useCallback(
    (nextChangeObjects, lomakeAnchor) => {
      if (!equals(nextChangeObjects, changeObjects)) {
        // Mikäli päivämäärä kenttiä on asetettu rajoittelle ja määräaika valintaa ei,
        // poistetaan päivämäärä tiedot rajoite muutos objekteista.
        const dateRestrictionsSelectionChangeObjects = filter(cb => {
          return (
            cb.anchor.includes("paattymispaiva") ||
            cb.anchor.includes("alkamispaiva")
          );
        })(nextChangeObjects);

        forEach(dateChangeObject => {
          // Poistetaan päivämäärä muutosobjektista alkuosuus ja loppuosuus pois
          let anchorTail = removeAnchorPart(dateChangeObject.anchor, 0);
          const anchorStructure = compose(
            replace(".tarkennin.alkamispaiva", ""),
            replace(".tarkennin.paattymispaiva", "")
          )(anchorTail);

          // Jokaiselle alkamispaiva ja paattymispaiva muutos objektille pitäisi löytyä määräaika muutosobjekti
          const dateRestrictionsCb = filter(cb => {
            return (
              cb.properties.value.label === "määräaika" &&
              cb.anchor.includes(anchorStructure)
            );
          })(nextChangeObjects);

          // Mikäli määräaika muutosobjektia ei löydy poistetaan pvm muutos objektit muutosobkektilistalta
          if (dateRestrictionsCb.length === 0) {
            nextChangeObjects = filter(changeObject => {
              return (
                changeObject.anchor.includes(anchorStructure) &&
                (changeObject.anchor.includes("paattymispaiva") ||
                  changeObject.anchor.includes("alkamispaiva"))
              );
            })(nextChangeObjects);
          }
        })(dateRestrictionsSelectionChangeObjects);

        setChanges(nextChangeObjects, lomakeAnchor);
      }
    },
    [changeObjects]
  );

  // Tässä on tarkoitus poistaa tietyt rajoitteita koskevat muutosobjektit
  // tilanteessa jossa kohdetta on vaihdettu. Funktion ollessa käytössä
  // Lomake-komponentti ei päivitä muutosobjekteja, vaan se on tämän
  // funktion tehtävä.
  const runInsteadOfSettingChanges = useCallback(
    (changes, anchor, latestTargetNode) => {
      if (latestTargetNode) {
        const { original } = latestTargetNode;

        if (original.anchor === "valikko") {
          // Käsittelee muutos objektien poistoja kun ankkurilla "valikko" syntyy muutos käyttöliittymällä

          const anchorOfKohteenTarkennin = replace(
            ".valikko",
            ".tarkennin.komponentti",
            original.fullAnchor
          );
          const nextChangeObjects = filter(changeObj => {
            // Etsitään kohteen tarkenninta koskeva muutosobjekti,
            // jotta se voidaan poistaa tallennettavien muutosten joukosta.
            const isKohteenTarkenninChangeObj = endsWith(
              anchorOfKohteenTarkennin,
              changeObj.anchor
            );

            // Muodostetaan muutosobjektin ankkurista uusi objekti josta poistetaan alusta identifioiva komponentti id
            const anchorTail = removeAnchorPart(changeObj.anchor, 0);

            // Poistaa kaikki muutos objektit paitsi ankkurilla "valikko" kun pohjatason valikosta vaihtaa tietoja.
            let rootLevelValikkomuutos = false;
            if (original.level === 3) {
              rootLevelValikkomuutos = original.fullAnchor != anchorTail;
            }
            // Poistaa seuraavat muutos objektit ->
            // - Kohteen tarkenninta koskevan muutosobjektin
            // - Pohjatason valikkoa vaihtaessa kaikki muut muutosobjektit
            return !isKohteenTarkenninChangeObj && !rootLevelValikkomuutos;
          }, changes);

          if (!equals(nextChangeObjects, changeObjects)) {
            handleSaveChanges(nextChangeObjects, lomakeAnchor);
          }
        } else if (original.anchor === "kohde") {
          // Käsittelee muutos objektien poistoja kun ankkurilla "kohde" syntyy muutos käyttöliittymällä

          // Poistaa ankkurimerkkijonosta viimeisein polun osuuden joka on tässä tapauksessa aina "kohde"
          const anchorSplitted = split(".", original.fullAnchor);
          const anchorOfRajoiteKriteeri = join(
            ".",
            remove(anchorSplitted.length - 1, 1, anchorSplitted)
          );

          const nextChangeObjects = filter(changeObj => {
            // Muodostetaan muutosobjektin ankkurista uusi objekti josta poistetaan alusta identifioiva komponentti id
            const anchorTail = removeAnchorPart(changeObj.anchor, 0);

            // Kohteelle voi olla ankkurin lopusta erilaiset osuudet ->
            // - komponentti = alasvetovalikko erilaisista arvoista
            // - lukumaara = input johon voi kirjottaa numeraalisen arvon
            const anchorOfRajoiteTarkenninKomponentti = replace(
              ".tarkennin.komponentti",
              "",
              anchorTail
            );

            const anchorOfRajoiteTarkenninLukumaara = replace(
              ".tarkennin.lukumaara",
              "",
              anchorTail
            );

            // Poistetaan kohteen tarkentimen muutosobjekti joka koskee kohdetta
            return anchorOfRajoiteKriteeri ===
              anchorOfRajoiteTarkenninKomponentti ||
              anchorOfRajoiteKriteeri === anchorOfRajoiteTarkenninLukumaara
              ? false
              : true;
          }, changes);
          if (!equals(nextChangeObjects, changeObjects)) {
            handleSaveChanges(nextChangeObjects, lomakeAnchor);
          }
        } else {
          handleSaveChanges(changes, anchor);
        }
      }
    },
    [changeObjects]
  );

  const revertChangesAndCloseDialog = () => {
    actions.closeRestrictionDialog();
  };

  const acceptChangesAndCloseDialog = () => {
    actions.acceptRestriction(sectionId, restrictionId, parentSectionId);
  };

  return (
    <Dialog
      open={state.isRestrictionDialogVisible}
      PaperProps={{
        style: {
          overflowY: "auto",
          minWidth: "32rem",
          height: "100%",
          width: "80%",
          maxWidth: "88rem"
        }
      }}>
      <DialogTitle onClose={actions.closeRestrictionDialog}>
        Lisää rajoite luvalle
      </DialogTitle>
      <DialogContent style={{ overflowY: "visible" }}>
        {/* <Typography component="p" variant="p">
          Aloita valitsemalla rajoituksen kohde. Valinnan jälkeen voit tehdä
          tarvittavat rajoitukset haluamallasi tavalla
        </Typography> */}
        <div className="m-10">
          <Lomake
            anchor={lomakeAnchor}
            changeObjects={changeObjects}
            data={{
              kohdevaihtoehdot,
              koulutustyyppi,
              osioidenData,
              rajoiteId: restrictionId,
              sectionId
            }}
            functions={{
              lisaaKohdennus,
              onAddCriterion,
              onRemoveCriterion
            }}
            isInExpandableRow={false}
            isSavingState={false}
            mode={"modification"}
            runInsteadOfSettingChanges={runInsteadOfSettingChanges}
            path={constants.formLocation}
            showCategoryTitles={true}></Lomake>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="flex pr-6 pb-4">
          <div className="mr-4">
            <Button
              onClick={() => revertChangesAndCloseDialog()}
              color="primary"
              variant="outlined">
              {intl.formatMessage(common.cancel)}
            </Button>
          </div>
          <Button
            onClick={() => {
              acceptChangesAndCloseDialog(rajoitelomakeChangeObjs);
            }}
            disabled={!canSaveRestrictions}
            color="primary"
            variant="contained">
            {intl.formatMessage(common.accept)}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

Rajoitedialogi.propTypes = {
  kohdevaihtoehdot: PropTypes.array,
  koulutustyyppi: PropTypes.string,
  osioidenData: PropTypes.object,
  parentSectionId: PropTypes.string,
  restrictionId: PropTypes.string,
  sectionId: PropTypes.string
};

export default Rajoitedialogi;
