import { CellImplementations } from "graphHandling/graphTypes";
import { CellId } from "processes/CellId";

// Funktiot, jotka määrittävät graafin solmujen sisällön.
import { fetch_lupaJaMuutospyynto } from "../fetch_lupaJaMuutospyynto";
import { initialize_lisaaPainike } from "./cellImplementations/initialize_lisaaPainike";
import { initialize_multiTextBox } from "./cellImplementations/initialize_multiTextBox";
import { update_multiTextBox } from "./cellImplementations/update_multiTextBox";
import initialize_kumoaMuutokset from "./cellImplementations/initialize_kumoaMuutokset";
import { create_textBox } from "./cellImplementations/create_textBox";

const mainProcessId = "000";

export const processDefinition = {
  cells: {
    [CellId.INITIALIZE_GRAPH]: {
      isFirstCell: true,
      name: "Start",
      out: {
        [CellId.FETCH_LUPA_JA_MUUTOSPYYNTO]: {},
        [CellId.INITIALIZE_KUMOA_MUUTOKSET]: {}
      }
    },
    [CellId.CREATE_TEXTBOX]: {
      name: "Create: TextBox",
      out: {
        [CellId.UPDATE_MAIN_PROCESS]: {
          processId: mainProcessId
        }
      }
    },
    [CellId.FETCH_LUPA_JA_MUUTOSPYYNTO]: {
      name: "Fetch: Lupa",
      out: {
        [CellId.INITIALIZE_LISAA_PAINIKE]: {},
        [CellId.INITIALIZE_MULTI_TEXTBOX]: {}
      }
    },
    [CellId.INITIALIZE_KUMOA_MUUTOKSET]: {
      name: "Initialize: Kumoa muutokset",
      out: {
        [CellId.UPDATE_MAIN_PROCESS]: {
          events: ["onClick"],
          processId: mainProcessId
        }
      }
    },
    [CellId.INITIALIZE_LISAA_PAINIKE]: {
      name: "Initialize: Lisää-painike",
      out: {
        [CellId.CREATE_TEXTBOX]: {
          events: ["onClick"]
        },
        [CellId.UPDATE_MAIN_PROCESS]: {
          processId: mainProcessId
        }
      }
    },
    [CellId.INITIALIZE_MULTI_TEXTBOX]: {
      name: "Initialize: MultiTextBox",
      out: {
        [CellId.UPDATE_MULTI_TEXTBOX]: {
          events: ["onChange"]
        }
      }
    },
    [CellId.UPDATE_MULTI_TEXTBOX]: {
      name: "Update: MultiTextBox",
      out: {
        [CellId.UPDATE_MAIN_PROCESS]: {
          processId: mainProcessId
        }
      }
    }
  }
};

export const cellImplementations: CellImplementations = {
  [CellId.CREATE_TEXTBOX]: create_textBox,
  [CellId.FETCH_LUPA_JA_MUUTOSPYYNTO]: fetch_lupaJaMuutospyynto,
  [CellId.INITIALIZE_KUMOA_MUUTOKSET]: initialize_kumoaMuutokset,
  [CellId.INITIALIZE_LISAA_PAINIKE]: initialize_lisaaPainike,
  [CellId.INITIALIZE_MULTI_TEXTBOX]: initialize_multiTextBox,
  [CellId.UPDATE_MULTI_TEXTBOX]: update_multiTextBox
};
