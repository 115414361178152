import { defineAlert } from "graphHandling/components/alert/definitions/customizable";
import { CellFn } from "graphHandling/graphTypes";
import tuva from "i18n/definitions/tuva";
import { CellId } from "processes/CellId";

export const ilmoitusAvoimestaAsiasta: CellFn = (
  storeFunctions,
  intl
): boolean => {
  const ilmoitus = defineAlert({
    isVisible: true,
    message: intl?.formatMessage(tuva.ilmoitusTuvaLuvanOlemassaOlosta),
    isClosable: false,
    sx: { bgcolor: "#fefcf4" },
    title: intl?.formatMessage(tuva.ilmoitusTuvaLuvanOlemassaOlostaOtsikko)
  });

  storeFunctions.updateGraph(
    ["components", CellId.SHOW_AVOIN_ASIAILMOITUS],
    ilmoitus
  );

  return true;
};
