import { getRajoitteetFromMaarays } from "utils/rajoitteetUtils";
import { CellFn } from "graphHandling/graphTypes";
import { Maarays } from "koodistodatanTyypit";
import { Lupa } from "Lupa";
import { isNil, length, map, reject } from "ramda";
import { Koodisto } from "enums";

import rajoitteet from "i18n/definitions/rajoitteet";
import { getVoimassaOlevatMaaraykset } from "../../../../helpers/muut";

const initialize_restrictions: CellFn = async (storeFunctions, intl) => {
  if (!intl) {
    return false;
  }

  const lupa = storeFunctions.readPath(["custom", "viimeisinLupa"]) as Lupa;
  let restrictions: Array<unknown> = [];

  if (lupa) {
    const { maaraykset } = lupa;

    const kuntaJaMaakuntamaaraykset: Array<Maarays> = reject(
      isNil,
      map(maarays => {
        return maarays.koodisto === Koodisto.MAAKUNTA ||
          (maarays.koodisto === Koodisto.KUNTA && maarays.koodiarvo !== "200")
          ? maarays
          : null;
      }, maaraykset)
    );

    const idsOfRemovedRestrictions = storeFunctions.readPath([
      "custom",
      "idsOfRemovedRestrictions"
    ]) as Array<string> | undefined;

    restrictions = reject(
      isNil,
      map((maarays: Maarays) => {
        const restriction: string | null =
          maarays.aliMaaraykset && length(maarays.aliMaaraykset)
            ? getRajoitteetFromMaarays(
                maarays.aliMaaraykset,
                intl.locale,
                intl.formatMessage(rajoitteet.ajalla),
                "nimi",
                true,
                null,
                idsOfRemovedRestrictions
              )
            : null;

        return restriction
          ? {
              restrictionString: restriction,
              koodiarvo: maarays.koodiarvo
            }
          : null;
      }, getVoimassaOlevatMaaraykset(kuntaJaMaakuntamaaraykset))
    );
  }

  storeFunctions.updateGraph(["custom"], {
    restrictions
  });

  return true;
};

export default initialize_restrictions;
