import {
  any,
  compose,
  concat,
  equals,
  filter,
  find,
  flatten,
  map,
  mapObjIndexed,
  path,
  prop,
  propEq,
  startsWith,
  toLower,
  values
} from "ramda";
import localForage from "localforage";
import { createAlimaarayksetBEObjects } from "helpers/rajoitteetHelper";
import {
  createMaarayksiaVastenLuodutRajoitteetBEObjects,
  getRajoitteetByValue
} from "utils/rajoitteetUtils";
import { getMaarayksetByTunniste } from "helpers/lupa";
import { PaikallisenTietovarastonAvain } from "enums";
import { luoMuutosobjektitLisatietokentasta } from "helpers/lisatiedot";

export const defineBackendChangeObjects = async (
  changeObjects = {},
  kohde,
  maaraystyypit,
  lupaMaaraykset,
  kohteet
) => {
  const maaraykset = await getMaarayksetByTunniste(
    kohde.tunniste,
    lupaMaaraykset
  );
  const opetuskielet = await localForage.getItem(
    PaikallisenTietovarastonAvain.ENSISIJAISET_OPETUSKIELET_OPH
  );

  const { rajoitteetByRajoiteId } = changeObjects;

  const opetuskieletChangeObjs = filter(
    compose(startsWith("opetuskielet.opetuskieli"), prop("anchor")),
    changeObjects.opetuskielet
  );

  const lukioKohde = find(propEq("tunniste", "opetuskieli"), kohteet);
  const vstKohde = find(propEq("tunniste", "kielet"), kohteet);

  const kohdeObj = lukioKohde || vstKohde;

  // Lisätietokentän muutosten käsittely
  const lisatietomuutokset = await luoMuutosobjektitLisatietokentasta(
    kohde,
    changeObjects.opetuskielet
  );

  /** Opetuskielten käsittely */
  const opetuskieliBeChangeObjects = map(opetuskieli => {
    const changeObj = find(
      cObj =>
        find(
          kieli => kieli.value === opetuskieli.koodiarvo,
          cObj.properties.value
        ),
      opetuskieletChangeObjs
    );

    const rajoitteetByRajoiteIdAndKoodiarvo = getRajoitteetByValue(
      opetuskieli.koodiarvo,
      rajoitteetByRajoiteId
    );

    const maarays = find(
      maarays =>
        maarays.koodiarvo.toLowerCase() === opetuskieli.koodiarvo.toLowerCase(),
      maaraykset
    );

    const isValikkoChanged = any(change => {
      return equals(
        path(["properties", "metadata", "valikko"], change),
        path(["meta", "valikko"], maarays)
      );
    }, opetuskieletChangeObjs);

    let muutosobjekti = null;

    if (changeObj && !maarays) {
      // Kokonaan uusi kielivalinta
      muutosobjekti = {
        generatedId: `opetuskielet-${Math.random()}`,
        kohde: kohdeObj,
        koodiarvo: toLower(opetuskieli.koodiarvo),
        koodisto: opetuskieli.koodisto.koodistoUri,
        maaraystyyppi: find(propEq("tunniste", "OIKEUS"), maaraystyypit),
        meta: {
          changeObjects: concat(
            [changeObj],
            values(rajoitteetByRajoiteIdAndKoodiarvo)
          ),
          ...(changeObj
            ? {
                valikko: path(["properties", "metadata", "valikko"], changeObj)
              }
            : null)
        },
        tila: "LISAYS"
      };
    } else if (maarays && !changeObj && isValikkoChanged) {
      // Luvalta löytyvä kielivalinta jonka valikko on muuttunut, mutta ei ole muutosobjektia.
      muutosobjekti = {
        generatedId: `opetuskielet-${Math.random()}`,
        kohde: kohdeObj,
        koodiarvo: toLower(opetuskieli.koodiarvo),
        koodisto: opetuskieli.koodisto.koodistoUri,
        maaraystyyppi: find(propEq("tunniste", "OIKEUS"), maaraystyypit),
        meta: {
          changeObjects: opetuskieletChangeObjs,
          ...(changeObj
            ? {
                valikko: path(["properties", "metadata", "valikko"], changeObj)
              }
            : null)
        },
        maaraysUuid: maarays.uuid,
        tila: "POISTO"
      };
    }

    // Muodostetaan tehdyistä rajoittuksista objektit backendiä varten.
    // Linkitetään ensimmäinen rajoitteen osa yllä luotuun muutokseen ja
    // loput toisiinsa "alenevassa polvessa".
    const alimaaraykset = muutosobjekti
      ? values(
          mapObjIndexed(asetukset => {
            return createAlimaarayksetBEObjects(
              kohteet,
              maaraystyypit,
              muutosobjekti,
              asetukset
            );
          }, rajoitteetByRajoiteIdAndKoodiarvo)
        )
      : [];

    return [muutosobjekti, alimaaraykset];
  }, opetuskielet);

  const maarayksiaVastenLuodutRajoitteet =
    createMaarayksiaVastenLuodutRajoitteetBEObjects(
      maaraykset,
      rajoitteetByRajoiteId,
      kohteet,
      maaraystyypit,
      kohde
    );

  return flatten([
    opetuskieliBeChangeObjects,
    lisatietomuutokset,
    maarayksiaVastenLuodutRajoitteet
  ]).filter(Boolean);
};
