import moment from "moment";
import { getKoulutusmuotoByPredicate } from "utils/common";
import {
  compose,
  filter,
  find,
  groupBy,
  includes,
  last,
  nth,
  path,
  prop,
  propEq,
  split,
  flatten
} from "ramda";
import { ISO_DATE_FORMAT, koulutustyypitMap } from "utils/constants";
import * as opetustaAntavatKunnatHelper from "helpers/opetustaAntavatKunnat";
import * as opetuskieletHelper from "../../../helpers/opetuskielet";
import * as opiskelijamaaratHelper from "helpers/opiskelijamaarat";
import { backendRoutes } from "stores/utils/backendRoutes";
import { getRaw } from "../../../basedata";
import { luoMuutosobjektitLisatietokentasta } from "helpers/lisatiedot";
import { createBeObjsForRajoitepoistot } from "helpers/rajoitteetHelper";
import { getRajoitteetByKohde } from "utils/rajoitteetUtils";

export async function createObjectToSave(
  koulutusmuotoKebabCase,
  valittuKj,
  kieli,
  intl,
  organisation,
  lupa,
  changeObjects,
  uuid,
  kohteet,
  maaraystyypit,
  alkupera = "KJ",
  muutospyynnonTila
) {
  const koulutusmuoto = getKoulutusmuotoByPredicate(
    propEq("kebabCase", koulutusmuotoKebabCase),
    intl
  );

  const paasivunOtsikko = prop("paasivunOtsikko", koulutusmuoto);
  const parentKoulutusTyyppi = prop("koulutustyyppi", koulutusmuoto);

  let luvanKieli = kieli;

  let backEndRoute;
  switch (parentKoulutusTyyppi) {
    case koulutustyypitMap.LUKIO:
      backEndRoute = `${backendRoutes.viimeisinLupa.path}${valittuKj}/viimeisin?koulutustyyppi=${parentKoulutusTyyppi}&kieli=${kieli}`;
      break;
    case koulutustyypitMap.AMMATILLINEN_KOULUTUS:
      luvanKieli = null;
      backEndRoute = `${backendRoutes.viimeisinLupa.path}${valittuKj}/viimeisin`;
      break;
    default:
      luvanKieli = null;
      backEndRoute = `${backendRoutes.viimeisinLupa.path}${valittuKj}/viimeisin?koulutustyyppi=${parentKoulutusTyyppi}`;
  }
  const viimeisinLupa = backEndRoute
    ? await getRaw("viimeisinLupa", backEndRoute, [], 0)
    : null;

  const koulutustyyppi = koulutustyypitMap.TUVA;
  const rajoitteetByRajoiteId = groupBy(
    compose(last, split("_"), nth(0), split("."), prop("anchor")),
    changeObjects.rajoitteet
  );
  const lupaMaaraykset = prop("maaraykset", lupa) || [];

  // OPETUSKIELET
  const opetuskielet = changeObjects.eiNoudatetaOpetuskielet
    ? await opetuskieletHelper.defineBackendChangeObjects(
        {
          opetuskielet: changeObjects.opetuskielet,
          rajoitteetByRajoiteId: getRajoitteetByKohde(
            "opetuskielet",
            rajoitteetByRajoiteId
          )
        },
        find(propEq("tunniste", "opetuskieli"), kohteet),
        maaraystyypit,
        lupaMaaraykset,
        kohteet
      )
    : [];

  // KUNNAT, JOISSA OPETUSTA JÄRJESTETÄÄN
  const categoryFilterChangeObj =
    find(
      propEq("anchor", "toimintaalue.categoryFilter"),
      changeObjects.toimintaalue || []
    ) || {};

  const opetustaAntavatKunnat = changeObjects.eiNoudatetaToimintaAlue
    ? await opetustaAntavatKunnatHelper.defineBackendChangeObjects(
        {
          quickFilterChanges: path(
            ["properties", "quickFilterChanges"],
            categoryFilterChangeObj
          ),
          changesByProvince: path(
            ["properties", "changesByProvince"],
            categoryFilterChangeObj
          ),
          lisatiedot: find(
            compose(includes(".lisatiedot."), prop("anchor")),
            changeObjects.toimintaalue || []
          ),
          ulkomaa: filter(
            compose(includes(".200."), prop("anchor")),
            changeObjects.toimintaalue || []
          ),
          rajoitteetByRajoiteId: getRajoitteetByKohde(
            "toimintaalue",
            rajoitteetByRajoiteId
          )
        },
        find(propEq("tunniste", "toimintaalue"), kohteet),
        maaraystyypit,
        lupa.maaraykset,
        kohteet,
        "kunnatjoissaopetustajarjestetaan"
      )
    : [];

  const maaratVuodet = changeObjects.eiNoudatetaMaaratVuodet
    ? await opiskelijamaaratHelper.defineBackendChangeObjects(
        {
          opiskelijamaarat: changeObjects.maaratVuodet,
          rajoitteetByRajoiteId: getRajoitteetByKohde(
            "opiskelijamaarat",
            rajoitteetByRajoiteId
          )
        },
        maaraystyypit,
        kohteet,
        koulutustyyppi
      )
    : [];

  // LISÄTIEDOT
  const lisatiedot = find(
    lisatiedot => lisatiedot.tila === "LISAYS",
    await luoMuutosobjektitLisatietokentasta(
      find(propEq("tunniste", "opetuskieli"), kohteet),
      changeObjects.lisatiedot
    )
  );

  /** Luodaan rajoitepoistoihin liittyvät Backend muutosobjektit */
  const rajoitepoistot = createBeObjsForRajoitepoistot(
    changeObjects.rajoitepoistot,
    lupa.maaraykset,
    kohteet,
    maaraystyypit
  );

  const objectToSave = {
    alkupera,
    kieli: luvanKieli,
    koulutustyyppi,
    jarjestajaOid: organisation.oid,
    jarjestajaYtunnus: organisation.ytunnus,
    luoja: sessionStorage.getItem("username"),
    // luontipvm: moment().valueOf(),
    luontipvm: moment().format(ISO_DATE_FORMAT),
    lupaUuid: lupa.uuid,
    // uuid: lupa.asiatyyppi.uuid,
    tila:
      muutospyynnonTila ||
      (alkupera === "ESITTELIJA" && uuid ? "VALMISTELUSSA" : "LUONNOS"),
    paivittaja: "string",
    paivityspvm: null,
    voimassaalkupvm: null,
    voimassaloppupvm: null, // TODO: find the correct value somehow,
    liitteet: [],
    meta: {
      // Tietoa tarvitaan HTML-lupanäkymässä, jossa on kerrottava
      // käyttäjälle, mihin koulutusmuotoon TUVA-lupa liittyy.
      koulutusmuodonPaasivunOtsikko: paasivunOtsikko,
      // Tietoa hyödynnetään ainakin linkissä, jolla siirrytään korjaamaan
      // lupaa.
      koulutusmuotoKebabCase,
      opetuskieletEsivalinnat: changeObjects.opetuskieletEsivalinnat,
      toimintaAlueEsivalinnat: changeObjects.toimintaAlueEsivalinnat,
      maaratVuodetEsivalinnat: changeObjects.maaratVuodetEsivalinnat,
      lisatiedot: lisatiedot ? lisatiedot.meta : []
    },
    muutokset: flatten([
      opetuskielet,
      opetustaAntavatKunnat,
      maaratVuodet,
      rajoitepoistot
    ]).filter(Boolean),
    uuid,
    parentLupa: {
      uuid: viimeisinLupa.uuid
    }
  };

  const asianumeroObj = find(
    propEq("anchor", "paatoksentiedot.asianumero.A"),
    changeObjects.paatoksentiedot || []
  );

  objectToSave.asianumero = asianumeroObj ? asianumeroObj.properties.value : "";

  const diaarinumeroObj = find(
    propEq("anchor", "paatoksentiedot.diaarinumero.A"),
    changeObjects.paatoksentiedot || []
  );

  objectToSave.diaarinumero = diaarinumeroObj
    ? diaarinumeroObj.properties.value
    : "";

  const paatospaivaObj = find(
    propEq("anchor", "paatoksentiedot.paatospaiva.A"),
    changeObjects.paatoksentiedot || []
  );

  objectToSave.paatospvm =
    paatospaivaObj && paatospaivaObj.properties.value
      ? moment(paatospaivaObj.properties.value).format(ISO_DATE_FORMAT)
      : "";
  const voimaantulopaivaObj = find(
    propEq("anchor", "paatoksentiedot.voimaantulopaiva.A"),
    changeObjects.paatoksentiedot || []
  );
  objectToSave.voimassaalkupvm =
    voimaantulopaivaObj && voimaantulopaivaObj.properties.value
      ? moment(voimaantulopaivaObj.properties.value).format(ISO_DATE_FORMAT)
      : "";
  const paattymispaivamaaraObj = find(
    propEq("anchor", "paatoksentiedot.paattymispaivamaara.A"),
    changeObjects.paatoksentiedot || []
  );
  objectToSave.voimassaloppupvm =
    paattymispaivamaaraObj && paattymispaivamaaraObj.properties.value
      ? moment(paattymispaivamaaraObj.properties.value).format(ISO_DATE_FORMAT)
      : "";

  // This helps the frontend to initialize the first four fields on form load.
  objectToSave.meta.paatoksentiedot = changeObjects.paatoksentiedot;

  return objectToSave;
}
