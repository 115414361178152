export const AppRoute = {
  AmmatillinenKoulutus: "routes.ammatillinenKoulutus",
  Asia: "routes.asia",
  Asianhallinta: "routes.asianhallinta",
  AsianhallintaAvoimet: "routes.asianhallintaAvoimet",
  AsianhallintaKorjattavat: "routes.asianhallintaKorjattavat",
  AsianhallintaPaatetyt: "routes.asianhallintaPaatetyt",
  CasAuth: "routes.casAuth",
  CasLogOut: "routes.casLogOut",
  CasReady: "routes.casReady",
  UusiAsiaEsivalinnat: "routes.uusiAsiaEsivalinnat",
  EsiJaPerusopetus: "routes.esiJaPerusopetus",
  Hakemus: "routes.hakemus",
  Home: "routes.home",
  Jarjestaja: "routes.jarjestaja",
  JarjestamisJaYllapitamisluvat: "routes.jarjestamisJaYllapitamisluvat",
  Jarjestamislupa: "routes.jarjestamislupa",
  Jarjestamislupaasiat: "routes.jarjestamislupaasiat",
  Koulutustoimijat: "routes.koulutustoimijat",
  KoulutusmuodonEtusivu: "routes.koulutusmuodonEtusivu",
  LogIn: "routes.logIn",
  LogOut: "routes.logOut",
  Lukiokoulutus: "routes.lukiokoulutus",
  OmatTiedot: "routes.omatTiedot",
  Paatokset: "routes.paatokset",
  Saavutettavuusseloste: "routes.saavutettavuusseloste",
  Sivukartta: "routes.sivukartta",
  TaiteenPerusopetus: "routes.taiteenPerusopetus",
  Tilastot: "routes.tilastot",
  Tuva: "routes.tuva",
  Tuvahakemus: "routes.tuvahakemus",
  UusiHakemus: "routes.uusiHakemus",
  UusiHakemusVST: "routes.uusiHakemusVST",
  UusiHakemusTUVA: "routes.uusiHakemusTUVA",
  VapaaSivistystyo: "routes.vapaaSivistystyo",
  Yhteydenotto: "routes.yhteydenotto"
};

export const AppRouteTitles = {
  home: new Map([[AppRoute.Home, "common.oiva"]]),
  navigation: new Map([
    [
      AppRoute.JarjestamisJaYllapitamisluvat,
      "common.jarjestamisJaYllapitamisluvat"
    ],
    [AppRoute.Tilastot, "common.statistics"],
    [AppRoute.KoulutusmuodonEtusivu, "education.preAndBasicEducation"],
    [AppRoute.KoulutusmuodonEtusivu, "education.highSchoolEducation"],
    [AppRoute.KoulutusmuodonEtusivu, "education.vocationalEducation"],
    [AppRoute.KoulutusmuodonEtusivu, "common.vstTitleName"]
  ])
};
