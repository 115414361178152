import { useIntl } from "react-intl";
import KoulutusmuodonEtusivu from "components/03-templates/KoulutusmuodonEtusivu";
import JarjestamislupaJSX from "./JarjestamislupaHTML";
import Jarjestajaluettelo from "./Jarjestajaluettelo";
import WizardContainer from "./WizardContainer";
import { getKoulutusmuodot } from "utils/common";
import { PaikallisenTietovarastonAvain } from "enums";

/**
 * Hakuavaimet, joiden perusteella basedata.js täydentään lokaalia
 * tietovarastoa. Näiden avaimien mukaista dataa tarvitaan laajasti kyseisen
 * koulutusmuodon sivuilla.
 **/
const hakuavaimet = [
  PaikallisenTietovarastonAvain.AJALLA,
  PaikallisenTietovarastonAvain.JOISTA_LISAKSI,
  PaikallisenTietovarastonAvain.KUJALISAMAAREET,
  "kieletOPH",
  "kohteet",
  "kunnat",
  "lisatietoja",
  "lupaByUuid",
  "lupaByOid",
  "luvantaiteenalatKoodistoTPO",
  "maakunnat",
  "maakuntakunnat",
  "maaraystyypit",
  "muutEhdotTPO",
  "oppilaitoksetByOid",
  "tpoluvantaiteenalat",
  "tulevatLuvat"
];

export default function TaiteenPerusopetus() {
  const intl = useIntl();

  const koulutusmuoto = getKoulutusmuodot(intl).taiteenPerusopetus;

  return (
    <KoulutusmuodonEtusivu
      hakuavaimet={hakuavaimet}
      Jarjestajaluettelo={Jarjestajaluettelo}
      jarjestajatOtsikko={koulutusmuoto.jarjestajatOtsikko}
      JarjestamislupaJSX={JarjestamislupaJSX}
      koulutusmuoto={koulutusmuoto}
      kuvausteksti={koulutusmuoto.kuvausteksti}
      paasivunOtsikko={koulutusmuoto.paasivunOtsikko}
      WizardContainer={WizardContainer}></KoulutusmuodonEtusivu>
  );
}
