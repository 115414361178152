import { ReactElement, useEffect } from "react";
import { CellId } from "processes/CellId";
import { IProcessCollection } from "graphHandling/graphProcessHandling";
import { useProcess_ulkomaat } from "graphs/storeHandling";
import {
  graphDefinition,
  cellImplementations
} from "graphs/toiminta-alue/ulkomaat/processDefinition";
import { renderMultiTextBox } from "graphHandling/components/multiTextBox/rendering";
import { renderSimpleButton } from "graphHandling/components/simpleButton/rendering";
import { TietueProps } from "graphHandling/components/multiTextBox/MultiTextBox";
import {
  addIndex,
  dropLast,
  find,
  includes,
  isNil,
  map,
  path,
  prop,
  propEq,
  reject,
  sortBy,
  values
} from "ramda";
import { getCurrentValue } from "graphHandling/graphUtils";
import { useChangeObjectsByAnchorWithoutUnderRemoval } from "stores/muutokset";
import { ChangeObjects } from "utils/muutokset";
import {
  getHtmlFormattedRestrictionByKoodiarvo,
  getRestrictionsInListFormat,
  RajoitteetListamuodossa
} from "graphs/toiminta-alue/restrictions_utils";
import { useIntl } from "react-intl";
import HtmlContent from "components/01-molecules/HtmlContent";

type ActionAreaProps = {
  isPreviewModeOn?: boolean;
  pc: IProcessCollection;
  rajoitteet?: Record<string, { changeObjects: ChangeObjects }>;
};

const processId = "ulkomaat";

const OpetusUlkomailla = (props: ActionAreaProps): null | ReactElement => {
  const [, actions] = useProcess_ulkomaat();
  const { locale } = useIntl();
  const [changeObjects] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "rajoitepoistot"
  });

  useEffect(() => {
    if (props.pc) {
      props.pc.addProcess(
        {
          actions,
          CellId,
          cellImplementations,
          processDefinition: graphDefinition
        },
        processId
      );

      props.pc.handleIncomingProcessToken(
        CellId.INITIALIZE_PROCESS_OPETUS_ULKOMAILLA,
        processId
      );
    }
  }, []);

  useEffect(() => {
    if (process) {
      process.actions.updateGraph(["custom"], {
        idsOfRemovedRestrictions: map(
          path(["properties", "rajoiteId"]),
          changeObjects
        )
      });
    }
    props.pc.handleIncomingProcessToken(CellId.INITIALIZE_GRAPH, processId);
  }, [changeObjects]);

  const process = props.pc?.getProcess(processId);

  if (process) {
    const components = process.actions.readPath(["components"]);

    const luvanRajoitteet = process.actions.readPath([
      "custom",
      "restrictions"
    ]);

    const tietueet = values(
      getCurrentValue(
        "tietueet",
        components[CellId.INITIALIZE_MULTI_TEXTBOX]
      ) as Record<string, TietueProps>
    );

    const foundRajoiteStrings: Array<string> = [];

    const listValues: Array<{ label: string; rajoiteString: null | string }> =
      reject(
        isNil,
        map((tietue: TietueProps) => {
          if (!tietue.isDeleted) {
            const luvanRajoite = find(
              propEq("koodiarvo", tietue.id),
              luvanRajoitteet
            );
            const rajoitteetListamuodossa = getRestrictionsInListFormat(
              props.rajoitteet,
              locale,
              tietue.id
            );
            const rajoiteString = rajoitteetListamuodossa
              ? (getHtmlFormattedRestrictionByKoodiarvo(
                  tietue.id,
                  rajoitteetListamuodossa as RajoitteetListamuodossa
                ) as string)
              : null;
            const droppedRajoiteString = rajoiteString
              ? dropLast(20, rajoiteString)
              : "";

            const obj = {
              label: tietue.value,
              rajoiteString:
                luvanRajoite && droppedRajoiteString
                  ? `${droppedRajoiteString}${"TEST"}`
                  : rajoiteString
                  ? rajoiteString
                  : luvanRajoite
                  ? `<ul><li>${tietue.value}${"TEST"}</li></ul>`
                  : null
            };

            if (
              obj.rajoiteString &&
              !includes(obj.rajoiteString, foundRajoiteStrings)
            ) {
              foundRajoiteStrings.push(obj.rajoiteString);
            }
            return obj;
          }
          return null;
        }, tietueet)
      );

    return props.isPreviewModeOn ? (
      <ul className="list-disc ml-6">
        {addIndex(map)((_location, index) => {
          const location = _location as {
            label: string;
            rajoiteString: string;
          };
          const { label, rajoiteString } = location;
          if (rajoiteString) {
            return (
              <li key={index}>
                <HtmlContent content={rajoiteString} />
              </li>
            );
          } else {
            return <li key={index}>{label}</li>;
          }
        }, sortBy(prop("label"), listValues))}
      </ul>
    ) : (
      <div className="ml-8">
        {renderMultiTextBox(components[CellId.INITIALIZE_MULTI_TEXTBOX])}

        <div className="mt-4">
          {renderSimpleButton(components[CellId.INITIALIZE_LISAA_PAINIKE])}
        </div>
      </div>
    );
  }
  return null;
};

OpetusUlkomailla.displayName = "Opetus ulkomailla";

export default OpetusUlkomailla;
