import { CellFn } from "graphHandling/graphTypes";
import { GraphComponent, IChip, IGroupedChipCollection } from "utils/lomakkeet";
import {
  append,
  assoc,
  assocPath,
  map,
  mapObjIndexed,
  path,
  uniq
} from "ramda";
import { CellId } from "processes/CellId";

export const action_kumoaMuutokset: CellFn = storeFunctions => {
  const components = storeFunctions.readPath(["components"]) as Record<
    string,
    GraphComponent
  >;
  const groupedChipCollection =
    components[CellId.INITIALIZE_GROUPED_CHIP_COLLECTIONS];

  let gChipCs = path(
    ["properties", "gChipCs"],
    groupedChipCollection
  ) as Record<string, IGroupedChipCollection>;

  gChipCs = mapObjIndexed(gChipC => {
    const maakuntaChipPath = ["properties", "parentChip"];
    const chipCollectionPath = ["properties", "chipCollection"];

    const currentChipCollection = path(chipCollectionPath, gChipC);

    const currentChips = path(
      ["properties", "chips"],
      currentChipCollection
    ) as Array<IChip> | undefined;

    !currentChips && console.info(gChipC);

    if (currentChips) {
      const nextChips = map(chip => {
        return assoc("modifications", undefined, chip);
      }, currentChips);

      const nextChipCollection = assocPath(
        ["properties", "chips"],
        nextChips,
        currentChipCollection
      );

      let updatedGChipC = assocPath(
        ["properties", "chipCollection"],
        nextChipCollection,
        gChipC
      );

      const parentChip = assoc(
        "modifications",
        undefined,
        gChipC.properties.parentChip
      );

      updatedGChipC = assocPath(maakuntaChipPath, parentChip, updatedGChipC);

      return updatedGChipC;
    }
    return gChipC;
  }, gChipCs) as Record<string, IGroupedChipCollection>;

  storeFunctions.updateGraph(
    ["components", "groupedChipCollections", "properties", "gChipCs"],
    gChipCs
  );

  storeFunctions.updateComponent(CellId.INITIALIZE_AUTOCOMPLETE, {
    modifications: undefined
  });

  const unsaved =
    (storeFunctions.readPath(["custom", "unsaved"]) as Array<string>) || [];

  storeFunctions.updateGraph(["custom"], {
    unsaved: uniq(append(CellId.INITIALIZE_GROUPED_CHIP_COLLECTIONS, unsaved))
  });
};
