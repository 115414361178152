import { __ } from "i18n-for-browser";
import { PaikallisenTietovarastonAvain } from "enums";
import localForage from "localforage";
import { find, pathEq } from "ramda";
import { Category } from "utils/lomakkeet";

export async function getLisatiedot(
  data: Record<string, unknown>
): Promise<Array<Category>> {
  const lisatiedot = await localForage.getItem(
    PaikallisenTietovarastonAvain.LISATIEDOT
  );

  const lisatiedotObj = find(
    pathEq(["koodisto", "koodistoUri"], "lisatietoja"),
    lisatiedot || ([] as any)
  );

  return [
    {
      title: __("common.lisatiedot"),
      anchor: "lisatiedot",
      styleClasses: ["mt-6", "p-10", "bg-green-50/25"],
      layout: { indentation: "large" },
      components: [
        {
          anchor: lisatiedotObj.koodiarvo,
          name: "TextBox",
          properties: {
            placeholder: __("common.lisatiedot"),
            title: "",
            value: data.lisatiedot ? data.lisatiedot : ""
          }
        }
      ]
    }
  ];
}
