import { Autocomplete } from "utils/lomakkeet";
import { IntlShape } from "react-intl";
import common from "i18n/definitions/common";
import { ComponentEvent } from "graphHandling/graphTypes";
import { ComponentType } from "graphHandling/components/componentType";
import { AutocompleteProps } from "components/02-organisms/Autocomplete/ac";

export function defineOrganisaatiovalikko(
  intl: IntlShape,
  functions: {
    onChange: ComponentEvent;
    onInputChange?: ComponentEvent;
  },
  properties?: AutocompleteProps
): Autocomplete {
  const defaultProperties = {
    closeMenuOnSelect: true,
    hideSelectedOptions: false,
    isDisabled: true,
    isMulti: false,
    isSearchable: true,
    isSearchIndicatorVisible: true,
    options: [],
    placeholder: intl.formatMessage(common.valitseOrganisaatio),
    values: []
  };
  const initialProperties = Object.assign({}, defaultProperties, properties);
  return {
    name: ComponentType.AUTOCOMPLETE,
    onChange: functions.onChange,
    onInputChange: functions.onInputChange,
    properties: initialProperties
  };
}
