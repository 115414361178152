import { activatePredecessors } from "./utils/activatePredecessors";
import { deactivateNodesPredecessors } from "./utils/deactivateNodesPredecessors";
import { activateNodeAndItsDescendants } from "./utils/activateNodeAndItsDescendants";
import { deactivateNodeAndItsDescendants } from "./utils/deactivateNodeAndItsDescendants";
import { AnchorBase, removeAnchorPart } from "utils/anchor";
import { removeDeprecatedChanges } from "./utils/removeDeprecatedChanges";
import { setIsIndeterminateAsTrueForPredecessors } from "./utils/setIsIndeterminateAsTrueForPredecessors";
import { findParent } from "./utils/findParent";
import { ComponentWithMetadata, Properties } from "utils/lomakkeet";
import {
  ChangeObject,
  ChangeObjects,
  getChangeObjByAnchor
} from "utils/muutokset";
import {
  append,
  assoc,
  equals,
  find,
  flatten,
  map,
  prop,
  propEq,
  uniq
} from "ramda";

/**
 * @module CategorizedListRoot/utils
 **/

/**
 * Etsii ja palauttaa yksittäisen komponentin metadatalla höystettynä sekä
 * siihen kohdistuvat muutokset.
 * @param changeObj Muutosobjekti.
 * @param componentsWithMetadata Taulukollinen metadatalla höystettyjä komponentteja.
 * @returns Objekti, joka sisältää alkuperäisen, muutosten alaisen komponentin metadatalla
 * höystettynä sekä muutokset, jotka komponenttiin kohdistuvat.
 */
export const getTargetNode = (
  changeObj: ChangeObject,
  componentsWithMetadata: Array<ComponentWithMetadata>
): {
  original: ComponentWithMetadata | undefined;
  requestedChanges: Properties;
} => {
  return {
    original: find(
      propEq("fullAnchor", prop("anchor", changeObj)),
      componentsWithMetadata
    ),
    requestedChanges: changeObj ? changeObj.properties : {}
  };
};

const getPropertiesObject = (
  changeObj: ChangeObject | undefined,
  requestedChanges: Properties
) => {
  return Object.assign({}, changeObj?.properties || {}, requestedChanges);
};

/**
 * Function handles the new changes of a form and returns an updated array of
 * change objects.
 * @param {object} nodeWithRequestedChanges - Target node and requested changes.
 * @param {object} nodeWithRequestedChanges.requestedChanges - Properties object.
 * @param {array} changes - Array of change objects.
 */
export const handleNodeMain = (
  uncheckParentWithoutActiveChildNodes = false,
  nodeWithRequestedChanges: {
    original: ComponentWithMetadata;
    requestedChanges: Properties;
  },
  rootAnchor: AnchorBase,
  reducedStructure: Array<ComponentWithMetadata>,
  changes = []
): ChangeObjects => {
  /**
   * node = definition of a component that user has interacted with. Node
   * is an object that includes a name (e.g. CheckboxWithLabel) and the
   * properties defined on the current form.
   * E.g.
   *
   * {
   *   anchor: "A",
   *   name: "CheckboxWithLabel",
   *   properties: {
   *     code: "A.A.A",
   *     isChecked: false,
   *     labelStyles: {
   *       addition: { color: "purple" },
   *       removal: { color: "purple", textDecoration: "line-through" },
   *       custom: { fontWeight: 600 }
   *     },
   *     name: "example-checkbox",
   *     title: "Osaamisala 1",
   *     value: "Testi"
   *   },
   *   anchorParts: ["A", "A", "A"],
   *   fullAnchor: "A.A.A",
   *   level: 1,
   *   columnIndex: 0,
   *   path: ["components", 0, "categories", "components", 0]
   * };
   **/
  const componentWithMetadata: ComponentWithMetadata = prop(
    "original",
    nodeWithRequestedChanges
  );

  // Requested changes. E.g. {"isChecked":true}
  const requestedChanges = prop("requestedChanges", nodeWithRequestedChanges);

  // First part of every anchor will be removed.
  let changeObjects: ChangeObjects = rootAnchor
    ? map((changeObj: ChangeObject) => {
        const reducedAnchor = removeAnchorPart(changeObj.anchor, 0);
        return assoc("anchor", reducedAnchor, changeObj);
      }, changes)
    : changes;

  if (requestedChanges.isChecked) {
    /**
     * If user has clicked an unchecked checkbox or a radio button we must do
     * two things:
     * 1) Activate the node and its descendants.
     */
    changeObjects = uniq(
      flatten(
        activateNodeAndItsDescendants(
          componentWithMetadata,
          reducedStructure,
          changeObjects
        )
      )
    );

    // 2) Activate the node's predecessors.
    if (
      findParent(componentWithMetadata, reducedStructure, [
        "CheckboxWithLabel",
        "RadioButtonWithLabel"
      ])
    ) {
      changeObjects = activatePredecessors(
        componentWithMetadata,
        reducedStructure,
        changeObjects
      );
    }
  } else if (requestedChanges.isChecked === false) {
    /**
     * If user has clicked a checked checkbox or a radio button we must do
     * two things:
     * 1) Deactivate the node and its descendants.
     */
    changeObjects = deactivateNodeAndItsDescendants(
      componentWithMetadata,
      reducedStructure,
      changeObjects
    );
    // 2) Deactivate the node's predecessors.
    if (uncheckParentWithoutActiveChildNodes) {
      changeObjects = deactivateNodesPredecessors(
        componentWithMetadata,
        reducedStructure,
        changeObjects
      );
    } else {
      changeObjects = setIsIndeterminateAsTrueForPredecessors(
        componentWithMetadata,
        reducedStructure,
        changeObjects
      );
    }
  } else {
    /**
     * Otherwise the properties of the new change object will be merged with
     * the properties of the earlier changes of the current node.
     **/

    const changeObj: ChangeObject | undefined = getChangeObjByAnchor(
      componentWithMetadata.fullAnchor,
      changeObjects
    );
    const propsObj = getPropertiesObject(changeObj, requestedChanges);
    const updatedChangeObj = {
      anchor: componentWithMetadata.fullAnchor,
      properties: propsObj
    };

    if (changeObj) {
      /**
       * The earlier change object related to the node will be replaced with the
       * updated one.
       **/
      changeObjects = map(_changeObj => {
        if (
          updatedChangeObj &&
          equals(_changeObj.anchor, updatedChangeObj.anchor)
        ) {
          return updatedChangeObj;
        }
        return _changeObj;
      }, changeObjects);
    } else {
      /**
       * If there wasn't an earlier change object then we add the freshly made
       * change object on to the array of change objects.
       **/
      if (updatedChangeObj) {
        changeObjects = append(updatedChangeObj, changeObjects);
      }
    }
  }

  changeObjects = removeDeprecatedChanges(changeObjects);

  // Last thing is to prefix the anchors of change objects with the root anchor.
  const updatedChangesArr = rootAnchor
    ? map(changeObj => {
        return assoc("anchor", `${rootAnchor}.${changeObj.anchor}`, changeObj);
      }, changeObjects)
    : changeObjects;

  // Updated array of change objects will be returned.
  return updatedChangesArr;
};
