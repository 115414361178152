import { CellFn, EventPayload, Options } from "graphHandling/graphTypes";
import {
  append,
  assocPath,
  compose,
  concat,
  filter,
  find,
  head,
  isEmpty,
  isNil,
  last,
  length,
  lensIndex,
  map,
  mergeAll,
  omit,
  or,
  path,
  pathEq,
  prop,
  propEq,
  reject,
  set,
  sortBy,
  split,
  uniq,
  values
} from "ramda";
import localForage from "localforage";
import { Koodisto, PaikallisenTietovarastonAvain, Toiminto } from "enums";
import { MaakuntakunnatRaw, MaakuntaRaw, Maarays } from "koodistodatanTyypit";
import { getKuntaChipCollection } from "graphs/toiminta-alue/chips";
import {
  Autocomplete,
  IChip,
  IChipCollection,
  IGroupedChipCollection,
  IGroupedChipCollections
} from "utils/lomakkeet";
import { defineChip } from "graphHandling/components/chip/unopinionated";
import { getLocalizedProperty } from "services/lomakkeet/utils";
import { ComponentType } from "graphHandling/components/componentType";
import { getCurrentProps, getCurrentValue } from "graphHandling/graphUtils";
import { Muutospyynto } from "types";
import { Lupa } from "Lupa";
import { CellId } from "processes/CellId";

function getMaakuntaByKuntakoodi(
  kuntakoodi: string,
  maakuntakunnat: MaakuntakunnatRaw
): MaakuntaRaw {
  const maakunnat = filter(maakuntakunta => {
    return !!find(propEq("koodiArvo", kuntakoodi), maakuntakunta.kunta);
  }, maakuntakunnat);

  return maakunnat[0];
}

export const initialize_groupedChipCollections: CellFn = async (
  storeFunctions,
  intl
) => {
  const maakuntakunnat = (await localForage.getItem(
    PaikallisenTietovarastonAvain.MAAKUNTAKUNNAT_RAW
  )) as MaakuntakunnatRaw;

  const handleChipDelete = (payload: EventPayload) => {
    const { id, isLineThrough, isLineThroughable } = payload.changeProps;
    const idSplitted = split("-", id as string);
    const kunnanKoodiarvo = head(idSplitted) as string;
    const maakunnanKoodiarvo = last(idSplitted) as string;
    const modIndex =
      (storeFunctions.readPath(["custom", "modIndex"]) as number) || 0;
    const maakuntaLite = find(
      propEq("koodiarvo", maakunnanKoodiarvo),
      maakunnatLite
    );
    if (!maakuntaLite) {
      return false;
    }
    const gChipC = storeFunctions.readPath([
      "components",
      "groupedChipCollections",
      "properties",
      "gChipCs",
      maakuntaLite.key
    ]) as IGroupedChipCollection;

    const kuntaChipCollection = path(
      ["properties", "chipCollection"],
      gChipC
    ) as IChipCollection;

    const kuntaChips = path(
      ["properties", "chips"],
      kuntaChipCollection
    ) as Array<IChip>;

    const nextkuntaChips = map(chip => {
      if (chip.properties.id === payload.changeProps.id) {
        const mods = chip.modifications?.frontend || [];
        const modsAmount = length(mods);
        const sourceArr =
          modsAmount <= modIndex
            ? concat(mods, new Array(modIndex + 1 - modsAmount))
            : mods;
        const modifications = set(
          lensIndex(modIndex),
          omit(["id"], payload.changeProps),
          sourceArr
        );

        return assocPath(["modifications", "frontend"], modifications, chip);
      }
      return chip;
    }, kuntaChips || []);

    const amountOfCheckedkuntaChips = length(
      filter(kuntaChip => {
        const _kuntaChip = kuntaChip as IChip;
        return !!getCurrentValue("isChecked", _kuntaChip);
      }, nextkuntaChips)
    );

    const maakuntaModifications = {
      isChecked: !!find(kuntaChip => {
        const _kuntaChip = kuntaChip as IChip;
        return !!getCurrentValue("isChecked", _kuntaChip);
      }, nextkuntaChips),
      isIndeterminate:
        amountOfCheckedkuntaChips > 0 &&
        amountOfCheckedkuntaChips !== length(nextkuntaChips)
    };

    if (!isLineThrough && !isLineThroughable) {
      const autocomplete = storeFunctions.readPath([
        "components",
        CellId.INITIALIZE_AUTOCOMPLETE
      ]) as Autocomplete;
      const kunta = find(
        pathEq(["properties", "value"], kunnanKoodiarvo),
        gChipC.properties.chipCollection.properties.chips
      );
      if (kunta) {
        const options = uniq(
          sortBy(
            prop("label"),
            append(
              {
                label: kunta.properties.label,
                value: `${kunta.properties.value}-${maakunnanKoodiarvo}`
              },
              getCurrentValue("options", autocomplete) as Options
            )
          ) as Options
        );
        storeFunctions.addModification(
          CellId.INITIALIZE_AUTOCOMPLETE,
          { options },
          modIndex
        );
      }
    }

    storeFunctions.addModification(
      `groupedChipCollections_properties_gChipCs_${maakuntaLite.key}_properties_parentChip`,
      maakuntaModifications,
      modIndex
    );

    storeFunctions.updateComponent(
      `groupedChipCollections_properties_gChipCs_${maakuntaLite.key}`,
      {
        properties: {
          chipCollection: {
            properties: {
              chips: nextkuntaChips
            }
          }
        }
      },
      modIndex
    );
  };

  const createGChipC = (maakunnanKoodiarvo: string): IGroupedChipCollection => {
    const lupa = storeFunctions.readPath(["custom", "viimeisinLupa"]) as Lupa;
    const maakuntakunta = find(
      propEq("koodiArvo", maakunnanKoodiarvo),
      values(maakuntakunnat)
    ) as MaakuntaRaw;
    const modifications: { backend: Record<string, unknown> } = {
      backend: {}
    };
    let maakuntamaarays: Maarays | undefined = undefined;
    let kuntamaaraykset: Array<Maarays> = [];
    let isMaakuntaCheckedByMaarays = false;
    let isMaakuntaCheckedByKuntaMaaraykset = false;
    let isMaakuntaIndeterminate = false;
    let kuntaChipCollection: boolean | IChipCollection = false;

    /**
     * Käydään läpi viimeisimmän luvan määräykset koskien maakuntavalintoja
     */
    if (lupa) {
      const kuntaMaakuntamaaraykset = filter(
        compose(
          k => or(k === Koodisto.KUNTA, k === Koodisto.MAAKUNTA),
          prop("koodisto")
        ),
        lupa.maaraykset
      );
      maakuntamaarays = find(
        propEq("koodiarvo", maakunnanKoodiarvo),
        kuntaMaakuntamaaraykset
      );

      if (maakuntamaarays) {
        isMaakuntaCheckedByMaarays = true;
      } else {
        kuntamaaraykset = filter(
          (kuntaMaarays): any => {
            const maakuntaRaw = getMaakuntaByKuntakoodi(
              kuntaMaarays.koodiarvo,
              maakuntakunnat
            );
            return maakuntaRaw.koodiArvo === maakunnanKoodiarvo;
          },
          filter(maarays => {
            return maarays.koodisto === "kunta" && maarays.koodiarvo !== "200";
          }, kuntaMaakuntamaaraykset) as Array<Maarays>
        );
        isMaakuntaCheckedByKuntaMaaraykset = !!length(kuntamaaraykset);
        kuntaChipCollection = maakuntakunta
          ? getKuntaChipCollection(
              maakuntakunta,
              intl,
              isMaakuntaCheckedByMaarays,
              kuntamaaraykset
            )
          : false;

        if (kuntaChipCollection) {
          const amountOfCheckedKuntaCheckboxes = length(
            filter(kuntaChip => {
              return !!getCurrentValue("isChecked", kuntaChip, {
                bMods: false,
                fMods: false
              });
            }, kuntaChipCollection.properties.chips)
          );

          isMaakuntaIndeterminate =
            amountOfCheckedKuntaCheckboxes > 0 &&
            amountOfCheckedKuntaCheckboxes !==
              length(kuntaChipCollection.properties.chips);
        }
      }
    }

    const muutospyynto = storeFunctions.readPath([
      "custom",
      "muutospyynto"
    ]) as Muutospyynto;

    if (muutospyynto) {
      const { muutokset } = muutospyynto;
      const kuntaMaakuntaMuutokset = filter(
        compose(k => or(k === "kunta", k === "maakunta"), prop("koodisto")),
        muutokset
      );
      const maakuntamuutos = find(
        propEq("koodiarvo", maakunnanKoodiarvo),
        kuntaMaakuntaMuutokset
      );

      const isMaakuntaRemovedByMuutos =
        !!maakuntamuutos && maakuntamuutos.tila === Toiminto.POISTO;

      const isMaakuntaSelectedByMuutos =
        !!maakuntamuutos && maakuntamuutos.tila === Toiminto.LISAYS;

      const backendMuutoksillaPoistetutKunnat = filter(muutos => {
        const o = filter(maakuntakunta => {
          return !!find(
            propEq("koodiArvo", muutos.koodiarvo),
            maakuntakunta.kunta
          );
        }, maakuntakunnat);

        return (
          o[0]?.koodiArvo === maakunnanKoodiarvo &&
          muutos.tila === Toiminto.POISTO
        );
      }, kuntaMaakuntaMuutokset);

      const backendMuutoksillaValitutKunnat = filter(muutos => {
        const o = filter(maakuntakunta => {
          return !!find(
            propEq("koodiArvo", muutos.koodiarvo),
            maakuntakunta.kunta
          );
        }, maakuntakunnat);

        return (
          o[0]?.koodiArvo === maakunnanKoodiarvo &&
          muutos.koodiarvo !== "200" &&
          muutos.tila === Toiminto.LISAYS
        );
      }, kuntaMaakuntaMuutokset);

      kuntaChipCollection = maakuntakunta
        ? getKuntaChipCollection(
            maakuntakunta,
            intl,
            isMaakuntaCheckedByMaarays,
            kuntamaaraykset,
            backendMuutoksillaPoistetutKunnat,
            backendMuutoksillaValitutKunnat
          )
        : false;

      if (kuntaChipCollection) {
        const amountOfCheckedKuntaCheckboxes = length(
          filter(kuntaChip => {
            return !!getCurrentValue("isChecked", kuntaChip);
          }, kuntaChipCollection.properties.chips)
        );

        modifications.backend.isChecked = amountOfCheckedKuntaCheckboxes > 0;
        modifications.backend.isIndeterminate =
          amountOfCheckedKuntaCheckboxes > 0 &&
          amountOfCheckedKuntaCheckboxes !==
            length(kuntaChipCollection.properties.chips);
      }

      const isMaakuntaCheckedByMuutos =
        (kuntaChipCollection
          ? !isEmpty(
              filter(chip => {
                const currentKuntaProps = getCurrentProps(chip);
                return (
                  !!currentKuntaProps.isChecked &&
                  !!currentKuntaProps.isLineThrough
                );
              }, kuntaChipCollection.properties.chips)
            )
          : false) || isMaakuntaSelectedByMuutos;

      if (isMaakuntaCheckedByMuutos) {
        modifications.backend.isChecked = true;
        modifications.backend.isRemovable = true;
      }
      if (isMaakuntaCheckedByMaarays && isMaakuntaRemovedByMuutos) {
        modifications.backend.isLineThrough = true;
      }
    } else {
      kuntaChipCollection = maakuntakunta
        ? getKuntaChipCollection(
            maakuntakunta,
            intl,
            isMaakuntaCheckedByMaarays,
            kuntamaaraykset
          )
        : false;
    }

    const defaultValues = {
      id: `${maakunnanKoodiarvo}`,
      isChecked:
        isMaakuntaCheckedByMaarays || isMaakuntaCheckedByKuntaMaaraykset,
      isIndeterminate: isMaakuntaIndeterminate,
      isLineThroughable: isMaakuntaCheckedByMaarays,
      isRemovable: false,
      label: getLocalizedProperty(maakuntakunta.metadata, intl.locale, "nimi"),
      metadata: reject(isNil, {
        maaraysUuid: maakuntamaarays?.uuid
      }),
      value: maakunnanKoodiarvo
    };

    const maakuntaChip = defineChip(
      defaultValues,
      {
        onDelete: payload => {
          return payload;
        }
      },
      !modifications.backend || isEmpty(modifications.backend)
        ? undefined
        : modifications
    );

    return {
      name: ComponentType.GROUPED_CHIP_COLLECTION,
      properties: {
        chipCollection: kuntaChipCollection,
        parentChip: maakuntaChip
      }
    } as IGroupedChipCollection;
  };

  /**
   * Käsitellään tilanne, jossa maakunta on klikattu poistettavaksi
   * valittujen maakuntien ja kuntien listasta.
   * @param payload EventPayload.
   * @returns EventPayload.
   */
  const handleMaakuntaChipClick = (payload: EventPayload) => {
    const { isLineThrough } = payload.changeProps;
    const modIndex =
      (storeFunctions.readPath(["custom", "modIndex"]) as number) || 0;

    const maakuntaLite = find(
      propEq("koodiarvo", payload.changeProps.id),
      maakunnatLite
    );

    if (!maakuntaLite) {
      return false;
    }

    const gChipC = storeFunctions.readPath([
      "components",
      "groupedChipCollections",
      "properties",
      "gChipCs",
      maakuntaLite.key
    ]) as IGroupedChipCollection;

    const kuntaChipCollection = path(
      ["properties", "chipCollection"],
      gChipC
    ) as IChipCollection;

    let kuntaChips = path(
      ["properties", "chips"],
      kuntaChipCollection
    ) as Array<IChip>;

    if (kuntaChips) {
      kuntaChips = map(kuntaChip => {
        const headLens = lensIndex(modIndex);
        const mods = kuntaChip.modifications?.frontend || [];
        const modsAmount = length(mods);
        const sourceArr =
          modsAmount <= modIndex
            ? concat(mods, new Array(modIndex + 1 - modsAmount))
            : mods;
        const modifications = set(
          headLens,
          omit(["id"], payload.changeProps),
          sourceArr
        );

        return assocPath(
          ["modifications", "frontend"],
          modifications,
          kuntaChip
        );
      }, kuntaChips);
    }

    if (!isLineThrough) {
      const autocomplete = storeFunctions.readPath([
        "components",
        CellId.INITIALIZE_AUTOCOMPLETE
      ]) as Autocomplete;

      let options = sortBy(
        prop("label"),
        append(
          {
            label: gChipC.properties.parentChip.properties.label,
            value: gChipC.properties.parentChip.properties.value
          },
          getCurrentValue("options", autocomplete) as Options
        )
      ) as Options;

      options = concat(
        options,
        map(chip => {
          return {
            label: chip.properties.label,
            value: chip.properties.id
          };
        }, gChipC.properties.chipCollection.properties.chips)
      );

      storeFunctions.addModification(
        CellId.INITIALIZE_AUTOCOMPLETE,
        { options },
        modIndex
      );
    }

    storeFunctions.addModification(
      `groupedChipCollections_properties_gChipCs_${maakuntaLite.key}_properties_parentChip`,
      {
        ...omit(["id", "shouldHaveFocusAt"], payload.changeProps),
        isIndeterminate: false
      },
      modIndex
    );

    storeFunctions.updateComponent(
      `groupedChipCollections_properties_gChipCs_${maakuntaLite.key}_properties_chipCollection`,
      {
        properties: {
          chips: kuntaChips
        }
      },
      modIndex
    );

    return payload;
  };

  const onClick = (payload: EventPayload) => {
    const isMaakuntaChip = length(payload.changeProps.id as string) === 2;
    if (isMaakuntaChip) {
      // Lupaan kuuluvan maakunnan poistaminen
      handleMaakuntaChipClick(payload);
    } else {
      // Lupaan kuuluvan kunnan poistaminen
      handleChipDelete(payload);
    }
    return payload;
  };

  const componentDef = {
    name: ComponentType.GROUPED_CHIP_COLLECTIONS,
    onClick,
    properties: {
      gChipCs: mergeAll(
        map(maakunta => {
          return {
            [maakunta.key]: createGChipC(
              maakunta.koodiarvo
            ) as IGroupedChipCollection
          };
        }, maakunnatLite)
      )
    }
  } as IGroupedChipCollections;

  return componentDef;
};

export const maakunnatLite = [
  { key: "etelaKarjala", koodiarvo: "09" },
  { key: "etelaPohjanmaa", koodiarvo: "14" },
  { key: "etelaSavo", koodiarvo: "10" },
  { key: "kainuu", koodiarvo: "18" },
  { key: "kantaHame", koodiarvo: "05" },
  { key: "keskiPohjanmaa", koodiarvo: "16" },
  { key: "keskiSuomi", koodiarvo: "13" },
  { key: "kymenlaakso", koodiarvo: "08" },
  { key: "lappi", koodiarvo: "19" },
  { key: "paijatHame", koodiarvo: "07" },
  { key: "pirkanmaa", koodiarvo: "06" },
  { key: "pohjanmaa", koodiarvo: "15" },
  { key: "pohjoisKarjala", koodiarvo: "12" },
  { key: "pohjoisPohjanmaa", koodiarvo: "17" },
  { key: "pohjoisSavo", koodiarvo: "11" },
  { key: "satakunta", koodiarvo: "04" },
  { key: "uusimaa", koodiarvo: "01" },
  { key: "varsinaisSuomi", koodiarvo: "02" }
];
