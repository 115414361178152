import {
  Koodisto,
  ToimintaAluePikavalinnat,
  ToimintaAluePikavalintaKoodiarvot,
  Toiminto
} from "enums";
import { TietueProps } from "graphHandling/components/multiTextBox/MultiTextBox";
import { getCurrentProps, getCurrentValue } from "graphHandling/graphUtils";
import { luoMuutosobjektitLisatietokentasta } from "helpers/lisatiedot";
import { createAlimaarayksetBEObjects } from "helpers/rajoitteetHelper";
import { Kohde, Maarays, Maaraystyyppi } from "koodistodatanTyypit";
import {
  append,
  assocPath,
  concat,
  find,
  flatten,
  isEmpty,
  isNil,
  map,
  mapObjIndexed,
  nth,
  path,
  propEq,
  reject,
  values
} from "ramda";
import { Muutos } from "types";
import {
  IChip,
  IGroupedChipCollection,
  IGroupedChipCollections,
  ILabeledCheckbox,
  IMultiTextBox,
  IRadioButtonCollection,
  ITextBox
} from "utils/lomakkeet";
import { ChangeObjects } from "utils/muutokset";
import { getRajoitteetByValue } from "../../../utils/rajoitteetUtils";

/**
 * Generoi ja palauttaa toiminta-aluetta koskevat muutosobjektit.
 * @param kohde Kohde.
 * @param lomakedata Lomakkeen tiedot eli tiedot, jotka halutaan tallentaa.
 * @param maaraykset Taulukollinen Maarays-tyyppisiä objekteja.
 * @param maaraystyypit Taulukollinen Maaraystyyppi-tyyppisiä objekteja.
 * @returns Taulukollinen Muutos-tyyppisiä objekteja.
 */
export async function generoiToimintaalueenMuutosobjektit(
  kohde: Kohde,
  kohteet: Array<Kohde>,
  lomakedata: {
    gChipCs: IGroupedChipCollections;
    eiMaariteltyKuvaus: ITextBox;
    lisatiedot: ITextBox;
    multiTextBox: IMultiTextBox;
    rBC: IRadioButtonCollection;
    suomiCheckbox: ILabeledCheckbox;
    ulkomaatCheckbox: ILabeledCheckbox;
  },
  maaraykset: Array<Maarays> = [],
  maaraystyypit: Array<Maaraystyyppi>,
  rajoitteetByRajoiteId: Record<string, ChangeObjects>
): Promise<Array<Muutos>> {
  const maaraystyyppiVelvoite = find(
    propEq("tunniste", "VELVOITE"),
    maaraystyypit
  );

  if (!maaraystyyppiVelvoite) {
    console.warn("Määräystyyppiä ei löytynyt.");
    return [];
  }

  const eiMaariteltyaP = generoiEiMaariteltya(
    lomakedata.eiMaariteltyKuvaus,
    kohde,
    maaraykset,
    maaraystyyppiVelvoite
  );

  const lisatiedotP = generoiMuutosobjektitLisatiedot(
    kohde,
    lomakedata.lisatiedot
  );

  const suomiP = generoiMuutosobjektitSuomi(
    lomakedata.gChipCs,
    kohde,
    maaraykset,
    maaraystyyppiVelvoite,
    lomakedata.rBC,
    lomakedata.suomiCheckbox
  );

  const ulkomaatP = generoiMuutosobjektitUlkomaat(
    kohde,
    maaraykset,
    maaraystyyppiVelvoite,
    lomakedata.multiTextBox,
    lomakedata.ulkomaatCheckbox
  );

  const muutosobjektit = await Promise.all(
    [eiMaariteltyaP, lisatiedotP, suomiP, ulkomaatP].filter(Boolean)
  ).then(results => {
    const suomimuutokset: Array<Muutos> = reject(
      isNil,
      flatten([nth(2, results)])
    );

    const ulkomaamuutokset: Array<Muutos> = reject(
      isNil,
      flatten([nth(3, results)])
    );

    // Liitetään muutoksiin rajoitteet,jos niitä on.
    const suomimuutoksetRajoitteineen = map(muutos => {
      const rajoitteet = getRajoitteetByValue(
        muutos.koodiarvo,
        rajoitteetByRajoiteId
      );
      // Muodostetaan tehdyistä rajoituksista objektit backendiä varten.
      // Linkitetään ensimmäinen rajoitteen osa muutokseen ja
      // loput toisiinsa "alenevassa polvessa".
      const alimaarayksetByRajoiteId = mapObjIndexed(changeObjects => {
        return createAlimaarayksetBEObjects(
          kohteet,
          maaraystyypit,
          muutos,
          changeObjects
        );
      }, rajoitteet);

      const alimaaraykset = alimaarayksetByRajoiteId
        ? values(alimaarayksetByRajoiteId)
        : [];

      return [
        alimaaraykset
          ? assocPath(["meta", "changeObjects"], values(rajoitteet), muutos)
          : muutos,
        alimaaraykset
      ];
    }, suomimuutokset);

    // Liitetään muutoksiin rajoitteet,jos niitä on.
    const ulkomaamuutoksetRajoitteineen = map(muutos => {
      if (muutos.meta?.ankkuri) {
        const rajoitteet = getRajoitteetByValue(
          muutos.meta.ankkuri,
          rajoitteetByRajoiteId
        );
        // Muodostetaan tehdyistä rajoituksista objektit backendiä varten.
        // Linkitetään ensimmäinen rajoitteen osa muutokseen ja
        // loput toisiinsa "alenevassa polvessa".
        const alimaarayksetByRajoiteId = mapObjIndexed(changeObjects => {
          return createAlimaarayksetBEObjects(
            kohteet,
            maaraystyypit,
            muutos,
            changeObjects
          );
        }, rajoitteet);

        const alimaaraykset = alimaarayksetByRajoiteId
          ? values(alimaarayksetByRajoiteId)
          : [];

        return [
          alimaaraykset
            ? assocPath(["meta", "changeObjects"], values(rajoitteet), muutos)
            : muutos,
          alimaaraykset
        ];
      }
      return muutos;
    }, ulkomaamuutokset);

    return reject(
      isNil,
      flatten([
        results[0],
        results[1],
        suomimuutoksetRajoitteineen,
        ulkomaamuutoksetRajoitteineen
      ])
    );
  });

  // Kunta- ja maakuntamääräyksiä vasten luodut rajoitteet.
  const kuntaJaMaakuntamaaraykset = reject(
    isNil,
    map(maarays => {
      return maarays.koodisto === Koodisto.MAAKUNTA ||
        (maarays.koodisto === Koodisto.KUNTA && maarays.koodiarvo !== "200")
        ? maarays
        : null;
    }, maaraykset)
  );

  const maarayksiaVastenLuodutRajoitteet = reject(
    isNil,
    flatten(
      map(maarays => {
        const maaraystaKoskevatRajoitteet = mapObjIndexed(changeObjects => {
          return createAlimaarayksetBEObjects(
            kohteet,
            maaraystyypit,
            {
              isMaarays: true,
              generatedId: maarays.uuid,
              kohde
            },
            changeObjects
          );
        }, getRajoitteetByValue(maarays.koodiarvo, rajoitteetByRajoiteId));
        return values(maaraystaKoskevatRajoitteet);
      }, kuntaJaMaakuntamaaraykset)
    )
  );

  // Ulkomaamääräyksiä vasten luodut rajoitteet.
  const ulkomaihinLiittyvatMaaraykset = reject(
    isNil,
    map(maarays => {
      return maarays.koodisto === Koodisto.KUNTA && maarays.koodiarvo === "200"
        ? maarays
        : null;
    }, maaraykset)
  );

  const ulkomaamaarayksiaVastenLuodutRajoitteet = reject(
    isNil,
    flatten(
      map(maarays => {
        const maaraystaKoskevatRajoitteet = mapObjIndexed(changeObjects => {
          return createAlimaarayksetBEObjects(
            kohteet,
            maaraystyypit,
            {
              isMaarays: true,
              generatedId: maarays.uuid,
              kohde
            },
            changeObjects
          );
        }, getRajoitteetByValue(path(["meta", "ankkuri"], maarays), rajoitteetByRajoiteId));
        return values(maaraystaKoskevatRajoitteet);
      }, ulkomaihinLiittyvatMaaraykset)
    )
  );

  return flatten([
    muutosobjektit,
    maarayksiaVastenLuodutRajoitteet,
    ulkomaamaarayksiaVastenLuodutRajoitteet
  ]);
}

/**
 * Generoi ja palauttaa tekstikenttää "Ei määräteltyä toiminta-aluetta"
 * koskevat muutosobjektit.
 * @param eiMaariteltyaKuvaus Tekstikenttä, jonka arvoa tarkastellaan.
 * @param kohde Kohde.
 * @param maaraykset Taulukollinen Maarays-tyyppisiä objekteja.
 * @param maaraystyyppi Maaraystyyppi-tyyppinen objekti.
 * @returns Taulukollinen BackendChangeObject-tyyppisiä objekteja.
 */
export async function generoiEiMaariteltya(
  eiMaariteltyaKuvaus: ITextBox,
  kohde: Kohde,
  maaraykset: Array<Maarays>,
  maaraystyyppi: Maaraystyyppi
): Promise<Muutos | undefined> {
  if (eiMaariteltyaKuvaus) {
    // Onko luvalla FI2-määräystä?
    const FI2Maarays = find(
      propEq("koodiarvo", ToimintaAluePikavalintaKoodiarvot.EI_MAARITELTY),
      maaraykset
    );

    // Kentän alkuarvo
    const defaultValue = eiMaariteltyaKuvaus.properties.value;

    // Tekstikenttä nykyinen arvo
    const value = getCurrentValue("value", eiMaariteltyaKuvaus);

    // Onko tekstikenttä "Ei määriteltyä toiminta-aluetta" näkyvissä
    const isEiMaariteltyaVisible = !getCurrentValue(
      "isHidden",
      eiMaariteltyaKuvaus
    );

    // Jos tekstikenttä on näkyvissä...
    if (isEiMaariteltyaVisible) {
      // Jos kentän arvo on muuttunut, luodaan lisäys.
      if (value !== defaultValue) {
        return {
          tila: Toiminto.LISAYS,
          kohde,
          maaraystyyppi,
          koodisto: Koodisto.NUTS1,
          koodiarvo: ToimintaAluePikavalintaKoodiarvot.EI_MAARITELTY,
          meta: {
            arvo: String(value)
          }
        };
      }
    } else if (FI2Maarays) {
      // Jos tekstikenttä ei ole näkyvissä ja on olemassa sitä koskeva
      // määräys, luodaan poisto.
      return {
        kohde,
        koodiarvo: ToimintaAluePikavalintaKoodiarvot.EI_MAARITELTY,
        koodisto: Koodisto.NUTS1,
        maaraystyyppi,
        tila: Toiminto.POISTO
      };
    }
  }

  // Jos muutosobjekteja ei tarvita, palautetaan vain tyhjä taulukko.
  return undefined;
}

/**
 * Generoi ja palauttaa toiminta-alueen lisätietokenttää koskevat
 * muutosobjektit.
 * @param kohde Kohde.
 * @param lisatiedot Tekstikenttä, jonka arvoa tarkastellaan.
 * @returns Taulukollinen BackendChangeObject-tyyppisiä objekteja.
 */
export async function generoiMuutosobjektitLisatiedot(
  kohde: Kohde,
  lisatiedot: ITextBox
): Promise<Array<Muutos>> {
  let lisatietomuutokset: Array<Muutos> = [];
  if (lisatiedot) {
    const currentValue = getCurrentValue("value", lisatiedot);

    const lisatiedotChangeObj =
      currentValue !== lisatiedot.properties.value
        ? {
            anchor: ".lisatiedot.1",
            properties: { value: currentValue }
          }
        : null;

    if (lisatiedotChangeObj) {
      if (currentValue === "") {
        if (lisatiedot.properties.value !== "") {
          lisatietomuutokset = await luoMuutosobjektitLisatietokentasta(kohde, [
            lisatiedotChangeObj
          ]);
        }
      } else {
        if (currentValue !== lisatiedot.properties.value) {
          lisatietomuutokset = await luoMuutosobjektitLisatietokentasta(kohde, [
            lisatiedotChangeObj
          ]);
        }
      }
    }
  }

  return lisatietomuutokset;
}

export async function generoiMuutosobjektitSuomi(
  gChipCs: IGroupedChipCollections,
  kohde: Kohde,
  maaraykset: Array<Maarays>,
  maaraystyyppi: Maaraystyyppi,
  rBC: IRadioButtonCollection,
  suomiCheckbox: ILabeledCheckbox
): Promise<Array<Muutos>> {
  let muutosobjektit: Array<Muutos> = [];
  const isSuomiCheckboxChecked = getCurrentValue("isChecked", suomiCheckbox);

  if (isSuomiCheckboxChecked) {
    // Suomi-checkbox on valittuna.
    const currentRBCValue = getCurrentValue("value", rBC);

    if (currentRBCValue === ToimintaAluePikavalinnat.MILTEI_KOKO_MAA) {
      // Koko Suomi - pois lukien Ahvenanmaan maakunta on valittuna,
      // joten luodaan poistot yksittäisille kunnille ja maakunnille.
      muutosobjektit = reject(
        isNil,
        map(maarays => {
          if (
            (maarays.koodisto === Koodisto.KUNTA &&
              maarays.koodiarvo !== "200") ||
            maarays.koodisto === Koodisto.MAAKUNTA
          ) {
            return {
              kohde,
              koodisto: maarays.koodisto,
              koodiarvo: maarays.koodiarvo,
              maaraystyyppi,
              maaraysUuid: maarays.uuid,
              tila: Toiminto.POISTO
            };
          }
          return null;
        }, maaraykset)
      );
      if (currentRBCValue !== rBC.properties.value) {
        // Koko Suomi - pois lukien Ahvenanmaan maakunta valinta tarvitsee
        // lisäysmuutoksen.
        muutosobjektit = append(
          {
            kohde,
            koodiarvo: ToimintaAluePikavalintaKoodiarvot.MILTEI_KOKO_MAA,
            koodisto: Koodisto.NUTS1,
            maaraystyyppi,
            tila: Toiminto.LISAYS
          },
          muutosobjektit
        );
      }
    } else {
      if (gChipCs) {
        const maakuntaKeys: Array<string> = Object.keys(gChipCs);
        muutosobjektit = reject(
          isNil,
          flatten(
            map(maakuntaKey => {
              let maakuntalisays: Muutos | undefined = undefined;
              let maakuntapoisto: Muutos | undefined = undefined;
              let kuntalisaykset: Array<Muutos> = [];
              let kuntapoistot: Array<Muutos> = [];
              const gChipC = path(
                [maakuntaKey],
                gChipCs
              ) as IGroupedChipCollection;
              const parentChip = path(
                ["properties", "parentChip"],
                gChipC
              ) as IChip;
              const currentParentChipProps = getCurrentProps(parentChip);
              if (
                currentParentChipProps.isChecked &&
                !currentParentChipProps.isLineThrough
              ) {
                // Maakunta on valittu joko kokonaan tai osittain.
                if (currentParentChipProps.isIndeterminate) {
                  // Vain osa kunnista on valittu.
                  // Luodaan lisäykset yksittäisille kunnille, joille ei ole olemassa
                  // määräystä.
                  kuntalisaykset = reject(
                    isNil,
                    map(kuntaChip => {
                      const isCurrentlyChecked = getCurrentValue(
                        "isChecked",
                        kuntaChip
                      );
                      if (
                        isCurrentlyChecked &&
                        !kuntaChip.properties.isChecked
                      ) {
                        // Luodaan kuntalisäys, kun kunta on valittu ja ei ole olemassa
                        // sitä koskevaa määräystä.
                        return {
                          generatedId: `${Koodisto.KUNTA}-${Math.random()}`,
                          kohde,
                          koodisto: Koodisto.KUNTA,
                          koodiarvo: kuntaChip.properties.value as string,
                          maaraystyyppi,
                          tila: Toiminto.LISAYS
                        };
                      }
                      return null;
                    }, gChipC.properties.chipCollection.properties.chips)
                  );
                  // Luodaan poistot yksittäisille kunnille, joille on olemassa
                  // määräys.
                  kuntapoistot = reject(
                    isNil,
                    map(kuntaChip => {
                      const currentProps = getCurrentProps(kuntaChip);
                      if (
                        kuntaChip.properties.isChecked &&
                        !kuntaChip.properties.isLineThrough &&
                        (!currentProps.isChecked || currentProps.isLineThrough)
                      ) {
                        const kuntamaarays = find(
                          maarays =>
                            maarays.koodisto === Koodisto.KUNTA &&
                            maarays.koodiarvo === kuntaChip.properties.value,
                          maaraykset
                        );
                        // Luodaan poisto, kun kuntamääräys on olemassa, mutta
                        // kunta ei ole enää valittuna.
                        return {
                          generatedId: `${Koodisto.KUNTA}-${Math.random()}`,
                          kohde,
                          koodisto: Koodisto.KUNTA,
                          koodiarvo: kuntaChip.properties.value as string,
                          maaraystyyppi,
                          maaraysUuid: kuntamaarays?.uuid,
                          tila: Toiminto.POISTO
                        };
                      }
                      return null;
                    }, gChipC.properties.chipCollection.properties.chips)
                  );
                } else {
                  // Koko maakunta on valittu.
                  if (
                    parentChip.properties.isChecked &&
                    !parentChip.properties.isIndeterminate
                  ) {
                    // Koko maakunta on valittu johtuen olemassa olevasta määräyksestä.
                  } else {
                    // Maakuntamääräystä ei ole, joten luodaan lisäys.
                    maakuntalisays = {
                      generatedId: `${Koodisto.MAAKUNTA}-${Math.random()}`,
                      kohde,
                      koodisto: Koodisto.MAAKUNTA,
                      koodiarvo: parentChip.properties.id as string,
                      maaraystyyppi,
                      tila: Toiminto.LISAYS
                    };

                    // Koska koko maakunta on valittuna, ei ole tarvetta sitä
                    // koskeville yksittäisille kuntamääräyksille.
                    kuntapoistot = reject(
                      isNil,
                      map(maarays => {
                        const isKuntaInThisMaakunta = !!find(
                          kuntaChip =>
                            kuntaChip.properties.metadata
                              ?.maakunnanKoodiarvo === maarays.koodiarvo,
                          gChipC.properties.chipCollection.properties.chips
                        );
                        if (
                          isKuntaInThisMaakunta &&
                          maarays.koodisto === Koodisto.KUNTA &&
                          maarays.koodiarvo !== "200"
                        ) {
                          return {
                            kohde,
                            koodisto: maarays.koodisto,
                            koodiarvo: maarays.koodiarvo,
                            maaraystyyppi,
                            maaraysUuid: maarays.uuid,
                            tila: Toiminto.POISTO
                          };
                        }
                        return null;
                      }, maaraykset)
                    );
                  }
                }
              } else {
                // Maakunta ei ole valittuna.
                if (
                  parentChip.properties.isChecked &&
                  !parentChip.properties.isLineThrough
                ) {
                  // Maakunta oli valittuna, joten sille voi olla olemassa määräys.
                  const maakuntamaarays = find(
                    propEq(
                      "uuid",
                      path(
                        ["properties", "metadata", "maaraysUuid"],
                        parentChip
                      )
                    ),
                    maaraykset
                  );

                  if (maakuntamaarays) {
                    // Maakuntamääräys on olemassa, joten luodaan sitä vasten poisto.
                    maakuntapoisto = {
                      tila: Toiminto.POISTO,
                      kohde,
                      koodisto: Koodisto.MAAKUNTA,
                      koodiarvo: parentChip.properties.value as string,
                      maaraystyyppi,
                      maaraysUuid: maakuntamaarays.uuid
                    };
                  } else {
                    // Luodaan poistot yksittäisille kunnille, joille on olemassa
                    // määräys.
                    kuntapoistot = reject(
                      isNil,
                      map(kuntaChip => {
                        const isCurrentlyChecked = getCurrentValue(
                          "isChecked",
                          kuntaChip
                        );
                        if (
                          kuntaChip.properties.isChecked &&
                          !isCurrentlyChecked
                        ) {
                          const kuntamaarays = find(
                            maarays =>
                              maarays.koodisto === Koodisto.KUNTA &&
                              maarays.koodiarvo === kuntaChip.properties.value,
                            maaraykset
                          );
                          // Luodaan poisto, kun kuntamääräys on olemassa, mutta
                          // kunta ei ole enää valittuna.
                          return {
                            generatedId: `${Koodisto.KUNTA}-${Math.random()}`,
                            kohde,
                            koodisto: Koodisto.KUNTA,
                            koodiarvo: kuntaChip.properties.value as string,
                            maaraystyyppi,
                            maaraysUuid: kuntamaarays?.uuid,
                            tila: Toiminto.POISTO
                          };
                        }
                        return null;
                      }, gChipC.properties.chipCollection.properties.chips)
                    );
                  }
                }
              }

              return [
                maakuntalisays,
                maakuntapoisto,
                kuntalisaykset,
                kuntapoistot
              ];
            }, maakuntaKeys)
          )
        );
      }

      // Tarkistetaan vielä onko olemassa määräystä nuts1-koodiston koodiarvolle FI1.
      const FI1Maarays = find(
        propEq("koodiarvo", ToimintaAluePikavalintaKoodiarvot.MILTEI_KOKO_MAA),
        maaraykset
      );
      if (
        FI1Maarays &&
        currentRBCValue !== ToimintaAluePikavalintaKoodiarvot.MILTEI_KOKO_MAA
      ) {
        // Luodaan poisto-objekti koskien FI1-määräystä.
        muutosobjektit = append(
          {
            kohde,
            koodiarvo: ToimintaAluePikavalintaKoodiarvot.MILTEI_KOKO_MAA,
            koodisto: Koodisto.NUTS1,
            maaraystyyppi,
            maaraysUuid: FI1Maarays.uuid,
            tila: Toiminto.POISTO
          },
          muutosobjektit
        );
      }
    }
  } else {
    // Suomi-checkbox ei ole valittuna, joten luodaan poistot Suomea koskeville
    // määräyksille.
    muutosobjektit = reject(
      isNil,
      map(maarays => {
        if (
          (maarays.koodisto === Koodisto.KUNTA &&
            maarays.koodiarvo !== "200") ||
          maarays.koodisto === Koodisto.MAAKUNTA ||
          maarays.koodisto === Koodisto.NUTS1
        ) {
          return {
            kohde,
            koodisto: maarays.koodisto,
            koodiarvo: maarays.koodiarvo,
            maaraystyyppi,
            maaraysUuid: maarays.uuid,
            tila: Toiminto.POISTO
          };
        }
        return null;
      }, maaraykset)
    );
  }

  return muutosobjektit;
}

/**
 * Generoi ja palauttaa ulkomailla järjestettävää opetusta koskevat
 * muutosobjektit.
 * @param kohde Kohde.
 * @param maaraykset Taulukollinen Maarays-tyyppisiä objekteja.
 * @param maaraystyyppi Taulukollinen Maaraystyyppi-tyyppisiä objekteja.
 * @param multiTextBox MultiTextBox-komponentti, joka pitää sisällään
 * tarkasteltava tekstikentät eli tiedon ulkomailla järjestettävästä
 * opetuksesta.
 * @param ulkomaatCheckbox Checkbox, jonka ollessa ruksaamaton ei
 * opetusta järjestetä ulkomailla.
 * @returns Taulukollinen Muutos-tyyppisiä objekteja.
 */
export async function generoiMuutosobjektitUlkomaat(
  kohde: Kohde,
  maaraykset: Array<Maarays>,
  maaraystyyppi: Maaraystyyppi,
  multiTextBox: IMultiTextBox,
  ulkomaatCheckbox: ILabeledCheckbox
): Promise<Array<Muutos>> {
  // Onko Ulkomaat-checkbox ruksattu?
  const isUlkomaatCheckboxChecked = getCurrentValue(
    "isChecked",
    ulkomaatCheckbox
  );

  const tietueetById = getCurrentValue("tietueet", multiTextBox);

  let lisaykset: Array<Muutos> = [];
  let poistot: Array<Muutos> = [];

  // Jos lomakkeella on tietueita, käydään ne läpi.
  if (tietueetById && !isEmpty(tietueetById)) {
    const tietueet = values(tietueetById as Record<string, TietueProps>);
    if (isUlkomaatCheckboxChecked) {
      // Ulkomaat-checkbox on valittuna. Sekä lisäykset että poistot
      // ovat mahdollisia.
      lisaykset = isUlkomaatCheckboxChecked
        ? reject(
            isNil,
            map(tietue => {
              const lisays = {
                generatedId: `ulkomaa-${Math.random()}`,
                kohde,
                koodiarvo: "200",
                koodisto: Koodisto.KUNTA,
                maaraystyyppi,
                meta: {
                  arvo: tietue.value,
                  ankkuri: tietue.id
                },
                tila: Toiminto.LISAYS
              };

              const maarays = find(propEq("uuid", tietue.id), maaraykset);

              if (maarays) {
                return maarays.meta.arvo !== tietue.value
                  ? { ...lisays, maaraysUuid: maarays.uuid }
                  : null;
              } else {
                return lisays;
              }
            }, tietueet)
          )
        : [];
      poistot = reject(
        isNil,
        map(tietue => {
          // Etsitään tekstikenttää koskeva määräys.
          const maarays = find(propEq("uuid", tietue.id), maaraykset);

          if (maarays && tietue.isDeleted) {
            return {
              kohde,
              koodiarvo: "200",
              koodisto: Koodisto.KUNTA,
              maaraystyyppi: maaraystyyppi,
              maaraysUuid: maarays.uuid,
              meta: {
                ankkuri: tietue.id
              },
              tila: Toiminto.POISTO
            };
          }
          return null;
        }, tietueet)
      );
    } else {
      // Ulkomaat-checkbox ei ole valittuna, joten tehdään
      // korkeintaan pelkkiä poistoja.
      poistot = reject(
        isNil,
        map(tietue => {
          // Etsitään tekstikenttää koskeva määräys.
          const maarays = find(propEq("uuid", tietue.id), maaraykset);

          if (maarays) {
            return {
              kohde,
              koodiarvo: "200",
              koodisto: Koodisto.KUNTA,
              maaraystyyppi: maaraystyyppi,
              maaraysUuid: maarays.uuid,
              meta: {
                ankkuri: tietue.id
              },
              tila: Toiminto.POISTO
            };
          }
          return null;
        }, tietueet)
      );
    }
  }

  return concat(lisaykset, poistot);
}
