import { dropLast, equals, filter } from "ramda";
import { ComponentWithMetadata } from "utils/lomakkeet";

/**
 * Etsii elementin kanssa samalla tasolla olevat elementit (sisarelementit) jättäen
 * parametrina annetun komponenttimerkkauksen palauttamatta.
 * @param componentWithMetadata Metadatalla höystetty komponenttimerkkaus.
 * @param componentsWithMetadata Taulukollinen metadatalla höystettyjä komponenttimerkkauksia.
 * @returns Taulukollinen metadatalla höystettyjä komponenttimerkkauksia.
 */
export function findSiblings(
  componentWithMetadata: ComponentWithMetadata,
  componentsWithMetadata: Array<ComponentWithMetadata>
): Array<ComponentWithMetadata> {
  const parentAnchorPartsInit = dropLast(2, componentWithMetadata.anchorParts);

  return filter(siblingCandidate => {
    return (
      equals(
        parentAnchorPartsInit,
        dropLast(2, siblingCandidate.anchorParts)
      ) &&
      equals(
        componentWithMetadata.anchorParts.length,
        siblingCandidate.anchorParts.length
      ) &&
      componentWithMetadata.fullAnchor !== siblingCandidate.fullAnchor
    );
  }, componentsWithMetadata);
}
