import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useIntl } from "react-intl";
import { Typography } from "@mui/material";
import { localizeRouteKey } from "utils/common";
import { AppRoute } from "routes/index";
import { ReactElement } from "react";

import auth from "i18n/definitions/auth";
import common from "i18n/definitions/common";

const LogIn = (): ReactElement => {
  const intl = useIntl();
  const { formatMessage, locale } = intl;
  return (
    <div>
      <Helmet htmlAttributes={{ lang: locale }}>
        <title>Oiva | {formatMessage(auth.logIn)}</title>
      </Helmet>
      <BreadcrumbsItem to={localizeRouteKey(locale, AppRoute.Home, intl)}>
        {formatMessage(common.frontpage)}
      </BreadcrumbsItem>
      <BreadcrumbsItem to="/kirjaudu">
        {formatMessage(auth.logIn)}
      </BreadcrumbsItem>
      <Typography component="h1" variant="h1">
        Kirjautuminen
      </Typography>
      <div className="border my-8 px-8 py-4 inline-block cursor-pointer uppercase text-white bg-gray-500">
        <Link to="/cas-auth" style={{ textDecoration: "none" }}>
          CAS-Kirjautuminen
        </Link>
      </div>
    </div>
  );
};

export default LogIn;
