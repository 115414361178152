import common from "../i18n/definitions/common";
import moment from "moment";
import { resolveLocalizedOrganizationName } from "../modules/helpers";
import { getKoulutusmuodot, localizeRouteKey } from "utils/common";
import { AppRoute } from "routes/index";
import {
  addIndex,
  find,
  map,
  path,
  prop,
  propEq,
  toUpper,
  values
} from "ramda";
import { koulutustyypitMap, DATE_FORMAT } from "utils/constants";

export const labelColorClassesByTila = {
  VALMISTELUSSA: "bg-blue-100",
  ESITTELYSSA: "bg-yellow-100",
  PAATETTY: "bg-gray-200",
  KORJAUKSESSA: "bg-red-400 text-white"
};

export const asiatTableColumnSetup = (
  avoimet,
  koulutusmuoto,
  locale,
  korjattavat = false
) => {
  let kaannosavain3 =
    common[
      koulutusmuoto
        ? `asiaTable.headers.asiakas.${koulutusmuoto.key}`
        : "asiaTable.headers.asiakas"
    ];
  let kaannosavain4 = common["asiaTable.headers.maakunta"];
  let kaannosavain6 = common["asiaTable.headers.paatospvm"];
  let columnKey4 = `jarjestaja.maakuntaKoodi.metadata.nimi.${locale || "fi"}`;
  let columnKey6 = "paatospvm";
  if (koulutusmuoto) {
    if (koulutusmuoto.koulutustyyppi === koulutustyypitMap.VAPAASIVISTYSTYO) {
      kaannosavain4 = common["asiaTable.headers.oppilaitostyyppi"];
      columnKey4 = "oppilaitostyyppi";
    } else if (koulutusmuoto.koulutustyyppi === koulutustyypitMap.TUVA) {
      kaannosavain3 = common.jarjestaja;
      kaannosavain4 =
        common[
          koulutusmuoto
            ? `asiaTable.headers.asiakas.${koulutusmuoto.key}`
            : "asiaTable.headers.asiakas"
        ];
      columnKey4 = "koulutustyyppi";
    }
  }
  if (
    avoimet ||
    (koulutusmuoto && koulutusmuoto.koulutustyyppi === koulutustyypitMap.TUVA)
  ) {
    kaannosavain6 = common["asiaTable.headers.saapunut"];
    columnKey6 = "paivityspvm";
  }
  return [
    {
      titleKey: common.asiaKauttaDiaarinumero,
      widthClass: "w-2/12",
      isSearchable: !korjattavat,
      isSortable: !korjattavat,
      columnKey: "asianumero"
    },
    {
      titleKey: common["asiaTable.headers.asia"],
      widthClass: "w-2/12",
      isSortable: false
    },
    {
      titleKey: kaannosavain3,
      widthClass: kaannosavain3 === common.jarjestaja ? "w-2/12" : "w-3/12", // Set width depending on value
      isSearchable: !korjattavat,
      isSortable: !korjattavat,
      columnKey: `jarjestaja.nimi.${locale || "fi"}`
    },
    {
      titleKey: kaannosavain4,
      widthClass: kaannosavain3 === common.jarjestaja ? "w-3/12" : "w-2/12", // Set width depending on value
      isSearchable: !korjattavat,
      isSortable: !korjattavat,
      columnKey: columnKey4
    },
    {
      titleKey: common["asiaTable.headers.tila"],
      widthClass: "w-1/12",
      isSortable: false
    },
    {
      titleKey: kaannosavain6,
      widthClass: "w-1/12",
      columnKey: columnKey6,
      isSortable: !korjattavat
    }
  ];
};

export const generateAsiatTableHeaderStructure = (t, tableColumnSetup) => {
  return {
    role: "thead",
    rowGroups: [
      {
        rows: [
          {
            cells: map(item => {
              return {
                isSortable: !(item.isSortable === false),
                isSearchable: !!item.isSearchable,
                columnKey: item.columnKey,
                searchPlaceholder: t(common.filterRows),
                truncate: true,
                styleClasses: [item.widthClass],
                text: t(item.titleKey)
              };
            })(tableColumnSetup)
          }
        ]
      }
    ]
  };
};

const getMaakuntaNimiFromHakemus = (hakemus, locale) => {
  const maakuntaObject = find(propEq("kieli", locale.toUpperCase()))(
    path(["jarjestaja", "maakuntaKoodi", "metadata"], hakemus) || []
  );
  return maakuntaObject ? maakuntaObject.nimi : "";
};

// Generates common row data for all Asiat-tables
export const generateAsiaTableRows = (
  row,
  intl,
  avoimet,
  koulutusmuoto,
  vstTyypit = []
) => {
  const { formatMessage, locale } = intl;
  // Used only in Tuva Case
  const rowKoulutusmuoto =
    find(
      propEq("key", path(["meta", "koulutusmuotoKebabCase"], row)),
      values(getKoulutusmuodot(intl))
    ) || getKoulutusmuodot(intl)["ammatillinenKoulutus"]; // Default to ammattillinen koulutus if find is empty
  const tableColumnSetup = asiatTableColumnSetup(avoimet);
  const paivityspvm = row.paivityspvm
    ? moment(row.paivityspvm).format(DATE_FORMAT)
    : "";
  const paatospvm = row.paatospvm
    ? moment(row.paatospvm).format(DATE_FORMAT)
    : "";

  const oppilaitostyyppiObj = find(
    propEq("koodiarvo", row.oppilaitostyyppi),
    vstTyypit
  );
  const oppilaitostyyppiText = oppilaitostyyppiObj
    ? path(["metadata", toUpper(locale), "nimi"], oppilaitostyyppiObj)
    : "";
  const text1 = // Set content related to koulutusmuoto, opetuksen järjestäjä or oppilaitoksen ylläpitäjä
    koulutusmuoto.koulutustyyppi === koulutustyypitMap.TUVA
      ? prop("paasivunOtsikko", rowKoulutusmuoto)
      : resolveLocalizedOrganizationName(row.jarjestaja, locale);
  const text2 = // Set content related to oppilaitostyyppi, koulutuksen järjestäjä or maakunta
    koulutusmuoto &&
    koulutusmuoto.koulutustyyppi === koulutustyypitMap.VAPAASIVISTYSTYO
      ? oppilaitostyyppiText
      : koulutusmuoto.koulutustyyppi === koulutustyypitMap.TUVA
      ? resolveLocalizedOrganizationName(row.jarjestaja, locale)
      : getMaakuntaNimiFromHakemus(row, locale);
  return addIndex(map)(
    (col, j) => {
      return {
        truncate: false,
        styleClasses: [tableColumnSetup[j].widthClass],
        text: col.text
      };
    },
    [
      { text: row.asianumero || row.diaarinumero },
      {
        text:
          formatMessage(common["asiaTypes.lupaChange"]) +
          (row.kieli && row.kieli === "sv" ? " (SV)" : "")
      }, // Only one type known in system at this juncture
      {
        text:
          koulutusmuoto.koulutustyyppi === koulutustyypitMap.TUVA
            ? text2 // Set content related to oppilaitostyyppi, koulutuksen järjestäjä or maakunta
            : text1 // Set content related to koulutusmuoto, opetuksen järjestäjä or oppilaitoksen ylläpitäjä
      },
      {
        text:
          koulutusmuoto.koulutustyyppi === koulutustyypitMap.TUVA
            ? text1 // Set content related to koulutusmuoto, opetuksen järjestäjä or oppilaitoksen ylläpitäjä
            : text2 // Set content related to oppilaitostyyppi, koulutuksen järjestäjä or maakunta
      },
      {
        text: `<span class="px-3 py-2 rounded-sm ${prop(
          row.tila,
          labelColorClassesByTila
        )}">${
          formatMessage(common[`asiaStates.esittelija.${row.tila}`]) || ""
        }</span>`
      },
      { text: avoimet ? paivityspvm : paatospvm }
    ]
  );
};

export const generateAvoimetAsiatTableStructure = (
  hakemusList,
  intl,
  history,
  koulutusmuoto,
  vstTyypit
) => {
  return {
    role: "tbody",
    rowGroups: [
      {
        rows: addIndex(map)(row => {
          let actions = [];
          if (row.tila === "VALMISTELUSSA") {
            actions.push({
              id: "esittelyyn",
              text: intl.formatMessage(common["asiaTable.actions.esittelyssa"])
            });
          } else if (row.tila === "ESITTELYSSA") {
            actions.push(
              {
                id: "valmisteluun",
                text: intl.formatMessage(
                  common["asiaTable.actions.valmistelussa"]
                )
              },
              {
                id: "paata",
                text: intl.formatMessage(common["asiaTable.actions.paatetty"])
              }
            );
          }
          return {
            id: row.uuid,
            onClick: async row => {
              history.push(
                localizeRouteKey(intl.locale, AppRoute.Asia, intl, {
                  koulutusmuoto: koulutusmuoto.kebabCase,
                  uuid: row.id
                })
              );
            },
            cells: generateAsiaTableRows(
              row,
              intl,
              true,
              koulutusmuoto,
              vstTyypit
            )
          };
        }, hakemusList || [])
      }
    ]
  };
};

export const generateKorjauksessaOlevatAsiatTableStructure = (
  hakemusList,
  intl,
  history,
  koulutusmuoto,
  vstTyypit
) => {
  const formatMessage = intl.formatMessage;
  const tableColumnSetup = asiatTableColumnSetup(
    true,
    koulutusmuoto,
    intl.locale,
    true
  );
  return [
    generateAsiatTableHeaderStructure(formatMessage, tableColumnSetup),
    {
      role: "tbody",
      rowGroups: [
        {
          rows: addIndex(map)(row => {
            let actions = [];
            if (row.tila === "VALMISTELUSSA") {
              actions.push({
                id: "esittelyyn",
                text: formatMessage(common["asiaTable.actions.esittelyssa"])
              });
            } else if (row.tila === "ESITTELYSSA") {
              actions.push(
                {
                  id: "valmisteluun",
                  text: formatMessage(common["asiaTable.actions.valmistelussa"])
                },
                {
                  id: "paata",
                  text: formatMessage(common["asiaTable.actions.paatetty"])
                }
              );
            }
            return {
              id: row.uuid,
              onClick: async row => {
                history.push(
                  localizeRouteKey(intl.locale, AppRoute.Asia, intl, {
                    koulutusmuoto: koulutusmuoto.kebabCase,
                    uuid: row.id
                  })
                );
              },
              cells: generateAsiaTableRows(
                row,
                intl,
                true,
                koulutusmuoto,
                vstTyypit
              )
            };
          }, hakemusList || [])
        }
      ]
    },
    {
      role: "tfoot"
    }
  ];
};

export const generatePaatetytAsiatTableStructure = (
  hakemusList,
  intl,
  history,
  koulutusmuoto,
  vstTyypit
) => {
  return {
    role: "tbody",
    rowGroups: [
      {
        rows: addIndex(map)(row => {
          return {
            id: row.uuid,
            onClick: async row => {
              history.push(
                localizeRouteKey(intl.locale, AppRoute.Asia, intl, {
                  koulutusmuoto: koulutusmuoto.kebabCase,
                  uuid: row.id
                })
              );
            },
            cells: generateAsiaTableRows(
              row,
              intl,
              false,
              koulutusmuoto,
              vstTyypit
            )
          };
        }, hakemusList || [])
      }
    ]
  };
};
