import { Fragment, ReactElement } from "react";
import { Helmet } from "react-helmet";
import { Typography } from "@mui/material";
import { useIntl } from "react-intl";
import common from "i18n/definitions/common";
import { addIndex, map, values } from "ramda";
import { Route } from "react-router-dom";
import { AppRoute } from "routes/index";
import { LocalizedSwitch } from "modules/i18n/index";
import { getRouteUrl, ROUTE_PATH } from "helpers/routes";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { getKoulutusmuodot, localizeRouteKey } from "utils/common";
import EsiJaPerusopetus from "scenes/Koulutusmuodot/EsiJaPerusopetus/index";
import Koulutusmuotokortti from "components/03-templates/Koulutusmuotokortti";
import TaiteenPerusopetus from "scenes/Koulutusmuodot/TaiteenPerusopetus/index";
import AmmatillinenKoulutus from "scenes/Koulutusmuodot/AmmatillinenKoulutus/index";
import VapaaSivistystyo from "scenes/Koulutusmuodot/VapaaSivistystyo/index";
import Lukio from "scenes/Koulutusmuodot/Lukiokoulutus/index";
import TUVA from "scenes/Koulutusmuodot/TUVA";

const JarjestamisJaYllapitamisluvat = (): ReactElement => {
  const intl = useIntl();
  const { formatMessage, locale } = intl;
  const koulutusmuodot = getKoulutusmuodot(intl);

  const routes = {
    ammatillinenKoulutus: getRouteUrl(ROUTE_PATH.AMMATILLINEN_KOULUTUS),
    esiJaPerusopetus: getRouteUrl(ROUTE_PATH.ESI_JA_PERUSOPETUS),
    lukiokoulutus: getRouteUrl(ROUTE_PATH.LUKIOKOULUTUS),
    taiteenPerusopetus: getRouteUrl(ROUTE_PATH.TAITEEN_PERUSOPETUS),
    tuva: getRouteUrl(ROUTE_PATH.TUVA),
    vapaaSivistystyo: getRouteUrl(ROUTE_PATH.VAPAA_SIVISTYSTYO)
  };

  return (
    <Fragment>
      <BreadcrumbsItem
        to={localizeRouteKey(
          locale,
          AppRoute.JarjestamisJaYllapitamisluvat,
          intl
        )}>
        {formatMessage(common.jarjestamisJaYllapitamisluvat)}
      </BreadcrumbsItem>
      <LocalizedSwitch>
        {routes.esiJaPerusopetus && (
          <Route path={routes.esiJaPerusopetus}>
            <EsiJaPerusopetus />
          </Route>
        )}
        {routes.lukiokoulutus && (
          <Route path={routes.lukiokoulutus}>
            <Lukio />
          </Route>
        )}
        {routes.ammatillinenKoulutus && (
          <Route path={routes.ammatillinenKoulutus}>
            <AmmatillinenKoulutus />
          </Route>
        )}
        {routes.taiteenPerusopetus && (
          <Route path={routes.taiteenPerusopetus}>
            <TaiteenPerusopetus />
          </Route>
        )}
        {routes.vapaaSivistystyo && (
          <Route path={routes.vapaaSivistystyo}>
            <VapaaSivistystyo />
          </Route>
        )}
        {routes.tuva && (
          <Route path={routes.tuva}>
            <TUVA />
          </Route>
        )}
        <Route path="*">
          <article className="px-8 py-16 md:px-12 lg:px-32 xxl:px-0 xxl:max-w-8xl mx-auto">
            <Helmet htmlAttributes={{ lang: locale }}>
              <title>Oiva - {formatMessage(common.frontpage)}</title>
            </Helmet>
            <section>
              <Typography component="h1" variant="h1" className="py-4">
                {formatMessage(common.jarjestamisJaYllapitamisluvat)}
              </Typography>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 justify-items-auto">
                {addIndex(map)(
                  (koulutusmuoto, index) => (
                    <Koulutusmuotokortti
                      key={`koulutusmuotokortti-${index}`}
                      koulutusmuoto={koulutusmuoto}
                    />
                  ),
                  values(koulutusmuodot)
                )}
              </div>
            </section>
          </article>
        </Route>
      </LocalizedSwitch>
    </Fragment>
  );
};

export default JarjestamisJaYllapitamisluvat;
