import moment from "moment";
import * as tutkinnotHelper from "helpers/tutkinnot/";
import { defineLisatiedotBackendChangeObjects } from "helpers/ammatillisenLisatiedot";
import * as toimintaalueHelper from "helpers/toiminta-alue";
import * as opiskelijavuodetHelper from "helpers/opiskelijavuodet";
import * as muutHelper from "helpers/muut";
import * as kieletHelper from "helpers/kielet/index";
import * as koulutuksetHelper from "helpers/koulutukset";
import * as R from "ramda";

/**
 * Muodostaa ja palauttaa objektin, joka sisältää tallennettavat tiedot.
 * Koska funktiota kutsutaan esittelijöiden toimesta, ei perusteluita
 * muutoksille tarvita.
 *
 * @param muutMuuMaarays
 * @param {*} locale
 * @param {*} organisation
 * @param {*} lupa
 * @param {*} changeObjects
 * @param {*} uuid
 * @param {*} kohteet
 * @param {*} maaraystyypit
 * @param {*} muut
 * @param {*} lupaKohteet
 * @param {*} lomakedata
 * @param {*} muutospyynnonTila
 * @param {*} koulutukset
 */
export async function createObjectToSave(
  muutMuuMaarays,
  locale,
  organisation,
  lupa = {},
  changeObjects,
  uuid,
  kohteet,
  maaraystyypit,
  muut,
  lupaKohteet,
  lomakedata,
  muutospyynnonTila,
  koulutukset
) {
  // TUTKINNOT, OSAAMISALAT JA TUKINTOKIELET
  const tutkinnotKohde = R.find(
    R.propEq("tunniste", "tutkinnotjakoulutukset"),
    kohteet
  );
  const opetuskieliKohde = R.find(
    R.propEq("tunniste", "opetusjatutkintokieli"),
    kohteet
  );
  const tutkinnot = await tutkinnotHelper.defineBackendChangeObjects(
    {
      tutkinnotJaOsaamisalat: {
        muutokset: changeObjects.tutkinnot,
        perustelut: []
      },
      tutkintokielet: {
        muutokset: changeObjects.tutkintokielet,
        perustelut: []
      }
    },
    tutkinnotKohde,
    opetuskieliKohde,
    maaraystyypit,
    locale
  );

  // TUTKINTO LISÄTIEDOT
  const tutkinnotLisatiedot = defineLisatiedotBackendChangeObjects(
    changeObjects.tutkinnotLisatiedot,
    tutkinnotKohde,
    maaraystyypit,
    "lisatiedot_tutkinnot"
  );

  // KOULUTUKSET
  const koulutusMuutokset = koulutuksetHelper.getChangesToSave(
    {
      muutokset: changeObjects.koulutukset,
      perustelut: []
    },
    tutkinnotKohde,
    maaraystyypit,
    R.flatten([R.values(koulutukset.muut), R.values(koulutukset.poikkeukset)]),
    lupa.maaraykset
  );

  // KOULUTUKSET LISÄTIEDOT
  const koulutuksetLisatiedot = defineLisatiedotBackendChangeObjects(
    changeObjects.koulutuksetLisatiedot,
    tutkinnotKohde,
    maaraystyypit,
    "lisatiedot_koulutukset"
  );

  // OPETUSKIELET
  const opetuskielet = kieletHelper.getChangesToSave(
    {
      muutokset: changeObjects.opetuskielet,
      perustelut: []
    },
    opetuskieliKohde,
    maaraystyypit
  );

  // OPETUSKIELET LISÄTIEDOT
  const opetuskieletLisatiedot = defineLisatiedotBackendChangeObjects(
    changeObjects.opetuskieletLisatiedot,
    opetuskieliKohde,
    maaraystyypit,
    "lisatiedot_opetuskielet"
  );

  // TOIMINTA-ALUE
  const categoryFilterChangeObj = R.find(
    R.propEq("anchor", "categoryFilter"),
    changeObjects.toimintaalue
  );

  const toimintaalueKohde = R.find(
    R.propEq("tunniste", "toimintaalue"),
    kohteet
  );
  const toimintaalue = await toimintaalueHelper.defineBackendChangeObjects(
    {
      quickFilterChanges: R.path(
        ["properties", "quickFilterChanges"],
        categoryFilterChangeObj
      ),
      changesByProvince: R.path(
        ["properties", "changesByProvince"],
        categoryFilterChangeObj
      ),
      perustelut: []
    },
    toimintaalueKohde,
    maaraystyypit,
    lupa.maaraykset
  );

  // TOIMINTA-ALUE LISÄTIEDOT
  const toimintaalueLisatiedot = defineLisatiedotBackendChangeObjects(
    changeObjects.toimintaalueLisatiedot,
    toimintaalueKohde,
    maaraystyypit,
    "lisatiedot_toimintaalue"
  );

  // OPISKELIJAVUODET
  const opiskelijavuodetKohde = R.find(
    R.propEq("tunniste", "opiskelijavuodet"),
    kohteet
  );
  const opiskelijavuodet = opiskelijavuodetHelper.createBackendChangeObjects(
    {
      muutokset: changeObjects.opiskelijavuodet,
      perustelut: []
    },
    opiskelijavuodetKohde,
    maaraystyypit,
    muut,
    lupaKohteet,
    changeObjects.muut,
    lomakedata.muut
  );

  // OPISKELIJAVUODET LISÄTIEDOT
  const opiskelijavuodetLisatiedot = defineLisatiedotBackendChangeObjects(
    changeObjects.opiskelijavuodetLisatiedot,
    opiskelijavuodetKohde,
    maaraystyypit,
    "lisatiedot_opiskelijavuodet"
  );

  // MUUT
  const muutMuutokset = muutHelper.getChangesToSave(
    {
      muutokset: changeObjects.muut,
      perustelut: []
    },
    R.find(R.propEq("tunniste", "muut"), kohteet),
    maaraystyypit,
    R.filter(
      maarays =>
        maarays.koodisto === "oivamuutoikeudetvelvollisuudetehdotjatehtavat",
      lupa.maaraykset || []
    ),
    muutMuuMaarays
  );

  let objectToSave = {
    alkupera: "ESITTELIJA",
    jarjestajaOid: organisation.oid,
    jarjestajaYtunnus: organisation.ytunnus,
    lupaUuid: lupa.uuid,
    tila: muutospyynnonTila || (uuid ? "VALMISTELUSSA" : "LUONNOS"),
    paivittaja: "string",
    paivityspvm: null,
    voimassaalkupvm: null,
    voimassaloppupvm: null,
    liitteet: [], // allAttachments,
    meta: {},
    muutokset: R.flatten([
      tutkinnot,
      tutkinnotLisatiedot,
      koulutusMuutokset,
      koulutuksetLisatiedot,
      opetuskielet,
      opetuskieletLisatiedot,
      toimintaalue,
      toimintaalueLisatiedot,
      opiskelijavuodet,
      opiskelijavuodetLisatiedot,
      muutMuutokset
    ]),
    uuid
  };

  const asianumeroObj = R.find(
    R.propEq("anchor", "topthree.asianumero.A"),
    changeObjects.topthree || []
  );
  objectToSave.asianumero = asianumeroObj ? asianumeroObj.properties.value : "";
  const paatospaivaObj = R.find(
    R.propEq("anchor", "topthree.paatospaiva.A"),
    changeObjects.topthree || []
  );
  objectToSave.paatospvm =
    paatospaivaObj && paatospaivaObj.properties.value
      ? moment(paatospaivaObj.properties.value).format("YYYY-MM-DD")
      : "";
  const voimaantulopaivaObj = R.find(
    R.propEq("anchor", "topthree.voimaantulopaiva.A"),
    changeObjects.topthree || []
  );
  objectToSave.voimassaalkupvm =
    voimaantulopaivaObj && voimaantulopaivaObj.properties.value
      ? moment(voimaantulopaivaObj.properties.value).format("YYYY-MM-DD")
      : "";
  const paattymispaivamaaraObj = R.find(
    R.propEq("anchor", "topthree.paattymispaivamaara.A"),
    changeObjects.topthree || []
  );
  objectToSave.voimassaloppupvm =
    paattymispaivamaaraObj && paattymispaivamaaraObj.properties.value
      ? moment(paattymispaivamaaraObj.properties.value).format("YYYY-MM-DD")
      : "";
  // This helps the frontend to initialize the first fields on form load.
  objectToSave.meta.topthree = changeObjects.topthree;

  return objectToSave;
}
