import { Fragment, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useChangeObjectsByAnchorWithoutUnderRemoval } from "stores/muutokset";
import { useIntl } from "react-intl";
import { v1 as uuidv1 } from "uuid";
import Lomake from "components/02-organisms/Lomake";
import { useAllSections } from "stores/lomakedata";
import { useChangeObjects } from "stores/muutokset";
import rajoitteetMessages from "i18n/definitions/rajoitteet";
import Rajoitedialogi from "components/02-organisms/Rajoitedialogi/index";
import { ToimintaAluePikavalinnat } from "enums";
import { concat, filter, flatten, mapObjIndexed, uniq, values } from "ramda";
import { getCurrentValue } from "graphHandling/graphUtils";
import { CellId as CellId_opetuskielet } from "processes/CellId";

const constants = {
  formLocations: ["rajoitteet"]
};

const Rajoitteet = ({
  isPreviewModeOn,
  isRestrictionsModeOn,
  kohdevaihtoehdot,
  koulutustyyppi,
  onChangesUpdate,
  pc,
  render,
  sectionId,
  rajoitemaaraykset
}) => {
  const dialogSectionId = "rajoitelomake";
  const { formatMessage } = useIntl();
  const [{ isRestrictionDialogVisible }] = useChangeObjects();
  const [osioidenData] = useAllSections();
  const [restrictionId, setRestrictionId] = useState(null);
  const [
    changeObjects,
    { setChanges, setRajoitelomakeChangeObjects, showNewRestrictionDialog }
  ] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: sectionId
  });

  const onAddRestriction = useCallback(() => {
    setRestrictionId(uuidv1());
    showNewRestrictionDialog();
  }, [setRestrictionId, showNewRestrictionDialog]);

  const onModifyRestriction = useCallback(
    rajoiteId => {
      setRestrictionId(rajoiteId);
      setRajoitelomakeChangeObjects(rajoiteId, sectionId, dialogSectionId);
      showNewRestrictionDialog();
    },
    [sectionId, setRajoitelomakeChangeObjects, showNewRestrictionDialog]
  );

  const onRemoveRestriction = useCallback(
    (rajoiteId, isMaarays) => {
      const baseAnchor = `${sectionId}_${rajoiteId}`;
      /** Jos poistettava rajoite on määräys, luodaan siitä muutosobjekti storeen */
      if (isMaarays) {
        const changeObj = {
          anchor: baseAnchor,
          properties: {
            rajoiteId: rajoiteId,
            tila: "POISTO"
          }
        };
        setChanges([changeObj], `rajoitepoistot_${rajoiteId}`);
      } else {
        setChanges([], baseAnchor);
      }
    },
    [sectionId, setChanges]
  );

  if (osioidenData && pc) {
    const processToimintaalue = pc.getProcess(
      ToimintaAluePikavalinnat.MAAKUNNAT_JA_KUNNAT
    );
    const processUlkomaat = pc.getProcess("ulkomaat");
    const gChipCs = processToimintaalue
      ? processToimintaalue.actions.readPath([
          "components",
          "groupedChipCollections",
          "properties",
          "gChipCs"
        ])
      : null;
    const ulkomaat = processUlkomaat
      ? processUlkomaat.actions.readPath(["components", "multiTextBox"])
      : null;
    const valitutKunnatJaMaakunnat = gChipCs
      ? flatten(
          values(
            mapObjIndexed(gCC => {
              const maakunta = gCC.properties.parentChip;
              const isMaakuntaChecked = getCurrentValue("isChecked", maakunta);
              const isMaakuntaIndeterminate = getCurrentValue(
                "isIndeterminate",
                maakunta
              );
              if (isMaakuntaChecked && !isMaakuntaIndeterminate) {
                return maakunta;
              } else {
                return filter(
                  chip => getCurrentValue("isChecked", chip),
                  gCC.properties.chipCollection.properties.chips
                );
              }
            }, gChipCs)
          )
        )
      : [];
    osioidenData.toimintaalue = { valitutKunnatJaMaakunnat, ulkomaat };

    const processOpetuskielet = pc.getProcess("opetuskielet");
    if (processOpetuskielet) {
      const components = processOpetuskielet.actions.readPath(["components"]);
      if (components) {
        const autocomplete1 =
          components[CellId_opetuskielet.INITIALIZE_AUTOCOMPLETE_1];
        const autocomplete2 =
          components[CellId_opetuskielet.INITIALIZE_AUTOCOMPLETE_2];
        if (autocomplete1 && autocomplete2) {
          const ensisijaiset = getCurrentValue("values", autocomplete1);
          const toissijaiset = getCurrentValue("values", autocomplete2);
          osioidenData.opetuskielet = uniq(concat(ensisijaiset, toissijaiset));
        }
      }
    }
  }

  return (
    <Fragment>
      {render ? render() : null}
      {isRestrictionDialogVisible && !isPreviewModeOn && (
        <Rajoitedialogi
          kohdevaihtoehdot={kohdevaihtoehdot}
          koulutustyyppi={koulutustyyppi}
          osioidenData={osioidenData}
          onChangesUpdate={onChangesUpdate}
          parentSectionId={sectionId}
          restrictionId={restrictionId}
          sectionId={dialogSectionId}></Rajoitedialogi>
      )}
      {isRestrictionsModeOn && !isPreviewModeOn && (
        <div className="max-w-8xl mx-auto bg-white pb-8 px-8">
          <Lomake
            isInExpandableRow={false}
            anchor={sectionId}
            changeObjects={changeObjects}
            data={{
              rajoitemaaraykset
            }}
            formTitle={formatMessage(rajoitteetMessages.rajoitteet)}
            functions={{
              onAddRestriction,
              onModifyRestriction,
              onRemoveRestriction
            }}
            mode={"listaus"}
            noPadding={true}
            onChangesUpdate={onChangesUpdate}
            path={constants.formLocations}
            showCategoryTitles={true}></Lomake>
        </div>
      )}
    </Fragment>
  );
};

Rajoitteet.propTypes = {
  isPreviewModeOn: PropTypes.bool,
  isRestrictionsModeOn: PropTypes.bool,
  kohdevaihtoehdot: PropTypes.array.isRequired,
  koulutustyyppi: PropTypes.string,
  onChangesUpdate: PropTypes.func,
  pc: PropTypes.object,
  render: PropTypes.func,
  sectionId: PropTypes.string,
  rajoitemaaraykset: PropTypes.array
};

export default Rajoitteet;
