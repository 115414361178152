import { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import { PropTypes } from "prop-types";
import { useIntl } from "react-intl";
import common from "i18n/definitions/common";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import commonMessages from "../../../i18n/definitions/common";
import Typography from "@mui/material/Typography";
import { localizeRouteKey } from "utils/common";
import { AppRoute } from "routes/index";
import { isEmpty, path, pathEq } from "ramda";
import { koulutustyypitMap } from "utils/constants";
import { CellId } from "processes/CellId";
import { renderAutocomplete } from "graphHandling/components/autocomplete/rendering";
import { renderSimpleButton } from "graphHandling/components/simpleButton/rendering";
import { renderAlert } from "graphHandling/components/alert/rendering";
import { renderRadioButtonsGroup } from "graphHandling/components/radioButtonGroup/rendering";
import { useHistory } from "react-router-dom";
import tuva from "i18n/definitions/tuva";

const AsialomakeTuva = props => {
  const { graph, koulutusmuoto, storeFunctions } = props;
  const history = useHistory();
  const intl = useIntl();
  const { formatMessage, locale } = intl;

  const asianhallintaUrl = localizeRouteKey(
    locale,
    AppRoute.Asianhallinta,
    intl,
    { koulutusmuoto: koulutusmuoto.kebabCase }
  );

  const esidialogiUrl = localizeRouteKey(
    locale,
    AppRoute.UusiAsiaEsivalinnat,
    intl,
    {
      koulutusmuoto: koulutusmuoto.kebabCase
    }
  );

  useEffect(() => {
    if (graph.custom.goToRoute?.value) {
      const nextRoute = graph.custom.goToRoute.value;
      storeFunctions.updateGraph(["custom", "goToRoute"], { value: "" });
      history.push(nextRoute);
    }
  }, [graph.custom.goToRoute, history, storeFunctions]);

  if (graph) {
    const koulutusmuotovalikko = graph
      ? graph.components[CellId.INITIALIZE_KOULUTUSMUOTOVALIKKO]
      : null;

    const lokalisaatiovalikko =
      graph.components[CellId.INITIALIZE_LOKALISAATIOVALIKKO];

    const organisaatiovalikko =
      graph.components[CellId.INITIALIZE_ORGANISAATIOVALIKKO];

    return (
      <Fragment>
        {isEmpty(graph) ? null : (
          <Fragment>
            <BreadcrumbsItem to={asianhallintaUrl}>
              {formatMessage(commonMessages.asianhallinta)}
            </BreadcrumbsItem>

            <BreadcrumbsItem to={esidialogiUrl}>
              {formatMessage(common.luoUusiAsia)}
            </BreadcrumbsItem>

            <Helmet htmlAttributes={{ lang: locale }}>
              <title>{`Oiva | ${formatMessage(common.asiat)}`}</title>
            </Helmet>

            <div className="flex-1 flex bg-gray-100 border-t mt-8 border-solid border-gray-300">
              <div className="mx-auto w-4/5 max-w-8xl bg-white px-16 py-12 m-8 relative border">
                <div className="max-w-[44rem] m-auto">
                  <Typography component="h1" variant="h1">
                    {formatMessage(common.luoUusiAsia)}
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    style={{
                      fontSize: "1.25rem",
                      fontWeight: 400,
                      lineHeight: "1rem",
                      paddingTop: 0
                    }}>
                    {koulutusmuoto.paasivunOtsikko}
                  </Typography>
                  <p className="mt-8">
                    {formatMessage(tuva.esivalinnatLomakeohje)}
                  </p>
                  <div className="flex flex-col">
                    <div className="mt-8">
                      <div className="mt4">
                        <Typography component="h3" variant="h3">
                          {formatMessage(tuva.esivalinnatOtsikko1)}
                        </Typography>

                        {renderRadioButtonsGroup(
                          graph.components[
                            CellId.INITIALIZE_KOULUTUSMUOTOVALIKKO
                          ]
                        )}
                      </div>
                      {!!organisaatiovalikko && (
                        <div
                          className={`mt-8 ${
                            path(
                              ["properties", "isDisabled"],
                              organisaatiovalikko
                            )
                              ? "opacity-40"
                              : ""
                          }`}>
                          <Typography component="h3" variant="h3">
                            {formatMessage(tuva.esivalinnatOtsikko2)}
                          </Typography>

                          {renderAutocomplete(
                            graph.components[
                              CellId.INITIALIZE_ORGANISAATIOVALIKKO
                            ]
                          )}
                          <p className="text-sm mt-2 text-gray-500">
                            {formatMessage(
                              tuva.esivalinnatOrganisaatiohaunOhje
                            )}
                          </p>
                        </div>
                      )}
                      <div className={"mt-8"}>
                        {organisaatiovalikko &&
                          pathEq(
                            ["properties", "value"],
                            koulutustyypitMap.LUKIO,
                            koulutusmuotovalikko
                          ) && (
                            <Fragment>
                              <div
                                className={`${
                                  path(
                                    ["properties", "options", 0, "isDisabled"],
                                    lokalisaatiovalikko
                                  )
                                    ? "opacity-40"
                                    : ""
                                }`}>
                                <Typography component="h3" variant="h3">
                                  {formatMessage(tuva.esivalinnatOtsikko3)}
                                </Typography>
                              </div>
                              {renderRadioButtonsGroup(
                                graph.components[
                                  CellId.INITIALIZE_LOKALISAATIOVALIKKO
                                ]
                              )}
                            </Fragment>
                          )}
                      </div>
                      <div className="my-8">
                        {renderAlert(
                          graph.components[CellId.SHOW_PAALUPAILMOITUS]
                        )}
                      </div>
                      <div className="my-8">
                        {renderAlert(
                          graph.components[CellId.SHOW_AVOIN_ASIAILMOITUS],
                          graph.components[
                            CellId.INITIALIZE_TAYDENNA_ASIAA_PAINIKE
                          ]
                        )}
                      </div>
                      <div className="flex justify-end my-8">
                        <div>
                          {renderSimpleButton(
                            graph.components[CellId.INITIALIZE_PERUUTA_PAINIKE]
                          )}
                        </div>
                        <div className="flex justify-end ml-4">
                          {renderSimpleButton(
                            graph.components[CellId.INITIALIZE_SEURAAVA_PAINIKE]
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  } else {
    return null;
  }
};

AsialomakeTuva.propTypes = {
  storeFunctions: PropTypes.object,
  graph: PropTypes.object,
  koulutusmuoto: PropTypes.object
};

export default AsialomakeTuva;
