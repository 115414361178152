/**
 * Apufunktioita
 */
import { Lupa } from "Lupa";
import { find, path, prop, propEq } from "ramda";
import { MetadataOfKieli, Organisation } from "types";

/**
 * Etsii käyttöliittymän kieltä koskevan määreen ja palauttaa sen arvon.
 * @param kieltenMetadatat Taulukollinen käyttöliittymän kielivaihtoehtoihin
 * liittyviä metadataobjekteja.
 * @param localeUpper FI / SV.
 * @param key Määre, jonka mukainen arvo halutaan funktiolta saada vastauksena.
 * @param localeKey Määre, johon localeUpper-tietoa verrataan metadataobjektin
 * löytämiseksi.
 * @returns Käyttöliittymän kieltä koskevan määreen arvo tai undefined.
 */
export const parseLocalizedField = (
  kieltenMetadatat: Array<MetadataOfKieli> = [],
  localeUpper = "FI",
  key: keyof MetadataOfKieli = "nimi",
  localeKey = "kieli"
): string | undefined => {
  const kielenMetadata = find(propEq(localeKey, localeUpper), kieltenMetadatat);
  return kielenMetadata
    ? (prop(key, kielenMetadata) as unknown as string)
    : undefined;
};

/**
 * Resolve name of the organizer from lupa based on given locale. If it doesn't exist,
 * resolve into other locale. We assume that only 'fi' and 'sv' locales exist.
 * @param organisation
 * @param primaryLocale
 * @return {any}
 */
export const resolveLocalizedOrganizationName = (
  organisation: Organisation,
  primaryLocale: string
): string => {
  const altLocale = primaryLocale === "fi" ? "sv" : "fi";
  let retval = path(["nimi", primaryLocale])(organisation);
  if (!retval) {
    retval = path(["nimi", altLocale])(organisation);
  }
  return retval as string;
};

/**
 * Resolve name of the VST oppilaitos from an oppilaitosmääräys in lupa.
 * @param lupa
 * @param locale
 * @return {string|*}
 */
export const resolveVSTOppilaitosNameFromLupa = (
  lupa: Lupa,
  locale: string
): string => {
  // TODO: Varmista, ettei maarays-muuttujaa luoda virheellisesti.
  const maarays = lupa.maaraykset.find(item => (item.koodisto = "oppilaitos"));
  if (maarays) {
    const fakelupa = {
      jarjestaja: { nimi: path(["organisaatio", "nimi"])(maarays) as string }
    };
    return resolveLocalizedOrganizerName(fakelupa, locale);
  } else return "";
};

/**
 * Resolve name of the organizer from lupa based on given locale. If it doesn't exist,
 * resolve into other locale. We assume that only 'fi' and 'sv' locales exist.
 * @param lupa
 * @param primaryLocale
 * @return {any}
 */
export const resolveLocalizedOrganizerName = (
  lupa: { jarjestaja: { nimi: string } } | Lupa,
  primaryLocale: string
): string => {
  const altLocale = primaryLocale === "fi" ? "sv" : "fi";
  let retval = path(["jarjestaja", "nimi", primaryLocale])(lupa);
  if (!retval) {
    retval = path(["jarjestaja", "nimi", altLocale])(lupa);
  }
  return retval as string;
};

/**
 * Given a koodisto koodi metadata array, return the localized message contained in given primaryLocale, or
 * alternative locale when locales are assumed to be either 'fi' or 'sv'.
 * @param messageObjects
 * @param primaryLocale
 * @return {*}
 */
export const resolveKoodiLocalization = (
  messageObjects: Array<{
    kieli: string;
    nimi: string;
  }>,
  locale = "FI"
): string | undefined => {
  const primaryLocale = locale.toUpperCase();
  const altLocale = primaryLocale === "FI" ? "SV" : "FI";
  const primaryObject = find(
    item => item.kieli === primaryLocale,
    messageObjects
  );
  const altObject = find(item => item.kieli === altLocale, messageObjects);

  return primaryObject
    ? primaryObject.nimi
    : altObject
    ? altObject.nimi
    : undefined;
};
