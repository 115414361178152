import { Fragment } from "react";
import PropTypes from "prop-types";
import ValmentavatKoulutukset from "./Koulutukset/ValmentavatKoulutukset";
import ATVKoulutukset from "./Koulutukset/ATVKoulutukset";
import Tyovoimakoulutukset from "./Koulutukset/Tyovoimakoulutukset";
import Kuljettajakoulutukset from "./Koulutukset/Kuljettajakoulutukset";
import { useIntl } from "react-intl";
import { Typography } from "@mui/material";
import common from "i18n/definitions/common";
import KoulutuksetLisatiedot from "./Koulutukset/KoulutusLisatiedot";

const MuutospyyntoWizardKoulutukset = ({
  isReadOnly,
  koulutukset,
  maaraykset,
  mode
}) => {
  const intl = useIntl();
  return (
    <Fragment>
      <Typography component="h3" variant="h3">
        {intl.formatMessage(common.koulutukset)}
      </Typography>

      <ValmentavatKoulutukset
        isReadOnly={isReadOnly}
        koulutukset={koulutukset}
        maaraykset={maaraykset}
        mode={mode}
      />

      <ATVKoulutukset
        isReadOnly={isReadOnly}
        koulutukset={koulutukset}
        maaraykset={maaraykset}
        mode={mode}
      />

      <Tyovoimakoulutukset
        isReadOnly={isReadOnly}
        koulutukset={koulutukset}
        maaraykset={maaraykset}
        mode={mode}
      />

      <Kuljettajakoulutukset
        isReadOnly={isReadOnly}
        koulutukset={koulutukset}
        maaraykset={maaraykset}
        mode={mode}
      />

      <KoulutuksetLisatiedot
        isReadOnly={isReadOnly}
        title={""}
        maaraykset={maaraykset}
        key={`lisatiedot_koulutukset`}
        mode={mode}
        sectionId={`lisatiedot_koulutukset`}
      />
    </Fragment>
  );
};

MuutospyyntoWizardKoulutukset.propTypes = {
  isReadOnly: PropTypes.bool,
  koulutukset: PropTypes.object,
  maaraykset: PropTypes.array,
  mode: PropTypes.string
};

export default MuutospyyntoWizardKoulutukset;
