import { ReactElement } from "react";
import { ComponentEvent } from "graphHandling/graphTypes";
import { addIndex, filter, has, length, map, propEq, values } from "ramda";
import TextBox from "components/00-atoms/TextBox/TextBox";

export type TietueProps = {
  createdAt: Date;
  id: string;
  isDeleted?: boolean;
  maaraysUuid?: string;
  placeholder: string;
  title: string;
  value: string;
};

export interface IMultiTextBoxProps {
  isVisible?: boolean;
  tietueet?: Record<string, TietueProps>;
}

interface IMultiTextBoxPropsAndEvents extends IMultiTextBoxProps {
  onChange: ComponentEvent;
}

const defaultProps = {
  isVisible: true,
  tietueet: {}
};

const MultiTextBox = ({
  isVisible = defaultProps.isVisible,
  tietueet = defaultProps.tietueet,
  onChange
}: IMultiTextBoxPropsAndEvents): ReactElement => {
  const tietueetArr = values(tietueet);
  const poistamattomatTietueet = filter(
    tietue =>
      (has("isDeleted", tietue) && propEq("isDeleted", false, tietue)) ||
      !has("isDeleted", tietue),
    tietueetArr
  );
  return (
    <div className={`grid gap-4 grid-cols-1 ${isVisible ? "" : "hidden"}`}>
      {addIndex(map)((_tietueProps, index) => {
        const tietueProps = _tietueProps as TietueProps;
        return tietueProps.isDeleted ? null : (
          <div className="flex items-start" key={`tietue-${index}`}>
            <TextBox
              id={tietueProps.id}
              isRemovable={length(poistamattomatTietueet) > 1}
              label={"Määräys"}
              onChange={onChange}
              placeholder={tietueProps.placeholder}
              title={tietueProps.title}
              value={tietueProps.value}
            />
          </div>
        );
      }, tietueetArr)}
    </div>
  );
};

export default MultiTextBox;
