import {
  CellImplementations,
  GraphStoreFunctions
} from "graphHandling/graphTypes";
import { CellId } from "processes/CellId";

// Funktiot, jotka määrittävät graafin solmujen sisällön.
import { checkOrganisaatiotFetch } from "./cellImplementations/checkOrganisaatiotFetch";
import { defineCellLokalisaatiovalikko } from "./cellImplementations/initialize_lokalisaatiovalikko";
import { defineCellOrganisaatiovalikko } from "./cellImplementations/initialize_organisaatiovalikko";
import { ilmoitusPaaluvanPuuttumisesta } from "./cellImplementations/show_paalupailmoitus";
import { ilmoitusAvoimestaAsiasta } from "./cellImplementations/show_avoinAsiailmoitus";
import { initialize_koulutusmuotovalikko } from "./cellImplementations/initialize_koulutusmuotovalikko";
import { koulutusmuotojenNoutaminen } from "./cellImplementations/fetch_koulutusmuodot";
import { organisaatioidenNoutaminen } from "./cellImplementations/fetch_organisaatiot";
import { paalupailmoituksenPoistaminen } from "./cellImplementations/hide_paalupailmoitus";
import { paaluvanNoutaminen } from "./cellImplementations/fetch_paalupa";
import { paivitaLokalisaatiovalikonTila } from "./cellImplementations/update_lokalisaatiovalikko";
import { paivitaOrganisaatiovalikonTila } from "./cellImplementations/update_organisaatiovalikko";
import { paivitaSeuraavaPainikkeenTila } from "./cellImplementations/update_seuraavaPainike";
import { peruutaPainike } from "./cellImplementations/initialize_peruutaPainike";
import { seuraavaPainike } from "./cellImplementations/initialize_seuraavaPainike";
import { avoinAsiailmoituksenPoistaminen } from "./cellImplementations/hide_avoinAsiailmoitus";
import { avoimenAsianNoutaminen } from "./cellImplementations/fetch_avoinAsia";

// Solmujen välisten kytkentöjen erikoisehdot.
import {
  onkoPaalupailmoitusNakyvilla,
  onkoAvoinAsiailmoitusNakyvilla,
  voidaankoAvoinAsiaNoutaa
} from "./connectionRules";
import { equals, prop } from "ramda";
import { Properties } from "utils/lomakkeet";
import { initialize_taydennaAsiaaPainike } from "./cellImplementations/initialize_taydennaAsiaaPainike";

export const graphDefinition = {
  cells: {
    [CellId.INITIALIZE_TUVA_ASIA_ESIVALINNAT]: {
      isFirstCell: true,
      name: "Start",
      out: {
        [CellId.FETCH_KOULUTUSMUODOT]: {},
        [CellId.INITIALIZE_PERUUTA_PAINIKE]: {},
        [CellId.INITIALIZE_SEURAAVA_PAINIKE]: {},
        [CellId.INITIALIZE_ORGANISAATIOVALIKKO]: {},
        [CellId.INITIALIZE_LOKALISAATIOVALIKKO]: {}
      }
    },
    [CellId.FETCH_KOULUTUSMUODOT]: {
      name: "Fetch: Koulutusmuodot",
      out: {
        [CellId.INITIALIZE_KOULUTUSMUOTOVALIKKO]: {}
      }
    },
    [CellId.INITIALIZE_KOULUTUSMUOTOVALIKKO]: {
      name: "Initialize: Koulutusmuotovalikko",
      out: {
        [CellId.FETCH_ORGANISATIONS]: {
          events: ["onChange"]
        },
        [CellId.SET_PAALUPAILMOITUS_AS_HIDDEN]: {
          events: ["onChange"],
          isConnected: onkoPaalupailmoitusNakyvilla
        },
        [CellId.SET_AVOIN_ASIAILMOITUS_AS_HIDDEN]: {
          events: ["onChange"],
          isConnected: onkoAvoinAsiailmoitusNakyvilla
        },
        [CellId.UPDATE_ORGANISAATIOVALIKKO]: {
          events: ["onChange"]
        }
      }
    },
    [CellId.FETCH_ORGANISATIONS]: {
      name: "Fetch: Organisaatiot"
    },
    [CellId.INITIALIZE_ORGANISAATIOVALIKKO]: {
      name: "Initialize: Organisaatiovalikko",
      out: {
        [CellId.CHECK_ORGANISAATIOT_FETCH]: {
          events: ["onChange", "onInputChange"]
        },
        [CellId.CHECK_AVOIN_ASIA_FETCH]: {
          events: ["onChange"]
        },
        [CellId.UPDATE_LOKALISAATIOVALIKKO]: {
          events: ["onChange"]
        },
        [CellId.UPDATE_SEURAAVA_PAINIKE]: {}
      }
    },
    [CellId.INITIALIZE_LOKALISAATIOVALIKKO]: {
      name: "Initialize: Lokalisaatiovalikko",
      out: {
        [CellId.CHECK_AVOIN_ASIA_FETCH]: {
          events: ["onChange"]
        }
      }
    },
    [CellId.FETCH_AVOIN_ASIA]: {
      name: "Fetch: Avoin asia",
      out: {
        [CellId.CHECK_AVOIN_ASIA_OLEMASSA]: {}
      }
    },
    [CellId.CHECK_AVOIN_ASIA_OLEMASSA]: {
      name: "Check: Onko avointa asiaaa olemassa?",
      out: {
        [CellId.FETCH_PAALUPA]: {
          edge: {
            name: "Ei"
          },
          isConnected: (storeFunctions: GraphStoreFunctions): boolean =>
            !storeFunctions.readPath(["custom", "avoinMuutospyynto"])
        },
        [CellId.SET_AVOIN_ASIAILMOITUS_AS_HIDDEN]: {
          edge: {
            name: "Ei"
          },
          isConnected: (storeFunctions: GraphStoreFunctions): boolean => {
            const properties = storeFunctions.readPath([
              "components",
              CellId.SHOW_AVOIN_ASIAILMOITUS,
              "properties"
            ]) as Properties;
            return equals(prop("isVisible", properties), true);
          }
        },
        [CellId.SHOW_AVOIN_ASIAILMOITUS]: {
          edge: {
            name: "Kyllä"
          },
          isConnected: (storeFunctions: GraphStoreFunctions): boolean => {
            return (
              !!storeFunctions.readPath(["custom", "avoinMuutospyynto"]) &&
              !onkoAvoinAsiailmoitusNakyvilla(storeFunctions)
            );
          }
        },
        [CellId.UPDATE_SEURAAVA_PAINIKE]: {
          edge: {
            name: "Kyllä"
          },
          isConnected: (storeFunctions: GraphStoreFunctions): boolean =>
            !!storeFunctions.readPath(["custom", "avoinMuutospyynto"])
        }
      }
    },
    [CellId.FETCH_PAALUPA]: {
      name: "Fetch: Päälupa",
      out: {
        [CellId.CHECK_PAALUPA]: {},
        [CellId.UPDATE_SEURAAVA_PAINIKE]: {}
      }
    },
    [CellId.CHECK_PAALUPA]: {
      name: "Check: Onko päälupa olemassa?",
      out: {
        [CellId.SHOW_PAALUPAILMOITUS]: {
          edge: {
            name: "Ei"
          },
          isConnected: (storeFunctions: GraphStoreFunctions): boolean =>
            !storeFunctions.readPath(["custom", "viimeisinPaalupa"]) &&
            !onkoPaalupailmoitusNakyvilla(storeFunctions)
        },
        [CellId.SET_PAALUPAILMOITUS_AS_HIDDEN]: {
          edge: {
            name: "Kyllä"
          },
          isConnected: (storeFunctions: GraphStoreFunctions): boolean => {
            return (
              !!storeFunctions.readPath(["custom", "viimeisinPaalupa"]) &&
              onkoPaalupailmoitusNakyvilla(storeFunctions)
            );
          }
        }
      }
    },
    [CellId.INITIALIZE_PERUUTA_PAINIKE]: {
      name: "Initialize: Peruuta-painike"
    },
    [CellId.INITIALIZE_SEURAAVA_PAINIKE]: {
      name: "Initialize: Seuraava-painike"
    },
    [CellId.SHOW_PAALUPAILMOITUS]: {
      name: "Show: Ilmoitus pääluvan puuttumisesta"
    },
    [CellId.SHOW_AVOIN_ASIAILMOITUS]: {
      name: "Show: Ilmoitus olemassa olevasta avoimesta asiasta",
      out: {
        [CellId.INITIALIZE_TAYDENNA_ASIAA_PAINIKE]: {}
      }
    },
    [CellId.SET_PAALUPAILMOITUS_AS_HIDDEN]: {
      name: "Hide: Ilmoitus pääluvan puuttumisesta"
    },
    [CellId.SET_AVOIN_ASIAILMOITUS_AS_HIDDEN]: {
      name: "Hide: Ilmoitus olemassa olevasta avoimesta asiasta"
    },
    [CellId.UPDATE_SEURAAVA_PAINIKE]: {
      name: "Update: Seuraava-painike"
    },
    [CellId.CHECK_AVOIN_ASIA_FETCH]: {
      name: "Check: Voidaanko avoin asia noutaa?",
      out: {
        [CellId.UPDATE_SEURAAVA_PAINIKE]: {
          edge: {
            name: "Ei"
          },
          isConnected: (storeFunctions: GraphStoreFunctions): boolean =>
            !voidaankoAvoinAsiaNoutaa(storeFunctions)
        },
        [CellId.FETCH_AVOIN_ASIA]: {
          edge: {
            name: "Kyllä"
          },
          isConnected: voidaankoAvoinAsiaNoutaa
        }
      }
    },
    [CellId.UPDATE_ORGANISAATIOVALIKKO]: {
      name: "Update: Organisaatiovalikko",
      out: {
        [CellId.UPDATE_LOKALISAATIOVALIKKO]: {},
        [CellId.UPDATE_SEURAAVA_PAINIKE]: {}
      }
    },
    [CellId.UPDATE_LOKALISAATIOVALIKKO]: {
      name: "Update: Lokalisaatiovalikko",
      out: {
        [CellId.UPDATE_SEURAAVA_PAINIKE]: {}
      }
    },
    [CellId.CHECK_ORGANISAATIOT_FETCH]: {
      name: "Check: Onko organisaatiolistaus vanhentunut?",
      out: {
        [CellId.FETCH_ORGANISATIONS]: {
          edge: {
            name: "Kyllä"
          },
          isConnected: checkOrganisaatiotFetch
        }
      }
    },
    [CellId.INITIALIZE_TAYDENNA_ASIAA_PAINIKE]: {}
  }
};

export const cellImplementations: CellImplementations = {
  [CellId.FETCH_KOULUTUSMUODOT]: koulutusmuotojenNoutaminen,
  [CellId.FETCH_ORGANISATIONS]: organisaatioidenNoutaminen,
  [CellId.INITIALIZE_KOULUTUSMUOTOVALIKKO]: initialize_koulutusmuotovalikko,
  [CellId.INITIALIZE_LOKALISAATIOVALIKKO]: defineCellLokalisaatiovalikko,
  [CellId.INITIALIZE_ORGANISAATIOVALIKKO]: defineCellOrganisaatiovalikko,
  [CellId.INITIALIZE_TAYDENNA_ASIAA_PAINIKE]: initialize_taydennaAsiaaPainike,
  [CellId.INITIALIZE_PERUUTA_PAINIKE]: peruutaPainike,
  [CellId.INITIALIZE_SEURAAVA_PAINIKE]: seuraavaPainike,
  [CellId.FETCH_PAALUPA]: paaluvanNoutaminen,
  [CellId.FETCH_AVOIN_ASIA]: avoimenAsianNoutaminen,
  [CellId.SET_PAALUPAILMOITUS_AS_HIDDEN]: paalupailmoituksenPoistaminen,
  [CellId.SET_AVOIN_ASIAILMOITUS_AS_HIDDEN]: avoinAsiailmoituksenPoistaminen,
  [CellId.SHOW_PAALUPAILMOITUS]: ilmoitusPaaluvanPuuttumisesta,
  [CellId.SHOW_AVOIN_ASIAILMOITUS]: ilmoitusAvoimestaAsiasta,
  [CellId.UPDATE_LOKALISAATIOVALIKKO]: paivitaLokalisaatiovalikonTila,
  [CellId.UPDATE_ORGANISAATIOVALIKKO]: paivitaOrganisaatiovalikonTila,
  [CellId.UPDATE_SEURAAVA_PAINIKE]: paivitaSeuraavaPainikkeenTila
};
