import {
  and,
  append,
  concat,
  filter,
  find,
  head,
  isNil,
  last,
  map,
  or,
  pathEq,
  prop,
  propEq,
  reject,
  split
} from "ramda";
import {
  Kohde,
  Maarays,
  Maaraystyyppi,
  Organisation
} from "../../koodistodatanTyypit";
import { Muutos } from "../../types";
import { Toiminto } from "../../enums";

const oppilaitoksenKuntaPoistot = (
  maaraykset: Array<Maarays>
): Array<Muutos> => {
  return map<Maarays, Muutos>((maarays: Maarays): Muutos => {
    return {
      maaraystyyppi: maarays.maaraystyyppi,
      kohde: maarays.kohde,
      meta: {},
      koodisto: maarays.koodisto,
      koodiarvo: maarays.koodiarvo,
      maaraysUuid: maarays.uuid,
      tila: Toiminto.POISTO
    };
  }, maaraykset);
};

export const createOppilaitoksenKuntaMuutokset = (
  oppilaitosKohde: Kohde,
  maaraystyypit: Array<Maaraystyyppi>,
  oppilaitos: Organisation,
  lupaMaaraykset?: Array<Maarays> | null
): Array<Muutos> => {
  let kotipaikatUris: Array<string> =
    prop("muutKotipaikatUris", oppilaitos) || [];
  kotipaikatUris = append(
    prop("kotipaikkaUri", oppilaitos),
    kotipaikatUris
  ) as Array<string>;
  const maaraykset = filter(maarays => {
    return and(
      or(
        propEq("koodisto", "kunta", maarays),
        propEq("koodisto", "maakunta", maarays)
      ),
      !pathEq(["meta", "oppilaitosmaarays"], "Sopimuskunta", maarays)
    );
  }, lupaMaaraykset || []);
  const muuttamattomat = [] as Array<Maarays>;
  const lisaykset = map<string, Muutos | null>(kotipaikkaUri => {
    const uriParts = split("_", kotipaikkaUri);
    const koodisto = head(uriParts) || "";
    const koodiarvo = last(uriParts) || "";
    const maarays = find(maarays => {
      return and(
        propEq("koodisto", koodisto, maarays),
        propEq("koodiarvo", koodiarvo, maarays)
      );
    }, maaraykset);
    if (maarays) {
      muuttamattomat.push(maarays);
      return null;
    }
    return {
      generatedId: `oppilaitoskuntamuutos-${Math.random()}`,
      kohde: oppilaitosKohde,
      meta: {},
      koodisto,
      koodiarvo,
      maaraystyyppi: find(
        propEq("tunniste", "OIKEUS"),
        maaraystyypit
      ) as Maaraystyyppi,
      tila: Toiminto.LISAYS
    };
  }, reject(isNil, kotipaikatUris) as Array<string>);

  const poistot = oppilaitoksenKuntaPoistot(
    filter(maarays => {
      return !find(lisays => {
        return and(
          propEq("koodisto", lisays.koodisto, maarays),
          propEq("koodiarvo", lisays.koodiarvo, maarays)
        );
      }, concat(lisaykset, muuttamattomat).filter(Boolean) as Array<Muutos>);
    }, maaraykset)
  );
  return concat(lisaykset, poistot).filter(Boolean) as Array<Muutos>;
};
