import { append, reject, isNil } from "ramda";
import { findParent } from "./findParent";
import { updateChangeObjectsArray } from "./updateChangeObjectsArray";
import { getChangeObjByAnchor } from "utils/muutokset";
import { uncheckRadioButtonsOnSameLevel } from "./uncheckRadioButtonsOnSameLevel";
import { ComponentWithMetadata } from "utils/lomakkeet";
import { ChangeObjects } from "utils/muutokset";

/**
 * Käy komponentit läpi edeten kohti 1. tasoa ja varmistaa, että polussa olevien
 * komponenttien isIndeterminate-määreen arvo on true luomalla tarvittaessa uuden,
 * kyseistä komponenttia koskevan muutosobjektin tai päivittämällä nykyistä
 * muutosobjektia.
 * @param componentWithMetadata Metatiedoilla höystetty komponentti.
 * @param componentsWithMetadata Taulukollinen metadatalla höystettyjä komponentteja.
 * @param changeObjects Taulukollinen muutosobjekteja.
 * @returns Taulukollinen muutosobjekteja.
 */
export function setIsIndeterminateAsTrueForPredecessors(
  componentWithMetadata: ComponentWithMetadata,
  componentsWithMetadata: Array<ComponentWithMetadata>,
  changeObjects: ChangeObjects = []
): ChangeObjects {
  // Selvitetään, onko komponentti riippuvainen tasoa ylempänä mahdollisesti
  // olevasta komponentista.
  const parentComponentWithMetadata = findParent(
    componentWithMetadata,
    componentsWithMetadata,
    ["CheckboxWithLabel", "RadioButtonWithLabel"]
  );

  // Jos komponentti on riippuvainen ylemmällä tasolla olevasta komponentista...
  if (
    parentComponentWithMetadata &&
    parentComponentWithMetadata.formId === componentWithMetadata.formId
  ) {
    const parentChangeObj = getChangeObjByAnchor(
      parentComponentWithMetadata.fullAnchor,
      changeObjects
    );

    if (!parentChangeObj && !componentWithMetadata.properties.isIndeterminate) {
      changeObjects = append(
        {
          anchor: parentComponentWithMetadata.fullAnchor,
          properties: reject(isNil)({
            metadata: parentComponentWithMetadata.properties.forChangeObject,
            isChecked: true,
            isIndeterminate: true
          })
        },
        changeObjects
      );
    } else if (parentChangeObj && !parentChangeObj.properties.isIndeterminate) {
      changeObjects = updateChangeObjectsArray(
        parentComponentWithMetadata,
        { isChecked: true, isIndeterminate: true },
        changeObjects
      );
    }

    // Jos tasoa ylempänä oleva komponentti on radio button, on sen kanssa samalla tasolla
    // olevat komponentit käytävä läpi eli poistettava niistä ruksit.
    if (parentComponentWithMetadata.name === "RadioButtonWithLabel") {
      changeObjects = uncheckRadioButtonsOnSameLevel(
        parentComponentWithMetadata,
        componentsWithMetadata,
        changeObjects
      );
    }

    // Jotta koko komponenttien polku tulisi käytyä läpi, kutsutaan tätä funktiota
    // tasoa ylempänä olevalle komponentille (parentComponentWithMetadata)
    return setIsIndeterminateAsTrueForPredecessors(
      parentComponentWithMetadata,
      componentsWithMetadata,
      changeObjects
    );
  }

  return changeObjects;
}
