import {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo
} from "react";
import { PropTypes } from "prop-types";
import { useIntl } from "react-intl";
import DialogTitle from "components/02-organisms/DialogTitle";
import Autocomplete from "components/02-organisms/Autocomplete";
import common from "i18n/definitions/common";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import {
  find,
  filter,
  map,
  mapObjIndexed,
  prop,
  propEq,
  sortBy,
  trim,
  values,
  isEmpty
} from "ramda";
import { resolveLocalizedOrganizationName } from "modules/helpers";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles, withStyles } from "@mui/styles";
import { fetchJSON } from "basedata";
import { backendRoutes } from "stores/utils/backendRoutes";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import Select from "react-select";
import informUser from "../../../i18n/definitions/informUser";
import languages from "../../../i18n/definitions/languages";
import { getRaw } from "../../../basedata";
import { koulutustyypitMap } from "utils/constants";
import { getKoulutusmuodot } from "utils/common";
import { AppRoute } from "routes/index";

const StyledButton = withStyles({
  root: {
    color: "#4C7A61",
    fontWeight: 400,
    fontSize: "0.9375rem",
    textTransform: "none"
  }
})(Button);

const StyledErrorIcon = withStyles({
  root: {
    color: "#E5C418"
  }
})(ErrorIcon);

const useStyles = makeStyles({
  fakeDisabled: {
    backgroundColor: "#B7CAC0",
    "&:hover": {
      backgroundColor: "#B7CAC0"
    }
  }
});

const defaultProps = {
  organisations: []
};

const UusiAsiaEsidialog = ({
  isVisible,
  onClose,
  onSelect,
  organisations = defaultProps.organisations,
  koulutustyyppi
}) => {
  const intl = useIntl();
  const [viimeisinLupa, setViimeisinLupa] = useState();
  const [selectedKJ, setSelectedKJ] = useState();
  const [isSearchFieldVisible, setIsSearchFieldVisible] = useState(false);
  const [organisation, setOrganisation] = useState(null);
  const [organisationStatus, setOrganisationStatus] = useState();
  const [selectedKoulutusmuoto, setSelectedKoulutusmuoto] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState();
  const inputEl = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isKJMissing, setIsKJMissing] = useState(false);
  const [isKoulutusmuotoMissing, setIsKoulutusmuotoMissing] = useState(false);
  const [isLanguageMissing, setIsLanguageMissing] = useState(false);
  const [existingMuutospyynto, setExistingMuutospyynto] = useState(false);
  const [organisationsInState, setOrganisationsToState] =
    useState(organisations);
  const [muutospyyntoKorjauksessa, setMuutospyyntoKorjauksessa] =
    useState(false);
  const classes = useStyles();

  const koulutusmuodot = filter(
    km =>
      km.koulutustyyppi === koulutustyypitMap.AMMATILLINEN_KOULUTUS ||
      km.koulutustyyppi === koulutustyypitMap.ESI_JA_PERUSOPETUS ||
      km.koulutustyyppi === koulutustyypitMap.LUKIO,
    getKoulutusmuodot(intl)
  );

  const Kielivalikko = (
    <Select
      id="luvan-kielivalinta"
      name="luvan-kieli"
      options={[
        {
          label: intl.formatMessage(languages.finnish),
          value: "fi"
        },
        {
          label: intl.formatMessage(languages.swedish),
          value: "sv"
        }
      ]}
      onChange={value => {
        setSelectedLanguage(value);
        setIsLanguageMissing(false);
      }}
      placeholder={intl.formatMessage(common.valitseLuvanKieli)}
      value={selectedLanguage}
    />
  );

  const Koulutusmuotovalikko =
    koulutusmuodot && koulutustyyppi === koulutustyypitMap.TUVA ? (
      <Select
        id="koulutusmuotovalikko"
        name="koulutusmuotovalikko"
        options={sortBy(
          prop("label"),
          values(
            mapObjIndexed(koulutusmuoto => {
              return koulutusmuoto.pascalCase !== "Tuva"
                ? {
                    label: koulutusmuoto.paasivunOtsikko,
                    value: koulutusmuoto.koulutustyyppi || ""
                  }
                : null;
            }, koulutusmuodot)
          ).filter(Boolean)
        )}
        onChange={value => {
          setSelectedKoulutusmuoto(value);
          setIsKoulutusmuotoMissing(false);
        }}
        placeholder={intl.formatMessage(common.valitseKoulutusmuoto)}
        value={selectedKoulutusmuoto}
      />
    ) : null;

  useEffect(() => {
    if (selectedKoulutusmuoto) {
      // Noudetaan KJ:t koulutusmuodon perusteella.
      getRaw(
        "organisaatiot",
        koulutustyyppi && !isEmpty(selectedKoulutusmuoto.value)
          ? `${backendRoutes.organisaatiot.path}?koulutustyyppi=${selectedKoulutusmuoto.value}`
          : backendRoutes.organisaatiot.path,
        ["organisaatiot"],
        backendRoutes.organisaatiot.minimumTimeBetweenFetchingInMinutes
      ).then(organisations => {
        setSelectedKJ(null);
        setOrganisationsToState(organisations);
      });
    }
  }, [selectedKoulutusmuoto]);

  // Käyttäjän tehdessä organisaatiohaun tai vaihtaessa luvan kieltä
  // tarkistetaan onko KJ:llä jo kyseisellä kielellä lupaa.
  useEffect(() => {
    if (koulutustyyppi === koulutustyypitMap.LUKIO) {
      const oid = prop("value", selectedKJ) || prop("oid", organisation);
      if (koulutustyyppi && oid && selectedLanguage) {
        (async () => {
          const viimeisinLupa = await getRaw(
            "viimeisinLupa",
            `${backendRoutes.viimeisinLupa.path}${oid}/viimeisin?koulutustyyppi=${koulutustyyppi}&kieli=${selectedLanguage.value}`,
            [],
            0
          );
          setViimeisinLupa(viimeisinLupa);
        })();
      }
      // Tuva-lupaa luodessa pitää tarkastaa, onko valitulla koulutuksen järjestäjällä olemassa olevaa lupaa
      // Mikäli lupaa ei löydy pitää siirtyminen muutospyynnölle estää
    } else if (koulutustyyppi === koulutustyypitMap.TUVA) {
      const oid = prop("value", selectedKJ);
      const selectedKoulutustyyppi = prop("value", selectedKoulutusmuoto);

      // Riippuen valitusta koulutusmuodosta, haetaan viimeisin lupa backend:ltä eri parametreillä.
      let backEndRoute;
      if (oid && selectedKoulutustyyppi !== undefined && selectedLanguage) {
        switch (selectedKoulutustyyppi) {
          case koulutustyypitMap.LUKIO:
            backEndRoute = `${backendRoutes.viimeisinLupa.path}${oid}/viimeisin?koulutustyyppi=${selectedKoulutustyyppi}&kieli=${selectedLanguage.value}`;
            break;
          case koulutustyypitMap.AMMATILLINEN_KOULUTUS:
            backEndRoute = `${backendRoutes.viimeisinLupa.path}${oid}/viimeisin`;
            break;
          default:
            backEndRoute = `${backendRoutes.viimeisinLupa.path}${oid}/viimeisin?koulutustyyppi=${selectedKoulutustyyppi}`;
        }
      }

      (async () => {
        const viimeisinLupa = backEndRoute
          ? await getRaw("viimeisinLupa", backEndRoute, [], 0)
          : null;
        setViimeisinLupa(viimeisinLupa);
      })();
    }
  }, [
    koulutustyyppi,
    organisation,
    selectedKJ,
    selectedLanguage,
    selectedKoulutusmuoto
  ]);

  const searchById = useCallback(async () => {
    const { value: id } = inputEl.current;
    setIsLoading(true);
    const result = await fetchJSON(
      `${backendRoutes.organisaatio.path}/${trim(id)}`
    );
    setIsLoading(false);
    setSelectedLanguage(null);
    setExistingMuutospyynto(false);
    setMuutospyyntoKorjauksessa(false);
    setOrganisation(result);
    setIsKJMissing(false);
    if (result) {
      const isLupaExisting = !!find(
        propEq("oid", result.oid),
        organisationsInState
      );
      if (isLupaExisting) {
        setOrganisationStatus("duplicate");
      } else if (result.status === "PASSIIVINEN") {
        setOrganisationStatus("passive");
      } else {
        setOrganisationStatus("ok");
      }
    } else {
      setOrganisationStatus("notfound");
    }
  }, [organisationsInState]);

  useEffect(() => {
    async function checkExisting() {
      if (
        ((organisation || selectedKJ) &&
          koulutustyyppi !== koulutustyypitMap.LUKIO) ||
        ((organisation || selectedKJ) &&
          koulutustyyppi === koulutustyypitMap.LUKIO &&
          selectedLanguage)
      ) {
        const oid =
          (organisation && organisation.oid) ||
          (selectedKJ && selectedKJ.value);
        const result = await fetchJSON(
          `${backendRoutes.muutospyynnot.path}?oid=${oid}${
            koulutustyyppi ? "&koulutustyyppi=" + koulutustyyppi : ""
          }&tilat=VALMISTELUSSA&tilat=ESITTELYSSA&tilat=KORJAUKSESSA&vainOmat=false${
            selectedLanguage ? "&kieli=" + selectedLanguage.value : ""
          }`
        );
        if (result) {
          const korjauksessa = find(propEq("tila", "KORJAUKSESSA"), result);
          if (korjauksessa) {
            setMuutospyyntoKorjauksessa(true);
          } else {
            setMuutospyyntoKorjauksessa(false);
            setExistingMuutospyynto(result.length > 0);
          }
        }
      }
    }
    checkExisting();
  }, [organisation, selectedKJ, selectedLanguage]);

  const isAcceptEnabled = useMemo(() => {
    switch (koulutustyyppi) {
      case koulutustyypitMap.LUKIO:
        return (!!selectedKJ || !!organisation) && !!selectedLanguage;
      case koulutustyypitMap.TUVA:
        return (
          (!!selectedKJ || !!organisation) &&
          !!selectedKoulutusmuoto &&
          !!selectedLanguage &&
          !!viimeisinLupa
        );
      default:
        return !!selectedKJ || !!organisation;
    }
  }, [
    selectedKJ,
    organisation,
    koulutustyyppi,
    selectedLanguage,
    viimeisinLupa
  ]);

  return organisationsInState ? (
    <Dialog open={isVisible} PaperProps={{ style: { overflowY: "visible" } }}>
      <DialogTitle onClose={onClose}>
        {intl.formatMessage(common.luoUusiAsia)}
      </DialogTitle>
      <DialogContent style={{ overflowY: "visible" }}>
        <div className="px-8 py-4 relative">
          {isSearchFieldVisible ? (
            <Fragment>
              <p className="mb-6">
                {!koulutustyyppi &&
                  intl.formatMessage(common.luoUusiAsiaEsidialogiInfo3)}
                {koulutustyyppi === koulutustyypitMap.ESI_JA_PERUSOPETUS &&
                  intl.formatMessage(common.luoUusiAsiaEsidialogiInfo3PO)}
                {koulutustyyppi === koulutustyypitMap.LUKIO &&
                  intl.formatMessage(common.luoUusiAsiaEsidialogiInfo3Lukio)}
                {koulutustyyppi === koulutustyypitMap.VAPAASIVISTYSTYO &&
                  intl.formatMessage(common.luoUusiAsiaEsidialogiInfo3VST)}
                {koulutustyyppi === koulutustyypitMap.TAITEEN_PERUSOPETUS &&
                  intl.formatMessage(common.luoUusiAsiaEsidialogiInfo3TPO)}
                {koulutustyyppi === koulutustyypitMap.TUVA &&
                  intl.formatMessage(common.luoUusiAsiaEsidialogiInfo3TUVA)}
              </p>
              <Typography component="h4" variant="h4">
                {!koulutustyyppi && intl.formatMessage(common.haeKJ)}
                {koulutustyyppi === koulutustyypitMap.ESI_JA_PERUSOPETUS &&
                  intl.formatMessage(common.haeKJPO)}
                {koulutustyyppi === koulutustyypitMap.LUKIO &&
                  intl.formatMessage(common.haeKJLukio)}
                {koulutustyyppi === koulutustyypitMap.VAPAASIVISTYSTYO &&
                  intl.formatMessage(common.haeKJVST)}
                {koulutustyyppi === koulutustyypitMap.TAITEEN_PERUSOPETUS &&
                  intl.formatMessage(common.haeKJTPO)}
                {koulutustyyppi === koulutustyypitMap.TUVA &&
                  intl.formatMessage(common.haeKJTUVA)}
              </Typography>
              <div className="flex items-center">
                <FormControl
                  style={{ flexGrow: koulutustyypitMap.ESI_JA_PERUSOPETUS }}>
                  <TextField
                    id="search-field"
                    label={intl.formatMessage(common.syotaHaettavaTunniste)}
                    InputProps={{
                      endAdornment: isLoading ? (
                        <CircularProgress style={{ height: "auto" }} />
                      ) : (
                        <IconButton
                          type="button"
                          aria-label={intl.formatMessage(
                            common.suoritaYtunnushaku
                          )}
                          onClick={searchById}
                          size="large">
                          <SearchIcon />
                        </IconButton>
                      ),
                      inputRef: inputEl,
                      onKeyUp: e => {
                        return e.key === "Enter" ? searchById() : null;
                      }
                    }}
                    variant="outlined"
                  />
                </FormControl>
                <StyledButton
                  onClick={() => {
                    setOrganisation(null);
                    setIsSearchFieldVisible(false);
                  }}
                  style={{ marginLeft: "auto" }}>
                  {intl.formatMessage(common.suljeHaku)}
                </StyledButton>
              </div>
              {organisation && organisationStatus === "ok" ? (
                <div>
                  <p className="my-4 text-gray-500 text-xs">
                    {!koulutustyyppi &&
                      intl.formatMessage(common.haullaLoytyiKJ)}
                    {koulutustyyppi === koulutustyypitMap.ESI_JA_PERUSOPETUS &&
                      intl.formatMessage(common.haullaLoytyiKJPO)}
                    {koulutustyyppi === koulutustyypitMap.LUKIO &&
                      intl.formatMessage(common.haullaLoytyiKJLukio)}
                    {koulutustyyppi === koulutustyypitMap.VAPAASIVISTYSTYO &&
                      intl.formatMessage(common.haullaLoytyiKJVST)}
                    {koulutustyyppi === koulutustyypitMap.TAITEEN_PERUSOPETUS &&
                      intl.formatMessage(common.haullaLoytyiKJTPO)}
                    {koulutustyyppi === koulutustyypitMap.TUVA &&
                      intl.formatMessage(common.haullaLoytyiKJTUVA)}
                  </p>
                  <p className="mb-2">
                    <CheckIcon color="primary" />{" "}
                    {organisation.nimi.fi || organisation.nimi.sv}
                  </p>
                  {selectedLanguage && viimeisinLupa ? (
                    <p className="mt-4 mb-12">
                      <StyledErrorIcon />{" "}
                      {intl.formatMessage(
                        informUser.voimassaOlevaJarjestamislupa,
                        {
                          xKielinen:
                            prop("value", selectedLanguage) === "fi"
                              ? intl.formatMessage(languages.suomenkielinen)
                              : intl.formatMessage(languages.ruotsinkielinen)
                        }
                      )}{" "}
                      {intl.formatMessage(
                        informUser.hyvaksymallaSiirrytaanLuvanMuokkaamiseen
                      )}
                    </p>
                  ) : null}
                </div>
              ) : null}
              {organisationStatus === "notfound" ? (
                <div>
                  <p className="my-4 text-gray-500 text-xs">
                    {intl.formatMessage(common.KJHakuEpaonnistui)}
                  </p>
                  <p className="mb-2">
                    {intl.formatMessage(common.KJHakuEpaonnistuiLisainfo)}{" "}
                    <a
                      href={`mailto:${intl.formatMessage(
                        common.yhteisetpalvelutEmailAddress
                      )}`}>
                      {intl.formatMessage(common.yhteisetpalvelutEmailAddress)}
                    </a>
                  </p>
                </div>
              ) : null}
              {organisation &&
              organisationStatus === "duplicate" &&
              !muutospyyntoKorjauksessa &&
              !existingMuutospyynto &&
              ((koulutustyyppi === koulutustyypitMap.LUKIO &&
                viimeisinLupa &&
                selectedLanguage) ||
                koulutustyyppi !== koulutustyypitMap.LUKIO) ? (
                <div>
                  <p className="my-4 text-gray-500 text-xs">
                    {!koulutustyyppi &&
                      intl.formatMessage(common.haullaLoytyiKJ)}
                    {koulutustyyppi === koulutustyypitMap.ESI_JA_PERUSOPETUS &&
                      intl.formatMessage(common.haullaLoytyiKJPO)}
                    {koulutustyyppi === koulutustyypitMap.LUKIO &&
                      intl.formatMessage(common.haullaLoytyiKJLukio)}
                    {koulutustyyppi === koulutustyypitMap.VAPAASIVISTYSTYO &&
                      intl.formatMessage(common.haullaLoytyiKJVST)}
                    {koulutustyyppi === koulutustyypitMap.TAITEEN_PERUSOPETUS &&
                      intl.formatMessage(common.haullaLoytyiKJTPO)}
                    {koulutustyyppi === koulutustyypitMap.TUVA &&
                      intl.formatMessage(common.haullaLoytyiKJTUVA)}{" "}
                  </p>
                  <p className="mb-2 text-xl">
                    <StyledErrorIcon />{" "}
                    {organisation.nimi.fi || organisation.nimi.sv}
                  </p>
                  <p className="mb-2">
                    {intl.formatMessage(common.loytyyjoVoimassaOlevaLupa)}
                  </p>
                </div>
              ) : null}
              {organisation && organisationStatus === "passive" ? (
                <div>
                  <p className="my-4 text-gray-500 text-xs">
                    {!koulutustyyppi &&
                      intl.formatMessage(common.haullaLoytyiKJ)}
                    {koulutustyyppi === koulutustyypitMap.ESI_JA_PERUSOPETUS &&
                      intl.formatMessage(common.haullaLoytyiKJPO)}
                    {koulutustyyppi === koulutustyypitMap.LUKIO &&
                      intl.formatMessage(common.haullaLoytyiKJLukio)}
                    {koulutustyyppi === koulutustyypitMap.VAPAASIVISTYSTYO &&
                      intl.formatMessage(common.haullaLoytyiKJVST)}
                    {koulutustyyppi === koulutustyypitMap.TAITEEN_PERUSOPETUS &&
                      intl.formatMessage(common.haullaLoytyiKJTPO)}
                    {koulutustyyppi === koulutustyypitMap.TUVA &&
                      intl.formatMessage(common.haullaLoytyiKJTUVA)}{" "}
                  </p>
                  <p className="mb-2 text-xl">
                    <StyledErrorIcon />{" "}
                    {organisation.nimi.fi || organisation.nimi.sv}
                  </p>
                  <p className="mb-2">
                    {intl.formatMessage(common.KJPassiivinen)}{" "}
                  </p>
                </div>
              ) : null}
              {isKJMissing && !organisation ? (
                <p className="mt-2">
                  <StyledErrorIcon />{" "}
                  {intl.formatMessage(common.oneKJMustBeSelected)}
                </p>
              ) : null}
              {organisation && existingMuutospyynto ? (
                <Fragment>
                  <div>
                    <p className="my-4 text-gray-500 text-xs">
                      {intl.formatMessage(common.haullaLoytyiKJ)}
                    </p>
                    <p className="mb-2 text-xl">
                      <StyledErrorIcon />{" "}
                      {organisation.nimi.fi || organisation.nimi.sv}
                    </p>
                  </div>
                  <p className="mt-2">
                    {intl.formatMessage(common.existingMuutospyynto)}
                  </p>
                </Fragment>
              ) : null}

              {organisation && muutospyyntoKorjauksessa ? (
                <div>
                  <p className="my-4 text-gray-500 text-xs">
                    {!koulutustyyppi &&
                      intl.formatMessage(common.haullaLoytyiKJ)}
                    {koulutustyyppi === koulutustyypitMap.ESI_JA_PERUSOPETUS &&
                      intl.formatMessage(common.haullaLoytyiKJPO)}
                    {koulutustyyppi === koulutustyypitMap.LUKIO &&
                      intl.formatMessage(common.haullaLoytyiKJLukio)}
                    {koulutustyyppi === koulutustyypitMap.VAPAASIVISTYSTYO &&
                      intl.formatMessage(common.haullaLoytyiKJVST)}
                    {koulutustyyppi === koulutustyypitMap.TAITEEN_PERUSOPETUS &&
                      intl.formatMessage(common.haullaLoytyiKJTPO)}{" "}
                  </p>
                  <p className="mb-2 text-xl">
                    {organisation.nimi.fi || organisation.nimi.sv}
                  </p>
                  <div className="inline-flex items-start">
                    <p className="mb-2">
                      <StyledErrorIcon className="mr-2" />
                      {intl.formatMessage(common.lupaOnKorjauksessa)}
                    </p>
                  </div>
                </div>
              ) : null}

              {organisation && koulutustyyppi === koulutustyypitMap.LUKIO ? (
                <div className="my-4 w-4/5 pr-6">{Kielivalikko}</div>
              ) : null}
              {isLanguageMissing ? (
                <p className="mt-2">
                  <StyledErrorIcon />{" "}
                  {intl.formatMessage(common.oneLanguageMustBeSelected)}
                </p>
              ) : null}
            </Fragment>
          ) : (
            <Fragment>
              {koulutustyyppi === koulutustyypitMap.TUVA ? (
                <div className="mb-6">
                  <p className="mb-6">
                    {koulutustyyppi === koulutustyypitMap.TUVA &&
                      intl.formatMessage(common.luoUusiAsiaInstructions1TUVA)}
                  </p>
                  {Koulutusmuotovalikko}
                </div>
              ) : null}
              <p className="mb-6">
                {!koulutustyyppi &&
                  intl.formatMessage(common.luoUusiAsiaInstructions)}
                {koulutustyyppi === koulutustyypitMap.ESI_JA_PERUSOPETUS &&
                  intl.formatMessage(common.luoUusiAsiaInstructionsPO)}
                {koulutustyyppi === koulutustyypitMap.LUKIO &&
                  intl.formatMessage(common.luoUusiAsiaInstructionsLukio)}
                {koulutustyyppi === koulutustyypitMap.VAPAASIVISTYSTYO &&
                  intl.formatMessage(common.luoUusiAsiaInstructionsVST)}
                {koulutustyyppi === koulutustyypitMap.TAITEEN_PERUSOPETUS &&
                  intl.formatMessage(common.luoUusiAsiaInstructionsTPO)}
              </p>
              {koulutustyyppi !== koulutustyypitMap.TUVA ||
              selectedKoulutusmuoto ? (
                <Autocomplete
                  closeMenuOnSelect={true}
                  id="list-of-organisations"
                  isMulti={false}
                  name="koulutuksen-jarjestaja"
                  options={sortBy(
                    prop("label"),
                    map(organisation => {
                      return organisation
                        ? {
                            label: resolveLocalizedOrganizationName(
                              organisation,
                              intl.locale
                            ),
                            value: organisation.oid
                          }
                        : null;
                    }, organisationsInState)
                  ).filter(Boolean)}
                  callback={(payload, values) => {
                    setSelectedLanguage(null);
                    setExistingMuutospyynto(false);
                    setMuutospyyntoKorjauksessa(false);
                    setSelectedKJ(values.value);
                  }}
                  title={""}
                  value={[selectedKJ]}
                />
              ) : null}
              {/* Jos KJ on valittuna, näytetään valikko, josta käyttäjän tulee valita kieli luvalle, jonka hän on aikeissa luoda. */}
              {(koulutustyyppi === koulutustyypitMap.LUKIO ||
                (koulutustyyppi === koulutustyypitMap.TUVA && selectedKJ)) && (
                <div className="mt-4">{Kielivalikko}</div>
              )}

              {selectedKJ &&
              existingMuutospyynto &&
              !muutospyyntoKorjauksessa ? (
                <p className="mt-2">
                  <StyledErrorIcon />{" "}
                  {intl.formatMessage(common.existingMuutospyynto)}
                </p>
              ) : null}
              {selectedKJ &&
              !existingMuutospyynto &&
              muutospyyntoKorjauksessa ? (
                <div className="inline-flex items-start">
                  <p className="mt-2">
                    <StyledErrorIcon className="mr-2" />
                    {intl.formatMessage(common.lupaOnKorjauksessa)}
                  </p>
                </div>
              ) : null}
              {/* Jos KJ:lla ei ole voimassa olevaa järjestämislupaa valitulla kielellä, näytetään aihetta koskeva ohjeteksti.  */}
              {koulutustyyppi === koulutustyypitMap.LUKIO &&
              selectedKJ &&
              selectedLanguage &&
              !viimeisinLupa ? (
                <p className="mt-4 mb-12">
                  <StyledErrorIcon />{" "}
                  {intl.formatMessage(
                    informUser.eiVoimassaOlevaaJarjestamislupaa,
                    {
                      xKielista:
                        prop("value", selectedLanguage) === "fi"
                          ? intl.formatMessage(languages.suomenkielista)
                          : intl.formatMessage(languages.ruotsinkielista)
                    }
                  )}{" "}
                  {intl.formatMessage(
                    informUser.hyvaksymallaSiirrytaanLupalomakkeelle
                  )}
                </p>
              ) : null}
              {(!!selectedKJ || !!organisation) &&
                !!selectedKoulutusmuoto &&
                !!selectedLanguage &&
                !viimeisinLupa && (
                  <p className="mt-4 mb-12">
                    <StyledErrorIcon />
                    {intl.formatMessage(
                      common.valitulleKoulutusmuodolleJarjestajalleKielelleEiloydyPaalupaa
                    )}
                  </p>
                )}
              <p className="my-4">
                {!koulutustyyppi &&
                  intl.formatMessage(common.luoUusiAsiaEsidialogiInfo3)}
                {koulutustyyppi === koulutustyypitMap.ESI_JA_PERUSOPETUS &&
                  intl.formatMessage(common.luoUusiAsiaEsidialogiInfo3PO)}
                {koulutustyyppi === koulutustyypitMap.LUKIO &&
                  intl.formatMessage(common.luoUusiAsiaEsidialogiInfo3Lukio)}
                {koulutustyyppi === koulutustyypitMap.VAPAASIVISTYSTYO &&
                  intl.formatMessage(common.luoUusiAsiaEsidialogiInfo3VST)}
                {koulutustyyppi === koulutustyypitMap.TAITEEN_PERUSOPETUS &&
                  intl.formatMessage(common.luoUusiAsiaEsidialogiInfo3TPO)}
                {koulutustyyppi === koulutustyypitMap.TUVA &&
                  intl.formatMessage(common.luoUusiAsiaEsidialogiInfo3TUVA)}
              </p>
              <StyledButton
                onClick={() => {
                  setSelectedKJ(null);
                  setIsSearchFieldVisible(true);
                }}
                startIcon={<SearchIcon />}>
                {!koulutustyyppi && intl.formatMessage(common.haeKJ)}
                {koulutustyyppi === koulutustyypitMap.ESI_JA_PERUSOPETUS &&
                  intl.formatMessage(common.haeKJPO)}
                {koulutustyyppi === koulutustyypitMap.LUKIO &&
                  intl.formatMessage(common.haeKJLukio)}
                {koulutustyyppi === koulutustyypitMap.VAPAASIVISTYSTYO &&
                  intl.formatMessage(common.haeKJVST)}
                {koulutustyyppi === koulutustyypitMap.TAITEEN_PERUSOPETUS &&
                  intl.formatMessage(common.haeKJTPO)}
                {koulutustyyppi === koulutustyypitMap.TUVA &&
                  intl.formatMessage(common.haeKJTUVA)}
              </StyledButton>
              {koulutustyyppi === koulutustyypitMap.TUVA &&
              isKoulutusmuotoMissing ? (
                <p className="mt-2">
                  <StyledErrorIcon />{" "}
                  {intl.formatMessage(common.oneKoulutusmuotoMustBeSelected)}
                </p>
              ) : isKJMissing && !selectedKJ ? (
                <p className="mt-2">
                  <StyledErrorIcon />{" "}
                  {intl.formatMessage(common.oneKJMustBeSelected)}
                </p>
              ) : isLanguageMissing ? (
                <p className="mt-2">
                  <StyledErrorIcon />{" "}
                  {intl.formatMessage(common.oneLanguageMustBeSelected)}
                </p>
              ) : null}
            </Fragment>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <div className="flex pr-6 pb-4">
          <div className="mr-4">
            <Button onClick={onClose} color="primary" variant="outlined">
              {intl.formatMessage(common.cancel)}
            </Button>
          </div>
          <Button
            is-fake-disabled={!isAcceptEnabled ? "true" : "false"}
            className={!isAcceptEnabled ? classes.fakeDisabled : ""}
            onClick={() => {
              const kj =
                isSearchFieldVisible && organisation
                  ? { value: organisation.oid }
                  : selectedKJ;
              if (koulutustyyppi === koulutustyypitMap.LUKIO && kj) {
                return selectedLanguage
                  ? onSelect(kj, selectedLanguage)
                  : !!setIsLanguageMissing(true);
              } else if (
                (koulutustyyppi !== koulutustyypitMap.TUVA && kj) ||
                (selectedKoulutusmuoto && kj && selectedLanguage)
              ) {
                if (koulutustyyppi === koulutustyypitMap.TUVA) {
                  return onSelect(
                    kj,
                    { value: selectedLanguage.value },
                    AppRoute.UusiHakemusTUVA,
                    {
                      koulutustyyppi: selectedKoulutusmuoto.value
                    }
                  );
                } else {
                  return onSelect(kj);
                }
              } else if (
                koulutustyyppi !== koulutustyypitMap.TUVA ||
                selectedKoulutusmuoto
              ) {
                setIsKJMissing(true);
              }
              if (koulutustyyppi === koulutustyypitMap.TUVA) {
                if (!selectedKoulutusmuoto) {
                  setIsKoulutusmuotoMissing(true);
                } else if (kj && !selectedLanguage) {
                  setIsLanguageMissing(true);
                }
              }
              return false;
            }}
            color="primary"
            disabled={existingMuutospyynto || muutospyyntoKorjauksessa}
            variant="contained">
            {intl.formatMessage(common.accept)}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  ) : null;
};

UusiAsiaEsidialog.propTypes = {
  // Boolean that tells if the dialog is open or closed.
  isVisible: PropTypes.bool,
  // Function that will be called when the dialog is going to be closed / hided.
  onClose: PropTypes.func.isRequired,
  // Function that will be called when user selects a koulutuksen järjestäjä
  onSelect: PropTypes.func.isRequired,
  // List of organisations
  organisations: PropTypes.array,
  // Koulutustyyppi
  koulutustyyppi: PropTypes.string
};

export default UusiAsiaEsidialog;
