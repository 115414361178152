import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import common from "i18n/definitions/common";
import { ROLE_ESITTELIJA } from "modules/constants";
import { useHistory } from "react-router-dom";
import { localizeRouteKey } from "utils/common";
import { AppRoute } from "routes";
import { PropTypes } from "prop-types";

export default function Koulutusmuotokortti({ koulutusmuoto }) {
  const history = useHistory();
  const intl = useIntl();
  const { formatMessage, locale } = intl;

  return (
    <Card>
      <CardActionArea
        onClick={() => {
          history.push(
            localizeRouteKey(locale, AppRoute.KoulutusmuodonEtusivu, intl, {
              koulutusmuoto: koulutusmuoto.kebabCase
            })
          );
        }}>
        <CardContent>
          <Typography gutterBottom variant="h3" component="h3">
            {koulutusmuoto.kortinOtsikko}
          </Typography>
          <Typography gutterBottom component="p">
            {koulutusmuoto.lyhytKuvaus}
          </Typography>
        </CardContent>
      </CardActionArea>
      {sessionStorage.getItem("role") === ROLE_ESITTELIJA ? (
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              history.push(
                localizeRouteKey(locale, AppRoute.AsianhallintaAvoimet, intl, {
                  koulutusmuoto: koulutusmuoto.kebabCase
                })
              );
            }}>
            {formatMessage(common.asianhallinta)}
          </Button>
        </CardActions>
      ) : null}
    </Card>
  );
}

Koulutusmuotokortti.propTypes = {
  koulutusmuoto: PropTypes.object
};
