import { Fragment } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import common from "i18n/definitions/common";
import lukiokoulutus from "i18n/definitions/lukiokoulutus";
import moment from "moment";
import Typography from "@mui/material/Typography";
import OpetustaAntavatKunnatHtml from "./opetustaAntavatKunnat";
import OpetuskieletHtml from "./opetuskielet";
import OpiskelijamaaratHtml from "./opiskelijamaarat";
import ErityisetKoulutustehtavatHtml from "./erityisetKoulutustehtavat";
import OpetuksenMuutEhdotHtml from "./muutEhdot";
import OikeusSisaoppilaitosmuotoiseenKoulutukseenHtml from "./oikeusSisaoppilaitosmuotoiseenKoulutukseen";
import ValtakunnallisetKehittamistehtavatHtml from "./valtakunnallisetKehittamistehtavat";
import { getVoimassaOlevatMaaraykset } from "helpers/muut";
import LupaLinkkiHTML, {
  LupaLinkkiTyyppi
} from "../../../../components/03-templates/HtmlLupanakyma/LupaLinkki.tsx";
import { DATE_FORMAT } from "utils/constants";
import ErityisenKoulutustehtavanRahoitusHtml from "scenes/Koulutusmuodot/Lukiokoulutus/JarjestamislupaHTML/erityisenKoulutustehtavanRahoitus";

/**
 * Funktio rakentaa lukiokoulutuksen HTML-lupanäkymän.
 * @param {*} lupa - Lupa, jonka tietoja hyödyntäen lupanäkymä muodostetaan.
 */
const JarjestamislupaJSX = ({ lupa }) => {
  const { formatMessage } = useIntl();

  const voimassaOlevatMaaraykset = getVoimassaOlevatMaaraykset(lupa.maaraykset);

  return (
    <Fragment>
      <Typography component="h2" variant="h2">
        {formatMessage(common.htmlLuvanOtsikko, {
          date: moment().format(DATE_FORMAT),
          koulutusmuodon: formatMessage(lukiokoulutus.genetiivi)
        })}
      </Typography>
      {lupa.loppupvm && (
        <p className="mb-4">
          {formatMessage(common.onVoimassa, {
            loppupvm: moment(lupa.loppupvm).format(DATE_FORMAT)
          })}
        </p>
      )}
      <p className="mb-4">{formatMessage(lukiokoulutus.esittelyteksti)}</p>

      <LupaLinkkiHTML maaraavaLupa={lupa} tyyppi={LupaLinkkiTyyppi.ALILUPA} />
      <OpetustaAntavatKunnatHtml maaraykset={voimassaOlevatMaaraykset} />
      <OpetuskieletHtml maaraykset={voimassaOlevatMaaraykset} />
      <OikeusSisaoppilaitosmuotoiseenKoulutukseenHtml
        maaraykset={voimassaOlevatMaaraykset}
      />
      <ErityisetKoulutustehtavatHtml maaraykset={voimassaOlevatMaaraykset} />
      <ErityisenKoulutustehtavanRahoitusHtml
        maaraykset={voimassaOlevatMaaraykset}
      />
      <ValtakunnallisetKehittamistehtavatHtml
        maaraykset={voimassaOlevatMaaraykset}
      />
      <OpiskelijamaaratHtml maaraykset={lupa.maaraykset} />
      <OpetuksenMuutEhdotHtml maaraykset={voimassaOlevatMaaraykset} />
    </Fragment>
  );
};

JarjestamislupaJSX.propTypes = {
  lupa: PropTypes.object.isRequired
};

export default JarjestamislupaJSX;
