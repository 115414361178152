import {
  compose,
  endsWith,
  filter,
  find,
  flatten,
  length,
  map,
  path,
  pathEq,
  prop,
  propEq
} from "ramda";
import {
  createMaarayksiaVastenLuodutRajoitteetDynaamisilleTekstikentilleBEObjects,
  getRajoitteetByValueStartsWith
} from "utils/rajoitteetUtils";
import { getMaarayksetByTunniste } from "helpers/lupa";
import {
  createDynamicTextBoxBeChangeObjects,
  createBECheckboxChangeObjectsForDynamicTextBoxes
} from "services/lomakkeet/dynamic";
import { luoMuutosobjektitLisatietokentasta } from "helpers/lisatiedot";
import { PaikallisenTietovarastonAvain } from "enums";
import localForage from "localforage";

export const defineBackendChangeObjects = async (
  changeObjects = {},
  maaraystyypit,
  locale,
  lupaMaaraykset,
  kohteet
) => {
  const { rajoitteetByRajoiteId } = changeObjects;

  const kohde = find(propEq("tunniste", "erityinenkoulutustehtava"), kohteet);
  const maaraykset = await getMaarayksetByTunniste(
    kohde.tunniste,
    lupaMaaraykset
  );
  const maaraystyyppi = find(propEq("tunniste", "OIKEUS"), maaraystyypit);
  const erityisetKoulutustehtavat = await localForage.getItem(
    PaikallisenTietovarastonAvain.PO_ERITYISET_KOULUTUSTEHTAVAT
  );

  const maarayksiaVastenLuodutRajoitteet =
    createMaarayksiaVastenLuodutRajoitteetDynaamisilleTekstikentilleBEObjects(
      maaraykset,
      rajoitteetByRajoiteId,
      kohteet,
      maaraystyypit,
      kohde
    );

  const muutokset = map(koulutustehtava => {
    // Checkbox-kenttien muutokset
    const checkboxChangeObj = find(
      compose(
        endsWith(`.${koulutustehtava.koodiarvo}.valintaelementti`),
        prop("anchor")
      ),
      changeObjects.erityisetKoulutustehtavat
    );

    const tehtavaanLiittyvatMaaraykset = filter(
      m =>
        propEq("koodiarvo", koulutustehtava.koodiarvo, m) &&
        propEq("koodisto", "poerityinenkoulutustehtava", m),
      maaraykset
    );

    // Kuvauskenttien muutokset kohdassa (muu koulutustehtava)
    const kuvausChangeObjects = filter(changeObj => {
      return (
        koulutustehtava.koodiarvo ===
          path(["metadata", "koodiarvo"], changeObj.properties) &&
        endsWith(".kuvaus", changeObj.anchor)
      );
    }, changeObjects.erityisetKoulutustehtavat);

    let checkboxBEchangeObject = null;
    let kuvausBEchangeObjects = [];

    const rajoitteetByRajoiteIdAndKoodiarvo = getRajoitteetByValueStartsWith(
      `${koulutustehtava.koodiarvo}-`,
      rajoitteetByRajoiteId
    );

    const isCheckboxChecked =
      (!!tehtavaanLiittyvatMaaraykset.length && !checkboxChangeObj) ||
      (checkboxChangeObj &&
        pathEq(["properties", "isChecked"], true, checkboxChangeObj));

    if (length(kuvausChangeObjects) && isCheckboxChecked) {
      kuvausBEchangeObjects = createDynamicTextBoxBeChangeObjects(
        kuvausChangeObjects,
        tehtavaanLiittyvatMaaraykset,
        isCheckboxChecked,
        koulutustehtava,
        maaraystyyppi,
        maaraystyypit,
        rajoitteetByRajoiteIdAndKoodiarvo,
        checkboxChangeObj,
        kohde,
        kohteet
      );
    } else {
      return createBECheckboxChangeObjectsForDynamicTextBoxes(
        checkboxChangeObj,
        koulutustehtava,
        rajoitteetByRajoiteIdAndKoodiarvo,
        kohteet,
        kohde,
        maaraystyypit,
        maaraystyyppi,
        tehtavaanLiittyvatMaaraykset,
        isCheckboxChecked,
        locale,
        "erityinenKoulutustehtava"
      );
    }

    return [checkboxBEchangeObject, kuvausBEchangeObjects];
  }, erityisetKoulutustehtavat);

  // Lisätietokentän muutosten käsittely
  const lisatietomuutokset = await luoMuutosobjektitLisatietokentasta(
    kohde,
    changeObjects.erityisetKoulutustehtavat
  );

  return flatten([
    muutokset,
    lisatietomuutokset,
    maarayksiaVastenLuodutRajoitteet
  ]).filter(Boolean);
};
