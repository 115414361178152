import {
  koulutusmuotoRoutes,
  yhteydenottoRoutes,
  tilastotRoutes,
  saavutettavuusselosteRoutes,
  sivukarttaRoutes
} from "../../routes/index";
import { append, split, path } from "ramda";
import { Component } from "utils/lomakkeet";
import { getPathByAnchor } from "../../services/lomakkeet/utils";

const allRoutes = [
  sivukarttaRoutes,
  yhteydenottoRoutes,
  tilastotRoutes,
  saavutettavuusselosteRoutes,
  koulutusmuotoRoutes
];

export enum ROUTE_PATH {
  JARJESTAMIS_JA_YLLAPITAJAMISLUVAT = "JarjestamisJaYllapitamisluvat",
  ESI_JA_PERUSOPETUS = "JarjestamisJaYllapitamisluvat.EsiJaPerusopetus",
  LUKIOKOULUTUS = "JarjestamisJaYllapitamisluvat.Lukiokoulutus",
  AMMATILLINEN_KOULUTUS = "JarjestamisJaYllapitamisluvat.AmmatillinenKoulutus",
  TAITEEN_PERUSOPETUS = "JarjestamisJaYllapitamisluvat.TaiteenPerusopetus",
  VAPAA_SIVISTYSTYO = "JarjestamisJaYllapitamisluvat.VapaaSivistystyo",
  TILASTOT = "Tilastot",
  TUVA = "JarjestamisJaYllapitamisluvat.TUVA",
  YHTEYDENOTTO = "Yhteydenotto",
  SIVUKARTTA = "Sivukartta",
  SAAVUTETTAVUUSSELOSTE = "Saavutettavuusseloste"
}

/**
 * Function returns an a route url value from apps route structure.
 * This is used to fetch url to reacts router
 * @param routePath - string of anchors
 * @returns - url string
 */
export function getRouteUrl(
  routePath: ROUTE_PATH | string
): string | undefined | void {
  const _path = append("Link", split(".", routePath));

  let foundRoute: Record<string, unknown> | undefined;
  allRoutes.forEach(currentRoutes => {
    currentRoutes.categories.forEach(route => {
      if (route.anchor == _path[0]) foundRoute = currentRoutes;
    });
  });
  const componentPath = getPathByAnchor(_path, foundRoute?.categories);
  const component: Component | undefined = path(
    componentPath,
    foundRoute?.categories
  );
  return component ? (component.properties.route as string) : undefined;
}
