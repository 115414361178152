import { addIndex, includes, map, prop } from "ramda";
import { Component } from "react";
import styled from "styled-components";
import {
  LUPA_VOIMASSAOLO_20181231_ASTI,
  LUPA_VOIMASSAOLO_20180731_ASTI,
  LUPA_VOIMASSAOLO_20180801_LUKIEN,
  LUPA_VOIMASSAOLO_20190101_LUKIEN
} from "./constants";

import moment from "moment";
import { PropTypes } from "prop-types";
import { getVoimassaoloaika, isNowBetweenDates } from "utils/common";

const TutkintoWrapper = styled.div`
  margin: 6px 0 6px 30px;
  font-size: 1rem;
  display: flex;
  position: relative;
`;

const Koodi = styled.span`
  flex: 1;
  flex-grow: 0.6;
`;

const Nimi = styled.span`
  flex: 6;
`;

const Pvm = styled.span`
  flex: 2;
`;

const RajoiteStyle = styled.div`
  margin-left: 125px;
`;

const Rajoite = props => {
  const { koodi, nimi, koodisto } = props;
  if (koodisto === "kieli") return "";
  return (
    <RajoiteStyle>
      lukuun&nbsp;ottamatta:&nbsp;{koodi}&nbsp;{nimi}
    </RajoiteStyle>
  );
};

Rajoite.propTypes = {
  koodi: PropTypes.string,
  koodisto: PropTypes.string,
  nimi: PropTypes.string
};

class Tutkinto extends Component {
  render() {
    const { koodi, nimi, rajoitteet, renderCheckbox, lupaAlkuPvm, meta, intl } =
      this.props;
    const alkupvm = prop("alkupvm", meta);
    const loppupvm = prop("loppupvm", meta);
    const indexedMap = addIndex(map);
    return (
      isNowBetweenDates(alkupvm, loppupvm) && (
        <div>
          <TutkintoWrapper>
            {renderCheckbox ? (
              <input
                type="checkbox"
                defaultValue="off"
                onChange={this.handleInputOnChange.bind(this)}
              />
            ) : null}
            <Koodi>{koodi}</Koodi>
            <Nimi>{nimi}</Nimi>
            <Pvm>
              {this.checkDateString(koodi, lupaAlkuPvm) ||
                getVoimassaoloaika(intl, alkupvm, loppupvm)}
            </Pvm>
          </TutkintoWrapper>
          {rajoitteet
            ? indexedMap(
                (rajoite, i) => <Rajoite {...rajoite} key={i} />,
                rajoitteet
              )
            : null}
        </div>
      )
    );
  }

  getInputValue() {
    return ""; // TODO: Korvaa tämä vanhentunut koodi: this.refs.tutkintoInput.checked;
  }

  handleInputOnChange() {
    const { koodi, nimi, maaraysId } = this.props;
    const val = this.getInputValue();

    if (val === true) {
      this.props.removeTutkinto({ koodi, nimi, maaraysId });
    } else {
      this.props.undoRemoveTutkinto({ koodi, nimi, maaraysId });
    }
  }

  checkDateString(koodi, lupaAlkuPvm) {
    if (moment(lupaAlkuPvm).isAfter("2018-12-31")) {
      return;
    }

    if (includes(koodi, LUPA_VOIMASSAOLO_20181231_ASTI)) {
      return "31.12.2018 asti";
    }
    if (includes(koodi, LUPA_VOIMASSAOLO_20180731_ASTI)) {
      return "31.07.2018 asti";
    }
    if (includes(koodi, LUPA_VOIMASSAOLO_20180801_LUKIEN)) {
      return "01.08.2018 lukien";
    }
    if (includes(koodi, LUPA_VOIMASSAOLO_20190101_LUKIEN)) {
      return "01.01.2019 lukien";
    }
  }
}

Tutkinto.propTypes = {
  koodi: PropTypes.string,
  lupaAlkuPvm: PropTypes.string,
  maaraysId: PropTypes.string,
  nimi: PropTypes.string,
  rajoitteet: PropTypes.array,
  removeTutkinto: PropTypes.func,
  renderCheckbox: PropTypes.bool,
  undoRemoveTutkinto: PropTypes.func,
  meta: PropTypes.object,
  intl: PropTypes.object
};

export default Tutkinto;
