import {
  assoc,
  flatten,
  forEach,
  includes,
  is,
  isEmpty,
  mapObjIndexed,
  path,
  prop,
  split,
  values
} from "ramda";
import {
  CellFn,
  CellImplementations,
  EventPayload,
  GraphDefinition,
  GraphFlow,
  GraphStoreFunctions,
  Out
} from "graphHandling/graphTypes";
import { IntlShape } from "react-intl";

interface IProcessSetup {
  actions: any;
  CellId: any;
  processDefinition: GraphDefinition;
  cellImplementations: CellImplementations;
  eventPayload?: EventPayload;
  trackingFunction?: (
    cellFnResults: Record<string, ReturnType<CellFn>>,
    isErroneous?: boolean
  ) => void;
  customParams?: Record<string, unknown>;
}

type Processes = Record<string, IProcessSetup>;

export type IProcessCollection = {
  addProcess: (
    processSetup: IProcessSetup,
    processId: string
  ) => IProcessCollection;
  getProcess: (id: string) => IProcessSetup;
  getProcesses: () => Processes;
  handleIncomingProcessToken: (
    cellId: string,
    processId: string,
    storeFunctions?: GraphStoreFunctions,
    eventPayload?: EventPayload,
    results?: any
  ) => Promise<any | GraphDefinition>;
};

export const createProcessCollection = (
  id: string,
  intl: IntlShape
): IProcessCollection => {
  const _intl = intl;
  const processes: Processes = {};
  let allResults: any = {};

  let timeoutId: number | undefined = undefined;

  async function handleIncomingProcessToken(
    cellId: string,
    processId: string,
    storeFunctions: GraphStoreFunctions,
    eventPayload?: EventPayload,
    results?: Record<string, unknown>
  ): Promise<any> {
    const processSetup = prop(processId, processes);

    const { cellImplementations, processDefinition } = processSetup;

    !!storeFunctions.updateFlow && storeFunctions.updateFlow(cellId);

    // Suoritetaan solun tehtävä.
    const cellFn = prop(cellId, cellImplementations);

    let _results: any = results || [];

    if (cellFn) {
      try {
        _results = await cellFn(
          storeFunctions,
          _intl,
          eventPayload,
          allResults,
          processSetup.customParams
        );
        allResults = assoc(cellId, _results, allResults);
        if (is(Object, _results)) {
          _results = mapObjIndexed((value, key) => {
            if (typeof value === "function") {
              return async (payload: EventPayload): Promise<boolean> => {
                const eventPayload = await value(payload);
                // Käsitellään solusta ulos johtavat reitit.
                return await handleOutgoingRoutes(
                  cellId,
                  processId,
                  storeFunctions,
                  _results,
                  key, // e.g. key = onChange, onClick
                  eventPayload
                );
              };
            }
            return value;
          }, _results as any) as any;

          !!storeFunctions.updateGraph &&
            storeFunctions.updateGraph(
              flatten(["components", split("_", cellId)]),
              _results
            );
        }
        !!processSetup.trackingFunction &&
          processSetup.trackingFunction({ [cellId]: _results });
      } catch (err) {
        !!processSetup.trackingFunction &&
          processSetup.trackingFunction({ [cellId]: err as string }, true);
      }
    }

    if (processDefinition.cy) {
      const recentlyVisitedCells = storeFunctions.readPath([
        "flow"
      ]) as GraphFlow;

      processDefinition.cy.nodes().removeClass("hasToken");

      forEach(flowItem => {
        const nodes = processDefinition.cy.nodes(`[id = "${flowItem.cellId}"]`);

        if (nodes[0]) {
          nodes[0].addClass("hasToken");
        }
      }, recentlyVisitedCells);
    }

    // Käsitellään solusta ulos johtavat reitit
    const outgoingRouteResults = await handleOutgoingRoutes(
      cellId,
      processId,
      storeFunctions,
      _results
    );

    return outgoingRouteResults;
  }

  async function handleOutgoingRoutes(
    cellId: string,
    processId: string,
    storeFunctions: GraphStoreFunctions,
    results?: any,
    eventName?: string,
    eventPayload?: EventPayload
  ): Promise<any | boolean> {
    let processSetup = prop(processId, processes);
    let processDefinition = processSetup.processDefinition;
    const out: Out | undefined = path(
      ["cells", cellId, "out"],
      processDefinition
    );

    // Käsitellään solusta ulos johtavat reitit.
    if (processSetup && out) {
      const resultOfTheRoutes = mapObjIndexed(
        async (route, _cellId: string) => {
          // processSetup = prop(out.processId as string, processes);
          // processDefinition = processSetup.processDefinition;

          const exitRule1 =
            route.isConnected === undefined ||
            route.isConnected(storeFunctions, processSetup.customParams);
          const exitRule2 = eventName
            ? includes(eventName, route.events || [])
            : !route.events ||
              isEmpty(route.events) ||
              includes("onInit", route.events);
          if (exitRule1 && exitRule2) {
            if (route.processId) {
              processId = route.processId;
              processSetup = prop(processId, processes);
              if (processSetup) {
                processDefinition = processSetup.processDefinition;
                storeFunctions = processSetup.actions;
              } else {
                return true;
              }
            }

            // Cytoscape-graafin päivitys tehdään vain canvaksen ollessa käytössä.
            if (processDefinition.cy) {
              const edges = processDefinition.cy.edges(
                `[id = "${cellId}-${_cellId}"]`
              );
              if (edges[0]) {
                edges[0].addClass("highlighted");
              }

              if (timeoutId) {
                clearTimeout(timeoutId);
              }

              timeoutId = window.setTimeout(() => {
                processDefinition.cy.edges().removeClass("highlighted");
              }, 3000);
            }
            const outgoingRouteResults = await handleIncomingProcessToken(
              _cellId,
              processId,
              storeFunctions,
              eventPayload,
              results
            );

            return outgoingRouteResults;
          }
        },
        out
      );

      return await Promise.all(values(resultOfTheRoutes));
    }

    return results;
  }

  const processCollection = (() => {
    const _id = id;
    return {
      addProcess: (processSetup: IProcessSetup, processId: string) => {
        processes[processId] = {
          ...processSetup
        };
        return processCollection;
      },
      getProcess: (id: string) => processes[id],
      getProcesses: () => processes,
      getId: () => _id,
      handleIncomingProcessToken: async (
        cellId: string,
        processId: string,
        storeFunctions?: GraphStoreFunctions,
        eventPayload?: EventPayload,
        results?: any
      ) => {
        const processSetup = prop(processId, processes);
        if (processSetup) {
          allResults = {};
          await handleIncomingProcessToken(
            cellId,
            processId,
            processSetup.actions,
            undefined,
            results
          );
          return allResults;
        }
        return new Promise(resolve => {
          return resolve(false);
        });
      }
    };
  })();

  return processCollection;
};
