import { Fragment } from "react";
import { useIntl } from "react-intl";
import Typography from "@mui/material/Typography";
import common from "i18n/definitions/common";
import tuva from "i18n/definitions/tuva";
import { PropTypes } from "prop-types";
import Opetuskielet from "components/03-templates/HtmlLupanakyma/Opetuskielet";
import { filter, pathEq, toLower } from "ramda";
export default function TuvaOpetuskieli({ maaraykset, parentKoulutusmuoto }) {
  const { formatMessage } = useIntl();
  const kielimaaraykset = filter(
    pathEq(["kohde", "tunniste"], "opetuskieli"),
    maaraykset
  );

  return !kielimaaraykset || !kielimaaraykset.length ? (
    <Fragment>
      <Typography component="h3" variant="h3">
        {formatMessage(common.opetuskieli)}
      </Typography>

      <p>
        {formatMessage(tuva.maaratyllaOpetuskielella, {
          koulutusmuoto: toLower(parentKoulutusmuoto.genetiivi)
        })}
      </p>
    </Fragment>
  ) : (
    <Opetuskielet maaraykset={maaraykset} />
  );
}

TuvaOpetuskieli.propTypes = {
  maaraykset: PropTypes.array,
  parentKoulutusmuoto: PropTypes.object
};
