import { Fragment } from "react";
import { find, length, path, toUpper } from "ramda";
import { useIntl } from "react-intl";
import education from "../../../../i18n/definitions/education";
import Typography from "@mui/material/Typography";
import { getRajoitteetFromMaarays } from "../../../../utils/rajoitteetUtils";
import { getLocalizedProperty } from "services/lomakkeet/utils";
import LisatiedotHtmlLupa from "components/03-templates/HtmlLupanakyma/Lisatiedot";
import rajoitteet from "../../../../i18n/definitions/rajoitteet";
import { PropTypes } from "prop-types";

export default function OikeusSisaoppilaitosmuotoiseenKoulutukseenHtml({
  maaraykset
}) {
  const intl = useIntl();
  const locale = toUpper(intl.locale);

  const opetuksenJarjestamismuoto = find(
    maarays =>
      maarays.kohde.tunniste === "sisaoppilaitosmuotoinenkoulutus" &&
      maarays.koodisto === "lukiooikeussisaooppilaitosmuotoiseenkoulutukseen",
    maaraykset
  );

  const lisatietomaarays = find(
    maarays =>
      maarays.kohde.tunniste === "sisaoppilaitosmuotoinenkoulutus" &&
      maarays.koodisto === "lisatietoja",
    maaraykset
  );

  let kuvaus = null;
  if (opetuksenJarjestamismuoto) {
    const jarjestamismuodonMetadata = path(
      ["koodi", "metadata"],
      opetuksenJarjestamismuoto
    );
    kuvaus =
      opetuksenJarjestamismuoto.meta.kuvaus ||
      getLocalizedProperty(jarjestamismuodonMetadata, locale, "kuvaus");
  }

  return opetuksenJarjestamismuoto || lisatietomaarays ? (
    <div className="mt-4">
      <Typography component="h3" variant="h3">
        {intl.formatMessage(education.opetuksenJarjestamismuoto)}
      </Typography>
      {opetuksenJarjestamismuoto ? (
        <ul className="ml-8 list-disc mb-4">
          <li
            key={opetuksenJarjestamismuoto.koodiarvo}
            className={`leading-bulletList ${
              length(opetuksenJarjestamismuoto.aliMaaraykset) ? "mb-2" : ""
            }`}>
            {kuvaus}
            <Fragment>
              {length(opetuksenJarjestamismuoto.aliMaaraykset)
                ? getRajoitteetFromMaarays(
                    opetuksenJarjestamismuoto.aliMaaraykset,
                    locale,
                    intl.formatMessage(rajoitteet.ajalla)
                  )
                : ""}
            </Fragment>
          </li>
        </ul>
      ) : null}
      <LisatiedotHtmlLupa lisatietomaarays={lisatietomaarays} />
    </div>
  ) : null;
}

OikeusSisaoppilaitosmuotoiseenKoulutukseenHtml.propTypes = {
  maaraykset: PropTypes.array
};
