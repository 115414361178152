import {
  compose,
  endsWith,
  find,
  flatten,
  forEach,
  groupBy,
  keys,
  last,
  length,
  map,
  nth,
  path,
  pathEq,
  pipe,
  prop,
  split,
  startsWith,
  test,
  toUpper,
  transduce
} from "ramda";
import { useIntl } from "react-intl";
import education from "../../../../i18n/definitions/education";
import Typography from "@mui/material/Typography";
import { isRajoiteVoimassaNew } from "../../../../utils/rajoitteetUtils";
import { getLocalizedProperty } from "services/lomakkeet/utils";
import LisatiedotHtmlLupa from "components/03-templates/HtmlLupanakyma/Lisatiedot";
import { Lupamaarays } from "Lupa";
import { ReactElement } from "react";
import { getVoimassaoloText } from "../../../../utils/common";
import { __ } from "i18n-for-browser";

type Props = {
  maaraykset: Array<Lupamaarays>;
};

export default function PoOpetuksenJarjestamismuotoHtml({
  maaraykset
}: Props): null | ReactElement {
  const intl = useIntl();
  const locale = toUpper(intl.locale);

  const opetuksenJarjestamismuoto: any = find(
    maarays =>
      pathEq(["kohde", "tunniste"], "opetuksenjarjestamismuoto", maarays) &&
      maarays.koodisto === "opetuksenjarjestamismuoto",
    maaraykset
  );

  const lisatietomaarays = find(
    maarays =>
      pathEq(["kohde", "tunniste"], "opetuksenjarjestamismuoto", maarays) &&
      maarays.koodisto === "lisatietoja",
    maaraykset
  );

  let kuvaus = null;
  if (opetuksenJarjestamismuoto) {
    const jarjestamismuodonMetadata = path(
      ["koodi", "metadata"],
      opetuksenJarjestamismuoto
    );
    kuvaus =
      opetuksenJarjestamismuoto.meta.kuvaus ||
      getLocalizedProperty(jarjestamismuodonMetadata, locale, "kuvaus");
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isRajoite: any = pipe(path(["anchor"]), startsWith("rajoitteet"));

  const rajoitteetAll: any[] = flatten(
    (opetuksenJarjestamismuoto &&
      opetuksenJarjestamismuoto.meta.changeObjects) ||
      []
  ).filter(isRajoite);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const rajoitteetGroupedById: any[] = groupBy(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    compose(last, split("_"), nth(0), split("."), prop("anchor")),
    rajoitteetAll
  );

  // rajoite.asetukset.0.tarkennin.komponentti
  const isRajoiteTitle = pipe(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    path(["anchor"]),
    test(
      /^rajoitteet_[^.]+.kohdennukset.[^.].rajoite.asetukset.[^.].tarkennin.komponentti/
    )
  );

  const isAlkuPvm = pipe(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    path(["anchor"]),
    endsWith("tarkennin.alkamispaiva")
  );

  const isPaattymisPvm = pipe(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    path(["anchor"]),
    endsWith("tarkennin.paattymispaiva")
  );

  const rajoiteIds = keys(rajoitteetGroupedById);
  let modifiedString = `<ul class="list-disc">`;
  forEach(rajoiteId => {
    const rajoite = rajoitteetGroupedById[rajoiteId];
    const isRajoiteVoimassa = isRajoiteVoimassaNew(rajoite);
    if (isRajoiteVoimassa) {
      // rajoite otsikko
      const transducer = compose(map(prop("label")));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const iterator = (acc, val) => {
        if (acc) {
          return acc + ", " + val;
        }
        return val;
      };

      const initialVal = "";
      const rajoiteTitleObj = rajoite.find(pipe(isRajoiteTitle));
      const rajoiteTitleArr = rajoiteTitleObj
        ? rajoiteTitleObj.properties.value
        : [];

      const title: string = transduce(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        transducer,
        iterator,
        initialVal,
        typeof rajoiteTitleArr === "object"
          ? [rajoiteTitleArr]
          : rajoiteTitleArr
      );

      if (title) {
        modifiedString += `<li class="list-disc">${title}</li>`;
      }

      // rajoite maaraaika
      let alkupvm = rajoite.find(isAlkuPvm);
      if (alkupvm) {
        alkupvm = alkupvm.properties.value;
      }
      let loppupvm = rajoite.find(isPaattymisPvm);
      if (loppupvm) {
        loppupvm = loppupvm.properties.value;
      }

      if (alkupvm || loppupvm) {
        modifiedString += `
        <ul class="list-disc">
            <li class="list-disc">
            ${getVoimassaoloText(
              alkupvm,
              loppupvm,
              locale,
              __("common.from"),
              __("common.until")
            )}</li>
        </ul>`;
      }
    }
  }, rajoiteIds);

  modifiedString += "</ul>";

  return opetuksenJarjestamismuoto || lisatietomaarays ? (
    <div className="mt-4">
      <Typography component="h3" variant="h3">
        {intl.formatMessage(education.opetuksenJarjestamismuoto)}
      </Typography>
      {opetuksenJarjestamismuoto ? (
        <ul className="ml-8 list-disc mb-4">
          <li
            key={opetuksenJarjestamismuoto.koodiarvo}
            className={`leading-bulletList ${
              length(opetuksenJarjestamismuoto.aliMaaraykset) ? "mb-2" : ""
            }`}>
            {kuvaus}
            <ul
              className="htmlContent ml-8"
              dangerouslySetInnerHTML={{ __html: modifiedString }}></ul>
          </li>
        </ul>
      ) : null}
      <LisatiedotHtmlLupa lisatietomaarays={lisatietomaarays} />
    </div>
  ) : null;
}
