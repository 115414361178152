import { CellImplementations } from "graphHandling/graphTypes";
import { initialize_eiMaariteltyKuvaus } from "./cellImplementations/initialize_eiMaariteltyKuvaus";
import { update_eiMaariteltyKuvaus } from "./cellImplementations/update_eiMaariteltyKuvaus";
import initialize_kumoaMuutokset from "./cellImplementations/initialize_kumoaMuutokset";
import { fetch_lupaJaMuutospyynto } from "graphs/fetch_lupaJaMuutospyynto";
import { initialize_suomiCheckbox } from "./cellImplementations/initialize_suomiCheckbox";
import { initialize_ulkomaatCheckbox } from "./cellImplementations/initialize_ulkomaatCheckbox";
import { update_suomiCheckbox } from "./cellImplementations/update_suomiCheckbox";
import { update_ulkomaatCheckbox } from "./cellImplementations/update_ulkomaatCheckbox";
import { initialize_lisatiedot } from "./cellImplementations/initialize_lisatiedot";
import { update_lisatiedot } from "./cellImplementations/update_lisatiedot";
import { CellId } from "processes/CellId";

const mainProcessId = "000";

export const graphDefinition = {
  cells: {
    [CellId.INITIALIZE_PROCESS_TOIMINTAALUE]: {
      isFirstCell: true,
      name: "Start",
      out: {
        [CellId.INITIALIZE_KUMOA_MUUTOKSET]: {},
        [CellId.FETCH_LUPA_JA_MUUTOSPYYNTO]: {}
      }
    },
    [CellId.FETCH_LUPA_JA_MUUTOSPYYNTO]: {
      name: "Fetch: Lupa ja muutospyyntö",
      out: {
        [CellId.INITIALIZE_SUOMI_CHECKBOX]: {},
        [CellId.INITIALIZE_ULKOMAAT_CHECKBOX]: {},
        [CellId.INITIALIZE_EI_MAARITELTY_KUVAUS]: {},
        [CellId.INITIALIZE_LISATIEDOT]: {}
      }
    },
    [CellId.INITIALIZE_EI_MAARITELTY_KUVAUS]: {
      name: "Initialize: Kuvauskenttä",
      out: {
        [CellId.UPDATE_EI_MAARITELTY_KUVAUS]: {
          events: ["onChange"]
        }
      }
    },
    [CellId.INITIALIZE_KUMOA_MUUTOKSET]: {
      name: "Initialize: Kumoa muutokset",
      out: {
        [CellId.ACTION_KUMOA_MUUTOKSET]: {
          events: ["onClick"],
          processId: "maakunnatJaKunnat"
        },
        [CellId.ACTION_KUMOA_MUUTOKSET]: {
          events: ["onClick"],
          processId: "suomi"
        },
        [CellId.ACTION_KUMOA_MUUTOKSET]: {
          events: ["onClick"],
          processId: "ulkomaat"
        },
        [CellId.UPDATE_MAIN_PROCESS]: {
          events: ["onClick"],
          processId: mainProcessId
        }
      }
    },
    [CellId.INITIALIZE_LISATIEDOT]: {
      name: "Initialize: Lisätiedot",
      out: {
        [CellId.UPDATE_LISATIEDOT]: {
          events: ["onChange"]
        }
      }
    },
    [CellId.INITIALIZE_SUOMI_CHECKBOX]: {
      name: "Initialize: Suomi-checkbox",
      out: {
        [CellId.UPDATE_SUOMI_CHECKBOX]: {
          events: ["onChange"]
        }
      }
    },
    [CellId.INITIALIZE_ULKOMAAT_CHECKBOX]: {
      name: "Initialize: Ulkomaat-checkbox",
      out: {
        [CellId.UPDATE_ULKOMAAT_CHECKBOX]: {
          events: ["onChange"]
        }
      }
    },
    [CellId.UPDATE_EI_MAARITELTY_KUVAUS]: {
      name: "Update: Kuvauskenttä",
      out: {
        [CellId.UPDATE_MAIN_PROCESS]: {
          processId: mainProcessId
        }
      }
    },
    [CellId.UPDATE_LISATIEDOT]: {
      name: "Update: Lisätiedot",
      out: {
        [CellId.UPDATE_MAIN_PROCESS]: {
          processId: mainProcessId
        }
      }
    },
    [CellId.UPDATE_SUOMI_CHECKBOX]: {
      name: "Update: Suomi-checkbox",
      out: {
        [CellId.UPDATE_EI_MAARITELTY_KUVAUS]: {}
      }
    },
    [CellId.UPDATE_ULKOMAAT_CHECKBOX]: {
      name: "Update: Ulkomaat-checkbox",
      out: {
        [CellId.UPDATE_EI_MAARITELTY_KUVAUS]: {}
      }
    }
  }
};

export const cellImplementations: CellImplementations = {
  [CellId.FETCH_LUPA_JA_MUUTOSPYYNTO]: fetch_lupaJaMuutospyynto,
  [CellId.INITIALIZE_EI_MAARITELTY_KUVAUS]: initialize_eiMaariteltyKuvaus,
  [CellId.INITIALIZE_KUMOA_MUUTOKSET]: initialize_kumoaMuutokset,
  [CellId.INITIALIZE_LISATIEDOT]: initialize_lisatiedot,
  [CellId.INITIALIZE_SUOMI_CHECKBOX]: initialize_suomiCheckbox,
  [CellId.INITIALIZE_ULKOMAAT_CHECKBOX]: initialize_ulkomaatCheckbox,
  [CellId.UPDATE_EI_MAARITELTY_KUVAUS]: update_eiMaariteltyKuvaus,
  [CellId.UPDATE_LISATIEDOT]: update_lisatiedot,
  [CellId.UPDATE_SUOMI_CHECKBOX]: update_suomiCheckbox,
  [CellId.UPDATE_ULKOMAAT_CHECKBOX]: update_ulkomaatCheckbox
};
