import { CellFn } from "graphHandling/graphTypes";
import { IMultiTextBox } from "utils/lomakkeet";
import { CellId } from "processes/CellId";

const currentCellId = CellId.INITIALIZE_MULTI_TEXTBOX;

export const action_kumoaMuutokset: CellFn = storeFunctions => {
  const multiTextBox = storeFunctions.readPath([
    "components",
    currentCellId
  ]) as IMultiTextBox;

  if (multiTextBox) {
    storeFunctions.updateComponent(currentCellId, {
      modifications: undefined
    });
  }
};
