import Drawer from "@mui/material/Drawer";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const SideNavigation = ({ children, isVisible, setIsMobileMenuVisible }) => {
  const handleClose = (e, reason) => {
    return reason ? setIsMobileMenuVisible(false) : false;
  };

  const useStyles = makeStyles(() => ({
    paper: {
      backgroundColor: "#4c7a61",
      color: "white",
      maxWidth: "20.625rem"
    }
  }));
  const classes = useStyles();

  return (
    <div data-testid="side-navigation">
      <Drawer
        classes={{
          paper: classes.paper
        }}
        open={isVisible}
        onClose={handleClose}
        variant="temporary">
        <div
          tabIndex={0}
          role="presentation"
          onClick={handleClose}
          onKeyDown={handleClose}>
          {children}
        </div>
      </Drawer>
    </div>
  );
};

SideNavigation.propTypes = {
  children: PropTypes.object,
  handleDrawerToggle: PropTypes.func,
  isVisible: PropTypes.bool,
  setIsMobileMenuVisible: PropTypes.func.isRequired
};

export default SideNavigation;
