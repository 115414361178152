import { map, path, toUpper } from "ramda";
import { PaikallisenTietovarastonAvain } from "enums";
import localForage from "localforage";

export const getKohdennuksenKohdekomponentti = async (
  isReadOnly,
  locale,
  inputId
) => {
  const kujalisamaareet = await localForage.getItem(
    PaikallisenTietovarastonAvain.JOISTA_LISAKSI
  );
  return {
    anchor: "kohde",
    name: "Autocomplete",
    layout: { indentation: "none" },
    styleClasses: ["w-4/5 xl:w-2/3 mb-6"],
    properties: {
      inputId,
      isMulti: false,
      isReadOnly,
      isVisible: !isReadOnly,
      options: map(maare => {
        const koodistoUri = path(["koodisto", "koodistoUri"], maare);
        return {
          value: `${koodistoUri}_${maare.koodiarvo}`,
          label: maare.metadata[toUpper(locale)].nimi
        };
      }, kujalisamaareet),
      value: ""
    }
  };
};
