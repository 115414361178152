import "../i18n-config";
import { __ } from "i18n-for-browser";
import { find, flatten, map, path, pathEq, propEq } from "ramda";
import { getLocalizedProperty } from "services/lomakkeet/utils";
import { isAdded, isInLupa, isRemoved } from "css/label";
import { PaikallisenTietovarastonAvain } from "enums";
import localForage from "localforage";

/**
 * Muodostaa lomakerakenteen oppilaitoksen alueellisuusasteen valitsemiseksi ja
 * lisätietojen kirjoittamiseksi.
 * @param {object} data Sekalaista pohjatietoa sisältävä objekti.
 * @param {object} Boolean-tyyppiset muuttujat.
 * @param {string} locale Käyttöliittymän kieli.
 * @returns Lomakerakenne (Array of objects)
 */
export default async function getFormStructureOfOppilaitoksenAlueellisuusJaValtakunnallisuus(
  { maaraykset = [] },
  { isPreviewModeOn, isReadOnly },
  locale
) {
  const _isReadOnly = isPreviewModeOn || isReadOnly;
  const lisatietomaarays = find(propEq("koodisto", "lisatietoja"), maaraykset);
  const maarays = find(
    propEq("koodisto", "vstoppilaitoksenalueellisuusjavaltakunnallisuus"),
    maaraykset
  );

  const items = await localForage.getItem(
    PaikallisenTietovarastonAvain.VST_OPPILAITOKSEN_VAIKUTUSALUE
  );
  const lisatiedot = await localForage.getItem(
    PaikallisenTietovarastonAvain.LISATIEDOT
  );

  const lisatiedotObj = find(
    pathEq(["koodisto", "koodistoUri"], "lisatietoja"),
    lisatiedot || []
  );

  return {
    isValid: true,
    structure: flatten([
      items
        ? map(item => {
            return {
              anchor: item.koodiarvo,
              categories:
                item.koodiarvo === "2"
                  ? [
                      {
                        anchor: "kuvaus",
                        components: [
                          {
                            anchor: "A",
                            name: "TextBox",
                            properties: {
                              isReadOnly: _isReadOnly,
                              title: __("common.kuvaus"),
                              value: path(["meta", "kuvaus"], maarays) || ""
                            }
                          }
                        ]
                      }
                    ]
                  : [],
              components: [
                {
                  anchor: "A",
                  name: "RadioButtonWithLabel",
                  properties: {
                    title: getLocalizedProperty(item.metadata, locale, "nimi"),
                    labelStyles: {
                      addition: isAdded,
                      removal: isRemoved,
                      custom: Object.assign(
                        {},
                        maarays && maarays.koodiarvo === item.koodiarvo
                          ? isInLupa
                          : {}
                      )
                    },
                    isChecked: maarays
                      ? item.koodiarvo === maarays.koodiarvo
                      : false,
                    isIndeterminate: false,
                    isPreviewModeOn,
                    isReadOnly
                  }
                }
              ]
            };
          }, items)
        : null,
      lisatiedotObj
        ? [
            {
              anchor: "lisatiedotTitle",
              layout: { margins: { top: "large" } },
              styleClasses: ["mt-10", "pt-10", "border-t"],
              components: [
                {
                  anchor: lisatiedotObj.koodiarvo,
                  name: "StatusTextRow",
                  properties: {
                    title: __("common.lisatiedotInfo")
                  }
                }
              ]
            },
            {
              anchor: "lisatiedot",
              components: [
                {
                  anchor: lisatiedotObj.koodiarvo,
                  name: "TextBox",
                  properties: {
                    forChangeObject: {
                      koodiarvo: lisatiedotObj.koodiarvo,
                      koodisto: lisatiedotObj.koodisto,
                      versio: lisatiedotObj.versio,
                      voimassaAlkuPvm: lisatiedotObj.voimassaAlkuPvm
                    },
                    isPreviewModeOn,
                    isReadOnly: _isReadOnly,
                    title: __("common.lisatiedot"),
                    value: lisatietomaarays ? lisatietomaarays.meta.arvo : ""
                  }
                }
              ]
            }
          ].filter(Boolean)
        : null
    ]).filter(Boolean)
  };
}
