import { defineMessages } from "react-intl";

export default defineMessages({
  tallennusEpaonnistui: {
    id: "informUser.tallennusEpaonnistui",
    defaultMessage: "Tallennus epäonnistui"
  },
  muutospyyntoTallennettu: {
    id: "informUser.muutospyyntoTallennettu",
    defaultMessage: "Muutospyyntö tallennettu!"
  },
  paatoskirjeTallennusEpaonnistui: {
    id: "informUser.paatoskirjeTallennusEpaonnistui",
    defaultMessage: "Päätöskirjeen haku ei onnistunut VAHVAsta."
  },
  asianumeroOnJoKaytossa: {
    id: "informUser.asianumeroOnJoKaytossa",
    defaultMessage: "Asianumero on jo käytössä toisella muutospyynnöllä"
  },
  latausOnnistuiPDFUudessaIkkunassa: {
    id: "informUser.latausOnnistuiPDFUudessaIkkunassa",
    defaultMessage:
      "Muutospyynnön lataaminen onnistui. PDF-muotoinen dokumentti avattiin uuteen selainikkunaan."
  },
  lataaminenOnnistuiJaPDFTarkasteltavana: {
    id: "informUser.lataaminenOnnistuiJaPDFTarkasteltavana",
    defaultMessage:
      "Muutospyynnön lataaminen onnistui. PDF-muotoinen dokumentti on valmis tarkasteltavaksi."
  },
  virheDokumentinLataamisessa: {
    id: "informUser.virheDokumentinLataamisessa",
    defaultMessage: "Virhe dokumentin lataamisessa"
  },
  muutospyyntoLahetetty: {
    id: "informUser.muutospyyntoLahetetty",
    defaultMessage: "Muutospyyntö lähetetty!"
  },
  lahettaminenEpaonnistui: {
    id: "informUser.lahettaminenEpaonnistui",
    defaultMessage: "Lähettäminen epäonnistui."
  },
  liitePoistettu: {
    id: "informUser.liitePoistettu",
    defaultMessage: "Liite poistettu"
  },
  liitteenPoistaminenEpaonnistui: {
    id: "informUser.liitteenPoistaminenEpaonnistui",
    defaultMessage: "Liitteen poistaminen epäonnistui"
  },
  dokumenttiaEiLoytynyt: {
    id: "informUser.dokumenttiaEiLoytynyt",
    defaultMessage: "Dokumenttia ei syystä tai toisesta löytynyt"
  },
  pdfDokumenttiLoytyi: {
    id: "informUser.pdfDokumenttiLoytyi",
    defaultMessage: "PDF-dokumentti löytyi."
  },
  muutospyynnonPoistaminenEpaonnistui: {
    id: "informUser.muutospyynnonPoistaminenEpaonnistui",
    defaultMessage: "Muutospyynnön eli asian poistaminen epäonnistui."
  },
  muutospyyntoPoistettu: {
    id: "informUser.muutospyyntoPoistettu",
    defaultMessage: "Muutospyyntö (Asia) poistettu!"
  },
  muutospyynnonTilaMuutettu: {
    id: "informUser.muutospyynnonTilaMuutettu",
    defaultMessage: "Muutospyynnön eli asian tila muutettu!"
  },
  muutospyynonTilanMuuttaminenEpaonnistui: {
    id: "informUser.muutospyynonTilanMuuttaminenEpaonnistui",
    defaultMessage: "Muutospyynnön eli asian tilaa ei voitu muuttaa."
  },
  eiVoimassaOlevaaJarjestamislupaa: {
    id: "informUser.eiVoimassaOlevaaJarjestamislupaa",
    defaultMessage:
      "Tällä koulutuksen järjestäjällä ei ole voimassa olevaa {xKielista} järjestämislupaa."
  },
  hyvaksymallaSiirrytaanLupalomakkeelle: {
    id: "informUser.hyvaksymallaSiirrytaanLupalomakkeelle",
    defaultMessage: "Hyväksymällä siirrytään luvan tietojen syöttämiseen."
  },
  hyvaksymallaSiirrytaanLuvanMuokkaamiseen: {
    id: "informUser.hyvaksymallaSiirrytaanLuvanMuokkaamiseen",
    defaultMessage: "Hyväksymällä siirrytään luvan muokkaamiseen."
  },
  voimassaOlevaJarjestamislupa: {
    id: "informUser.voimassaOlevaJarjestamislupa",
    defaultMessage:
      "Tällä koulutuksen järjestäjällä on jo voimassa oleva {xKielinen} järjestämislupa."
  },
  muutospyynnonValidointiVirhe: {
    id: "informUser.muutospyynnonValidointiVirhe",
    defaultMessage: "Asian tilaa ei voitu muuttaa.\nLisää puuttuvat tiedot:"
  },
  paatospvm_is_required: {
    id: "informUser.paatospvm_is_required",
    defaultMessage: "Päätöspäivä"
  },
  voimassaalkuPvm_is_required: {
    id: "informUser.voimassaalkuPvm_is_required",
    defaultMessage: "Voimaantulopäivä"
  },
  paatoskirje_is_missing: {
    id: "informUser.paatoskirje_is_missing",
    defaultMessage: "Päätöskirje"
  },
  jarjestamisluvanValidointiVirhe: {
    id: "informUser.jarjestamisluvanValidointiVirhe",
    defaultMessage:
      "Järjestämislupaa ei voitu tallentaa.\nLisää puuttuvat tiedot:"
  },
  asianumero_is_required: {
    id: "informUser.asianumero_is_required",
    defaultMessage: "Asianumero"
  },
  diaarinumero_is_required: {
    id: "informUser.diaarinumero_is_required",
    defaultMessage: "Diaarinumero"
  },
  asianumero_wrong_format: {
    id: "informUser.asianumero_wrong_format",
    defaultMessage: "Asianumero on väärässä muodossa"
  },
  asianumero_is_required_input: {
    id: "informUser.asianumero_is_required_input",
    defaultMessage: "Asianumero tai diaarinumero on pakollinen"
  },
  diaarinumero_is_required_input: {
    id: "informUser.diaarinumero_is_required_input",
    defaultMessage: "Diaarinumero tai asianumero on pakollinen"
  },
  asianumero_wrong_format_input: {
    id: "informUser.asianumero_wrong_format_input",
    defaultMessage: "Asianumero on väärässä muodossa"
  }
});
