import {
  clone,
  compose,
  concat,
  filter,
  find,
  flatten,
  forEach,
  groupBy,
  indexOf,
  isEmpty,
  last,
  map,
  mapObjIndexed,
  nth,
  path,
  pipe,
  prop,
  propEq,
  split
} from "ramda";
import { __ } from "i18n-for-browser";

export function rajoitteet(
  data,
  isReadOnly,
  locale,
  changeObjects,
  { onAddRestriction, onModifyRestriction, onRemoveRestriction },
  changeObjectsByRajoiteId
) {
  const _changeObjectsByRajoiteId =
    changeObjectsByRajoiteId ||
    groupBy(
      compose(last, split("_"), nth(0), split("."), prop("anchor")),
      changeObjects
    );

  const getCombinedRajoitteetFromMaaraykset = () => {
    const rajoitteetKeys = [];
    const modifiedMaaraykset = clone(data.rajoitemaaraykset);
    const combinedMaaraykset = [];

    map(maarays => {
      const aliMaaraykset = path(["aliMaaraykset"], maarays);
      if (aliMaaraykset) {
        map(aliMaarays => {
          const rajoiteId = path([0, "meta", "rajoiteId"], aliMaarays);
          /* Tarkistaa onko rajoiteId jo olemassa. Jos rajoiteId löytyy,
          logiikka käy läpi kaikki määräykset ja poistaa määräykseltä rajoitteen samalla rajoiteId:llä
          ja luo uuden yhdistetyn rajoitteen. */
          if (indexOf(rajoiteId, rajoitteetKeys) === -1) {
            rajoitteetKeys.push(rajoiteId);
          } else {
            const foundRajoiteIds = [];
            let muokattuMaarays = {};
            mapObjIndexed((modifiedMaarays, index) => {
              if (
                modifiedMaarays.aliMaaraykset &&
                modifiedMaarays.koodisto === maarays.koodisto
              ) {
                const existingAliMaarays =
                  modifiedMaarays.aliMaaraykset[rajoiteId];
                if (existingAliMaarays !== undefined) {
                  modifiedMaarays.aliMaaraykset = filter(
                    moedifiedAliMaarays => {
                      return (
                        path([0, "meta", "rajoiteId"], moedifiedAliMaarays) !==
                        rajoiteId
                      );
                    },
                    modifiedMaarays.aliMaaraykset
                  );
                  if (indexOf(rajoiteId, foundRajoiteIds) === -1) {
                    foundRajoiteIds.push(rajoiteId);
                    muokattuMaarays = clone(modifiedMaarays);
                    muokattuMaarays.aliMaaraykset[rajoiteId] =
                      existingAliMaarays;
                    combinedMaaraykset.push(muokattuMaarays);
                  } else {
                    pipe(
                      path(["koodi", "metadata"]),
                      forEach(metadata => {
                        const kieli = find(propEq("kieli", metadata.kieli))(
                          path(["koodi", "metadata"], muokattuMaarays)
                        );
                        if (kieli) {
                          kieli.nimi += `, ${
                            modifiedMaarays.meta.arvo || metadata.nimi
                          }`;
                          // Ulkomaa käyttää arvoa näyttämiseen.
                          muokattuMaarays.meta.arvo += `, ${
                            modifiedMaarays.meta.arvo || metadata.nimi
                          }`;
                        }
                      })
                    )(modifiedMaarays);
                    muokattuMaarays.meta.kuvaus += `, ${modifiedMaarays.meta.kuvaus}`;
                  }
                  if (isEmpty(modifiedMaarays.aliMaaraykset)) {
                    delete modifiedMaaraykset[index];
                  }
                }
              }
            }, modifiedMaaraykset.filter(Boolean));
          }
        }, aliMaaraykset);
      }
    }, data.rajoitemaaraykset || []);

    return concat(modifiedMaaraykset || [], combinedMaaraykset || []).filter(
      Boolean
    );
  };

  const rajoitteetGrouped = mapObjIndexed(changeObjects => {
    return {
      changeObjects
    };
  }, _changeObjectsByRajoiteId);

  const lomake = flatten(
    [
      {
        anchor: "ohjeteksti",
        components: [
          {
            anchor: "rajoiteosio",
            name: "StatusTextRow",
            properties: {
              title:
                "Lupaan kohdistuvia rajoitteita voit tehdä lomakkeella tekemiesi valintojen perusteella."
            }
          }
        ],
        styleClasses: ["mb-6"]
      },
      {
        anchor: "rajoitteenLisaaminen",
        components: [
          {
            anchor: "painike",
            name: "SimpleButton",
            onClick: onAddRestriction,
            properties: {
              text: __("rajoitteet.lisaaRajoite"),
              color: "secondary",
              variant: "outlined"
            }
          }
        ]
      },
      {
        anchor: "listaus",
        components: [
          {
            anchor: "A",
            name: "RajoitteetList",
            properties: {
              areTitlesVisible: false,
              isBorderVisible: false,
              locale,
              onModifyRestriction,
              onRemoveRestriction,
              rajoitteet: rajoitteetGrouped, // rajoite muutosobjektit
              rajoitemaaraykset: getCombinedRajoitteetFromMaaraykset() // määräykset joihin liittyy rajoitteita. Rajoitteet groupattuna rajoiteId:n mukaan aliMaaraykset propertyyn
            }
          }
        ]
      }
    ].filter(Boolean)
  );

  return lomake;
}
