import { Fragment, useEffect, useState } from "react";
import {
  filter,
  find,
  length,
  map,
  toUpper,
  isEmpty,
  propEq,
  path,
  addIndex,
  pathEq,
  prop,
  sortBy
} from "ramda";
import { useIntl } from "react-intl";
import Typography from "@mui/material/Typography";
import { getRajoitteetFromMaarays } from "../../../../utils/rajoitteetUtils";
import LisatiedotHtmlLupa from "components/03-templates/HtmlLupanakyma/Lisatiedot";
import rajoitteet from "i18n/definitions/rajoitteet";
import common from "i18n/definitions/common";
import { PropTypes } from "prop-types";
import education from "../../../../i18n/definitions/education";
import localForage from "localforage";
import { PaikallisenTietovarastonAvain } from "enums";

const defaultProps = {
  maaraykset: []
};

export default function LuvanTaiteenalatHtml({
  maaraykset = defaultProps.maaraykset
}) {
  const intl = useIntl();
  const locale = toUpper(intl.locale);
  const [luvanTaiteenalatFromStorage, setLuvanTaiteenalat] = useState([]);
  const [luvanTaiteenalatKoodisto, setLuvanTaiteenalatKoodisto] = useState([]);

  /** Haetaan luvan taiteen alat ja koodisto lokaalista tietovarastosta. */
  useEffect(() => {
    localForage
      .getItem(PaikallisenTietovarastonAvain.LUVAN_TAITEENALAT_KOODISTO_TPO)
      .then(luvanTaiteenalatKoodisto => {
        setLuvanTaiteenalatKoodisto(luvanTaiteenalatKoodisto);
      })
      .catch(err => {
        console.error(err);
      });

    localForage
      .getItem(PaikallisenTietovarastonAvain.LUVAN_TAITEENALAT_TPO)
      .then(luvanTaiteenalat => {
        setLuvanTaiteenalat(luvanTaiteenalat);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  const luvanTaiteenalatMaaraykset = sortBy(
    prop("koodiarvo"),
    filter(
      maarays =>
        pathEq(["kohde", "tunniste"], "luvantaiteenalat", maarays) &&
        maarays.koodisto === "tpoluvantaiteenalat",
      maaraykset
    )
  );

  const lisatietomaarays = find(
    maarays =>
      pathEq(["kohde", "tunniste"], "luvantaiteenalat", maarays) &&
      maarays.koodisto === "lisatietoja",
    maaraykset
  );

  return (
    !isEmpty(luvanTaiteenalatMaaraykset) &&
    !isEmpty(luvanTaiteenalatKoodisto) &&
    luvanTaiteenalatFromStorage &&
    !isEmpty(luvanTaiteenalatFromStorage) && (
      <div className="mt-4">
        <Typography component="h3" variant="h3">
          {intl.formatMessage(common.opetusJotaLupaKoskee)}
        </Typography>
        <ul className="ml-8 list-disc mb-4">
          {addIndex(map)((maarays, index) => {
            const result = (
              <Fragment key={`${maarays.koodiarvo}-${index}`}>
                <li className="leading-bulletList">
                  {path(
                    ["metadata", locale, "nimi"],
                    find(
                      propEq("koodiarvo", maarays.koodiarvo),
                      luvanTaiteenalatFromStorage
                    )
                  )}
                </li>
                {maarays.meta.opetusAnnetaanLaajanOppimaaranMukaan && (
                  <ul className="list-disc ml-8">
                    <li className="list-disc">
                      {intl.formatMessage(education.laajanOppimaaranMukaan)}
                    </li>
                  </ul>
                )}
                {length(maarays.aliMaaraykset)
                  ? getRajoitteetFromMaarays(
                      maarays.aliMaaraykset,
                      locale,
                      intl.formatMessage(rajoitteet.ajalla)
                    )
                  : ""}
              </Fragment>
            );
            return result;
          }, luvanTaiteenalatMaaraykset)}
        </ul>
        <LisatiedotHtmlLupa lisatietomaarays={lisatietomaarays} />
      </div>
    )
  );
}

LuvanTaiteenalatHtml.propTypes = {
  maaraykset: PropTypes.array
};
