import {
  concat,
  find,
  isNil,
  lensIndex,
  map,
  path,
  propEq,
  reject,
  sort,
  uniq,
  view
} from "ramda";
import { Muutos, Kielet, Kieli } from "types";
import { LocaleUpper, Toiminto } from "enums";
import { sortLanguagesByName } from "./utils";
import { Kohde, Maaraystyyppi } from "koodistodatanTyypit";
import { ChangeObject, ChangeObjects } from "utils/muutokset";

/**
 * Järjestää OPH-koodiston kielet järjestykseen siten, että
 * arrangeOpetuskieletOPH-funktiolle 1. parametrinä annettuja
 * lyhenteitä vastaavat kielet ovat funktion palauttamassa
 * taulukossa ensimmäisinä.
 * @param kieltenLyhenteet Listalla ensimmäisinä näytettävien kielten lyhenteet.
 * @param ophKielet Kielten luettelo.
 * @param localeUpper Käyttöliittymän kieli kokonaan isoilla kirjaimilla kirjoitettuna.
 * @returns Kielet-tyyppinen objekti järjestettynä yllä kuvatulla tavalla.
 */
export const arrangeOpetuskieletOPH = (
  kieltenLyhenteet: Array<string>,
  ophKielet: Kielet,
  localeUpper: LocaleUpper
): Array<Kieli> => {
  const listanKarkeenAsetettavat: Array<Kieli> = reject(
    isNil,
    map((kielikoodi: string) => {
      return find(propEq("koodiarvo", kielikoodi), ophKielet);
    }, kieltenLyhenteet)
  );
  return uniq(
    concat(
      listanKarkeenAsetettavat,
      sortLanguagesByName(ophKielet, localeUpper)
    )
  );
};

export const getChangesToSave = (
  changeObjects: {
    muutokset: ChangeObjects;
  },
  kohde: Kohde,
  maaraystyypit: Array<Maaraystyyppi>
): Array<Muutos> => {
  const maaraystyyppi = find(propEq("tunniste", "VELVOITE"), maaraystyypit);
  if (maaraystyyppi) {
    return changeObjects.muutokset
      .map((changeObj: ChangeObject) => {
        const anchorParts = changeObj.anchor.split(".");
        const code = view(lensIndex(1), anchorParts);
        const meta: {
          maaraysUuid?: string | undefined;
        } = path(["properties", "metadata"], changeObj) || {};

        return {
          koodiarvo: code,
          koodisto: "oppilaitoksenopetuskieli",
          kohde,
          maaraystyyppi,
          maaraysUuid: meta.maaraysUuid,
          meta: {
            tunniste: "opetuskieli",
            changeObjects: [changeObj]
          },
          tila: meta.maaraysUuid ? Toiminto.POISTO : Toiminto.LISAYS
        };
      })
      .filter(Boolean);
  }
  return [];
};

/**
 * Järjestää parametrina annetun kielten taulukon siten, että
 * ensimmäisenä palautettavassa taulukossa ovat FI, SV, EN ja RU.
 * Muilta osin kielet järjestetään aakkosjärjestykseen.
 * @param languages Taulukollinen kieliä.
 * @param localeUpper FI | SV.
 * @returns Järjestetty taulukollinen kieliä.
 */
export function sortLanguages(
  languages: Array<Kieli> = [],
  localeUpper: LocaleUpper
): Array<Kieli> {
  return sort((a, b) => {
    const metadataA = a.metadata[localeUpper] || a.metadata["FI"];
    const metadataB = b.metadata[localeUpper] || b.metadata["FI"];
    if (!isNil(metadataA) && !isNil(metadataB)) {
      if (a.koodiarvo === "FI") return -1;
      if (b.koodiarvo === "FI") return 1;
      if (a.koodiarvo === "SV") return -1;
      if (b.koodiarvo === "SV") return 1;
      if (a.koodiarvo === "EN") return -1;
      if (b.koodiarvo === "EN") return 1;
      if (a.koodiarvo === "RU") return -1;
      if (b.koodiarvo === "RU") return 1;
      if (metadataA.nimi < metadataB.nimi) return -1;
      if (metadataA.nimi > metadataB.nimi) return 1;
      return 0;
    }
    return 0;
  }, languages);
}
