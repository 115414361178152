import { isAdded, isInLupa, isRemoved } from "../../../../css/label";
import { compose, find, includes, map, path, prop, propEq } from "ramda";
import getDefaultRemovalForm from "../../perustelut/lomakeosiot/poistolomake";
import { __ } from "i18n-for-browser";
import { getAnchorInit } from "utils/anchor";

function getReasoningForm(koulutusdata, changeObjects, { isReadOnly }, prefix) {
  const categories = map(item => {
    const changeObj = find(
      compose(includes(`.${item.code}.`), prop("anchor")),
      changeObjects
    );

    if (!changeObj) {
      return null;
    }

    const isAddition = path(["properties", "isChecked"], changeObj);

    return {
      anchor: item.code,
      components: [
        {
          anchor: "A",
          name: "StatusTextRow",
          properties: {
            name: "StatusTextRow",
            code: item.code,
            title: item.title,
            labelStyles: {
              addition: isAdded,
              removal: isRemoved,
              custom: Object.assign({}, item.isInLupa ? isInLupa : {})
            }
          }
        }
      ],
      categories: isAddition
        ? [
            {
              anchor: "perustelut",
              components: [
                {
                  anchor: "A",
                  name: "TextBox",
                  properties: {
                    forChangeObject: {
                      isInLupa: item.isInLupa,
                      koodisto: item.koodisto,
                      metadata: item.metadata
                    },
                    isReadOnly,
                    title: __("reasoning.title.default"),
                    value: "",
                    requiredMessage: "Pakollinen tieto puuttuu"
                  }
                }
              ]
            }
          ]
        : getDefaultRemovalForm(isReadOnly, prefix)
    };
  }, koulutusdata.items).filter(Boolean);
  return categories;
}

function getModificationForm(
  koulutusdata,
  changeObjects,
  { isReadOnly },
  locale,
  functions
) {
  const { onAddButtonClick, onRemoveButtonClick } = functions;
  return map(item => {
    const anchor = `${item.sectionId}.${item.code}.A`;
    const anchorInit = getAnchorInit(anchor);
    const changeObj = find(propEq("anchor", anchor), changeObjects);
    const voimassaoloChangeObj = find(
      propEq("anchor", `${anchorInit}.voimassaoloaika`),
      changeObjects
    );
    const showVoimassaolo = !!(
      (path(["maarays", "meta", "alkupvm"], item) &&
        path(["maarays", "meta", "loppupvm"], item)) ||
      voimassaoloChangeObj
    );
    const voimassoloRemoved = path(
      ["properties", "removed"],
      voimassaoloChangeObj
    );

    const voimassolo =
      showVoimassaolo && !voimassoloRemoved
        ? [
            {
              anchor: "voimassaoloaika",
              layout: {
                components: {
                  justification: "start"
                }
              },
              components: [
                {
                  anchor: "alkupvm",
                  name: "Datepicker",
                  styleClasses: ["mr-3"],
                  properties: {
                    inputId: `${item.koodiarvo}-alkupvm`,
                    forChangeObject: {
                      koodiarvo: item.code,
                      sectionId: item.sectionId,
                      koodisto: item.koodisto,
                      maaraysUuid: item.maaraysUuid
                    },
                    isReadOnly,
                    label: __("common.aloituspaivamaara"),
                    value: path(["maarays", "meta", "alkupvm"], item),
                    clearable: true,
                    showTodayButton: false,
                    localizations: {
                      ok: __("common.ok"),
                      clear: __("common.clear"),
                      cancel: __("common.cancel"),
                      today: __("common.today"),
                      datemax: __("common.datemax"),
                      datemin: __("common.datemin"),
                      dateinvalid: __("common.dateinvalid")
                    },
                    locale
                  }
                },
                {
                  anchor: "loppupvm",
                  name: "Datepicker",
                  styleClasses: ["mr-3"],
                  properties: {
                    inputId: `${item.koodiarvo}-loppupvm`,
                    forChangeObject: {
                      koodiarvo: item.code,
                      sectionId: item.sectionId,
                      koodisto: item.koodisto,
                      maaraysUuid: item.maaraysUuid
                    },
                    isReadOnly,
                    label: __("common.paattymispaivamaara"),
                    value: path(["maarays", "meta", "loppupvm"], item),
                    clearable: true,
                    showTodayButton: false,
                    localizations: {
                      ok: __("common.ok"),
                      clear: __("common.clear"),
                      cancel: __("common.cancel"),
                      today: __("common.today"),
                      datemax: __("common.datemax"),
                      datemin: __("common.datemin"),
                      dateinvalid: __("common.dateinvalid")
                    },
                    locale
                  }
                },
                {
                  anchor: "A",
                  name: "SimpleIconButton",
                  onClick: fromComponent => {
                    onRemoveButtonClick(fromComponent, item);
                  },
                  properties: {
                    iconButton: true,
                    icon: "ClearIcon",
                    buttonStyles: {},
                    size: "small",
                    isReadOnly,
                    variant: "text",
                    text: "",
                    iconContainerStyles: {}
                  }
                }
              ]
            }
          ]
        : [];
    return {
      anchor: item.code,
      components: [
        {
          anchor: "A",
          name: "CheckboxWithLabel",
          properties: {
            forChangeObject: {
              isInLupa: item.isInLupa,
              koodiarvo: item.code,
              sectionId: item.sectionId,
              koodisto: item.koodisto,
              metadata: item.metadata,
              maaraysUuid: item.maaraysUuid
            },
            name: "CheckboxWithLabel",
            title: item.title,
            isChecked: item.shouldBeChecked || false,
            labelStyles: {
              addition: isAdded,
              removal: isRemoved,
              custom: Object.assign({}, item.isInLupa ? isInLupa : {})
            }
          }
        },
        item.isInLupa || path(["properties", "isChecked"], changeObj)
          ? {
              anchor: "menu",
              name: "SimpleMenu",
              properties: {
                id: `menu_${item.code}`,
                isExpanded: false,
                size: "small",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "right"
                },
                menuClasses: { list: "p-0" },
                menuItemClasses: { root: "p-2" },
                menuActions: [
                  {
                    name: "date",
                    text: __("common.asetaVoimassaoloaika"),
                    disabled: showVoimassaolo && !voimassoloRemoved,
                    onClick: fromConponent => {
                      onAddButtonClick(fromConponent, item);
                    }
                  }
                ]
              }
            }
          : {}
      ],
      categories: voimassolo ? voimassolo : []
    };
  }, koulutusdata.items);
}

export default function getValmentavatKoulutuksetLomake(
  mode,
  data,
  booleans,
  locale,
  changeObjects = [],
  functions,
  prefix
) {
  switch (mode) {
    case "modification":
      return getModificationForm(
        data.koulutusdata,
        changeObjects,
        booleans,
        locale,
        functions
      );
    case "reasoning":
      return getReasoningForm(
        data.koulutusdata,
        changeObjects,
        booleans,
        prefix
      );
    default:
      return [];
  }
}
