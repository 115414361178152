// Colors
export const COLORS = {
  OIVA_GREEN: "#5A8A70",
  OIVA_RED: "#cc3300",
  OIVA_PURPLE: "#9B26B6",
  DARK_GRAY: "#525252",
  BLACK: "#000000",
  WHITE: "#FFFFFF",
  LIGHT_GRAY: "#D8D8D8",
  BORDER_GRAY: "#DFDFDF",
  OIVA_DARK_GREEN: "#517c64",
  OIVA_TABLE_HOVER_COLOR: "rgba(90,138,112,0.2)",
  OIVA_TABLE_HEADER_HOVER_COLOR: "#98B8A7",
  OIVA_ORANGE: "#E5C317",
  OIVA_ORANGE_TEXT: "#757600",
  OIVA_TEXT: "#333"
};

export const TRANSITIONS = {
  EASE_IN_OUT_QUICK: "all 0.05s ease-in-out"
};
