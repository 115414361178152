import FormTitle from "components/00-atoms/FormTitle";
import { Fragment, ReactElement, useEffect } from "react";
import { useProcess_opetuskielet } from "graphs/storeHandling";
import { IProcessCollection } from "graphHandling/graphProcessHandling";
import { CellId } from "processes/CellId";
import {
  cellImplementations,
  graphDefinition
} from "graphs/opetuskielet/processDefinition";
import { renderAutocomplete } from "graphHandling/components/autocomplete/rendering";
import { renderUndo2 } from "graphHandling/components/undo";
import {
  addIndex,
  dropLast,
  find,
  includes,
  isEmpty,
  isNil,
  length,
  map,
  mapObjIndexed,
  prop,
  propEq,
  reject,
  sortBy
} from "ramda";
import { renderTextBox } from "graphHandling/components/textBox/rendering";
import { useIntl } from "react-intl";
import common from "i18n/definitions/common";
import { getCurrentValue, isInInitialState } from "graphHandling/graphUtils";
import { Options } from "graphHandling/graphTypes";
import { ChangeObjects } from "utils/muutokset";
import {
  getHtmlFormattedRestrictionByKoodiarvo,
  getRestrictionsInListFormat,
  RajoitteetListamuodossa
} from "graphs/toiminta-alue/restrictions_utils";
import HtmlContent from "components/01-molecules/HtmlContent";
import education from "i18n/definitions/education";
import {
  getPaatasonMaaraaikaRajoite,
  getVoimassaOlevatRajoitteet,
  isRajoiteVoimassa,
  rajoiteHasValue
} from "../../../../utils/rajoitteetUtils";

const processId = "opetuskielet";

type Props = {
  code: string;
  isPreviewModeOn: boolean;
  pc: IProcessCollection;
  rajoitteet?: Record<string, { changeObjects: ChangeObjects }>;
  title: string;
  notInitial: Array<string>;
};

const componentsForUndo = [
  CellId.INITIALIZE_AUTOCOMPLETE_1,
  CellId.INITIALIZE_AUTOCOMPLETE_2,
  CellId.INITIALIZE_LISATIEDOT
];

const Opetuskieli = ({
  code,
  isPreviewModeOn,
  pc,
  rajoitteet,
  title
}: Props): null | ReactElement => {
  const { formatMessage, locale } = useIntl();
  const [, actions] = useProcess_opetuskielet();

  if (!pc) {
    return null;
  }

  useEffect(() => {
    if (pc) {
      pc.addProcess(
        {
          actions,
          CellId: CellId,
          cellImplementations: cellImplementations,
          processDefinition: graphDefinition
        },
        processId
      );

      pc.handleIncomingProcessToken(
        CellId.INITIALIZE_OPETUSKIELET_PROCESS,
        processId
      );
    }
  }, []);

  const process = pc.getProcess(processId);

  if (process) {
    const components = process.actions.readPath(["components"]);
    const autocomplete1 = components[CellId.INITIALIZE_AUTOCOMPLETE_1];
    const autocomplete2 = components[CellId.INITIALIZE_AUTOCOMPLETE_2];
    const lisatiedot = components[CellId.INITIALIZE_LISATIEDOT];
    const luvanRajoitteet = process.actions.readPath([
      "custom",
      "restrictions"
    ]) as Array<{
      restrictionString: string;
      koodiarvo: string;
      voimassa: boolean;
    }>;

    if (autocomplete1 && autocomplete2 && lisatiedot) {
      const isUndoVisible =
        !isInInitialState(autocomplete1) ||
        !isInInitialState(autocomplete2) ||
        !isInInitialState(lisatiedot);

      const valitutKielet1 = getCurrentValue(
        "values",
        autocomplete1
      ) as Options;
      const valitutkielet2 = getCurrentValue(
        "values",
        autocomplete2
      ) as Options;

      const foundRajoiteStrings: Array<string> = [];

      const parseKielivalinnat = (valitutKielet: Options) => {
        return reject(
          isNil,
          map(option => {
            const luvanRajoite = find(
              propEq("koodiarvo", option.value),
              luvanRajoitteet
            );
            const kohdistuvatRajoitteet = rajoitteet
              ? reject(
                  isNil,
                  mapObjIndexed(rajoite => {
                    return rajoiteHasValue(rajoite.changeObjects, option.value)
                      ? rajoite
                      : null;
                  }, rajoitteet)
                )
              : null;

            const paatasonMaaraaikaRajoite = getPaatasonMaaraaikaRajoite(
              kohdistuvatRajoitteet
            );

            if (!isRajoiteVoimassa(paatasonMaaraaikaRajoite)) {
              return null;
            }

            const voimassaOlevatRajoitteet = getVoimassaOlevatRajoitteet(
              kohdistuvatRajoitteet
            );

            const rajoitteetListamuodossa = getRestrictionsInListFormat(
              voimassaOlevatRajoitteet,
              locale,
              option.value as string
            );
            const rajoiteString = rajoitteetListamuodossa
              ? (getHtmlFormattedRestrictionByKoodiarvo(
                  option.value as string,
                  rajoitteetListamuodossa as RajoitteetListamuodossa
                ) as string)
              : null;
            const droppedRajoiteString = rajoiteString
              ? dropLast(20, rajoiteString)
              : "";
            const obj = {
              label: option.label,
              rajoiteString:
                luvanRajoite && droppedRajoiteString
                  ? `${droppedRajoiteString}${luvanRajoite.restrictionString}`
                  : rajoiteString
                  ? rajoiteString
                  : luvanRajoite
                  ? `<ul><li>${option.label}${luvanRajoite.restrictionString}</li></ul>`
                  : null
            };
            if (
              obj.rajoiteString &&
              !includes(obj.rajoiteString, foundRajoiteStrings)
            ) {
              foundRajoiteStrings.push(obj.rajoiteString);
            }
            return obj;
          }, sortBy(prop("label"), valitutKielet) as Options)
        );
      };

      const esikatselunKielet1: Array<{
        label: string;
        rajoiteString: null | string;
      }> = parseKielivalinnat(valitutKielet1);

      const esikatselunKielet2: Array<{
        label: string;
        rajoiteString: null | string;
      }> = parseKielivalinnat(valitutkielet2);

      const lisatiedotValue = getCurrentValue("value", lisatiedot) as string;
      const onkoEsikatseltavaa =
        !isEmpty(valitutKielet1) ||
        !isEmpty(valitutkielet2) ||
        !!length(lisatiedotValue);
      return (isPreviewModeOn && onkoEsikatseltavaa) || !isPreviewModeOn ? (
        <div className={"max-w-7xl mx-auto bg-white"}>
          {isPreviewModeOn && onkoEsikatseltavaa ? (
            <Fragment>
              <FormTitle level={3} title={title} />
              <ul className="list-disc px-6">
                {addIndex(map)((_location, index) => {
                  const location = _location as {
                    label: string;
                    rajoiteString: string;
                  };
                  const { label, rajoiteString } = location;
                  if (rajoiteString) {
                    return (
                      <li key={index}>
                        <HtmlContent content={rajoiteString} />
                      </li>
                    );
                  } else {
                    return <li key={index}>{label}</li>;
                  }
                }, sortBy(prop("label"), esikatselunKielet1))}
              </ul>

              {!isEmpty(esikatselunKielet2) ? (
                <FormTitle
                  level={4}
                  title={formatMessage(
                    education.voidaanAntaaMyosSeuraavillaKielilla
                  )}
                />
              ) : null}

              <ul className="list-disc px-6">
                {addIndex(map)((_location, index) => {
                  const location = _location as {
                    label: string;
                    rajoiteString: string;
                  };
                  const { label, rajoiteString } = location;
                  if (rajoiteString) {
                    return (
                      <li key={index}>
                        <HtmlContent content={rajoiteString} />
                      </li>
                    );
                  } else {
                    return <li key={index}>{label}</li>;
                  }
                }, sortBy(prop("label"), esikatselunKielet2))}
              </ul>

              <p
                className={`whitespace-pre-wrap ${
                  !isEmpty(valitutKielet1) ? "mt-4" : ""
                }`}>
                <span>{lisatiedotValue}</span>
              </p>
            </Fragment>
          ) : isPreviewModeOn ? null : (
            <Fragment>
              <div className="flex justify-between items-center">
                <FormTitle code={code} title={title} />
                {isUndoVisible &&
                  renderUndo2(
                    components[CellId.INITIALIZE_KUMOA_MUUTOKSET],
                    componentsForUndo
                  )}
              </div>
              <div className="grid grid-cols-2 gap-8">
                <div className="border-r pr-8">
                  <div className="pb-8">
                    {renderAutocomplete(autocomplete1)}
                  </div>
                  <div>{renderAutocomplete(autocomplete2)}</div>
                </div>
                <div>
                  <p>{formatMessage(common.lisatiedotInfo)}</p>
                  {renderTextBox(lisatiedot)}
                </div>
              </div>
            </Fragment>
          )}
        </div>
      ) : null;
    }
  }

  return null;
};

export default Opetuskieli;
