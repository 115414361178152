import {
  flatten,
  map,
  prop,
  compose,
  filter,
  includes,
  find,
  propEq,
  equals,
  append,
  assocPath,
  startsWith,
  endsWith,
  difference,
  path,
  pipe
} from "ramda";
import { getAnchorInit, getAnchorPart } from "../../../utils/anchor";
import moment from "moment";
import { ISO_DATE_FORMAT } from "utils/constants";

/**
 * Luo backendin tarvitsemat muutosobjektit tutkintojen ja osaamisalojen
 * osalta.
 *
 * @param {object} tutkinto
 * @param {array} changeObjects
 * @param {object} kohde
 * @param {array} maaraystyypit
 * @param {object} locale
 */
export function createBEOofTutkinnotJaOsaamisalat(
  tutkinto,
  changeObjects,
  kohde,
  maaraystyypit,
  locale
) {
  const tutkintoAnchor = `tutkinnot_${tutkinto.koulutusalakoodiarvo}.${tutkinto.koulutustyyppikoodiarvo}.${tutkinto.koodiarvo}`;
  let changes = filter(
    compose(startsWith(tutkintoAnchor), prop("anchor")),
    changeObjects.tutkinnotJaOsaamisalat.muutokset
  );

  /**
   * Mikäli tarkasteltavaan tutkintoon eikä sen alla oleviin osaamisaloihin ole kohdistunut muutoksia,
   * tarkastelua ei tarvitse tehdä
   */
  if (changes.length === 0) {
    return null;
  }

  const tutkintoChangeObj = find(
    compose(endsWith("tutkinto"), prop("anchor")),
    changes
  );
  const osaamisalaChangeObjs = difference(changes, [tutkintoChangeObj]);

  /**
   * Tutkinto kuuluu lupaan, jos sillä on määräys.
   */
  const isTutkintoInLupa = !!tutkinto.maarays;
  const isTutkintopoisto =
    isTutkintoInLupa &&
    tutkintoChangeObj &&
    !tutkintoChangeObj.properties.isChecked;

  const anchorInit = tutkintoChangeObj
    ? getAnchorInit(tutkintoChangeObj.anchor)
    : "";

  /**
   * Käyttäjälle tarjotaan mahdollisuutta perustella muutokset.
   * Perustelut ovat omia frontin puolen muutosobjektejaan, jotka
   * liitetään osaksi muodostettavaa - backendin kaipaamaa -
   * muutosobjektia.
   */
  const perustelut = filter(
    compose(includes(anchorInit), prop("anchor")),
    changeObjects.tutkinnotJaOsaamisalat.perustelut
  );

  /**
   * On tarpeen luoda tai päivittää muutosobjekti, jos tutkinto on joko aktivoitu
   * tai deaktivoitu eli se ollaan joko lisäämässä lupaan tai poistamassa luvasta
   * tai jos tutkinnon perusteluita on muutettu.
   */

  const perustelutForTutkinto = filter(
    perustelu =>
      getAnchorPart(tutkintoChangeObj.anchor, 2) ===
        getAnchorPart(perustelu.anchor, 2) &&
      !includes("osaamisala", perustelu.anchor),
    perustelut
  );

  const voimassaoloChangeObjects = filter(
    compose(includes(`${anchorInit}.voimassaoloaika`), prop("anchor")),
    changes
  );

  const voimassaoloChangeObject = find(
    compose(endsWith("voimassaoloaika"), prop("anchor")),
    voimassaoloChangeObjects
  );
  const alkupvmChangeObject = find(
    compose(endsWith("alkupvm"), prop("anchor")),
    voimassaoloChangeObjects
  );
  const loppupvmChangeObject = find(
    compose(endsWith("loppupvm"), prop("anchor")),
    voimassaoloChangeObjects
  );
  const voimassaoloRemoved = path(
    ["properties", "removed"],
    voimassaoloChangeObject
  );

  const voimassaoloChanged =
    alkupvmChangeObject || loppupvmChangeObject || voimassaoloRemoved;

  const isTutkinnonMuutosobjektiNeeded =
    (tutkintoChangeObj &&
      tutkintoChangeObj.properties.isChecked !== isTutkintoInLupa) ||
    perustelutForTutkinto.length ||
    voimassaoloChanged;

  const alkupvm =
    path(["properties", "value"], alkupvmChangeObject) ||
    path(["maarays", "meta", "alkupvm"], tutkinto);
  const loppupvm =
    path(["properties", "value"], loppupvmChangeObject) ||
    path(["maarays", "meta", "loppupvm"], tutkinto);
  /**
   * Mahdollinen tutkintomuutos on paikallaan määritellä muokattavana
   * objektina, koska siihen on myöhemmässä vaiheessa tarkoitus liittää
   * osaamisaloja koskevat käyttöliittymäpuolen muutosobjektit.
   */
  let tutkintomuutos = isTutkinnonMuutosobjektiNeeded
    ? {
        kohde,
        koodiarvo: tutkinto.koodiarvo,
        koodisto: tutkinto.koodisto.koodistoUri,
        kuvaus: tutkinto.metadata[locale].kuvaus,
        maaraystyyppi: find(propEq("tunniste", "OIKEUS"), maaraystyypit),
        generatedId: `${tutkinto.koodiarvo}_${Math.random()}`,
        meta: {
          alkupvm:
            alkupvm && !voimassaoloRemoved
              ? moment(alkupvm).format(ISO_DATE_FORMAT)
              : null,
          loppupvm:
            loppupvm && !voimassaoloRemoved
              ? moment(loppupvm).format(ISO_DATE_FORMAT)
              : null,
          changeObjects: flatten([
            [tutkintoChangeObj],
            perustelutForTutkinto,
            voimassaoloChangeObjects
          ]),
          nimi: tutkinto.metadata[locale].nimi,
          koulutusala: tutkinto.koulutusalaKoodiarvo,
          koulutustyyppi: tutkinto.koulutustyyppiKoodiarvo,
          perusteluteksti: "", // TODO: Täydennä oikea perusteluteksti
          muutosperustelukoodiarvo: [] // Tarvitaanko tätä oikeasti?
        },
        nimi: tutkinto.metadata[locale].nimi,
        tila: isTutkintopoisto ? "POISTO" : "LISAYS"
      }
    : null;

  let tutkintopoistoMuutos = null;
  if (tutkintomuutos) {
    // Jos tutkinto kuuluu lupaan, asetetaan määräyksen uuid
    if (isTutkintoInLupa) {
      tutkintomuutos.maaraysUuid = tutkinto.maarays.uuid;
    }

    // Jos tutkinto on jo luvalla mutta esimerkiksi voimassaolo on muuttunut, poistetaan vanha määräys luvalta ja lisätään uusi tilalle.
    if (voimassaoloChanged && isTutkintoInLupa) {
      tutkintopoistoMuutos = pipe(
        assocPath(["meta"], path(["maarays", "meta"], tutkinto)),
        assocPath(["meta", "changeObjects"], null)
      )(tutkintomuutos);
      tutkintopoistoMuutos.tila = "POISTO";
      tutkintopoistoMuutos.generatedId = null;

      tutkintomuutos.maaraysUuid = null;
    }
  }

  /**
   * Seuraavaksi on käytävä läpi tarkasteltavan tutkinnon osaamisalat
   * ja tarkistettava, onko niihin kohdistettu muutoksia.
   */
  const osaamisalamuutokset = map(osaamisala => {
    const osaamisalaPerustelut = filter(
      perustelu =>
        getAnchorPart(perustelu.anchor, 4) === osaamisala.koodiarvo &&
        includes("osaamisala", perustelu.anchor),
      perustelut
    );

    const isOsaamisalaRajoiteInLupa = !!osaamisala.maarays;
    let osaamisalamuutos = null;
    let osaamisalaPoistomuutos = null;

    const osaamisalaChangeObj = find(changeObj => {
      return equals(getAnchorPart(changeObj.anchor, 3), osaamisala.koodiarvo);
    }, osaamisalaChangeObjs);

    const osaamisalaChecked =
      path(["properties", "isChecked"], osaamisalaChangeObj) ||
      !isOsaamisalaRajoiteInLupa;

    /**
     * OSAAMISALARAJOITTEEN LISÄÄMINEN
     *
     * Rajoite täytyy lisätä, jos tutkintoa ei olla poistamassa ja jompi kumpi seuraavista kohdista
     * pitää paikkansa:
     *
     * 1. Tutkinto lisätään lupaan ja osaamisalaa ei ole valittu
     * 2. Osaamisala on valittu luvassa, mutta osaamisala poistetaan
     **/
    if (
      !isTutkintopoisto &&
      ((!osaamisalaChangeObj && !isTutkintoInLupa) ||
        (osaamisalaChangeObj && !osaamisalaChecked) ||
        (voimassaoloChanged && !osaamisalaChecked))
    ) {
      // Luodaan LISÄYS
      osaamisalamuutos = {
        generatedId: osaamisalaChangeObj
          ? getAnchorInit(osaamisalaChangeObj.anchor)
          : `osaamisala-${Math.random()}`,
        kohde,
        koodiarvo: osaamisala.koodiarvo,
        koodisto: osaamisala.koodisto.koodistoUri,
        kuvaus: osaamisala.metadata[locale].kuvaus,
        maaraystyyppi: find(propEq("tunniste", "RAJOITE"), maaraystyypit),
        // maaraysUuid,
        meta: {
          changeObjects: flatten([
            [osaamisalaChangeObj],
            osaamisalaPerustelut
          ]).filter(Boolean),
          nimi: osaamisala.metadata[locale].nimi,
          koulutusala: tutkinto.koulutusalaKoodiarvo,
          koulutustyyppi: tutkinto.koulutustyyppiKoodiarvo,
          perusteluteksti: "", // TODO: Täydennä oikea perusteluteksti
          muutosperustelukoodiarvo: []
        },
        parentMaaraysUuid: path(["maarays", "uuid"], tutkinto),
        nimi: osaamisala.metadata[locale].nimi,
        tila: "LISAYS"
      };

      /**
       * Jos ollaan lisäämässä uutta tutkintoa, tulee osaamisalan
       * parent-propertyn viitata tutkinnon generatedId-
       * propertyyn.
       **/
      if ((!isTutkintoInLupa && tutkintomuutos) || voimassaoloChanged) {
        osaamisalamuutos.parent = tutkintomuutos.generatedId;
        osaamisalamuutos.parentMaaraysUuid = null;
      }
    }

    /**
     * OSAAMISALARAJOITTEEN POISTAMINEN
     *
     * Rajoite täytyy poistaa, mikäli jompi kumpi seuraavista kohdista
     * pitää paikkansa:
     *
     * 1. Rajoite on olemassa ja tutkinto poistetaan luvalta (Osaamisalarajoitteet poistuisivat joka tapauksessa
     *    tutkinnon myötä, mutta selvennyksen vuoksi muodostetaan myös näistä muutokset)
     * 2. Rajoite on olemassa ja osaamisala asetetaan aktiiviseksi
     **/
    if (
      isOsaamisalaRajoiteInLupa &&
      ((!osaamisalaChangeObj && isTutkintopoisto) ||
        (osaamisalaChangeObj && osaamisalaChecked) ||
        voimassaoloChanged)
    ) {
      // Luodaan POISTO
      osaamisalaPoistomuutos = {
        kohde,
        koodiarvo: osaamisala.koodiarvo,
        koodisto: osaamisala.koodisto.koodistoUri,
        kuvaus: osaamisala.metadata[locale].kuvaus,
        maaraystyyppi: find(propEq("tunniste", "RAJOITE"), maaraystyypit),
        maaraysUuid: path(["maarays", "uuid"], osaamisala),
        meta: {
          changeObjects: flatten([
            [osaamisalaChangeObj],
            osaamisalaPerustelut
          ]).filter(Boolean),
          nimi: osaamisala.metadata[locale].nimi,
          koulutusala: tutkinto.koulutusalaKoodiarvo,
          koulutustyyppi: tutkinto.koulutustyyppiKoodiarvo,
          perusteluteksti: "", // TODO: Täydennä oikea perusteluteksti
          muutosperustelukoodiarvo: []
        },
        nimi: osaamisala.metadata[locale].nimi,
        tila: "POISTO"
      };
    }

    /**
     * Jos osaamisalamuutosta ei muodostettu, tarkoittaa se sitä, ettei
     * frontin muutosobjektia ole vielä tallennettu metadataan.
     **/
    if (!osaamisalamuutos && !!tutkintomuutos && !!osaamisalaChangeObj) {
      // Lisätään frontin muutosbjekti tutkintomuutoksen metadataan
      tutkintomuutos = assocPath(
        ["meta", "changeObjects"],
        append(osaamisalaChangeObj, tutkintomuutos.meta.changeObjects),
        tutkintomuutos
      );
    }

    /**
     * Jos ei ole tutkintoon kohdistuu fronttimuutos, jonka pohjalta ei
     * generoitu backend-muutosta, on fronttimuutos tallennettava johonkin,
     * jotta käyttöliittymän tila osataan tutkinnonkin osalta näyttää
     * ladattaessa oikein. Tallennetaan tutkinnon fronttimuutos osaksi
     * osaamisalamuutoksen metatietoja.
     **/
    if (!tutkintomuutos && !!osaamisalamuutos) {
      osaamisalamuutos = assocPath(
        ["meta", "changeObjects"],
        append(tutkintoChangeObj, osaamisalamuutos.meta.changeObjects),
        osaamisalamuutos
      );
    }

    return [osaamisalamuutos, osaamisalaPoistomuutos];
  }, tutkinto.osaamisalat).filter(Boolean);

  return append(
    [tutkintomuutos, tutkintopoistoMuutos],
    flatten(osaamisalamuutokset)
  ).filter(Boolean);
}
