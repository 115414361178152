import {
  find,
  flatten,
  isEmpty,
  isNil,
  map,
  mapObjIndexed,
  propEq,
  reject,
  toLower,
  toUpper,
  values
} from "ramda";
import localForage from "localforage";
import { createAlimaarayksetBEObjects } from "helpers/rajoitteetHelper";
import {
  createMaarayksiaVastenLuodutRajoitteetBEObjects,
  getRajoitteetByValue
} from "utils/rajoitteetUtils";
import { getMaarayksetByTunniste } from "helpers/lupa";
import { PaikallisenTietovarastonAvain, Toiminto } from "enums";
import { luoMuutosobjektitLisatietokentasta } from "helpers/lisatiedot";
import { getCurrentValue } from "graphHandling/graphUtils";

export const defineBackendChangeObjects = async (
  osionData = {},
  rajoitteetByRajoiteId,
  kohde,
  maaraystyypit,
  lupaMaaraykset,
  kohteet
) => {
  const maaraykset = await getMaarayksetByTunniste(
    kohde.tunniste,
    lupaMaaraykset
  );
  const opetuskielet = await localForage.getItem(
    PaikallisenTietovarastonAvain.ENSISIJAISET_OPETUSKIELET_OPH
  );

  const currentValues = reject(isNil, {
    ensisijaiset: getCurrentValue(
      "values",
      osionData.opetuskielet.ensisijaiset
    ),
    toissijaiset: getCurrentValue(
      "values",
      osionData.opetuskielet.toissijaiset
    ),
    lisatiedot: getCurrentValue("value", osionData.opetuskielet.lisatiedot)
  });

  if (isEmpty(currentValues)) {
    return [];
  }

  const lukioKohde = find(propEq("tunniste", "opetuskieli"), kohteet);
  const vstKohde = find(propEq("tunniste", "kielet"), kohteet);

  const kohdeObj = lukioKohde || vstKohde;

  // Lisätietokentän muutosten käsittely
  const lisatietomuutokset = await luoMuutosobjektitLisatietokentasta(kohde, [
    { anchor: ".lisatiedot.1", properties: { value: currentValues.lisatiedot } }
  ]);

  /** Opetuskielten käsittely */
  const opetuskieliBeChangeObjects = map(opetuskieli => {
    // Etsitään opetuskieltä koskeva määräys.
    const maarays = find(
      maarays =>
        maarays.koodiarvo.toLowerCase() === opetuskieli.koodiarvo.toLowerCase(),
      maaraykset
    );

    const rajoitteetByRajoiteIdAndKoodiarvo = getRajoitteetByValue(
      opetuskieli.koodiarvo,
      rajoitteetByRajoiteId
    );

    const onkoOpetuskieliEnsisijaisissa = !!find(
      propEq("value", opetuskieli.koodiarvo),
      currentValues.ensisijaiset
    );

    const onkoOpetuskieliToissijaisissa = !!find(
      propEq("value", opetuskieli.koodiarvo),
      currentValues.toissijaiset
    );

    const onkoOpetuskieliValituissa =
      onkoOpetuskieliEnsisijaisissa || onkoOpetuskieliToissijaisissa;

    let muutosobjekti = null;

    if (maarays) {
      const maarayksenKoodiarvoUpper = toUpper(maarays.koodiarvo);
      // Opetuskielen poistaminen, mikäli se ei ole enää valittuna.
      if (!onkoOpetuskieliValituissa) {
        const onkoPoistettuEnsisijaisista = !!find(
          propEq("value", maarayksenKoodiarvoUpper),
          osionData.opetuskielet.ensisijaiset.properties.values
        );
        const onkoPoistettuToissijaisista = !!find(
          propEq("value", maarayksenKoodiarvoUpper),
          osionData.opetuskielet.toissijaiset.properties.values
        );
        if (onkoPoistettuEnsisijaisista) {
          muutosobjekti = {
            generatedId: `opetuskielet-${Math.random()}`,
            kohde: kohdeObj,
            koodiarvo: toLower(opetuskieli.koodiarvo),
            koodisto: opetuskieli.koodisto.koodistoUri,
            maaraystyyppi: find(propEq("tunniste", "OIKEUS"), maaraystyypit),
            maaraysUuid: maarays.uuid,
            meta: {
              valikko: "ensisijaiset"
            },
            tila: Toiminto.POISTO
          };
        } else if (onkoPoistettuToissijaisista) {
          muutosobjekti = {
            generatedId: `opetuskielet-${Math.random()}`,
            kohde: kohdeObj,
            koodiarvo: toLower(opetuskieli.koodiarvo),
            koodisto: opetuskieli.koodisto.koodistoUri,
            maaraystyyppi: find(propEq("tunniste", "OIKEUS"), maaraystyypit),
            maaraysUuid: maarays.uuid,
            meta: {
              valikko: "toissijaiset"
            },
            tila: Toiminto.POISTO
          };
        }
      }
    } else if (onkoOpetuskieliValituissa) {
      // Opetuskielen lisääminen, mikäli se on valittuna.
      muutosobjekti = {
        generatedId: `opetuskielet-${Math.random()}`,
        kohde: kohdeObj,
        koodiarvo: toLower(opetuskieli.koodiarvo),
        koodisto: opetuskieli.koodisto.koodistoUri,
        maaraystyyppi: find(propEq("tunniste", "OIKEUS"), maaraystyypit),
        meta: {
          changeObjects: values(rajoitteetByRajoiteIdAndKoodiarvo),
          valikko: onkoOpetuskieliEnsisijaisissa
            ? "ensisijaiset"
            : "toissijaiset"
        },
        tila: Toiminto.LISAYS
      };
    }

    // Muodostetaan tehdyistä rajoittuksista objektit backendiä varten.
    // Linkitetään ensimmäinen rajoitteen osa yllä luotuun muutokseen ja
    // loput toisiinsa "alenevassa polvessa".
    const alimaaraykset = muutosobjekti
      ? values(
          mapObjIndexed(asetukset => {
            return createAlimaarayksetBEObjects(
              kohteet,
              maaraystyypit,
              muutosobjekti,
              asetukset
            );
          }, rajoitteetByRajoiteIdAndKoodiarvo)
        )
      : [];

    return [muutosobjekti, alimaaraykset];
  }, opetuskielet);

  const maarayksiaVastenLuodutRajoitteet =
    createMaarayksiaVastenLuodutRajoitteetBEObjects(
      maaraykset,
      rajoitteetByRajoiteId,
      kohteet,
      maaraystyypit,
      kohde
    );

  return flatten([
    opetuskieliBeChangeObjects,
    lisatietomuutokset,
    maarayksiaVastenLuodutRajoitteet
  ]).filter(Boolean);
};
