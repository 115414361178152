import { Fragment, ReactElement } from "react";
import {
  find,
  isEmpty,
  filter,
  map,
  concat,
  includes,
  path,
  addIndex,
  length,
  sortBy,
  toUpper
} from "ramda";
import { useIntl } from "react-intl";
import education from "i18n/definitions/education";
import { getRajoitteetFromMaarays } from "utils/rajoitteetUtils";
import Typography from "@mui/material/Typography";
import LisatiedotHtmlLupa from "components/03-templates/HtmlLupanakyma/Lisatiedot";
import rajoitteet from "i18n/definitions/rajoitteet";
import { Locale, LocaleUpper } from "enums";
import { sortByLocale } from "helpers/html";
import { Lupamaarays } from "Lupa";
import { getLocalizedProperty } from "services/lomakkeet/utils";

const tunnisteet = ["kunnatjoissaopetustajarjestetaan", "toimintaalue"];

type Props = {
  maaraykset: Array<Lupamaarays>;
};

export default function OpetustaAntavatKunnat({
  maaraykset
}: Props): null | ReactElement {
  const intl = useIntl();
  const locale: Locale = intl.locale === "fi" ? Locale.FI : Locale.SV;
  const localeUpper: LocaleUpper =
    intl.locale === "fi" ? LocaleUpper.FI : LocaleUpper.SV;

  const kuntamaaraykset: Array<Lupamaarays> = filter(maarays => {
    return (
      !!includes(path(["kohde", "tunniste"], maarays) as string, tunnisteet) &&
      maarays.koodisto === "kunta" &&
      !includes("200", path(["koodiarvo"], maarays) || "")
    );
  }, maaraykset);

  const jarjestetytKuntamaaraykset = sortByLocale(localeUpper, kuntamaaraykset);

  const maakuntamaaraykset: Array<Lupamaarays> = filter(maarays => {
    return (
      !!includes(path(["kohde", "tunniste"], maarays) as string, tunnisteet) &&
      maarays.koodisto === "maakunta" &&
      !includes("200", path(["koodiarvo"], maarays) || "")
    );
  }, maaraykset);

  const jarjestetytMaakuntamaaraykset = sortByLocale(
    toUpper(intl.locale),
    maakuntamaaraykset
  );

  const lisatietomaarays = find(
    maarays =>
      !!includes(path(["kohde", "tunniste"], maarays) as string, tunnisteet) &&
      maarays.koodisto === "lisatietoja",
    maaraykset
  );

  const opetustaJarjestetaanUlkomaillaLisatiedotMaaraykset: Array<Lupamaarays> =
    filter(
      maarays =>
        !!includes(
          path(["kohde", "tunniste"], maarays) as string,
          tunnisteet
        ) &&
        includes("200", path(["koodiarvo"], maarays) || "") &&
        !!maarays.meta.arvo,
      maaraykset
    );

  const maarayksetOrdered = sortBy(
    maarays => getKuntamaaraysvalue(maarays, intl.locale),
    concat(
      kuntamaaraykset,
      opetustaJarjestetaanUlkomaillaLisatiedotMaaraykset
    ).filter(Boolean)
  );

  return !isEmpty(jarjestetytKuntamaaraykset) ||
    !isEmpty(opetustaJarjestetaanUlkomaillaLisatiedotMaaraykset) ||
    !isEmpty(jarjestetytMaakuntamaaraykset) ? (
    <div className="mt-4">
      <Typography component="h3" variant="h3">
        {intl.formatMessage(education.opetustaAntavatKunnat)}
      </Typography>
      <ul className="list-disc mb-4 ml-8">
        {addIndex(map)((_maarays, index) => {
          const maarays = _maarays as Lupamaarays;
          return (
            <Fragment key={`${maarays.koodiarvo}-${index}`}>
              <li
                className={`leading-bulletList ${
                  length(maarays.aliMaaraykset) ? "mb-2" : ""
                }`}>
                {getKuntamaaraysvalue(maarays, locale)}
                {length(maarays.aliMaaraykset)
                  ? getRajoitteetFromMaarays(
                      maarays.aliMaaraykset,
                      locale,
                      intl.formatMessage(rajoitteet.ajalla)
                    )
                  : ""}
              </li>
            </Fragment>
          );
        }, maakuntamaaraykset)}
        {maakuntamaaraykset.length > 0 && <br></br>}
        {addIndex(map)((_maarays, index) => {
          const maarays = _maarays as Lupamaarays;
          return (
            <Fragment key={`${maarays.koodiarvo}-${index}`}>
              <li
                className={`leading-bulletList ${
                  length(maarays.aliMaaraykset) ? "mb-2" : ""
                }`}>
                {getKuntamaaraysvalue(maarays, intl.locale)}
                {length(maarays.aliMaaraykset)
                  ? getRajoitteetFromMaarays(
                      maarays.aliMaaraykset,
                      locale,
                      intl.formatMessage(rajoitteet.ajalla)
                    )
                  : ""}
              </li>
            </Fragment>
          );
        }, maarayksetOrdered)}
      </ul>
      <LisatiedotHtmlLupa lisatietomaarays={lisatietomaarays} />
    </div>
  ) : null;
}

export const getKuntamaaraysvalue = (
  maarays: Lupamaarays,
  localeUpper: string
): string => {
  if (maarays.koodiarvo === "200") {
    // Ulkomaat
    return maarays.meta.arvo as string;
  } else {
    // Opetus Suomessa
    return getLocalizedProperty(maarays.koodi.metadata, localeUpper, "nimi");
  }
};
