import { Toiminto } from "enums";
import { Kohde, Maaraystyyppi } from "koodistodatanTyypit";
import { find, propEq, path, assoc, isNil, reject, pipe } from "ramda";
import { Muutos, ChangeObject } from "types";

export function defineLisatiedotBackendChangeObjects(
  changeObjects: Array<ChangeObject>,
  kohde: Kohde,
  maaraystyypit: Array<Maaraystyyppi>,
  sectionId: string
): Array<Muutos> {
  if (!kohde) {
    console.warn("Kohde is missing!");
    return [];
  } else if (!maaraystyypit) {
    console.warn("Array of määräystyypit is missing!");
    return [];
  }

  const lisatiedot = find(
    propEq("anchor", `${sectionId}.lisatiedot.1`),
    changeObjects || []
  );

  if (!lisatiedot) {
    return [];
  }

  const maaraysUuid = path(
    ["properties", "metadata", "maaraysUuid"],
    lisatiedot
  ) as string;
  const lisatiedotMuutos: Muutos = {
    kohde: kohde,
    maaraystyyppi: find(propEq("tunniste", "OIKEUS"), maaraystyypit),
    koodisto: "lisatietoja",
    koodiarvo: "1",
    meta: {
      arvo: path(["properties", "value"], lisatiedot) as string,
      tunniste: sectionId, // Käytetään identifioimaan lisätiedon paikka PDF tulostuksessa, jos samaan kohteeseen kohdistuu useampia lisätietoja.
      changeObjects: [lisatiedot]
    },
    tila: Toiminto.LISAYS
  };
  const lisatietoPoisto = maaraysUuid
    ? pipe(
        assoc("tila", Toiminto.POISTO),
        assoc("maaraysUuid", maaraysUuid)
      )(lisatiedotMuutos)
    : null;

  return reject(isNil, [lisatiedotMuutos, lisatietoPoisto]);
}
