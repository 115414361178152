import { getRaw } from "basedata";
import { CellFn } from "graphHandling/graphTypes";
import { backendRoutes } from "stores/utils/backendRoutes";

export const fetch_lupaByUuid: CellFn = async (
  storeFunctions,
  intl,
  payload,
  result,
  customParams
) => {
  if (!customParams?.uuid) {
    throw new Error(
      "Määritä luvan uuid, jotta lupa voidaan hakea sitä käyttäen."
    );
  }

  try {
    if (customParams.uuid) {
      const lupa = await getRaw(
        "lupaByUuid",
        `${backendRoutes.lupaByUuid.path}${customParams.uuid}?with=all&useKoodistoVersions=false`,
        [],
        backendRoutes.lupaByUuid.minimumTimeBetweenFetchingInMinutes
      );
      return lupa;
    }
    return false;
  } catch (errText) {
    throw new Error(errText as string);
  }
};
