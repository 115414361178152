import { CellFn } from "graphHandling/graphTypes";
import { CellId } from "processes/CellId";
import { flatten, isNil, map, prop, reject, sortBy } from "ramda";
import { fetchJSON } from "basedata";
import { resolveLocalizedOrganizationName } from "modules/helpers";
import { Organisation } from "types";

export const organisaatioidenNoutaminen: CellFn = async (
  storeFunctions,
  intl
) => {
  if (!intl) {
    console.warn("Parametri intl puuttuu.");
    return false;
  }
  // Selvitetään noudetaanko organisaatiot kiinteänä listauksena vai
  // hakutoimintoa käyttäen.
  const searchStr = storeFunctions.readPath([
    "components",
    CellId.INITIALIZE_ORGANISAATIOVALIKKO,
    "properties",
    "searchStr"
  ]);

  const validOrganisationListSource = storeFunctions.readPath([
    "custom",
    "validOrganisationListSource"
  ]) as {
    id: number;
    path: string;
  };

  const organisaatiot = validOrganisationListSource
    ? flatten([await fetchJSON(validOrganisationListSource.path)])
    : [];

  const organisaatiovaihtoehdot: Array<{
    label: string;
    value: string;
  }> = organisaatiot
    ? sortBy(
        prop("label"),
        reject(
          isNil,
          map((organisaatio: Organisation) => {
            return organisaatio
              ? {
                  label: `${
                    validOrganisationListSource.id === 1 ? searchStr : ""
                  } ${resolveLocalizedOrganizationName(
                    organisaatio,
                    intl.locale
                  )}`,
                  value: organisaatio.oid
                }
              : null;
          }, organisaatiot)
        )
      )
    : [];

  storeFunctions.updateGraph(
    ["components", CellId.INITIALIZE_ORGANISAATIOVALIKKO, "properties"],
    {
      options: organisaatiovaihtoehdot,
      listSource: validOrganisationListSource
    }
  );

  return true;
};
