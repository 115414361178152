import { filter } from "ramda";
import { ComponentWithMetadata } from "utils/lomakkeet";
import { ChangeObjects } from "utils/muutokset";
import { isNodeChecked } from "./nodePropertiesCalculation";

/**
 * Tarkistaa, ovatko ruksiboksit ruksaamatta jokaisen komponentin osalta.
 * @param nodes - Taulukollinen metatiedoilla höystettyjä komponenttimäärityksiä.
 * @param changeObjects - Taulukollinen muutosobjekteja.
 */
export function isEveryNodeUnchecked(
  nodes: Array<ComponentWithMetadata>,
  changeObjects: ChangeObjects
): boolean {
  const checkedNodes = filter(componentWithMetadata => {
    return isNodeChecked(componentWithMetadata, changeObjects);
  }, nodes);
  return checkedNodes.length === 0;
}
