import { FTietueAvain, PaikallisenTietovarastonAvain } from "enums";
import { Koodistotietue } from "koodistodatanTyypit";
import { FKoodistotietue } from "koodistodatanTyypitFrontille";
import { mergeAll, sort } from "ramda";
import localForage from "localforage";

/**
 * Vertailee kahden fKoodistotietueen koodiarvoja ja palauttaa vertailun tuloksen.
 * @param fKoodistotietue1
 * @param fKoodistotietue2
 * @returns -1, 0 tai 1.
 */
export function vertaileKahdenFKoodistotietueenKoodiarvoja(
  fKoodistotietue1: FKoodistotietue,
  fKoodistotietue2: FKoodistotietue
): number {
  const koodiarvo1Int: number = parseInt(
    fKoodistotietue1[FTietueAvain.KOODIARVO],
    10
  );
  const koodiarvo2Int: number = parseInt(
    fKoodistotietue2[FTietueAvain.KOODIARVO],
    10
  );
  if (koodiarvo1Int < koodiarvo2Int) {
    return -1;
  } else if (koodiarvo1Int > koodiarvo2Int) {
    return 1;
  } else {
    return 0;
  }
}

/**
 * Luo koodistopalvelun yksittäisestä objektista frontendin käyttöön
 * paremmin soveltuvan version.
 * @param koodistotietue Koodistopalvelusta tulevassa muodossa
 * @returns Fronttiystävällinen versio koodistotietueesta.
 */
export function luoFronttiystavallinenVersioKoodistotietueesta(
  koodistotietue: Koodistotietue
): FKoodistotietue {
  return {
    koodiarvo: koodistotietue.koodiArvo,
    koodisto: koodistotietue.koodisto,
    metadata: mergeAll(
      koodistotietue.metadata.map(item => ({
        [item.kieli]: item
      }))
    ),
    versio: koodistotietue.versio,
    voimassaAlkuPvm: koodistotietue.voimassaAlkuPvm
  };
}

/**
 * Luo taulukollisesta koodistotietueita fronttiystävälliset versiot.
 * @param koodistotietueet Taulukollinen Koodistotietue-tyyppisiä objekteja.
 * @returns Taulukollinen fronttiystävällisiä koodistotietueita.
 */
export function luoFronttiystavallisetVersiotKoodistodatanTietueista(
  koodistotietueet: Array<Koodistotietue> = []
): Array<FKoodistotietue> {
  return koodistotietueet.map(tietue =>
    luoFronttiystavallinenVersioKoodistotietueesta(tietue)
  );
}

/**
 * @param fKoodistotietueet Taulukollinen fronttiystävällisiä koodisto-
 * tietueita.
 * @returns FKoodistotietue.koodiarvo mukaan järjestetty
 * taulukollinen fronttiystävällisiä koodistotietueita(FKoodistotietue).
 */
export function jarjestaFronttiystavallisetTietueet(
  fKoodistotietueet: Array<FKoodistotietue> = []
): Array<FKoodistotietue> {
  return sort(vertaileKahdenFKoodistotietueenKoodiarvoja, fKoodistotietueet);
}

/**
 * Käsittelee koodistopalvelusta saadut tietueet, järjestää ne koodiarvon mukaan ja
 * tallentaa järjestetyt fronttiystävälliset tietueet paikalliseen tietovarastoon.
 * @param koodistotietueet Koodistopalvelun mukaisessa muodossa olevat tietueet.
 * @param key Avain, jonka arvon mukaisesti tietueet tallennetaan paikalliseen
 * tietovarastoon.
 * @param localForage localForage-kirjaston ilmentymä.
 * @returns Tallennuksen lopputulos.
 */
export async function kasitteleKoodistotietueetJaTallennaNePaikalliseenTietovarastoon(
  koodistotietueet: Array<Koodistotietue>,
  key: PaikallisenTietovarastonAvain
): Promise<FKoodistotietue[]> {
  const jarjestetytFKoodistotietueet = jarjestaFronttiystavallisetTietueet(
    luoFronttiystavallisetVersiotKoodistodatanTietueista(koodistotietueet)
  );
  return await localForage.setItem(key, jarjestetytFKoodistotietueet);
}
