import { luoMuutosobjektitLisatietokentasta } from "helpers/lisatiedot";
import { createAlimaarayksetBEObjects } from "helpers/rajoitteetHelper";
import {
  find,
  flatten,
  forEach,
  last,
  mapObjIndexed,
  path,
  pathEq,
  propEq,
  reject,
  split,
  values
} from "ramda";
import { getAnchorPart } from "utils/anchor";

export const defineBackendChangeObjects = async (
  changeObjects = {},
  maaraystyypit,
  kohteet,
  koulutustyyppi
) => {
  const { rajoitteetByRajoiteId } = changeObjects;
  const kohde = getOpiskelijamaarakohdeByKoulutustyyppi(
    koulutustyyppi,
    kohteet
  );

  // Muodostetaan tehdyistä rajoittuksista objektit backendiä varten.
  // Linkitetään ensimmäinen rajoitteen osa yllä luotuun muutokseen ja
  // loput toisiinsa "alenevassa polvessa".
  const alimaaraykset = values(
    mapObjIndexed(asetukset => {
      return createAlimaarayksetBEObjects(
        kohteet,
        maaraystyypit,
        { kohde },
        // Poista asetukset joissa ei ole value arvoa (kohdennuksenkohdennus).
        reject(pathEq(["properties", "value"], ""), asetukset)
      );
    }, rajoitteetByRajoiteId)
  );

  // Lisää rajoitus muutosobjektit parent muutoksille, sekä lisätään metaan parentin tyyppi
  forEach(alimaarays => {
    if (!path(["parent"], alimaarays)) {
      const rajoiteId = last(
        split("_", getAnchorPart(alimaarays.meta.changeObjects[0].anchor, 0))
      );
      const rajoiteObjWithRajoitteenTyyppi = find(
        rajoiteObj =>
          path(["properties", "metadata", "section"], rajoiteObj) ===
          "opiskelijamaarat",
        rajoitteetByRajoiteId[rajoiteId]
      );
      alimaarays.meta = {
        ...alimaarays.meta,
        changeObjects: values(rajoitteetByRajoiteId[rajoiteId]),
        tyyppi: path(
          ["properties", "value", "value"],
          rajoiteObjWithRajoitteenTyyppi
        )
      };
    }
  }, flatten(alimaaraykset));

  // Lisätietokentän muutosten käsittely
  const lisatietomuutokset = await luoMuutosobjektitLisatietokentasta(
    kohde,
    changeObjects.opiskelijamaarat,
    find(propEq("tunniste", "RAJOITE"), maaraystyypit)
  );

  return flatten([lisatietomuutokset, alimaaraykset]).filter(Boolean);
};

const getOpiskelijamaarakohdeByKoulutustyyppi = (koulutustyyppi, kohteet) => {
  switch (koulutustyyppi) {
    case "1":
      return find(propEq("tunniste", "oppilasopiskelijamaara"), kohteet);
    case "2":
      return find(propEq("tunniste", "opiskelijamaarat"), kohteet);
    case "5":
      return find(propEq("tunniste", "opiskelijamaarat"), kohteet);
    default:
      return null;
  }
};
