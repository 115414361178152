import {
  addIndex,
  append,
  assocPath,
  concat,
  endsWith,
  filter,
  find,
  flatten,
  forEach,
  head,
  includes,
  isEmpty,
  isNil,
  join,
  keys,
  last,
  length,
  map,
  mapObjIndexed,
  path,
  pathEq,
  pipe,
  prop,
  propEq,
  reject,
  split,
  test,
  toLower,
  toUpper,
  unnest,
  values
} from "ramda";
import moment from "moment";
import { __ } from "i18n-for-browser";
import { createAlimaarayksetBEObjects } from "helpers/rajoitteetHelper";
import { sortByLocale } from "../helpers/html";
import { DATE_FORMAT } from "utils/constants";
import { getVoimassaoloText, isNowBetweenDates } from "utils/common";

function getAmountOfInstances(substring, string) {
  const re = new RegExp(substring, "g");
  return (string.match(re) || []).length;
}

function addEnding(ending, string, amountOfEndings = 0, index = 0) {
  const updatedString = string + ending;
  if (index < amountOfEndings) {
    return addEnding(ending, updatedString, amountOfEndings, index + 1);
  }
  return string;
}

const kohteenTarkentimet = ["enintaan", "vahintaan"];

/**
 * Palauttaa tarkentimen arvon.
 * @param {*} tarkennin
 * @param {*} useKuvaus
 * @param {*} koodiarvo Koodiarvolla voi rajata palautusarvoa siten, että
 * vain koodiarvon mukainen tarkentimen arvo huomioidaan.
 * @returns
 */
function getTarkentimenArvo(tarkennin, useKuvaus = false, koodiarvo) {
  let kuvaus = path(["properties", "value", "kuvaus"], tarkennin);
  let useKuvausInRajoite = path(
    ["properties", "value", "useKuvausInRajoite"],
    tarkennin
  );
  let tarkentimenArvo =
    (useKuvaus || useKuvausInRajoite) && kuvaus
      ? kuvaus
      : path(["properties", "value", "label"], tarkennin) ||
        path(["properties", "value"], tarkennin);
  tarkentimenArvo = koodiarvo
    ? filter(propEq("value", koodiarvo), tarkentimenArvo)
    : tarkentimenArvo;
  return Array.isArray(tarkentimenArvo)
    ? pipe(
        map(arvo => arvo.label),
        join(", ")
      )(tarkentimenArvo)
    : tarkentimenArvo;
}

function kayLapiKohdennus(
  kohdennus,
  locale,
  lista = [],
  format,
  ensimmainenRajoite = true,
  koodiarvo
) {
  const asetukset = join(
    " ",
    flatten(
      map(
        asetus => {
          let maaraaikaKasitelty = false;
          const tarkenninkomponentit = Object.keys(
            prop("tarkennin", asetus) || {}
          );
          return `${join(
            " ",
            map(tarkenninavain => {
              // Ei käsitellä päivämääriä enään uudestaan, jos ne on jo käsitelty tälle asetukselle.
              if (
                (tarkenninavain === "alkamispaiva" ||
                  tarkenninavain === "paattymispaiva") &&
                maaraaikaKasitelty
              ) {
                return null;
              }

              if (
                tarkenninavain === "alkamispaiva" ||
                tarkenninavain === "paattymispaiva"
              ) {
                const alkamispvm = path(
                  ["tarkennin", "alkamispaiva", "properties", "value"],
                  asetus
                );
                const paattymispvm = path(
                  ["tarkennin", "paattymispaiva", "properties", "value"],
                  asetus
                );

                maaraaikaKasitelty = true;
                return `<ul><li>${
                  alkamispvm && paattymispvm ? __("rajoitteet.ajalla") : ""
                } ${getVoimassaoloText(
                  alkamispvm,
                  paattymispvm,
                  locale,
                  __("common.from"),
                  __("common.until")
                )}`;
              }
              const tarkenninValue = asetus.kohde.properties.value.value;
              // TODO: Label pitäisi hakea koodistosta tarkenninValue arvon avulla jos koodistossa on muutettu tekstiä.
              const tarkenninLabel =
                tarkenninavain === "lukumaara"
                  ? asetus.kohde.properties.value.label
                  : "";
              const taydennyssana = includes(tarkenninValue, kohteenTarkentimet)
                ? {
                    pre: `on ${toLower(asetus.kohde.properties.value.label)}`,
                    post: "henkilöä"
                  }
                : null;

              const tarkentimenArvo = getTarkentimenArvo(
                asetus.tarkennin[tarkenninavain]
              );

              const muokattuTarkentimenArvo = moment(
                tarkentimenArvo,
                "YYYY-MM-DDTHH:mm:ss.SSSZ",
                true
              ).isValid()
                ? moment(tarkentimenArvo).format(DATE_FORMAT)
                : tarkentimenArvo;

              if (taydennyssana) {
                const item = join(
                  " ",
                  [
                    taydennyssana.pre,
                    muokattuTarkentimenArvo,
                    taydennyssana.post
                  ].filter(Boolean)
                );
                if (format === "list") {
                  return `<ul><li>${item}`;
                } else {
                  return item;
                }
              } else if (muokattuTarkentimenArvo) {
                /** Näytetään opiskelijamäärärajoitteen ensimmäinen rivi hieman eri tavalla */
                if (tarkenninavain === "lukumaara" && ensimmainenRajoite) {
                  return format === "list"
                    ? `: ${tarkenninLabel} ${muokattuTarkentimenArvo}`
                    : muokattuTarkentimenArvo;
                }
                return format === "list"
                  ? `<ul><li>${tarkenninLabel} ${muokattuTarkentimenArvo}`
                  : muokattuTarkentimenArvo;
              }
            }, tarkenninkomponentit)
          )}`;
        },
        isNil(kohdennus.rajoite)
          ? []
          : values(kohdennus.rajoite.asetukset) || []
      )
    )
  );

  const joista = path(["kohde", "properties", "value", "label"], kohdennus)
    ? kohdennus.kohde.properties.value.label
    : null;
  const kohdennuslukema = kohdennus.tarkennin
    ? values(kohdennus.tarkennin)[0].properties.value
    : null;

  let paivitettyLista = lista;
  if (joista) {
    paivitettyLista = append(
      `<ul><li>${format === "list" ? toLower(joista) : `, ${toLower(joista)}`}`,
      paivitettyLista
    );
    paivitettyLista = append(` ${kohdennuslukema}`, paivitettyLista);
  }
  const tarkennin = path(["rajoite", "kohde", "tarkennin"], kohdennus);

  const tarkenninavain = head(keys(tarkennin || {}));
  const tarkentimenArvo = getTarkentimenArvo(
    prop(tarkenninavain, tarkennin),
    isEmpty(lista),
    koodiarvo
  );
  const taydennyssana = null;

  let item = tarkentimenArvo;

  if (taydennyssana) {
    item = join(
      " ",
      [taydennyssana.pre, item, taydennyssana.post].filter(Boolean)
    );
  }

  if (!isNil(item)) {
    paivitettyLista = ensimmainenRajoite
      ? append(
          format === "list" ? `<ul class="mb-2"><li>${item}` : item,
          paivitettyLista
        )
      : paivitettyLista;
    paivitettyLista = append(asetukset, paivitettyLista);
  }

  if (kohdennus.kohdennukset) {
    return kayLapiKohdennukset(
      kohdennus.kohdennukset,
      locale,
      paivitettyLista,
      format
    );
  }

  return paivitettyLista;
}

export function kayLapiKohdennukset(
  kohdennukset,
  locale,
  lista = [],
  format,
  ensimmainenRajoite,
  koodiarvo
) {
  const indexedMap = addIndex(map);
  return indexedMap((kohdennus, index) => {
    return kayLapiKohdennus(
      kohdennus,
      locale,
      index > 0 ? [] : lista,
      format,
      ensimmainenRajoite,
      koodiarvo
    );
  }, kohdennukset);
}

export function getRajoiteListamuodossa(
  changeObjects = [],
  locale,
  rajoiteId,
  format = "list",
  koodiarvo
) {
  let listamuotoWithEndings = "";

  let rakenne = {};

  for (let i = 0; i < changeObjects.length; i += 1) {
    rakenne = assocPath(
      split(".", changeObjects[i].anchor),
      changeObjects[i],
      rakenne
    );
  }

  const baseAnchor = rajoiteId ? `rajoitteet_${rajoiteId}` : "rajoitteet";

  const kohdennukset = path([baseAnchor, "kohdennukset"], rakenne);

  if (kohdennukset) {
    const lapikaydytKohdennukset = kayLapiKohdennukset(
      kohdennukset,
      locale,
      [],
      format,
      true,
      koodiarvo
    );

    const kohdennusLista = pipe(
      values,
      map(kohdennus =>
        Array.isArray(kohdennus) ? append(kohdennus, []) : values(kohdennus)
      ),
      unnest
    )(lapikaydytKohdennukset);
    const indexMap = addIndex(map);
    listamuotoWithEndings = join(
      "",
      indexMap((kohdennus, index) => {
        // Lopuksi täytyy vielä sulkea avatut listat ja niiden alkiot.
        const s = join("", kohdennus);
        const amountOfInstances = getAmountOfInstances("<ul", s);
        return addEnding(
          "</li></ul>",
          s,
          index === 0 ? amountOfInstances - 1 : amountOfInstances
        );
      }, kohdennusLista)
    );
    listamuotoWithEndings = addEnding("</li></ul>", listamuotoWithEndings, 2);
  }
  return listamuotoWithEndings;
}

/**
 * Muodostaa rajoitteisiin kohdistuvista muutosobjekteista html-muotoisen merkkijonon
 * @param rajoiteChangeObjsByRajoiteId
 * @param locale
 * @param format
 * @returns {string}
 */
export function getKohdistuvatRajoitteet(
  rajoiteChangeObjsByRajoiteId,
  locale,
  format = "list"
) {
  let listamuotoWithEndings = "";
  let listamuoto = "";
  let rakenne = {};
  addIndex(forEach)((key, index) => {
    const changeObjects = rajoiteChangeObjsByRajoiteId[key].changeObjects || [];
    for (let i = 0; i < changeObjects.length; i += 1) {
      rakenne = assocPath(
        split(".", changeObjects[i].anchor),
        changeObjects[i],
        rakenne
      );
    }

    const baseAnchor = key ? `rajoitteet_${key}` : "rajoitteet";

    const kohdennukset = path([baseAnchor, "kohdennukset"], rakenne);

    if (kohdennukset) {
      const lapikaydytKohdennukset = kayLapiKohdennukset(
        kohdennukset,
        locale,
        [],
        format,
        index === 0
      );

      const kohdennusLista = pipe(
        values,
        map(kohdennus =>
          Array.isArray(kohdennus) ? append(kohdennus, []) : values(kohdennus)
        ),
        unnest
      )(lapikaydytKohdennukset);

      listamuotoWithEndings = join(
        "",
        map(kohdennus => {
          const s = join("", kohdennus);
          const amountOfInstances = getAmountOfInstances("<ul>", s);

          return addEnding("</li></ul>", s, amountOfInstances);
        }, kohdennusLista)
      );

      listamuoto = concat(listamuoto, listamuotoWithEndings);
    }
  }, keys(rajoiteChangeObjsByRajoiteId));
  return listamuoto;
}

export const getRajoitteetByKohde = (kohde, rajoitteetByRajoiteId) => {
  if (!rajoitteetByRajoiteId) {
    return [];
  }
  return reject(
    isNil,
    mapObjIndexed(rajoite => {
      const kohdeChangeObj = find(
        pipe(
          path(["anchor"]),
          test(/^rajoitteet_[^.]+.kohdennukset.0.rajoite.kohde.valikko/)
        ),
        rajoite
      );
      return pathEq(["properties", "value", "value"], kohde, kohdeChangeObj)
        ? rajoite
        : null;
    }, rajoitteetByRajoiteId)
  );
};

export const getRajoitteetBySection = (sectionId, rajoitteetByRajoiteId) => {
  return reject(
    isNil,
    mapObjIndexed(rajoite => {
      const kohdeChangeObject = find(
        pipe(
          path(["anchor"]),
          test(/^rajoitteet_[^.]+.kohdennukset.0.rajoite.kohde.valikko/)
        ),
        rajoite.changeObjects
      );
      return pathEq(
        ["properties", "value", "value"],
        sectionId,
        kohdeChangeObject
      )
        ? rajoite
        : null;
    }, rajoitteetByRajoiteId)
  );
};

export const rajoiteHasValue = (rajoite, value, propName = "value") => {
  if (!rajoite) {
    return false;
  }
  const changeObject = find(
    pipe(
      path(["anchor"]),
      test(
        /^rajoitteet_[^.]+.kohdennukset.0.rajoite.kohde.tarkennin.komponentti/
      )
    ),
    rajoite
  );
  const values = path(["properties", "value"], changeObject);
  return !!find(propEq(propName, value), unnest([values]));
};

export const getRajoitteetByValue = (
  value,
  rajoitteetByRajoiteId,
  propName = "value"
) => {
  if (!rajoitteetByRajoiteId) {
    return [];
  }
  return reject(
    isNil,
    map(rajoite => {
      return rajoiteHasValue(rajoite, value, propName) ? rajoite : null;
    }, rajoitteetByRajoiteId)
  );
};

export const getRajoitteetByValueStartsWith = (
  value,
  rajoitteetByRajoiteId
) => {
  if (!rajoitteetByRajoiteId) {
    return [];
  }
  return reject(
    isNil,
    mapObjIndexed(rajoite => {
      const changeObject = find(
        pipe(path(["anchor"]), includes("kohde.tarkennin.komponentti")),
        rajoite
      );
      return map(
        prop("value") || "",
        path(["properties", "value"], changeObject)
      ).filter(s => s.startsWith(value)).length
        ? rajoite
        : null;
    }, rajoitteetByRajoiteId)
  );
};

export const getRajoitteet = (
  value,
  rajoiteChangeObjsByRajoiteId,
  valueAttr = "value"
) => {
  return reject(
    isNil,
    map(rajoite => {
      const changeObjects = path(["changeObjects"], rajoite);
      const changeObject = find(
        pipe(path(["anchor"]), includes("kohde.tarkennin.komponentti")),
        changeObjects
      );
      const values = path(["properties", "value"], changeObject);
      if (Array.isArray(values)) {
        const rajoiteValue = find(propEq(valueAttr, value), values);
        if (rajoiteValue) {
          return rajoite;
        }
      } else {
        return pathEq(["properties", "value", valueAttr], value, changeObject)
          ? rajoite
          : null;
      }
    }, rajoiteChangeObjsByRajoiteId)
  );
};

/**
 *  Palauttaa html merkkauksen rajoitemääräyksistä html-lupaa / lomaketta varten
 *
 */
export const getRajoitteetFromMaarays = (
  alimaaraykset = [],
  locale,
  ajallaText,
  naytettavaArvo,
  returnAsString = false,
  parentMaarays = null, // Näyttää myös parent-määräyksen, jos se annetaan parametrina
  idsOfRemovedRestrictions = []
) => {
  const maaraysHtmlString = parentMaarays
    ? getRajoiteFromParentMaarays(parentMaarays, locale, naytettavaArvo)
    : "";

  const htmlString = handleAlimaaraykset(
    maaraysHtmlString,
    alimaaraykset,
    locale,
    ajallaText,
    idsOfRemovedRestrictions
  );

  return returnAsString ? (
    htmlString
  ) : (
    <ul
      className="htmlContent ml-8"
      dangerouslySetInnerHTML={{ __html: htmlString }}></ul>
  );
};

/** Rajoitemääräyksen parent-osan näyttäminen. (käytetään ainakin lomakkeen rajoitelaatikossa) */
const getRajoiteFromParentMaarays = (parentMaarays, locale, naytettavaArvo) => {
  let maaraysHtmlString = "";
  const value =
    find(
      metadata => metadata.kieli === locale,
      path(["koodi", "metadata"], parentMaarays) || []
    ) || prop("meta", parentMaarays);

  /** Opiskelijamäärämääräykset */
  if (naytettavaArvo === "tyyppi") {
    const tyyppiString =
      value[naytettavaArvo] === "yksittainen"
        ? __("opiskelijamaara.yksittainenKohdennus")
        : __("opiskelijamaara.kokonaismaara");
    maaraysHtmlString = `<ul><li>${tyyppiString}: ${parentMaarays.arvo}`;

    /** Dynaamiset tekstikentät */
  } else if (naytettavaArvo === "kuvaus") {
    maaraysHtmlString = `<ul><li>${prop("kuvaus", value)}`;
    /** Ulkomaat */
  } else if (naytettavaArvo === "ulkomaa") {
    maaraysHtmlString = `<ul><li>${path(["meta", "arvo"], parentMaarays)}`;
    /** Muut */
  } else {
    const metadata = find(
      maarays => prop("kieli", maarays) === toUpper(locale),
      path(["koodi", "metadata"], parentMaarays) || []
    );
    maaraysHtmlString = `<ul><li>${prop(naytettavaArvo, metadata)}`;
  }
  return maaraysHtmlString;
};

export const handleAlimaarays = (
  alimaarays,
  htmlString,
  locale,
  ajallaText,
  multiselectAlimaaraykset = null,
  idsOfRemovedRestrictions = []
) => {
  const arvo = getValueFromAlimaarays(alimaarays, toUpper(locale));
  let modifiedString = `${htmlString}<ul class="list-disc">`;
  const hasAlimaarays = !!length(alimaarays.aliMaaraykset);
  const isMaaraaikaRajoite =
    alimaarays.koodisto === "kujalisamaareetlisaksiajalla";

  if (!includes(alimaarays.meta.rajoiteId, idsOfRemovedRestrictions)) {
    if (isMaaraaikaRajoite) {
      const alkupvm = prop("alkupvm", arvo);
      const loppupvm = prop("loppupvm", arvo);
      modifiedString = `${modifiedString}<li class="list-disc">${
        alkupvm && loppupvm ? ajallaText : ""
      } ${getVoimassaoloText(
        alkupvm,
        loppupvm,
        locale,
        __("common.from"),
        __("common.until")
      )}</li>`;
    } else {
      if (multiselectAlimaaraykset) {
        multiselectAlimaaraykset = sortByLocale(
          locale,
          multiselectAlimaaraykset
        );
        modifiedString = `${modifiedString}<li class="list-disc">`;
        addIndex(forEach)((alimaarays, index) => {
          const last = index === length(multiselectAlimaaraykset) - 1;
          const arvo = getValueFromAlimaarays(alimaarays, toUpper(locale));
          const naytettavaArvo = `${arvo}${last ? "" : ", "}`;
          modifiedString = `${modifiedString}${naytettavaArvo}`;
        }, multiselectAlimaaraykset);
        modifiedString = `${modifiedString}</li>`;
      } else {
        modifiedString = `${modifiedString}<li class="list-disc">${arvo}`;
      }
    }

    if (hasAlimaarays) {
      modifiedString = handleAlimaaraykset(
        modifiedString,
        alimaarays.aliMaaraykset,
        locale,
        ajallaText,
        idsOfRemovedRestrictions
      );
    }
  }

  modifiedString = `${modifiedString}</ul>`;
  return modifiedString;
};

const handleAlimaaraykset = (
  modifiedString,
  alimaaraykset,
  locale,
  ajallaText,
  idsOfRemovedRestrictions = []
) => {
  let lapikaydytMultiselectit = [];
  forEach(alimaarays => {
    const multiselectUuid = path(["meta", "multiselectUuid"], alimaarays);
    /** Käydään vain yksi alimääräys läpi, joka sisältää tietyn multiselectUuid:n */
    if (
      !multiselectUuid ||
      !includes(multiselectUuid, lapikaydytMultiselectit)
    ) {
      const multiselectAlimaaraykset = multiselectUuid
        ? getMultiselectAlimaaraykset(multiselectUuid, alimaaraykset)
        : null;
      // Rajoite special case, mikäli rajoite ei ole voimassa, niin sitä ei näytetä html-luvalla. Lomakkeella se voidaan näyttää.
      const multiselectAlimaarayksetFiltered = multiselectAlimaaraykset
        ? filter(
            alimaarays => isRajoiteVoimassa(alimaarays.meta),
            multiselectAlimaaraykset
          )
        : null;
      modifiedString = handleAlimaarays(
        alimaarays,
        modifiedString,
        locale,
        ajallaText,
        multiselectAlimaarayksetFiltered,
        idsOfRemovedRestrictions
      );
      if (multiselectUuid) {
        lapikaydytMultiselectit = append(lapikaydytMultiselectit, [
          multiselectUuid
        ]);
      }
    }
  }, alimaaraykset);
  return modifiedString;
};

const getMultiselectAlimaaraykset = (multiselectUuid, alimaaraykset) => {
  return multiselectUuid
    ? filter(
        alimaarays =>
          pathEq(["meta", "multiselectUuid"], multiselectUuid, alimaarays),
        alimaaraykset
      )
    : null;
};

// Alimääräysten luonti rajoitteille, jotka on kytketty olemassa
// oleviin määräyksiin.
export const createMaarayksiaVastenLuodutRajoitteetBEObjects = (
  maaraykset,
  rajoitteetByRajoiteId,
  kohteet,
  maaraystyypit,
  kohde
) => {
  return flatten(
    map(maarays => {
      const maaraystaKoskevatRajoitteet = mapObjIndexed(rajoite => {
        if (rajoiteHasValue(rajoite, toUpper(maarays.koodiarvo))) {
          return createAlimaarayksetBEObjects(
            kohteet,
            maaraystyypit,
            {
              isMaarays: true,
              generatedId: maarays.uuid,
              kohde
            },
            rajoite
          );
        }
      }, rajoitteetByRajoiteId);
      return values(maaraystaKoskevatRajoitteet);
    }, maaraykset)
  ).filter(Boolean);
};

// Alimääräysten luonti rajoitteille, jotka on kytketty olemassa
// oleviin dynaamisia tekstikenttiä koskeviin määräyksiin.
export const createMaarayksiaVastenLuodutRajoitteetDynaamisilleTekstikentilleBEObjects =
  (maaraykset, rajoitteetByRajoiteId, kohteet, maaraystyypit, kohde) => {
    return flatten(
      map(maarays => {
        const maaraystaKoskevatRajoitteet = mapObjIndexed(rajoite => {
          const changeObject = find(
            pipe(path(["anchor"]), includes("kohde.tarkennin.komponentti")),
            rajoite
          );
          /** Tekstikentän id on muotoa koodiarvo-ankkuri */
          const rajoiteTekstikenttaId = find(
            s => s.includes(`${maarays.koodiarvo}-`),
            map(prop("value"), path(["properties", "value"], changeObject))
          );
          if (length(rajoiteTekstikenttaId) > 0) {
            const rajoitteenTekstikentanAnkkuri = last(
              split("-", rajoiteTekstikenttaId)
            );
            const rajoitteenTekstikentanKoodiarvo = head(
              split("-", rajoiteTekstikenttaId)
            );
            if (
              rajoitteenTekstikentanKoodiarvo === maarays.koodiarvo &&
              maarays.koodisto !== "lisatietoja" &&
              /** Määräyksen ankkuri on null jos tekstikenttiä ei voi lisätä */
              (path(["meta", "ankkuri"], maarays) == null ||
                rajoitteenTekstikentanAnkkuri ===
                  path(["meta", "ankkuri"], maarays))
            ) {
              return createAlimaarayksetBEObjects(
                kohteet,
                maaraystyypit,
                {
                  isMaarays: true,
                  generatedId: maarays.uuid,
                  kohde
                },
                rajoite
              );
            }
          }
        }, rajoitteetByRajoiteId);
        return values(maaraystaKoskevatRajoitteet);
      }, maaraykset)
    ).filter(Boolean);
  };

/** Palauttaa rajoitekriteerin arvon */
const getValueFromAlimaarays = (alimaarays, locale) => {
  /** Ulkomaa */
  if (alimaarays.koodisto === "kunta" && alimaarays.koodiarvo === "200") {
    return path(["meta", "kuvaus"], alimaarays);

    /** Määräaika on erikoistapaus. Palauttaa objektin, joka sisältää alkupvm:n sekä loppupvm:n */
  } else if (alimaarays.koodisto === "kujalisamaareetlisaksiajalla") {
    return {
      alkupvm: path(["meta", "alkupvm"], alimaarays),
      loppupvm: path(["meta", "loppupvm"], alimaarays)
    };

    /** Opetustehtävä, Kieli, Oppilaitos, Kunnat, Opetuksen järjestämismuoto, Oikeus sisäoppilaitosmuotoiseen koulutukseen */
  } else if (
    includes(alimaarays.koodisto, [
      "tpoluvantaiteenalat",
      "opetustehtava",
      "kielikoodistoopetushallinto",
      "oppilaitos",
      "kunta",
      "opetuksenjarjestamismuoto",
      "lukiooikeussisaooppilaitosmuotoiseenkoulutukseen"
    ])
  ) {
    return (
      getMetadataByLocaleAndPropertyFromMaarays(alimaarays, "nimi", locale) ||
      path(["meta", "kuvaus"], alimaarays)
    );

    /** Dynaamiset tekstikentät: Jos koodistosta tulevan lyhenne-kentan arvo on Kuvaus tai koodistosta ei saada nimeä
     * koodiarvolle, näytetään rajoitekriteerin arvona tekstikenttään syötetty kuvaus. Muutoin nimi koodistosta */
  } else if (
    includes(alimaarays.koodisto, [
      "lukioerityinenkoulutustehtavauusi",
      "lukiomuutkoulutuksenjarjestamiseenliittyvatehdot",
      "poerityinenkoulutustehtava",
      "pomuutkoulutuksenjarjestamiseenliittyvatehdot"
    ])
  ) {
    return getMetadataByLocaleAndPropertyFromMaarays(
      alimaarays,
      "lyhenne",
      "FI"
    ) === "Kuvaus" ||
      !getMetadataByLocaleAndPropertyFromMaarays(alimaarays, "nimi", locale)
      ? path(["meta", "kuvaus"], alimaarays)
      : getMetadataByLocaleAndPropertyFromMaarays(alimaarays, "nimi", locale);

    /** Joista enintään, Joista vähintään etc. */
  } else if (
    alimaarays.koodisto === "kujalisamaareetjoistalisaksi" ||
    alimaarays.koodisto === "kujalisamaareet"
  ) {
    return `${getMetadataByLocaleAndPropertyFromMaarays(
      alimaarays,
      "nimi",
      locale
    )} ${alimaarays.arvo}`;
  } else {
    console.info(alimaarays.koodisto);
    return "Virhetilanne.";
  }
};

const getMetadataByLocaleAndPropertyFromMaarays = (
  maarays,
  propertyName,
  locale
) => {
  return prop(
    propertyName,
    find(
      metadata => toUpper(prop("kieli", metadata)) === toUpper(locale),
      path(["koodi", "metadata"], maarays) || []
    )
  );
};

export const isRajoiteVoimassa = rajoite => {
  if (!rajoite || !rajoite.changeObjects) {
    return true;
  }
  const alkupvm = find(
    pipe(path(["anchor"]), endsWith(".alkamispaiva")),
    rajoite.changeObjects
  );
  const loppupvm = find(
    pipe(path(["anchor"]), endsWith(".paattymispaiva")),
    rajoite.changeObjects
  );
  const alkupvmValue = path(["properties", "value"], alkupvm);
  const loppupvmValue = path(["properties", "value"], loppupvm);
  return isNowBetweenDates(alkupvmValue, loppupvmValue);
};

export const isRajoiteVoimassaNew = rajoite => {
  if (!rajoite) {
    return false;
  }
  const alkupvm = find(
    pipe(path(["anchor"]), endsWith(".alkamispaiva")),
    rajoite
  );
  const loppupvm = find(
    pipe(path(["anchor"]), endsWith(".paattymispaiva")),
    rajoite
  );
  const alkupvmValue = path(["properties", "value"], alkupvm);
  const loppupvmValue = path(["properties", "value"], loppupvm);
  return isNowBetweenDates(alkupvmValue, loppupvmValue);
};

export const getVoimassaOlevatRajoitteet = rajoitteet => {
  return reject(
    isNil,
    mapObjIndexed(rajoite => {
      return isRajoiteVoimassa(rajoite) ? rajoite : null;
    }, rajoitteet || {})
  );
};

export const getPaatasonMaaraaikaRajoite = rajoitteet => {
  return find(rajoite => {
    const paatasonKohde = find(
      pipe(
        prop("anchor"),
        endsWith("kohdennukset.0.rajoite.asetukset.0.kohde")
      ),
      rajoite.changeObjects
    );
    return (
      !!paatasonKohde &&
      pathEq(
        ["properties", "value", "value"],
        "kujalisamaareetlisaksiajalla_1",
        paatasonKohde
      )
    );
  }, values(rajoitteet || {}));
};
