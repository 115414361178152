import { getRaw } from "basedata";
import { CellFn } from "graphHandling/graphTypes";
import { Lupa } from "Lupa";
import { backendRoutes } from "stores/utils/backendRoutes";
import { CellId } from "processes/CellId";

export const fetch_tulevatLuvat: CellFn = async (
  storeFunctions,
  intl,
  payload,
  result,
  customParams
) => {
  if (!customParams || !result) {
    throw new Error(
      "Määritä organisaation id (oid), jotta tulevat luvat voidaan noutaa."
    );
  }

  const organisaatio = result[CellId.FETCH_ORGANISATION];

  const { koulutustyyppi, language, oppilaitosId, oppilaitostyyppi } =
    customParams;

  try {
    const tulevatLuvat: Array<Lupa> = await getRaw(
      "tulevatLuvat",
      `${backendRoutes.tulevatLuvat.path}${organisaatio.oid}${
        backendRoutes.tulevatLuvat.postfix
      }?with=all&useKoodistoVersions=false${
        koulutustyyppi ? "&koulutustyyppi=" + koulutustyyppi : ""
      }${language ? `&kieli=${language}` : ""}${
        oppilaitostyyppi ? "&oppilaitostyyppi=" + oppilaitostyyppi : ""
      }${oppilaitosId ? "&oppilaitosOid=" + oppilaitosId : ""}`,
      [],
      backendRoutes.tulevatLuvat.minimumTimeBetweenFetchingInMinutes
    );
    return tulevatLuvat;
  } catch (err) {
    throw new Error(err as string);
  }
};
