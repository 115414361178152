import { find, map, propEq } from "ramda";
import { PaikallisenTietovarastonAvain } from "enums";
import localForage from "localforage";
import { Options } from "graphHandling/graphTypes";
import { Component } from "utils/lomakkeet";
import { Kielet } from "types";
/**
 * Mikäli päälomakkeella on valittuna opetuskieliä, tämä funktio määrittää
 * ja palauttaa pudotusvalikon, jossa valitut opetuskielet ovat valittavina
 * rajoitteiksi. Muussa tapauksessa palautuu tieto siitä, ettei rajoituksia
 * ole mahdollista tehdä opetuskielten perusteella, koska päälomakkeella
 * niistä yksikään ei ole valittuna.
 *
 * @param {array} valitutKielet - Sisältää päälomakkeella valittuina olevat
 * opetuskielet.
 */

/**
 * Mikäli päälomakkeella on valittuna opetuskieliä, tämä funktio määrittää
 * ja palauttaa pudotusvalikon, jossa valitut opetuskielet ovat valittavina
 * rajoitteiksi. Muussa tapauksessa palautuu tieto siitä, ettei rajoituksia
 * ole mahdollista tehdä opetuskielten perusteella, koska päälomakkeella
 * niistä yksikään ei ole valittuna.
 *
 * @param isReadOnly Onko lomake lukutilassa.
 * @param valitutKielet Päälomakkeella valittuina olevat opetuskielet.
 * @param locale fi / sv
 * @param isMulti Voidaanko valikosta valita useita opetuskieliä.
 * @param inputId Input id, pudotusvalikon yksilöivä tieto.
 * @returns Promise<Array<Component>>
 */
export default async function getOpetuskielikomponentit2(
  isReadOnly: boolean,
  valitutKielet: Options = [],
  locale: string,
  isMulti: boolean,
  inputId: string
): Promise<Array<Component>> {
  const kielet = (await localForage.getItem(
    PaikallisenTietovarastonAvain.KIELET_OPH
  )) as Kielet;

  // Palautettava lomakerakenne
  return kielet.length
    ? [
        {
          anchor: "komponentti",
          name: "Autocomplete",
          styleClasses: ["w-4/5", "xl:w-2/3", "mb-6"],
          properties: {
            forChangeObject: {
              section: "opetuskielet"
            },
            inputId,
            isMulti,
            isReadOnly,
            options: map(opetuskieli => {
              /**
               * Tarkistetaan, onko kyseinen opetuskieli valittuna
               * lomakkeella, jota vasten rajoituksia ollaan tekemässä.
               **/
              return find(
                propEq("value", opetuskieli.koodiarvo),
                valitutKielet
              );
            }, kielet).filter(Boolean),
            value: ""
          }
        }
      ]
    : [
        {
          anchor: "teksti",
          name: "StatusTextRow",
          properties: {
            title: "Ongelma kielien näyttämisessä."
          }
        }
      ];
}
