import { useCallback } from "react";
import PropTypes from "prop-types";
import Lomake from "components/02-organisms/Lomake";
import { useChangeObjectsByAnchorWithoutUnderRemoval } from "stores/muutokset";
import { useIntl } from "react-intl";
import common from "i18n/definitions/common";
import { useChangeObjects } from "../../../../stores/muutokset";
import { getAnchorPart } from "../../../../utils/anchor";

const constants = {
  formLocations: {
    erityinenKoulutustehtava: ["vapaaSivistystyo", "erityinenKoulutustehtava"]
  }
};

const ErityinenKoulutustehtava = ({
  maaraykset,
  isPreviewModeOn,
  mode = constants.mode,
  oppilaitostyyppi
}) => {
  const { formatMessage } = useIntl();
  const [, { createTextBoxChangeObject }] = useChangeObjects();
  const sectionId = "erityinenKoulutustehtava";
  const [changeObjects] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: sectionId
  });

  const onAddButtonClick = useCallback(
    (fromComponent, index, value = null) => {
      createTextBoxChangeObject(
        sectionId,
        getAnchorPart(fromComponent.fullAnchor, 1),
        index,
        value
      );
    },
    [createTextBoxChangeObject, sectionId]
  );

  return (
    <Lomake
      anchor={sectionId}
      changeObjects={changeObjects}
      data={{ maaraykset, oppilaitostyyppi, sectionId }}
      functions={{ onAddButtonClick }}
      formTitle={formatMessage(common.VSTLupaSectionTitleSchoolMissionSpecial)}
      isPreviewModeOn={isPreviewModeOn}
      isReadOnly={isPreviewModeOn}
      isRowExpanded={true}
      mode={mode}
      path={constants.formLocations.erityinenKoulutustehtava}
      rowTitle={formatMessage(
        common.VSTLupaSectionTitleSchoolMissionSpecial
      )}></Lomake>
  );
};

ErityinenKoulutustehtava.propTypes = {
  isPreviewModeOn: PropTypes.bool,
  mode: PropTypes.string,
  oppilaitostyyppi: PropTypes.string,
  maaraykset: PropTypes.array
};

export default ErityinenKoulutustehtava;
