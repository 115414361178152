import { defineSimpleButton } from "graphHandling/components/simpleButton/definitions/customizable";
import { CellFn } from "graphHandling/graphTypes";
import { getKoulutusmuotoByPredicate, localizeRouteKey } from "utils/common";
import { CellId } from "processes/CellId";
import { path, prop, propEq } from "ramda";
import { AppRoute } from "routes/app-routes";
import { Properties } from "utils/lomakkeet";
import education from "i18n/definitions/education";
import {
  Color,
  Variant
} from "../../../../components/00-atoms/SimpleButton/SimpleButton";

export const initialize_taydennaAsiaaPainike: CellFn = (
  storeFunctions,
  intl
) => {
  if (!intl) {
    console.warn("Parametri intl puuttuu.");
    return false;
  }
  const definition = defineSimpleButton(
    {
      ariaLabel: "Täydennä asiaa -painike",
      id: CellId.INITIALIZE_KOULUTUSMUOTOVALIKKO,
      isDisabled: false,
      icon: "Arrow",
      variant: Variant.INFO,
      className: "bg-[#faf9f1]", // tailwind taustaväri
      color: Color.PRIMARY,
      text: intl.formatMessage(education.taydennaAsiaa)
    },
    () => {
      const valittuKoulutusmuoto = prop(
        "value",
        storeFunctions.readPath([
          "components",
          CellId.INITIALIZE_KOULUTUSMUOTOVALIKKO,
          "properties"
        ]) as Properties
      );

      const koulutusmuoto = getKoulutusmuotoByPredicate(
        propEq("koulutustyyppi", valittuKoulutusmuoto),
        intl
      );

      if (koulutusmuoto) {
        const routeToAvoinTUVAHakemus = localizeRouteKey(
          intl.locale,
          AppRoute.Tuvahakemus,
          intl,
          {
            id:
              (path(
                ["values", 0, "value"],
                storeFunctions.readPath([
                  "components",
                  CellId.INITIALIZE_ORGANISAATIOVALIKKO,
                  "properties"
                ]) as Properties
              ) as string) || "",
            koulutusmuoto: koulutusmuoto.kebabCase,
            muutospyyntoUuid: prop(
              "uuid",
              storeFunctions.readPath([
                "custom",
                "avoinMuutospyynto"
              ]) as Properties
            ) as string,
            language:
              (prop(
                "value",
                storeFunctions.readPath([
                  "components",
                  CellId.INITIALIZE_LOKALISAATIOVALIKKO,
                  "properties"
                ]) as Properties
              ) as string) || intl.locale,
            page: 1
          }
        );

        storeFunctions.updateGraph(["custom", "goToRoute"], {
          value: routeToAvoinTUVAHakemus
        });
      }
      return true;
    }
  );

  storeFunctions.updateGraph(
    ["components", CellId.INITIALIZE_TAYDENNA_ASIAA_PAINIKE],
    definition
  );

  return true;
};
