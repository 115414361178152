import {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { COLORS } from "../../../modules/styles";
import { FormHelperText, IconButton } from "@mui/material";
import { InputLabel } from "@mui/material";
import { ComponentEvent } from "graphHandling/graphTypes";
import ClearIcon from "@mui/icons-material/Clear";

// Komponentin teksti lähetetään komponentin ulkopuolelle
// tässä määritellyn ajan (ms) kuluttua.
const sendOutDelay = 300;

export interface ITextBoxProps {
  ariaLabel?: string;
  id?: string;
  isDisabled?: boolean;
  isErroneous?: boolean;
  isHidden?: boolean;
  isPreviewModeOn?: boolean;
  isReadOnly?: boolean;
  isRemovable?: boolean;
  isRequired?: boolean;
  isValid?: boolean;
  label: string;
  placeholder: string;
  requiredMessage?: string;
  rows?: number;
  rowsMax?: number;
  shouldHaveFocusAt?: boolean;
  showValidationErrors?: boolean;
  title: string;
  value: string;
}

interface ITextBoxPropsAndEvents extends ITextBoxProps {
  onChange: ComponentEvent;
}

const defaultProps = {
  ariaLabel: "Tekstikenttä",
  delay: 300,
  id: `textBox-${Math.random()}`,
  isDisabled: false,
  isErroneous: false,
  isHidden: false,
  isReadOnly: false,
  isRemovable: false,
  isRequired: false,
  isValid: true,
  isVisited: false,
  placeholder: "",
  rows: 2,
  rowsMax: 100,
  shouldHaveFocusAt: false,
  showValidationErrors: false,
  title: "",
  tooltip: {},
  value: ""
};

const TextBox = ({
  ariaLabel,
  id = defaultProps.id,
  isDisabled = defaultProps.isDisabled,
  isErroneous = defaultProps.isErroneous,
  isHidden = defaultProps.isHidden,
  isReadOnly = defaultProps.isReadOnly,
  isRemovable = defaultProps.isRemovable,
  isRequired = defaultProps.isRequired,
  isValid = defaultProps.isValid,
  onChange,
  placeholder,
  requiredMessage,
  rows = defaultProps.rows,
  rowsMax = defaultProps.rowsMax,
  shouldHaveFocusAt = defaultProps.shouldHaveFocusAt,
  showValidationErrors = defaultProps.showValidationErrors,
  title,
  value
}: ITextBoxPropsAndEvents): null | ReactElement => {
  const [sendOutHandle, setSendOutHandle] = useState<number>();
  const [internalValue, setInternalValue] = useState("");
  const [isOkToSendOut, setIsOkToSendOut] = useState(false);

  const textBoxRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  useEffect(() => {
    if (isOkToSendOut) {
      onChange({
        changeProps: {
          id,
          value: internalValue
        }
      });
      setIsOkToSendOut(false);
    }
  }, [id, internalValue, isOkToSendOut, onChange]);

  const updateValue = useCallback(
    e => {
      setInternalValue(e.target.value);

      if (sendOutHandle) {
        clearTimeout(sendOutHandle);
      }

      const handle = setTimeout(() => {
        setIsOkToSendOut(true);
      }, sendOutDelay);

      setSendOutHandle(Number(handle));
    },
    [sendOutHandle]
  );

  const deleteTextBox = useCallback(() => {
    onChange({
      changeProps: {
        id,
        deleteMe: true,
        dateOfRemoval: new Date().getTime()
      }
    });
    return true;
  }, [id, onChange]);

  useEffect(() => {
    if (shouldHaveFocusAt && textBoxRef && textBoxRef.current) {
      textBoxRef.current.focus();
    }
  }, [shouldHaveFocusAt, textBoxRef]);

  return isHidden ? null : (
    <Fragment>
      {value !== null ? (
        <Fragment>
          <div className="flex flex-row w-full">
            <div className="flex flex-col w-full">
              {title && !isHidden && (
                <InputLabel
                  disabled={isDisabled || isReadOnly}
                  htmlFor={id}
                  shrink={false}
                  variant="outlined"
                  error={
                    isErroneous
                      ? isErroneous
                      : (isRequired && value && !isValid) ||
                        (!isRequired && !isValid)
                  }>
                  <span className="bg-white p-2">
                    {title}
                    {!isReadOnly && isRequired && "*"}
                  </span>
                </InputLabel>
              )}
              <TextareaAutosize
                aria-label={ariaLabel}
                disabled={isDisabled || isReadOnly}
                id={id}
                minRows={isReadOnly ? 1 : rows}
                maxRows={isReadOnly ? Infinity : rowsMax}
                onChange={updateValue}
                placeholder={isDisabled || isReadOnly ? "" : placeholder}
                ref={textBoxRef}
                value={internalValue}
                onResize={undefined}
                onResizeCapture={undefined}
              />
              {showValidationErrors && requiredMessage && !isHidden && (
                <FormHelperText
                  id="component-message-text"
                  style={{
                    paddingLeft: "0.5em",
                    marginBottom: "0.5em",
                    color: COLORS.OIVA_ORANGE_TEXT
                  }}>
                  {!value && requiredMessage}
                </FormHelperText>
              )}
            </div>
            {!isReadOnly && isRemovable && !isHidden && (
              <div className="ml-8 mr-1 mt-4">
                <IconButton
                  aria-label="delete"
                  size="large"
                  onClick={deleteTextBox}>
                  <ClearIcon fontSize="inherit" />
                </IconButton>
              </div>
            )}
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default TextBox;
