export enum Locale {
  FI = "fi",
  SV = "sv"
}

export enum LocaleUpper {
  FI = "FI",
  SV = "SV"
}

export enum Alkupera {
  ESITTELIJA = "ESITTELIJA",
  KJ = "KJ"
}

export enum AppLanguage {
  Finnish = "fi",
  Swedish = "sv"
}

/**
 * Avain tulee opintopolun datan rakenteesta.
 * @see FKoodistotietue
 */
export enum FTietueAvain {
  KOODIARVO = "koodiarvo"
}

export enum PaikallisenTietovarastonAvain {
  AJALLA = "ajalla",
  ENSISIJAISET_OPETUSKIELET_OPH = "ensisijaisetOpetuskieletOPH",
  JOISTA_LISAKSI = "joistaLisaksi",
  KIELET = "kielet",
  KIELET_OPH = "kieletOPH",
  KOHTEET = "kohteet",
  KOULUTUSALAT = "koulutusalat",
  KOULUTUSTYYPIT = "koulutustyypit",
  KUJALISAMAAREET = "kujalisamaareet",
  KUNNAT = "kunnat",
  LISATIEDOT = "lisatiedot",
  LUKIO_ERITYINEN_KOULUTUSTEHTAVA_UUSI = "lukioErityinenKoulutustehtavaUusi",
  LUKIO_MUUT_EHDOT = "lukioMuutKoulutuksenJarjestamiseenLiittyvatEhdot",
  LUPA = "lupa",
  LUVAN_TAITEENALAT_KOODISTO_TPO = "luvantaiteenalatKoodistoTPO",
  LUVAN_TAITEENALAT_TPO = "luvanTaiteenalatTPO",
  MAAKUNNAT = "maakunnat",
  MAAKUNTAKUNNAT = "maakuntakunnat",
  MAAKUNTAKUNNAT_RAW = "maakuntakunnatRaw",
  MAARAYSTYYPIT = "maaraystyypit",
  MUUT = "muut",
  MUUTOSPYYNTO = "muutospyynto",
  OIKEUS_SISAOPPILAITOSMUOTOISEEN_KOULUTUKSEEN = "oikeusSisaoppilaitosmuotoiseenKoulutukseen",
  OIVAPERUSTELUT = "oivaperustelut",
  OPETUKSEN_JARJESTAMISMUODOT = "opetuksenJarjestamismuodot",
  OPETUSKIELET = "opetuskielet",
  OPETUSTEHTAVAKOODISTO = "opetustehtavakoodisto",
  OPETUSTEHTAVAT = "opetustehtavat",
  OPPILAITOKSET_BY_OID = "oppilaitoksetByOid",
  OPPILAITOS = "oppilaitos",
  ORGANISAATIO = "organisaatio",
  PO_ERITYISET_KOULUTUSTEHTAVAT = "poErityisetKoulutustehtavat",
  PO_MUUT_EHDOT = "poMuutEhdot",
  TOISSIJAISET_OPETUSKIELET_OPH = "toissijaisetOpetuskieletOPH",
  TPO_MUUT_EHDOT = "muutEhdotTPO",
  TUTKINNOT = "tutkinnot",
  VANKILAT = "vankilat",
  VST_ERITYISET_KOULUTUSTEHTAVAT = "vstErityisetKoulutustehtavat",
  VST_MUUT_EHDOT = "muutEhdotVST",
  VST_OPPILAITOKSEN_VAIKUTUSALUE = "vstOppilaitoksenAlueellisuusJaValtakunnallisuus"
}

export enum JarjestajanStatus {
  AKTIIVINEN = "AKTIIVINEN"
}

export enum Tila {
  LUONNOS = "LUONNOS"
}

export enum Toiminto {
  LISAYS = "LISAYS",
  POISTO = "POISTO"
}

export enum Type {
  ADDITION = "addition",
  REMOVAL = "removal"
}

export enum MuutospyynnonTila {
  LUONNOS = "LUONNOS",
  AVOIN = "AVOIN",
  VALMISTELUSSA = "VALMISTELUSSA",
  TAYDENNETTAVA = "TAYDENNETTAVA",
  PAATETTY = "PAATETTY",
  PASSIVOITU = "PASSIVOITU",
  ESITTELYSSA = "ESITTELYSSA",
  KORJAUKSESSA = "KORJAUKSESSA"
}

export enum ToimintaAluePikavalinnat {
  EI_MAARITELTY = "eiMaaritelty",
  MILTEI_KOKO_MAA = "milteiKokoMaa",
  MAAKUNNAT_JA_KUNNAT = "maakunnatJaKunnat"
}

export enum Koodisto {
  MAAKUNTA = "maakunta",
  KUNTA = "kunta",
  NUTS1 = "nuts1"
}

export enum ToimintaAluePikavalintaKoodiarvot {
  MILTEI_KOKO_MAA = "FI1",
  EI_MAARITELTY = "FI2"
}
