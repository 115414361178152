import { TietueProps } from "graphHandling/components/multiTextBox/MultiTextBox";
import { CellFn } from "graphHandling/graphTypes";
import { getCurrentValue } from "graphHandling/graphUtils";
import common from "i18n/definitions/common";
import { IMultiTextBox } from "utils/lomakkeet";
import { CellId } from "processes/CellId";

const currentCellId = CellId.INITIALIZE_MULTI_TEXTBOX;

export const create_textBox: CellFn = (storeFunctions, intl) => {
  if (!intl) {
    console.warn("Parametri intl puuttuu.");
    return false;
  }

  const multiTextBox = storeFunctions.readPath([
    "components",
    CellId.INITIALIZE_MULTI_TEXTBOX
  ]) as IMultiTextBox;

  if (multiTextBox) {
    const nykyisetTietueet = getCurrentValue(
      "tietueet",
      multiTextBox
    ) as Array<TietueProps>;
    const createdAt = new Date();
    const id = `multiTextBox-${createdAt.getTime()}`;
    const tulevatTietueet = {
      ...nykyisetTietueet,
      [id]: {
        createdAt,
        id,
        placeholder: intl.formatMessage(common.kuvausPlaceholder),
        title: intl.formatMessage(common.kuvaus),
        value: ""
      }
    };

    storeFunctions.addModification(currentCellId, {
      tietueet: tulevatTietueet
    });
  }

  return true;
};
