import { Dispatch, ReactElement, SetStateAction } from "react";
import { FormControl, Pagination, PaginationItem, Select } from "@mui/material";
import common from "../../../i18n/definitions/common";
import { NavigateBefore, NavigateNext } from "@material-ui/icons";
import { useIntl } from "react-intl";

type InputProps = {
  pageNo: number;
  pageSize: number;
  totalPages: number;
  setPageNo: Dispatch<SetStateAction<number>>;
  setPageSize: Dispatch<SetStateAction<number>>;
};

const PageNavigator = ({
  pageNo,
  pageSize,
  totalPages,
  setPageNo,
  setPageSize
}: InputProps): ReactElement | null => {
  const intl = useIntl();

  if (totalPages < 1) {
    return null;
  }

  const backButton = () => (
    <div className={"pr-2 text-green-500"}>
      <NavigateBefore />
      {intl.formatMessage(common.edellinen)}
    </div>
  );
  const nextButton = () => (
    <div className={"pl-2 text-green-500"}>
      {intl.formatMessage(common.seuraava)}
      <NavigateNext />
    </div>
  );
  return (
    <div style={{ float: "right" }} className={"mr-1 mt-3"}>
      <FormControl>
        <label className={"text-sm"}>
          {intl.formatMessage(common.rowsPerPage)}
          <Select
            className={"text-sm"}
            size={"small"}
            native
            value={pageSize}
            onChange={e => {
              setPageNo(1);
              setPageSize(Number(e.target.value));
            }}
            inputProps={{
              name: "rows-per-page",
              id: "rows-per-page"
            }}
            sx={{ marginLeft: 2 }}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
          </Select>
        </label>
      </FormControl>
      <FormControl>
        <Pagination
          color={"primary"}
          className={"pt-1 ml-6"}
          count={totalPages}
          boundaryCount={1}
          page={pageNo}
          siblingCount={2}
          onChange={(event, page) => {
            setPageNo(page);
          }}
          renderItem={item => (
            <PaginationItem
              {...item}
              components={{ previous: backButton, next: nextButton }}
            />
          )}
        />
      </FormControl>
    </div>
  );
};

export default PageNavigator;
