import { getRaw } from "basedata";
import { CellFn } from "graphHandling/graphTypes";
import { backendRoutes } from "stores/utils/backendRoutes";
import { assoc, prop } from "ramda";
import { initializeRajoitteet } from "../../../helpers/alimaaraykset";

export const fetch_viimeisinLupa: CellFn = async (
  storeFunctions,
  intl,
  payload,
  result,
  customParams
) => {
  if (!customParams) {
    throw new Error(
      "Määritä organisaation ID (oid), jotta organisaatiohaku voidaan tehdä."
    );
  }

  const { koulutustyyppi, language, oid } = customParams;

  // TODO: Selvitä oppilaitosId:n ja oppilaitostyypin määrittyminen.
  const oppilaitosId = null;
  const oppilaitostyyppi = null;

  try {
    let viimeisinLupa = await getRaw(
      "viimeisinLupa",
      `${backendRoutes.viimeisinLupa.path}${oid}${
        backendRoutes.viimeisinLupa.postfix
      }?with=all&useKoodistoVersions=false${
        koulutustyyppi ? `&koulutustyyppi=${koulutustyyppi}` : ""
      }${language ? `&kieli=${language}` : ""}${
        oppilaitostyyppi ? "&oppilaitostyyppi=" + oppilaitostyyppi : ""
      }${oppilaitosId ? "&oppilaitosOid=" + oppilaitosId : ""}`,
      [],
      0
    );
    if (viimeisinLupa) {
      viimeisinLupa = assoc(
        "rajoitteet",
        initializeRajoitteet(prop("maaraykset", viimeisinLupa)),
        viimeisinLupa
      );
    }
    return viimeisinLupa;
  } catch (err) {
    console.info(err);
    throw new Error(err as string);
  }
};
