import { CellFn } from "graphHandling/graphTypes";
import { getRaw } from "basedata";
import { backendRoutes } from "stores/utils/backendRoutes";
import { getKoulutusmuotoByPredicate } from "utils/common";
import { assoc, prop, propEq, split, zipObj } from "ramda";
import { Koulutusmuoto } from "types";
import { initializeRajoitteet } from "../helpers/alimaaraykset";

export const fetch_lupaJaMuutospyynto: CellFn = async (
  storeFunctions,
  intl
) => {
  if (!intl) {
    console.warn("Parametri intl puuttuu.");
    return false;
  }

  const urlParams = zipObj(
    [
      "0",
      "locale",
      "rootPage",
      "koulutusmuotoKebabCase",
      "asianhallinta",
      "oid",
      "muutospyyntoUuid",
      "page",
      "language"
    ],
    split("/", window.location.pathname)
  );

  const { oid, koulutusmuotoKebabCase, muutospyyntoUuid } = urlParams;

  const koulutusmuoto = getKoulutusmuotoByPredicate(
    propEq("kebabCase", koulutusmuotoKebabCase),
    intl
  );

  if (koulutusmuoto) {
    const language = "";
    const oppilaitostyyppi = null;
    const oppilaitosId = null;
    let muutospyynto = null;
    if (muutospyyntoUuid && muutospyyntoUuid.length > 4) {
      muutospyynto = await getRaw(
        "muutospyynto",
        `${backendRoutes.muutospyynto.path + muutospyyntoUuid}`,
        [],
        0.5 // 0.5 = puolen minuutin cache
      );
      storeFunctions.updateGraph(["custom", "muutospyynto"], muutospyynto);
    }

    const { koulutustyyppi } = koulutusmuoto as Koulutusmuoto;

    /**
     * Jos muutospyyntö löytyy käytetään aina siltä löytyvää lupaUuid:ta luvan hakemiseksi.
     * Uuden (tallentamattoman) muutospyynnön pohjaksi haetaan viimeisin KJ:n lupa.
     **/
    let viimeisinLupa = !muutospyynto
      ? await getRaw(
          "viimeisinLupa",
          `${backendRoutes.viimeisinLupa.path}${oid}${
            backendRoutes.viimeisinLupa.postfix
          }?with=all&useKoodistoVersions=false${
            koulutustyyppi ? `&koulutustyyppi=${koulutustyyppi}` : ""
          }${language ? `&kieli=${language}` : ""}${
            oppilaitostyyppi ? "&oppilaitostyyppi=" + oppilaitostyyppi : ""
          }${oppilaitosId ? "&oppilaitosOid=" + oppilaitosId : ""}`,
          [],
          0.5 // 0.5 = puolen minuutin cache
        )
      : prop("lupaUuid", muutospyynto)
      ? await getRaw(
          "lupaByUuid",
          `${backendRoutes.lupaByUuid.path}${muutospyynto.lupaUuid}?with=all&useKoodistoVersions=false`,
          [],
          0.5
        )
      : null;

    if (viimeisinLupa) {
      viimeisinLupa = assoc(
        "rajoitteet",
        initializeRajoitteet(prop("maaraykset", viimeisinLupa)),
        viimeisinLupa
      );
    }
    storeFunctions.updateGraph(["custom", "viimeisinLupa"], viimeisinLupa);
  }

  return true;
};
