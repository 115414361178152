import {
  compose,
  endsWith,
  find,
  isNil,
  path,
  pathEq,
  prop,
  propEq,
  reject,
  unnest
} from "ramda";
import { Kohde, Maarays, Maaraystyyppi } from "koodistodatanTyypit";
import { ChangeObjects } from "utils/muutokset";
import { Muutos } from "types";
import { PaikallisenTietovarastonAvain, Toiminto } from "enums";
import localForage from "localforage";
import { FKoodistotietue } from "koodistodatanTyypitFrontille";
import { getMaarayksetByKoodisto } from "helpers/lupa";
import { isAnchor } from "utils/anchor";

/**
 * Luo muutosobjektit osion lisätietokenttään tehtyjen muutosten pohjalta.
 * @param kohde Kohde.
 * @param changeObjects Taulukollinen muutosobjekteja, joiden joukosta
 * funktio etsii lisätietokenttää koskevan muutosobjektin.
 * @returns Taulukollinen backend-kelpoisia muutosobjekteja tai null.
 */
export async function luoMuutosobjektitLisatietokentasta(
  kohde: Kohde,
  changeObjects: ChangeObjects,
  maaraystyyppi?: Maaraystyyppi
): Promise<Muutos[]> {
  const lisatiedot: Array<FKoodistotietue> | null = await localForage.getItem(
    PaikallisenTietovarastonAvain.LISATIEDOT
  );

  const maaraystyypit: Array<Maaraystyyppi> | null = await localForage.getItem(
    PaikallisenTietovarastonAvain.MAARAYSTYYPIT
  );

  if (lisatiedot && maaraystyypit) {
    const _maaraystyyppi =
      maaraystyyppi || find(propEq("tunniste", "OIKEUS"), maaraystyypit);

    if (_maaraystyyppi) {
      // Etsitään lisätietoja koskeva fKoodistotietue.
      const lisatiedotObj = find(
        pathEq(["koodisto", "koodistoUri"], "lisatietoja"),
        lisatiedot
      );

      if (lisatiedotObj) {
        // Etsitään luvan tiedoista lisätietoja koskevat määräykset.
        const lisatietojaKoskevatMaaraykset = await getMaarayksetByKoodisto(
          "lisatietoja"
        );

        // Etsitään lisätietoja koskeva muutosobjekti.
        const lisatiedotChangeObj = find(
          compose(endsWith(".lisatiedot.1"), prop("anchor")),
          changeObjects
        );

        if (lisatiedotChangeObj) {
          const isAnchorValid = isAnchor(lisatiedotChangeObj.anchor);
          // Luodaan poisto-objektit lupaan kuuluville lisätiedoille, jotka
          // koskevat kyseistä lomakkeen osiota eli tarkastellaan
          // määräyksen kohdetta ja tämän funktion parametrina saamaa kohdetta.
          const poistot = lisatietojaKoskevatMaaraykset
            .map((maarays: Maarays) => {
              return maarays.kohde.tunniste === kohde.tunniste &&
                lisatiedotChangeObj.properties.value !== maarays.meta.arvo
                ? {
                    kohde: maarays.kohde,
                    maaraystyyppi: _maaraystyyppi,
                    koodiarvo: maarays.koodiarvo,
                    koodisto: maarays.koodisto,
                    maaraysUuid: maarays.uuid,
                    meta: reject(isNil, {
                      arvo: maarays.meta.arvo as string,
                      changeObjects: isAnchorValid
                        ? [lisatiedotChangeObj]
                        : null
                    }),
                    tila: Toiminto.POISTO
                  }
                : null;
            })
            .filter(Boolean) as Array<Muutos>;

          // Tarkistetaan, onko tarvetta lisäykselle.
          const maarays = find(
            pathEq(["kohde", "tunniste"], kohde.tunniste),
            lisatietojaKoskevatMaaraykset
          );
          let lisays = null;

          if (
            (!maarays && lisatiedotChangeObj.properties.value !== "") ||
            (maarays &&
              path(["meta", "arvo"], maarays) !==
                lisatiedotChangeObj.properties.value)
          ) {
            lisays = {
              kohde,
              koodiarvo: lisatiedotObj.koodiarvo,
              koodisto: lisatiedotObj.koodisto.koodistoUri,
              maaraystyyppi: _maaraystyyppi,
              meta: reject(isNil, {
                arvo: lisatiedotChangeObj.properties.value as string,
                changeObjects: isAnchorValid ? [lisatiedotChangeObj] : null
              }),
              tila: Toiminto.LISAYS
            };
          }

          return reject(isNil, unnest([lisays, poistot]));
        } else {
          return [];
        }
      } else {
        return [];
      }
    } else {
      return [];
    }
  } else {
    return [];
  }
}
