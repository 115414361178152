import "../i18n-config";
import { __ } from "i18n-for-browser";

export default async function getFormStructureOfOppilaitoksenKoulutustehtava(
  data,
  { isPreviewModeOn, isReadOnly }
) {
  return {
    isValid: true,
    structure: [
      {
        anchor: "oppilaitoksenTarkoitus",
        components: [
          {
            anchor: "A",
            name: "TextBox",
            properties: {
              isPreviewModeOn,
              isReadOnly,
              label: __("common.VSTLupaSectionTitleSchoolPurpose"),
              placeholder: __("common.VSTLupaSectionTitleSchoolPurpose"),
              value: ""
            }
          }
        ]
      }
    ]
  };
}
