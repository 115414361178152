import {
  compose,
  concat,
  endsWith,
  filter,
  find,
  flatten,
  isEmpty,
  length,
  map,
  mapObjIndexed,
  nth,
  path,
  pathEq,
  prop,
  propEq,
  split,
  values
} from "ramda";
import localForage from "localforage";
import { createAlimaarayksetBEObjects } from "helpers/rajoitteetHelper";
import { getAnchorPart } from "utils/anchor";
import { getMaarayksetByTunniste } from "helpers/lupa";
import {
  createMaarayksiaVastenLuodutRajoitteetDynaamisilleTekstikentilleBEObjects,
  getRajoitteetByValue,
  getRajoitteetByValueStartsWith
} from "utils/rajoitteetUtils";
import { luoMuutosobjektitLisatietokentasta } from "helpers/lisatiedot";
import { PaikallisenTietovarastonAvain } from "enums";

const getAlimaaraykset = (
  kuvausnro,
  rajoitteetByRajoiteIdAndKoodiarvo,
  ankkuri,
  kohteet,
  maaraystyypit,
  kuvausBEChangeObject,
  koodiarvo
) => {
  const rajoitteetForKuvaus = getRajoitteetByValue(
    `${koodiarvo}-${kuvausnro}`,
    values(rajoitteetByRajoiteIdAndKoodiarvo)
  );

  if (rajoitteetForKuvaus) {
    // Muodostetaan tehdyistä rajoittuksista objektit backendiä varten.
    // Linkitetään ensimmäinen rajoitteen osa yllä luotuun muutokseen ja
    // loput toisiinsa "alenevassa polvessa".
    return values(
      mapObjIndexed(asetukset => {
        return createAlimaarayksetBEObjects(
          kohteet,
          maaraystyypit,
          kuvausBEChangeObject,
          asetukset
        );
      }, rajoitteetForKuvaus)
    );
  } else {
    return [];
  }
};

export const defineBackendChangeObjects = async (
  changeObjects = {},
  maaraystyypit,
  lupaMaaraykset,
  locale,
  kohteet,
  kohde,
  isValtakunnallinenKehitystehtava
) => {
  const { rajoitteetByRajoiteId } = changeObjects;

  const maaraykset = await getMaarayksetByTunniste(
    kohde.tunniste,
    lupaMaaraykset
  );
  const maaraystyyppi = find(propEq("tunniste", "OIKEUS"), maaraystyypit);
  const erityisetKoulutustehtavat =
    (await localForage.getItem(
      PaikallisenTietovarastonAvain.LUKIO_ERITYINEN_KOULUTUSTEHTAVA_UUSI
    )) || [];

  const maarayksiaVastenLuodutRajoitteet =
    createMaarayksiaVastenLuodutRajoitteetDynaamisilleTekstikentilleBEObjects(
      maaraykset,
      rajoitteetByRajoiteId,
      kohteet,
      maaraystyypit,
      kohde
    );

  const muutokset = map(koulutustehtava => {
    // Checkbox-kenttien muutokset
    const checkboxChangeObj = find(
      compose(
        endsWith(`.${koulutustehtava.koodiarvo}.valintaelementti`),
        prop("anchor")
      ),
      changeObjects.erityisetKoulutustehtavat
    );

    // Lkm-kenttien muutokset (Rahoituksen opiskelijamäärä)
    const lkmChangeObject = find(
      compose(endsWith(`.${koulutustehtava.koodiarvo}.lkm`), prop("anchor")),
      changeObjects.erityisetKoulutustehtavat
    );
    const arvo = path(["properties", "value"], lkmChangeObject);

    const tehtavaanLiittyvatMaaraykset = filter(
      m =>
        propEq("koodiarvo", koulutustehtava.koodiarvo, m) &&
        propEq("koodisto", "lukioerityinenkoulutustehtavauusi", m),
      maaraykset
    );

    const isCheckboxChecked =
      (!!tehtavaanLiittyvatMaaraykset.length && !checkboxChangeObj) ||
      (checkboxChangeObj &&
        pathEq(["properties", "isChecked"], true, checkboxChangeObj));

    // Kuvauskenttien muutokset kohdassa (muu koulutustehtava)
    const kuvausChangeObjects = filter(changeObj => {
      return (
        koulutustehtava.koodiarvo ===
          path(["metadata", "koodiarvo"], changeObj.properties) &&
        endsWith(".kuvaus", changeObj.anchor)
      );
    }, changeObjects.erityisetKoulutustehtavat);

    let checkboxBEchangeObject = null;
    let kuvausBEchangeObjects = [];

    const rajoitteetByRajoiteIdAndKoodiarvo = getRajoitteetByValueStartsWith(
      `${koulutustehtava.koodiarvo}-`,
      rajoitteetByRajoiteId
    );

    if ((length(kuvausChangeObjects) || lkmChangeObject) && isCheckboxChecked) {
      kuvausBEchangeObjects = map(
        changeObj => {
          const ankkuri =
            path(["properties", "metadata", "ankkuri"], changeObj) || "0";
          const koodiarvo = nth(1, split(".", changeObj.anchor));

          const liittyvaMaarays = find(
            maarays =>
              maarays.koodiarvo === koodiarvo &&
              pathEq(["meta", "ankkuri"], ankkuri, maarays),
            tehtavaanLiittyvatMaaraykset
          );

          /** Haetaan kuvaus-muutosobjektiin liittyvät rajoitteet */
          const liittyvatRajoitteet = getRajoitteetByValueStartsWith(
            `${koulutustehtava.koodiarvo}-`,
            rajoitteetByRajoiteIdAndKoodiarvo
          );

          const kuvaus = path(["properties", "value"], changeObj);
          const isDeleted = path(["properties", "isDeleted"], changeObj);
          const isMuokattu = liittyvaMaarays && !isDeleted;
          const changeObjectDeleted = isDeleted && !liittyvaMaarays;
          const tila = isCheckboxChecked && !isDeleted ? "LISAYS" : "POISTO";

          let kuvausBEChangeObject = changeObjectDeleted
            ? null
            : Object.assign(
                {},
                {
                  generatedId: Math.random(),
                  kohde,
                  koodiarvo: koulutustehtava.koodiarvo,
                  koodisto: koulutustehtava.koodisto.koodistoUri,
                  maaraystyyppi,
                  arvo: arvo || liittyvaMaarays?.arvo,
                  meta: {
                    ankkuri,
                    kuvaus: kuvausChangeObjects.length
                      ? kuvaus
                      : liittyvaMaarays?.meta?.kuvaus,
                    isValtakunnallinenKehitystehtava,
                    changeObjects: concat(values(liittyvatRajoitteet), [
                      checkboxChangeObj,
                      changeObj,
                      lkmChangeObject
                    ]).filter(Boolean)
                  },
                  tila,
                  maaraysUuid: tila === "POISTO" ? liittyvaMaarays.uuid : null
                }
              );

          /** Jos tekstikenttämääräystä on muokattu, pitää luoda poisto-objekti määräykselle */
          const muokkausPoistoObjekti = isMuokattu
            ? {
                kohde,
                koodiarvo: koulutustehtava.koodiarvo,
                koodisto: koulutustehtava.koodisto.koodistoUri,
                tila: "POISTO",
                maaraysUuid: liittyvaMaarays.uuid,
                maaraystyyppi,
                arvo: liittyvaMaarays?.arvo,
                meta: {
                  kuvaus: liittyvaMaarays?.meta?.kuvaus
                }
              }
            : null;

          const kuvausnro = getAnchorPart(changeObj.anchor, 2);
          const alimaaraykset = getAlimaaraykset(
            kuvausnro,
            liittyvatRajoitteet,
            ankkuri,
            kohteet,
            maaraystyypit,
            kuvausBEChangeObject,
            koulutustehtava.koodiarvo
          );

          return [kuvausBEChangeObject, muokkausPoistoObjekti, alimaaraykset];
        },
        kuvausChangeObjects.length ? kuvausChangeObjects : [lkmChangeObject]
      );
    } else {
      // Jos muokattuja kuvauksia ei kyseiselle koodiarvolle löydy, tarkistetaan
      // löytyykö checkbox-muutos. Jos löytyy, niin luodaan sille backend-muotoinen
      // muutosobjekti.
      checkboxBEchangeObject =
        checkboxChangeObj && checkboxChangeObj.properties.isChecked
          ? {
              generatedId: `erityinenKoulutustehtava-${Math.random()}`,
              kohde,
              koodiarvo: koulutustehtava.koodiarvo,
              koodisto: koulutustehtava.koodisto.koodistoUri,
              kuvaus: koulutustehtava.metadata[locale].kuvaus,
              maaraystyyppi,
              arvo,
              meta: {
                isValtakunnallinenKehitystehtava,
                changeObjects: concat(
                  values(rajoitteetByRajoiteIdAndKoodiarvo),
                  [checkboxChangeObj, lkmChangeObject]
                ).filter(Boolean)
              },
              tila: "LISAYS"
            }
          : null;

      // Muodostetaan tehdyistä rajoituksista objektit backendiä varten.
      // Linkitetään ensimmäinen rajoitteen osa yllä luotuun muutokseen ja
      // loput toisiinsa "alenevassa polvessa".
      const alimaaraykset =
        checkboxBEchangeObject && !isEmpty(rajoitteetByRajoiteIdAndKoodiarvo)
          ? values(
              mapObjIndexed(asetukset => {
                return createAlimaarayksetBEObjects(
                  kohteet,
                  maaraystyypit,
                  checkboxBEchangeObject,
                  asetukset
                );
              }, rajoitteetByRajoiteIdAndKoodiarvo)
            )
          : null;

      /** Jos checkboxi ei ole checkattu. Luodaan poisto-objektit koulutustehtävään
       * liittyville määräyksille (eli tekstikentille) */
      const uncheckedCheckBoxPoistot = !isCheckboxChecked
        ? map(maarays => {
            return {
              kohde,
              koodiarvo: koulutustehtava.koodiarvo,
              koodisto: koulutustehtava.koodisto.koodistoUri,
              tila: "POISTO",
              maaraysUuid: maarays.uuid,
              maaraystyyppi,
              meta: {
                kuvaus: maarays?.meta?.kuvaus,
                isValtakunnallinenKehitystehtava,
                changeObjects: concat(
                  values(rajoitteetByRajoiteIdAndKoodiarvo),
                  [checkboxChangeObj, lkmChangeObject]
                ).filter(Boolean)
              }
            };
          }, tehtavaanLiittyvatMaaraykset)
        : null;

      return [checkboxBEchangeObject, uncheckedCheckBoxPoistot, alimaaraykset];
    }
    return [checkboxBEchangeObject, kuvausBEchangeObjects];
  }, erityisetKoulutustehtavat);

  const lisatietomuutokset = await luoMuutosobjektitLisatietokentasta(
    kohde,
    changeObjects.erityisetKoulutustehtavat,
    maaraystyyppi
  );

  return flatten([
    muutokset,
    lisatietomuutokset,
    maarayksiaVastenLuodutRajoitteet
  ]).filter(Boolean);
};
