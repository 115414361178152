import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import LupaSection from "./LupaSection";
import Typography from "@mui/material/Typography";
import { COLORS } from "modules/styles";
import {
  LUPA_LISAKOULUTTAJAT,
  LUPA_SECTIONS
} from "scenes/Koulutusmuodot/AmmatillinenKoulutus/JarjestamislupaHTML/constants";
import PropTypes from "prop-types";
import common from "i18n/definitions/common";
import moment from "moment";
import localForage from "localforage";
import { PaikallisenTietovarastonAvain } from "enums";
import { parseLupa } from "utils/lupaParser";
import LupaLinkkiHTML, {
  LupaLinkkiTyyppi
} from "../../../../components/03-templates/HtmlLupanakyma/LupaLinkki.tsx";
import { DATE_FORMAT } from "utils/constants";

const TopSectionWrapper = styled.div`
  border-bottom: 1px solid ${COLORS.BORDER_GRAY};
`;

/**
 * Funktio rakentaa ammatillisen koulutuksen HTML-lupanäkymän
 * (TO DO: Siirrä lupanäkymän generointi tänne).
 * @param {*} lupa - Lupa, jonka tietoja hyödyntäen lupanäkymä muodostetaan.
 * @param {*} muut
 */
const JarjestamislupaJSX = ({ lupa, muut }) => {
  const { formatMessage, locale } = useIntl();
  // Luvan poikkeuskäsittely erikoisluville (17kpl)
  const titleMessageKey = common.lupaPageTitleAmmatillinen;
  const lupaException = LUPA_LISAKOULUTTAJAT[lupa.jarjestajaYtunnus];
  const dateString = new moment().format(DATE_FORMAT);

  const lupakohteet = useMemo(() => {
    return !lupa
      ? {}
      : parseLupa({ ...lupa }, formatMessage, locale.toUpperCase(), muut);
  }, [lupa, formatMessage, locale, parseLupa]);

  const [kielet, setKielet] = useState();

  useEffect(() => {
    localForage
      .getItem(PaikallisenTietovarastonAvain.KIELET)
      .then(kielet => {
        setKielet(kielet);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  return kielet ? (
    <div>
      {lupaException ? (
        <TopSectionWrapper className="pb-4">
          <Typography component="h2" variant="h2">
            {formatMessage(titleMessageKey, { date: "" })}
          </Typography>
        </TopSectionWrapper>
      ) : (
        <TopSectionWrapper className="pb-4">
          <Typography component="h2" variant="h2">
            {formatMessage(titleMessageKey, { date: dateString })}
          </Typography>
        </TopSectionWrapper>
      )}
      <LupaLinkkiHTML maaraavaLupa={lupa} tyyppi={LupaLinkkiTyyppi.ALILUPA} />
      {lupaException ? (
        ""
      ) : (
        <div>
          {Object.keys(LUPA_SECTIONS).map((k, i) => {
            return (
              <LupaSection
                key={i}
                kohde={lupakohteet[k]}
                ytunnus={lupa.jarjestajaYtunnus}
                lupaAlkuPvm={lupa.alkupvm}
                kielet={kielet}
              />
            );
          })}
        </div>
      )}
    </div>
  ) : null;
};

JarjestamislupaJSX.propTypes = {
  lupa: PropTypes.object.isRequired,
  lupakohteet: PropTypes.object,
  muut: PropTypes.array
};

export default JarjestamislupaJSX;
