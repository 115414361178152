import { ReactElement } from "react";
import { getVoimassaoloaika } from "utils/common";
import { useIntl } from "react-intl";

type Props = { alkupvm: string; koodi: string; loppupvm: string; nimi: string };

const VT = ({ alkupvm, koodi, loppupvm, nimi }: Props): ReactElement => {
  const intl = useIntl();
  return (
    <div className="flex mt-2">
      <div className="flex-1 pl-8 pr-4">
        {koodi ? koodi + " " : null}
        {nimi}
      </div>
      <div>{getVoimassaoloaika(intl, alkupvm, loppupvm)}</div>
    </div>
  );
};

export default VT;
