import { and, filter, includes, startsWith } from "ramda";
import { getAnchorInit } from "utils/anchor";
import { ComponentWithMetadata } from "utils/lomakkeet";

/**
 * Finds the child nodes of the given node filtered by names array.
 * @param {object} node - Includes an anchor and a properties object.
 * @param {array} componentsWithMetadata - Flatten structure of a form.
 * @param {array} names - Array of node / component names.
 * @returns {array} - Array of child nodes.
 */
export function getChildNodes(
  node: ComponentWithMetadata,
  componentsWithMetadata: Array<ComponentWithMetadata>,
  names = ["CheckboxWithLabel", "RadioButtonWithLabel"]
): Array<ComponentWithMetadata> {
  // The beginning of descendants' full anchors equals with the fullAnchorInit.
  const fullAnchorInit = getAnchorInit(node.fullAnchor);
  // Finding the relevant child nodes...
  const childNodes = filter(item => {
    // All of the following conditions must be fulfilled:
    return (
      // Only direct child nodes will be returned.
      item.anchorParts.length - 1 === node.anchorParts.length &&
      and(
        // Item must be a descendant of the checked node.
        startsWith(fullAnchorInit, item.fullAnchor),
        // Item's name must be one of the given name options.
        includes(item.name, names)
      ) &&
      node.formId === item.formId
    );
  }, componentsWithMetadata);
  return childNodes;
}
