import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Link from "@mui/material/Link";
import { Alert, AlertTitle, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import ErrorIcon from "@mui/icons-material/Error";

const PREFIX = "AlertMessage";

const classes = {
  root: `${PREFIX}-root`
};

const Root = styled("div")(({ theme }) => ({
  ".MuiAlert-filledInfo": {
    backgroundColor: "#f9faff",
    borderBottom: "1px solid #4299e1",
    borderRadius: 0,
    color: "#333333",
    fontWeight: 400,
    marginLeft: "0.4rem",
    paddingBottom: "0.7rem",
    paddingTop: "0.7rem",
    "& .MuiAlert-icon": {
      color: "#4299e1",
      marginLeft: "0.4rem"
    }
  },
  ".MuiAlert-filledWarning": {
    backgroundColor: "#fefcf3",
    border: "1px solid #e5c214",
    borderRadius: "0.1rem",
    color: "#333333",
    fontWeight: 400,
    marginLeft: "0.4rem",
    paddingBottom: "0.7rem",
    paddingTop: "0.7rem",
    "& .MuiAlert-icon": {
      color: "#e5c214",
      marginLeft: "0.4rem"
    },
    paddingLeft: "2%"
  },
  ".MuiAlert-root": {
    display: "flex",
    alignItems: "center"
  },
  [`& .${classes.root}`]: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    },
    "& .MuiTypography-gutterBottom": {
      margin: 0 // removes extra margin especially if no message but only title
    },
    "& .MuiAlert-root": {
      display: "flex"
    },
    "& .MuiAlert-action": {
      marginLeft: 0,
      paddingLeft: "0.5em",
      flexGrow: "1"
    },
    "& .MuiButtonBase-root": {
      marginLeft: "auto !important"
    },
    "& .MuiLink-root": {
      color: "green",
      padding: "2px 0.5em 0 0"
    }
  }
}));

const AlertMessage = props => {
  const [isVisible, setVisible] = useState(true);

  const clickCallback = e => {
    e.preventDefault();
    return props.handleLinkClick();
  };

  const firstLinkClickCallback = e => {
    e.preventDefault();
    return props.handleFirstLinkClick();
  };

  return (
    <Root className={`${isVisible ? "" : "hidden"}`}>
      <Collapse in={props.isVisible}>
        <Alert
          icon={<ErrorIcon />}
          iconMapping={{
            info: <InfoIcon fontSize="inherit" className="text-[#216ed2]" />
          }}
          id={props.id}
          aria-label={props.ariaLabel}
          severity={props.type}
          variant={props.variant}
          action={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%"
              }}>
              {props.linkUrl && props.linkText && (
                <Link
                  href={props.linkUrl}
                  style={{ cursor: "pointer", textDecoration: "underline" }}>
                  {props.linkText}
                </Link>
              )}
              {!props.linkUrl && props.handleLinkClick && props.linkText && (
                <Link
                  onClick={clickCallback}
                  style={{ cursor: "pointer", textDecoration: "underline" }}>
                  {props.linkText}
                </Link>
              )}
              {props.isClosable && (
                <IconButton
                  style={{
                    color: "#666666",
                    marginLeft: "1rem",
                    marginRight: "0.7rem",
                    fontSize: "1.5rem"
                  }}
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setVisible(false)}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              )}
            </div>
          }>
          {props.title && <AlertTitle>{props.title}</AlertTitle>}
          <p className="p-0">
            {props.firstLinkText && props.handleFirstLinkClick ? (
              <Fragment>
                {props.message}
                <Link
                  onClick={firstLinkClickCallback}
                  style={{
                    cursor: "pointer",
                    marginLeft: "0.3rem",
                    textDecoration: "underline"
                  }}>
                  {props.firstLinkText}
                </Link>
                .
              </Fragment>
            ) : (
              props.message
            )}
          </p>
        </Alert>
      </Collapse>
    </Root>
  );
};

AlertMessage.defaultProps = {
  firstLinkText: "",
  firstLinkUrl: "",
  id: "Alert",
  isClosable: true,
  type: "info",
  ariaLabel: "alert",
  linkText: "<missing linkText prop>",
  isVisible: true,
  variant: "outlined"
};

AlertMessage.propTypes = {
  /** Text of the link next to text. */
  firstLinkText: PropTypes.string,
  /** Id of the component */
  id: PropTypes.string,
  /** can alert be closed */
  isClosable: PropTypes.bool,
  /** is alert visible */
  isVisible: PropTypes.bool,
  /** aria-label as string */
  ariaLabel: PropTypes.string,
  /** title as string */
  title: PropTypes.string,
  /** message as string */
  message: PropTypes.string,
  /** type of alert info (default), warning, error, success */
  type: PropTypes.string,
  /** link url as string (optional) */
  linkUrl: PropTypes.string,
  /** link text as string (optional) */
  linkText: PropTypes.string,
  /** link clicking callback function of the link next to text  */
  handleFirstLinkClick: PropTypes.func,
  /** link clicking callback function (if no linkUrl given) */
  handleLinkClick: PropTypes.func,
  /** Outlook of the alert */
  variant: PropTypes.string
};

export default AlertMessage;
