import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import Lomake from "components/02-organisms/Lomake";
import common from "i18n/definitions/common";
import { useChangeObjectsByAnchorWithoutUnderRemoval } from "stores/muutokset";
import tuva from "i18n/definitions/tuva";
import FormTitle from "components/00-atoms/FormTitle";
import { toLower } from "ramda";

const constants = {
  mode: "modification",
  formLocation: ["tuva", "opetuskielet"]
};

const Opetuskieli = ({
  isPreviewModeOn,
  maaraykset,
  mode = constants.mode,
  rajoitteet,
  sectionId,
  title,
  eiNoudatetaOpetuskielet,
  parentKoulutusmuoto
}) => {
  const intl = useIntl();
  const [changeObjects] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: sectionId
  });

  if (eiNoudatetaOpetuskielet) {
    return (
      <Lomake
        anchor={sectionId}
        changeObjects={changeObjects}
        data={{ maaraykset, rajoitteet }}
        formTitle={title}
        isPreviewModeOn={isPreviewModeOn}
        isRowExpanded={true}
        mode={mode}
        path={constants.formLocation}
        showCategoryTitles={true}
        rowTitle={intl.formatMessage(common.kielet)}
      />
    );
  } else if (isPreviewModeOn) {
    return (
      <div>
        <FormTitle level={3} title={intl.formatMessage(common.opetuskieli)} />
        <p>
          {intl.formatMessage(tuva.maaratyllaOpetuskielella, {
            koulutusmuoto: toLower(parentKoulutusmuoto.genetiivi)
          })}
        </p>
      </div>
    );
  }

  return null;
};

Opetuskieli.propTypes = {
  code: PropTypes.string,
  eiNoudatetaOpetuskielet: PropTypes.bool,
  isPreviewModeOn: PropTypes.bool,
  maaraykset: PropTypes.array,
  mode: PropTypes.string,
  rajoitteet: PropTypes.object,
  sectionId: PropTypes.string,
  title: PropTypes.string,
  parentKoulutusmuoto: PropTypes.object
};

export default Opetuskieli;
