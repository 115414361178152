import { GraphStoreFunctions } from "graphHandling/graphTypes";
import { equals } from "ramda";
import { CellId } from "processes/CellId";

/**
 * Tarkistaa organisaatiolistauksen tilan.
 * @param storeFunctions GraphStoreFunctions-tyyppinen objekti.
 * @returns Palauttaa true, jos organisaatiolista vaatii päivityksen eli on vanhentunut.
 */
export function checkOrganisaatiotFetch(
  storeFunctions: GraphStoreFunctions
): boolean {
  const validOrganisationListSource = storeFunctions.readPath([
    "custom",
    "validOrganisationListSource"
  ]);

  return !equals(
    validOrganisationListSource,
    storeFunctions.readPath([
      "components",
      CellId.INITIALIZE_ORGANISAATIOVALIKKO,
      "properties",
      "listSource"
    ])
  );
}
