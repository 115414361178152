import { head, isEmpty, path, prop, split } from "ramda";
import {
  getLukumaarakomponentit,
  getMaaraaikakomponentit
} from "services/lomakkeet/rajoitteet/rajoitedialogi/tarkenninkomponentit/yhteiset/tarkenninkomponentit";
import getLuvanTaiteenalakomponentit from "services/lomakkeet/rajoitteet/rajoitedialogi/tarkenninkomponentit/taiteen-perusopetus/1-luvanTaiteenalat";
import getOpetustehtavakomponentit from "services/lomakkeet/rajoitteet/rajoitedialogi/tarkenninkomponentit/esi-ja-perusopetus/1-opetustehtavat";
import getOikeusSisaoppilaitosmuotoiseenKoulutukseen from "./lukiokoulutus/3-oikeusSisaoppilaitosmuotoiseenKoulutukseen";
import getOpetuksenJarjestamismuotokomponentit from "services/lomakkeet/rajoitteet/rajoitedialogi/tarkenninkomponentit/esi-ja-perusopetus/4-opetuksenjarjestamismuoto";
// Koulutusmuodoille yhteiset tarkentimet
import getKunnat from "services/lomakkeet/rajoitteet/rajoitedialogi/tarkenninkomponentit/yhteiset/kunnat";
import getMuutKunnatJoistaOppilaita from "services/lomakkeet/rajoitteet/rajoitedialogi/tarkenninkomponentit/taiteen-perusopetus/2-muutKunnatJoistaOppilaita";
import getOpetuskielikomponentit from "services/lomakkeet/rajoitteet/rajoitedialogi/tarkenninkomponentit/yhteiset/opetuskielet";
import getErityisetKoulutustehtavat from "services/lomakkeet/rajoitteet/rajoitedialogi/tarkenninkomponentit/yhteiset/erityisetKoulutustehtavat";
import getOppilaitokset from "services/lomakkeet/rajoitteet/rajoitedialogi/tarkenninkomponentit/yhteiset/oppilaitokset";
import { getMaaraaikalomake } from "services/lomakkeet/rajoitteet/rajoitedialogi/tarkenninkomponentit/yhteiset/maaraaika";
import getMuutEhdot from "./yhteiset/muutEhdot";
import getKunnat2 from "./yhteiset/kunnat2";
import { __ } from "i18n-for-browser";
import getOpetuskielikomponentit2 from "./yhteiset/opetuskielet2";

const tarkenninlomakkeet = {
  // Enintään
  kujalisamaareet_1: getLukumaarakomponentit,
  // Vähintään
  kujalisamaareet_2: getLukumaarakomponentit,
  // Ajalla
  kujalisamaareetlisaksiajalla_1: getMaaraaikakomponentit,

  erityisetKoulutustehtavat: getErityisetKoulutustehtavat,
  luvanTaiteenalat: getLuvanTaiteenalakomponentit,
  valtakunnallisetKehittamistehtavat: getErityisetKoulutustehtavat,
  erityisenKoulutustehtavanRahoitus: getErityisetKoulutustehtavat,
  muutEhdot: getMuutEhdot,
  oikeusSisaoppilaitosmuotoiseenKoulutukseen:
    getOikeusSisaoppilaitosmuotoiseenKoulutukseen,
  opetuksenJarjestamismuodot: getOpetuksenJarjestamismuotokomponentit,
  opetuskielet: (isReadOnly, osionData = [], locale, isMulti, inputId) => {
    // Kutsutaan oikeaa funktiota riippuen siitä, onko päälomakkeen
    // opetuskieliosio toteutettu CategorizedList- vai Process -mallilla.
    if (isEmpty(osionData)) {
      return null;
    } else {
      if (path([0, "properties"], osionData)) {
        // Kun käytössä on CategorizedList-malli.
        return getOpetuskielikomponentit(
          isReadOnly,
          osionData,
          locale,
          isMulti,
          inputId
        );
      } else {
        // Kun käytössä on Process-malli.
        return getOpetuskielikomponentit2(
          isReadOnly,
          osionData,
          locale,
          isMulti,
          inputId
        );
      }
    }
  },
  opetustehtavat: getOpetustehtavakomponentit,
  oppilaitokset: getOppilaitokset,
  toimintaalue: (isReadOnly, osionData = [], locale, isMulti, inputId) => {
    // Kutsutaan oikeaa funktiota riippuen siitä, onko päälomakkeen
    // toiminta-alueosio toteutettu CategorizedList- vai Process -mallilla.
    if (isEmpty(osionData)) {
      return null;
    } else {
      if (path(["valitutKunnatJaMaakunnat", 0, "name"], osionData) === "Chip") {
        // Kun käytössä on Process-malli.
        return getKunnat2(isReadOnly, osionData, locale, isMulti, inputId);
      } else {
        // Kun käytössä on CategorizedList-malli.
        return getKunnat(isReadOnly, osionData, locale, isMulti, inputId);
      }
    }
  },
  muutKunnatJoistaOppilaita: getMuutKunnatJoistaOppilaita,
  maaraaika: getMaaraaikalomake,
  opiskelijamaarat: (isReadOnly, osionData, locale, isMulti, inputId) => [
    {
      anchor: "komponentti",
      name: "Autocomplete",
      styleClasses: ["w-4/5", "xl:w-2/3", "mb-6"],
      properties: {
        forChangeObject: {
          section: "opiskelijamaarat"
        },
        inputId,
        isMulti,
        isReadOnly,
        options: [
          {
            value: "kokonaismaara",
            label: __("opiskelijamaara.kokonaismaara")
          },
          {
            value: "yksittainen",
            label: __("opiskelijamaara.yksittainenKohdennus")
          }
        ],
        value: []
      }
    }
  ]
};

export const getTarkenninkomponentit = async (
  key,
  locale,
  osioidenData,
  isReadOnly = false,
  koulutustyyppi,
  voidaankoValitaUseita = false,
  inputId
) => {
  if (!key) {
    return false;
  }
  const tarkenninFn = prop(key, tarkenninlomakkeet);
  if (tarkenninFn) {
    return tarkenninFn(
      isReadOnly,
      prop(head(split("_", key)), osioidenData),
      locale,
      voidaankoValitaUseita,
      inputId,
      koulutustyyppi
    );
  } else {
    console.info(key, "tarkenninkomponentteja ei löytynyt");
    return [];
  }
};
