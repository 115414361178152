import { getCurrentProps } from "graphHandling/graphUtils";
import {
  addIndex,
  concat,
  dropLast,
  find,
  includes,
  isEmpty,
  isNil,
  map,
  mapObjIndexed,
  prop,
  propEq,
  reject,
  sortBy
} from "ramda";
import { memo, ReactElement } from "react";
import { IGroupedChipCollections } from "utils/lomakkeet";
import { ChangeObjects } from "utils/muutokset";
import { useIntl } from "react-intl";
import HtmlContent from "components/01-molecules/HtmlContent";
import {
  getHtmlFormattedRestrictionByKoodiarvo,
  getRestrictionsInListFormat,
  RajoitteetListamuodossa
} from "graphs/toiminta-alue/restrictions_utils";
import {
  getPaatasonMaaraaikaRajoite,
  getVoimassaOlevatRajoitteet,
  isRajoiteVoimassa,
  rajoiteHasValue
} from "../../utils/rajoitteetUtils";

const SimpleListFromGroupedChipCollections = memo(
  ({
    gChipCs,
    luvanRajoitteet,
    rajoitteet
  }: {
    gChipCs: IGroupedChipCollections;
    luvanRajoitteet: Array<{
      restrictionString: string;
      koodiarvo: string;
      voimassa: boolean;
    }>;
    rajoitteet?: Record<string, { changeObjects: ChangeObjects }>;
  }): ReactElement | null => {
    if (!gChipCs) {
      return null;
    }
    const { locale } = useIntl();

    let kunnat: Array<{ label: string; rajoiteString: null | string }> = [];
    const maakunnat: Array<string> = [];

    const foundRajoiteStrings: Array<string> = [];

    mapObjIndexed(gChipC => {
      const { isIndeterminate, isLineThrough, isChecked, label } =
        getCurrentProps(gChipC.properties.parentChip);
      if (isChecked && !isLineThrough && !isIndeterminate) {
        maakunnat.push(label as string);
      } else {
        const { chips } = gChipC.properties.chipCollection.properties || {};
        kunnat = isIndeterminate
          ? reject(
              isNil,
              concat(
                kunnat,
                reject(
                  isNil,
                  map(chip => {
                    const {
                      isChecked,
                      isLineThrough,
                      isLineThroughable,
                      isRemovable,
                      label,
                      value: kunnanKoodiarvo
                    } = getCurrentProps(chip);
                    if (
                      isChecked &&
                      (isRemovable || isLineThroughable) &&
                      !isLineThrough
                    ) {
                      const luvanRajoite = find(
                        propEq("koodiarvo", kunnanKoodiarvo),
                        luvanRajoitteet
                      );

                      const kohdistuvatRajoitteet = rajoitteet
                        ? reject(
                            isNil,
                            mapObjIndexed(rajoite => {
                              return rajoiteHasValue(
                                rajoite.changeObjects,
                                kunnanKoodiarvo
                              )
                                ? rajoite
                                : null;
                            }, rajoitteet)
                          )
                        : null;

                      const paatasonMaaraaikaRajoite =
                        getPaatasonMaaraaikaRajoite(kohdistuvatRajoitteet);

                      if (!isRajoiteVoimassa(paatasonMaaraaikaRajoite)) {
                        return null;
                      }

                      const voimassaOlevatRajoitteet =
                        getVoimassaOlevatRajoitteet(kohdistuvatRajoitteet);

                      const rajoitteetListamuodossa =
                        getRestrictionsInListFormat(
                          voimassaOlevatRajoitteet,
                          locale
                        );
                      const rajoiteString = rajoitteetListamuodossa
                        ? (getHtmlFormattedRestrictionByKoodiarvo(
                            kunnanKoodiarvo as string,
                            rajoitteetListamuodossa as RajoitteetListamuodossa
                          ) as string)
                        : null;
                      const droppedRajoiteString = rajoiteString
                        ? dropLast(20, rajoiteString)
                        : "";
                      const obj = {
                        label: label as string,
                        rajoiteString:
                          luvanRajoite && droppedRajoiteString
                            ? `${droppedRajoiteString}${luvanRajoite.restrictionString}`
                            : rajoiteString
                            ? rajoiteString
                            : luvanRajoite
                            ? `<ul><li>${label}${luvanRajoite.restrictionString}</li></ul>`
                            : null
                      };

                      if (
                        obj.rajoiteString &&
                        !includes(obj.rajoiteString, foundRajoiteStrings)
                      ) {
                        foundRajoiteStrings.push(obj.rajoiteString);
                      }
                      return obj;
                    }
                    return null;
                  }, chips)
                )
              )
            )
          : kunnat;
      }
    }, gChipCs.properties.gChipCs);

    return (
      <ul className="list-disc px-6">
        {addIndex(map)(
          (location, index) => (
            <li key={index}>{location as string}</li>
          ),
          maakunnat
        )}
        {!isEmpty(maakunnat) && <br />}
        {addIndex(map)((_location, index) => {
          const location = _location as {
            label: string;
            rajoiteString: string;
          };
          const { label, rajoiteString } = location;
          if (rajoiteString) {
            return (
              <li key={index}>
                <HtmlContent content={rajoiteString} />
              </li>
            );
          } else {
            return <li key={index}>{label}</li>;
          }
        }, sortBy(prop("label"), kunnat))}
      </ul>
    );
  }
);

SimpleListFromGroupedChipCollections.displayName =
  "Simple list from a Grouped chip collections";

export default SimpleListFromGroupedChipCollections;
