import { CellFn } from "graphHandling/graphTypes";
import { defineAutocomplete } from "graphHandling/components/autocomplete/definitions/customizable";
import localforage from "localforage";
import { Koodisto, PaikallisenTietovarastonAvain, Toiminto } from "enums";
import { MaakuntakunnatRaw, MaakuntaRaw, Maarays } from "koodistodatanTyypit";
import {
  append,
  compose,
  equals,
  filter,
  find,
  flatten,
  head,
  isNil,
  length,
  map,
  mapObjIndexed,
  or,
  prop,
  propEq,
  reject,
  sortBy,
  split,
  values
} from "ramda";
import { getLocalizedProperty } from "services/lomakkeet/utils";
import { Options } from "graphHandling/graphTypes";
import { Lupa } from "Lupa";
import { Muutos, Muutospyynto } from "types";

function getMaakuntaByKuntakoodi(
  kuntakoodi: string,
  maakuntakunnat: MaakuntakunnatRaw
): MaakuntaRaw {
  const maakunnat = filter(maakuntakunta => {
    return !!find(propEq("koodiArvo", kuntakoodi), maakuntakunta.kunta);
  }, maakuntakunnat);

  return maakunnat[0];
}

const initialize_autocomplete: CellFn = async (storeFunctions, intl) => {
  if (!intl) {
    return false;
  }

  const maakuntakunnat = (await localforage.getItem(
    PaikallisenTietovarastonAvain.MAAKUNTAKUNNAT_RAW
  )) as MaakuntakunnatRaw;

  const lupa = storeFunctions.readPath(["custom", "viimeisinLupa"]) as Lupa;

  let kuntaMaakuntamaaraykset: Array<Maarays> = [];

  if (lupa) {
    kuntaMaakuntamaaraykset = filter(
      compose(
        k => or(k === Koodisto.KUNTA, k === Koodisto.MAAKUNTA),
        prop("koodisto")
      ),
      lupa.maaraykset
    );
  }

  const muutospyynto = storeFunctions.readPath([
    "custom",
    "muutospyynto"
  ]) as Muutospyynto;

  let kuntaMaakuntaMuutokset: Array<Muutos> = [];

  if (muutospyynto) {
    const { muutokset } = muutospyynto;
    kuntaMaakuntaMuutokset = filter(
      compose(k => or(k === "kunta", k === "maakunta"), prop("koodisto")),
      muutokset
    );
  }

  let kaikkiBackendmuutoksillaValitutKunnat: any = [];

  const options: Options = sortBy(
    prop("label"),
    reject(
      isNil,
      flatten(
        values(
          mapObjIndexed(maakuntakunta => {
            const backendMuutoksillaValitutKunnat = filter(muutos => {
              const o = filter(maakuntakunta => {
                return !!find(
                  propEq("koodiArvo", muutos.koodiarvo),
                  maakuntakunta.kunta
                );
              }, maakuntakunnat);
              return (
                o[0]?.koodiArvo === maakuntakunta.koodiArvo &&
                muutos.tila === Toiminto.LISAYS
              );
            }, kuntaMaakuntaMuutokset);

            kaikkiBackendmuutoksillaValitutKunnat = flatten(
              append(
                backendMuutoksillaValitutKunnat,
                kaikkiBackendmuutoksillaValitutKunnat
              )
            );

            let kuntamaaraykset: Array<Maarays> = [];
            let isMaakuntaCheckedByMaarays = false;
            let isMaakuntaCheckedByKuntamaaraykset = false;

            /**
             * Käydään läpi viimeisimmän luvan määräykset.
             */
            if (lupa) {
              const maakuntaMaarays = find(
                propEq("koodiarvo", maakuntakunta.koodiArvo),
                kuntaMaakuntamaaraykset
              );

              if (maakuntaMaarays) {
                isMaakuntaCheckedByMaarays = true;
              }

              kuntamaaraykset = filter((kuntaMaarays): any => {
                const maakuntaRaw = getMaakuntaByKuntakoodi(
                  kuntaMaarays.koodiarvo,
                  maakuntakunnat
                );
                return maakuntaRaw.koodiArvo === maakuntakunta.koodiArvo;
              }, filter(propEq("koodisto", "kunta"), kuntaMaakuntamaaraykset) as Array<Maarays>);
              isMaakuntaCheckedByKuntamaaraykset = !!length(kuntamaaraykset);
            }

            if (
              maakuntakunta.koodiArvo !== "21" &&
              maakuntakunta.koodiArvo !== "99"
            ) {
              const kuntaOptions: Options = reject(
                isNil,
                map(kunta => {
                  const kuntaMaarays = find(
                    propEq("koodiarvo", kunta.koodiArvo),
                    kuntamaaraykset
                  );
                  return kuntaMaarays
                    ? null
                    : {
                        label: getLocalizedProperty(
                          kunta.metadata,
                          intl.locale,
                          "nimi"
                        ),
                        value: `${kunta.koodiArvo}-${maakuntakunta.koodiArvo}`
                      };
                }, maakuntakunta.kunta)
              );

              return isMaakuntaCheckedByMaarays ||
                isMaakuntaCheckedByKuntamaaraykset
                ? kuntaOptions
                : append(
                    {
                      label: getLocalizedProperty(
                        maakuntakunta.metadata,
                        intl.locale,
                        "nimi"
                      ),
                      value: maakuntakunta.koodiArvo
                    },
                    kuntaOptions
                  );
            }
            return null;
          }, maakuntakunnat)
        )
      )
    )
  );

  const optionsAfterBackendChanges = filter(option => {
    const kunnanKoodiarvo = head(split("-", option.value as string));
    return !find(
      propEq("koodiarvo", kunnanKoodiarvo),
      kaikkiBackendmuutoksillaValitutKunnat
    );
  }, options);

  return defineAutocomplete(
    {
      inputId: "maakunnat-ja-kunnat-filter",
      isSearchable: true,
      isMulti: false,
      options,
      placeholder: "Lisää maakuntia ja kuntia...",
      searchStr: "",
      values: []
    },
    {
      onChange: payload => {
        return payload;
      }
    },
    equals(optionsAfterBackendChanges, options)
      ? undefined
      : { backend: { options: optionsAfterBackendChanges } }
  );
};

export default initialize_autocomplete;
