import {
  both,
  find,
  flatten,
  isNil,
  map,
  path,
  prop,
  propEq,
  reject
} from "ramda";
import { PaikallisenTietovarastonAvain } from "enums";
import { getChangeObjByAnchor } from "utils/muutokset";
import localForage from "localforage";
import { luoMuutosobjektitLisatietokentasta } from "helpers/lisatiedot";
import { getMaarayksetByTunniste } from "helpers/lupa";

/**
 * Palauttaa taulukollisen backend-muotoisia muutosobjekteja.
 * @param {object} changeObjects
 * @param {array} maaraystyypit
 * @param {array} kohteet
 * @param {array} lupaMaaraykset
 */
export async function defineBackendChangeObjects(
  changeObjects = [],
  maaraystyypit = [],
  kohteet = [],
  lupaMaaraykset
) {
  const kohde = find(propEq("tunniste", "oppilaitoksentoimintaalue"), kohteet);
  const maaraykset = await getMaarayksetByTunniste(
    kohde.tunniste,
    lupaMaaraykset
  );
  const items = await localForage.getItem(
    PaikallisenTietovarastonAvain.VST_OPPILAITOKSEN_VAIKUTUSALUE
  );

  const maaraystyyppi = find(propEq("tunniste", "OIKEUS"), maaraystyypit);

  const radioAreaBackendChangeObjects = map(item => {
    const changeObj = getChangeObjByAnchor(
      `oppilaitoksenAlueellisuusJaValtakunnallisuus.${item.koodiarvo}.A`,
      changeObjects
    );
    const maarays = find(
      both(
        propEq("koodisto", "vstoppilaitoksenalueellisuusjavaltakunnallisuus"),
        propEq("koodiarvo", item.koodiarvo)
      ),
      maaraykset
    );
    const isChecked = path(["properties", "isChecked"], changeObj);
    const kuvausChangeObj = getChangeObjByAnchor(
      `oppilaitoksenAlueellisuusJaValtakunnallisuus.${item.koodiarvo}.kuvaus.A`,
      changeObjects
    );

    const muutokset = [];
    if (changeObj || kuvausChangeObj) {
      if (isChecked || (!changeObj && kuvausChangeObj)) {
        muutokset.push(
          reject(isNil, {
            generatedId: `${path(
              ["koodisto", "koodistoUri"],
              item
            )}-${Math.random()}`,
            kohde,
            koodiarvo: item.koodiarvo,
            koodisto: item.koodisto.koodistoUri,
            maaraystyyppi,
            meta: {
              changeObjects: [changeObj, kuvausChangeObj].filter(Boolean),
              kuvaus: path(["properties", "value"], kuvausChangeObj)
            },
            tila: "LISAYS"
          })
        );
      }

      if (maarays) {
        muutokset.push({
          kohde,
          koodiarvo: maarays.koodiarvo,
          koodisto: maarays.koodisto,
          maaraystyyppi,
          meta: {
            changeObjects: [changeObj, kuvausChangeObj].filter(Boolean)
          },
          maaraysUuid: prop("uuid", maarays),
          tila: "POISTO"
        });
      }
    }
    return muutokset;
  }, items).filter(Boolean);

  const lisatietomuutokset = await luoMuutosobjektitLisatietokentasta(
    kohde,
    changeObjects,
    maaraystyyppi
  );

  return flatten([radioAreaBackendChangeObjects, lisatietomuutokset]);
}
