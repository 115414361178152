import { RadioButtonGroup } from "utils/lomakkeet";
import { IntlShape } from "react-intl";
import { ComponentType } from "graphHandling/components/componentType";
import { ComponentEvent } from "graphHandling/graphTypes";
import { RadioButtonGroupProps } from "../RadioButtonGroup";

export function defineRadioButtonGroup(
  properties: RadioButtonGroupProps,
  intl: IntlShape,
  functions: {
    onChange: ComponentEvent;
  }
): RadioButtonGroup {
  const definition = {
    initialProperties: properties,
    name: ComponentType.GROUP_OF_RADIO_BUTTONS,
    onChange: functions.onChange,
    properties
  };

  return definition;
}
