import { CellFn } from "graphHandling/graphTypes";
import { getRaw } from "basedata";
import { backendRoutes } from "stores/utils/backendRoutes";
import localForage from "localforage";

export const fetch_maakuntakunnat: CellFn = async (storeFunctions, intl) => {
  if (!intl) {
    console.warn("Parametri intl puuttuu.");
    return false;
  }

  const maakuntakunnat = await getRaw(
    "maakuntakunnat",
    backendRoutes.maakuntakunnat.path
  );

  await localForage.setItem("maakuntakunnatRaw", maakuntakunnat);

  return maakuntakunnat;
};
