import { findParent } from "./findParent";
import { updateChangeObjectsArray } from "./updateChangeObjectsArray";
import { uncheckRadioButtonsOnSameLevel } from "./uncheckRadioButtonsOnSameLevel";
import { isEveryChildNodeUnchecked } from "./isEveryChildNodeUnchecked";
import { findSiblings } from "./findSiblings";
import { isEveryNodeUnchecked } from "./isEveryNodeUnchecked";
import { modifyNode } from "./modifyNode";
import { ComponentWithMetadata } from "utils/lomakkeet";
import { ChangeObjects } from "utils/muutokset";

/**
 * Poistaa ruksit ylemmillä tasoilla olevista komponenteista.
 * @param componentWithMetadata Metadatalla höystetty komponentti.
 * @param componentsWithMetadata Taulukollinen metadatalla höystettyjä komponentteja.
 * @param changeObjects Taulukollinen muutosobjekteja.
 * @returns Taulukollinen muutosobjekteja.
 */
export function deactivateNodesPredecessors(
  componentWithMetadata: ComponentWithMetadata,
  componentsWithMetadata: Array<ComponentWithMetadata>,
  changeObjects: ChangeObjects = [],
  index = 0
): ChangeObjects {
  // Etsitään komponenttia tasoa ylempänä oleva komponentti (myös. komponentti A).
  const parentNode = findParent(componentWithMetadata, componentsWithMetadata, [
    "CheckboxWithLabel",
    "RadioButtonWithLabel"
  ]);

  // Jos komponentti A on olemassa ja se kuuluu samaan lomakerakennealueeseen,
  // käydään taso, jolla komponentti A on, läpi.
  if (parentNode && parentNode.formId === componentWithMetadata.formId) {
    /**
     * Jos parametrina annetulla komponentilla ei ole sisarkomponentteja tai jos kaikki
     * sisarkomponentit ovat ruksaamattomia, tulee komponentti A asettaa ruksaamattomaksi.
     **/
    const siblings = findSiblings(
      componentWithMetadata,
      componentsWithMetadata
    );

    const siblingsUnchecked = isEveryNodeUnchecked(siblings, changeObjects);

    if (!siblings || siblingsUnchecked) {
      changeObjects = updateChangeObjectsArray(
        parentNode,
        { isChecked: false, isIndeterminate: false },
        changeObjects
      );
    } else {
      changeObjects = modifyNode(
        parentNode,
        componentsWithMetadata,
        changeObjects
      );
    }

    // Jos komponentti A on radio button, tulee poistaa ruksit sen sisarkomponenteista.
    if (parentNode.name === "RadioButtonWithLabel") {
      changeObjects = uncheckRadioButtonsOnSameLevel(
        parentNode,
        componentsWithMetadata,
        changeObjects
      );
    }

    // Komponentti A saattaa olla tasoa ylempänä olevasta komponentista rippuvainen.
    return deactivateNodesPredecessors(
      parentNode,
      componentsWithMetadata,
      changeObjects,
      index + 1
    );
  } else if (index > 0) {
    const isEveryChildUnchecked = isEveryChildNodeUnchecked(
      componentWithMetadata,
      componentsWithMetadata,
      changeObjects
    );
    if (isEveryChildUnchecked) {
      changeObjects = updateChangeObjectsArray(
        componentWithMetadata,
        { isChecked: false, isIndeterminate: false },
        changeObjects
      );
    } else {
      changeObjects = modifyNode(
        componentWithMetadata,
        componentsWithMetadata,
        changeObjects
      );
    }
  }

  return changeObjects;
}
