import PropTypes from "prop-types";
import DialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "../DialogTitle/index";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import { withStyles } from "@mui/styles";

const PREFIX = "ConfirmDialog";

const classes = {
  paper: `${PREFIX}-paper`,
  root: `${PREFIX}-root`
};

const StyledDialog = styled(Dialog)(({ theme }) => {
  return {
    [`& .${classes.paper}`]: { minWidth: "360px" },

    [`& .${classes.root}`]: {
      minWidth: "300px",
      "& > *:not(:last-child)": {
        marginBottom: "20px",
        [theme.breakpoints.up("sm")]: {
          marginRight: theme.spacing(1),
          marginBottom: theme.spacing(0)
        }
      }
    }
  };
});

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
}))(MuiDialogActions);

const ConfirmDialog = props => {
  const {
    isConfirmDialogVisible = false,
    handleOk,
    handleCancel,
    handleExitAndAbandonChanges,
    onClose,
    messages,
    loadingSpinner = false
  } = props;

  return (
    <StyledDialog
      open={isConfirmDialogVisible}
      fullWidth={true}
      aria-labelledby="confirm-dialog"
      maxWidth="sm"
      classes={{ root: classes.root }}>
      <DialogTitle id="confirm-dialog" onClose={onClose}>
        <span className="mr-12">{messages.title}</span>
      </DialogTitle>
      <DialogContent>
        <div className="py-2 px-8">{messages.content}</div>
      </DialogContent>
      <DialogActions>
        <div
          className={
            classes.root + " flex flex-col sm:flex-row flex-grow sm:grow-0"
          }>
          <Button onClick={handleCancel} color="primary" variant="outlined">
            {messages.cancel}
          </Button>
          {!!handleExitAndAbandonChanges && (
            <Button
              onClick={handleExitAndAbandonChanges}
              color="primary"
              variant="outlined">
              {messages.noSave}
            </Button>
          )}
          <Button
            onClick={handleOk}
            color="primary"
            variant="contained"
            disabled={loadingSpinner}>
            {loadingSpinner ? <CircularProgress size={20} /> : messages.ok}
          </Button>
        </div>
      </DialogActions>
    </StyledDialog>
  );
};

ConfirmDialog.propTypes = {
  isConfirmDialogVisible: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  handleExitAndAbandonChanges: PropTypes.func,
  messages: PropTypes.object,
  loadingSpinner: PropTypes.bool,
  onClose: PropTypes.func
};

export default ConfirmDialog;
