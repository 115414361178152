import { getAnchorBase } from "utils/anchor";
import {
  find,
  map,
  propEq,
  compose,
  filter,
  includes,
  prop,
  path,
  flatten,
  reject,
  isNil,
  isEmpty,
  endsWith,
  pipe,
  assocPath,
  pathEq,
  head,
  not
} from "ramda";
import { fillForBackend } from "services/lomakkeet/backendMappings";
import { Kohde, Maarays, Maaraystyyppi } from "koodistodatanTyypit";
import { Toiminto } from "enums";
import { Muutos } from "types";
import { ChangeObjects } from "utils/muutokset";
import moment from "moment";
import { ISO_DATE_FORMAT } from "../utils/constants";

export const getChangesToSave = (
  changeObjects: { muutokset: ChangeObjects; perustelut: ChangeObjects },
  kohde: Kohde,
  maaraystyypit: Array<Maaraystyyppi>,
  koulutukset: Array<any>,
  maaraykset: Array<Maarays> = []
): Array<Muutos> => {
  return reject(
    isNil,
    flatten(
      map(koulutus => {
        const koodistoUri = path(["koodisto", "koodistoUri"], koulutus);
        const koodiarvo = path(["koodiarvo"], koulutus);

        const maarays = find(maarays => {
          return (
            propEq("koodisto", koodistoUri, maarays) &&
            propEq("koodiarvo", koodiarvo, maarays)
          );
        }, maaraykset);

        const changes = filter(muutos => {
          return (
            pathEq(
              ["properties", "metadata", "koodisto", "koodistoUri"],
              koodistoUri,
              muutos
            ) &&
            pathEq(["properties", "metadata", "koodiarvo"], koodiarvo, muutos)
          );
        }, changeObjects.muutokset);

        const anchor: string = path(["anchor"], head(changes)) || "";
        const anchorInit = `${getAnchorBase(anchor)}.${koodiarvo}`;

        const perustelut = filter(
          compose(includes(anchorInit), prop("anchor")),
          changeObjects.perustelut
        );

        if (isEmpty(changes) && isEmpty(perustelut)) {
          // Kyseiseen koulutukseen ei kohdistu muutoksia
          return null;
        }

        const koulutusChangeObj = find(
          compose(not, includes("voimassaoloaika"), prop("anchor")),
          changes
        );

        const isKoulutuspoisto =
          koulutusChangeObj && !koulutusChangeObj.properties.isChecked;

        const perustelutForBackend = fillForBackend(perustelut, anchor);

        const perusteluteksti = perustelutForBackend
          ? null
          : map(perustelu => {
              if (path(["properties", "value"], perustelu)) {
                return { value: path(["properties", "value"], perustelu) };
              }
              return {
                value: path(["properties", "metadata", "fieldName"], perustelu)
              };
            }, perustelut);

        const voimassaoloChangeObjects = filter(
          compose(includes(`${anchorInit}.voimassaoloaika`), prop("anchor")),
          changeObjects.muutokset
        );

        const voimassaoloChangeObject = find(
          compose(endsWith("voimassaoloaika"), prop("anchor")),
          voimassaoloChangeObjects
        );
        const alkupvmChangeObject = find(
          compose(endsWith("alkupvm"), prop("anchor")),
          voimassaoloChangeObjects
        );
        const loppupvmChangeObject = find(
          compose(endsWith("loppupvm"), prop("anchor")),
          voimassaoloChangeObjects
        );
        const voimassaoloRemoved = path(
          ["properties", "removed"],
          voimassaoloChangeObject
        ) as boolean;

        const voimassaoloChanged =
          !!alkupvmChangeObject || !!loppupvmChangeObject || voimassaoloRemoved;

        const alkupvm: string | undefined =
          path(["properties", "value"], alkupvmChangeObject) ||
          path(["meta", "alkupvm"], maarays);
        const loppupvm: string | undefined =
          path(["properties", "value"], loppupvmChangeObject) ||
          path(["meta", "loppupvm"], maarays);

        const meta = Object.assign(
          {},
          {
            changeObjects: flatten([
              [koulutusChangeObj],
              perustelut,
              voimassaoloChangeObject,
              alkupvmChangeObject,
              loppupvmChangeObject
            ]),
            muutosperustelukoodiarvo: []
          },
          perustelutForBackend,
          perusteluteksti ? { perusteluteksti } : null,
          {
            alkupvm:
              alkupvm && !voimassaoloRemoved
                ? moment(alkupvm).format(ISO_DATE_FORMAT)
                : null,
            loppupvm:
              loppupvm && !voimassaoloRemoved
                ? moment(loppupvm).format(ISO_DATE_FORMAT)
                : null
          }
        );

        const maaraysUuid =
          path(["properties", "metadata", "maaraysUuid"], koulutusChangeObj) ||
          path(
            ["properties", "metadata", "maaraysUuid"],
            alkupvmChangeObject
          ) ||
          path(
            ["properties", "metadata", "maaraysUuid"],
            loppupvmChangeObject
          ) ||
          path(
            ["properties", "metadata", "maaraysUuid"],
            voimassaoloChangeObject
          );

        const maaraystyyppi = find(propEq("tunniste", "OIKEUS"), maaraystyypit);
        const koulutusmuutos =
          (!!koulutusChangeObj || voimassaoloChanged) && !isKoulutuspoisto
            ? {
                kohde,
                koodiarvo: koodiarvo || "",
                koodisto: koodistoUri as string,
                maaraystyyppi,
                meta,
                tila: Toiminto.LISAYS
              }
            : null;

        let koulutuspoistoMuutos: any = null;
        // Jos koulutus on jo luvalla mutta esimerkiksi voimassaolo on muuttunut, poistetaan vanha määräys luvalta ja lisätään uusi tilalle.
        if ((voimassaoloChanged || isKoulutuspoisto) && maaraysUuid) {
          koulutuspoistoMuutos = pipe(
            assocPath(["meta"], path(["maarays", "meta"], koulutus)),
            assocPath(["meta", "changeObjects"], meta?.changeObjects)
          )(koulutusmuutos);
          koulutuspoistoMuutos.maaraystyyppi = maaraystyyppi;
          koulutuspoistoMuutos.kohde = kohde;
          koulutuspoistoMuutos.koodisto = koodistoUri;
          koulutuspoistoMuutos.koodiarvo = koodiarvo;
          koulutuspoistoMuutos.tila = Toiminto.POISTO;
          koulutuspoistoMuutos.generatedId = null;
          koulutuspoistoMuutos.maaraysUuid = maaraysUuid;
        }

        return [koulutusmuutos, koulutuspoistoMuutos];
      }, koulutukset)
    )
  );
};
