import {
  ComponentWithMetadata,
  getPropertiesObject,
  Properties
} from "utils/lomakkeet";
import {
  ChangeObjects,
  getChangeObjByAnchor,
  isChangeObjectDeprecated
} from "utils/muutokset";

/**
 * Finds out if the given node is checked either by default or by
 * a change object.
 * @param {object} node - Includes an anchor and a properties object.
 * @param {array} changeObjects - Array of change objects.
 * @returns {bool} - Boolean tells if the given node is checked.
 */
export function calculateComponentProperties(
  node: ComponentWithMetadata,
  changeObjects: ChangeObjects
): Properties {
  const changeObj = getChangeObjByAnchor(node.fullAnchor, changeObjects);
  if (changeObj) {
    const isDeprecated = isChangeObjectDeprecated(changeObj);
    if (isDeprecated) {
      return node.properties;
    } else {
      return getPropertiesObject(node.properties, changeObj.properties);
    }
  } else {
    return node.properties;
  }
}

export function isNodeChecked(
  componentWithMetadata: ComponentWithMetadata,
  changeObjects: ChangeObjects
): boolean {
  const componentProperties: Properties = calculateComponentProperties(
    componentWithMetadata,
    changeObjects
  );

  return !!componentProperties.isChecked;
}

export function isNodeIsIndeterminate(
  componentWithMetadata: ComponentWithMetadata,
  changeObjects: ChangeObjects
): boolean {
  const componentProperties: Properties = calculateComponentProperties(
    componentWithMetadata,
    changeObjects
  );

  return !!componentProperties.isIndeterminate;
}
