import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import {
  drawGraph,
  handleIncomingGraphToken
} from "graphHandling/graphHandler";
import {
  GraphDataStoreContainer,
  initialState,
  useGraphDataStore
} from "stores/graphStore";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import { compose, head, isNil, keys, mapObjIndexed, omit, reject } from "ramda";
import { useParams } from "react-router-dom";

const isGraphViewOn = process.env.REACT_APP_GRAPHS === "true";

const styleClasses1 = "w-[10rem] h-[10rem] m-4";
const styleClasses2 = "w-auto h-[32rem]";

const GraphTemplate = props => {
  const intl = useIntl();
  const urlParams = useParams();
  const [isExpanded, setGraphView] = useState(true);
  const [graphDefinition, setGraphDefinition] = useState(
    omit(["cy"], props.graphDefinition)
  );
  const { cellImplementations, firstCellId, render } = props;

  function expandTheGraphView() {
    setGraphView(isExpanded ? false : true);
    setGraphDefinition(omit(["cy"], graphDefinition));
  }

  useEffect(() => {
    if (isGraphViewOn && !graphDefinition.cy) {
      const graphCanvasId = compose(
        head,
        keys,
        reject(isNil)
      )(
        mapObjIndexed(cellDefinition => {
          return cellDefinition.isFirstCell ? true : null;
        }, graphDefinition.cells)
      );

      graphDefinition.cy = drawGraph(graphCanvasId, graphDefinition);
      setGraphDefinition(graphDefinition);
    }
  }, [graphDefinition, isExpanded]);

  const [
    graph,
    {
      addModification,
      readPath,
      updateComponent,
      updateCurrentProperties,
      updateFlow,
      updateGraph
    }
  ] = useGraphDataStore();

  const storeFunctions = useMemo(() => {
    return { readPath, updateGraph };
  }, [readPath, updateGraph]);

  useEffect(() => {
    updateGraph(["custom"], urlParams);
  }, [urlParams]);

  useEffect(() => {
    (async () => {
      if (intl) {
        const gd = await handleIncomingGraphToken(
          firstCellId,
          graphDefinition,
          {
            addModification,
            readPath,
            updateComponent,
            updateCurrentProperties,
            updateFlow,
            updateGraph
          },
          intl,
          cellImplementations,
          isGraphViewOn
        );
        setGraphDefinition(gd);
      }
    })();
  }, [intl]);

  return (
    <GraphDataStoreContainer
      components={initialState.components}
      custom={initialState.custom}
      flow={initialState.flow}>
      {isGraphViewOn ? (
        <div className="relative">
          <button
            className="absolute p-2 z-60 right-0"
            onClick={expandTheGraphView}>
            <AspectRatioIcon color="secondary" />
          </button>
          <div
            id={firstCellId}
            className={`${
              !isGraphViewOn ? "hidden" : ""
            } bg-white r-0 border border-gray-300 ${
              isExpanded ? styleClasses2 : styleClasses1
            }`}></div>
        </div>
      ) : null}
      {render ? render({ graph, storeFunctions }) : null}
    </GraphDataStoreContainer>
  );
};

GraphTemplate.propTypes = {
  cellId: PropTypes.object,
  cellImplementations: PropTypes.object,
  children: PropTypes.object,
  firstCellId: PropTypes.string,
  graphDefinition: PropTypes.object,
  graphId: PropTypes.string,
  render: PropTypes.func
};

export default GraphTemplate;
