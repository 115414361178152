import { Autocomplete } from "utils/lomakkeet";
import { ComponentType } from "graphHandling/components/componentType";
import { ComponentEvent, Modifications } from "graphHandling/graphTypes";
import { AutocompletePropsWithExtra } from "../rendering";
import { assoc } from "ramda";

export function defineAutocomplete(
  properties: AutocompletePropsWithExtra,
  functions: { onChange: ComponentEvent },
  modifications?: Modifications
): Autocomplete {
  const componentDef = {
    name: ComponentType.AUTOCOMPLETE,
    onChange: functions.onChange,
    properties
  };
  if (modifications) {
    return assoc("modifications", modifications, componentDef);
  }
  return componentDef;
}
