import Lisatiedot from "services/lomakkeet/lisatiedot";
import { ChangeObject } from "utils/muutokset";
import { Categories } from "utils/lomakkeet";

/**
 * Muodostaa TUVA:n lisätietokentän esikatselunäkymän.
 * @param param0 Lomakedata.
 * @returns Categories.
 */
export function previewOfLisatiedot({
  lisatiedotNode
}: {
  lisatiedotNode: ChangeObject;
}): Categories {
  return lisatiedotNode
    ? [Lisatiedot(lisatiedotNode.properties.value, ["whitespace-pre-wrap"])]
    : [];
}
