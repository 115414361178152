import { Fragment } from "react";
import PropTypes from "prop-types";
import { localizeRouteKey } from "../../../utils/common";
import { useIntl } from "react-intl";

const SitemapLink = ({ route, text }) => {
  const intl = useIntl();
  const { formatMessage, locale } = intl;

  return (
    <Fragment>
      <ul className="list-disc leading-bulletList pt-2">
        <li className="leading-bulletList">
          <a
            className="underline"
            target={route[locale] ? "_blank" : "_self"}
            href={
              typeof route === "string"
                ? localizeRouteKey(locale, route, intl)
                : route[locale]
            }
            rel="noreferrer">
            {formatMessage(text)}
          </a>
        </li>
      </ul>
    </Fragment>
  );
};

SitemapLink.propTypes = {
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  text: PropTypes.object
};

export default SitemapLink;
