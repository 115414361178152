import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { getSavedChangeObjects } from "utils/frontendMuutoksetMuutospyynnolla";
import wizard from "i18n/definitions/wizard";
import {
  useChangeObjects,
  useChangeObjectsByAnchorWithoutUnderRemoval
} from "stores/muutokset";
import Wizard from "components/03-templates/Wizard/index";
import LupanakymaA from "./lupanakymat/LupanakymaA";
import ProcedureHandler from "components/02-organisms/procedureHandler";
import { createMuutospyyntoOutput } from "utils/muutospyyntoUtil";
import { createObjectToSave } from "./saving";
import { find, prop, propEq, path } from "ramda";
import { getKoulutusmuotoByPredicate, localizeRouteKey } from "utils/common";
import { AppRoute } from "routes/index";
import { FIELDS } from "modules/constants";
import { getUrlOnClose } from "components/03-templates/Wizard/wizardUtils";
import { PropTypes } from "prop-types";
import { useLomakedata } from "stores/lomakedata";

/**
 * Container component of UusiaAsiaDialog.
 *
 * @param {Object} props - Props object.
 */
const WizardContainer = ({
  kohteet,
  koulutusmuoto,
  maaraystyypit,
  organisaatio,
  role,
  viimeisinLupa,
  muutospyynto
}) => {
  let history = useHistory();
  const intl = useIntl();
  const { formatMessage, locale } = intl;
  const {
    id,
    koulutusmuoto: koulutusmuotoKebabCase,
    muutospyyntoUuid,
    language
  } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [muutospyynnonTila, setMuutospyynnonTila] = useState();
  const [{ isPreviewModeOn }, { initializeChanges, setPreviewMode }] =
    useChangeObjects();
  const [validationErrors, setValidationErrors] = useState([]);

  const parentKoulutusmuoto = getKoulutusmuotoByPredicate(
    propEq("kebabCase", koulutusmuotoKebabCase),
    intl
  );

  const [paatoksentiedotCo] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "paatoksentiedot"
  });

  const [opetuskieletEsivalinnatCO] =
    useChangeObjectsByAnchorWithoutUnderRemoval({
      anchor: "opetuskieletEsivalinnat"
    });

  const opetuskieletEsivalinnatAnchorBase = "opetuskieletEsivalinnat";
  const [opetuskieletLomakedata] = useLomakedata({
    anchor: opetuskieletEsivalinnatAnchorBase
  });

  const eiNoudatetaOpetuskielet = path(
    ["properties", "isChecked"],
    find(
      propEq(
        "anchor",
        `${opetuskieletEsivalinnatAnchorBase}.esivalinnat.ei-noudateta.A`
      ),
      opetuskieletLomakedata
    )
  );

  const [toimintaAlueEsivalinnatCO] =
    useChangeObjectsByAnchorWithoutUnderRemoval({
      anchor: "toimintaAlueEsivalinnat"
    });

  const toimintaAlueEsivalinnatAnchorBase = "toimintaAlueEsivalinnat";
  const [toimintaAlueLomakedata] = useLomakedata({
    anchor: toimintaAlueEsivalinnatAnchorBase
  });

  const eiNoudatetaToimintaAlue = path(
    ["properties", "isChecked"],
    find(
      propEq(
        "anchor",
        `${toimintaAlueEsivalinnatAnchorBase}.esivalinnat.ei-noudateta.A`
      ),
      toimintaAlueLomakedata
    )
  );
  // Oppilas-/opiskelijamäärät tai opiskelijavuodet na niitä koskevat rajoitukset
  const maaratVuodetEsivalinnatAnchorBase = "maaratVuodetEsivalinnat";
  const [maaratVuodetLomakedata] = useLomakedata({
    anchor: maaratVuodetEsivalinnatAnchorBase
  });
  const eiNoudatetaMaaratVuodet = path(
    ["properties", "isChecked"],
    find(
      propEq(
        "anchor",
        `${maaratVuodetEsivalinnatAnchorBase}.esivalinnat.ei-noudateta.A`
      ),
      maaratVuodetLomakedata
    )
  );

  const [maaratVuodetEsivalinnatCO] =
    useChangeObjectsByAnchorWithoutUnderRemoval({
      anchor: "maaratVuodetEsivalinnat"
    });

  const [opetuskieletCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "opetuskielet"
  });

  const [toimintaalueCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "toimintaalue"
  });

  const [maaratVuodetCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "maaratVuodet"
  });

  const [rajoitteetCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "rajoitteet"
  });

  const [lisatiedotCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "lisatiedot"
  });

  const [rajoitepoistotCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "rajoitepoistot"
  });

  useEffect(() => {
    if (muutospyynto) {
      const changeObjectsFromBackend = getSavedChangeObjects(muutospyynto);
      initializeChanges(changeObjectsFromBackend);
      setMuutospyynnonTila(prop("tila", muutospyynto));
    }
  }, [muutospyynto, initializeChanges]);

  const steps = null;
  const valtakunnallinenMaarays = find(
    propEq("koodisto", "nuts1"),
    prop("maaraykset", viimeisinLupa) || []
  );

  const title =
    muutospyynnonTila === FIELDS.TILA.VALUES.KORJAUKSESSA
      ? formatMessage(wizard.luvanKorjaustilaJarjestamisluvanMuutos)
      : formatMessage(wizard.esittelijatMuutospyyntoDialogTitle);

  const onNewDocSave = useCallback(
    uuid => {
      /**
       * User is redirected to the url of the saved document.
       */
      const url = localizeRouteKey(locale, AppRoute.Tuvahakemus, intl, {
        id,
        koulutusmuoto: koulutusmuotoKebabCase,
        language,
        page: 1,
        muutospyyntoUuid: uuid
      });
      history.push(url);
    },
    [formatMessage, history, id, koulutusmuoto.kebabCase, locale]
  );

  /**
   * Opens the preview.
   * @param {object} formData
   */
  const onPreview = useCallback(async () => {
    return setPreviewMode(!isPreviewModeOn);
  }, [isPreviewModeOn, setPreviewMode]);

  /**
   * Saves the form.
   * @param {object} formData
   * @returns {object} - Muutospyyntö
   */
  const onSave = useCallback(
    async formData => {
      setIsSaving(true);
      const procedureHandler = new ProcedureHandler(formatMessage);
      const outputs = await procedureHandler.run(
        "muutospyynto.tallennus.tallennaEsittelijanToimesta",
        [formData]
      );
      setIsSaving(false);
      if (
        outputs.muutospyynto.tallennus.tallennaEsittelijanToimesta.output.result
          .statusCode &&
        outputs.muutospyynto.tallennus.tallennaEsittelijanToimesta.output.result
          .statusCode === 400
      ) {
        setValidationErrors(
          outputs.muutospyynto.tallennus.tallennaEsittelijanToimesta.output
            .result.errors
        );
      } else {
        setValidationErrors([]);
      }
      return outputs.muutospyynto.tallennus.tallennaEsittelijanToimesta.output
        .result;
    },
    [formatMessage]
  );

  const onAction = useCallback(
    async (action, fromDialog = false, muutospyynnonTila) => {
      const formData = createMuutospyyntoOutput(
        await createObjectToSave(
          koulutusmuotoKebabCase,
          id,
          language,
          intl,
          organisaatio,
          viimeisinLupa || {},
          {
            paatoksentiedot: paatoksentiedotCo,
            opetuskielet: opetuskieletCO,
            opetuskieletEsivalinnat: opetuskieletEsivalinnatCO,
            eiNoudatetaOpetuskielet,
            toimintaalue: toimintaalueCO,
            toimintaAlueEsivalinnat: toimintaAlueEsivalinnatCO,
            eiNoudatetaToimintaAlue,
            maaratVuodet: maaratVuodetCO,
            maaratVuodetEsivalinnat: maaratVuodetEsivalinnatCO,
            eiNoudatetaMaaratVuodet,
            rajoitepoistot: rajoitepoistotCO,
            rajoitteet: rajoitteetCO,
            lisatiedot: lisatiedotCO
          },
          muutospyyntoUuid,
          kohteet,
          maaraystyypit,
          "ESITTELIJA",
          muutospyynnonTila
        )
      );

      let muutospyynto = null;

      if (action === "save") {
        muutospyynto = await onSave(formData);
      } else if (action === "preview") {
        muutospyynto = await onPreview(formData);
      }

      if (action === "save") {
        if (!!muutospyynto && prop("uuid", muutospyynto)) {
          if (!muutospyyntoUuid && !fromDialog) {
            // Jos kyseessä on ensimmäinen tallennus...
            onNewDocSave(muutospyynto.uuid);
          } else {
            /**
             * Kun muutospyyntolomakkeen tilaa muokataan tässä vaiheessa,
             * vältytään tarpeelta tehdä sivun täydellistä uudelleen latausta.
             **/
            const changeObjectsFromBackend =
              getSavedChangeObjects(muutospyynto);
            initializeChanges(changeObjectsFromBackend);
          }
        }
      }
    },
    [
      initializeChanges,
      intl,
      kohteet,
      maaraystyypit,
      maaratVuodetEsivalinnatCO,
      muutospyyntoUuid,
      onNewDocSave,
      onPreview,
      onSave,
      opetuskieletCO,
      opetuskieletEsivalinnatCO,
      organisaatio,
      paatoksentiedotCo,
      rajoitepoistotCO,
      toimintaAlueEsivalinnatCO,
      viimeisinLupa
    ]
  );

  const urlOnClose = getUrlOnClose(
    role,
    locale,
    intl,
    organisaatio,
    koulutusmuoto,
    muutospyyntoUuid
  );

  return (
    <Wizard
      page1={
        <LupanakymaA
          isPreviewModeOn={false}
          isRestrictionsModeOn={true}
          koulutustyyppi={koulutusmuoto.koulutustyyppi}
          maaraykset={viimeisinLupa ? viimeisinLupa.maaraykset : []}
          rajoitemaaraykset={viimeisinLupa ? viimeisinLupa.rajoitteet : []}
          valtakunnallinenMaarays={valtakunnallinenMaarays || {}}
          validationErrors={validationErrors}
          viimeisinLupa={viimeisinLupa}
          parentKoulutusmuoto={parentKoulutusmuoto}
        />
      }
      isSaving={isSaving}
      koulutusmuoto={koulutusmuoto}
      onAction={onAction}
      organisation={organisaatio}
      steps={steps}
      tila={muutospyynnonTila}
      title={title}
      urlOnClose={urlOnClose}
    />
  );
};

WizardContainer.propTypes = {
  kohteet: PropTypes.array,
  koulutusmuoto: PropTypes.object,
  maaraystyypit: PropTypes.array,
  organisaatio: PropTypes.object,
  role: PropTypes.string,
  viimeisinLupa: PropTypes.object,
  muutospyynto: PropTypes.object
};

export default WizardContainer;
