import { Fragment, useEffect, useState } from "react";
import {
  find,
  toUpper,
  isEmpty,
  filter,
  map,
  concat,
  includes,
  path,
  pipe,
  groupBy,
  mergeDeepWithKey,
  pathEq,
  prop,
  addIndex,
  length,
  sortBy
} from "ramda";
import { useIntl } from "react-intl";
import education from "i18n/definitions/education";
import { getRajoitteetFromMaarays } from "utils/rajoitteetUtils";
import Typography from "@mui/material/Typography";
import LisatiedotHtmlLupa from "components/03-templates/HtmlLupanakyma/Lisatiedot";
import rajoitteet from "i18n/definitions/rajoitteet";
import { PropTypes } from "prop-types";
import { PaikallisenTietovarastonAvain } from "enums";
import localForage from "localforage";
import { sortByLocale } from "helpers/html";

const defaultProps = {
  tunniste: "kunnatjoissaopetustajarjestetaan"
};

export default function OpetustaAntavatKunnat({
  maaraykset,
  tunniste = defaultProps.tunniste
}) {
  const intl = useIntl();
  const locale = toUpper(intl.locale);
  const [kunnat, setKunnat] = useState([]);
  const [maakuntaKunnat, setMaakuntaKunnat] = useState([]);

  /** Fetch kunnat and maaKuntaKunnat from storage */
  useEffect(() => {
    localForage
      .getItem(PaikallisenTietovarastonAvain.KUNNAT)
      .then(kunnat => setKunnat(kunnat))
      .catch(err => {
        console.error(err);
      });
    localForage
      .getItem(PaikallisenTietovarastonAvain.MAAKUNTAKUNNAT)
      .then(maakuntakunnat => setMaakuntaKunnat(maakuntakunnat))
      .catch(err => {
        console.error(err);
      });
  }, []);

  let kuntaMaaraykset = [];
  pipe(
    groupBy(x => x.koodiarvo),
    map(x => {
      let kuntaWithCombinedAliMaaraykset = {};
      map(kunta => {
        kuntaWithCombinedAliMaaraykset = mergeDeepWithKey(
          (k, l, r) => (k === "aliMaaraykset" ? concat(l, r) : r),
          kunta,
          kuntaWithCombinedAliMaaraykset
        );
      }, x);
      kuntaMaaraykset.push(kuntaWithCombinedAliMaaraykset);
    })
  )(
    filter(maarays => {
      return (
        pathEq(["kohde", "tunniste"], tunniste, maarays) &&
        maarays.koodisto === "kunta" &&
        !includes("200", path(["koodiarvo"], maarays) || "")
      );
    }, maaraykset)
  );

  let maakuntaMaaraykset = filter(maarays => {
    return (
      pathEq(["kohde", "tunniste"], tunniste, maarays) &&
      maarays.koodisto === "maakunta" &&
      !includes("200", path(["koodiarvo"], maarays) || "")
    );
  }, maaraykset);

  kuntaMaaraykset = sortByLocale(locale, kuntaMaaraykset);
  maakuntaMaaraykset = sortByLocale(locale, maakuntaMaaraykset);

  const lisatietomaarays = find(
    maarays =>
      pathEq(["kohde", "tunniste"], tunniste, maarays) &&
      maarays.koodisto === "lisatietoja",
    maaraykset
  );

  const opetustaJarjestetaanUlkomaillaLisatiedotMaaraykset = filter(
    maarays =>
      pathEq(["kohde", "tunniste"], tunniste, maarays) &&
      includes("200", path(["koodiarvo"], maarays) || "") &&
      maarays.meta.arvo,
    maaraykset
  );

  const maarayksetOrdered = sortBy(
    maarays => getKuntamaaraysvalue(maarays, locale),
    concat(
      kuntaMaaraykset,
      opetustaJarjestetaanUlkomaillaLisatiedotMaaraykset
    ).filter(Boolean)
  );

  return !isEmpty(kunnat) &&
    !isEmpty(maakuntaKunnat) &&
    (!isEmpty(kuntaMaaraykset) ||
      !isEmpty(opetustaJarjestetaanUlkomaillaLisatiedotMaaraykset) ||
      !isEmpty(maakuntaMaaraykset)) ? (
    <div className="mt-4">
      <Typography component="h3" variant="h3">
        {intl.formatMessage(education.opetustaAntavatKunnat)}
      </Typography>
      <ul className="list-disc mb-4 ml-8">
        {addIndex(map)((maarays, index) => {
          return (
            <Fragment key={`${maarays.koodiarvo}-${index}`}>
              <li
                className={`leading-bulletList ${
                  length(maarays.aliMaaraykset) ? "mb-2" : ""
                }`}>
                {getKuntamaaraysvalue(maarays, locale)}
                {length(maarays.aliMaaraykset)
                  ? getRajoitteetFromMaarays(
                      maarays.aliMaaraykset,
                      locale,
                      intl.formatMessage(rajoitteet.ajalla)
                    )
                  : ""}
              </li>
            </Fragment>
          );
        }, maakuntaMaaraykset)}
        {maakuntaMaaraykset.length > 0 && <br></br>}
        {addIndex(map)((maarays, index) => {
          return (
            <Fragment key={`${maarays.koodiarvo}-${index}`}>
              <li
                className={`leading-bulletList ${
                  length(maarays.aliMaaraykset) ? "mb-2" : ""
                }`}>
                {getKuntamaaraysvalue(maarays, locale)}
                {length(maarays.aliMaaraykset)
                  ? getRajoitteetFromMaarays(
                      maarays.aliMaaraykset,
                      locale,
                      intl.formatMessage(rajoitteet.ajalla)
                    )
                  : ""}
              </li>
            </Fragment>
          );
        }, maarayksetOrdered)}
      </ul>
      <LisatiedotHtmlLupa lisatietomaarays={lisatietomaarays} />
    </div>
  ) : null;
}

export const getKuntamaaraysvalue = (maarays, locale) => {
  // Ulkomaat
  return maarays.koodiarvo === "200"
    ? path(["meta", "arvo"], maarays)
    : // Muut kunnat
      prop(
        "nimi",
        find(
          maarays => prop("kieli", maarays) === toUpper(locale),
          path(["koodi", "metadata"], maarays) || []
        )
      );
};

OpetustaAntavatKunnat.propTypes = {
  maaraykset: PropTypes.array,
  tunniste: PropTypes.string
};
