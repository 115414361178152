import { mapObjIndexed, values } from "ramda";
import { Fragment, memo, ReactElement } from "react";
import { IGroupedChipCollections } from "utils/lomakkeet";
import GChipC from "graphHandling/components/GroupedChipCollection";

const GroupedChipCollections = memo(
  ({ gChipCs }: { gChipCs: IGroupedChipCollections }): ReactElement | null => {
    if (!gChipCs) {
      return null;
    }

    return (
      <Fragment>
        {values(
          mapObjIndexed((gChipC, key) => {
            return (
              <GChipC key={key} gChipC={gChipC} onClick={gChipCs.onClick} />
            );
          }, gChipCs.properties.gChipCs)
        )}
      </Fragment>
    );
  }
);

GroupedChipCollections.displayName = "Grouped chip collections";

export default GroupedChipCollections;
