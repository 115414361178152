import { AppRoute } from "./app-routes";
import common from "../i18n/definitions/common";
import education from "../i18n/definitions/education";

export const koulutusmuotoRoutes = {
  categories: [
    {
      anchor: "JarjestamisJaYllapitamisluvat",
      components: [
        {
          anchor: "Link",
          name: "SitemapLink",
          properties: {
            route: AppRoute.JarjestamisJaYllapitamisluvat,
            text: common.jarjestamisJaYllapitamisluvat
          }
        }
      ],
      categories: [
        {
          anchor: "EsiJaPerusopetus",
          components: [
            {
              anchor: "Link",
              name: "SitemapLink",
              properties: {
                route: AppRoute.EsiJaPerusopetus,
                text: education.preAndBasicEducation
              }
            }
          ]
        },
        {
          anchor: "Lukiokoulutus",
          components: [
            {
              anchor: "Link",
              name: "SitemapLink",
              properties: {
                route: AppRoute.Lukiokoulutus,
                text: education.highSchoolEducation
              }
            }
          ]
        },
        {
          anchor: "AmmatillinenKoulutus",
          components: [
            {
              anchor: "Link",
              name: "SitemapLink",
              properties: {
                route: AppRoute.AmmatillinenKoulutus,
                text: education.vocationalEducation
              }
            }
          ]
        },
        {
          anchor: "TUVA",
          components: [
            {
              anchor: "Link",
              name: "SitemapLink",
              properties: {
                route: AppRoute.Tuva,
                text: education.tuva
              }
            }
          ]
        },
        {
          anchor: "VapaaSivistystyo",
          components: [
            {
              anchor: "Link",
              name: "SitemapLink",
              properties: {
                route: AppRoute.VapaaSivistystyo,
                text: education.vstEducation
              }
            }
          ]
        },
        {
          anchor: "TaiteenPerusopetus",
          components: [
            {
              anchor: "Link",
              name: "SitemapLink",
              properties: {
                route: AppRoute.TaiteenPerusopetus,
                text: education.taiteenPerusopetus
              }
            }
          ]
        }
      ]
    }
  ]
};
