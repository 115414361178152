import Lomake from "components/02-organisms/Lomake";
import { ReactElement } from "react";
import { useChangeObjectsByAnchorWithoutUnderRemoval } from "stores/muutokset";
import PropTypes from "prop-types";
import { Maarays } from "../../../../../../../../../koodistodatanTyypit";
import { find, pathEq } from "ramda";

const constants = {
  mode: "modification",
  formLocation: ["ammatillinenKoulutus", "koulutusLisatiedot"]
};

type Props = {
  mode: string;
  sectionId: string;
  title: string;
  maaraykset: Array<Maarays>;
};

const KoulutusLisatiedot = ({
  mode,
  sectionId,
  title,
  maaraykset
}: Props): ReactElement => {
  const [changeObjects] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: sectionId
  });

  const lisatietoMaarays = find(
    pathEq(["koodisto"], "lisatietoja") &&
      pathEq(["meta", "tunniste"], sectionId),
    maaraykset || []
  );

  const params = {
    anchor: sectionId,
    changeObjects,
    data: { maarays: lisatietoMaarays },
    isRowExpanded: mode === "reasoning",
    key: sectionId,
    mode: mode,
    path: constants.formLocation,
    rowTitle: title,
    showCategoryTitles: true,
    isInExpandableRow: false
  };

  return <Lomake {...params} />;
};

KoulutusLisatiedot.propTypes = {
  mode: PropTypes.string,
  sectionId: PropTypes.string,
  title: PropTypes.string
};
export default KoulutusLisatiedot;
