import { dropLast, equals, find, includes } from "ramda";
import { ComponentWithMetadata } from "utils/lomakkeet";

/**
 * Etsii ja palauttaa komponenttimääritystä tasoa ylempänä olevan komponenttimäärityksen.
 * @param {object} node Se komponenttimääritys, jonka parent-määritystä etsitään.
 * @param {array} componentsWithMetadata Lista metadatalla höystettyjä komponenttimäärityksiä.
 * @param {array} acceptedParentNodeNames Lista komponenttien nimiä. Löydetyn parent-määrityksen
 * komponentin nimen on löydyttävä näiden joukosta.
 * @returns {object} Metadatalla höystetty komponenttimääritys.
 */
export function findParent(
  node: ComponentWithMetadata,
  componentsWithMetadata: Array<ComponentWithMetadata>,
  acceptedParentNodeNames: Array<string> = []
): ComponentWithMetadata | undefined {
  return find(parentNodeCandidate => {
    return (
      parentNodeCandidate.level === node.level - 1 &&
      parentNodeCandidate.columnIndex === 0 &&
      (acceptedParentNodeNames.length > 0
        ? includes(parentNodeCandidate.name, acceptedParentNodeNames)
        : true) &&
      equals(
        dropLast(1, parentNodeCandidate.anchorParts),
        dropLast(2, node.anchorParts)
      )
    );
  }, componentsWithMetadata);
}
