import { find, prop, propEq } from "ramda";
import {
  Kohde,
  Maarays,
  Maaraystyyppi,
  Organisation
} from "../../koodistodatanTyypit";
import { Muutos } from "../../types";
import { Toiminto } from "../../enums";

export const createOppilaitosmuutokset = (
  oppilaitos: Organisation,
  kohde: Kohde,
  maaraystyypit: Array<Maaraystyyppi>,
  oppilaitosMaarays?: Maarays
): Array<Muutos> => {
  const oppilaitosOid = prop("oid", oppilaitos);
  const oppilaitosLisaysMuutos: Muutos | null = oppilaitosOid
    ? {
        generatedId: `oppilaitosmuutos-${Math.random()}`,
        koodiarvo: "1",
        koodisto: "oppilaitos",
        meta: {},
        kohde,
        maaraystyyppi: find(
          propEq("tunniste", "OIKEUS"),
          maaraystyypit
        ) as Maaraystyyppi,
        tila: Toiminto.LISAYS,
        orgOid: oppilaitosOid
      }
    : null;
  const oppilaitosPoistoMuutos: Muutos | null = oppilaitosMaarays
    ? {
        koodiarvo: "1",
        koodisto: "oppilaitos",
        meta: {},
        kohde,
        maaraystyyppi: find(
          propEq("tunniste", "OIKEUS"),
          maaraystyypit
        ) as Maaraystyyppi,
        orgOid: prop("orgOid", oppilaitosMaarays),
        maaraysUuid: prop("uuid", oppilaitosMaarays),
        tila: Toiminto.POISTO
      }
    : null;
  return [oppilaitosLisaysMuutos, oppilaitosPoistoMuutos].filter(
    Boolean
  ) as Array<Muutos>;
};
