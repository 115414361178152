import { ReactElement } from "react";
import { ComponentType } from "graphHandling/components/componentType";
import { ComponentEvent } from "graphHandling/graphTypes";
import {
  IRadioButton,
  IRadioButtonCollection,
  Properties
} from "utils/lomakkeet";
import { FormControlLabel, Radio } from "@mui/material";
import { getCurrentProps } from "graphHandling/graphUtils";
import { getCurrentValue } from "../graphUtils";
import { equals, isNil, reject } from "ramda";

export type RadioButtonState = {
  afterChanges: IRadioButtonProps;
  initial: IRadioButtonProps;
  current: IRadioButtonProps;
};

export interface IRadioButtonProps {
  id: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  label: string;
  name: string;
  styleClasses?: Array<string>;
  value: number | string;
}

interface IRadioButtonPropsWithEvents extends IRadioButtonProps {
  onChange: ComponentEvent;
}

/**
 * Undo-komponentin määrittely.
 * @param properties
 * @param functions
 * @returns
 */
export function defineRadioButton(
  properties: IRadioButtonProps,
  modifications?: Properties,
  functions?: { onChange: ComponentEvent }
): IRadioButton {
  return reject(isNil, {
    modifications,
    name: ComponentType.RADIO_BUTTON,
    onChange: functions?.onChange,
    properties
  }) as unknown as IRadioButton;
}

/**
 * Undo-komponentin renderöintifunktio.
 * @param componentDefinition Radio Button.
 * @param rBC Radio Button Collection.
 * @returns
 */
export function renderRadioButton(
  radioButton: IRadioButton,
  rBC: IRadioButtonCollection
): ReactElement | null {
  if (radioButton.name === ComponentType.RADIO_BUTTON) {
    let isChecked = !!radioButton.properties.isChecked;
    let radioStyleClasses: Array<string> = [];
    if (rBC) {
      isChecked =
        getCurrentValue("value", rBC) === radioButton.properties.value;
      const propsOfLupa = getCurrentProps(
        rBC,
        false,
        false
      ) as unknown as IRadioButtonProps;
      const currentRBCProps = getCurrentProps(rBC);
      if (!equals(currentRBCProps.value, propsOfLupa.value)) {
        if (equals(radioButton.properties.value, currentRBCProps.value)) {
          radioStyleClasses = ["text-[purple]"];
        } else if (equals(radioButton.properties.value, propsOfLupa.value)) {
          radioStyleClasses = ["text-[purple] line-through"];
        }
      }
    }

    const properties = getCurrentProps(
      radioButton
    ) as unknown as IRadioButtonProps;

    const propertiesWithDefaultValues = Object.assign(
      {},
      {
        isChecked: false
      },
      {
        ...properties,
        isChecked,
        styleClasses: radioStyleClasses //isRadioButtonModified ? ["text-[purple]"] : []
      }
    );
    const onChange = (
      rBC ? rBC.onChange : radioButton.onChange
    ) as ComponentEvent;

    if (onChange) {
      return (
        <RadioButton {...propertiesWithDefaultValues} onChange={onChange} />
      );
    }
  }

  return null;
}

/**
 * RadioButton-komponentin toteutus.
 * @param props
 * @returns
 */
export const RadioButton = (
  props: IRadioButtonPropsWithEvents
): null | ReactElement => {
  const handleChange = () => {
    props.onChange({
      changeProps: {
        id: props.id,
        value: props.value
      }
    });
  };

  return (
    <FormControlLabel
      value="start"
      className={props.styleClasses?.join(" ")}
      control={
        <Radio
          checked={props.isChecked}
          onChange={handleChange}
          value={props.value}
          name={props.name}
          inputProps={{ "aria-label": "A" }}
        />
      }
      label={props.label}
    />
  );
};
