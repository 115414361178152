import moment from "moment";
import common from "i18n/definitions/common";
import ammatillinenKoulutus from "i18n/definitions/ammatillinenKoulutus";
import esiJaPerusopetus from "i18n/definitions/esiJaPerusopetus";
import lukiokoulutus from "i18n/definitions/lukiokoulutus";
import vapaaSivistystyo from "i18n/definitions/vapaaSivistystyo";
import education from "i18n/definitions/education";
import taiteenPerusopetus from "i18n/definitions/taiteenPerusopetus";
import { getAnchorPart } from "./anchor";
import {
  assocPath,
  chain,
  compose,
  curry,
  dissocPath,
  equals,
  filter,
  find,
  flatten,
  fromPairs,
  head,
  includes,
  init,
  is,
  isEmpty,
  isNil,
  keys,
  KeyValuePair,
  last,
  length,
  map,
  not,
  path,
  pathEq,
  prop,
  reject,
  split,
  toPairs,
  toUpper,
  values,
  without
} from "ramda";
import { IntlShape } from "react-intl";
import { ROUTE_PATH } from "../helpers/routes";
import tuva from "i18n/definitions/tuva";
import { DATE_FORMAT } from "./constants";
import { ChangeObjects } from "utils/muutokset";
/**
 * Utility functions are listed here.
 * @namespace utils
 * */

/**
 * @module Utils/common
 */

/**
 * Palauttaa funktion, joka palauttaa ankkurin osan annetun indeksin perusteella.
 */
export const curriedGetAnchorPartsByIndex = curry((objects, index) => {
  return map(obj => {
    return getAnchorPart(prop("anchor", obj), index);
  }, objects);
});

/**
 * Luo moniulotteisen taulukon sisällöstä yksiulotteisen objektin,
 * johon listaa kaikki objektista löytyvät arvot käyttäen avaimina
 * objektin eri tason avaimista koostettua polkua, jossa arvo
 * sijaitsee.
 * @param obj Läpikäytävä objekti.
 * @returns Yksiulotteinen objekti, jonka avaimina ovat koostetut
 * polut ja arvoinan polkujen päässä olevat arvot.
 */
export function getAllValuesOfAnObject(
  obj: Record<string, unknown>
): Record<string, string> {
  function go(obj_: Record<string, unknown>): Array<unknown> {
    if (is(Object, obj_)) {
      return chain(pair => {
        const [key, value] = pair as Array<unknown>;
        if (is(Object, value)) {
          return map(pair_ => {
            const [key_, value_] = pair_ as Array<unknown>;
            return [`${key}.${key_}`, value_];
          }, go(value as Record<string, unknown>));
        } else {
          return [[key, value]];
        }
      }, toPairs(obj_ as Record<string, unknown>));
    } else {
      return [];
    }
  }

  const went = go(obj) as KeyValuePair<number, string>[];
  return fromPairs(went);
}

/**
 * Etsii parametrina annettua avainta parametrina annetusta (moniulotteisesta)
 * objektista ja palauttaa kaikki avaimen kohdalle asetetut arvot.
 * @param object - Objekti, josta objekteja etsitään.
 * @param targetKey - Avain.
 * @returns Taulukollinen arvoja.
 */
export function findObjectWithKey(
  object: Record<string, unknown>,
  targetKey: string
): ChangeObjects {
  function findObject(
    object: Record<string, unknown> | unknown,
    targetKey: string
  ): Array<unknown> {
    if (typeof object === "object" && object !== null) {
      const keysOfObject = keys(object);
      if (keysOfObject.length > 0) {
        return keysOfObject.map(key => {
          if (equals(key, targetKey)) {
            return object[key];
          } else if (is(Object, object[key])) {
            return findObjectWithKey(object[key], targetKey);
          }
          return false;
        });
      }
    }
    return [];
  }
  return reject(
    isNil,
    filter(
      compose(not, isEmpty),
      flatten(findObject(object, targetKey)).filter(Boolean)
    )
  ) as ChangeObjects;
}

export function getVoimassaoloText(
  alkupvm = "",
  loppupvm = "",
  locale: string,
  fromText: string,
  untilText: string
): string {
  const start = moment(alkupvm);
  const end = moment(loppupvm);
  if (start.isValid() && end.isValid()) {
    return `${start.format(DATE_FORMAT)} - ${end.format(DATE_FORMAT)}`;
  } else {
    if (start.isValid()) {
      return toUpper(locale) === "SV"
        ? `${fromText} ${start.format(DATE_FORMAT)}`
        : `${start.format(DATE_FORMAT)} ${fromText}`;
    } else if (end.isValid()) {
      return toUpper(locale) === "SV"
        ? `${untilText} ${end.format(DATE_FORMAT)}`
        : `${end.format(DATE_FORMAT)} ${untilText}`;
    }
  }
  return "";
}

export function getVoimassaoloaika(
  intl: IntlShape,
  alkupvm = "",
  loppupvm = ""
): string {
  const fromText = intl.formatMessage(common.from);
  const untilText = intl.formatMessage(common.until);
  const locale = intl.locale;
  return getVoimassaoloText(alkupvm, loppupvm, locale, fromText, untilText);
}

export function isNowBetweenDates(alkupvm = "", loppupvm = ""): boolean {
  const start = moment(alkupvm);
  const end = moment(loppupvm);
  if (start.isValid() && end.isValid()) {
    return moment().isBetween(start, end, undefined, "[)");
  } else if (start.isValid()) {
    return moment().isSameOrAfter(start);
  } else if (end.isValid()) {
    return moment().isBefore(end);
  }
  return true;
}

export const isAsianumeroInValidFormat = (value: string): boolean =>
  /^VN\/[0-9]{1,9}\/[0-9]{4}$/.test(value);

/**
 * Vertailee kahta objekt
 * @param {object} a - Object to compare.
 * @param {object} b - Object to compare.
 * @param {array} path - Path to the property.
 */
export function sortObjectsByProperty(
  a: unknown,
  b: unknown,
  _path: Array<string> = []
): number {
  if (!_path.length) {
    return 0;
  }
  const aRaw: string | undefined = path(_path, a);
  const bRaw: string | undefined = path(_path, b);
  const aDate = moment(aRaw, DATE_FORMAT, true);
  const bDate = moment(bRaw, DATE_FORMAT, true);
  const aCompare = aDate.isValid() ? aDate : toUpper(aRaw || "");
  const bCompare = aDate.isValid() ? bDate : toUpper(bRaw || "");

  if (aCompare < bCompare) {
    return -1;
  } else if (aCompare > bCompare) {
    return 1;
  }
  return 0;
}

/**
 * Tarkistaa, onko parametrina annettu arvo objekti.
 * @param variable Tarkistettava arvo.
 * @returns Totuusarvo.
 */
export const isObject = (variable: unknown): boolean => {
  return Object.prototype.toString.call(variable) === "[object Object]";
};

/**
 * Palauttaa haaran (branch) datan.
 * @param branch Objekti, jota on määrä tarkastella.
 * @returns Objekti, taulukollinen objekteja tai tyhjä taulukko.
 */
export const getBranchContent = (
  obj: Record<string, unknown>
): Array<unknown> => {
  const result = keys(obj).map(function (key) {
    return is(Object, obj[key])
      ? getBranchContent(obj[key] as Record<string, unknown>)
      : obj[key];
  });
  return flatten(values(result));
};

/**
 * Tarkistaa onko annettu parametri validi päivämäärä.
 * @param dateStr Päivämääräehdokas.
 * @returns Totuusarvo.
 */
export function isDate(dateStr: string): boolean {
  const minYear = 2020;
  return (
    /^\d{4}-\d{2}-\d{2}$/.test(dateStr) &&
    compose(Number, head, split("-"))(dateStr) >= minYear
  );
}

/**
 * Käy moniulotteisen objektin läpi ja selvittää, onko sen
 * eri polkujen päissä vain tyhjiä objekteja tai tyhjiä taulukoita.
 * @param obj Objekti, jonka tyhjyyttä on määrä tarkastella.
 * @returns Totuusarvo.
 */
export const isWholeBranchEmpty = (obj: Record<string, unknown>): boolean => {
  return isEmpty(getBranchContent(obj));
};

/**
 * Poistaa objektista tyhjät taulukot ja objektit.
 * @param p Taulukollinen merkkijonoja.
 * @param branch Moniulotteinen objekti, joka voi olla kokonainen puurakenne.
 * @returns Objekti, josta on poistettu tyhjät taulukot ja objektit.
 */
export const removeOldLeaves = (
  branch: Record<string, unknown>,
  p: Array<string> = []
): Record<string, unknown> => {
  const leavesOnBranch = path(p, branch);
  if (Array.isArray(leavesOnBranch)) {
    return assocPath(
      p,
      without(
        filter(leaf => {
          // Muutosobjektit joilla isDeleted property on true ja jotka eivät koske määräystä voidaan poistaa.
          // Määräystä koskevia muutosobjekteja joilla isDeleted on true, ei saa poistaa koska näitä halutaan käyttää
          // lomakkeen muodostamisessa ja tallentamisessa
          return (
            pathEq(["properties", "isDeleted"], true, leaf) &&
            !path(["properties", "metadata", "isMaarays"], leaf)
          );
        }, leavesOnBranch),
        leavesOnBranch
      ),
      branch
    );
  } else {
    return branch;
  }
};

const protectedTreeProps = ["saved", "underRemoval", "unsaved"];

/**
 * Funktiossa ravistellaan moniulotteisesta objektista (tree) pois
 * tyhjät taulukot ja objektit sekä suoritetaan mahdolliset poistamista
 * edeltävät operaatiot.
 * @param p Polku, joka käydään läpi aloittaen polun perältä
 * @param branch Objekti (oksa), joka käydään läpi. Voi olla koko puu.
 */
export const recursiveTreeShake = (
  p: Array<string> = [],
  branch: Record<string, unknown> = {}
): Record<string, unknown> => {
  /**
   * Poistetaan käsiteltävänä olevasta oksasta poistettavaksi merkityt lehdet.
   * Toimenpiteen ohessa lehdestä voi löytyä merkintöjä operaatoista, jotka
   * on suoritettava ennen lehden poistamista. Esimerkkinä tällaisesta
   * operaatiosta on fokuksen siirtäminen poistettavasta lehdestä muutospuun
   * toiseen lehteen. removeOldLeaves hoitaa tällaiset operaatiot ennen kuin
   * se poistaa poistettavaksi merkityn lehden. Lopuksi se palauttaa
   * uuden version muutosten puusta.
   **/
  let updatedBranch: Record<string, unknown> = removeOldLeaves(branch, p);

  /**
   * Tyhjän oksan voi poistaa, kunhan huomioidaan se, ettei poisteta
   * puusta pääoksien kiinnityskohtia, jotka on määritelty
   * protectedTreeProps-muuttujassa.
   */
  if (!includes(last(p), protectedTreeProps)) {
    const subBranch: Record<string, unknown> | undefined = path(p, branch);
    if (subBranch) {
      if (isWholeBranchEmpty(subBranch)) {
        updatedBranch = dissocPath(p, branch);
        if (length(p)) {
          return recursiveTreeShake(init(p), updatedBranch);
        }
      }
    }
  }

  return updatedBranch;
};

/**
 * Palauttaa lokalisoidun reittiavaimen.
 * @param locale fi | sv
 * @param localizationStrId Viittaus käännöksen yksilöivään tietoon.
 * @param formatMessage Funktio, joka tekee käännöstyön (i18n).
 * @param params Käännöksen mahdollisesti tarvitsemat parametrit.
 * @returns
 */
export function localizeRouteKey(
  locale: string,
  localizationStrId: string,
  intl: IntlShape,
  params: Record<string, number | string> = {}
): string {
  return `/${locale}` + intl.formatMessage({ id: localizationStrId }, params);
}

/**
 *
 * @param predicate Vertailufunktio, esim. propEq("koulutustyyppi", "2")
 * @param intl IntlShape.
 * @returns Koulutusmuoto tai undefined.
 */
export function getKoulutusmuotoByPredicate(
  predicate: (koulutusmuoto: Record<string, string>) => boolean,
  intl: IntlShape
): Record<string, string> | undefined {
  const koulutusmuodot = values(getKoulutusmuodot(intl)) || [];
  return find(predicate, koulutusmuodot);
}

export function getKoulutusmuodot(
  intl: IntlShape
): Record<string, Record<string, string>> {
  const { formatMessage } = intl;
  return {
    esiJaPerusopetus: {
      genetiivi: formatMessage(esiJaPerusopetus.genetiivi),
      kebabCase: formatMessage(esiJaPerusopetus.kebabCase),
      key: "esi-ja-perusopetus",
      kortinOtsikko: formatMessage(education.preAndBasicEducation),
      koulutustyyppi: "1",
      kuvausteksti: formatMessage(esiJaPerusopetus.kuvausteksti),
      lyhytKuvaus: formatMessage(esiJaPerusopetus.lyhytKuvaus),
      paasivunOtsikko: formatMessage(education.preAndBasicEducation),
      pascalCase: "EsiJaPerusopetus",
      jarjestajatOtsikko: formatMessage(education.opetuksenJarjestajat),
      route: ROUTE_PATH.ESI_JA_PERUSOPETUS
    },
    lukiokoulutus: {
      genetiivi: formatMessage(lukiokoulutus.genetiivi),
      kebabCase: formatMessage(lukiokoulutus.kebabCase),
      key: "lukiokoulutus",
      kortinOtsikko: formatMessage(education.highSchoolEducation),
      koulutustyyppi: "2",
      kuvausteksti: formatMessage(lukiokoulutus.kuvausteksti),
      lyhytKuvaus: formatMessage(lukiokoulutus.lyhytKuvaus),
      paasivunOtsikko: formatMessage(education.highSchoolEducation),
      pascalCase: "Lukiokoulutus",
      jarjestajatOtsikko: formatMessage(education.koulutuksenJarjestajat),
      route: ROUTE_PATH.LUKIOKOULUTUS
    },
    ammatillinenKoulutus: {
      genetiivi: formatMessage(ammatillinenKoulutus.genetiivi),
      kebabCase: formatMessage(ammatillinenKoulutus.kebabCase),
      key: "ammatillinen-koulutus",
      kortinOtsikko: formatMessage(education.vocationalEducation),
      koulutustyyppi: "",
      kuvausteksti: formatMessage(ammatillinenKoulutus.kuvausteksti),
      lyhytKuvaus: formatMessage(ammatillinenKoulutus.lyhytKuvaus),
      paasivunOtsikko: formatMessage(education.vocationalEducation),
      pascalCase: "AmmatillinenKoulutus",
      jarjestajatOtsikko: formatMessage(education.koulutuksenJarjestajat),
      route: ROUTE_PATH.AMMATILLINEN_KOULUTUS
    },
    tuva: {
      genetiivi: formatMessage(tuva.genetiivi),
      kebabCase: formatMessage(tuva.kebabCase),
      key: "tuva",
      kortinOtsikko: formatMessage(education.tuva),
      koulutustyyppi: "5",
      kuvausteksti: formatMessage(tuva.kuvausteksti),
      lyhytKuvaus: formatMessage(tuva.lyhytKuvaus),
      paasivunOtsikko: formatMessage(education.tuva),
      pascalCase: "Tuva",
      jarjestajatOtsikko: formatMessage(education.koulutuksenJarjestajat),
      route: ROUTE_PATH.TUVA
    },
    vapaaSivistystyo: {
      genetiivi: formatMessage(vapaaSivistystyo.genetiivi),
      kebabCase: formatMessage(vapaaSivistystyo.kebabCase),
      key: "vapaa-sivistystyo",
      kortinOtsikko: formatMessage(education.vstEducation),
      koulutustyyppi: "3",
      kuvausteksti: formatMessage(vapaaSivistystyo.kuvausteksti),
      lyhytKuvaus: formatMessage(vapaaSivistystyo.lyhytKuvaus),
      paasivunOtsikko: formatMessage(common.vstTitleName),
      pascalCase: "VapaaSivistystyo",
      jarjestajatOtsikko: formatMessage(education.oppilaitostenYllapitajat),
      route: ROUTE_PATH.VAPAA_SIVISTYSTYO
    },
    taiteenPerusopetus: {
      genetiivi: formatMessage(taiteenPerusopetus.genetiivi),
      kebabCase: formatMessage(taiteenPerusopetus.kebabCase),
      key: "taiteen-perusopetus",
      kortinOtsikko: formatMessage(education.taiteenPerusopetus),
      koulutustyyppi: "4",
      kuvausteksti: formatMessage(taiteenPerusopetus.kuvausteksti),
      lyhytKuvaus: formatMessage(taiteenPerusopetus.lyhytKuvaus),
      paasivunOtsikko: formatMessage(education.taiteenPerusopetus),
      pascalCase: "TaiteenPerusopetus",
      jarjestajatOtsikko: formatMessage(education.koulutuksenJarjestajat),
      route: ROUTE_PATH.TAITEEN_PERUSOPETUS
    }
  };
}
