import { CellFn } from "graphHandling/graphTypes";
import { CellId } from "processes/CellId";

const currentCellId = CellId.INITIALIZE_AUTOCOMPLETE_1;

export const update_autocomplete1: CellFn = async (
  storeFunctions,
  intl,
  payload
) => {
  if (!intl || !payload) {
    console.warn("Parametri intl puuttuu.");
    return false;
  }

  storeFunctions.addModification(currentCellId, payload?.changeProps);

  return true;
};
