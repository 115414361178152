import { Fragment, ReactElement, useEffect, useState } from "react";
import { CellId } from "processes/CellId";
import FormTitle from "components/00-atoms/FormTitle";
import { IProcessCollection } from "graphHandling/graphProcessHandling";
import {
  Process_suomiContainer,
  Process_ulkomaatContainer,
  useProcess_toimintaalue
} from "graphs/storeHandling";
import {
  graphDefinition,
  cellImplementations
} from "graphs/toiminta-alue/rootLevel/processDefinition";
import { useIntl } from "react-intl";
import education from "i18n/definitions/education";
import OpetusSuomessa from "./OpetusSuomessa";
import { renderLabeledCheckbox } from "graphHandling/components/labeledCheckbox/rendering";
import { renderUndo2 } from "graphHandling/components/undo";
import {
  getCurrentValue,
  isGChipCsInInitialState,
  isInInitialState
} from "graphHandling/graphUtils";
import { renderTextBox } from "graphHandling/components/textBox/rendering";
import wizard from "i18n/definitions/wizard";
import OpetusUlkomailla from "./OpetusUlkomailla";
import { ChangeObjects } from "utils/muutokset";
import { concat, isEmpty, isNil, reject } from "ramda";
import Loading from "scenes/Loading";
import { trackingFunction } from "..";
import { CellFn } from "graphHandling/graphTypes";
import { ILabeledCheckbox, ITextBox, IUndo } from "utils/lomakkeet";

type ActionAreaProps = {
  code: string;
  isPreviewModeOn: boolean;
  pc: IProcessCollection;
  rajoitteet?: Record<string, { changeObjects: ChangeObjects }>;
  title: string;
  unsaved: Array<string>;
};

const fadeOutTime = 0;
const processId = "toimintaalue";

const componentsForUndo = [
  CellId.INITIALIZE_EI_MAARITELTY_KUVAUS,
  CellId.INITIALIZE_SUOMI_CHECKBOX,
  CellId.INITIALIZE_ULKOMAAT_CHECKBOX,
  CellId.INITIALIZE_LISATIEDOT
];

const ToimintaAlue = (props: ActionAreaProps): null | ReactElement => {
  const intl = useIntl();
  const [, actions] = useProcess_toimintaalue();
  const [fadeOut, setFadeOut] = useState<number>();
  const [readyList, setReadyList] = useState<Array<any>>([]);
  const [results, setResults] = useState<Record<string, ReturnType<CellFn>>>(
    {}
  );

  useEffect(() => {
    if (props.pc) {
      props.pc.addProcess(
        {
          actions,
          CellId,
          cellImplementations,
          customParams: {},
          processDefinition: graphDefinition,
          trackingFunction: cellFnResults => {
            const reactElements = trackingFunction(
              cellFnResults,
              graphDefinition
            );
            setReadyList(prevState => {
              return concat(prevState, reactElements);
            });
          }
        },
        processId
      );

      const runProcess = async () => {
        const outcome = await props.pc.handleIncomingProcessToken(
          CellId.INITIALIZE_PROCESS_TOIMINTAALUE,
          processId
        );

        setFadeOut(fadeOutTime);

        setTimeout(() => {
          setResults(outcome);
        }, fadeOutTime);
      };

      runProcess();
    }
  }, []);

  const process = props.pc?.getProcess(processId);
  const process_maakunnatJaKunnat = props.pc?.getProcess("maakunnatJaKunnat");
  const process_suomi = props.pc?.getProcess("suomi");
  const process_ulkomaat = props.pc?.getProcess("ulkomaat");
  if (process) {
    const components = process.actions.readPath(["components"]);
    const suomiCheckbox = components[
      CellId.INITIALIZE_SUOMI_CHECKBOX
    ] as ILabeledCheckbox;
    const ulkomaatCheckbox = components[
      CellId.INITIALIZE_ULKOMAAT_CHECKBOX
    ] as ILabeledCheckbox;
    const lisatiedot = components[CellId.INITIALIZE_LISATIEDOT] as ITextBox;
    const eiMaariteltyKuvaus = components[
      CellId.INITIALIZE_EI_MAARITELTY_KUVAUS
    ] as ITextBox;
    if (!suomiCheckbox || !ulkomaatCheckbox) {
      return null;
    }

    const components_maakunnatJakunnat = process_maakunnatJaKunnat
      ? process_maakunnatJaKunnat.actions.readPath(["components"])
      : {};

    const components_suomi = process_suomi
      ? process_suomi.actions.readPath(["components"])
      : {};

    const isSuomiCheckboxChecked = getCurrentValue("isChecked", suomiCheckbox);

    const isUlkomaatCheckboxChecked = getCurrentValue(
      "isChecked",
      ulkomaatCheckbox
    );

    const components_ulkomaat = process_ulkomaat
      ? process_ulkomaat.actions.readPath(["components"])
      : {};

    const multiTextBox = components_ulkomaat[CellId.INITIALIZE_MULTI_TEXTBOX];

    const isUndoVisible =
      !isInInitialState(eiMaariteltyKuvaus) ||
      !isInInitialState(suomiCheckbox) ||
      !isInInitialState(ulkomaatCheckbox) ||
      !isInInitialState(lisatiedot) ||
      (components_maakunnatJakunnat
        ? !isInInitialState(
            components_maakunnatJakunnat[CellId.INITIALIZE_AUTOCOMPLETE]
          )
        : true) ||
      (components_maakunnatJakunnat
        ? !isGChipCsInInitialState(
            components_maakunnatJakunnat[
              CellId.INITIALIZE_GROUPED_CHIP_COLLECTIONS
            ]
          )
        : true) ||
      (components_suomi
        ? !isInInitialState(
            components_suomi[CellId.INITIALIZE_RADIO_BUTTON_COLLECTION]
          )
        : true) ||
      !isInInitialState(multiTextBox);

    if (isEmpty(reject(isNil, results))) {
      return fadeOut ? (
        <Loading readyList={readyList} fadeOut={fadeOut} />
      ) : null;
    } else {
      if (props.isPreviewModeOn) {
        return (
          <Fragment>
            <FormTitle
              level={3}
              title={intl.formatMessage(education.opetustaAntavatKunnat)}
            />
            {!isSuomiCheckboxChecked && !isUlkomaatCheckboxChecked && (
              <p className="whitespace-pre-wrap">
                {getCurrentValue("value", eiMaariteltyKuvaus) as string}
              </p>
            )}

            {isSuomiCheckboxChecked && (
              <Process_suomiContainer scope="suomi">
                <OpetusSuomessa
                  isPreviewModeOn={true}
                  pc={props.pc}
                  rajoitteet={props.rajoitteet}
                />
              </Process_suomiContainer>
            )}
            {isUlkomaatCheckboxChecked && (
              <Process_ulkomaatContainer scope="ulkomaat">
                <OpetusUlkomailla
                  isPreviewModeOn={true}
                  pc={props.pc}
                  rajoitteet={props.rajoitteet}
                />
              </Process_ulkomaatContainer>
            )}

            <span className="block pt-4">
              {getCurrentValue("value", lisatiedot) as string}
            </span>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <div className="flex justify-between items-center">
              <FormTitle
                code={"2"}
                title={intl.formatMessage(education.opetustaAntavatKunnat)}
              />
              {isUndoVisible &&
                renderUndo2(
                  components[CellId.INITIALIZE_KUMOA_MUUTOKSET] as IUndo,
                  componentsForUndo
                )}
            </div>
            {!isSuomiCheckboxChecked && !isUlkomaatCheckboxChecked && (
              <Fragment>
                <div className="px-8 py-4 bg-gray-100">
                  {intl.formatMessage(wizard.areaOfActionIsUndefined)}
                </div>
                <div className="pt-2 pb-6">
                  {renderTextBox(eiMaariteltyKuvaus)}
                </div>
              </Fragment>
            )}

            {renderLabeledCheckbox(suomiCheckbox)}

            {isSuomiCheckboxChecked && (
              <div className="ml-8 py-2">
                <Process_suomiContainer scope="suomi">
                  <OpetusSuomessa isPreviewModeOn={false} pc={props.pc} />
                </Process_suomiContainer>
              </div>
            )}

            {renderLabeledCheckbox(ulkomaatCheckbox)}

            {isUlkomaatCheckboxChecked && (
              <Process_ulkomaatContainer scope="ulkomaat">
                <OpetusUlkomailla pc={props.pc} />
              </Process_ulkomaatContainer>
            )}

            <div className="pt-4">{renderTextBox(lisatiedot)}</div>
          </Fragment>
        );
      }
    }
  }
  return <div>Ei näytettävää</div>;
};

ToimintaAlue.displayName = "Toiminta-alue";

export default ToimintaAlue;
