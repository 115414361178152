import { CellFn } from "graphHandling/graphTypes";
import { koulutustyypitMap } from "utils/constants";
import { CellId } from "processes/CellId";
import { backendRoutes } from "stores/utils/backendRoutes";
import { getRaw } from "basedata";
import { head, path } from "ramda";

export const avoimenAsianNoutaminen: CellFn = async storeFunctions => {
  const koulutustyyppi = koulutustyypitMap.TUVA;
  const components = storeFunctions.readPath(["components"]);
  const valitunOrganisaationOid = path(
    [CellId.INITIALIZE_ORGANISAATIOVALIKKO, "properties", "values", 0, "value"],
    components
  );
  const valitunKielenLyhenne = path(
    [CellId.INITIALIZE_LOKALISAATIOVALIKKO, "properties", "value"],
    components
  );

  const muutospyyntoUrl = `${backendRoutes.muutospyynnot.path}?oid=${valitunOrganisaationOid}&koulutustyyppi=${koulutustyyppi}&tilat=VALMISTELUSSA&tilat=ESITTELYSSA&tilat=KORJAUKSESSA&vainOmat=false`;

  const backendRoute = valitunKielenLyhenne
    ? `${muutospyyntoUrl}&kieli=${valitunKielenLyhenne}`
    : muutospyyntoUrl;

  const muutospyynnot = await getRaw(
    "avoimetMuutospyynnot",
    backendRoute,
    [],
    0
  );
  const muutospyynto = muutospyynnot ? head(muutospyynnot) : null;
  storeFunctions.updateGraph(
    ["custom", "avoinMuutospyynto"],
    muutospyynto as any
  );

  return true;
};
