import { isAdded, isInLupa, isRemoved } from "css/label";
import {
  find,
  flatten,
  map,
  pathEq,
  propEq,
  filter,
  compose,
  prop,
  endsWith,
  pipe
} from "ramda";
import { __ } from "i18n-for-browser";
import { PaikallisenTietovarastonAvain } from "enums";
import localForage from "localforage";
import { createDynamicTextFields } from "services/lomakkeet/dynamic";
import { getLocalizedProperty } from "services/lomakkeet/utils";

export async function getErityisenKoulutustehtavanRahoituslomake(
  { maaraykset, sectionId },
  { isPreviewModeOn, isReadOnly },
  locale,
  changeObjects,
  { onAddButtonClick }
) {
  const _isReadOnly = isPreviewModeOn || isReadOnly;
  const erityisetKoulutustehtavat = await localForage.getItem(
    PaikallisenTietovarastonAvain.LUKIO_ERITYINEN_KOULUTUSTEHTAVA_UUSI
  );
  const lisatiedot = await localForage.getItem(
    PaikallisenTietovarastonAvain.LISATIEDOT
  );
  const lisatiedotObj = find(
    pathEq(["koodisto", "koodistoUri"], "lisatietoja"),
    lisatiedot || []
  );
  const lisatietomaaraykset = filter(
    propEq("koodisto", "lisatietoja"),
    maaraykset
  );
  const lisatietomaarays = find(
    compose(
      propEq("tunniste", "erityisenkoulutustehtavanrahoitus"),
      prop("kohde")
    )
  )(lisatietomaaraykset);

  let koulutustehtavanRahoitusStructure = map(erityinenKoulutustehtava => {
    const tehtavaanLiittyvaMaarays = find(
      m =>
        compose(
          propEq("tunniste", "erityisenkoulutustehtavanrahoitus"),
          prop("kohde")
        )(m) &&
        propEq("koodiarvo", erityinenKoulutustehtava.koodiarvo, m) &&
        propEq("koodisto", "lukioerityinenkoulutustehtavauusi", m),
      maaraykset
    );
    const tehtavaanLiittyvaChangeObj = find(
      pipe(
        prop("anchor"),
        endsWith(`${erityinenKoulutustehtava.koodiarvo}.valintaelementti`)
      ),
      changeObjects
    );

    return {
      anchor: erityinenKoulutustehtava.koodiarvo,
      styleClasses: ["mt-4"],
      categories: createDynamicTextFields(
        sectionId,
        [tehtavaanLiittyvaMaarays].filter(Boolean),
        changeObjects,
        erityinenKoulutustehtava.koodiarvo,
        onAddButtonClick,
        isPreviewModeOn,
        isReadOnly,
        10,
        erityisetKoulutustehtavat,
        locale
      ),
      components: [
        {
          anchor: "valintaelementti",
          name: "CheckboxWithLabel",
          properties: {
            isChecked: !!tehtavaanLiittyvaMaarays,
            isIndeterminate: false,
            isPreviewModeOn,
            isReadOnly: _isReadOnly,
            labelStyles: {
              addition: isAdded,
              custom: Object.assign(
                {},
                tehtavaanLiittyvaMaarays ? isInLupa : {}
              ),
              removal: isRemoved
            },
            title: getLocalizedProperty(
              erityinenKoulutustehtava.metadata,
              locale,
              "nimi"
            )
          }
        },
        (!!tehtavaanLiittyvaMaarays && !tehtavaanLiittyvaChangeObj) ||
        tehtavaanLiittyvaChangeObj?.properties?.isChecked
          ? {
              anchor: "lkm",
              name: "Input",
              properties: {
                fullWidth: true,
                _isReadOnly,
                label: __("education.opiskelijamaara"),
                type: "number",
                value: tehtavaanLiittyvaMaarays?.arvo || "0"
              }
            }
          : null
      ].filter(Boolean)
    };
  }, erityisetKoulutustehtavat);
  let lisatiedotStructure = flatten([
    {
      anchor: "erityisen-koulutustehtavan-rahoitus",
      components: [
        {
          anchor: "lisatiedot-info",
          name: "StatusTextRow",
          properties: {
            title: __("common.lisatiedotInfo")
          }
        }
      ],
      layout: { margins: { top: "large" } },
      styleClasses: ["mt-10", "pt-10", "border-t"]
    },
    lisatiedotObj
      ? {
          anchor: "lisatiedot",
          components: [
            {
              anchor: lisatiedotObj.koodiarvo,
              name: "TextBox",
              properties: {
                forChangeObject: {
                  koodiarvo: lisatiedotObj.koodiarvo,
                  koodisto: lisatiedotObj.koodisto,
                  versio: lisatiedotObj.versio,
                  voimassaAlkuPvm: lisatiedotObj.voimassaAlkuPvm
                },
                isPreviewModeOn,
                isReadOnly: _isReadOnly,
                title: __("common.lisatiedot"),
                value: lisatietomaarays ? lisatietomaarays.meta?.arvo : ""
              }
            }
          ]
        }
      : null
  ]);

  return flatten([
    koulutustehtavanRahoitusStructure,
    lisatiedotStructure
  ]).filter(Boolean);
}
