import KoulutusmuodonEtusivu from "components/03-templates/KoulutusmuodonEtusivu";
import JarjestamislupaJSX from "./JarjestamislupaHTML";
import Jarjestajaluettelo from "./Jarjestajaluettelo";
import WizardContainer from "./WizardContainer";
import { useIntl } from "react-intl";
import { getKoulutusmuodot } from "utils/common";

/**
 * Hakuavaimet, joiden perusteella basedata.js täydentään lokaalia
 * tietovarastoa. Näiden avaimien mukaista dataa tarvitaan laajasti kyseisen
 * koulutusmuodon sivuilla.
 **/
const hakuavaimet = [
  "ammatilliseentehtavaanvalmistavakoulutus",
  "kielet",
  "kohteet",
  "koulutusalat",
  "koulutustyypit",
  "kuljettajakoulutus",
  "kunnat",
  "lisatietoja",
  "lupaByUuid",
  "lupaByOid",
  "maakuntakunnat",
  "maaraystyypit",
  "muut",
  "oivatyovoimakoulutus",
  "opetuskielet",
  "poikkeus999901",
  "poikkeus999903",
  "tulevatLuvat",
  "tutkinnot"
];

export default function AmmatillinenKoulutus() {
  const intl = useIntl();

  const koulutusmuoto = getKoulutusmuodot(intl).ammatillinenKoulutus;

  return (
    <KoulutusmuodonEtusivu
      hakuavaimet={hakuavaimet}
      Jarjestajaluettelo={Jarjestajaluettelo}
      jarjestajatOtsikko={koulutusmuoto.jarjestajatOtsikko}
      JarjestamislupaJSX={JarjestamislupaJSX}
      koulutusmuoto={koulutusmuoto}
      kuvausteksti={koulutusmuoto.kuvausteksti}
      paasivunOtsikko={koulutusmuoto.paasivunOtsikko}
      WizardContainer={WizardContainer}></KoulutusmuodonEtusivu>
  );
}
