import { defineRadioButtonGroup } from "graphHandling/components/radioButtonGroup/definitions/unopinionated";
import { CellFn, EventPayload, Options } from "graphHandling/graphTypes";
import { CellId } from "processes/CellId";
import { backendRoutes } from "stores/utils/backendRoutes";
import { isEmpty } from "ramda";

const currentCellId = CellId.INITIALIZE_KOULUTUSMUOTOVALIKKO;

export const initialize_koulutusmuotovalikko: CellFn = (
  storeFunctions,
  intl
) => {
  if (!intl) {
    return false;
  }
  return defineRadioButtonGroup(
    {
      options: storeFunctions.readPath([
        "components",
        currentCellId,
        "properties",
        "options"
      ]) as Options
    },
    intl,
    {
      onChange: (payload: EventPayload) => {
        const koulutustyyppi = payload.changeProps.value;
        storeFunctions.updateGraph(
          ["components", currentCellId, "properties"],
          {
            value: koulutustyyppi
          }
        );

        storeFunctions.updateGraph(["custom", "validOrganisationListSource"], {
          id: 2,
          path:
            koulutustyyppi && !isEmpty(koulutustyyppi)
              ? `${backendRoutes.organisaatiot.path}?koulutustyyppi=${koulutustyyppi}`
              : backendRoutes.organisaatiot.path
        });

        return true;
      }
    }
  );
};
