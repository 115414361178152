import { createHook, createStore } from "react-sweet-state";
import {
  createGraphStoreContainer,
  graphStoreActions,
  initialState
} from "stores/graphStore";

export const Store000 = createStore({
  initialState,
  actions: graphStoreActions,
  name: "000 - Muutospyyntö"
});
export const Process000Container = createGraphStoreContainer(Store000);
export const useProcess000 = createHook(Store000);

export const Store001 = createStore({
  initialState,
  actions: graphStoreActions,
  name: "001 - Toiminta-alue"
});
export const Process_toimintaalueContainer =
  createGraphStoreContainer(Store001);
export const useProcess_toimintaalue = createHook(Store001);

export const Store002 = createStore({
  initialState,
  actions: graphStoreActions,
  name: "002 - Opetus Suomessa - Toiminta-alue"
});
export const Process_suomiContainer = createGraphStoreContainer(Store002);
export const useProcess_suomi = createHook(Store002);

export const Store002_1 = createStore({
  initialState,
  actions: graphStoreActions,
  name: "002.1 - Maakunnat - Opetus Suomessa - Toiminta-alue"
});
export const Process_maakunnatJaKunnatContainer =
  createGraphStoreContainer(Store002_1);
export const useProcess_maakunnatJaKunnat = createHook(Store002_1);

export const Store003 = createStore({
  initialState,
  actions: graphStoreActions,
  name: "003 - Opetus ulkomailla - Toiminta-alue"
});
export const Process_ulkomaatContainer = createGraphStoreContainer(Store003);
export const useProcess_ulkomaat = createHook(Store003);

export const Store004 = createStore({
  initialState,
  actions: graphStoreActions,
  name: "004 - Muu määräys - Muut"
});
export const Process_muutMuuMaaraysContainer =
  createGraphStoreContainer(Store004);
export const useProcess_muutMuuMaarays = createHook(Store004);

export const Store005 = createStore({
  initialState,
  actions: graphStoreActions,
  name: "005 - Opetuskielet"
});
export const Process_opetuskieletContainer =
  createGraphStoreContainer(Store005);
export const useProcess_opetuskielet = createHook(Store005);
