import { Fragment } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import common from "i18n/definitions/common";
import taiteenPerusopetus from "i18n/definitions/taiteenPerusopetus";
import moment from "moment";
import Typography from "@mui/material/Typography";
import LuvanTaiteenalatHtml from "./luvanTaiteenalat";
import OpetustaAntavatKunnatHtml from "./opetustaAntavatKunnat";
import OpetuskieletHtml from "./opetuskielet";
import MuutEhdotHtml from "./muutEhdot";
import { getVoimassaOlevatMaaraykset } from "helpers/muut";
import { filter, pathEq } from "ramda";
import wizard from "i18n/definitions/wizard";
import { DATE_FORMAT } from "utils/constants";

/**
 * Funktio rakentaa esi- ja perusopetuksen HTML-lupanäkymän.
 * @param {*} lupa - Lupa, jonka tietoja hyödyntäen lupanäkymä muodostetaan.
 */
const JarjestamislupaJSX = ({ lupa }) => {
  const { formatMessage } = useIntl();

  const voimassaOlevatMaaraykset = getVoimassaOlevatMaaraykset(lupa.maaraykset);

  const toimintaaluettaKoskevatMaaraykset = filter(
    pathEq(["meta", "sectionId"], "toimintaalue"),
    voimassaOlevatMaaraykset
  );

  const oppilaspaikkojenVaraamistaKoskevatMaaraykset = filter(
    pathEq(["meta", "sectionId"], "muutKunnatJoistaOppilaita"),
    voimassaOlevatMaaraykset
  );

  return (
    <Fragment>
      <Typography component="h2" variant="h2">
        {formatMessage(common.htmlLuvanOtsikko, {
          date: moment().format(DATE_FORMAT),
          koulutusmuodon: formatMessage(
            taiteenPerusopetus.genetiivi
          ).toLowerCase()
        })}
      </Typography>
      {lupa.loppupvm && (
        <p className="mb-4">
          {formatMessage(common.onVoimassa, {
            loppupvm: moment(lupa.loppupvm).format(DATE_FORMAT)
          })}
        </p>
      )}
      <p className="mb-4">{formatMessage(taiteenPerusopetus.esittelyteksti)}</p>

      <LuvanTaiteenalatHtml maaraykset={voimassaOlevatMaaraykset} />
      <OpetustaAntavatKunnatHtml
        maaraykset={toimintaaluettaKoskevatMaaraykset}
      />
      <OpetustaAntavatKunnatHtml
        maaraykset={oppilaspaikkojenVaraamistaKoskevatMaaraykset}
        title={formatMessage(wizard.muutKunnatOppilaspaikkojenVaraaminen)}
        titleTag={"h4"}
      />
      <OpetuskieletHtml maaraykset={voimassaOlevatMaaraykset} />
      <MuutEhdotHtml maaraykset={voimassaOlevatMaaraykset} />
    </Fragment>
  );
};

JarjestamislupaJSX.propTypes = {
  lupa: PropTypes.object.isRequired
};

export default JarjestamislupaJSX;
