import { IMultiTextBox, Properties } from "utils/lomakkeet";
import { ComponentType } from "graphHandling/components/componentType";
import { ComponentEvent } from "graphHandling/graphTypes";
import { IMultiTextBoxProps } from "./MultiTextBox";

export function defineMultiTextBox(
  properties: IMultiTextBoxProps,
  functions: { onChange: ComponentEvent },
  modifications?: {
    backend?: Properties;
    frontend?: Array<Properties>;
  }
): IMultiTextBox {
  return {
    modifications,
    name: ComponentType.MULTI_TEXTBOX,
    onChange: functions.onChange,
    properties
  };
}
