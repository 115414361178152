import KoulutusmuodonEtusivu from "components/03-templates/KoulutusmuodonEtusivu";
import JarjestamislupaJSX from "./JarjestamislupaHTML";
import JarjestajaluetteloFiltteroinnilla from "./JarjestajaluetteloFiltteroinnilla";
import { useIntl } from "react-intl";
import { getKoulutusmuodot } from "utils/common";
import UusiAsiaEsidialog from "./UusiAsiaEsidialog";
import WizardContainer from "./WizardContainer";
import { PaikallisenTietovarastonAvain } from "enums";
import BaseData from "../../../basedata";
import { useState } from "react";

/**
 * Hakuavaimet, joiden perusteella basedata.js täydentään lokaalia
 * tietovarastoa. Näiden avaimien mukaista dataa tarvitaan laajasti kyseisen
 * koulutusmuodon sivuilla.
 **/
const hakuavaimet = [
  "kieletOPH",
  "kohteet",
  "kunnat",
  "lisatietoja",
  "lupaByOid",
  "lupaByUuid",
  "maaraystyypit",
  "oppilaitoksetByOid",
  "vstErityisetKoulutustehtavat",
  "vstOppilaitoksenAlueellisuusJaValtakunnallisuus",
  "vstTyypit",
  "muutEhdotVST",
  "maaraystyypit",
  PaikallisenTietovarastonAvain.OPPILAITOS,
  "kunnat",
  "maakunnat",
  "maakuntakunnat"
];
const esidialoginHakuavaimet = ["organisaatiot", "vstTyypit"];
const keys = ["vstTyypit"];
export default function VapaaSivistystyo() {
  const intl = useIntl();
  const [koulutusmuoto] = useState(getKoulutusmuodot(intl).vapaaSivistystyo);
  return (
    <BaseData
      locale={intl.locale}
      keys={keys}
      render={_props => (
        <KoulutusmuodonEtusivu
          hakuavaimet={hakuavaimet}
          Jarjestajaluettelo={JarjestajaluetteloFiltteroinnilla}
          JarjestamislupaJSX={JarjestamislupaJSX}
          koulutusmuoto={koulutusmuoto}
          kuvausteksti={koulutusmuoto.kuvausteksti}
          paasivunOtsikko={koulutusmuoto.paasivunOtsikko}
          jarjestajatOtsikko={koulutusmuoto.jarjestajatOtsikko}
          UusiAsiaEsidialog={UusiAsiaEsidialog}
          esidialoginHakuavaimet={esidialoginHakuavaimet}
          WizardContainer={WizardContainer}
          vstTyypit={_props.vstTyypit}></KoulutusmuodonEtusivu>
      )}></BaseData>
  );
}
