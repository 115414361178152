// Palvelu
export const HOST_BASE_URL = window.location.origin;
export const BACKEND_BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8099"
    : HOST_BASE_URL;
export const API_BASE_URL = `${HOST_BASE_URL}/api`;
export const CAS_LOGIN_REDIRECT_URL = (CAS_LOGIN_READY: string): string =>
  `${BACKEND_BASE_URL}/api/auth/login?redirect=${CAS_LOGIN_READY}`;
export const CAS_BASE_URL = "https://virkailija.testiopintopolku.fi";
export const CAS_LOGOUT_URL = `${BACKEND_BASE_URL}/api/auth/logout`;
export const CAS_LOGIN_READY = "http://localhost/cas-ready";

// USERS & ROLES
export const ROLE_YLLAPITAJA = "OIVA_APP_ADMIN";
export const ROLE_ESITTELIJA = "OIVA_APP_ESITTELIJA";
export const ROLE_MUOKKAAJA = "OIVA_APP_MUOKKAAJA";
export const ROLE_NIMENKIRJOITTAJA = "OIVA_APP_NIMENKIRJOITTAJA";
export const ROLE_KATSELIJA = "OIVA_APP_KATSELIJA";

// SESSION
export const sessionTimeoutInMinutes = 20;

// Uusi hakemus form constants
export const FIELDS = {
  TILA: {
    NAME: "tila",
    VALUES: {
      KORJATTAVANA: "KORJATTAVANA",
      LUONNOS: "LUONNOS",
      AVOIN: "AVOIN",
      ESITTELYSSA: "ESITTELYSSA",
      VALMISTELUSSA: "VALMISTELUSSA",
      TAYDENNETTAVA: "TAYDENNETTAVA",
      PAATETTY: "PAATETTY",
      KORJAUKSESSA: "KORJAUKSESSA",
      PASSIVOITU: "PASSIVOITU"
    }
  }
};
