import { ReactElement } from "react";
import AC from "components/02-organisms/Autocomplete/ac";
import { Autocomplete } from "utils/lomakkeet";
import { ComponentType } from "graphHandling/components/componentType";
import { AutocompleteProps } from "components/02-organisms/Autocomplete/ac";
import { getCurrentValue } from "graphHandling/graphUtils";
import { Options } from "graphHandling/graphTypes";
import { filter } from "ramda";

export interface AutocompletePropsWithExtra extends AutocompleteProps {
  optionsKey?: string;
  searchStr?: string;
}

export function renderAutocomplete(
  componentDefinition: Autocomplete | undefined
): ReactElement | null {
  if (componentDefinition) {
    const properties = {
      ...componentDefinition.properties
    } as AutocompletePropsWithExtra;

    if (componentDefinition.name === ComponentType.AUTOCOMPLETE) {
      if (properties.optionsKey) {
        properties.options = JSON.parse(
          sessionStorage.getItem(properties.optionsKey as string) as string
        );
      }

      properties.options = getCurrentValue(
        "options",
        componentDefinition
      ) as Options;

      properties.values = getCurrentValue(
        "values",
        componentDefinition
      ) as Options;

      properties.options = getCurrentValue(
        "options",
        componentDefinition
      ) as Options;

      properties.values = filter(
        option => !option.isRemoved,
        getCurrentValue("values", componentDefinition) as Options
      );

      if (componentDefinition.onChange) {
        return (
          <AC
            {...properties}
            onChange={componentDefinition.onChange}
            onInputChange={componentDefinition.onInputChange}
          />
        );
      }
    }
  }

  return null;
}
