import { Children, isValidElement, cloneElement } from "react";
import { Redirect, Switch } from "react-router";
import { useIntl } from "react-intl";
import { has } from "ramda";
import { getLocalizedPath, hasMultiplePaths } from "./LocalizedSwitchUtils";
import { PropTypes } from "prop-types";

export const LocalizedSwitch = ({ children }) => {
  const { formatMessage, locale, messages } = useIntl();

  /**
   * Lokalisoidaan kaikki reitit tarkastaen samalla, ovatko kaikki
   * lapsielementit <Route /> -komponentteja, koska vain niiden
   * osalta lokalisointi on olennaista.
   */
  return (
    <Switch>
      {Children.map(children, child => {
        if (
          isValidElement(child) &&
          (messages[child.props.path] ||
            hasMultiplePaths(child.props.path, messages))
        ) {
          if (
            !has("authenticated", child.props) ||
            child.props.authenticated === true
          ) {
            // Annetaan alkuperäiselle Route-komponentille lista reittejä tai yksittäinen reitti
            const _path = hasMultiplePaths(child.props.path, messages)
              ? child.props.path
                  .filter(messageId => !!messages[messageId])
                  .map(messageId =>
                    getLocalizedPath(messages[messageId], locale, formatMessage)
                  )
              : getLocalizedPath(
                  messages[child.props.path],
                  locale,
                  formatMessage
                );
            return cloneElement(child, {
              ...child.props,
              path: _path
            });
          } else {
            return <Redirect to="/" />;
          }
        } else {
          return child;
        }
      })}
    </Switch>
  );
};

LocalizedSwitch.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};
