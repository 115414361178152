import {
  assocPath,
  find,
  forEach,
  includes,
  isEmpty,
  path,
  prepend,
  split,
  uniq
} from "ramda";
import { Muutospyynto } from "types";
import { getAnchorPart } from "utils/anchor";
import { findObjectWithKey } from "utils/common";
import { ChangeObject, ChangeObjects } from "utils/muutokset";

export const getSavedChangeObjects = (
  muutospyynto: Muutospyynto
): Record<string, ChangeObjects> | undefined => {
  if (muutospyynto) {
    let changesBySection: Record<string, ChangeObjects> = {};

    const changeObjects = findObjectWithKey(
      { ...muutospyynto },
      "changeObjects"
    );

    if (!isEmpty(changeObjects)) {
      forEach((changeObj: ChangeObject) => {
        const anchorPart = getAnchorPart(changeObj.anchor, 0);
        if (anchorPart) {
          const anchorInitialSplitted = split("_", anchorPart);
          const existingChangeObjects: ChangeObjects =
            path(anchorInitialSplitted, changesBySection) || [];
          const changeObjects = prepend(changeObj, existingChangeObjects);
          changesBySection = assocPath(
            anchorInitialSplitted,
            uniq(changeObjects),
            changesBySection
          );
        }
      }, changeObjects);
    }

    // Special case: Toiminta-alueen perustelut
    const toimintaAluePerusteluChangeObject: ChangeObject | undefined = path(
      ["perustelut", "toimintaalue", "0"],
      changesBySection
    );
    if (
      toimintaAluePerusteluChangeObject &&
      !includes("reasoning", toimintaAluePerusteluChangeObject.anchor)
    ) {
      changesBySection = assocPath(
        ["perustelut", "toimintaalue"],
        [
          {
            anchor: "perustelut_toimintaalue.reasoning.A",
            properties: toimintaAluePerusteluChangeObject.properties
          }
        ],
        changesBySection
      );
    }

    changesBySection.topthree = path(["meta", "topthree"], muutospyynto) || [];
    changesBySection.paatoksentiedot =
      path(["meta", "paatoksentiedot"], muutospyynto) || [];

    if (muutospyynto.meta.opetuskieletEsivalinnat) {
      changesBySection.opetuskieletEsivalinnat = muutospyynto.meta
        .opetuskieletEsivalinnat as ChangeObjects;
    }

    if (muutospyynto.meta.toimintaAlueEsivalinnat) {
      changesBySection.toimintaAlueEsivalinnat = muutospyynto.meta
        .toimintaAlueEsivalinnat as ChangeObjects;
    }

    if (muutospyynto.meta.maaratVuodetEsivalinnat) {
      changesBySection.maaratVuodetEsivalinnat = muutospyynto.meta
        .maaratVuodetEsivalinnat as ChangeObjects;
    }

    // Set uuid for asianumero
    const topThreeChanges = find(
      topthree => getAnchorPart(topthree.anchor, 1) === "asianumero",
      changesBySection.topthree
    );

    if (topThreeChanges) {
      topThreeChanges.properties.metadata = { uuid: muutospyynto.uuid };
    }

    if (
      changesBySection.categoryFilter &&
      changesBySection.categoryFilter.length > 0
    ) {
      changesBySection.toimintaalue = [
        Object.assign({}, changesBySection.categoryFilter[0])
      ];
    }

    delete changesBySection.categoryFilter;

    return changesBySection;
  }
};
