import {
  append,
  compose,
  endsWith,
  filter,
  find,
  isEmpty,
  map,
  pathEq,
  prop
} from "ramda";
import { getAnchorPart } from "utils/anchor";
import Lisatiedot from "../../lisatiedot";
import { createEsikatseluHTML } from "helpers/esikatselu";

export async function previewOfLuvanTaiteenalat(
  { lomakedata, rajoitteet, maaraykset },
  booleans,
  locale
) {
  let structure = [];
  if (!lomakedata || !lomakedata.length) {
    return structure;
  }
  /**
   * Muodostetaan lista-alkiot hyödyntäen ListItem-komponenttiamme.
   * Huomioidaan vain luvan taiteenalat, jotka ovat aktivoituina lomakkeella
   * (!!isChecked = true).
   */
  const paakohtienCheckboxValinnat = filter(
    compose(endsWith(".ala"), prop("anchor")),
    lomakedata
  );

  const listItems = map(luvanTaiteenala => {
    const koodiarvo = getAnchorPart(luvanTaiteenala.anchor, 1);
    const maarays = find(
      maarays =>
        maarays.koodiarvo === koodiarvo &&
        maarays.koodisto === "tpoluvantaiteenalat",
      maaraykset
    );

    // Etsitään tieto siitä, opetetaanko kyseinen taiteenala laajan
    // oppimäärän mukaan.
    const laajaOpetusStateObj = find(
      compose(endsWith(`.${koodiarvo}.laajaoppimaara.A`), prop("anchor")),
      lomakedata
    );

    const isLaajaOppimaaraChecked = pathEq(
      ["properties", "isChecked"],
      true,
      laajaOpetusStateObj
    );

    const html = createEsikatseluHTML(
      maarays,
      koodiarvo,
      rajoitteet,
      locale,
      "nimi",
      luvanTaiteenala.properties.title
    );

    if (isEmpty(html)) {
      return null;
    }

    const laajaOppimaaraHtml = isLaajaOppimaaraChecked
      ? `<ul><li>${laajaOpetusStateObj.properties.title}`
      : "";

    if (luvanTaiteenala.properties.isChecked) {
      return {
        anchor: koodiarvo,
        components: [
          {
            anchor: "tpoluvantaiteenalat",
            name: "HtmlContent",
            properties: {
              content: `${html}${laajaOppimaaraHtml}</li></ul></ul>`
            }
          }
        ]
      };
    }
  }, paakohtienCheckboxValinnat).filter(Boolean);

  if (listItems.length) {
    structure = append(
      {
        anchor: "valitut",
        components: [
          {
            anchor: "listaus",
            name: "List",
            properties: {
              isDense: true,
              items: listItems
            }
          }
        ]
      },
      structure
    );
  }

  const lisatiedotNode = find(
    node => endsWith(".lisatiedot.1", node.anchor),
    lomakedata
  );

  if (lisatiedotNode && lisatiedotNode.properties.value) {
    structure = append(Lisatiedot(lisatiedotNode.properties.value), structure);
  }

  return structure;
}
