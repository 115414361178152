import { getRaw } from "basedata";
import { CellFn } from "graphHandling/graphTypes";
import { backendRoutes } from "stores/utils/backendRoutes";
import { KOODISTOT } from "utils/constants";

export const fetch_opetustehtavat: CellFn = async () => {
  try {
    return await getRaw(
      KOODISTOT.OPETUSTEHTAVAT,
      backendRoutes.opetustehtavat.path
    );
  } catch (err) {
    console.warn(err);
    return false;
  }
};
