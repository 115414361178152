import { Fragment, useEffect, useState } from "react";
import Table from "../../02-organisms/Table/separatedTableSections";
import {
  asiatTableColumnSetup,
  generateAsiatTableHeaderStructure,
  generateAvoimetAsiatTableStructure
} from "utils/asiatUtils";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { path } from "ramda";
import { PropTypes } from "prop-types";
import PageRowIndicator from "components/01-molecules/PageRowIndicator";
import PageNavigator from "components/01-molecules/PageNavigator";

const AvoimetAsiat = ({
  koulutusmuoto,
  vstTyypit = [],
  muutospyynnot,
  pageNo,
  pageSize,
  searchBy,
  sortedBy,
  setPageNo,
  setPageSize,
  setSearchBy,
  setSortedBy
}) => {
  const history = useHistory();
  const intl = useIntl();
  const { formatMessage, locale } = intl;

  const tableColumnSetup = asiatTableColumnSetup(true, koulutusmuoto, locale);

  const [tableHeader] = useState(
    generateAsiatTableHeaderStructure(formatMessage, tableColumnSetup)
  );

  const [tableBody, setTableBody] = useState({});

  useEffect(() => {
    setTableBody(
      generateAvoimetAsiatTableStructure(
        muutospyynnot.avoimet?.data,
        intl,
        history,
        koulutusmuoto,
        vstTyypit
      )
    );
  }, [muutospyynnot.avoimet]);

  const totalCount = parseInt(
    path(["avoimet", "paging", "totalCount"], muutospyynnot) || "0",
    10
  );

  const totalPages = parseInt(
    path(["avoimet", "paging", "totalPages"], muutospyynnot) || "0",
    10
  );

  return (
    <Fragment>
      <PageRowIndicator
        totalCount={totalCount}
        pageNo={pageNo}
        pageSize={pageSize}
      />

      {tableHeader && tableBody && (
        <Table
          tableBody={tableBody}
          tableHeader={tableHeader}
          sortedBy={sortedBy}
          setSortedBy={setSortedBy}
          sortEnabled={true}
          searchBy={searchBy}
          setSearchBy={setSearchBy}
        />
      )}
      <PageNavigator
        pageNo={pageNo}
        pageSize={pageSize}
        totalPages={totalPages}
        setPageNo={setPageNo}
        setPageSize={setPageSize}
      />
    </Fragment>
  );
};

AvoimetAsiat.propTypes = {
  koulutusmuoto: PropTypes.object,
  vstTyypit: PropTypes.array,
  muutospyynnot: PropTypes.object,
  pageNo: PropTypes.number,
  pageSize: PropTypes.number,
  searchBy: PropTypes.object,
  sortedBy: PropTypes.object,
  setPageNo: PropTypes.func,
  setPageSize: PropTypes.func,
  setSortedBy: PropTypes.func,
  setSearchBy: PropTypes.func
};

export default AvoimetAsiat;
