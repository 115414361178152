import { getRaw } from "basedata";
import { CellFn } from "graphHandling/graphTypes";
import { backendRoutes } from "stores/utils/backendRoutes";

export const fetch_maaraystyypit: CellFn = async () => {
  try {
    const maaraystyypit = await getRaw(
      "maaraystyypit",
      backendRoutes.maaraystyypit.path
    );
    return maaraystyypit;
  } catch (err) {
    throw new Error(err as string);
  }
};
