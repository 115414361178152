import { Fragment } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import common from "i18n/definitions/common";
import tuva from "i18n/definitions/tuva";
import moment from "moment";
import Typography from "@mui/material/Typography";
import { getVoimassaOlevatMaaraykset } from "helpers/muut";
import TuvaKoulutusmuoto from "./koulutusmuoto";
import TuvaOpetuskieli from "./opetuskieli";
import TuvaToimintaalue from "./toimintaalue";
import TuvaOppilasOpiskelijamaaratYms from "./oppilasOpiskelijamaaratYms";
import Lisatiedot from "./lisatiedot.tsx";
import { path, propEq } from "ramda";
import LupaLinkkiHTML, {
  LupaLinkkiTyyppi
} from "../../../../components/03-templates/HtmlLupanakyma/LupaLinkki.tsx";
import { DATE_FORMAT } from "utils/constants";
import { getKoulutusmuotoByPredicate } from "utils/common";

/**
 * Funktio rakentaa TUVA:n HTML-lupanäkymän.
 * @param {*} lupa - Lupa, jonka tietoja hyödyntäen lupanäkymä muodostetaan.
 */
const JarjestamislupaJSX = ({ lupa }) => {
  const intl = useIntl();

  const voimassaOlevatMaaraykset = getVoimassaOlevatMaaraykset(lupa.maaraykset);

  const parentKebabCase = path(["meta", "koulutusmuotoKebabCase"], lupa);
  const parentKoulutusmuoto = getKoulutusmuotoByPredicate(
    propEq("kebabCase", parentKebabCase),
    intl
  );

  return (
    <Fragment>
      <Typography component="h2" variant="h2">
        {intl.formatMessage(common.htmlLuvanOtsikko, {
          date: moment().format(DATE_FORMAT),
          koulutusmuodon: intl.formatMessage(tuva.pitkaGenetiivi).toLowerCase()
        })}
      </Typography>
      {lupa.loppupvm && (
        <p className="mb-4">
          {intl.formatMessage(common.onVoimassa, {
            loppupvm: moment(lupa.loppupvm).format(DATE_FORMAT)
          })}
        </p>
      )}
      <TuvaKoulutusmuoto parentKoulutusmuoto={parentKoulutusmuoto} />

      <LupaLinkkiHTML maaraavaLupa={lupa} tyyppi={LupaLinkkiTyyppi.PAALUPA} />

      <TuvaOpetuskieli
        maaraykset={voimassaOlevatMaaraykset}
        parentKoulutusmuoto={parentKoulutusmuoto}
      />

      <TuvaToimintaalue
        maaraykset={voimassaOlevatMaaraykset}
        parentKoulutusmuoto={parentKoulutusmuoto}
      />

      <TuvaOppilasOpiskelijamaaratYms
        maaraykset={lupa.maaraykset}
        parentKoulutusmuoto={parentKoulutusmuoto}
      />

      <Lisatiedot lupa={lupa} />
    </Fragment>
  );
};

JarjestamislupaJSX.propTypes = {
  lupa: PropTypes.object.isRequired
};

export default JarjestamislupaJSX;
