import { ReactElement } from "react";
import { ComponentType } from "graphHandling/components/componentType";
import { ComponentEvent } from "graphHandling/graphTypes";
import { Properties, IUndo, GraphComponent } from "utils/lomakkeet";
import {
  has,
  includes,
  isEmpty,
  keys,
  map,
  mapObjIndexed,
  prop,
  values
} from "ramda";
import { Button, Tooltip } from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import common from "i18n/definitions/common";
import { useIntl } from "react-intl";

export interface IUndoProps {
  isHidden?: boolean;
  listOfCellIds?: Array<string>;
  title: string;
}

interface IUndoPropsWithEvents extends IUndoProps {
  onClick: ComponentEvent;
}

/**
 * Undo-komponentin määrittely.
 * @param properties
 * @param functions
 * @returns
 */
export function defineUndo(
  properties: IUndoProps,
  functions: { onClick: ComponentEvent }
): IUndo {
  return {
    name: ComponentType.UNDO,
    onClick: functions.onClick,
    properties
  };
}

/**
 * Undo-komponentin renderöintifunktio.
 * @param componentDefinition
 * @param listOfCellIds
 * @param readPath
 * @returns
 */
export function renderUndo(
  componentDefinition: IUndo,
  listOfCellIds: Array<string>,
  readPath: (pathToRead?: Array<number | string>) => unknown
): ReactElement | null {
  if (componentDefinition?.name === ComponentType.UNDO && readPath) {
    const { properties } = componentDefinition;

    const unsavedList = map(cellId => {
      const p = readPath(["components", cellId]) as Properties;
      return prop("isUnsaved", p);
    }, listOfCellIds).filter(Boolean);

    properties.isHidden = !includes(true, unsavedList);

    if (componentDefinition.onClick) {
      return (
        <Undo
          {...properties}
          listOfCellIds={listOfCellIds}
          onClick={componentDefinition.onClick}
        />
      );
    }
  }

  return null;
}

/**
 * Undo-komponentin renderöintifunktio 2.
 * @param componentDefinition
 * @param listOfCellIds
 * @param readPath
 * @returns
 */
export function renderUndo2(
  componentDefinition: IUndo,
  listOfCellIds: Array<string>
): ReactElement | null {
  if (componentDefinition?.name === ComponentType.UNDO) {
    const { properties } = componentDefinition;

    properties.isHidden = false;

    if (componentDefinition.onClick) {
      return (
        <Undo
          {...properties}
          listOfCellIds={listOfCellIds}
          onClick={componentDefinition.onClick}
        />
      );
    }
  }

  return null;
}

/**
 * Undo-komponentin renderöintifunktio 3.
 * @param componentDefinition
 * @param listOfComponents
 * @returns GraphComponent
 */
export function renderUndo3(
  componentDefinition: IUndo,
  listOfComponents: Record<string, GraphComponent>
): ReactElement | null {
  if (componentDefinition?.name === ComponentType.UNDO) {
    const { properties } = componentDefinition;

    properties.isHidden = !includes(
      true,
      values(
        mapObjIndexed(
          component =>
            has("modifications", component) &&
            !isEmpty(prop("modifications", component)),
          listOfComponents
        )
      )
    );

    if (componentDefinition.onClick) {
      return (
        <Undo
          {...properties}
          listOfCellIds={keys(listOfComponents)}
          onClick={componentDefinition.onClick}
        />
      );
    }
  }

  return null;
}

/**
 * Undo-komponentin toteutus.
 * @param props
 * @returns
 */
export const Undo = (props: IUndoPropsWithEvents): null | ReactElement => {
  const { formatMessage } = useIntl();
  return props.isHidden ? null : (
    <Tooltip title={props.title}>
      <Button
        component="span"
        color="primary"
        endIcon={<UndoIcon />}
        onClick={(e: any) => {
          e.stopPropagation();
          return props.onClick({
            changeProps: {
              listOfCellIds: props.listOfCellIds
            },
            mouseEvent: e
          });
        }}
        size="small">
        {formatMessage(common.undo)}
      </Button>
    </Tooltip>
  );
};
