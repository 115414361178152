import { Fragment, memo, ReactElement } from "react";
import { IChip, IGroupedChipCollection } from "utils/lomakkeet";
import {
  addIndex,
  assocPath,
  concat,
  equals,
  filter,
  init,
  isNil,
  last,
  length,
  map,
  reject
} from "ramda";
import { getCurrentProps, getCurrentValue } from "graphHandling/graphUtils";
import Chip, { IChipProps } from "./chip/Chip";
import { ComponentEvent } from "graphHandling/graphTypes";

const GroupedChipCollection = memo(
  ({
    bMods = true,
    fMods = true,
    gChipC,
    isReadOnly,
    onClick
  }: {
    bMods?: boolean;
    fMods?: boolean;
    gChipC: IGroupedChipCollection | unknown;
    isReadOnly?: boolean;
    onClick: ComponentEvent;
  }): ReactElement | null => {
    if (!gChipC) {
      return null;
    }
    const { chipCollection, parentChip } = (gChipC as IGroupedChipCollection)
      .properties;

    if (!chipCollection) {
      return null;
    }

    const _chips = chipCollection.properties.chips;
    const checkedChips: Array<IChip> = filter(
      chip => getCurrentValue("isChecked", chip) as boolean,
      _chips
    );

    const parentProps = getCurrentProps(
      parentChip,
      bMods,
      fMods
    ) as unknown as IChipProps;
    let hasAtleastOneCheckedChip = parentProps.isChecked;

    const isMaakuntaModified = !equals(
      parentProps,
      getCurrentProps(parentChip, !bMods, !fMods) as unknown as IChipProps
    );

    let listItems: Array<ReactElement> = reject(
      isNil,
      addIndex(map)((_chip, index) => {
        const chip = _chip as IChip;
        const properties = getCurrentProps(
          chip,
          bMods,
          fMods
        ) as unknown as IChipProps;
        if (properties.isChecked) {
          hasAtleastOneCheckedChip = true;
        }
        const isKuntaModified = !equals(
          properties,
          getCurrentProps(chip, !bMods, !fMods) as unknown as IChipProps
        );

        if (isReadOnly) {
          return properties.isChecked ? (
            <Fragment key={chip.properties.id}>
              <span
                className={`whitespace-normal ${
                  isKuntaModified ? "text-[purple]" : ""
                }`}>
                {chip.properties.label}
              </span>
              {", "}
            </Fragment>
          ) : null;
        }

        return properties.isChecked ? (
          <li
            className={`inline-block m-[2px] ${
              index === length(checkedChips) - 1 ? "" : ""
            } ${isKuntaModified ? "text-[purple]" : ""}`}
            key={chip.properties.id}>
            <Chip {...properties} onDelete={onClick} />
          </li>
        ) : null;
      }, checkedChips)
    );

    const lastListItem = last(listItems) as ReactElement;

    // Pilkun poistaminen viimeisen kunnan perästä
    // listattaessa kunnat lukutilassa.
    listItems =
      lastListItem && isReadOnly
        ? concat(init(listItems), [
            assocPath(
              ["props", "children"],
              init(lastListItem.props.children),
              lastListItem
            )
          ])
        : listItems;

    const onkokaikkiKunnatValittu = !getCurrentValue(
      "isIndeterminate",
      parentChip
    );

    return length(_chips) && hasAtleastOneCheckedChip ? (
      <ul className={`mb-4 pb-4 ${isReadOnly ? "list-disc" : ""}`}>
        {parentProps.isChecked && (
          <li className="flex justify-start relative pt-6 pb-4 h-full border">
            {isReadOnly ? (
              <Fragment>
                {hasAtleastOneCheckedChip && (
                  <span
                    className={`${isMaakuntaModified ? "text-[purple]" : ""}`}>
                    {parentProps.label}
                  </span>
                )}
              </Fragment>
            ) : (
              <div
                className={`absolute bg-white top-[-1rem] left-[1rem] ${
                  isMaakuntaModified ? "text-[purple]" : ""
                }`}>
                <Chip {...parentProps} onDelete={onClick} />
              </div>
            )}
            {isReadOnly && parentProps.isIndeterminate && (
              <div className="pl-6">
                <ul>{listItems}</ul>
              </div>
            )}
            {!isReadOnly && !onkokaikkiKunnatValittu ? (
              <Fragment>
                <ul className="ml-8">{listItems}</ul>
              </Fragment>
            ) : (
              <Fragment>
                <p className="pl-12 m-2">Kaikki kunnat</p>
              </Fragment>
            )}
          </li>
        )}
      </ul>
    ) : null;
  }
);

GroupedChipCollection.displayName = "Grouped chip collection";

export default GroupedChipCollection;
