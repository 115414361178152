import { CellFn } from "graphHandling/graphTypes";
import common from "i18n/definitions/common";
import { forEach } from "ramda";
import { defineUndo } from "graphHandling/components/undo";

const initialize_kumoaMuutokset: CellFn = (storeFunctions, intl) => {
  if (!intl) {
    return false;
  }

  return defineUndo(
    {
      title: intl.formatMessage(common.undo)
    },
    {
      onClick: payload => {
        const listOfCellIds = payload.changeProps
          .listOfCellIds as Array<string>;

        forEach((cellId: string) => {
          storeFunctions.updateComponent(cellId, {
            modifications: undefined
          });
        }, listOfCellIds);

        return payload;
      }
    }
  );
};

export default initialize_kumoaMuutokset;
