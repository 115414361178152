import { Fragment } from "react";
import common from "i18n/definitions/common";
import { useIntl } from "react-intl";
import { join, replace, split } from "ramda";
import { PropTypes } from "prop-types";

const JarjestajaBasicInfo = ({ jarjestaja }) => {
  const intl = useIntl();
  const ytunnusTitle = `${intl.formatMessage(common.ytunnus)}: `;
  const ytunnusVoiceOverSpelling = replace(
    "-",
    intl.formatMessage(common.viiva),
    join(" ", split("", jarjestaja.ytunnus || ""))
  );
  const ariaLabel = `${ytunnusTitle}: ${ytunnusVoiceOverSpelling}`;

  return (
    <Fragment>
      {jarjestaja.ytunnus && (
        <p className="text-lg font-normal" aria-label={ariaLabel} role="text">
          {ytunnusTitle} {jarjestaja.ytunnus}
        </p>
      )}
    </Fragment>
  );
};

JarjestajaBasicInfo.propTypes = {
  jarjestaja: PropTypes.object
};

export default JarjestajaBasicInfo;
