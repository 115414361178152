import { useIntl } from "react-intl";
import { getKoulutusmuotoByPredicate, localizeRouteKey } from "utils/common";
import Jarjestajaluettelo from "./Jarjestajaluettelo";
import WizardContainer from "./WizardContainer";
import {
  createProcessCollection,
  IProcessCollection
} from "graphHandling/graphProcessHandling";
import { Fragment, ReactElement, useEffect, useState } from "react";
import { CellId } from "processes/CellId";
import {
  cellImplementations,
  processDefinition
} from "graphs/esiJaPerusopetus/processDefinition";
import Loading from "scenes/Loading";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { concat, map, propEq } from "ramda";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { NavLink, Redirect, Route, Router, useHistory } from "react-router-dom";
import { AppRoute, getRouteUrl } from "routes";
import { LocalizedSwitch } from "modules/i18n";
import { Typography } from "@mui/material";
import common from "i18n/definitions/common";
import { Helmet } from "react-helmet";
import { MuutoksetContainer } from "stores/muutokset";
import { Process000Container } from "graphs/storeHandling";
import Asiat from "components/03-templates/Asiat";
import { ROLE_ESITTELIJA } from "modules/constants";
import education from "i18n/definitions/education";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import esiJaPerusopetus from "i18n/definitions/esiJaPerusopetus";
import lukiokoulutus from "i18n/definitions/lukiokoulutus";
import ammatillinenKoulutus from "i18n/definitions/ammatillinenKoulutus";
import JarjestamislupaJSX from "./JarjestamislupaHTML";
import Jarjestaja from "components/03-templates/Jarjestaja";
import { CellFn, GraphDefinition } from "graphHandling/graphTypes";
import { Koulutusmuoto } from "types";

const fadeOutTime = 0;

export default function EsiJaPerusopetus(): null | ReactElement {
  const intl = useIntl();
  const [readyList, setReadyList] = useState<Array<string>>([]);
  const [results, setResults] = useState<Record<string, unknown> | undefined>();
  const [fadeOut, setFadeOut] = useState<number | undefined>();
  const [dataFetchTrigger, setDataFetchTrigger] = useState<boolean>(true);
  const [pageNo, setPageNo] = useState(1);
  const [searchBy, setSearchBy] = useState({});
  const history = useHistory();
  const { formatMessage, locale } = intl;
  const user = {
    oid: sessionStorage.getItem("oid")
  };
  const [processCollection, setProcessCollection] = useState<
    IProcessCollection | undefined
  >();

  const koulutusmuotoOrUndefined = getKoulutusmuotoByPredicate(
    propEq("koulutustyyppi", "1"),
    intl
  );

  if (!koulutusmuotoOrUndefined) {
    return null;
  }

  const koulutusmuoto = koulutusmuotoOrUndefined as Koulutusmuoto;

  const routeUrl = getRouteUrl(koulutusmuoto.route);

  if (!routeUrl) {
    return null;
  }

  const koulutusmuotoUrl = localizeRouteKey(locale, routeUrl, intl);

  useEffect(() => {
    setPageNo(1);
  }, [searchBy]);

  useEffect(() => {
    const pc = createProcessCollection("EsiJaPerusopetus", intl);

    pc.addProcess(
      {
        actions: {},
        CellId,
        cellImplementations,
        customParams: {
          koulutustyyppi: koulutusmuoto.koulutustyyppi,
          timeout: 0
        },
        processDefinition,
        trackingFunction: (
          cellFnResults: Record<string, ReturnType<CellFn>>,
          isErroneous?: boolean
        ): void => {
          const reactElement = trackingFunction(
            cellFnResults,
            processDefinition,
            isErroneous
          );
          setReadyList(
            prevState => concat(prevState, reactElement) as Array<string>
          );
        }
      },
      "NoudaLuvat"
    );

    const runProcess = async () => {
      const outcome = await pc.handleIncomingProcessToken(
        CellId.INITIALIZE_PROCESS_ESI_JA_PERUSOPETUS,
        "NoudaLuvat"
      );

      setFadeOut(fadeOutTime);

      setTimeout(() => {
        setResults(outcome);
      }, fadeOutTime);
    };

    setProcessCollection(pc);

    runProcess();
  }, [dataFetchTrigger]);

  if (!results || !processCollection) {
    return <Loading readyList={readyList} fadeOut={fadeOut} />;
  } else if (Array.isArray(results[CellId.FETCH_LUVAT])) {
    return (
      <div className="flex-1 flex flex-col bg-white">
        <div className="flex-1 flex flex-col">
          <BreadcrumbsItem to={koulutusmuotoUrl}>
            {koulutusmuoto.paasivunOtsikko}
          </BreadcrumbsItem>
          <Router history={history}>
            <LocalizedSwitch>
              {!!user.oid && (
                <Route
                  path={AppRoute.Asianhallinta}
                  render={() => (
                    <Fragment>
                      <Router history={history}>
                        <LocalizedSwitch>
                          <Route exact path={[AppRoute.UusiHakemus]}>
                            <MuutoksetContainer>
                              <Process000Container>
                                <WizardContainer
                                  koulutusmuoto={koulutusmuoto}
                                  processCollection={processCollection}
                                />
                              </Process000Container>
                            </MuutoksetContainer>
                          </Route>
                          <Route exact path={AppRoute.Hakemus}>
                            <MuutoksetContainer>
                              <WizardContainer
                                koulutusmuoto={koulutusmuoto}
                                processCollection={processCollection}
                              />
                            </MuutoksetContainer>
                          </Route>

                          <Route path={AppRoute.Asianhallinta}>
                            <Asiat
                              koulutusmuoto={koulutusmuoto}
                              user={user}
                              dataFetchTrigger={dataFetchTrigger}
                              setDataFetchTrigger={setDataFetchTrigger}
                            />
                          </Route>
                          <Route path="*">
                            {sessionStorage.getItem("role") ===
                            ROLE_ESITTELIJA ? (
                              <div className="flex-1 bg-gray-100">
                                <div className="border border-gray-300 max-w-7xl m-auto bg-white mt-12 px-64 py-12">
                                  <Typography component="h1" variant="h1">
                                    {formatMessage(common.asianhallinta)}
                                  </Typography>
                                  <p className="text-lg">
                                    {formatMessage(
                                      common.asianhallintaInfoText
                                    )}
                                  </p>
                                  <div className="grid grid-cols-3 gap-4 justify-items-auto pt-12">
                                    <NavLink
                                      className="font-semibold px-4 py-8 bg-white border border-gray-300 flex justify-center items-center"
                                      to={localizeRouteKey(
                                        locale,
                                        AppRoute.AsianhallintaAvoimet,
                                        intl,
                                        {
                                          koulutusmuoto: formatMessage(
                                            esiJaPerusopetus.kebabCase
                                          )
                                        }
                                      )}
                                      exact={true}
                                      style={{
                                        textDecoration: "none",
                                        color: "inherit"
                                      }}>
                                      {formatMessage(
                                        education.preAndBasicEducation
                                      )}
                                      <ArrowForwardIcon className="ml-4" />
                                    </NavLink>
                                    <NavLink
                                      className="font-semibold px-4 py-8 bg-white border border-gray-300 flex justify-center items-center"
                                      to={localizeRouteKey(
                                        locale,
                                        AppRoute.AsianhallintaAvoimet,
                                        intl,
                                        {
                                          koulutusmuoto: formatMessage(
                                            lukiokoulutus.kebabCase
                                          )
                                        }
                                      )}
                                      exact={true}
                                      style={{
                                        textDecoration: "none",
                                        color: "inherit"
                                      }}>
                                      {formatMessage(
                                        education.highSchoolEducation
                                      )}
                                      <ArrowForwardIcon className="ml-4" />
                                    </NavLink>
                                    <NavLink
                                      className="font-semibold px-4 py-8 bg-white border border-gray-300 flex justify-center items-center"
                                      to={localizeRouteKey(
                                        locale,
                                        AppRoute.AsianhallintaAvoimet,
                                        intl,
                                        {
                                          koulutusmuoto: formatMessage(
                                            ammatillinenKoulutus.kebabCase
                                          )
                                        }
                                      )}
                                      exact={true}
                                      style={{
                                        textDecoration: "none",
                                        color: "inherit"
                                      }}>
                                      {formatMessage(
                                        education.vocationalEducation
                                      )}
                                      <ArrowForwardIcon className="ml-4" />
                                    </NavLink>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </Route>
                        </LocalizedSwitch>
                      </Router>
                    </Fragment>
                  )}
                />
              )}
              <Route exact path={AppRoute.UusiHakemus}>
                <WizardContainer koulutusmuoto={koulutusmuoto} role="KJ" />
              </Route>
              <Route path={AppRoute.Koulutustoimijat}>
                <Jarjestaja
                  JarjestamislupaJSX={JarjestamislupaJSX}
                  koulutusmuoto={koulutusmuoto}
                  pc={processCollection}
                  user={user}
                />
              </Route>
              <Route exact={true} path={AppRoute.EsiJaPerusopetus}>
                <article className="mx-auto w-4/5 mt-12 max-w-8xl">
                  <Typography component="h1" variant="h1">
                    {koulutusmuoto.paasivunOtsikko}
                  </Typography>
                  <p className="max-w-213 mb-6">{koulutusmuoto.kuvausteksti}</p>
                  {user.oid ? (
                    <p className="mb-6">
                      <NavLink
                        to={localizeRouteKey(
                          locale,
                          AppRoute.AsianhallintaAvoimet,
                          intl,
                          {
                            koulutusmuoto: koulutusmuoto.kebabCase
                          }
                        )}
                        className="block underline">
                        {formatMessage(common.asianhallinta)}
                      </NavLink>
                    </p>
                  ) : null}
                  <Typography component="h2" variant="h2">
                    {koulutusmuoto.jarjestajatOtsikko}
                  </Typography>
                  <section>
                    {Jarjestajaluettelo ? (
                      <Fragment>
                        <Helmet htmlAttributes={{ lang: locale }}>
                          <title>{koulutusmuoto.paasivunOtsikko} - Oiva</title>
                        </Helmet>

                        <BreadcrumbsItem to={koulutusmuotoUrl}>
                          {koulutusmuoto.paasivunOtsikko}
                        </BreadcrumbsItem>

                        <Jarjestajaluettelo
                          koulutusmuoto={koulutusmuoto}
                          luvat={results[CellId.FETCH_LUVAT]}
                          pageNo={pageNo}
                          setPageNo={setPageNo}
                          searchBy={searchBy}
                          setSearchBy={setSearchBy}
                        />
                      </Fragment>
                    ) : null}
                  </section>
                </article>
              </Route>
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </LocalizedSwitch>
          </Router>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export const trackingFunction = (
  cellFnResults: Record<string, ReturnType<CellFn>>,
  processDefinition: GraphDefinition,
  isErroneous?: boolean
): Array<ReactElement> => {
  const reactElements = map(cellId => {
    const icon = isErroneous ? (
      <ErrorOutlineIcon style={{ color: "red" }} />
    ) : (
      <CheckIcon style={{ color: "green" }} />
    );
    return (
      <li key={`${cellId}-${Math.random()}`}>
        {icon} {processDefinition.cells[cellId].name}
        {isErroneous ? `: Prosessin osan suorittaminen epäonnistui` : ""}
      </li>
    );
  }, Object.keys(cellFnResults));
  return reactElements;
};
