import { Alert, Properties } from "utils/lomakkeet";
import { ComponentType } from "graphHandling/components/componentType";

export function defineAlert(properties: Properties): Alert {
  return {
    initialProperties: properties,
    name: ComponentType.ALERT,
    properties
  };
}
