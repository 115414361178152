import { useMemo } from "react";
import PropTypes from "prop-types";
import { useLomakedata } from "stores/lomakedata";
import { find, path, pathEq, prop, propEq } from "ramda";
import Lomake from "components/02-organisms/Lomake";
import { useChangeObjectsByAnchorWithoutUnderRemoval } from "stores/muutokset";

const constants = {
  formLocation: ["ammatillinenKoulutus", "muut", "vaativaTuki"]
};

const VaativaTuki = ({
  isReadOnly,
  items,
  localeUpper,
  maarayksetByKoodiarvo,
  mode,
  sectionId
}) => {
  const [opiskelijavuosidata] = useLomakedata({
    anchor: "opiskelijavuodet"
  });

  const [muutData] = useLomakedata({
    anchor: "muut"
  });

  const [changeObjects] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: sectionId
  });

  const vaativaTukiStateObj = find(
    propEq("anchor", "opiskelijavuodet.vaativatuki.A"),
    opiskelijavuosidata
  );

  const dataLomakepalvelulle = useMemo(
    () => ({
      isApplyForValueSet: !!path(
        ["properties", "applyForValue"],
        vaativaTukiStateObj
      ),
      items,
      maarayksetByKoodiarvo,
      koodiarvot: ["2", "16", "17", "18", "19", "20", "21"],
      valittuRadioButton: prop("02", muutData)
        ? find(pathEq(["properties", "isChecked"], true), prop("02", muutData))
        : null
    }),
    [items, muutData, vaativaTukiStateObj, maarayksetByKoodiarvo]
  );

  return (
    <Lomake
      anchor={sectionId}
      changeObjects={changeObjects}
      data={dataLomakepalvelulle}
      isReadOnly={isReadOnly}
      mode={mode}
      path={constants.formLocation}
      rowTitle={(items.title && items.title.metadata[localeUpper].nimi) || ""}
      showCategoryTitles={true}
    />
  );
};

VaativaTuki.propTypes = {
  isReadOnly: PropTypes.bool,
  items: PropTypes.object,
  localeUpper: PropTypes.string,
  maarayksetByKoodiarvo: PropTypes.object,
  mode: PropTypes.string,
  opiskelijavuodetData: PropTypes.object,
  sectionId: PropTypes.string,
  title: PropTypes.string
};

export default VaativaTuki;
