import { filter } from "ramda";
import { isNodeIsIndeterminate } from "./nodePropertiesCalculation";
import { ComponentWithMetadata } from "utils/lomakkeet";
import { ChangeObjects } from "utils/muutokset";

/**
 * Palauttaa arvon true, jos jokainen komponentin lapsikomponenteista on ruksattu.
 * @param componentWithMetadata Metadatalla höystetty komponentin kuvaus.
 * @param reducedStructure Taulukollinen metadatalla höystettyjä komponenttimäärityksiä.
 * @param changeObjects Taulukollinen muutosobjekteja.
 */
export function findIsIndeterminateNodes(
  componentsWithMetadata: Array<ComponentWithMetadata>,
  changeObjects: ChangeObjects
): Array<ComponentWithMetadata> {
  const nodesInIsIndeterminateState = filter(componentWithMetadata => {
    return isNodeIsIndeterminate(componentWithMetadata, changeObjects);
  }, componentsWithMetadata);

  return nodesInIsIndeterminateState;
}
