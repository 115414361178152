import { defineSimpleButton } from "graphHandling/components/simpleButton/definitions/customizable";
import { CellFn } from "graphHandling/graphTypes";
import { getKoulutusmuotoByPredicate, localizeRouteKey } from "utils/common";
import { CellId } from "processes/CellId";
import { propEq } from "ramda";
import { AppRoute } from "routes/app-routes";
import common from "i18n/definitions/common";
import localforage from "localforage";
import { koulutustyypitMap } from "utils/constants";
import { Variant } from "components/00-atoms/SimpleButton/SimpleButton";

export const peruutaPainike: CellFn = (storeFunctions, intl) => {
  if (!intl) {
    console.warn("Parametri intl puuttuu.");
    return false;
  }
  const seuraavaPainikeDefinition = defineSimpleButton(
    {
      ariaLabel: "Peruuta-painike",
      id: CellId.INITIALIZE_PERUUTA_PAINIKE,
      text: intl.formatMessage(common.cancel),
      variant: Variant.OUTLINED
    },
    async () => {
      const koulutusmuoto = getKoulutusmuotoByPredicate(
        propEq("koulutustyyppi", koulutustyypitMap.TUVA),
        intl
      );
      if (koulutusmuoto) {
        const nextRoute =
          (await localforage.getItem("luoUusiAsiaPainikkeenSijainti")) ||
          localizeRouteKey(intl.locale, AppRoute.AsianhallintaAvoimet, intl, {
            koulutusmuoto: koulutusmuoto.kebabCase
          });
        storeFunctions.updateGraph(["custom", "goToRoute"], {
          value: nextRoute
        });
      }
      return true;
    }
  );

  storeFunctions.updateGraph(
    ["components", CellId.INITIALIZE_PERUUTA_PAINIKE],
    seuraavaPainikeDefinition
  );

  return true;
};
