import { CellFn } from "graphHandling/graphTypes";
import { CellId } from "processes/CellId";

const currentCellId = CellId.INITIALIZE_MULTI_TEXTBOX;

export const update_multiTextBoxVisibility: CellFn = storeFunctions => {
  storeFunctions.addModification(currentCellId, {
    isVisible: true
  });

  return true;
};
