import {
  append,
  equals,
  filter,
  flatten,
  forEach,
  init,
  isNil,
  map,
  nth,
  pathEq,
  reject,
  remove,
  startsWith,
  uniq
} from "ramda";
import {
  ChangeObjects,
  getChangeObjByAnchor,
  getChangeObjIndexByAnchor
} from "utils/muutokset";
import { getChildNodes } from "./getChildNodes";
import { uncheckRadioButtonsOnSameLevel } from "./uncheckRadioButtonsOnSameLevel";
import { updateChangeObjectsArray } from "./updateChangeObjectsArray";
import { ComponentWithMetadata } from "utils/lomakkeet";
import { getAnchorInit } from "utils/anchor";

/**
 * Aktivoi parametrina annetun komponentin sekä sen jälkeläiset.
 * @param componentWithMetadata Metadatalla höystetty komponentti.
 * @param componentsWithMetadata Taulukollinen metadatalla höystettyjä komponentteja.
 * @param changeObjects Taulukollinen muutosobjekteja.
 * @returns Taulukollinen muutosobjekteja.
 */
export function activateNodeAndItsDescendants(
  componentWithMetadata: ComponentWithMetadata,
  componentsWithMetadata: Array<ComponentWithMetadata>,
  changeObjects: ChangeObjects = [],
  originalComponentWithMetadata: ComponentWithMetadata = componentWithMetadata
): ChangeObjects {
  const childNodes = componentWithMetadata.hasDescendants
    ? getChildNodes(componentWithMetadata, componentsWithMetadata, [
        "CheckboxWithLabel"
      ])
    : [];

  // Mikäli komponentin tila on kytköksissä tasoa alempana oleviin
  // lapsikomponentteihin, on lapsikomponentit myös käytävä läpi.
  if (childNodes.length) {
    changeObjects = uniq(
      flatten(
        map(childNode => {
          const result = activateNodeAndItsDescendants(
            childNode,
            componentsWithMetadata,
            changeObjects,
            originalComponentWithMetadata
          );
          return result;
        }, childNodes)
      )
    );
  }

  // The first thing is to find out the change object of the target componentWithMetadata.
  const changeObj = getChangeObjByAnchor(
    componentWithMetadata.fullAnchor,
    changeObjects
  );

  if (changeObj) {
    if (
      componentWithMetadata.properties.isChecked === true &&
      !componentWithMetadata.properties.isIndeterminate
    ) {
      /**
       * If original isChecked value of the componentWithMetadata is true we just have to
       * remove the change object.
       **/
      changeObjects = updateChangeObjectsArray(
        componentWithMetadata,
        { isDeprecated: true },
        changeObjects
      );
    } else {
      /**
       * If the original value is not true and change object's isChecked
       * value is not true then the isChecked's value on change object must
       * be updated to be true.
       */
      changeObjects = updateChangeObjectsArray(
        componentWithMetadata,
        { isChecked: true, isIndeterminate: false },
        changeObjects
      );
    }
  } else if (!changeObj) {
    /**
     * If there's no change object and componentWithMetadata's own isChecked property is false
     * we need to create a new change object with isChecked equals true.
     * The metadata given on the form will also be included to the change
     * object.
     */
    if (!componentWithMetadata.properties.isChecked) {
      changeObjects = append(
        {
          anchor: componentWithMetadata.fullAnchor,
          properties: reject(isNil)({
            metadata: componentWithMetadata.properties.forChangeObject,
            isChecked: true,
            isIndeterminate: componentWithMetadata.properties.isIndeterminate
              ? false
              : undefined
          })
        },
        changeObjects
      );
    } else if (componentWithMetadata.properties.isIndeterminate === true) {
      // Kaikkien lapsikomponenttien ollessa ruksatut, tulee myös niiden
      // parent-komponentin olla ruksattu.
      changeObjects = append(
        {
          anchor: componentWithMetadata.fullAnchor,
          properties: reject(isNil)({
            metadata: componentWithMetadata.properties.forChangeObject,
            isChecked: true
          })
        },
        changeObjects
      );
    }
  }

  // If the target componentWithMetadata is a radio button its siblings must be unchecked.
  if (componentWithMetadata.name === "RadioButtonWithLabel") {
    changeObjects = uncheckRadioButtonsOnSameLevel(
      componentWithMetadata,
      componentsWithMetadata,
      changeObjects
    );

    // Päivitetään muutosobjektit myös radio buttonin kanssa samassa components-taulukossa
    // olevien komponenttien osalta. Lopputuloksen tulee olla se, että samassa komponentti-
    // taulukossa olevista radio button -komponenteista vain juuri valittu radio button on
    // valittuna.
    forEach((c: ComponentWithMetadata) => {
      if (
        equals(init(c.anchorParts), init(componentWithMetadata.anchorParts)) &&
        c.fullAnchor !== componentWithMetadata.fullAnchor
      ) {
        const changeObjIndex = getChangeObjIndexByAnchor(
          c.fullAnchor,
          changeObjects
        );
        const changeObj =
          changeObjIndex > -1 ? nth(changeObjIndex, changeObjects) : null;
        // Oletuksena valittu.
        if (c.properties.isChecked) {
          // Ei muutosobjektia.
          if (!changeObj) {
            changeObjects.push({
              anchor: c.fullAnchor,
              properties: {
                isChecked: false
              }
            });
          }
        } // Oletuksena ei-valittu.
        else {
          // Muutosobjekti.
          if (changeObj) {
            console.info(changeObjIndex, changeObj);
            changeObjects = remove(changeObjIndex, 1, changeObjects);
          }
        }
      }
    }, componentsWithMetadata);
  }

  if (equals(componentWithMetadata, originalComponentWithMetadata)) {
    // Kun lomakemerkkaus on käyty läpi, filtteröidään kertyneistä muutosobjekteista pois
    // ne, jotka eivät ole ajantasalla.
    const anchorInit = getAnchorInit(originalComponentWithMetadata.fullAnchor);
    const result = filter(changeObj => {
      const startsWithAnchorInit = startsWith(anchorInit, changeObj.anchor);
      return (
        !startsWithAnchorInit ||
        (startsWithAnchorInit &&
          (pathEq(["properties", "isChecked"], true, changeObj) ||
            originalComponentWithMetadata.name === "RadioButtonWithLabel"))
      );
    }, changeObjects);
    return result;
  } else {
    return changeObjects;
  }
}
