import { getRaw } from "basedata";
import { CellFn } from "graphHandling/graphTypes";
import { backendRoutes } from "stores/utils/backendRoutes";
import { KOODISTOT } from "utils/constants";

export const fetch_lisatietojaKoodisto: CellFn = async () => {
  return await getRaw(
    "lisatietoja",
    backendRoutes.lisatietoja.path,
    [],
    backendRoutes.lisatietoja.minimumTimeBetweenFetchingInMinutes
  );
};

export const fetch_opetustehtavatKoodisto: CellFn = async () => {
  return await getRaw(
    KOODISTOT.OPETUSTEHTAVAKOODISTO,
    backendRoutes.opetustehtavakoodisto.path
  );
};

export const fetch_poErityisetKoulutustehtavatKoodisto: CellFn = async () => {
  return await getRaw(
    "poErityisetKoulutustehtavat",
    backendRoutes.poErityisetKoulutustehtavat.path
  );
};

export const fetch_poMuutEhdotKoodisto: CellFn = async () => {
  return await getRaw("poMuutEhdot", backendRoutes.poMuutEhdot.path);
};

export const fetch_ajalla: CellFn = async () => {
  return await getRaw("ajalla", backendRoutes.ajalla.path);
};

export const fetch_joistalisaksi: CellFn = async () => {
  return await getRaw("joistalisaksi", backendRoutes.joistalisaksi.path);
};

export const fetch_kujalisamaareet: CellFn = async () => {
  return await getRaw("kujalisamaareet", backendRoutes.kujalisamaareet.path);
};

export const fetch_opetuksenJarjestamismuodot: CellFn = async () => {
  return await getRaw(
    "opetuksenJarjestamismuodot",
    backendRoutes.opetuksenJarjestamismuodot.path
  );
};

export const fetch_opetuskieletOPH: CellFn = async () => {
  return await getRaw("kieletOPH", backendRoutes.kieletOPH.path);
};
