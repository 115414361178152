import { filter, propEq } from "ramda";
import { ComponentWithMetadata } from "utils/lomakkeet";
import { ChangeObjects } from "utils/muutokset";
import { findSiblings } from "./findSiblings";
import { uncheckNodes } from "./uncheckNodes";

/**
 * Poistaa ruksin elementin sisarelementeistä.
 * @param componentWithMetadata Metadatalla höystetty komponenttimerkkaus.
 * @param componentsWithMetadata Taulukollinen metadatalla höystettyjä komponenttimerkkauksia.
 * @param changeObjects Taulukollinen muutosobjekteja.
 * @returns Taulukollinen muutosobjekteja.
 */
export function uncheckRadioButtonsOnSameLevel(
  componentWithMetadata: ComponentWithMetadata,
  componentsWithMetadata: Array<ComponentWithMetadata>,
  changeObjects: ChangeObjects = []
): ChangeObjects {
  // Etsitään elementin sisarelementtien merkkaukset.
  const siblingComponentsWithMetadata = findSiblings(
    componentWithMetadata,
    componentsWithMetadata
  );

  // Suodatetaan merkkauksia siten, että jäljelle jäävät vain
  // radio button -elementtien merkkaukset.
  const radioSiblingComponentsWithMetadata: Array<ComponentWithMetadata> =
    filter(
      propEq("name", "RadioButtonWithLabel"),
      siblingComponentsWithMetadata
    );

  if (radioSiblingComponentsWithMetadata.length) {
    return uncheckNodes(
      radioSiblingComponentsWithMetadata,
      componentsWithMetadata,
      changeObjects
    );
  }
  return changeObjects;
}
