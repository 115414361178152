import { compose, endsWith, find, map, prop } from "ramda";
import { getLocalizedProperty } from "services/lomakkeet/utils";
import { PaikallisenTietovarastonAvain } from "enums";
import localForage from "localforage";

export default async function getLuvanTaiteenalakomponentit(
  isReadOnly,
  osionData = [],
  locale,
  isMulti,
  inputId
) {
  const luvanTaiteenalat = await localForage.getItem(
    PaikallisenTietovarastonAvain.LUVAN_TAITEENALAT_TPO
  );
  let lomakerakenne = [
    {
      anchor: "teksti",
      name: "StatusTextRow",
      properties: {
        title: "Ei valintamahdollisuutta."
      }
    }
  ];
  if (luvanTaiteenalat.length) {
    lomakerakenne = [
      {
        anchor: "komponentti",
        name: "Autocomplete",
        styleClasses: ["w-4/5", "xl:w-2/3", "mb-6"],
        properties: {
          forChangeObject: {
            section: "luvanTaiteenalat"
          },
          inputId,
          isMulti,
          isReadOnly,
          options: map(luvanTaiteenala => {
            /**
             * Tarkistetaan, onko kyseinen opetustehtävä valittuna
             * lomakkeella, jota vasten rajoituksia ollaan tekemässä.
             **/
            const stateObj = find(
              compose(
                endsWith(`.${luvanTaiteenala.koodiarvo}.ala`),
                prop("anchor")
              ),
              osionData
            );

            /**
             * Jos valinnan tilasta kertova objekti on olemassa ja sen
             * isChecked-arvo on true, tarkoittaa se sitä, että kyseinen
             * opetustehtävä on päälomakkeella valitttuna. Tällöin
             * mahdollistetaan sen valitseminen yhdeksi rajoitteista.
             */
            return stateObj && stateObj.properties.isChecked
              ? {
                  label: getLocalizedProperty(
                    luvanTaiteenala.metadata,
                    locale,
                    "nimi"
                  ),
                  value: luvanTaiteenala.koodiarvo
                }
              : null;
          }, luvanTaiteenalat).filter(Boolean),
          value: ""
        }
      }
    ];
  }
  return lomakerakenne;
}
