import { Categories } from "utils/lomakkeet";
import { __ } from "i18n-for-browser";
import { ChangeObjects } from "utils/muutokset";
import { find, isEmpty, path, propEq } from "ramda";
import { asianumeroRegExp } from "utils/muutospyyntoUtil";

/**
 * Tarkistaa annetun ankkurin perusteella kentän nykytilan. Paluuarvo kertoo,
 * onko kenttä tyhjä.
 * @param {string} anchor
 * @param {string} defaultValue
 * @param {array} changeObjects
 */
const isFieldEmpty = (
  anchor: string,
  defaultValue: string,
  changeObjects: ChangeObjects
) => {
  const changeObj = find(propEq("anchor", anchor), changeObjects);
  const currentValueByChange = path(["properties", "value"], changeObj);
  const valueToCheck =
    currentValueByChange !== undefined ? currentValueByChange : defaultValue;
  return isEmpty(valueToCheck);
};

export async function getPaatoksenTiedotTUVA(
  data: Record<string, unknown>,
  booleans: Record<string, boolean>,
  locale: string,
  changeObjects: ChangeObjects
): Promise<Categories> {
  const defaultAsianumero = "VN/";
  const defaultDiaarinumero = "";

  const changeObjAsianumero = find(
    propEq("anchor", "paatoksentiedot.asianumero.A"),
    changeObjects
  );

  const isAsianumeroFieldEmpty = await isFieldEmpty(
    "paatoksentiedot.asianumero.A",
    defaultAsianumero,
    changeObjects
  );

  const isDiaarinumeroFieldEmpty = await isFieldEmpty(
    "paatoksentiedot.diaarinumero.A",
    defaultDiaarinumero,
    changeObjects
  );

  const asianumero: string =
    path(["properties", "value"], changeObjAsianumero) || defaultAsianumero;

  const isAsianumeroValid = asianumeroRegExp.test(asianumero);

  const isValid =
    (isAsianumeroValid && isDiaarinumeroFieldEmpty) ||
    (isAsianumeroFieldEmpty && !isDiaarinumeroFieldEmpty);

  return [
    {
      anchor: "asianumero",
      components: [
        {
          anchor: "A",
          name: "Input",
          properties: {
            isReadOnly: false,
            isRequired: isDiaarinumeroFieldEmpty,
            isValid,
            label: __("common.asianumero"),
            type: "text",
            value: defaultAsianumero
          }
        }
      ]
    },
    {
      anchor: "tai",
      components: [
        {
          anchor: "A",
          name: "HtmlContent",
          properties: {
            content: __("common.or")
          }
        }
      ],
      styleClasses: ["flex uppercase justify-center w-40 ml-2"]
    },
    {
      anchor: "diaarinumero",
      components: [
        {
          anchor: "A",
          name: "Input",
          properties: {
            isReadOnly: false,
            isRequired: isAsianumeroFieldEmpty,
            isValid,
            label: __("common.diaarinumero"),
            type: "text",
            value: defaultDiaarinumero
          }
        }
      ],
      styleClasses: ["pb-8"]
    },
    {
      anchor: "paatospaiva",
      components: [
        {
          anchor: "A",
          name: "Datepicker",
          properties: {
            fullWidth: true,
            label: __("common.paatospaiva"),
            placeholder: __("common.date"),
            locale: locale,
            localizations: {
              ok: __("common.ok"),
              clear: __("common.clear"),
              cancel: __("common.cancel"),
              today: __("common.today"),
              datemax: __("common.datemax"),
              datemin: __("common.datemin"),
              dateinvalid: __("common.dateinvalid")
            },
            value: ""
          },
          styleClasses: ["pt-4"]
        }
      ]
    },
    {
      anchor: "voimaantulopaiva",
      components: [
        {
          anchor: "A",
          name: "Datepicker",
          properties: {
            fullWidth: true,
            label: __("common.voimaantulopaiva"),
            placeholder: __("common.date"),
            locale: locale,
            localizations: {
              ok: __("common.ok"),
              clear: __("common.clear"),
              cancel: __("common.cancel"),
              today: __("common.today"),
              datemax: __("common.datemax"),
              datemin: __("common.datemin"),
              dateinvalid: __("common.dateinvalid")
            },
            value: ""
          },
          styleClasses: ["pt-4"]
        }
      ]
    },
    {
      anchor: "paattymispaivamaara",
      components: [
        {
          anchor: "A",
          name: "Datepicker",
          properties: {
            fullWidth: true,
            label: __("common.paattymispaivamaara"),
            placeholder: __("common.paattymispaivamaara"),
            locale: locale,
            localizations: {
              ok: __("common.ok"),
              clear: __("common.clear"),
              cancel: __("common.cancel"),
              today: __("common.today"),
              datemax: __("common.datemax"),
              datemin: __("common.datemin"),
              dateinvalid: __("common.dateinvalid")
            },
            value: ""
          },
          styleClasses: ["pt-4"]
        }
      ]
    }
  ];
}
