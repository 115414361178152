import { defineMessages } from "react-intl";

export default defineMessages({
  highSchoolEducation: {
    id: "education.highSchoolEducation",
    defaultMessage: "Lukiokoulutus"
  },
  preAndBasicEducation: {
    id: "education.preAndBasicEducation",
    defaultMessage: "Esi- ja perusopetus"
  },
  vocationalEducation: {
    id: "education.vocationalEducation",
    defaultMessage: "Ammatillinen koulutus"
  },
  taiteenPerusopetus: {
    id: "education.taiteenPerusopetus",
    defaultMessage: "Taiteen perusopetus"
  },
  vstEducation: {
    id: "education.vstEducation",
    defaultMessage: "Vapaa sivistystyö"
  },
  koulutuksenJarjestaja: {
    id: "education.koulutuksenJarjestaja",
    defaultMessage: "Koulutuksen järjestäjä"
  },
  koulutuksenJarjestajat: {
    id: "education.koulutuksenJarjestajat",
    defaultMessage: "Koulutuksen järjestäjät"
  },
  koulutus: {
    id: "education.koulutus",
    defaultMessage: "Koulutus"
  },
  opetustaAntavatKunnat: {
    id: "education.opetustaAntavatKunnat",
    defaultMessage: "Kunnat, joissa opetusta järjestetään"
  },
  opetuksenJarjestamismuoto: {
    id: "education.opetuksenJarjestamismuoto",
    defaultMessage: "Opetuksen järjestämismuoto"
  },
  opetuksenJarjestamismuodot: {
    id: "education.opetuksenJarjestamismuodot",
    defaultMessage: "Opetuksen järjestämismuodot"
  },
  eiSisaOppilaitosTaiKotikoulumuotoinen: {
    id: "education.eiSisaOppilaitosTaiKotikoulumuotoinen",
    defaultMessage:
      "Opetusta ei järjestetä sisäoppilaitosmuotoisesti, eikä koulukotiopetusmuotoisena"
  },
  opetuskieli: {
    id: "education.opetuskieli",
    defaultMessage: "Opetuskieli"
  },
  oppilastaOpiskelijaa: {
    id: "education.oppilastaOpiskelijaa",
    defaultMessage: "Oppilasta/opiskelijaa"
  },
  opiskelijaa: {
    id: "education.opiskelijaa",
    defaultMessage: "opiskelijaa"
  },
  opiskelijamaara: {
    id: "education.opiskelijamaara",
    defaultMessage: "Opiskelijamäärä"
  },
  oppilastaOpiskelijaaOtsikko: {
    id: "education.oppilastaOpiskelijaaOtsikko",
    defaultMessage: "Opetuksen järjestäjän oppilas-/opiskelijamäärä"
  },
  muutEhdotTitle: {
    id: "education.muutEhdotTitle",
    defaultMessage: "Muut koulutuksen järjestämiseen liittyvät ehdot"
  },
  opetustaJarjestetaanSuomenUlkopuolella: {
    id: "education.opetustaJarjestetaanSuomenUlkopuolella",
    defaultMessage: "Opetusta järjestetään Suomen ulkopuolella"
  },
  oppilasOpiskelijamaarat: {
    id: "education.oppilasOpiskelijamaarat",
    defaultMessage: "Oppilas-/opiskelijamäärät"
  },
  erityisetKoulutustehtavat: {
    id: "education.erityisetKoulutustehtavat",
    defaultMessage: "Erityiset koulutustehtävät"
  },
  erityinenKoulutustehtavaInfo: {
    id: "education.erityinenKoulutustehtavaInfo",
    defaultMessage:
      "Järjestämislupa sisältää seuraavat erityiset koulutustehtävät:"
  },
  erityisenKoulutustehtavanRahoitus: {
    id: "education.erityisenKoulutustehtavanRahoitus",
    defaultMessage: "Erityisen koulutustehtävän rahoitus"
  },
  erityisenKoulutustehtavanRahoitusInfo: {
    id: "education.erityisenKoulutustehtavanRahoitusInfo",
    defaultMessage:
      "Erityisen koulutustehtävän rahoituksen perusteena oleva opiskelijamäärä on enintään:"
  },
  laajanOppimaaranMukaan: {
    id: "education.laajanOppimaaranMukaan",
    defaultMessage: "Opetus annetaan laajan oppimäärän mukaan"
  },
  voidaanAntaaMyosSeuraavillaKielilla: {
    id: "education.voidaanAntaaMyosSeuraavillaKielilla",
    defaultMessage: "Opetusta voidaan antaa myös seuraavilla kielillä"
  },
  ammatillisenKoulutuksen: {
    id: "education.ammatillisenKoulutuksen",
    defaultMessage: "Ammatillisen koulutuksen"
  },
  esiJaPerusopetuksen: {
    id: "education.esiJaPerusopetuksen",
    defaultMessage: "Esi- ja perusopetuksen"
  },
  lukiokoulutuksen: {
    id: "education.lukiokoulutuksen",
    defaultMessage: "Lukiokoulutuksen"
  },
  vapaanSivistystyon: {
    id: "education.vapaanSivistystyon",
    defaultMessage: "Vapaan sivistystyön"
  },
  koulutusmuodot: {
    id: "education.koulutusmuodot",
    defaultMessage: "Koulutusmuodot"
  },
  opetuksenJarjestaja: {
    id: "education.opetuksenJarjestaja",
    defaultMessage: "Opetuksen järjestäjä"
  },
  oppilaitokset: {
    id: "education.oppilaitokset",
    defaultMessage: "Oppilaitokset"
  },
  oppilaitoksetInfoTPO: {
    id: "education.oppilaitoksetInfoTPO",
    defaultMessage:
      "Oppilaitostieto lisätään luvan taustatietoihin, eikä sitä näytetä itse luvalla. Valitse oppilaitos tai oppilaitokset alla olevasta pudotusvalikosta."
  },
  oppilaitostenYllapitajat: {
    id: "education.oppilaitostenYllapitajat",
    defaultMessage: "Oppilaitosten ylläpitäjät"
  },
  opetuksenJarjestajat: {
    id: "education.opetuksenJarjestajat",
    defaultMessage: "Opetuksen järjestäjät"
  },
  opetetaanKielilla: {
    id: "education.opetetaanKielilla",
    defaultMessage: "opetetaan kielillä"
  },
  opetustehtavana: {
    id: "education.opetustehtavana",
    defaultMessage: "opetustehtavana"
  },
  oikeusSisaoppilaitosmuotoiseenKoulutukseen: {
    id: "education.oikeusSisaoppilaitosmuotoiseenKoulutukseen",
    defaultMessage: "Oikeus sisäoppilaitosmuotoiseen koulutukseen"
  },
  koulutuksellaEiOleSisaoppilaitosmuotoista: {
    id: "education.koulutuksellaEiOleSisaoppilaitosmuotoista",
    defaultMessage: "Koulutuksella ei ole sisäoppilaitosmuotoista"
  },
  valtakunnallinenKehittamistehtava: {
    id: "education.valtakunnallinenKehittamistehtava",
    defaultMessage: "Valtakunnallinen kehittämistehtävä"
  },
  valtakunnallinenKehittamistehtavaInfo: {
    id: "education.valtakunnallinenKehittamistehtavaInfo",
    defaultMessage:
      "Erityisiin koulutustehtäviin sisältyvät valtakunnalliset kehittämistehtävät seuraavissa opetuspainotuksissa:"
  },
  valtakunnallinenKehittamistehtavaInfo2: {
    id: "education.valtakunnallinenKehittamistehtavaInfo2",
    defaultMessage: "Ei lisättyjä valtakunnallisia kehittämistehtäviä"
  },
  luvanTaiteenala: {
    id: "education.luvanTaiteenala",
    defaultMessage: "Luvan taiteenala"
  },
  opetuksenJarjestamiskunta: {
    id: "education.opetuksenJarjestamiskunta",
    defaultMessage: "Opetuksen järjestämiskunta"
  },
  oppilaitos: {
    id: "education.oppilaitos",
    defaultMessage: "Oppilaitos"
  },
  tuva: {
    id: "education.tuva",
    defaultMessage: "TUVA"
  },
  koulutusmuoto: {
    id: "education.koulutusmuoto",
    defaultMessage: "Koulutusmuoto"
  },
  taydennaAsiaa: {
    id: "education.taydennaAsiaa",
    defaultMessage: "Täydennä asiaa"
  }
});
