import { Fragment, ReactElement, useEffect } from "react";
import { CellId } from "processes/CellId";
import { renderRadioButton } from "graphHandling/components/radioButton";
import MaakunnatJaKunnat from "components/03-templates/MaakunnatJaKunnat";
import { getCurrentValue } from "graphHandling/graphUtils";
import { IProcessCollection } from "graphHandling/graphProcessHandling";
import {
  Process_maakunnatJaKunnatContainer,
  useProcess_suomi
} from "graphs/storeHandling";
import {
  graphDefinition,
  cellImplementations as rootLevelCellImplementations
} from "graphs/toiminta-alue/suomi/processDefinition";
import { cellImplementations as mjkCellImplementations } from "graphs/toiminta-alue/maakunnatJaKunnat/processDefinition";
import { ToimintaAluePikavalinnat } from "enums";
import wizard from "i18n/definitions/wizard";
import { useIntl } from "react-intl";
import { ChangeObjects } from "utils/muutokset";

type ActionAreaProps = {
  isPreviewModeOn: boolean;
  pc: IProcessCollection;
  rajoitteet?: Record<string, { changeObjects: ChangeObjects }>;
};

const suomiProcessId = "suomi";

const OpetusSuomessa = (props: ActionAreaProps): null | ReactElement => {
  const { formatMessage } = useIntl();
  const [, actions] = useProcess_suomi();

  const cellImplementations = Object.assign(
    {},
    rootLevelCellImplementations,
    mjkCellImplementations
  );

  useEffect(() => {
    if (props.pc) {
      props.pc.addProcess(
        {
          actions,
          CellId,
          cellImplementations: cellImplementations,
          processDefinition: graphDefinition
        },
        suomiProcessId
      );

      props.pc.handleIncomingProcessToken(
        CellId.INITIALIZE_SUOMI_PROCESS,
        suomiProcessId
      );
    }
  }, []);

  const process = props.pc?.getProcess(suomiProcessId);

  if (process) {
    const components = process.actions.readPath(["components"]);
    const rBC = components[CellId.INITIALIZE_RADIO_BUTTON_COLLECTION];
    if (!rBC || !rBC.properties) {
      return null;
    }

    const radioValue = getCurrentValue("value", rBC);

    if (props.isPreviewModeOn) {
      if (radioValue === ToimintaAluePikavalinnat.MAAKUNNAT_JA_KUNNAT) {
        return (
          <Process_maakunnatJaKunnatContainer isGlobal={true}>
            <MaakunnatJaKunnat
              isPreviewModeOn={true}
              pc={props.pc}
              rajoitteet={props.rajoitteet}
            />
          </Process_maakunnatJaKunnatContainer>
        );
      } else if (radioValue === ToimintaAluePikavalinnat.MILTEI_KOKO_MAA) {
        return (
          <ul className="pl-6 list-disc">
            <li className="leading-bulletList">
              {formatMessage(wizard.wholeCountryWithoutAhvenanmaa)}
            </li>
          </ul>
        );
      }
    } else {
      return (
        <Fragment>
          {renderRadioButton(rBC.properties.radioButtons[1], rBC)}
          <br />
          {renderRadioButton(rBC.properties.radioButtons[0], rBC)}
          {radioValue === "maakunnatJaKunnat" && props.pc ? (
            <Process_maakunnatJaKunnatContainer isGlobal={true}>
              <MaakunnatJaKunnat isPreviewModeOn={false} pc={props.pc} />
            </Process_maakunnatJaKunnatContainer>
          ) : null}
        </Fragment>
      );
    }
  }
  return null;
};

OpetusSuomessa.displayName = "Opetus Suomessa";

export default OpetusSuomessa;
