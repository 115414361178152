import { CellImplementations } from "graphHandling/graphTypes";
import { CellId } from "processes/CellId";
import { fetch_lupaJaMuutospyynto } from "graphs/fetch_lupaJaMuutospyynto";
import { create_textBox } from "./cellImplementations/create_textBox";
import { initialize_lisaaPainike } from "./cellImplementations/initialize_lisaaPainike";
import { initialize_multiTextBox } from "./cellImplementations/initialize_multiTextBox";
import { update_multiTextBox } from "./cellImplementations/update_multiTextBox";
import { update_lisaaPainike } from "./cellImplementations/update_lisaaPainike";
import { update_multiTextBoxVisibility } from "./cellImplementations/update_multiTextBoxVisibility";
import { action_kumoaMuutokset } from "./cellImplementations/action_kumoaMuutokset";
import initialize_restrictions from "./cellImplementations/initialize_restrictions";

const mainProcessId = "000";

export const graphDefinition = {
  cells: {
    [CellId.INITIALIZE_GRAPH]: {
      isFirstCell: true,
      name: "Start",
      out: {
        [CellId.FETCH_LUPA_JA_MUUTOSPYYNTO]: {}
      }
    },
    [CellId.ACTION_KUMOA_MUUTOKSET]: {
      name: "Action: Kumoa muutokset",
      out: {
        [CellId.UPDATE_MAIN_PROCESS]: {
          processId: mainProcessId
        }
      }
    },
    [CellId.FETCH_LUPA_JA_MUUTOSPYYNTO]: {
      name: "Fetch: Lupa ja muutospyyntö",
      out: {
        [CellId.INITIALIZE_LISAA_PAINIKE]: {},
        [CellId.INITIALIZE_MULTI_TEXTBOX]: {},
        [CellId.INITIALIZE_RESTRICTIONS]: {}
      }
    },
    [CellId.INITIALIZE_LISAA_PAINIKE]: {
      name: "Initialize: Lisää-painike",
      out: {
        [CellId.CREATE_TEXTBOX]: {
          events: ["onClick"]
        },
        [CellId.UPDATE_MAIN_PROCESS]: {
          processId: mainProcessId
        }
      }
    },
    [CellId.INITIALIZE_MULTI_TEXTBOX]: {
      name: "Initialize: MultiTextBox",
      out: {
        [CellId.UPDATE_MULTI_TEXTBOX]: {
          events: ["onChange"]
        }
      }
    },
    [CellId.UPDATE_LISAA_PAINIKE]: {
      name: "Update: Lisäyspainike"
    },
    [CellId.UPDATE_MULTI_TEXTBOX]: {
      name: "Update: MultiTextBox",
      out: {
        [CellId.UPDATE_MAIN_PROCESS]: {
          processId: mainProcessId
        }
      }
    },
    [CellId.UPDATE_MULTI_TEXTBOX_VISIBILITY]: {
      name: "Update: MultiTextBox visibility"
    }
  }
};

export const cellImplementations: CellImplementations = {
  [CellId.ACTION_KUMOA_MUUTOKSET]: action_kumoaMuutokset,
  [CellId.FETCH_LUPA_JA_MUUTOSPYYNTO]: fetch_lupaJaMuutospyynto,

  // Ulkomaan dynaaminen TextBox-toteutus
  [CellId.CREATE_TEXTBOX]: create_textBox,
  [CellId.INITIALIZE_LISAA_PAINIKE]: initialize_lisaaPainike,
  [CellId.INITIALIZE_MULTI_TEXTBOX]: initialize_multiTextBox,
  [CellId.INITIALIZE_RESTRICTIONS]: initialize_restrictions,
  [CellId.UPDATE_LISAA_PAINIKE]: update_lisaaPainike,
  [CellId.UPDATE_MULTI_TEXTBOX]: update_multiTextBox,
  [CellId.UPDATE_MULTI_TEXTBOX_VISIBILITY]: update_multiTextBoxVisibility
};
