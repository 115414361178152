import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { getSavedChangeObjects } from "utils/frontendMuutoksetMuutospyynnolla";
import wizard from "i18n/definitions/wizard";
import {
  useChangeObjects,
  useChangeObjectsByAnchorWithoutUnderRemoval
} from "stores/muutokset";
import Wizard from "components/03-templates/Wizard/index";
import Lupanakyma from "./Lupanakyma";
import ProcedureHandler from "components/02-organisms/procedureHandler";
import { createMuutospyyntoOutput } from "utils/muutospyyntoUtil";
import { createObjectToSave } from "./saving";
import {
  append,
  concat,
  find,
  flatten,
  has,
  isEmpty,
  isNil,
  map,
  path,
  prop,
  propEq,
  reject,
  toUpper
} from "ramda";
import { localizeRouteKey } from "utils/common";
import { AppRoute } from "routes/index";
import { FIELDS } from "modules/constants";
import { getUrlOnClose } from "components/03-templates/Wizard/wizardUtils";
import { PropTypes } from "prop-types";
import localForage from "localforage";
import { useProcess000 } from "graphs/storeHandling";
import { CellId } from "processes/CellId";
import {
  cellImplementations,
  processDefinition
} from "graphs/muutospyynto/processDefinition";
import {
  isGChipCsInInitialState,
  isInInitialState
} from "graphHandling/graphUtils";
import { trackingFunction } from ".";
import Loading from "scenes/Loading";

const fadeOutTime = 100;
const processId = "000";

/**
 * Container component of UusiaAsiaDialog.
 *
 * @param {Object} props - Props object.
 */
const WizardContainer = ({ koulutusmuoto, processCollection, role }) => {
  let history = useHistory();
  const intl = useIntl();
  const { formatMessage, locale } = intl;
  const { id, muutospyyntoUuid } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [muutospyynnonTila, setMuutospyynnonTila] = useState();
  const [{ isPreviewModeOn }, { initializeChanges, setPreviewMode }] =
    useChangeObjects();
  const [validationErrors, setValidationErrors] = useState([]);
  const [osiomuutokset, setOsiomuutokset] = useState([]);
  const [organisaatio, setOrganisaatio] = useState([]);
  const [muutospyynto, setMuutospyynto] = useState();
  const [viimeisinLupa, setViimeisinLupa] = useState();
  const [stateOfProcess_000, actions] = useProcess000();

  const [notInitial, setNotInitial] = useState([]);
  const [unsaved, setUnsaved] = useState([]);

  const [fadeOut, setFadeOut] = useState();
  const [readyList, setReadyList] = useState([]);
  const [results, setResults] = useState({});

  useEffect(() => {
    if (processCollection) {
      const processes = {
        maakunnatJaKunnat: processCollection.getProcess("maakunnatJaKunnat"),
        suomi: processCollection.getProcess("suomi"),
        ulkomaat: processCollection.getProcess("ulkomaat"),
        toimintaalue: processCollection.getProcess("toimintaalue"),
        opetuskielet: processCollection.getProcess("opetuskielet")
      };

      const components = {
        maakunnatJaKunnat: processes.maakunnatJaKunnat
          ? processes.maakunnatJaKunnat.actions.readPath(["components"])
          : [],
        suomi: processes.suomi
          ? processes.suomi.actions.readPath(["components"])
          : [],
        toimintaalue: processes.toimintaalue
          ? processes.toimintaalue.actions.readPath(["components"])
          : [],
        ulkomaat: processes.ulkomaat
          ? processes.ulkomaat.actions.readPath(["components"])
          : [],
        opetuskielet: processes.opetuskielet
          ? processes.opetuskielet.actions.readPath(["components"])
          : []
      };

      let notInitial = [];
      let unsaved = [];

      if (processes.maakunnatJaKunnat) {
        notInitial = append(
          isInInitialState(components.maakunnatJaKunnat.autocomplete)
            ? null
            : "autocomplete",
          notInitial
        );
        notInitial = append(
          isGChipCsInInitialState(
            components.maakunnatJaKunnat.groupedChipCollections
          )
            ? null
            : "groupedChipCollections",
          notInitial
        );
        unsaved = append(
          processes.maakunnatJaKunnat.actions.readPath(["custom", "unsaved"]),
          unsaved
        );
      }

      if (processes.suomi) {
        notInitial = append(
          isInInitialState(components.suomi.rBC) ? null : "rBC",
          notInitial
        );
        unsaved = append(
          processes.suomi.actions.readPath(["custom", "unsaved"]),
          unsaved
        );
      }

      if (processes.toimintaalue) {
        notInitial = append(
          isInInitialState(components.toimintaalue.eiMaariteltyKuvaus)
            ? null
            : "eiMaariteltyKuvaus",
          notInitial
        );
        notInitial = append(
          isInInitialState(components.toimintaalue.suomiCheckbox)
            ? null
            : "suomiCheckbox",
          notInitial
        );
        notInitial = append(
          isInInitialState(components.toimintaalue.ulkomaatCheckbox)
            ? null
            : "ulkomaatCheckbox",
          notInitial
        );
        notInitial = append(
          isInInitialState(components.toimintaalue.lisatiedot)
            ? null
            : "lisatiedot",
          notInitial
        );

        unsaved = append(
          processes.toimintaalue.actions.readPath(["custom", "unsaved"]),
          unsaved
        );
      }

      if (processes.ulkomaat) {
        notInitial = append(
          isInInitialState(components.ulkomaat.multiTextBox)
            ? null
            : "multiTextBox",
          notInitial
        );
        unsaved = append(
          processes.ulkomaat.actions.readPath(["custom", "unsaved"]),
          unsaved
        );
      }

      if (processes.opetuskielet) {
        notInitial = concat(
          notInitial,
          map(componentKey => {
            return has("modifications", components.opetuskielet[componentKey])
              ? componentKey
              : null;
          }, Object.keys(components.opetuskielet)).filter(Boolean)
        );
        unsaved = append(
          processes.opetuskielet.actions.readPath(["custom", "unsaved"]),
          unsaved
        );
      }

      const nextNotInitial = reject(isNil, flatten(notInitial));
      const nextUnsaved = reject(isNil, flatten(unsaved));

      setNotInitial(nextNotInitial);
      setUnsaved(nextUnsaved);
      setOsiomuutokset({
        // Opetuskielet
        opetuskielet: components.opetuskielet,
        //  Toiminta-alue
        gChipCs: path(
          ["groupedChipCollections", "properties", "gChipCs"],
          components.maakunnatJaKunnat
        ),
        eiMaariteltyKuvaus: components.toimintaalue.eiMaariteltyKuvaus,
        lisatiedot: components.toimintaalue.lisatiedot,
        multiTextBox: components.ulkomaat.multiTextBox,
        rBC: components.suomi.rBC,
        suomiCheckbox: components.toimintaalue.suomiCheckbox,
        ulkomaatCheckbox: components.toimintaalue.ulkomaatCheckbox
      });
    }
  }, [stateOfProcess_000]);

  useEffect(() => {
    if (processCollection) {
      processCollection.addProcess(
        {
          actions,
          CellId,
          cellImplementations,
          customParams: {
            koulutustyyppi: koulutusmuoto.koulutustyyppi,
            language: null,
            muutospyyntoUuid,
            oid: id
          },
          processDefinition,
          trackingFunction: cellFnResults => {
            const reactElement = trackingFunction(
              cellFnResults,
              processDefinition
            );
            setReadyList(prevState => concat(prevState, reactElement));
          }
        },
        processId
      );
      const runProcess = async () => {
        const outcome = await processCollection.handleIncomingProcessToken(
          CellId.INITIALIZE_GRAPH,
          processId
        );

        setFadeOut(fadeOutTime);

        setTimeout(() => {
          setMuutospyynto(outcome[CellId.FETCH_MUUTOSPYYNTO]);
          setOrganisaatio(outcome[CellId.FETCH_ORGANISATION]);
          // Ensisijaisesti käytetään muutospyynnöltä löytyvää lupaa, joka on haettu uuid:llä
          setViimeisinLupa(
            outcome[CellId.FETCH_LUPA_BY_UUID] ||
              outcome[CellId.FETCH_VIIMEISIN_LUPA]
          );
          setResults(outcome);
        }, fadeOutTime);
      };

      runProcess();
    }
  }, []);

  const [paatoksentiedotCo] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "paatoksentiedot"
  });
  const [opetustehtavatCo] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "opetustehtavat"
  });
  const [opetuksenJarjestamismuodotCo] =
    useChangeObjectsByAnchorWithoutUnderRemoval({
      anchor: "opetuksenJarjestamismuodot"
    });
  const [erityisetKoulutustehtavatCO] =
    useChangeObjectsByAnchorWithoutUnderRemoval({
      anchor: "erityisetKoulutustehtavat"
    });
  const [opiskelijamaaratCo] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "opiskelijamaarat"
  });
  const [muutEhdotCo] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "muutEhdot"
  });
  const [rajoitteetCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "rajoitteet"
  });
  const [rajoitepoistotCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "rajoitepoistot"
  });

  useEffect(() => {
    if (muutospyynto) {
      const changeObjectsFromBackend = getSavedChangeObjects(muutospyynto);
      initializeChanges(changeObjectsFromBackend);
      setMuutospyynnonTila(prop("tila", muutospyynto));
    }
  }, [muutospyynto, initializeChanges]);

  const valtakunnallinenMaarays = find(
    propEq("koodisto", "nuts1"),
    prop("maaraykset", viimeisinLupa) || []
  );

  const steps = null;

  const title =
    muutospyynnonTila === FIELDS.TILA.VALUES.KORJAUKSESSA
      ? formatMessage(wizard.luvanKorjaustilaJarjestamisluvanMuutos)
      : formatMessage(wizard.esittelijatMuutospyyntoDialogTitle);

  const onNewDocSave = useCallback(
    uuid => {
      /**
       * User is redirected to the url of the saved document.
       */
      const url = localizeRouteKey(locale, AppRoute.Hakemus, intl, {
        id,
        koulutusmuoto: koulutusmuoto.kebabCase,
        language: "fi",
        page: 1,
        muutospyyntoUuid: uuid
      });
      history.push(url);
    },
    [formatMessage, history, id, koulutusmuoto.kebabCase, locale]
  );

  /**
   * Opens the preview.
   * @param {object} formData
   */
  const onPreview = useCallback(async () => {
    return setPreviewMode(!isPreviewModeOn);
  }, [isPreviewModeOn, setPreviewMode]);

  /**
   * Saves the form.
   * @param {object} formData
   * @returns {object} - Muutospyyntö
   */
  const onSave = useCallback(
    async formData => {
      setIsSaving(true);
      const procedureHandler = new ProcedureHandler(formatMessage);
      const outputs = await procedureHandler.run(
        "muutospyynto.tallennus.tallennaEsittelijanToimesta",
        [formData]
      );
      setIsSaving(false);
      if (
        outputs.muutospyynto.tallennus.tallennaEsittelijanToimesta.output.result
          .statusCode &&
        outputs.muutospyynto.tallennus.tallennaEsittelijanToimesta.output.result
          .statusCode === 400
      ) {
        setValidationErrors(
          outputs.muutospyynto.tallennus.tallennaEsittelijanToimesta.output
            .result.errors
        );
      } else {
        setValidationErrors([]);
      }
      return outputs.muutospyynto.tallennus.tallennaEsittelijanToimesta.output
        .result;
    },
    [formatMessage]
  );

  const onAction = useCallback(
    async (action, fromDialog = false, muutospyynnonTila) => {
      const formData = createMuutospyyntoOutput(
        await createObjectToSave(
          toUpper(locale),
          organisaatio,
          viimeisinLupa || {},
          {
            erityisetKoulutustehtavat: erityisetKoulutustehtavatCO,
            muutEhdot: muutEhdotCo,
            opetuksenJarjestamismuodot: opetuksenJarjestamismuodotCo,
            opetuskielet: osiomuutokset,
            opetustehtavat: opetustehtavatCo,
            opiskelijamaarat: opiskelijamaaratCo,
            paatoksentiedot: paatoksentiedotCo,
            rajoitepoistot: rajoitepoistotCO,
            rajoitteet: rajoitteetCO,
            toimintaalue: osiomuutokset
          },
          muutospyyntoUuid,
          results[CellId.FETCH_KOHTEET],
          results[CellId.FETCH_MAARAYSTYYPIT],
          "ESITTELIJA",
          muutospyynnonTila,
          localForage
        )
      );

      let muutospyynto = null;

      if (action === "save") {
        muutospyynto = await onSave(formData);
      } else if (action === "preview") {
        muutospyynto = await onPreview(formData);
      }

      if (action === "save") {
        if (!!muutospyynto && prop("uuid", muutospyynto)) {
          if (!muutospyyntoUuid && !fromDialog) {
            // Jos kyseessä on ensimmäinen tallennus...
            onNewDocSave(muutospyynto.uuid);
          } else {
            /**
             * Kun muutospyyntolomakkeen tilaa muokataan tässä vaiheessa,
             * vältytään tarpeelta tehdä sivun täydellistä uudelleen latausta.
             **/
            const changeObjectsFromBackend =
              getSavedChangeObjects(muutospyynto);
            initializeChanges(changeObjectsFromBackend);
          }
        }
        setUnsaved([]);
      }
    },
    [
      erityisetKoulutustehtavatCO,
      initializeChanges,
      locale,
      results[CellId.FETCH_KOHTEET],
      viimeisinLupa,
      results[CellId.FETCH_MAARAYSTYYPIT],
      muutEhdotCo,
      onNewDocSave,
      onPreview,
      onSave,
      opetuksenJarjestamismuodotCo,
      opetustehtavatCo,
      opiskelijamaaratCo,
      organisaatio,
      osiomuutokset,
      paatoksentiedotCo,
      rajoitteetCO,
      rajoitepoistotCO,
      muutospyyntoUuid
    ]
  );

  const urlOnClose = getUrlOnClose(
    role,
    locale,
    intl,
    organisaatio,
    koulutusmuoto,
    muutospyyntoUuid
  );

  if (isEmpty(reject(isNil, results)) || !organisaatio) {
    return <Loading readyList={readyList} fadeOut={fadeOut} />;
  } else {
    return (
      <Wizard
        page1={
          <Lupanakyma
            isPreviewModeOn={false}
            isRestrictionsModeOn={true}
            koulutustyyppi={koulutusmuoto.koulutustyyppi}
            maaraykset={viimeisinLupa ? viimeisinLupa.maaraykset : []}
            notInitial={notInitial}
            pc={processCollection}
            rajoitemaaraykset={viimeisinLupa ? viimeisinLupa.rajoitteet : []}
            unsaved={unsaved}
            valtakunnallinenMaarays={valtakunnallinenMaarays || {}}
            validationErrors={validationErrors}
          />
        }
        unsaved={unsaved}
        isSaving={isSaving}
        koulutusmuoto={koulutusmuoto}
        onAction={onAction}
        organisation={organisaatio}
        steps={steps}
        tila={muutospyynnonTila}
        title={title}
        urlOnClose={urlOnClose}
      />
    );
  }
};

WizardContainer.propTypes = {
  koulutusmuoto: PropTypes.object,
  processCollection: PropTypes.object,
  role: PropTypes.string
};

export default WizardContainer;
