export enum ComponentType {
  ALERT = "Alert",
  AUTOCOMPLETE = "Autocomplete",
  CHECKBOX_COLLECTION = "CheckboxCollection",
  CHIP_COLLECTION = "ChipCollection",
  CHIP = "Chip",
  DATEPICKER = "Datepicker",
  GROUP_OF_RADIO_BUTTONS = "GroupOfRadioButtons",
  GROUPED_CHECKBOX_COLLECTION = "GroupedCheckboxCollection",
  GROUPED_CHIP_COLLECTION = "GroupedChipCollection",
  GROUPED_CHIP_COLLECTIONS = "GroupedChipCollections",
  LABELED_CHECKBOX = "LabeledCheckbox",
  MULTI_TEXTBOX = "MultiTextBox",
  RADIO_BUTTON = "RadioButton",
  RADIO_BUTTON_COLLECTION = "RadioButtonCollection",
  SIMPLE_BUTTON = "SimpleButton",
  TEXT_BOX = "TextBox",
  TEXT_INPUT = "TextInput",
  UNDO = "Undo"
}
