import { ReactElement } from "react";
import { RadioButtonGroup } from "utils/lomakkeet";
import { ComponentType } from "graphHandling/components/componentType";
import RadioBGroup from "./RadioButtonGroup";

export function renderRadioButtonsGroup(
  componentDefinition: RadioButtonGroup
): ReactElement | null {
  if (componentDefinition) {
    const { properties } = componentDefinition;

    if (
      componentDefinition.name === ComponentType.GROUP_OF_RADIO_BUTTONS &&
      componentDefinition.onChange
    ) {
      return (
        <RadioBGroup {...properties} onChange={componentDefinition.onChange} />
      );
    }
  }
  return null;
}
