import {
  compose,
  concat,
  endsWith,
  filter,
  find,
  flatten,
  map,
  path,
  pathEq,
  prop,
  startsWith,
  toUpper,
  values,
  without,
  propEq,
  forEach,
  append
} from "ramda";
import { PaikallisenTietovarastonAvain } from "enums";
import localForage from "localforage";

export default async function getKunnat(
  isReadOnly,
  osionData = [],
  locale,
  isMulti,
  inputId
) {
  const localeUpper = toUpper(locale);
  const kunnat = await localForage.getItem(
    PaikallisenTietovarastonAvain.KUNNAT
  );

  const maakuntaKunnat = await localForage.getItem(
    PaikallisenTietovarastonAvain.MAAKUNTAKUNNAT
  );

  const areaOfAction = find(
    compose(endsWith(".maakunnatjakunnat"), prop("anchor")),
    osionData
  );

  // Ulkomaiden eri kunnat, jotka käyttäjä on syöttänyt vapaasti
  // päälomakkeella, halutaan samaan luetteloon Suomen kuntien kanssa.
  // Päälomakkeella on syöttämistä varten yksi textarea-elementti, joten
  // tilaobjekteja on 0 - 1 kappale(tta).
  const ulkomaatStateObj = filter(changeObj => {
    return (
      endsWith(".kuvaus", changeObj.anchor) &&
      startsWith("toimintaalue.200.", changeObj.anchor)
    );
  }, osionData);

  // Jos kunta ulkomailta löytyi, luodaan sen pohjalta vaihtoehto (option)
  // alempana koodissa luotavaa pudostusvalikkoa varten.
  const ulkomaaOptions = map(item => {
    return {
      label: item.properties.value,
      value: "200",
      index: path(["properties", "forChangeObject", "ankkuri"], item)
    };
  }, ulkomaatStateObj);

  if (kunnat) {
    // Koska osion data ei ole ajantasalla johtuen kuntaosion monimutkaisesta
    // rakenteesta, on osion muutoksia tarkkailtava, jotta käyttäjälle osataan
    // näyttää vain ja ainoastaan kunnat, jotka ovat valittuina päälomakkeella.
    const muutoksillaValitutKunnat = areaOfAction
      ? map(
          path(["properties", "metadata", "koodiarvo"]),
          filter(changeObj => {
            return pathEq(["properties", "isChecked"], true, changeObj);
          }, flatten(values(areaOfAction.properties.changeObjectsByProvince)))
        )
      : [];

    const oletusarvoinValitutKunnat = areaOfAction
      ? map(prop("koodiarvo"), areaOfAction.properties.currentMunicipalities)
      : [];

    const muutoksillaPoistetutKunnat = areaOfAction
      ? map(
          path(["properties", "metadata", "koodiarvo"]),
          filter(changeObj => {
            return pathEq(["properties", "isChecked"], false, changeObj);
          }, flatten(values(areaOfAction.properties.changeObjectsByProvince)))
        )
      : [];

    const valitutKunnat = without(
      concat(["200"], muutoksillaPoistetutKunnat),
      concat(oletusarvoinValitutKunnat, muutoksillaValitutKunnat)
    );

    // Mapataan frontin muutosobjektit ja be oletus objektit yhdeksi ja samaksi jotta niiden käsittely helpottuu
    let kuntaChangeObjects = {};
    forEach(maakunta => {
      // Muodostetaan frontin muutosobjekteista lista. {valitutkunnat} pitää sisällään frontin ja backendin kunta muutos objektit
      forEach(koodiarvo => {
        const kuntaComponent = find(
          propEq("anchor", koodiarvo),
          maakunta.categories[0].components
        );
        if (kuntaComponent) {
          const kuntaObj = find(
            propEq("koodiarvo", kuntaComponent.anchor),
            areaOfAction.properties.municipalities
          );
          kuntaChangeObjects[maakunta.anchor] = append(
            kuntaObj,
            kuntaChangeObjects[maakunta.anchor]
          );
        }
      }, valitutKunnat);

      // Muodostetaan backendin muutosobjekteista kuntalistaus mikäli maakuntatieto on tallennettu
      forEach(oletusKunnatJaMaakunnat => {
        if (oletusKunnatJaMaakunnat.koodisto == "maakunta") {
          const maakunnanKoodiarvo = maakunta.components[0].properties.code;
          if (maakunnanKoodiarvo == oletusKunnatJaMaakunnat.koodiarvo) {
            forEach(kuntaComponent => {
              const kuntaObj = find(
                propEq("koodiarvo", kuntaComponent.anchor),
                areaOfAction.properties.municipalities
              );

              kuntaChangeObjects[maakunta.anchor] = append(
                kuntaObj,
                kuntaChangeObjects[maakunta.anchor]
              );
            }, maakunta.categories[0].components);
          }
        }
      }, areaOfAction.properties.currentMunicipalities);
    }, areaOfAction.properties.provinces);

    let maakuntaObjektit = [];
    let kuntaObjektit = [];

    // Loopataan muutos objektit läpi ja muodostetaan kaksi listaa
    // 1. Maakunnat
    // 2. Yksittäiset kunnat
    forEach(koodiarvo => {
      const kaikkiKunnatMaakunnassa = path(
        ["categories", "0", "components"],
        find(propEq("anchor", koodiarvo))(areaOfAction.properties.provinces)
      );

      if (
        kuntaChangeObjects[koodiarvo].length === kaikkiKunnatMaakunnassa.length
      ) {
        const maakuntaKoodiArvo = path(
          ["components", "0", "properties", "forChangeObject", "koodiarvo"],
          find(propEq("anchor", koodiarvo))(areaOfAction.properties.provinces)
        );
        const maakunta = find(
          propEq("koodiarvo", maakuntaKoodiArvo),
          maakuntaKunnat
        );
        const maakuntaObj = {
          koodiarvo: maakunta.koodiarvo,
          label: maakunta.metadata[localeUpper].nimi
        };
        maakuntaObjektit = append(maakuntaObj, maakuntaObjektit);
      } else {
        return (
          map(kunta => {
            const kuntaObj = {
              koodiarvo: kunta.koodiarvo,
              label: kunta.metadata[localeUpper].nimi
            };

            kuntaObjektit = append(kuntaObj, kuntaObjektit);
          }, kuntaChangeObjects[koodiarvo]) || []
        );
      }
    }, Object.keys(kuntaChangeObjects) || []).filter(Boolean);

    const valitutMaakunnatJaKunnat = concat(maakuntaObjektit, kuntaObjektit);
    return [
      {
        anchor: "komponentti",
        name: "Autocomplete",
        styleClasses: ["w-4/5", "xl:w-2/3", "mb-6"],
        properties: {
          forChangeObject: {
            section: "opetustaAntavatKunnat"
          },
          inputId,
          isMulti,
          isReadOnly,
          options: concat(
            map(maakunta => {
              if (!Array.isArray(maakunta)) {
                return { label: maakunta.label, value: maakunta.koodiarvo };
              }
            }, valitutMaakunnatJaKunnat),
            map(
              kunta => {
                return { label: kunta.label, value: kunta.koodiarvo };
              },
              (() => {
                let kuntaLista = [];
                map(valittuMaakuntaJaKunta => {
                  if (Array.isArray(valittuMaakuntaJaKunta)) {
                    kuntaLista.push(...valittuMaakuntaJaKunta);
                  }
                }, valitutMaakunnatJaKunnat).filter(Boolean);
                return kuntaLista;
              })()
            ),
            ulkomaaOptions
          ).filter(Boolean),
          value: ""
        }
      }
    ];
  } else {
    return [
      {
        anchor: "ei-kuntia",
        name: "StatusTextRow",
        properties: {
          title: "Ei valintamahdollisuutta."
        }
      }
    ];
  }
}
