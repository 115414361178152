import { CellFn } from "graphHandling/graphTypes";
import { CellId } from "processes/CellId";

export const avoinAsiailmoituksenPoistaminen: CellFn = storeFunctions => {
  storeFunctions.updateGraph(
    ["components", CellId.SHOW_AVOIN_ASIAILMOITUS, "properties"],
    {
      isVisible: false
    }
  );
  return true;
};
