import Lomake from "components/02-organisms/Lomake";
import { ReactElement, useEffect } from "react";
import { useChangeObjectsByAnchorWithoutUnderRemoval } from "stores/muutokset";
import PropTypes from "prop-types";
import { useChangeObjects } from "stores/muutokset";
import { useIntl } from "react-intl";
import common from "i18n/definitions/common";
import { Maarays } from "../../../../../../../../../koodistodatanTyypit";
import { find, pathEq } from "ramda";
const constants = {
  mode: "modification",
  formLocation: ["ammatillinenKoulutus", "tutkintoLisatiedot"]
};

type Props = {
  mode: string;
  sectionId: string;
  title: string;
  maaraykset: Array<Maarays>;
};

const TutkintoLisatiedot = ({
  mode,
  sectionId,
  title,
  maaraykset
}: Props): ReactElement => {
  const [, { setChanges }] = useChangeObjects();
  const { formatMessage } = useIntl();
  const [changeObjects] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: sectionId
  });

  const lisatietoMaarays = find(
    pathEq(["koodisto"], "lisatietoja") &&
      pathEq(["meta", "tunniste"], sectionId),
    maaraykset || []
  );

  useEffect(() => {
    // Lisätään tutkintojen lisätietomuutosobjekti storeen uudelle muutospyynnölle tai jolle sitä tietoa ei ole tietoa tallennettuna.
    // jotta se tallennetaan tietokantaan huolimatta siitä onko siihen tehty muutoksia.
    if (changeObjects.length == 0 && !lisatietoMaarays) {
      const defaultChangeObject = [
        {
          anchor: "lisatiedot_tutkinnot.lisatiedot.1",
          properties: {
            value: formatMessage(common.tutkintojenLisatiedotOletusTeksti),
            metadata: {}
          }
        }
      ];
      setChanges(defaultChangeObject, "lisatiedot_tutkinnot", "lisatiedot.1");
    }
  }, [changeObjects, lisatietoMaarays]);

  const params = {
    anchor: sectionId,
    data: { maarays: lisatietoMaarays },
    changeObjects,
    isRowExpanded: mode === "reasoning",
    key: sectionId,
    mode: mode,
    path: constants.formLocation,
    rowTitle: title,
    showCategoryTitles: true,
    isInExpandableRow: false
  };

  return <Lomake {...params} />;
};

TutkintoLisatiedot.propTypes = {
  mode: PropTypes.string,
  sectionId: PropTypes.string,
  title: PropTypes.string
};
export default TutkintoLisatiedot;
