import PropTypes from "prop-types";
import Lomake from "components/02-organisms/Lomake";
import { useChangeObjectsByAnchorWithoutUnderRemoval } from "stores/muutokset";
import { useIntl } from "react-intl";
import common from "i18n/definitions/common";

const formLocation = ["vapaaSivistystyo", "oppilaitoksenTarkoitus"];

const OppilaitoksenTarkoitus = ({ isPreviewModeOn, mode }) => {
  const { formatMessage } = useIntl();
  const sectionId = "oppilaitoksenTarkoitus";
  const [changeObjects] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: sectionId
  });

  return (
    <Lomake
      anchor={sectionId}
      changeObjects={changeObjects}
      formTitle={formatMessage(common.VSTLupaSectionTitleSchoolPurpose)}
      isPreviewModeOn={isPreviewModeOn}
      isReadOnly={isPreviewModeOn}
      isRowExpanded={true}
      mode={mode}
      path={formLocation}
      rowTitle={formatMessage(
        common.VSTLupaSectionTitleSchoolPurpose
      )}></Lomake>
  );
};

OppilaitoksenTarkoitus.propTypes = {
  isPreviewModeOn: PropTypes.bool,
  mode: PropTypes.string
};

export default OppilaitoksenTarkoitus;
