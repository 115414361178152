import {
  all,
  append,
  assoc,
  assocPath,
  compose,
  filter,
  find,
  flatten,
  includes,
  init,
  isEmpty,
  isNil,
  join,
  length,
  map,
  path,
  pathEq,
  prop,
  propEq,
  reject,
  split,
  values
} from "ramda";
import { fillForBackend } from "services/lomakkeet/backendMappings";
import { getChangeObjByAnchor } from "utils/muutokset";
import { Toiminto } from "enums";
import { KOODISTOT } from "utils/constants";
import { getCurrentProps } from "graphHandling/graphUtils";
import { isNowBetweenDates } from "utils/common";
import { v4 as uuidv4 } from "uuid";

export const getChangesToSave = (
  changeObjects = {},
  kohde,
  maaraystyypit,
  maaraykset,
  muutMuuMaarays
) => {
  const { multiTextBox } = muutMuuMaarays;
  const currentProps = getCurrentProps(multiTextBox);
  const maaraykset22 = filter(propEq("koodiarvo", "22"), maaraykset);

  const muuMaaraysBesLisaykset = values(
    reject(
      isNil,
      map(tietue => {
        const lupatietue = find(
          propEq("maaraysUuid", tietue.id),
          multiTextBox.properties.tietueet
        );
        const maarays = !find(propEq("uuid", tietue.id), maaraykset22);

        if (
          tietue.value !==
            path([tietue.id, "value"], multiTextBox.properties.tietueet) &&
          tietue.value !== "" &&
          !tietue.isDeleted &&
          (maarays || (!maarays && !lupatietue))
        ) {
          const lisays = {
            koodiarvo: "22",
            koodisto: KOODISTOT.OIVA_MUUT,
            kohde,
            maaraystyyppi: find(propEq("tunniste", "VELVOITE"), maaraystyypit),
            meta: {
              ankkuri: path(["meta", "ankkuri"], maarays) || uuidv4(),
              value: tietue.value,
              createdAt: tietue.createdAt,
              maaraysUuid: tietue.id
            },
            tila: Toiminto.LISAYS
          };
          return lisays;
        }
        return null;
      }, currentProps.tietueet || [])
    )
  );

  const muuMaaraysBesPoistot = reject(
    isNil,
    map(maarays => {
      const tietue = prop(maarays.uuid, currentProps.tietueet);
      return !tietue || tietue.isDeleted
        ? {
            koodiarvo: "22",
            koodisto: KOODISTOT.OIVA_MUUT,
            kohde,
            maaraystyyppi: find(propEq("tunniste", "VELVOITE"), maaraystyypit),
            maaraysUuid: maarays.uuid,
            meta: {
              ankkuri: tietue.id,
              maaraysUuid: maarays.uuid
            },
            tila: Toiminto.POISTO
          }
        : null;
    }, maaraykset22)
  );

  const yhteistyosopimusmaaraykset = getMaarayksetByKoodiarvo("8", maaraykset);
  const muuMaaraysMaaraykset = getMaarayksetByKoodiarvo("22", maaraykset);
  const yhteistyosopimuksetUnchecked = isValintaUnchecked(
    "muut_08.yhteistyosopimus.8.A",
    changeObjects
  );
  const muuMaarayksetUnchecked = isValintaUnchecked(
    "muut_07.muumaarays.22.A",
    changeObjects
  );

  let changes = map(changeObj => {
    const anchorInit = compose(join("."), init, split("."))(changeObj.anchor);

    let tila = changeObj.properties.isChecked ? "LISAYS" : "POISTO";
    if (
      (changeObj.properties.isChecked === undefined ||
        changeObj.properties.isChecked === null) &&
      changeObj.properties.value
    ) {
      tila = "LISAYS";
    }

    const perustelut = filter(
      compose(includes(anchorInit), prop("anchor")),
      changeObjects.perustelut
    );

    const perustelutForBackend = fillForBackend(perustelut, changeObj.anchor);

    const perusteluteksti = perustelutForBackend
      ? null
      : map(perustelu => {
          if (path(["properties", "value"], perustelu)) {
            return { value: path(["properties", "value"], perustelu) };
          }
          return {
            value: path(["properties", "metadata", "fieldName"], perustelu)
          };
        }, perustelut);

    const kuvausChangeObj = getChangeObjByAnchor(
      `${changeObj.anchor}.kuvaus`,
      changeObjects.muutokset
    );

    let meta = Object.assign(
      {},
      {
        tunniste: "muut",
        changeObjects: flatten([
          [changeObj, kuvausChangeObj],
          perustelut
        ]).filter(Boolean),
        muutosperustelukoodiarvo: []
      },
      perustelutForBackend,
      perusteluteksti ? { perusteluteksti } : null
    );

    meta = kuvausChangeObj
      ? assoc("kuvaus", kuvausChangeObj.properties.value, meta)
      : meta;

    /** Ei tallenneta yhteistyösopimusten/muiden määräysten tekstikenttää,
     * jos yhteistyösopimukset/muut määräykset unchecked */
    if (
      (pathEq(["properties", "metadata", "koodiarvo"], "8", changeObj) &&
        yhteistyosopimuksetUnchecked &&
        changeObj.anchor === "muut_08.yhteistyosopimus.8.tekstikentta.A") ||
      (pathEq(["properties", "metadata", "koodiarvo"], "22", changeObj) &&
        muuMaarayksetUnchecked &&
        changeObj.anchor === "muut_07.muumaarays.22.other.A")
    ) {
      return null;
    }
    /** Yhteistyösopimustekstin pitää tallentua määräykselle paikkaan
     * meta->yhteistyosopimus->kuvaus */
    if (
      !yhteistyosopimuksetUnchecked &&
      pathEq(["properties", "metadata", "koodiarvo"], "8", changeObj) &&
      changeObj.anchor === "muut_08.yhteistyosopimus.8.tekstikentta.A"
    ) {
      meta = assocPath(
        ["yhteistyosopimus", "kuvaus"],
        changeObj.properties.value,
        meta
      );
    }
    /** Tallennetaan muun määräyksen tekstikentän arvo paikkaan
     * meta->value */
    if (
      !muuMaarayksetUnchecked &&
      pathEq(["properties", "metadata", "koodiarvo"], "22", changeObj) &&
      changeObj.anchor === "muut_07.muumaarays.22.other.A"
    ) {
      meta = assoc("value", changeObj.properties.value, meta);
    }

    const koodiarvo = path(["properties", "metadata", "koodiarvo"], changeObj);

    return koodiarvo
      ? {
          koodiarvo,
          koodisto: path(
            ["properties", "metadata", "koodisto", "koodistoUri"],
            changeObj
          ),
          kohde,
          maaraystyyppi: find(propEq("tunniste", "VELVOITE"), maaraystyypit),
          maaraysUuid: path(
            ["properties", "metadata", "maaraysUuid"],
            changeObj
          ),
          meta,
          tila
        }
      : null;
  }, changeObjects.muutokset).filter(Boolean);

  /** Poisto-objektin luominen edellisille yhteistyösopimusmääräyksille, jos löytyy muutos-objekti koskien yhteistyösopimuksen
   * tekstikenttää */
  if (
    removalObjectsShouldBeCreated(
      "8",
      yhteistyosopimusmaaraykset,
      yhteistyosopimuksetUnchecked,
      changeObjects.muutokset,
      "muut_08.yhteistyosopimus.8.tekstikentta.A"
    )
  ) {
    changes = createRemovalObjectsForMaaraykset(
      yhteistyosopimusmaaraykset,
      kohde,
      maaraystyypit,
      changes
    );
  }
  /** Poisto-objektin luominen edellisille muille määräyksille, jos löytyy muutos-objekti koskien muun määräyksen
   * tekstikenttää */
  if (
    removalObjectsShouldBeCreated(
      "22",
      muuMaaraysMaaraykset,
      muuMaarayksetUnchecked,
      changeObjects.muutokset,
      "muut_07.muumaarays.22.other.A"
    )
  ) {
    changes = createRemovalObjectsForMaaraykset(
      muuMaaraysMaaraykset,
      kohde,
      maaraystyypit,
      changes
    );
  }

  changes = reject(
    isNil,
    flatten([
      changes,
      isEmpty(muuMaaraysBesPoistot) ? null : values(muuMaaraysBesPoistot),
      isEmpty(muuMaaraysBesLisaykset) ? null : values(muuMaaraysBesLisaykset)
    ])
  );

  return changes;
};

const createRemovalObjectsForMaaraykset = (
  maaraykset,
  kohde,
  maaraystyypit,
  changes
) =>
  append(
    map(maarays => {
      return {
        koodiarvo: maarays.koodiarvo,
        koodisto: "oivamuutoikeudetvelvollisuudetehdotjatehtavat",
        kohde,
        maaraystyyppi: find(propEq("tunniste", "VELVOITE"), maaraystyypit),
        maaraysUuid: maarays.uuid,
        tila: "POISTO"
      };
    }, maaraykset),
    changes
  );

const getMaarayksetByKoodiarvo = (koodiarvo, maaraykset) => {
  return filter(maarays => maarays.koodiarvo === koodiarvo, maaraykset);
};

const isValintaUnchecked = (anchor, changeObjects) => {
  return !!find(
    cObj => cObj.anchor === anchor && !cObj.properties.isChecked,
    changeObjects.muutokset
  );
};

const removalObjectsShouldBeCreated = (
  koodiarvo,
  maarayksetValinnalle,
  valintaUnchecked,
  muutokset,
  textFieldAnchor
) =>
  !!length(maarayksetValinnalle) &&
  !valintaUnchecked &&
  !!find(
    cObj =>
      pathEq(["properties", "metadata", "koodiarvo"], koodiarvo, cObj) &&
      cObj.anchor === textFieldAnchor,
    muutokset
  );

/**
 * Suodattaa määräyksistä vain voimassaolevat.
 **/
export const getVoimassaOlevatMaaraykset = maaraykset => {
  return reject(
    isNil,
    map(maarays => {
      const voimassaOlevaMaarays = { ...maarays };
      if (!onkoMaaraysVoimassa(voimassaOlevaMaarays)) {
        return null;
      }
      voimassaOlevaMaarays.aliMaaraykset = getVoimassaOlevatMaaraykset(
        maarays.aliMaaraykset
      );
      return voimassaOlevaMaarays;
    }, maaraykset || [])
  );
};

/**
 * Tarkistaa onko määräys voimassa.
 * @param maarays
 * @returns {boolean}
 */
export const onkoMaaraysVoimassa = maarays => {
  if (!maarays) {
    return true;
  }
  if (!maarays.aliMaaraykset || isEmpty(maarays.aliMaaraykset)) {
    return true;
  }
  const maaraaika = find(
    propEq("koodisto", "kujalisamaareetlisaksiajalla"),
    maarays.aliMaaraykset
  );
  if (!maaraaika) {
    return true;
  }
  return isNowBetweenDates(
    path(["meta", "alkupvm"], maaraaika),
    path(["meta", "loppupvm"], maaraaika)
  );
};

/**
 * Suodattaa oppilasmäärä määräyksen voimassaolon mukaan.
 * Kokonaisopiskelijamäärä rajoituksen tapauksessa voi olla että vain yksi "joista enintään/vähintään"
 * alimääräys suodatetaan sen mukaan onko se voimassa vai ei.
 **/
export const getVoimassaOlevatOppilasmaaraMaaraykset = maaraykset => {
  return reject(
    isNil,
    map(maarays => {
      const voimassaOlevaMaarays = { ...maarays };
      if (pathEq(["meta", "tyyppi"], "kokonaismaara", maarays)) {
        const kohdennusMaaraykset = filter(m => {
          return m.koodisto === "kujalisamaareetjoistalisaksi";
        }, voimassaOlevaMaarays.aliMaaraykset || []);

        if (isEmpty(kohdennusMaaraykset)) {
          return onkoOpiskelijamaaraMaaraysVoimassa(voimassaOlevaMaarays)
            ? voimassaOlevaMaarays
            : null;
        }

        voimassaOlevaMaarays.aliMaaraykset = filter(
          onkoOpiskelijamaaraMaaraysVoimassa,
          kohdennusMaaraykset
        );
        return voimassaOlevaMaarays;
      }
      return onkoOpiskelijamaaraMaaraysVoimassa(voimassaOlevaMaarays)
        ? voimassaOlevaMaarays
        : null;
    }, maaraykset)
  );
};

const onkoOpiskelijamaaraMaaraysVoimassa = maarays => {
  if (!maarays) {
    return false;
  }

  if (propEq("koodisto", "kujalisamaareetlisaksiajalla", maarays)) {
    return isNowBetweenDates(
      path(["meta", "alkupvm"], maarays),
      path(["meta", "loppupvm"], maarays)
    );
  } else if (maarays.aliMaaraykset && !isEmpty(maarays.aliMaaraykset)) {
    return all(onkoOpiskelijamaaraMaaraysVoimassa, maarays.aliMaaraykset);
  }
  return true;
};
