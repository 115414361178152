import { find, length, map, path, prop, propEq } from "ramda";
import { __ } from "i18n-for-browser";
import { PaikallisenTietovarastonAvain } from "enums";
import localForage from "localforage";

export async function getOppilaitoksetTPOForm(
  { maaraykset },
  { isReadOnly },
  locale
) {
  const oppilaitokset = await localForage.getItem(
    PaikallisenTietovarastonAvain.OPPILAITOKSET_BY_OID
  );

  if (length(oppilaitokset)) {
    return [
      {
        anchor: "info",
        components: [
          {
            anchor: "A",
            name: "StatusTextRow",
            properties: {
              title: __("education.oppilaitoksetInfoTPO")
            }
          }
        ],
        styleClasses: ["mb-4"]
      },
      {
        anchor: "oppilaitokset",
        components: [
          {
            anchor: "valintaelementti",
            name: "Autocomplete",
            styleClasses: ["w-4/5", "xl:w-2/3", "mb-6"],
            properties: {
              inputId: "oppilaitokset-autocomplete",
              isMulti: true,
              isReadOnly,
              options: map(oppilaitos => {
                return {
                  label: oppilaitos.nimi[locale],
                  value: oppilaitos.oid
                };
              }, oppilaitokset).filter(Boolean),
              value: map(maarays => {
                const oid = prop("orgOid", maarays);
                const oppilaitos = find(propEq("oid", oid), oppilaitokset);
                return oppilaitos
                  ? {
                      label: path(["nimi", locale], oppilaitos),
                      value: oid
                    }
                  : null;
              }, maaraykset).filter(Boolean)
            }
          }
        ]
      }
    ];
  } else {
    return [
      {
        anchor: "ei-vaihtoehtoja",
        components: [
          {
            anchor: "A",
            name: "StatusTextRow",
            properties: {
              title:
                "Oppilaitosten lista on tyhjä, joten valintaa ei voi tehdä."
            }
          }
        ]
      }
    ];
  }
}
