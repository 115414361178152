import "../i18n-config";
import { __ } from "i18n-for-browser";
import {
  filter,
  find,
  flatten,
  map,
  path,
  pathEq,
  propEq,
  toUpper
} from "ramda";
import { getLocalizedProperty } from "services/lomakkeet/utils";
import { isAdded, isInLupa, isRemoved } from "css/label";
import { createDynamicTextFields } from "../dynamic";
import { PaikallisenTietovarastonAvain } from "enums";
import localForage from "localforage";

/**
 * Muodostaa lomakerakenteen erityisten koulutustehtävien valitsemiseksi ja
 * lisätietojen kirjoittamiseksi.
 * @param {object} data Sekalaista pohjatietoa sisältävä objekti.
 * @param {object} Boolean-tyyppiset muuttujat.
 * @param {string} locale Käyttöliittymän kieli.
 * @returns Lomakerakenne (Array of objects)
 */
export default async function getFormStructureOfErityinenKoulutustehtava(
  { maaraykset, oppilaitostyyppi, sectionId },
  { isPreviewModeOn, isReadOnly },
  locale,
  changeObjects,
  { onAddButtonClick }
) {
  const _isReadOnly = isPreviewModeOn || isReadOnly;

  const erityisetKoulutustehtavat = await localForage.getItem(
    PaikallisenTietovarastonAvain.VST_ERITYISET_KOULUTUSTEHTAVAT
  );

  const lisatiedot = await localForage.getItem(
    PaikallisenTietovarastonAvain.LISATIEDOT
  );

  const lisatiedotObj = find(
    pathEq(["koodisto", "koodistoUri"], "lisatietoja"),
    lisatiedot || []
  );

  const lisatietomaarays = find(propEq("koodisto", "lisatietoja"), maaraykset);

  return {
    isValid: true,
    structure: flatten([
      erityisetKoulutustehtavat
        ? map(erityinenKoulutustehtava => {
            const maarays = filter(
              m =>
                propEq("koodiarvo", erityinenKoulutustehtava.koodiarvo, m) &&
                propEq("koodisto", "vsterityinenkoulutustehtava", m),
              maaraykset
            );
            const metadata = path(
              ["metadata", toUpper(locale)],
              erityinenKoulutustehtava
            );
            // Jos kasite-propertyn arvo on tyhjä tai yhtä
            // kuin oppilaitostyyppi, näytetään kyseinen
            // erityinen koulutustehtävä käyttöliittymässä.
            return metadata &&
              (metadata.kasite === oppilaitostyyppi || metadata.kasite === "")
              ? {
                  anchor: erityinenKoulutustehtava.koodiarvo,
                  components: [
                    {
                      anchor: "valintaelementti",
                      name: "CheckboxWithLabel",
                      properties: {
                        title: getLocalizedProperty(
                          erityinenKoulutustehtava.metadata,
                          locale,
                          "nimi"
                        ),
                        labelStyles: {
                          addition: isAdded,
                          removal: isRemoved,
                          custom: Object.assign(
                            {},
                            maarays.length ? isInLupa : {}
                          )
                        },
                        isChecked: !!maarays.length,
                        isIndeterminate: false,
                        isPreviewModeOn,
                        isReadOnly
                      }
                    }
                  ],
                  categories: createDynamicTextFields(
                    sectionId,
                    maarays,
                    changeObjects,
                    erityinenKoulutustehtava.koodiarvo,
                    onAddButtonClick,
                    isPreviewModeOn,
                    isReadOnly,
                    erityinenKoulutustehtava.koodiarvo === "99" ? 10 : 1,
                    erityisetKoulutustehtavat,
                    locale
                  )
                }
              : null;
          }, erityisetKoulutustehtavat)
        : null,
      lisatiedotObj
        ? [
            {
              anchor: "lisatiedotTitle",
              layout: { margins: { top: "large" } },
              styleClasses: ["mt-10", "pt-10", "border-t"],
              components: [
                {
                  anchor: lisatiedotObj.koodiarvo,
                  name: "StatusTextRow",
                  properties: {
                    title: __("common.lisatiedotInfo")
                  }
                }
              ]
            },
            {
              anchor: "lisatiedot",
              components: [
                {
                  anchor: lisatiedotObj.koodiarvo,
                  name: "TextBox",
                  properties: {
                    forChangeObject: {
                      koodiarvo: lisatiedotObj.koodiarvo,
                      koodisto: lisatiedotObj.koodisto,
                      versio: lisatiedotObj.versio,
                      voimassaAlkuPvm: lisatiedotObj.voimassaAlkuPvm
                    },
                    isPreviewModeOn,
                    isReadOnly: _isReadOnly,
                    title: __("common.lisatiedot"),
                    value: lisatietomaarays ? lisatietomaarays.meta.arvo : ""
                  }
                }
              ]
            }
          ]
        : null
    ]).filter(Boolean)
  };
}
