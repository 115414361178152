import {
  append,
  compose,
  endsWith,
  filter,
  find,
  flatten,
  includes,
  last,
  map,
  mapObjIndexed,
  path,
  prop,
  propEq,
  sortBy,
  split,
  startsWith,
  uniq,
  values,
  forEach,
  concat,
  toUpper
} from "ramda";
import { getLocalizedProperty } from "../../utils";
import {
  getKohdistuvatRajoitteet,
  getPaatasonMaaraaikaRajoite,
  getRajoitteet,
  getRajoitteetFromMaarays,
  getVoimassaOlevatRajoitteet,
  isRajoiteVoimassa
} from "utils/rajoitteetUtils";
import Lisatiedot from "../../lisatiedot";
import { __ } from "i18n-for-browser";
import { getAnchorPart } from "../../../../utils/anchor";
import { PaikallisenTietovarastonAvain } from "enums";
import localForage from "localforage";
import { sortEsikatseluRajoitteet } from "../../../../helpers/html";
import { getVoimassaOlevatMaaraykset, onkoMaaraysVoimassa } from "helpers/muut";

/**
 * Funktio luo lomakerakenteen, jonka myötä käyttäjälle näytetään lista
 * lupalomakkeelle valituista kunnista. Käytössä on List-
 * komponentti, jota varten luodaan { content: kunnan_nimi } -muotoisia
 * lista-alkoita. Maakuntamääräyksiä / muutoksia ei ole tässä otettu huomioon,
 * koska kokonaisia maakuntia ei pitäisi olla valittuna
 * Esi- ja perusopetuksen puolella.
 */
export async function previewOfOpetustaAntavaKunnat(
  { lomakedata, rajoitteet = [], kuntamaaraykset },
  booleans,
  locale
) {
  const localeUpper = toUpper(locale);
  const kunnat = await localForage.getItem(
    PaikallisenTietovarastonAvain.KUNNAT
  );

  const maakuntaKunnat = await localForage.getItem(
    PaikallisenTietovarastonAvain.MAAKUNTAKUNNAT
  );

  const changeObjectsByProvinceNode = find(
    compose(endsWith(".maakunnatjakunnat"), prop("anchor")),
    lomakedata
  );

  const voimassaOlevatKuntaMaaraykset =
    getVoimassaOlevatMaaraykset(kuntamaaraykset);

  const ulkomaaCheckboxChecked = path(
    ["properties", "isChecked"],
    find(compose(endsWith("200.valintaelementti"), prop("anchor")), lomakedata)
  );

  const ulkomaaTextBoxes = filter(
    compose(endsWith(".kuvaus"), prop("anchor")),
    lomakedata
  );

  const ulkomaaTextBoxValues = ulkomaaCheckboxChecked
    ? values(
        map(ulkomaaTextBox => {
          return {
            value: path(["properties", "value"], ulkomaaTextBox),
            koodiarvo: `ulkomaa-${getAnchorPart(ulkomaaTextBox.anchor, 2)}`
          };
        }, ulkomaaTextBoxes)
      )
    : null;

  const maakuntaArvot = map(koodiarvo => {
    const kaikkiKunnatMaakunnassa = path(
      ["categories", "0", "components"],
      find(propEq("anchor", koodiarvo))(
        changeObjectsByProvinceNode.properties.provinces
      )
    );

    const valitutMaaKunnat = filter(
      compose(includes("kunnat"), prop("anchor")),
      changeObjectsByProvinceNode.properties.changeObjectsByProvince[koodiarvo]
    );

    const maakuntaKoodiArvo = path(
      ["components", "0", "properties", "forChangeObject", "koodiarvo"],
      find(propEq("anchor", koodiarvo))(
        changeObjectsByProvinceNode.properties.provinces
      )
    );

    if (valitutMaaKunnat.length === kaikkiKunnatMaakunnassa.length) {
      const maakunta = find(
        propEq("koodiarvo", maakuntaKoodiArvo),
        maakuntaKunnat
      );

      return {
        koodiarvo: maakuntaKoodiArvo,
        value: maakunta.metadata[localeUpper].nimi,
        kaikkiKunnatMaakunnassa
      };
    }
  }, Object.keys(changeObjectsByProvinceNode.properties.changeObjectsByProvince) || []).filter(
    Boolean
  );

  /** Haetaan poistettujen kuntien koodiarvot */
  const poistokoodiarvot = flatten(
    values(
      mapObjIndexed(
        arrayOfLocationNodes =>
          map(
            node =>
              path(["properties", "isChecked"], node) === false
                ? path(["properties", "metadata", "koodiarvo"], node)
                : null,
            arrayOfLocationNodes
          ),
        path(
          ["properties", "changeObjectsByProvince"],
          changeObjectsByProvinceNode
        ) || {}
      )
    ).filter(Boolean)
  );

  /** Ottaa koodiarvon parametrina, muodostaa siitä määräysten ja muutos-objektien perusteella
   *  listamuotoisen html:n esikatselua varten */
  const getStructure = koodiarvo => {
    /** Ulkomaiden käsittely */

    // Ei lisätä kuntia jotka löytyvät valitun maakunnan alta
    let foundKuntaInMaakunta;
    forEach(maakunta => {
      if (find(propEq("anchor", koodiarvo), maakunta.kaikkiKunnatMaakunnassa)) {
        foundKuntaInMaakunta = true;
      }
    }, maakuntaArvot);

    if (foundKuntaInMaakunta) {
      return null;
    }

    if (startsWith("ulkomaa", koodiarvo)) {
      /** Jos opetusta järjestetään ulkomailla -valintaa ei ole tehty, ei ulkomaita näytetä esikatselussa.
       *  Ulkomaata ei näytetä jos lomakkeelta ei löydy koodiarvoa vastaavaa ulkomaata */
      if (
        !ulkomaaCheckboxChecked ||
        !find(propEq("koodiarvo", koodiarvo), ulkomaaTextBoxValues)
      ) {
        return null;
      }
      const ulkomaaAnkkuri = last(split("-", koodiarvo));

      /** Haetaan ulkomaata vastaava määräys */
      const maarays = find(
        maarays => path(["meta", "ankkuri"], maarays) === ulkomaaAnkkuri,
        voimassaOlevatKuntaMaaraykset
      );

      /** Luodaan html-muotoinen listaesitys rajoitteita sisältäviltä määräyksiltä */
      const rajoitteetFromMaaraysHtml = maarays
        ? getRajoitteetFromMaarays(
            maarays.aliMaaraykset,
            locale,
            __("rajoitteet.ajalla"),
            "arvo",
            true
          )
        : null;

      /** Haetaan ulkomaita koskevat rajoitemuutosobjektit */
      const kohdistuvatRajoitemuutosobjektitUlkomaat = getRajoitteet(
        ulkomaaAnkkuri,
        rajoitteet,
        "index"
      );

      /** Luodaan html-muotoinen listaesitys rajoite-muutosobjekteista */
      let rajoitteetFromChangeObjectsHtml = getKohdistuvatRajoitteet(
        kohdistuvatRajoitemuutosobjektitUlkomaat,
        locale
      );

      /** getKohdistuvatRajoitteet - funktion luoma html sisältää parent-määräyksen. Otetaan tämä pois. */
      rajoitteetFromChangeObjectsHtml =
        rajoitteetFromChangeObjectsHtml.substring(
          rajoitteetFromChangeObjectsHtml.indexOf("<ul>", 8)
        );

      /** Parent-määräys */
      const titlehtml = `<ul class="list-disc"><li class="list-disc">${prop(
        "value",
        find(
          ulkomaa => ulkomaa.koodiarvo === koodiarvo,
          ulkomaaTextBoxValues || []
        )
      )}`;

      /** Yhdistetty html parent-määräys, rajoite muutosobjektit sekä rajoitemääräykset */
      const html = `<div>${titlehtml}${
        rajoitteetFromChangeObjectsHtml ? rajoitteetFromChangeObjectsHtml : ""
      }${rajoitteetFromMaaraysHtml ? rajoitteetFromMaaraysHtml : ""}</div>`;

      return createChangeObject("kunta", koodiarvo, html);
    }

    /** Kuntien käsittely */
    /** Jos kunta on poistettu, ei näytetä sitä esikatselussa */
    if (includes(koodiarvo, poistokoodiarvot)) {
      return null;
    }

    const kunta = find(kunta => {
      return kunta.koodiarvo === koodiarvo;
    }, kunnat);

    // Kloonataan kohdistuvat rajoitus muutos objektit jotta data ei mutatoidu storessa
    const kohdistuvatRajoitemuutosobjektit = sortEsikatseluRajoitteet(
      getRajoitteet(koodiarvo, rajoitteet || kunta.meta)
    );

    const maarays = find(
      maarays => maarays.koodiarvo === koodiarvo,
      voimassaOlevatKuntaMaaraykset
    );

    const paatasonMaaraaikaRajoite = getPaatasonMaaraaikaRajoite(
      kohdistuvatRajoitemuutosobjektit
    );

    if (!isRajoiteVoimassa(paatasonMaaraaikaRajoite)) {
      return "";
    } else if (!onkoMaaraysVoimassa(maarays)) {
      return "";
    }

    const voimassaOlevatRajoitteet = getVoimassaOlevatRajoitteet(
      kohdistuvatRajoitemuutosobjektit
    );

    /** Luodaan html-muotoinen listaesitys rajoitteita sisältäviltä määräyksiltä */
    const rajoitteetFromMaaraysHtml = maarays
      ? getRajoitteetFromMaarays(
          maarays.aliMaaraykset,
          locale,
          __("rajoitteet.ajalla"),
          "nimi",
          true
        )
      : null;

    /** Luodaan html-muotoinen listaesitys rajoite-muutosobjekteista */
    let rajoitteetFromChangeObjectsHtml = getKohdistuvatRajoitteet(
      voimassaOlevatRajoitteet,
      locale
    );

    /** getKohdistuvatRajoitteet - funktion luoma html sisältää parent-määräyksen. Otetaan tämä pois. */
    rajoitteetFromChangeObjectsHtml = rajoitteetFromChangeObjectsHtml.substring(
      rajoitteetFromChangeObjectsHtml.indexOf("<ul>", 8)
    );

    /** Parent-määräys */
    const titlehtml = `<div><ul class="list-disc"><li class="list-disc">${
      (kunta.metadata &&
        getLocalizedProperty(kunta.metadata, locale, "nimi")) ||
      (kunta.koodi &&
        find(propEq("kieli", locale.toUpperCase()), kunta.koodi.metadata)
          .nimi) ||
      (kunta.properties && kunta.properties.metadata.title)
    }<div>`;

    /** Yhdistetty html parent-määräys, rajoite muutosobjektit sekä rajoitemääräykset */
    const html = `${titlehtml}${rajoitteetFromChangeObjectsHtml || ""}${
      rajoitteetFromMaaraysHtml ? rajoitteetFromMaaraysHtml : ""
    }`;

    return createChangeObject("kunta", koodiarvo, html);
  };

  /** Muodostetaan muutosobjekteista ja määräyksistä lista objekteja joilla propit koodiarvo sekä value
   *  Ulkomaiden koodiarvot muotoon ulkomaa-ankkuri. Value-propin perusteella järjestetään kunnat */
  const kuntamuutosarvot = flatten(
    values(
      mapObjIndexed(arrayOfLocationNodes => {
        return map(
          node =>
            includes(".kunnat.", node.anchor)
              ? {
                  koodiarvo: path(
                    ["properties", "metadata", "koodiarvo"],
                    node
                  ),
                  value: path(["properties", "metadata", "title"], node)
                }
              : null,
          arrayOfLocationNodes
        ).filter(Boolean);
      }, path(["properties", "changeObjectsByProvince"], changeObjectsByProvinceNode) || [])
    )
  );

  const kuntamaaraysarvot = map(maarays => {
    /** Ulkomaamääräyksille koodiarvoksi "ulkomaa-ankkuri" */
    if (maarays.koodiarvo === "200") {
      /** Filteröidään ulkomaiden valintaelementtimääräys pois  */
      return path(["meta", "arvo"], maarays) &&
        path(["meta", "ankkuri"], maarays)
        ? {
            koodiarvo: `ulkomaa-${path(["meta", "ankkuri"], maarays)}`,
            value: path(["meta", "arvo"], maarays)
          }
        : null;
    }

    const kunta = find(kunta => {
      return kunta.koodiarvo === maarays.koodiarvo;
    }, kunnat);

    return {
      koodiarvo: maarays.koodiarvo,
      value: getLocalizedProperty(kunta.metadata, locale, "nimi")
    };
  }, voimassaOlevatKuntaMaaraykset).filter(Boolean);

  const ulkomaaarvot = map(textboxValue => {
    return {
      koodiarvo: prop("koodiarvo", textboxValue),
      value: prop("value", textboxValue)
    };
  }, ulkomaaTextBoxValues || []);

  const kaikkikoodiarvot = uniq(
    sortBy(
      prop("value"),
      flatten([kuntamuutosarvot, kuntamaaraysarvot, ulkomaaarvot])
    )
  );

  // Muodostetaan html elementit maakuntatiedoista
  let maaKunnatHtml = map(maakunta => {
    const maakuntaRajoite = sortEsikatseluRajoitteet(
      getRajoitteet(maakunta.koodiarvo, rajoitteet || [])
    );

    let rajoitteetFromChangeObjectsHtml = getKohdistuvatRajoitteet(
      maakuntaRajoite,
      locale
    );

    /** getKohdistuvatRajoitteet - funktion luoma html sisältää parent-määräyksen. Otetaan tämä pois. */
    rajoitteetFromChangeObjectsHtml = rajoitteetFromChangeObjectsHtml.substring(
      rajoitteetFromChangeObjectsHtml.indexOf("<ul>", 8)
    );

    return createChangeObject(
      "maakunta",
      "maakunta",
      `<div><ul class="list-disc"><li class="list-disc">${maakunta.value} ${
        rajoitteetFromChangeObjectsHtml ? rajoitteetFromChangeObjectsHtml : ""
      }</li></ul></div>`
    );
  }, maakuntaArvot);

  // Mikäli maakuntatietoja on muutospyynnöllä, erotetaan maakunnat ja kuntatiedot tyhjällä rivillä toisistaan
  if (maaKunnatHtml.length) {
    maaKunnatHtml = append(
      createChangeObject("tyhja", "tyhja", `<br>`),
      maaKunnatHtml
    );
  }

  let kunnatHtml = map(
    getStructure,
    map(prop("koodiarvo"), kaikkikoodiarvot)
  ).filter(Boolean);

  const lisatiedotNode = find(
    // 1 = koodiston koodiarvo
    node => endsWith(".lisatiedot.1", node.anchor),
    lomakedata
  );

  if (lisatiedotNode && lisatiedotNode.properties.value) {
    kunnatHtml = append(
      Lisatiedot(lisatiedotNode.properties.value),
      kunnatHtml
    );
  }

  return concat(maaKunnatHtml, kunnatHtml);
}

const createChangeObject = (anchor, koodiarvo, htmlContent) => {
  return {
    anchor: anchor,
    components: [
      {
        anchor: koodiarvo,
        name: "HtmlContent",
        properties: {
          content: htmlContent
        }
      }
    ]
  };
};
