import { append, endsWith, find, pathEq, propEq } from "ramda";
import { replaceAnchorPartWith } from "utils/anchor";
import Lisatiedot from "../../lisatiedot";
import { createEsikatseluHTML } from "helpers/esikatselu";

export const previewOfOppilaitoksenAlueellisuusJaValtakunnallisuus = (
  { lomakedata },
  booleans,
  locale
) => {
  let structure = [];
  const checkedNode = find(
    pathEq(["properties", "isChecked"], true),
    lomakedata
  );

  if (checkedNode) {
    const kuvausNode = find(
      propEq(
        "anchor",
        `${replaceAnchorPartWith(checkedNode.anchor, 2, "kuvaus")}.A`
      ),
      lomakedata
    );

    let html = "";

    html = createEsikatseluHTML(
      [],
      0,
      [],
      locale,
      "nimi",
      kuvausNode
        ? kuvausNode.properties.value
          ? kuvausNode.properties.value
          : checkedNode.properties.title
        : checkedNode.properties.title
    );
    structure = append(
      {
        isValid: true,
        components: [
          {
            anchor: "A",
            name: "HtmlContent",
            properties: { content: html }
          }
        ]
      },
      structure
    );
  }

  const lisatiedotNode = find(
    node => endsWith(".lisatiedot.1", node.anchor),
    lomakedata
  );

  if (lisatiedotNode && lisatiedotNode.properties.value) {
    structure = append(Lisatiedot(lisatiedotNode.properties.value), structure);
  }

  return structure;
};
