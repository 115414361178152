import { AppRoute } from "./app-routes";
import common from "../i18n/definitions/common";

export const tilastotRoutes = {
  categories: [
    {
      anchor: "Tilastot",
      components: [
        {
          anchor: "Link",
          name: "SitemapLink",
          properties: {
            route: AppRoute.Tilastot,
            text: common.statistics
          }
        }
      ],
      categories: [
        {
          anchor: "perusopetuksenJarjestamisluvat",
          components: [
            {
              anchor: "Link",
              name: "SitemapLink",
              properties: {
                route: {
                  fi: "https://vipunen.fi/fi-fi/_layouts/15/xlviewer.aspx?id=/fi-fi/Raportit/Koulutuksen%20yhteiset%20-%20j%C3%A4rjest%C3%A4mis-%20ja%20yll%C3%A4pit%C3%A4misluvat%20-%20esi-%20ja%20perusopetus.xlsb",
                  sv: "https://vipunen.fi/fi-fi/_layouts/15/xlviewer.aspx?id=/fi-fi/Raportit/Koulutuksen%20yhteiset%20-%20j%C3%A4rjest%C3%A4mis-%20ja%20yll%C3%A4pit%C3%A4misluvat%20-%20esi-%20ja%20perusopetus.xlsb"
                },
                text: common.perusopetuksenJarjestamisluvat
              }
            }
          ]
        },
        {
          anchor: "ammatillisenKoulutuksenJarjestamisluvat",
          components: [
            {
              anchor: "Link",
              name: "SitemapLink",
              properties: {
                route: {
                  fi: "https://vipunen.fi/fi-fi/_layouts/15/xlviewer.aspx?id=/fi-fi/Raportit/Koulutuksen%20yhteiset%20-%20j%C3%A4rjest%C3%A4mis-%20ja%20yll%C3%A4pit%C3%A4misluvat%20-%20ammatillinen.xlsb",
                  sv: "https://vipunen.fi/fi-fi/_layouts/15/xlviewer.aspx?id=/fi-fi/Raportit/Koulutuksen%20yhteiset%20-%20j%C3%A4rjest%C3%A4mis-%20ja%20yll%C3%A4pit%C3%A4misluvat%20-%20ammatillinen.xlsb"
                },
                text: common.ammatillisenKoulutuksenJarjestamisluvat
              }
            }
          ]
        },
        {
          anchor: "vapaanSivistystyonJarjestamisluvat",
          components: [
            {
              anchor: "Link",
              name: "SitemapLink",
              properties: {
                route: {
                  fi: "https://vipunen.fi/fi-fi/_layouts/15/xlviewer.aspx?id=/fi-fi/Raportit/Koulutuksen%20yhteiset%20-%20j%C3%A4rjest%C3%A4mis-%20ja%20yll%C3%A4pit%C3%A4misluvat%20-%20vapaa%20sivistysty%C3%B6.xlsb",
                  sv: "https://vipunen.fi/fi-fi/_layouts/15/xlviewer.aspx?id=/fi-fi/Raportit/Koulutuksen%20yhteiset%20-%20j%C3%A4rjest%C3%A4mis-%20ja%20yll%C3%A4pit%C3%A4misluvat%20-%20vapaa%20sivistysty%C3%B6.xlsb"
                },
                text: common.vapaanSivistystyonJarjestamisluvat
              }
            }
          ]
        }
      ]
    }
  ]
};
