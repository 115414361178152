import { Fragment, ReactElement } from "react";
import {
  addIndex,
  filter,
  find,
  isEmpty,
  length,
  map,
  path,
  pathEq,
  propEq,
  sortBy,
  toUpper
} from "ramda";
import { useIntl } from "react-intl";
import education from "../../../../i18n/definitions/education";
import Typography from "@mui/material/Typography";
import { getRajoitteetFromMaarays } from "utils/rajoitteetUtils";
import { getLocalizedProperty } from "services/lomakkeet/utils";
import LisatiedotHtmlLupa from "components/03-templates/HtmlLupanakyma/Lisatiedot";
import rajoitteet from "i18n/definitions/rajoitteet";
import { Lupamaarays } from "Lupa";
import { FKoodistotietue } from "koodistodatanTyypitFrontille";

export default function PoOpetuksenMuutEhdotHtml({
  maaraykset,
  muutEhdotKoodisto
}: {
  maaraykset: Array<Lupamaarays>;
  muutEhdotKoodisto: Array<FKoodistotietue>;
}): null | ReactElement {
  const intl = useIntl();
  const localeUpper = toUpper(intl.locale);

  const muutEhdotMaaraykset = sortBy(
    (m: Lupamaarays) =>
      parseFloat(`${m.koodiarvo}.${path(["meta", "ankkuri"], m)}`),
    filter(
      maarays =>
        pathEq(
          ["kohde", "tunniste"],
          "muutkoulutuksenjarjestamiseenliittyvatehdot",
          maarays
        ) &&
        maarays.koodisto === "pomuutkoulutuksenjarjestamiseenliittyvatehdot",
      maaraykset
    )
  );

  const lisatietomaarays = find(
    maarays =>
      pathEq(
        ["kohde", "tunniste"],
        "muutkoulutuksenjarjestamiseenliittyvatehdot",
        maarays
      ) && maarays.koodisto === "lisatietoja",
    maaraykset
  );

  return !isEmpty(muutEhdotMaaraykset) && !isEmpty(muutEhdotKoodisto) ? (
    <div className="mt-4">
      <Typography component="h3" variant="h3">
        {intl.formatMessage(education.muutEhdotTitle)}
      </Typography>

      <ul className="ml-8 list-disc mb-4">
        {addIndex(map)((_maarays, index) => {
          const maarays = _maarays as Lupamaarays;
          let naytettavaArvo = path(["meta", "kuvaus"], maarays);

          if (!naytettavaArvo) {
            const koodistosta = find(
              propEq("koodiarvo", maarays.koodiarvo),
              muutEhdotKoodisto
            );

            if (koodistosta) {
              naytettavaArvo = getLocalizedProperty(
                koodistosta.metadata,
                localeUpper,
                "kuvaus"
              );
            }
          }

          const result = (
            <Fragment key={`${maarays.koodiarvo}-${index}`}>
              <li
                className={`leading-bulletList ${
                  length(maarays.aliMaaraykset) ? "mb-2" : ""
                }`}>
                {naytettavaArvo}
                {length(maarays.aliMaaraykset)
                  ? getRajoitteetFromMaarays(
                      maarays.aliMaaraykset,
                      localeUpper,
                      intl.formatMessage(rajoitteet.ajalla),
                      "kuvaus"
                    )
                  : ""}
              </li>
            </Fragment>
          );
          return result;
        }, muutEhdotMaaraykset)}
      </ul>
      <LisatiedotHtmlLupa lisatietomaarays={lisatietomaarays} />
    </div>
  ) : null;
}
