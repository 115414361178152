import { getRaw } from "basedata";
import { CellFn } from "graphHandling/graphTypes";
import { backendRoutes } from "stores/utils/backendRoutes";

export const fetch_muutospyynto: CellFn = async (
  storeFunctions,
  intl,
  payload,
  result,
  customParams
) => {
  if (!customParams) {
    throw new Error(
      "Määritä organisaation ID (oid), jotta organisaatiohaku voidaan tehdä."
    );
  }
  try {
    const muutospyynto = await getRaw(
      "muutospyynto",
      `${backendRoutes.muutospyynto.path + customParams.muutospyyntoUuid}`,
      [],
      0.5 // 0.5 = puolen minuutin cache
    );
    return muutospyynto;
  } catch (errText) {
    throw new Error(errText as string);
  }
};
