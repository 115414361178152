import { Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { prop } from "ramda";
import Loading from "scenes/Loading";
import BaseData from "basedata";
import Jarjestaja from "components/03-templates/Jarjestaja/index";
import { AppRoute } from "routes/index";

const JarjestajaSwitch = ({
  JarjestamislupaJSX,
  kohteet,
  koulutusmuoto,
  lupa,
  lupaUuid,
  organisation,
  user,
  tulevatLuvat,
  voimassaOlevaLupa,
  WizardContainer
}) => {
  const { locale } = useIntl();

  return (
    <Fragment>
      <Switch>
        <Route
          authenticated={!!user}
          exact
          path={AppRoute.UusiHakemus}
          render={() => (
            <BaseData
              locale={locale}
              koulutustyyppi={koulutusmuoto.koulutustyyppi}
              render={_props => {
                return (
                  <WizardContainer
                    kohteet={_props.kohteet}
                    koulutukset={_props.koulutukset}
                    koulutusalat={_props.koulutusalat}
                    koulutusmuoto={koulutusmuoto}
                    koulutustyypit={_props.koulutustyypit}
                    lisatiedot={_props.lisatiedot}
                    maaraystyypit={_props.maaraystyypit}
                    muut={_props.muut}
                    organisaatio={_props.organisaatio}
                    viimeisinLupa={_props.viimeisinLupa}
                    role="KJ"
                  />
                );
              }}
            />
          )}
        />
        <Route
          path="*"
          render={() => {
            /**
             * Varmistetaan, että ollaan aikeissa näyttää halutun järjestäjän
             * tiedot vertaamalla haussa käytettyä oid:a luvan
             * järjestäjän oid:iin, mikäli haussa käytettiin oid:a. Toinen
             * vaihtoehto on, että haku on tehty lupaUuid:llä, jolloin
             * vertaamme sitä luvan vastaavaan tietoon.
             * */
            if (
              (lupa &&
                organisation &&
                organisation.oid === prop("jarjestajaOid", lupa)) ||
              (lupaUuid && lupaUuid === prop("uuid", lupa))
            ) {
              return (
                <Jarjestaja
                  JarjestamislupaJSX={JarjestamislupaJSX}
                  kohteet={kohteet}
                  koulutusmuoto={koulutusmuoto}
                  lupa={lupa}
                  organisation={organisation}
                  user={user}
                  tulevatLuvat={tulevatLuvat}
                  voimassaOlevaLupa={voimassaOlevaLupa}
                />
              );
            }
            return <Loading />;
          }}
        />
      </Switch>
    </Fragment>
  );
};

JarjestajaSwitch.propTypes = {
  JarjestamislupaJSX: PropTypes.func,
  kohteet: PropTypes.array,
  koulutusmuoto: PropTypes.object,
  lupa: PropTypes.object,
  lupaUuid: PropTypes.string,
  organisation: PropTypes.object,
  tulevatLuvat: PropTypes.array,
  user: PropTypes.object,
  voimassaOlevaLupa: PropTypes.object,
  WizardContainer: PropTypes.func
};

export default JarjestajaSwitch;
