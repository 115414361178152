import { Fragment } from "react";
import { useIntl } from "react-intl";
import { NavLink, Route, Router, useHistory } from "react-router-dom";
import common from "i18n/definitions/common";
import education from "i18n/definitions/education";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Typography } from "@mui/material";
import { AppRoute } from "routes/index";
import { LocalizedSwitch } from "modules/i18n/index";
import { localizeRouteKey } from "utils/common";
import { MuutoksetContainer } from "stores/muutokset";
import { ROLE_ESITTELIJA } from "modules/constants";
import esiJaPerusopetus from "i18n/definitions/esiJaPerusopetus";
import lukiokoulutus from "i18n/definitions/lukiokoulutus";
import ammatillinenKoulutus from "i18n/definitions/ammatillinenKoulutus";
import { PropTypes } from "prop-types";
import Loading from "scenes/Loading";
import { concat } from "ramda";
import { PaikallisenTietovarastonAvain } from "enums";
import BaseData from "basedata";
import Asiat from "../Asiat";
import GraphTemplate from "components/03-templates/GraphTemplate.js";
import TuvaEsivalinnat from "../Asiat/AsialomakeTuva";
import { CellId } from "processes/CellId";
import {
  cellImplementations,
  graphDefinition
} from "graphs/tuva/uusiAsiaEsivalinnat/processDefinition";
import { Process000Container } from "graphs/storeHandling";

const Asianhallinta = ({
  esidialoginHakuavaimet,
  hakuavaimet,
  koulutusmuoto,
  user,
  UusiAsiaEsidialog,
  WizardContainer
}) => {
  const history = useHistory();
  const intl = useIntl();
  const { formatMessage, locale } = intl;

  const kaikkiHakuavaimet = concat(
    [PaikallisenTietovarastonAvain.ORGANISAATIO, "viimeisinLupa"],
    hakuavaimet || []
  );

  const role =
    sessionStorage.getItem("role") === ROLE_ESITTELIJA ? "ESITTELIJA" : "KJ";

  return (
    <Fragment>
      <Router history={history}>
        <LocalizedSwitch>
          <Route
            authenticated={!!user}
            path={AppRoute.UusiAsiaEsivalinnat}
            render={() => {
              return (
                <MuutoksetContainer>
                  <BaseData
                    keys={hakuavaimet}
                    locale={locale}
                    koulutustyyppi={koulutusmuoto.koulutustyyppi}
                    render={() => {
                      return (
                        <GraphTemplate
                          cellId={CellId}
                          cellImplementations={cellImplementations}
                          firstCellId={CellId.INITIALIZE_TUVA_ASIA_ESIVALINNAT}
                          graphDefinition={graphDefinition}
                          render={gtProps => {
                            return (
                              <TuvaEsivalinnat
                                graph={gtProps.graph}
                                koulutusmuoto={koulutusmuoto}
                                storeFunctions={gtProps.storeFunctions}
                              />
                            );
                          }}
                        />
                      );
                    }}
                  />
                </MuutoksetContainer>
              );
            }}
          />
          <Route
            authenticated={!!user}
            exact
            path={[
              AppRoute.UusiHakemus,
              AppRoute.UusiHakemusVST,
              AppRoute.UusiHakemusTUVA
            ]}
            render={() => {
              return (
                <BaseData
                  keys={kaikkiHakuavaimet}
                  locale={locale}
                  koulutustyyppi={koulutusmuoto.koulutustyyppi}
                  render={_props => {
                    return (
                      <MuutoksetContainer>
                        <Process000Container>
                          <WizardContainer
                            kohteet={_props.kohteet}
                            koulutukset={_props.koulutukset}
                            koulutusalat={_props.koulutusalat}
                            koulutusmuoto={koulutusmuoto}
                            koulutustyypit={_props.koulutustyypit}
                            lisatiedot={_props.lisatiedot}
                            maaraystyypit={_props.maaraystyypit}
                            muut={_props.muut}
                            opetuskielet={_props.opetuskielet}
                            organisaatio={_props.organisaatio}
                            role={role}
                            viimeisinLupa={_props.viimeisinLupa}
                          />
                        </Process000Container>
                      </MuutoksetContainer>
                    );
                  }}
                />
              );
            }}
          />
          <Route
            authenticated={!!user}
            exact
            path={[AppRoute.Hakemus, AppRoute.Tuvahakemus]}
            render={() => {
              return (
                <BaseData
                  keys={["muutospyynto"]}
                  koulutustyyppi={koulutusmuoto.koulutustyyppi}
                  locale={locale}
                  render={_props1 => {
                    return _props1.muutospyynto ? (
                      <BaseData
                        keys={kaikkiHakuavaimet}
                        locale={locale}
                        koulutustyyppi={koulutusmuoto.koulutustyyppi}
                        isMuutospyynnonMuokkaus={true}
                        lupaId={_props1.muutospyynto.lupaUuid}
                        oid={_props1.muutospyynto.jarjestaja.oid}
                        render={_props2 => {
                          return (
                            <MuutoksetContainer>
                              <WizardContainer
                                kohteet={_props2.kohteet}
                                koulutukset={_props2.koulutukset}
                                koulutusalat={_props2.koulutusalat}
                                koulutusmuoto={koulutusmuoto}
                                koulutustyypit={_props2.koulutustyypit}
                                lisatiedot={_props2.lisatiedot}
                                maaraystyypit={_props2.maaraystyypit}
                                muut={_props2.muut}
                                opetuskielet={_props2.opetuskielet}
                                organisaatio={_props1.muutospyynto.jarjestaja}
                                role={role}
                                viimeisinLupa={_props2.lupaByUuid}
                                muutospyynto={_props1.muutospyynto}
                              />
                            </MuutoksetContainer>
                          );
                        }}
                      />
                    ) : (
                      <Loading />
                    );
                  }}
                />
              );
            }}
          />
          <Route
            authenticated={!!user}
            path={AppRoute.Asianhallinta}
            render={() => {
              return (
                <BaseData
                  keys={hakuavaimet}
                  locale={locale}
                  koulutustyyppi={koulutusmuoto.koulutustyyppi}
                  render={_props => {
                    return (
                      <Asiat
                        esidialoginHakuavaimet={esidialoginHakuavaimet}
                        koulutusmuoto={koulutusmuoto}
                        user={user}
                        UusiAsiaEsidialog={UusiAsiaEsidialog}
                        vstTyypit={_props.vstTyypit}
                      />
                    );
                  }}
                />
              );
            }}
          />
          <Route path="*">
            {sessionStorage.getItem("role") === ROLE_ESITTELIJA ? (
              <div className="flex-1 bg-gray-100">
                <div className="border border-gray-300 max-w-8xl m-auto bg-white mt-12 px-64 py-12">
                  <Typography component="h1" variant="h1">
                    {formatMessage(common.asianhallinta)}
                  </Typography>
                  <p className="text-lg">
                    {formatMessage(common.asianhallintaInfoText)}
                  </p>
                  <div className="grid grid-cols-3 gap-4 justify-items-auto pt-12">
                    <NavLink
                      className="font-semibold px-4 py-8 bg-white border border-gray-300 flex justify-center items-center"
                      to={localizeRouteKey(
                        locale,
                        AppRoute.AsianhallintaAvoimet,
                        intl,
                        {
                          koulutusmuoto: formatMessage(
                            esiJaPerusopetus.kebabCase
                          )
                        }
                      )}
                      exact={true}
                      style={{ textDecoration: "none", color: "inherit" }}>
                      {formatMessage(education.preAndBasicEducation)}
                      <ArrowForwardIcon className="ml-4" />
                    </NavLink>
                    <NavLink
                      className="font-semibold px-4 py-8 bg-white border border-gray-300 flex justify-center items-center"
                      to={localizeRouteKey(
                        locale,
                        AppRoute.AsianhallintaAvoimet,
                        intl,
                        {
                          koulutusmuoto: formatMessage(lukiokoulutus.kebabCase)
                        }
                      )}
                      exact={true}
                      style={{ textDecoration: "none", color: "inherit" }}>
                      {formatMessage(education.highSchoolEducation)}
                      <ArrowForwardIcon className="ml-4" />
                    </NavLink>
                    <NavLink
                      className="font-semibold px-4 py-8 bg-white border border-gray-300 flex justify-center items-center"
                      to={localizeRouteKey(
                        locale,
                        AppRoute.AsianhallintaAvoimet,
                        intl,
                        {
                          koulutusmuoto: formatMessage(
                            ammatillinenKoulutus.kebabCase
                          )
                        }
                      )}
                      exact={true}
                      style={{ textDecoration: "none", color: "inherit" }}>
                      {formatMessage(education.vocationalEducation)}
                      <ArrowForwardIcon className="ml-4" />
                    </NavLink>
                  </div>
                </div>
              </div>
            ) : null}
          </Route>
        </LocalizedSwitch>
      </Router>
    </Fragment>
  );
};

Asianhallinta.propTypes = {
  koulutusmuoto: PropTypes.object,
  user: PropTypes.object,
  UusiAsiaEsidialog: PropTypes.func,
  WizardContainer: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  esidialoginHakuavaimet: PropTypes.array,
  hakuavaimet: PropTypes.array
};

export default Asianhallinta;
