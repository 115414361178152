import { usePopperTooltip } from "react-popper-tooltip";
import "react-popper-tooltip/dist/styles.css";
import { PropTypes } from "prop-types";
import { Fragment } from "react";

const Tooltip = ({ children, tooltip, hideArrow, ...props }) => {
  const {
    getTooltipProps,
    getArrowProps,
    visible,
    setTriggerRef,
    setTooltipRef
  } = usePopperTooltip({
    interactive: true,
    ...props
  });
  return (
    <Fragment>
      {visible ? (
        <div
          {...getTooltipProps({
            className: "tooltip-container max-w-xs"
          })}
          ref={setTooltipRef}>
          {!hideArrow && (
            <div
              {...getArrowProps({
                className: "tooltip-arrow"
              })}
            />
          )}
          {tooltip}
        </div>
      ) : null}
      <span ref={setTriggerRef}>{children}</span>
    </Fragment>
  );
};

Tooltip.propTypes = {
  children: PropTypes.object,
  hideArrow: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default Tooltip;
