import { ToimintaAluePikavalinnat, Toiminto } from "enums";
import { ComponentType } from "graphHandling/components/componentType";
import { defineRadioButton } from "graphHandling/components/radioButton";
import { CellFn, EventPayload } from "graphHandling/graphTypes";
import wizard from "i18n/definitions/wizard";
import {
  compose,
  filter,
  find,
  isEmpty,
  length,
  or,
  prop,
  propEq,
  tail,
  zipObj
} from "ramda";
import { Muutos, Muutospyynto } from "types";
import { IRadioButtonCollection } from "utils/lomakkeet";
import { Lupa } from "Lupa";

export const initialize_radioButtons: CellFn = (storeFunctions, intl) => {
  const onChange = (payload: EventPayload) => {
    return payload;
  };

  const lupa = storeFunctions.readPath(["custom", "viimeisinLupa"]) as Lupa;
  let defaultValue;

  if (lupa) {
    const { maaraykset } = lupa;

    if (find(propEq("koodiarvo", "FI2"), maaraykset)) {
      defaultValue = ToimintaAluePikavalinnat.EI_MAARITELTY;
    } else if (find(propEq("koodiarvo", "FI1"), maaraykset)) {
      defaultValue = ToimintaAluePikavalinnat.MILTEI_KOKO_MAA;
    } else if (
      !isEmpty(
        filter(
          compose(k => or(k === "kunta", k === "maakunta"), prop("koodisto")),
          lupa.maaraykset
        )
      )
    ) {
      defaultValue = ToimintaAluePikavalinnat.MAAKUNNAT_JA_KUNNAT;
    }
  }

  const muutospyynto = storeFunctions.readPath([
    "custom",
    "muutospyynto"
  ]) as Muutospyynto;

  const keys = ["maakunnatJaKunnat", "milteiKokoMaa"];
  let kuntaMaakuntaMuutokset: Array<Muutos> = [];
  let milteiKokoMaa = null;

  if (muutospyynto) {
    const { muutokset } = muutospyynto;
    kuntaMaakuntaMuutokset = filter(
      compose(k => or(k === "kunta", k === "maakunta"), prop("koodisto")),
      muutokset
    );
    milteiKokoMaa = find(propEq("koodiarvo", "FI1"), muutokset);
  }

  const radio0 = defineRadioButton({
    id: "radio0",
    label: "Maakunnat ja kunnat",
    name: "aluevalinta",
    styleClasses: length(kuntaMaakuntaMuutokset) > 0 ? ["text-[purple]"] : [],
    value: keys[0]
  });

  const tilat = zipObj(tail(keys), [
    milteiKokoMaa && prop("tila", milteiKokoMaa)
  ]);

  const valueByBMods = !isEmpty(
    filter(
      muutos => muutos.tila === Toiminto.LISAYS && muutos.koodiarvo !== "200",
      kuntaMaakuntaMuutokset
    )
  )
    ? keys[0]
    : tilat.milteiKokoMaa === Toiminto.LISAYS
    ? keys[1]
    : undefined;

  const radio1 = defineRadioButton({
    id: "radio1",
    label: intl.formatMessage(wizard.wholeCountryWithoutAhvenanmaa),
    name: "aluevalinta",
    styleClasses:
      tilat.milteiKokoMaa === Toiminto.POISTO
        ? ["line-through", "text-[purple]"]
        : [],
    value: keys[1]
  });

  const modifications =
    valueByBMods && valueByBMods !== defaultValue
      ? {
          backend: {
            value: valueByBMods
          }
        }
      : undefined;

  return {
    name: ComponentType.RADIO_BUTTON_COLLECTION,
    onChange,
    properties: {
      radioButtons: [radio0, radio1],
      value: defaultValue
    },
    modifications
  } as IRadioButtonCollection;
};
