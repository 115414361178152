import moment from "moment";
import {
  compose,
  dissoc,
  filter,
  find,
  flatten,
  groupBy,
  includes,
  last,
  map,
  nth,
  path,
  prop,
  propEq,
  split
} from "ramda";
import * as muutEhdotHelper from "helpers/lukioMuutEhdot";
import * as oikeusSisaoppilaitosmuotoiseenKoulutukseeenHelper from "helpers/oikeusSisaoppilaitosmuotoiseenKoulutukseen";
import * as opetustaAntavatKunnatHelper from "helpers/opetustaAntavatKunnat";
import * as opiskelijamaaratHelper from "helpers/opiskelijamaarat";
import * as opetuskieletHelper from "helpers/opetuskielet";
import * as erityinenKoulutustehtavaHelper from "helpers/lukioErityisetKoulutustehtavat";
import { koulutustyypitMap } from "utils/constants";
import { createBeObjsForRajoitepoistot } from "helpers/rajoitteetHelper";
import { getRajoitteetByKohde } from "utils/rajoitteetUtils";

export async function createObjectToSave(
  locale,
  organisation,
  lupa,
  changeObjects,
  uuid,
  kohteet,
  maaraystyypit,
  language, // Luvan kieli
  alkupera = "KJ",
  muutospyynnonTila
) {
  const allAttachmentsRaw = [];
  const koulutustyyppi = koulutustyypitMap.LUKIO;

  // ... without tiedosto-property
  const allAttachments = map(attachment => {
    return dissoc("tiedosto", attachment);
  }, allAttachmentsRaw);

  const rajoitteetByRajoiteId = groupBy(
    compose(last, split("_"), nth(0), split("."), prop("anchor")),
    changeObjects.rajoitteet
  );

  // 1. KUNNAT, JOISSA OPETUSTA JÄRJESTETÄÄN
  const categoryFilterChangeObj =
    find(
      propEq("anchor", "toimintaalue.categoryFilter"),
      changeObjects.toimintaalue || []
    ) || {};

  const opetustaAntavatKunnat =
    await opetustaAntavatKunnatHelper.defineBackendChangeObjects(
      {
        quickFilterChanges: path(
          ["properties", "quickFilterChanges"],
          categoryFilterChangeObj
        ),
        changesByProvince: path(
          ["properties", "changesByProvince"],
          categoryFilterChangeObj
        ),
        lisatiedot: find(
          compose(includes(".lisatiedot."), prop("anchor")),
          changeObjects.toimintaalue || []
        ),
        ulkomaa: filter(
          compose(includes(".200."), prop("anchor")),
          changeObjects.toimintaalue || []
        ),
        rajoitteetByRajoiteId: getRajoitteetByKohde(
          "toimintaalue",
          rajoitteetByRajoiteId
        )
      },
      find(propEq("tunniste", "kunnatjoissaopetustajarjestetaan"), kohteet),
      maaraystyypit,
      lupa.maaraykset,
      kohteet,
      "kunnatjoissaopetustajarjestetaan"
    );

  // 2. OPETUSKIELET
  const opetuskielet = await opetuskieletHelper.defineBackendChangeObjects(
    {
      opetuskielet: changeObjects.opetuskielet,
      rajoitteetByRajoiteId: getRajoitteetByKohde(
        "opetuskielet",
        rajoitteetByRajoiteId
      )
    },
    find(propEq("tunniste", "opetuskieli"), kohteet),
    maaraystyypit,
    lupa.maaraykset,
    kohteet
  );

  // 3. OIKEUS SISÄOPPILAITOSMUOTOISEEN KOULUTUKSEEN
  const oikeusSisaoppilaitosmuotoiseenKoulutukseen =
    await oikeusSisaoppilaitosmuotoiseenKoulutukseeenHelper.defineBackendChangeObjects(
      {
        oikeusSisaoppilaitosmuotoiseenKoulutukseen:
          changeObjects.oikeusSisaoppilaitosmuotoiseenKoulutukseen,
        rajoitteetByRajoiteId: getRajoitteetByKohde(
          "oikeusSisaoppilaitosmuotoiseenKoulutukseen",
          rajoitteetByRajoiteId
        )
      },
      maaraystyypit,
      kohteet,
      lupa.maaraykset
    );

  // 4. ERITYINEN KOULUTUSTEHTÄVÄ
  const erityisetKoulutustehtavat =
    await erityinenKoulutustehtavaHelper.defineBackendChangeObjects(
      {
        erityisetKoulutustehtavat: changeObjects.erityisetKoulutustehtavat,
        rajoitteetByRajoiteId: getRajoitteetByKohde(
          "erityisetKoulutustehtavat",
          rajoitteetByRajoiteId
        )
      },
      maaraystyypit,
      lupa.maaraykset,
      locale,
      kohteet,
      find(propEq("tunniste", "erityinenkoulutustehtava"), kohteet)
    );

  // 5. VALTAKUNNALLINEN KEHITTÄMISTEHTÄVÄ
  const valtakunnallinenKehittamistehtava =
    await erityinenKoulutustehtavaHelper.defineBackendChangeObjects(
      {
        erityisetKoulutustehtavat:
          changeObjects.valtakunnallisetKehittamistehtavat,
        rajoitteetByRajoiteId: getRajoitteetByKohde(
          "valtakunnallisetKehittamistehtavat",
          rajoitteetByRajoiteId
        )
      },
      maaraystyypit,
      lupa.maaraykset,
      locale,
      kohteet,
      find(propEq("tunniste", "valtakunnallinenkehittamistehtava"), kohteet),
      true
    );

  // 6. OPPILAS-/OPISKELIJAMÄÄRÄT
  const opiskelijamaarat =
    await opiskelijamaaratHelper.defineBackendChangeObjects(
      {
        opiskelijamaarat: changeObjects.opiskelijamaarat,
        rajoitteetByRajoiteId: getRajoitteetByKohde(
          "opiskelijamaarat",
          rajoitteetByRajoiteId
        )
      },
      maaraystyypit,
      kohteet,
      koulutustyyppi
    );

  // 7. MUUT KOULUTUKSEN JÄRJESTÄMISEEN LIITTYVÄT EHDOT
  const muutEhdot = await muutEhdotHelper.defineBackendChangeObjects(
    {
      muutEhdot: changeObjects.muutEhdot,
      rajoitteetByRajoiteId: getRajoitteetByKohde(
        "muutEhdot",
        rajoitteetByRajoiteId
      )
    },
    maaraystyypit,
    lupa.maaraykset,
    locale,
    kohteet
  );

  // 8. ERITYISEN KOULUTUSTEHTÄVÄN RAHOITUS
  const erityisenKoulutustehtavanRahoitus =
    await erityinenKoulutustehtavaHelper.defineBackendChangeObjects(
      {
        erityisetKoulutustehtavat:
          changeObjects.erityisenKoulutustehtavanRahoitus,
        rajoitteetByRajoiteId: getRajoitteetByKohde(
          "erityisenKoulutustehtavanRahoitus",
          rajoitteetByRajoiteId
        )
      },
      maaraystyypit,
      lupa.maaraykset,
      locale,
      kohteet,
      find(propEq("tunniste", "erityisenkoulutustehtavanrahoitus"), kohteet)
    );

  /** Luodaan rajoitepoistoihin liittyvät Backend muutosobjektit */
  const rajoitepoistot = createBeObjsForRajoitepoistot(
    changeObjects.rajoitepoistot,
    lupa.maaraykset,
    kohteet,
    maaraystyypit
  );

  // Kaikki lomakkeelle tehdyt muutokset.
  const kaikkiMuutokset = flatten([
    erityisetKoulutustehtavat,
    erityisenKoulutustehtavanRahoitus,
    muutEhdot,
    oikeusSisaoppilaitosmuotoiseenKoulutukseen,
    opetuskielet,
    opetustaAntavatKunnat,
    opiskelijamaarat,
    valtakunnallinenKehittamistehtava,
    rajoitepoistot
  ]);

  let objectToSave = {
    alkupera,
    kieli: language,
    koulutustyyppi,
    jarjestajaOid: organisation.oid,
    jarjestajaYtunnus: organisation.ytunnus,
    luoja: sessionStorage.getItem("username"),
    // luontipvm: moment().valueOf(),
    luontipvm: moment().format("YYYY-MM-DD"),
    lupaUuid: lupa.uuid,
    // uuid: lupa.asiatyyppi.uuid,
    tila:
      muutospyynnonTila ||
      (alkupera === "ESITTELIJA" && uuid ? "VALMISTELUSSA" : "LUONNOS"),
    paivittaja: "string",
    paivityspvm: null,
    voimassaalkupvm: null,
    voimassaloppupvm: null,
    liitteet: allAttachments,
    meta: {},
    muutokset: kaikkiMuutokset,
    uuid
  };

  const asianumeroObj = find(
    propEq("anchor", "paatoksentiedot.asianumero.A"),
    changeObjects.paatoksentiedot || []
  );

  objectToSave.asianumero = asianumeroObj ? asianumeroObj.properties.value : "";

  const diaarinumeroObj = find(
    propEq("anchor", "paatoksentiedot.diaarinumero.A"),
    changeObjects.paatoksentiedot || []
  );

  objectToSave.diaarinumero = diaarinumeroObj
    ? diaarinumeroObj.properties.value
    : "";

  const paatospaivaObj = find(
    propEq("anchor", "paatoksentiedot.paatospaiva.A"),
    changeObjects.paatoksentiedot || []
  );

  objectToSave.paatospvm =
    paatospaivaObj && paatospaivaObj.properties.value
      ? moment(paatospaivaObj.properties.value).format("YYYY-MM-DD")
      : "";
  const voimaantulopaivaObj = find(
    propEq("anchor", "paatoksentiedot.voimaantulopaiva.A"),
    changeObjects.paatoksentiedot || []
  );
  objectToSave.voimassaalkupvm =
    voimaantulopaivaObj && voimaantulopaivaObj.properties.value
      ? moment(voimaantulopaivaObj.properties.value).format("YYYY-MM-DD")
      : "";
  const paattymispaivamaaraObj = find(
    propEq("anchor", "paatoksentiedot.paattymispaivamaara.A"),
    changeObjects.paatoksentiedot || []
  );
  objectToSave.voimassaloppupvm =
    paattymispaivamaaraObj && paattymispaivamaaraObj.properties.value
      ? moment(paattymispaivamaaraObj.properties.value).format("YYYY-MM-DD")
      : "";

  // This helps the frontend to initialize the first four fields on form load.
  objectToSave.meta.paatoksentiedot = changeObjects.paatoksentiedot;

  return objectToSave;
}
