import {
  compose,
  endsWith,
  find,
  flatten,
  map,
  mapObjIndexed,
  prop,
  propEq,
  values
} from "ramda";
import localForage from "localforage";
import { createAlimaarayksetBEObjects } from "helpers/rajoitteetHelper";
import {
  createMaarayksiaVastenLuodutRajoitteetBEObjects,
  getRajoitteetByValueStartsWith
} from "utils/rajoitteetUtils";
import { getMaarayksetByTunniste } from "helpers/lupa";
import { luoMuutosobjektitLisatietokentasta } from "helpers/lisatiedot";
import { PaikallisenTietovarastonAvain } from "enums";

export const defineBackendChangeObjects = async (
  changeObjects = {},
  maaraystyypit,
  kohteet,
  lupaMaaraykset
) => {
  const { rajoitteetByRajoiteId } = changeObjects;

  const oikeusSisaoppilaitosmuotoiseenKoulutukseen = await localForage.getItem(
    PaikallisenTietovarastonAvain.OIKEUS_SISAOPPILAITOSMUOTOISEEN_KOULUTUKSEEN
  );

  const kohde = find(
    propEq("tunniste", "sisaoppilaitosmuotoinenkoulutus"),
    kohteet
  );
  const maaraystyyppi = find(propEq("tunniste", "OIKEUS"), maaraystyypit);

  const maaraykset = await getMaarayksetByTunniste(
    kohde.tunniste,
    lupaMaaraykset
  );

  const oikeusSisaoppilaitosmuotoiseenKoulutukseenChangeObjs = map(oikeus => {
    const rajoitteetByRajoiteIdAndKoodiarvo = getRajoitteetByValueStartsWith(
      `${oikeus.koodiarvo}-`,
      rajoitteetByRajoiteId
    );
    const maarays = find(propEq("koodiarvo", oikeus.koodiarvo), maaraykset);
    const changeObj = find(
      compose(endsWith(`${oikeus.koodiarvo}.valinta`), prop("anchor")),
      changeObjects.oikeusSisaoppilaitosmuotoiseenKoulutukseen
    );
    const kuvausChangeObj = find(
      compose(endsWith(`${oikeus.koodiarvo}.kuvaus.A`), prop("anchor")),
      changeObjects.oikeusSisaoppilaitosmuotoiseenKoulutukseen
    );

    const muutosobjekti =
      (changeObj && changeObj.properties?.isChecked) ||
      (maarays && !changeObj && kuvausChangeObj)
        ? {
            generatedId: `oikeus-${Math.random()}`,
            kohde,
            koodiarvo: oikeus.koodiarvo,
            koodisto: oikeus.koodisto.koodistoUri,
            maaraystyyppi,
            meta: {
              changeObjects: [
                changeObjects.oikeusSisaoppilaitosmuotoiseenKoulutukseen,
                values(rajoitteetByRajoiteIdAndKoodiarvo)
              ].filter(Boolean),
              kuvaus: kuvausChangeObj?.properties.value
            },
            tila: "LISAYS"
          }
        : null;

    const poistoobjekti =
      maarays && (changeObj || kuvausChangeObj)
        ? {
            kohde,
            koodiarvo: oikeus.koodiarvo,
            koodisto: oikeus.koodisto.koodistoUri,
            maaraystyyppi,
            meta: {
              changeObjects: [
                changeObjects.oikeusSisaoppilaitosmuotoiseenKoulutukseen,
                values(rajoitteetByRajoiteIdAndKoodiarvo)
              ].filter(Boolean),
              kuvaus: maarays.meta?.kuvaus
            },
            maaraysUuid: maarays.uuid,
            tila: "POISTO"
          }
        : null;

    // Muodostetaan tehdyistä rajoittuksista objektit backendiä varten.
    // Linkitetään ensimmäinen rajoitteen osa yllä luotuun muutokseen ja
    // loput toisiinsa "alenevassa polvessa".
    const alimaaraykset = muutosobjekti
      ? values(
          mapObjIndexed(asetukset => {
            return createAlimaarayksetBEObjects(
              kohteet,
              maaraystyypit,
              muutosobjekti,
              asetukset
            );
          }, rajoitteetByRajoiteIdAndKoodiarvo)
        )
      : null;

    return [muutosobjekti, poistoobjekti, alimaaraykset];
  }, oikeusSisaoppilaitosmuotoiseenKoulutukseen).filter(Boolean);

  const maarayksiaVastenLuodutRajoitteet =
    createMaarayksiaVastenLuodutRajoitteetBEObjects(
      maaraykset,
      rajoitteetByRajoiteId,
      kohteet,
      maaraystyypit,
      kohde
    );

  const lisatietomuutokset = await luoMuutosobjektitLisatietokentasta(
    kohde,
    changeObjects.oikeusSisaoppilaitosmuotoiseenKoulutukseen,
    maaraystyyppi
  );

  return flatten([
    oikeusSisaoppilaitosmuotoiseenKoulutukseenChangeObjs,
    lisatietomuutokset,
    maarayksiaVastenLuodutRajoitteet
  ]).filter(Boolean);
};
