import { Categories } from "utils/lomakkeet";
import { isAdded, isRemoved } from "css/label";
import { __ } from "i18n-for-browser";

const labelStyles = {
  addition: isAdded,
  removal: isRemoved
};

export function getOpetuskieletEsivalinnat(
  data: Record<string, unknown>
): Categories {
  return [
    {
      anchor: "esivalinnat",
      categories: [
        {
          anchor: "noudatetaan",
          components: [
            {
              anchor: "A",
              name: "RadioButtonWithLabel",
              properties: {
                isChecked: !data.isOpetuskieletMaaraykset,
                labelStyles,
                title: __("tuva.noudatetaanOpetuskielia")
              }
            }
          ],
          layout: { indentation: "none" }
        },
        {
          anchor: "ei-noudateta",
          components: [
            {
              anchor: "A",
              name: "RadioButtonWithLabel",
              properties: {
                isChecked: data.isOpetuskieletMaaraykset,
                labelStyles,
                title: __("tuva.opetuskieletMaaritetaanErikseen")
              }
            }
          ],
          layout: { indentation: "none" }
        }
      ],
      layout: { indentation: "none" }
    }
  ];
}
