import { AppRoute } from "./app-routes";
import common from "../i18n/definitions/common";

export const sivukarttaRoutes = {
  categories: [
    {
      anchor: "Sivukartta",
      components: [
        {
          anchor: "Link",
          name: "SitemapLink",
          properties: {
            route: AppRoute.Sivukartta,
            text: common.sivukartta
          }
        }
      ]
    }
  ]
};
