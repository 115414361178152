import { Helmet } from "react-helmet";
import common from "../i18n/definitions/common";
import { useIntl } from "react-intl";
import SivupohjaA from "components/03-templates/SivupohjaA";
import { localizeRouteKey } from "utils/common";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import {
  AppRoute,
  koulutusmuotoRoutes,
  tilastotRoutes,
  yhteydenottoRoutes,
  sivukarttaRoutes,
  saavutettavuusselosteRoutes
} from "../routes";
import CategorizedListRoot from "../components/02-organisms/CategorizedListRoot";
import { Typography } from "@mui/material";
import { ReactElement } from "react";

const Sivukartta = (): ReactElement => {
  const intl = useIntl();
  const { formatMessage, locale } = intl;

  const pageTitle = formatMessage(common.sivukartta);

  const title = `${pageTitle} - Oiva`;
  const sivukarttaKategoriat = [
    koulutusmuotoRoutes,
    tilastotRoutes,
    yhteydenottoRoutes,
    saavutettavuusselosteRoutes,
    sivukarttaRoutes
  ];

  return (
    <SivupohjaA>
      <Helmet title={title}></Helmet>
      <BreadcrumbsItem to={localizeRouteKey(locale, AppRoute.Sivukartta, intl)}>
        {formatMessage(common.sivukartta)}
      </BreadcrumbsItem>
      <Typography component="h1" variant="h1">
        {pageTitle}
      </Typography>
      <CategorizedListRoot
        anchor="sitemap"
        changes={[]}
        categories={sivukarttaKategoriat}
        showCategoryTitles={false}
      />
    </SivupohjaA>
  );
};

export default Sivukartta;
