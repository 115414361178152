import common from "../../../i18n/definitions/common";
import { ReactElement } from "react";
import { useIntl } from "react-intl";

type InputProps = {
  totalCount: number;
  pageNo: number;
  pageSize: number;
};

const PageRowIndicator = ({
  totalCount,
  pageNo,
  pageSize
}: InputProps): ReactElement | null => {
  const intl = useIntl();
  if (totalCount < 1) {
    return null;
  }
  const from = pageNo === 1 ? pageNo : (pageNo - 1) * pageSize + 1;
  const to = pageNo * pageSize > totalCount ? totalCount : pageNo * pageSize;
  return (
    <div style={{ float: "right" }} className={"text-sm mb-3"}>
      {intl.formatMessage(common.rows)} {from} - {to} / {totalCount}
    </div>
  );
};

export default PageRowIndicator;
