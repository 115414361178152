import {
  append,
  assocPath,
  compose,
  concat,
  difference,
  dissocPath,
  equals,
  filter,
  find,
  findIndex,
  flatten,
  groupBy,
  includes,
  isEmpty,
  map,
  mapObjIndexed,
  merge,
  mergeAll,
  not,
  path,
  pathEq,
  prepend,
  prop,
  propEq,
  split,
  unnest,
  values
} from "ramda";
import { recursiveTreeShake } from "./common";
import { Anchor, AnchorBase, getAnchorBaseParts } from "utils/anchor";
import { Properties } from "./lomakkeet";

/**
 * Utility functions are listed here.
 * @namespace utils
 * @module Utils/muutokset
 * */

// Muutosobjektien ryhmittely
export const SAVED = "saved";
export const UNSAVED = "unsaved";

export type State = {
  changeObjects: {
    [SAVED]: Record<string, unknown>;
    [UNSAVED]: Record<string, unknown>;
  };
  focusOn?: string | null;
  isRestrictionDialogVisible?: boolean;
  latestTargetNode?: Record<string, unknown>;
  restrictionUnderModification?: string | null;
  validity?: Record<string, unknown>;
};

export type ChangeObject = {
  anchor: string;
  properties: Properties;
};

export type ChangeObjects = Array<ChangeObject>;

/**
 * Luo muutosobjektin
 */
export function createChangeObject(
  anchor: Anchor,
  properties: Properties
): ChangeObject {
  return {
    anchor,
    properties
  };
}

/**
 * Palauttaa kaikki tallennetut muutosobjektit.
 * @param state Rakenne, jossa tallennetut muutosobjektit sijaitsevat
 */
export function getSavedChangeObjects(state: State): Record<string, unknown> {
  return prop(SAVED, state.changeObjects);
}

export function getUnsavedChangeObjects(state: State): Record<string, unknown> {
  return prop(UNSAVED, state.changeObjects);
}

export function getChangeObjectWithoutDeprecationFlag(
  changeObj: ChangeObject
): ChangeObject {
  return dissocPath(["properties", "isDeprecated"], changeObj);
}

/**
 * Etsii muutosobjektin annetulla ankkurilla ja palauttaa sen sijainnin
 * muutosobjektien joukossa.
 * @param anchor Ankkuri.
 * @param changeObjects Muutosobjektit.
 * @returns Muutosobjektin sijainti.
 */
export const getChangeObjIndexByAnchor = (
  anchor: Anchor,
  changeObjects: ChangeObjects
): number => {
  return findIndex(propEq("anchor", anchor), changeObjects);
};

/**
 * Etsii muutosobjektin annetulla ankkurilla ja palauttaa sen.
 * @param anchor Ankkuri.
 * @param changeObjects Muutosobjektit.
 * @returns Muutosobjekti.
 */
export const getChangeObjByAnchor = (
  anchor: Anchor,
  changeObjects: ChangeObjects
): ChangeObject | undefined => {
  return find(propEq("anchor", anchor), changeObjects);
};

/**
 * Palauttaa muutosobjektit, joiden properties-objekti sisältää
 * properties-parametrin mukaiset arvot.
 * @param properties Objekti, jonka sisältöä verrataan muutosobjektien
 * properties-objektien sisältöön.
 * @param objectToSearch Objekti, joka käydään läpi rekursiivisesti
 * muutosobjekteja etsien.
 */
export function getChangeObjectsByProperties(
  properties: Properties,
  objectToSearch: Record<string, unknown>
): ChangeObjects {
  return flatten(
    values(
      mapObjIndexed(value => {
        if (Array.isArray(value)) {
          // Muutosobjekteja löytynyt
          return filter(changeObj => {
            const resultObj = mapObjIndexed((value, key) => {
              return pathEq(["properties", key], value, changeObj);
            }, properties);

            const isChangeObjectAMatch = !includes(false, values(resultObj));
            return isChangeObjectAMatch;
          }, value);
        } else {
          return getChangeObjectsByProperties(
            properties,
            value as Record<string, unknown>
          );
        }
      }, objectToSearch)
    )
  );
}

/**
 * Asettaa muutosobjektit parametrina annetun ankkurin perusteella
 * laskettavaan polkuun.
 * @param changeObjects Muutosobjektit, jotka on määrä asettaa osaksi tilaobjektia.
 * @param anchorBase Määrittää polun, jonka päähän muutosobjektit on määrä asettaa.
 * @param state Tilaobjekti.
 * @param pathRoot Määrittää sen, ollaanko muutosobjekteja asettamassa tallennetuiksi
 * vai tallentamattomiksi.
 */
export function setChangeObjects(
  changeObjects: ChangeObjects,
  anchorBase: AnchorBase,
  state: State,
  pathRoot: string = UNSAVED
): State {
  let nextState = { ...state };
  const sectionParts = split("_", anchorBase);
  const fullSectionPath = prepend(
    "changeObjects",
    prepend(pathRoot, sectionParts)
  );
  // Mikäli ollaan asettamassa tallentamattomia muutoksia,
  // on muutosobjektit käytävä läpi ja selvitettävä miten
  // kukin muutosobjekti tulee tilaan tallentaa.
  if (pathRoot === UNSAVED) {
    const savedChangeObjects = getChangeObjectsFromPath(
      anchorBase,
      state,
      SAVED,
      true
    );
    const savedChangeObjectsWithoutDeprecationFlags = map(
      getChangeObjectWithoutDeprecationFlag,
      savedChangeObjects
    );
    const unsavedChangeObjects = difference(
      changeObjects,
      savedChangeObjectsWithoutDeprecationFlags
    );

    const updatedSavedChangeObjects: ChangeObjects = map(changeObj => {
      // Selvitetään muutosobjektin ankkuria hyödyntäen
      // tuleeko muutosobjekti asettaa poistettavaksi.
      const unsavedChangeObj = find(
        propEq("anchor", changeObj.anchor),
        changeObjects
      );
      const savedChangeObjWithoutDeprecationFlag =
        getChangeObjectWithoutDeprecationFlag(changeObj);
      if (
        !isChangeObjectDeprecated(changeObj) &&
        (!unsavedChangeObj || !equals(unsavedChangeObj, changeObj))
      ) {
        // Tässä tilanteessa setChangeObjects-funktiolle parametrina
        // annettujen muutosobjektien joukosta ei löytynyt vastaavaa
        // tallennettua muutosobjektia, joten tallennettu muutosobjekti
        // on asetettava poistettavaksi.
        return assocPath(["properties", "isDeprecated"], true, changeObj);
      } else if (
        equals(savedChangeObjWithoutDeprecationFlag, unsavedChangeObj)
      ) {
        // Poistetaan isDeprecated-tieto, mikäli tallennettava muutosobjekti
        // on identtinen jo tallennetun kanssa.
        return dissocPath(["properties", "isDeprecated"], changeObj);
      }
      return changeObj;
    }, savedChangeObjects);
    nextState = assocPath(fullSectionPath, unsavedChangeObjects, nextState);
    nextState = setChangeObjects(
      updatedSavedChangeObjects,
      anchorBase,
      nextState,
      SAVED
    );
  } else if (pathRoot === SAVED) {
    nextState = assocPath(fullSectionPath, changeObjects, state);
  }
  /**
   * Ravistetaan muutosten puusta tyhjät objektit pois.
   **/
  return recursiveTreeShake(fullSectionPath, nextState) as State;
}

/**
 * Asettaa kaikki tallentamattomat muutosobjektit tallennetuiksi.
 * @param state tilaobjekti
 */
export function saveUnsavedChangeObjects(state: State): State {
  const changeObjectsToSave = prop(UNSAVED, state.changeObjects);
  const savedChangeObjects = getSavedChangeObjects(state);
  const nextSavedChangeObjects = merge(savedChangeObjects, changeObjectsToSave);
  let nextState = assocPath(
    ["changeObjects", SAVED],
    nextSavedChangeObjects,
    state
  );
  nextState = assocPath(["changeObjects", UNSAVED], {}, nextState);
  return nextState;
}

/**
 * Tarkistaa, onko muutosobjekti tallennettu.
 * @param anchor
 * @param state
 */
export function isChangeObjectSaved(anchor: Anchor, state: State): boolean {
  const sectionParts = getAnchorBaseParts(anchor);
  return !!find(
    propEq("anchor", anchor),
    path(unnest([SAVED, sectionParts]), state.changeObjects) || []
  );
}

export function isChangeObjectUnsaved(anchor: Anchor, state: State): boolean {
  const sectionParts = getAnchorBaseParts(anchor);
  return !!path(unnest([UNSAVED, sectionParts]), state.changeObjects);
}

export function isChangeObjectDeprecated(changeObj: ChangeObject): boolean {
  return isPropertiesObjectDeprecated(changeObj.properties);
}

export function isPropertiesObjectDeprecated(
  properties: Properties = {}
): boolean {
  return !!properties.isDeprecated;
}

export function getChangeObjectsFromPath(
  anchor: Anchor,
  state: State,
  pathRoot = UNSAVED,
  includeDeprecatedChangeObjects = false
): ChangeObjects {
  const anchorBaseParts = getAnchorBaseParts(anchor);
  const changeObjects: ChangeObjects =
    path(unnest([pathRoot, anchorBaseParts]), state.changeObjects) || [];
  if (!includeDeprecatedChangeObjects) {
    return filter(compose(not, isChangeObjectDeprecated), changeObjects);
  }
  return changeObjects;
}

export function getChangeObjectFromPath(
  anchor: Anchor,
  state: State,
  pathRoot: string = UNSAVED,
  includeDeprecated = false
): ChangeObject | undefined {
  const changeObjects = getChangeObjectsFromPath(
    anchor,
    state,
    pathRoot,
    includeDeprecated
  );
  return find(propEq("anchor", anchor), changeObjects);
}

/**
 * Muodostaa objektin, josta käy ilmi, mitkä ovat annetun ankkurin
 * mukaisen muutosobjektin nykyiset tilat (saved, underRemoval, unsaved).
 * @param anchor
 * @param state
 */
export function getTheStatesOfTheChangeObject(
  anchor: Anchor,
  state: State
): {
  isSaved: boolean;
  isDeprecated: boolean;
} {
  const changeObj = getChangeObjectFromPath(anchor, state, SAVED, true);
  return {
    isSaved: !!changeObj,
    isDeprecated: !!(changeObj && isChangeObjectDeprecated(changeObj))
  };
}

export function addIsDeprecatedFlag(anchor: Anchor, state: State): State {
  const sectionParts = getAnchorBaseParts(anchor);
  const savedChangeObjects = getSavedChangeObjects(state);
  const savedChangeObjectsOfTheGivenPath: ChangeObjects =
    path(sectionParts, savedChangeObjects) || [];
  const updatedChangeObjects = map(changeObj => {
    if (equals(changeObj.anchor, anchor)) {
      return assocPath(["properties", "isDeprecated"], true, changeObj);
    }
    return changeObj;
  }, savedChangeObjectsOfTheGivenPath);
  return assocPath(
    unnest(["changeObjects", SAVED, sectionParts]),
    updatedChangeObjects,
    state
  );
}

export function removeIsDeprecatedFlag(anchor: Anchor, state: State): State {
  const sectionParts = getAnchorBaseParts(anchor);
  const savedChangeObjects = getSavedChangeObjects(state);
  const savedChangeObjectsOfTheGivenPath: ChangeObjects =
    path(sectionParts, savedChangeObjects) || [];
  const updatedChangeObjects = map(changeObj => {
    if (equals(changeObj.anchor, anchor)) {
      return dissocPath(["properties", "isDeprecated"], changeObj);
    }
    return changeObj;
  }, savedChangeObjectsOfTheGivenPath);
  return assocPath(
    unnest(["changeObjects", SAVED, sectionParts]),
    updatedChangeObjects,
    state
  );
}

/**
 * Mikäli muutosobjekti on tallennettu, asettaa tämä funktio sen
 * poistettavaksi. Mikäli muutosobjektia ei olla tallennettu,
 * se poistetaan parametrinä annetusta objektista.
 * @param {String} anchor
 * @param {Object} state
 * @returns Päivitetty tilaobjekti.
 */
export function removeChangeObject(anchor: Anchor, state: State): State {
  let nextState = state;
  const currentStatesOfTheChangeObject = getTheStatesOfTheChangeObject(
    anchor,
    state
  );

  if (currentStatesOfTheChangeObject.isSaved) {
    if (currentStatesOfTheChangeObject.isDeprecated) {
      // Jos muutosobjekti on tallennettu ja asetettu poistettavaksi,
      // ei lisäoperaatioita tarvita. Tällöin palautetaan parametrina
      // annettu tila sellaisenaan.
      return state;
    } else {
      // Jos muutosobjekti on tallennettu, eikä sitä vielä ole asetettu
      // poistettavaksi, asetetaan se poistettavaksi ja palautetaan
      // päivitetty tila.
      //   const changeObj = getChangeObjectFromPath(anchor, state, SAVED);
      nextState = addIsDeprecatedFlag(anchor, state);
    }
  } else {
    // Tallentamaton muutosobjekti voidaan poistaa asettamatta sitä
    // poistettavien joukkoon. Poistamme vain objektin ja sillä hyvä.
    const sectionParts = getAnchorBaseParts(anchor);
    const changeObjects = getChangeObjectsFromPath(anchor, state);
    const changeObjectsWithoutTheRemovedOne = filter(
      compose(not, propEq("anchor", anchor)),
      changeObjects
    );
    nextState = assocPath(
      unnest(["changeObjects", UNSAVED, sectionParts]),
      changeObjectsWithoutTheRemovedOne,
      state
    );
    nextState = recursiveTreeShake(
      unnest(["changeObjects", UNSAVED, sectionParts]),
      nextState
    ) as State;
  }

  return nextState;
}

/**
 * Käy muutosobjektit rekursiivisesti läpi yhdistäen niiden properties-objektit siten,
 * että tallennetut arvot ylikirjoitetaan tarvittaessa tuoreilla muutoksilla. Vanhentuneet
 * muutosobjektit jätetään pois paluuarvosta.
 * @param changeObjectsByAnchor Muutosobjektit ankkureittain.
 * @param result
 * @param index
 * @returns
 */
function addToStructure(
  changeObjects: Array<ChangeObjects>,
  result = {},
  index = 0
): Record<string, ChangeObject[]> {
  if (!changeObjects[index]) {
    return result;
  }
  let updatedResult = result;
  const upToDateProperties =
    mergeAll(
      flatten(
        changeObjects[index].map((changeObj: ChangeObject) => {
          return changeObj.properties;
        })
      )
    ) || {};
  const firstChangeObj: ChangeObject = changeObjects[index][0];
  if (firstChangeObj && !isPropertiesObjectDeprecated(upToDateProperties)) {
    const anchor = firstChangeObj.anchor;
    const anchorBaseParts = getAnchorBaseParts(anchor);
    const currentChangeObjects: ChangeObjects | undefined =
      path(anchorBaseParts, result) || [];

    updatedResult = assocPath(
      anchorBaseParts,
      append({ anchor, properties: upToDateProperties }, currentChangeObjects),
      result
    );
  }
  if (changeObjects[index + 1]) {
    return addToStructure(changeObjects, updatedResult, index + 1);
  }
  return updatedResult;
}

/**
 * Etsii ja palauttaa muutosobjektit, joita ei ole merkitty vanhentuneiksi.
 * @returns Muutosobjektit, joita ei ole merkitty vanhentuneiksi.
 */
export function getNonDeprecatedChangeObjects(
  state: State,
  anchorBase: AnchorBase
): Record<string, unknown> {
  const savedChangeObjects: ChangeObjects = getChangeObjectsFromPath(
    anchorBase,
    state,
    SAVED
  );

  const unsavedChangeObjects = getChangeObjectsFromPath(anchorBase, state);

  const savedAndUnsavedChangeObjects =
    concat(savedChangeObjects, unsavedChangeObjects).filter(Boolean) || [];

  const savedAndUnsavedChangeObjectsGroupedByAnchor = groupBy(
    (changeObj: ChangeObject) => changeObj.anchor,
    savedAndUnsavedChangeObjects
  );

  const result = isEmpty(savedAndUnsavedChangeObjectsGroupedByAnchor)
    ? { [anchorBase]: [] }
    : addToStructure(values(savedAndUnsavedChangeObjectsGroupedByAnchor));

  return result;
}

/**
 * Poistaa isDeprecated-asetuksen muutosobjektilta, mikäli
 * muutosobjektilla sellainen on.
 * @param anchor
 * @param state
 */
export function keepChangeObject(anchor: Anchor, state: State): State {
  let nextState = state;

  const currentStatesOfTheChangeObject = getTheStatesOfTheChangeObject(
    anchor,
    state
  );

  if (
    currentStatesOfTheChangeObject.isSaved &&
    currentStatesOfTheChangeObject.isDeprecated
  ) {
    nextState = removeIsDeprecatedFlag(anchor, state);
  }

  return nextState;
}

/**
 * Ryhmittelee muutosobjektit niiden ankkurin mukaan.
 * @param changeObjects Taulukollinen muutosobjekteja.
 * @returns Ankkureittain ryhmitellyt muutosobjektit.
 */
export function groupChangeObjectsByAnchor(changeObjects: ChangeObjects): {
  [anchor: string]: ChangeObjects;
} {
  return groupBy(prop("anchor"), changeObjects);
}

/**
 * Poistaa muutosobjektien joukosta kaksoiskappaleet huomioiden sen, että
 * muutosobjekti voi olla merkattu vanhentuneeksi.
 * @param changeObjects Muutosobjektit.
 */
export function removeDuplicates(changeObjects: ChangeObjects): ChangeObjects {
  const anchors: Array<string> = [];
  return filter(changeObj => {
    if (!includes(changeObj.anchor, anchors)) {
      anchors.push(changeObj.anchor);
      return true;
    }
    return false;
  }, changeObjects);
}

/**
 * Yhdistää kaksi Properties-tyyppistä objektia siten, että jälkimmäisen parametrin
 * arvot ylikirjoittavat ensimmäisen parametrin arvot niiltä osin, kuin yhteneviä arvoja
 * on.
 * @param properties1 Properties-tyyppinen objekti.
 * @param properties2 Properties-tyyppinen objekti.
 * @returns Properties-tyyppinen objekti
 */
export function combineProperties(
  properties1: Properties,
  properties2: Properties
): Properties {
  return Object.assign({}, properties1, properties2);
}

/**
 * Etsii muutosobjektien joukosta muutosobjektin käyttäen parametrina annettua
 * ankkuria ja muokkaa muutosobjektia siten, että se sisältää nykyisten
 * asetusten lisäksi parametrina annetut asetukset. Huom! Parametrina annetut
 * asetukset ylikirjoittavat olemassa olevat asetukset asetuksen avaimien ollessa
 * yhteneväiset.
 * @param anchor Muutosobjektin ankkuri.
 * @param properties Asetukset, jotka muutosobjektille asetetaan.
 * @param changeObjects Taulukollinen muutosobjekteja.
 * @returns Taulukollinen muutosobjekteja.
 */
export function modifyChangeObjectInArray(
  anchor: Anchor,
  properties: Properties,
  changeObjects: ChangeObjects
): ChangeObjects {
  return map(changeObj => {
    return equals(changeObj.anchor, anchor)
      ? {
          ...changeObj,
          properties: combineProperties(changeObj.properties, properties)
        }
      : changeObj;
  }, changeObjects);
}

/**
 * Poistaa ankkurin perusteella löytämänsä muutosobjektin muiden muutosobjektien.
 * @param anchor Muutosobjektin ankkuri.
 * @param changeObjects Taulukollinen muutosobjekteja.
 * @returns Taulukollinen muutosobjekteja.
 */
export function deleteChangeObject(
  anchor: Anchor,
  changeObjects: ChangeObjects
): ChangeObjects {
  return filter(compose(not, propEq("anchor", anchor)), changeObjects);
}

/**
 * Filteröi taulukosta pois vanhaksi merkityt muutosobjektit.
 * @param changeObjects Taulukollinen muutosobjekteja.
 * @returns Taulukollinen muutosobjekteja.
 */
export function filterOutNonDeprecatedChangeObjects(
  changeObjects: ChangeObjects
): ChangeObjects {
  return filter(compose(not, isChangeObjectDeprecated), changeObjects);
}
