import { Option, Options } from "graphHandling/graphTypes";
import {
  assoc,
  compose,
  concat,
  dropLast,
  endsWith,
  find,
  includes,
  indexOf,
  isNil,
  mapObjIndexed,
  prop,
  reduce,
  reject,
  slice,
  unnest,
  values
} from "ramda";
import { ChangeObjects } from "utils/muutokset";
import { getRajoiteListamuodossa } from "utils/rajoitteetUtils";

export type RajoitteetListamuodossa = {
  [rajoiteId: string]: {
    options: Options;
    str: string;
  };
};

export const getRestrictionsInListFormat = (
  rajoitteet:
    | Record<string, { changeObjects: ChangeObjects }>
    | null
    | undefined,
  locale: string,
  koodiarvo?: string
): null | Record<
  string,
  (Record<"options", Options> & Omit<{ str: string }, "options">) | undefined
> => {
  let first = true;
  const rajoitteetListamuodossa = rajoitteet
    ? mapObjIndexed((rajoite, rajoiteId) => {
        const tarkenninChangeObject = find(
          compose(
            endsWith(".kohdennukset.0.rajoite.kohde.tarkennin.komponentti"),
            prop("anchor")
          ),
          rajoite.changeObjects
        );
        if (tarkenninChangeObject) {
          const options = tarkenninChangeObject.properties.value as Options;
          const rajoiteListamuodossa = getRajoiteListamuodossa(
            rajoite.changeObjects,
            locale,
            rajoiteId,
            "list",
            koodiarvo
          );
          const str = first
            ? dropLast(20, rajoiteListamuodossa)
            : slice(
                indexOf("<ul>", rajoiteListamuodossa),
                rajoiteListamuodossa.length,
                rajoiteListamuodossa
              );
          first = false;
          return assoc("options", unnest([options]), {
            str: str
          });
        }
      }, rajoitteet)
    : null;

  return rajoitteetListamuodossa;
};

export const getHtmlFormattedRestrictionByKoodiarvo = (
  koodiarvo: string,
  rajoitteetListamuodossa: RajoitteetListamuodossa
): string | undefined => {
  return reduce(
    concat,
    "",
    values(
      reject(
        isNil,
        mapObjIndexed((t, rajoiteId, rlm) => {
          if (rlm) {
            const results = find((option: Option) => {
              return includes(koodiarvo, option.value as string);
            }, rlm[rajoiteId].options as Options);
            if (results) {
              return rlm[rajoiteId].str;
            }
          }
          return null;
        }, rajoitteetListamuodossa)
      )
    )
  );
};
