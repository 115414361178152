import { CellImplementations } from "graphHandling/graphTypes";
import { fetch_kohteet } from "./cellImplementations/fetch_kohteet";
import { fetch_organisation } from "./cellImplementations/fetch_organisation";
import { fetch_tulevatLuvat } from "./cellImplementations/fetch_tulevatLuvat";
import { CellId } from "processes/CellId";
import { fetch_lupaByUuid } from "./cellImplementations/fetch_lupaByUuid";

export const processDefinition = {
  cells: {
    [CellId.INITIALIZE_PROCESS_JARJESTAJA]: {
      isFirstCell: true,
      name: "Järjestäjä",
      out: {
        [CellId.FETCH_KOHTEET]: {},
        [CellId.FETCH_LUPA_BY_UUID]: {}
      }
    },
    [CellId.FETCH_KOHTEET]: {
      name: "Nouda: Kohteet",
      out: {
        [CellId.TALLENNA_KOHTEET_PAIKALLISEEN_TIETOVARASTOON]: {}
      }
    },
    [CellId.FETCH_ORGANISATION]: {
      name: "Nouda: Organisaation tiedot",
      out: {
        [CellId.FETCH_TULEVAT_LUVAT]: {}
      }
    },
    [CellId.FETCH_TULEVAT_LUVAT]: {
      name: "Nouda: Tulevat luvat"
    },
    [CellId.FETCH_LUPA_BY_UUID]: {
      name: "Nouda: Lupa UUID:n perusteella",
      out: {
        [CellId.FETCH_ORGANISATION]: {}
      }
    }
  }
};

export const cellImplementations: CellImplementations = {
  [CellId.FETCH_KOHTEET]: fetch_kohteet,
  [CellId.FETCH_ORGANISATION]: fetch_organisation,
  [CellId.FETCH_LUPA_BY_UUID]: fetch_lupaByUuid,
  [CellId.FETCH_TULEVAT_LUVAT]: fetch_tulevatLuvat
};
