import { TietueProps } from "graphHandling/components/multiTextBox/MultiTextBox";
import { CellFn } from "graphHandling/graphTypes";
import { getCurrentValue } from "graphHandling/graphUtils";
import { assoc, isEmpty, isNil, mapObjIndexed, reject } from "ramda";
import { CellId } from "processes/CellId";
import common from "i18n/definitions/common";
import { IMultiTextBox } from "utils/lomakkeet";

const currentCellId = CellId.INITIALIZE_MULTI_TEXTBOX;

export const update_multiTextBox: CellFn = (storeFunctions, intl, payload) => {
  if (!intl) {
    return false;
  }

  const multiTextBox = storeFunctions.readPath([
    "components",
    CellId.INITIALIZE_MULTI_TEXTBOX
  ]) as IMultiTextBox;

  const nykyisetTietueet = getCurrentValue("tietueet", multiTextBox) as Record<
    string,
    TietueProps
  >;

  const nykyisetEiPoistetutTietueet = reject(
    isNil,
    mapObjIndexed(
      tietue => (tietue.isDeleted !== true ? tietue : null),
      nykyisetTietueet
    )
  );

  let tulevatTietueet = nykyisetEiPoistetutTietueet;

  if (multiTextBox) {
    if (payload) {
      const { deleteMe, id, value } = payload.changeProps;
      tulevatTietueet = reject(
        isNil,
        mapObjIndexed((tietue, tietueId) => {
          if (tietueId === id) {
            return deleteMe
              ? assoc("isDeleted", true, tietue)
              : assoc("value", value, tietue);
          }
          return tietue;
        }, nykyisetTietueet)
      ) as Record<string, TietueProps>;
    } else {
      if (isEmpty(nykyisetTietueet)) {
        const createdAt = new Date();
        const oletustietueenId = `multiTextBox-${Math.random()}`;
        // Jos lupa ei sisällä yhtään tietuetta, luodaan yksi tietue, jotta
        // käyttäjän valitessa ulkomaacheckboxin, hänen ei tarvitsisi heti
        // painaa Lisää-painiketta.
        tulevatTietueet = {
          [oletustietueenId]: {
            createdAt,
            id: oletustietueenId,
            placeholder: intl.formatMessage(common.kuvausPlaceholder),
            title: intl.formatMessage(common.kuvaus),
            value: ""
          }
        };
      }
    }
  }

  storeFunctions.addModification(currentCellId, {
    tietueet: tulevatTietueet
  });

  return true;
};
