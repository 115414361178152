import { memo, useMemo, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import common from "i18n/definitions/common";
import wizard from "i18n/definitions/wizard";
import Lomake from "components/02-organisms/Lomake";
import {
  useChangeObjects,
  useChangeObjectsByAnchorWithoutUnderRemoval
} from "stores/muutokset";
import equal from "react-fast-compare";
import {
  compose,
  concat,
  filter,
  find,
  flatten,
  isEmpty,
  not,
  omit,
  prop,
  propEq,
  values
} from "ramda";
import { getAnchorPart } from "utils/anchor";

const constants = {
  formLocation: ["taiteenPerusopetus", "opetustaAntavatKunnat"],
  mode: "modification"
};

const MuutKunnatJoistaOppilaita = memo(
  ({
    code,
    isPreviewModeOn,
    maaraykset,
    rajoitteet,
    sectionId,
    title,
    valtakunnallinenMaarays
  }) => {
    const intl = useIntl();
    const [changeObjects, { setChanges }] =
      useChangeObjectsByAnchorWithoutUnderRemoval({
        anchor: sectionId
      });
    const [, { createTextBoxChangeObject }] = useChangeObjects();

    const maakuntamaaraykset = filter(
      maarays => maarays.koodisto === "maakunta",
      maaraykset
    );

    const kuntamaaraykset = filter(maarays => {
      return maarays.koodisto === "kunta";
    }, maaraykset);

    const [isEditViewActive, toggleEditView] = useState(false);

    const fiCode = prop("koodiarvo", valtakunnallinenMaarays);

    const whenChanges = useCallback(
      changes => {
        const withoutCategoryFilterChangeObj = filter(
          compose(not, propEq("anchor", `${sectionId}.categoryFilter`)),
          changeObjects
        );

        const amountOfChanges = flatten(
          values(changes.changesByProvince)
        ).length;

        const changesToSet = concat(
          withoutCategoryFilterChangeObj,
          amountOfChanges
            ? [
                {
                  anchor: `${sectionId}.categoryFilter`,
                  properties: {
                    changesByProvince: changes.changesByProvince
                  }
                }
              ]
            : []
        );

        return setChanges(changesToSet, sectionId);
      },
      [changeObjects, sectionId, setChanges]
    );

    const provinceChanges = useMemo(() => {
      const changeObj = find(
        propEq("anchor", `${sectionId}.categoryFilter`),
        changeObjects
      );
      return changeObj ? changeObj.properties.changesByProvince : {};
    }, [changeObjects, sectionId]);

    const noSelectionsInLupa =
      isEmpty(maakuntamaaraykset) &&
      isEmpty(kuntamaaraykset) &&
      fiCode !== "FI1";

    const onAddButtonClick = useCallback(
      (fromComponent, index) => {
        createTextBoxChangeObject(
          sectionId,
          getAnchorPart(fromComponent.fullAnchor, 1),
          index
        );
      },
      [createTextBoxChangeObject, sectionId]
    );

    return (
      <Lomake
        mode={constants.mode}
        anchor={sectionId}
        changeObjects={changeObjects}
        code={code}
        data={{
          changeObjectsByProvince: Object.assign({}, provinceChanges),
          fiCode,
          isEditViewActive,
          isEiMaariteltyaToimintaaluettaChecked: fiCode === "FI2",
          isValtakunnallinenChecked: fiCode === "FI1",
          localizations: {
            accept: intl.formatMessage(common.accept),
            areaOfActionIsUndefined: intl.formatMessage(
              wizard.noMunicipalitiesSelected
            ),
            cancel: intl.formatMessage(common.cancel),
            currentAreaOfAction: intl.formatMessage(
              wizard.municipalitiesInPresentLupa
            ),
            newAreaOfAction: noSelectionsInLupa
              ? intl.formatMessage(wizard.municipalities)
              : intl.formatMessage(wizard.municipalitiesInNewLupa),
            ofMunicipalities: intl.formatMessage(wizard.ofMunicipalities),
            editButtonText: intl.formatMessage(wizard.selectMunicipalities),
            sameAsTheCurrentAreaOfAction: intl.formatMessage(
              wizard.sameAsTheCurrentAreaOfAction
            ),
            wholeCountryWithoutAhvenanmaa: intl.formatMessage(
              wizard.wholeCountryWithoutAhvenanmaa
            )
          },
          kuntamaaraykset,
          maakuntamaaraykset,
          maaraykset,
          rajoitteet,
          sectionId,
          valtakunnallinenMaarays
        }}
        formTitle={isPreviewModeOn ? title : undefined}
        functions={{
          onChanges: whenChanges,
          toggleEditView,
          onAddButtonClick
        }}
        isPreviewModeOn={isPreviewModeOn}
        isRowExpanded={false}
        path={constants.formLocation}
        rowTitle={intl.formatMessage(
          wizard.muutKunnatOppilaspaikkojenVaraaminen
        )}
        showCategoryTitles={true}
        titleLevel={isPreviewModeOn ? 4 : 2}
      />
    );
  },
  (cp, np) => {
    return equal(omit(["functions"], cp), omit(["functions"], np));
  }
);

MuutKunnatJoistaOppilaita.propTypes = {
  code: PropTypes.string,
  isPreviewModeOn: PropTypes.bool,
  kuntamaaraykset: PropTypes.array,
  maaraykset: PropTypes.array,
  rajoitteet: PropTypes.object,
  sectionId: PropTypes.string,
  title: PropTypes.string,
  valtakunnallinenMaarays: PropTypes.object
};

MuutKunnatJoistaOppilaita.displayName = "Muut kunnat, joista oppilaita.";

export default MuutKunnatJoistaOppilaita;
