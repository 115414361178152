import { ILabeledCheckbox, Properties } from "utils/lomakkeet";
import { ComponentType } from "graphHandling/components/componentType";
import { ComponentEvent } from "graphHandling/graphTypes";
import { ILabeledCheckboxProps } from "./LabeledCheckbox";

export function defineLabeledCheckbox(
  properties: ILabeledCheckboxProps,
  functions: {
    onChange: ComponentEvent;
  },
  modifications?: {
    backend?: Properties;
    frontend?: Array<Properties>;
  }
): ILabeledCheckbox {
  const definition = {
    name: ComponentType.LABELED_CHECKBOX,
    onChange: functions.onChange,
    properties
  };

  if (modifications) {
    return { ...definition, modifications };
  }

  return definition;
}
