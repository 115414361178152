import {
  concat,
  find,
  flatten,
  mapObjIndexed,
  path,
  propEq,
  values
} from "ramda";
import { luoMuutosobjektitLisatietokentasta } from "./lisatiedot";
import { ChangeObjects, getChangeObjByAnchor } from "utils/muutokset";
import { createAlimaarayksetBEObjects } from "helpers/rajoitteetHelper";
import { getLocalizedProperty } from "services/lomakkeet/utils";
import { getMaarayksetByTunniste } from "helpers/lupa";
import {
  createMaarayksiaVastenLuodutRajoitteetBEObjects,
  getRajoitteetByValue
} from "utils/rajoitteetUtils";
import { Locale, PaikallisenTietovarastonAvain, Toiminto } from "enums";
import { Muutos } from "types";
import { Kohde, Maarays, Maaraystyyppi } from "koodistodatanTyypit";
import { Lupamaarays } from "Lupa";
import { FKoodistotietue } from "koodistodatanTyypitFrontille";
import localForage from "localforage";

/**
 * Muodostaa ja palauttaa Backend-kelpoiset muutosobjektit.
 * @param changeObjects Taulukollinen muutosobjekteja.
 * @param rajoitteetByRajoiteId Rajoite-ID:n perusteella ryhmitellyt,
 * rajoitteita koskevat muutosobjektit.
 * @param kohde Kohde, jota muutokset koskevat.
 * @param maaraystyypit Taulukollinen määräystyyppejä.
 * @param lupamaaraykset Luvalla olevat määräykset.
 * @param locale fi | sv.
 * @param kohteet Taulukollinen kohteita.
 * @returns Taulukollinen backend-kelpoisia muutosobjekteja.
 */
export const defineBackendChangeObjects = async (
  changeObjects: ChangeObjects,
  rajoitteetByRajoiteId: Record<string, ChangeObjects>,
  maaraystyypit: Array<Maaraystyyppi>,
  lupamaaraykset: Array<Lupamaarays>,
  locale: Locale,
  kohteet: Array<Kohde>
): Promise<Muutos[]> => {
  const tunniste = "opetusjotalupakoskee";
  const kohde = find(propEq("tunniste", tunniste), kohteet);
  if (!kohde) {
    return [];
  }
  const maaraystyyppi = find(propEq("tunniste", "OIKEUS"), maaraystyypit);
  const maaraykset = await getMaarayksetByTunniste(tunniste, lupamaaraykset);
  const fKoodistotietueet: Array<FKoodistotietue> | null =
    await localForage.getItem(PaikallisenTietovarastonAvain.OPETUSTEHTAVAT);

  // Lisätietokentän muutosten käsittely
  const lisatietomuutokset = await luoMuutosobjektitLisatietokentasta(
    kohde,
    changeObjects
  );

  const opetusmuutokset = fKoodistotietueet
    ? fKoodistotietueet
        .map((opetustehtava: FKoodistotietue) => {
          const rajoitteetByRajoiteIdAndKoodiarvo: any = getRajoitteetByValue(
            opetustehtava.koodiarvo,
            rajoitteetByRajoiteId
          );

          const opetustehtavaAnchor = `opetustehtavat.opetustehtava.${opetustehtava.koodiarvo}`;
          const changeObj = getChangeObjByAnchor(
            opetustehtavaAnchor,
            changeObjects
          );

          const maarays: Maarays | undefined = find(
            propEq("koodiarvo", opetustehtava.koodiarvo),
            maaraykset
          );

          // Muodostetaan muutosobjekti, mikäli käyttöliittymässä on tehty
          // kohtaan muutoksia.
          if (changeObj) {
            const tila = path(["properties", "isChecked"], changeObj)
              ? Toiminto.LISAYS
              : Toiminto.POISTO;
            const muutosId = `opetustehtava-${Math.random()}`;
            const muutosobjekti = {
              generatedId: muutosId,
              kohde,
              koodiarvo: opetustehtava.koodiarvo,
              koodisto: opetustehtava.koodisto.koodistoUri,
              kuvaus: getLocalizedProperty(
                opetustehtava.metadata,
                locale,
                "kuvaus"
              ),
              maaraystyyppi,
              meta: {
                changeObjects: concat(
                  [changeObj],
                  values(rajoitteetByRajoiteIdAndKoodiarvo)
                )
              },
              tila,
              maaraysUuid:
                tila === Toiminto.POISTO && maarays ? maarays.uuid : null
            };

            // Muodostetaan tehdyistä rajoituksista objektit backendiä varten.
            // Linkitetään ensimmäinen rajoitteen osa yllä luotuun muutokseen ja
            // loput toisiinsa "alenevassa polvessa".
            let alimaaraykset = [];
            if (rajoitteetByRajoiteIdAndKoodiarvo) {
              alimaaraykset = values(
                mapObjIndexed((asetukset: ChangeObjects) => {
                  return asetukset.length
                    ? createAlimaarayksetBEObjects(
                        kohteet,
                        maaraystyypit,
                        muutosobjekti,
                        asetukset
                      )
                    : [];
                }, rajoitteetByRajoiteIdAndKoodiarvo)
              );
            }

            return [muutosobjekti, alimaaraykset];
          } else {
            return false;
          }
        })
        .filter(Boolean)
    : [];

  // Luodaan vielä alimääräykset rajoitteille, jotka on kytketty olemassa
  // oleviin määräyksiin.
  const maarayksiaVastenLuodutRajoitteet =
    createMaarayksiaVastenLuodutRajoitteetBEObjects(
      maaraykset,
      rajoitteetByRajoiteId,
      kohteet,
      maaraystyypit,
      kohde
    );

  return flatten([
    maarayksiaVastenLuodutRajoitteet,
    opetusmuutokset,
    lisatietomuutokset
  ]).filter(Boolean);
};
