import { ReactElement, useCallback } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ComponentEvent } from "graphHandling/graphTypes";

export interface ILabeledCheckboxProps {
  id: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  isIndeterminate?: boolean;
  isReadOnly?: boolean;
  label: string;
  name?: string;
  value: string;
}

interface ILabeledCheckboxPropsAndEvents extends ILabeledCheckboxProps {
  onChange: ComponentEvent;
}

const defaultProps = {
  isChecked: false,
  isDisabled: false,
  isIndeterminate: false,
  isReadOnly: false
};

const LabeledCheckbox = ({
  id,
  label,
  isChecked = defaultProps.isChecked,
  isDisabled = defaultProps.isDisabled,
  isIndeterminate = defaultProps.isIndeterminate,
  isReadOnly = defaultProps.isReadOnly,
  onChange,
  value
}: ILabeledCheckboxPropsAndEvents): ReactElement => {
  const styles = makeStyles({
    root: {
      color: "#666666",
      "&$checked": {
        color: "#42A047"
      },
      padding: 6
    },
    checked: {} // This object must be empty for checked color to work.
  })();

  const handleChanges = useCallback(() => {
    onChange({
      changeProps: {
        id,
        isChecked: !isChecked
      }
    });
  }, [id, isChecked, onChange]);

  return (
    <FormGroup row>
      <FormControlLabel
        disabled={isDisabled}
        control={
          <Checkbox
            checked={isChecked}
            id={id}
            indeterminate={isIndeterminate}
            value={value}
            onChange={handleChanges}
            readOnly={isReadOnly}
            classes={{
              checked: styles.checked,
              root: styles.root
            }}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};

LabeledCheckbox.displayName = "LabeledCheckbox";

export default LabeledCheckbox;
