import { Fragment, ReactElement } from "react";
import Select, {
  components,
  DropdownIndicatorProps,
  MultiValue,
  SingleValue
} from "react-select";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import InputLabel from "@mui/material/InputLabel";
import { useIntl } from "react-intl";
import commonMessages from "i18n/definitions/common";
import { EventPayload, Option, Options } from "graphHandling/graphTypes";

export interface AutocompleteProps {
  closeMenuOnSelect?: boolean;
  hideSelectedOptions?: boolean;
  inputId?: string;
  isNotInitial?: boolean;
  isDisabled?: boolean;
  isMenuOpen?: boolean;
  isMulti?: boolean;
  isPreviewModeOn?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  isSearchable?: boolean;
  isSearchIndicatorVisible?: boolean;
  isValid?: boolean;
  isVisible?: boolean;
  name?: string;
  options?: Options;
  placeholder?: string;
  values: Options;
  title?: string;
}

interface AutocompletePropsAndEvents extends AutocompleteProps {
  onChange: (payload: EventPayload) => unknown;
  onInputChange?: (payload: EventPayload) => unknown;
}

const Autocomplete = (
  props: AutocompletePropsAndEvents
): ReactElement | null => {
  const intl = useIntl();

  const handleSelectChange = (
    newValue: MultiValue<Option> | SingleValue<Option>
  ): void => {
    props.onChange({
      changeProps: {
        searchStr: "",
        values: Array.isArray(newValue) ? newValue : [newValue]
      }
    });
  };

  const DropdownIndicator = (props: DropdownIndicatorProps<Option>) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownIcon />
      </components.DropdownIndicator>
    );
  };

  const SearchIndicator = (props: DropdownIndicatorProps<Option>) => {
    return (
      <components.DropdownIndicator {...props}>
        <SearchIcon />
      </components.DropdownIndicator>
    );
  };

  const onInputChange = (newValue: string) => {
    if (props.isSearchable && props.onInputChange && newValue !== "") {
      props.onInputChange({
        changeProps: {
          searchStr: newValue,
          values: props.values
        }
      });
    }
  };

  return (
    <Fragment>
      {props.title && (
        <InputLabel required={props.isRequired}>{props.title}</InputLabel>
      )}
      {
        <Select
          className="z-100"
          classNamePrefix="ac"
          closeMenuOnSelect={props.closeMenuOnSelect}
          components={{
            DropdownIndicator: props.isSearchIndicatorVisible
              ? SearchIndicator
              : DropdownIndicator
          }}
          name={props.name}
          hideSelectedOptions={props.hideSelectedOptions}
          inputId={props.inputId}
          isDisabled={props.isDisabled}
          isMulti={props.isMulti}
          isSearchable={props.isSearchable}
          noOptionsMessage={() =>
            intl.formatMessage(commonMessages.eiValittaviaKohteita)
          }
          onChange={handleSelectChange}
          onInputChange={onInputChange}
          openMenuOnClick={true}
          openMenuOnFocus={true}
          options={props.options}
          placeholder={
            props.placeholder
              ? props.placeholder
              : intl.formatMessage(commonMessages.autocompleteValitse)
          }
          value={props.values}
        />
      }
    </Fragment>
  );
};

Autocomplete.defaultProps = {
  closeMenuOnSelect: false,
  hideSelectedOptions: true,
  inputId: `autocompleteInput-${Math.random()}`,
  isNotInitial: false,
  isDisabled: false,
  isMulti: false,
  isPreviewModeOn: false,
  isReadOnly: false,
  isRequired: false,
  isSearchable: false,
  isSearchIndicatorVisible: false,
  isValid: true,
  isVisible: true,
  name: "autocomplete",
  options: [],
  placeholder: "",
  title: "",
  values: []
};

Autocomplete.displayName = "Autocomplete";

export default Autocomplete;
