import { CellImplementations } from "graphHandling/graphTypes";
import {
  fetch_opetustehtavatKoodisto,
  fetch_poErityisetKoulutustehtavatKoodisto,
  fetch_poMuutEhdotKoodisto
} from "graphs/fetch/fetch_koodisto";
import { fetch_opetustehtavat } from "graphs/fetch/fetch_opetustehtavat";
import {
  kasittele_opetustehtavat,
  kasittele_opetustehtavatKoodisto,
  kasittele_poErityisetKoulutustehtavatKoodisto,
  kasittele_poMuutEhdotKoodisto
} from "graphs/kasittele_koodisto";
import {
  tallenna_opetustehtavatKoodistoPaikalliseenTietovarastoon,
  tallenna_opetustehtavatPaikalliseenTietovarastoon,
  tallenna_poErityisetKoulutustehtavatKoodistoPaikalliseenTietovarastoon,
  tallenna_poMuutEhdotKoodistoPaikalliseenTietovarastoon
} from "graphs/muutospyynto/cellImplementations/tallenna_paikalliseenTietovarastoon";
import { kasittele_kieletOPH } from "graphs/kasittele_koodisto";
import { fetch_kieletOPH } from "graphs/fetch/fetch_kieletOPH";
import { CellId } from "processes/CellId";

export const processDefinition = {
  cells: {
    [CellId.INITIALIZE_PROCESS_JARJESTAMISLUPA_HTML]: {
      isFirstCell: true,
      name: "Initialize: JärjestämislupaHtml",
      out: {
        [CellId.FETCH_KIELET_OPH]: {},
        [CellId.FETCH_KOODISTO_OPETUSTEHTAVAT]: {},
        [CellId.FETCH_KOODISTO_PO_ERITYISET_KOULUTUSTEHTAVAT]: {},
        [CellId.FETCH_KOODISTO_PO_MUUT_EHDOT]: {},
        [CellId.FETCH_OPETUSTEHTAVAT]: {}
      }
    },
    [CellId.FETCH_KIELET_OPH]: {
      name: "Nouda: Kielet (OPH)",
      out: {
        [CellId.KASITTELE_KIELET_OPH]: {}
      }
    },
    [CellId.FETCH_KOODISTO_OPETUSTEHTAVAT]: {
      name: "Nouda: Opetustehtavat-koodisto",
      out: {
        [CellId.KASITTELE_KOODISTO_OPETUSTEHTAVAT]: {}
      }
    },
    [CellId.FETCH_KOODISTO_PO_ERITYISET_KOULUTUSTEHTAVAT]: {
      name: "Nouda: PO - Erityiset koulutustehtävät -koodisto",
      out: {
        [CellId.KASITTELE_KOODISTO_PO_ERITYISET_KOULUTUSTEHTAVAT]: {}
      }
    },
    [CellId.FETCH_KOODISTO_PO_MUUT_EHDOT]: {
      name: "Nouda: PO - Muut ehdot -koodisto",
      out: {
        [CellId.KASITTELE_KOODISTO_PO_MUUT_EHDOT]: {}
      }
    },
    [CellId.FETCH_OPETUSTEHTAVAT]: {
      name: "Nouda: Opetustehtavat",
      out: {
        [CellId.KASITTELE_OPETUSTEHTAVAT]: {}
      }
    },
    [CellId.KASITTELE_KIELET_OPH]: {
      name: "Käsittele: Kielet (OPH)"
    },
    [CellId.KASITTELE_KOODISTO_OPETUSTEHTAVAT]: {
      name: "Käsittele: Opetustehtävät-koodisto",
      out: {
        [CellId.TALLENNA_OPETUSTEHTAVAT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]:
          {}
      }
    },
    [CellId.KASITTELE_KOODISTO_PO_ERITYISET_KOULUTUSTEHTAVAT]: {
      name: "Koodisto: Muunnetaan helpommin käsiteltävään muotoon",
      out: {
        [CellId.TALLENNA_PO_ERITYISET_KOULUTUSTEHTAVAT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]:
          {}
      }
    },
    [CellId.KASITTELE_KOODISTO_PO_MUUT_EHDOT]: {
      name: "Koodisto: Muunnetaan helpommin käsiteltävään muotoon",
      out: {
        [CellId.TALLENNA_PO_MUUT_EHDOT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]: {}
      }
    },
    [CellId.KASITTELE_OPETUSTEHTAVAT]: {
      name: "Käsittele: Opetustehtävät",
      out: {
        [CellId.TALLENNA_OPETUSTEHTAVAT_PAIKALLISEEN_TIETOVARASTOON]: {}
      }
    },
    [CellId.TALLENNA_OPETUSTEHTAVAT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]: {
      name: "Tallenna: Opetustehtavat-koodisto paikalliseen tietovarastoon"
    },
    [CellId.TALLENNA_OPETUSTEHTAVAT_PAIKALLISEEN_TIETOVARASTOON]: {
      name: "Tallenna: Opetustehtavat paikalliseen tietovarastoon"
    },
    [CellId.TALLENNA_PO_ERITYISET_KOULUTUSTEHTAVAT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]:
      {
        name: "Tallenna: poErityisetKoulutustehtavat-koodisto paikalliseen tietovarastoon"
      },
    [CellId.TALLENNA_PO_MUUT_EHDOT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]: {
      name: "Tallenna: poMuutEhdot-koodisto paikalliseen tietovarastoon"
    }
  }
};

export const cellImplementations: CellImplementations = {
  [CellId.FETCH_KIELET_OPH]: fetch_kieletOPH,
  [CellId.FETCH_KOODISTO_OPETUSTEHTAVAT]: fetch_opetustehtavatKoodisto,
  [CellId.FETCH_KOODISTO_PO_ERITYISET_KOULUTUSTEHTAVAT]:
    fetch_poErityisetKoulutustehtavatKoodisto,
  [CellId.FETCH_OPETUSTEHTAVAT]: fetch_opetustehtavat,
  [CellId.FETCH_KOODISTO_PO_MUUT_EHDOT]: fetch_poMuutEhdotKoodisto,
  [CellId.KASITTELE_KIELET_OPH]: kasittele_kieletOPH,
  [CellId.KASITTELE_KOODISTO_OPETUSTEHTAVAT]: kasittele_opetustehtavatKoodisto,
  [CellId.KASITTELE_KOODISTO_PO_ERITYISET_KOULUTUSTEHTAVAT]:
    kasittele_poErityisetKoulutustehtavatKoodisto,
  [CellId.KASITTELE_KOODISTO_PO_MUUT_EHDOT]: kasittele_poMuutEhdotKoodisto,
  [CellId.KASITTELE_OPETUSTEHTAVAT]: kasittele_opetustehtavat,
  [CellId.TALLENNA_OPETUSTEHTAVAT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]:
    tallenna_opetustehtavatKoodistoPaikalliseenTietovarastoon,
  [CellId.TALLENNA_OPETUSTEHTAVAT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]:
    tallenna_opetustehtavatPaikalliseenTietovarastoon,
  [CellId.TALLENNA_PO_ERITYISET_KOULUTUSTEHTAVAT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]:
    tallenna_poErityisetKoulutustehtavatKoodistoPaikalliseenTietovarastoon,
  [CellId.TALLENNA_PO_MUUT_EHDOT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]:
    tallenna_poMuutEhdotKoodistoPaikalliseenTietovarastoon
};
