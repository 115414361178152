import {
  addIndex,
  concat,
  find,
  flatten,
  map,
  mapObjIndexed,
  path,
  prop,
  propEq,
  values
} from "ramda";
import { luoMuutosobjektitLisatietokentasta } from "./lisatiedot";
import { createAlimaarayksetBEObjects } from "helpers/rajoitteetHelper";
import { getLocalizedProperty } from "services/lomakkeet/utils";
import { getMaarayksetByTunniste } from "helpers/lupa";
import { getAnchorPart } from "utils/anchor";
import { PaikallisenTietovarastonAvain } from "enums";
import { getChangeObjByAnchor } from "utils/muutokset";
import localForage from "localforage";
import { getRajoitteetByValue, rajoiteHasValue } from "utils/rajoitteetUtils";

export const defineBackendChangeObjects = async (
  changeObjects,
  kohde,
  maaraystyypit,
  lupaMaaraykset,
  locale,
  kohteet
) => {
  const maaraykset = await getMaarayksetByTunniste(
    "luvantaiteenalat",
    lupaMaaraykset
  );
  const { rajoitteetByRajoiteId } = changeObjects;
  const luvanTaiteenalat = await localForage.getItem(
    PaikallisenTietovarastonAvain.LUVAN_TAITEENALAT_TPO
  );

  const lisatietomuutokset = await luoMuutosobjektitLisatietokentasta(
    find(propEq("tunniste", "luvantaiteenalat"), kohteet),
    changeObjects.luvanTaiteenalat,
    find(propEq("tunniste", "OIKEUS"), maaraystyypit)
  );

  const muutokset = addIndex(map)(luvanTaiteenala => {
    const rajoitteetByRajoiteIdAndKoodiarvo = getRajoitteetByValue(
      luvanTaiteenala.koodiarvo,
      rajoitteetByRajoiteId
    );

    // Etsitään iteraation kohteena olevaa taiteenalaa koskevaa muutosta.
    const changeObj = getChangeObjByAnchor(
      `luvanTaiteenalat.${luvanTaiteenala.koodiarvo}.ala`,
      changeObjects.luvanTaiteenalat
    );

    // Etsitään muutosobjekti, joka koskee kyseisen taiteenalan opettamista
    // laajan oppimäärän mukaan.
    const laajaOppimaaraChangeObj = getChangeObjByAnchor(
      `luvanTaiteenalat.${luvanTaiteenala.koodiarvo}.laajaoppimaara.A`,
      changeObjects.luvanTaiteenalat
    );

    const isLaajaOppimaaraChecked = !!path(
      ["properties", "isChecked"],
      laajaOppimaaraChangeObj
    );

    // Muodostetaan muutosobjekti, mikäli käyttöliittymässä on tehty
    // kohtaan muutoksia.
    if (changeObj) {
      /*  Jos ainoastaan laajaoppimäärä on muuttunut, tarkastetaan onko määräystä isäntä koodiarvolle.
          Määräykselle luodaan tällöin poisto-objekti.
       */
      const changeObjKoodiarvo = getAnchorPart(changeObj.anchor, 1);
      const maarays = find(x => {
        return x.koodiarvo === changeObjKoodiarvo;
      }, maaraykset);

      let poistoObj = null;
      if (maarays) {
        poistoObj = {
          kohde,
          koodiarvo: maarays.koodiarvo,
          koodisto: maarays.koodi.koodisto.koodistoUri,
          tila: "POISTO",
          maaraysUuid: maarays.uuid,
          maaraystyyppi: maarays.maaraystyyppi
        };
      }

      const tila = path(["properties", "isChecked"], changeObj)
        ? "LISAYS"
        : "POISTO";
      const muutosId = `luvanTaiteenala-${Math.random()}`;
      let muutosobjekti = {
        generatedId: muutosId,
        kohde: find(propEq("tunniste", "luvantaiteenalat"), kohteet),
        koodiarvo: luvanTaiteenala.koodiarvo,
        koodisto: luvanTaiteenala.koodisto.koodistoUri,
        kuvaus: getLocalizedProperty(
          luvanTaiteenala.metadata,
          locale,
          "kuvaus"
        ),
        maaraystyyppi: find(propEq("tunniste", "OIKEUS"), maaraystyypit),
        meta: {
          changeObjects: concat(
            [changeObj, laajaOppimaaraChangeObj],
            values(rajoitteetByRajoiteIdAndKoodiarvo)
          ).filter(Boolean),
          opetusAnnetaanLaajanOppimaaranMukaan: isLaajaOppimaaraChecked
        },
        tila,
        maaraysUuid:
          tila === "POISTO"
            ? prop(
                "uuid",
                find(propEq("koodiarvo", luvanTaiteenala.koodiarvo), maaraykset)
              )
            : null
      };

      // Muodostetaan tehdyistä rajoituksista objektit backendiä varten.
      // Linkitetään ensimmäinen rajoitteen osa yllä luotuun muutokseen ja
      // loput toisiinsa "alenevassa polvessa".
      const alimaaraykset = values(
        mapObjIndexed(asetukset => {
          return createAlimaarayksetBEObjects(
            kohteet,
            maaraystyypit,
            muutosobjekti,
            asetukset
          );
        }, rajoitteetByRajoiteIdAndKoodiarvo)
      );

      return [muutosobjekti, alimaaraykset, poistoObj];
    } else {
      return false;
    }
  }, luvanTaiteenalat).filter(Boolean);

  // Luodaan vielä alimääräykset rajoitteille, jotka on kytketty olemassa
  // oleviin määräyksiin.
  const maarayksiaVastenLuodutRajoitteet = flatten(
    map(maarays => {
      const maaraystaKoskevatRajoitteet = mapObjIndexed(rajoite => {
        if (rajoiteHasValue(rajoite, maarays.koodiarvo)) {
          return createAlimaarayksetBEObjects(
            kohteet,
            maaraystyypit,
            {
              isMaarays: true,
              generatedId: maarays.uuid,
              kohde
            },
            rajoite
          );
        }
      }, rajoitteetByRajoiteId);
      return values(maaraystaKoskevatRajoitteet);
    }, maaraykset)
  ).filter(Boolean);

  return flatten([
    muutokset,
    lisatietomuutokset,
    maarayksiaVastenLuodutRajoitteet
  ]).filter(Boolean);
};
