import PropTypes from "prop-types";
import Lomake from "components/02-organisms/Lomake";
import { useChangeObjectsByAnchorWithoutUnderRemoval } from "stores/muutokset";
import { useIntl } from "react-intl";
import common from "i18n/definitions/common";

const constants = {
  formLocations: {
    oppilaitoksenKoulutustehtava: [
      "vapaaSivistystyo",
      "oppilaitoksenKoulutustehtava"
    ]
  }
};

const OppilaitoksenKoulutustehtava = ({
  isPreviewModeOn,
  maaraykset,
  mode = constants.mode
}) => {
  const { formatMessage } = useIntl();
  const sectionId = "oppilaitoksenKoulutustehtava";
  const [changeObjects] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: sectionId
  });

  return (
    <Lomake
      anchor={sectionId}
      changeObjects={changeObjects}
      data={{ maaraykset }}
      formTitle={formatMessage(common.VSTLupaSectionTitleSchoolMission)}
      isPreviewModeOn={isPreviewModeOn}
      isReadOnly={isPreviewModeOn}
      isRowExpanded={true}
      mode={mode}
      path={constants.formLocations.oppilaitoksenKoulutustehtava}
      rowTitle={formatMessage(
        common.VSTLupaSectionTitleSchoolMission
      )}></Lomake>
  );
};

OppilaitoksenKoulutustehtava.propTypes = {
  isPreviewModeOn: PropTypes.bool,
  maaraykset: PropTypes.array,
  mode: PropTypes.string
};

export default OppilaitoksenKoulutustehtava;
