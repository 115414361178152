import Lomake from "components/02-organisms/Lomake";
import { ReactElement } from "react";
import PropTypes from "prop-types";
import { Lupa } from "Lupa";
import { useLomakedata } from "stores/lomakedata";
import { useChangeObjectsByAnchorWithoutUnderRemoval } from "stores/muutokset";
import { endsWith, find } from "ramda";
import { ChangeObject } from "types";

const constants = {
  mode: "modification",
  formLocation: ["tuva", "lisatiedot"]
};

type Props = {
  mode: string;
  isPreviewModeOn: boolean;
  sectionId: string;
  title: string;
  viimeisinLupa: Lupa;
};

const Lisatiedot = ({
  mode,
  isPreviewModeOn,
  sectionId
}: Props): null | ReactElement => {
  const [lomakedata] = useLomakedata({
    anchor: sectionId
  });

  const [changeObjects] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: sectionId
  });

  const lisatiedotNode = find(_node => {
    const node = _node as ChangeObject;
    return endsWith(".lisatiedot.1", node.anchor);
  }, lomakedata) as ChangeObject;

  const params = {
    anchor: sectionId,
    isPreviewModeOn: isPreviewModeOn,
    changeObjects,
    data: { lisatiedotNode },
    isRowExpanded: mode === "reasoning",
    key: sectionId,
    mode: mode,
    path: constants.formLocation,
    showCategoryTitles: true,
    isInExpandableRow: false
  };

  return (
    <div
      className={`${
        isPreviewModeOn && (!lisatiedotNode || !lisatiedotNode.properties.value)
          ? "hidden"
          : ""
      } mt-12`}>
      <Lomake {...params} />
    </div>
  );
};

Lisatiedot.propTypes = {
  mode: PropTypes.string,
  sectionId: PropTypes.string,
  title: PropTypes.string
};

export default Lisatiedot;
