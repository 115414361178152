import { memo, Fragment } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import common from "i18n/definitions/common";
import education from "i18n/definitions/education";
import Taiteenalat from "../lomakeosiot/1-Taiteenalat";
import OpetustaAntavatKunnat from "../lomakeosiot/2-OpetustaAntavatKunnat";
import Opetuskieli from "../lomakeosiot/3-Opetuskieli";
import MuutEhdot from "../lomakeosiot/4-MuutEhdot";
import {
  assoc,
  filter,
  find,
  includes,
  map,
  path,
  pathEq,
  propEq
} from "ramda";
import equal from "react-fast-compare";
import { useLomakedata } from "stores/lomakedata";
import AsianumeroYmsKentat from "../lomakeosiot/0-AsianumeroYmsKentat";
import Rajoitteet from "components/02-organisms/Rajoitteet/index";
import { useChangeObjectsByAnchorWithoutUnderRemoval } from "stores/muutokset";
import MuutKunnatJoistaOppilaita from "../lomakeosiot/2-MuutKunnatJoistaOppilaita";
import Oppilaitokset from "../lomakeosiot/Oppilaitokset";
import wizard from "i18n/definitions/wizard";
import { getRajoitteetBySection } from "utils/rajoitteetUtils";

// Kohdevaihtoehtoja käytetään rajoitteita tehtäessä.
// Kohteet vaihtelevat koulutusmuodoittain.
const rajoitteidenKohdevaihtoehdot = [
  {
    label: "Luvan taiteenalat",
    value: "luvanTaiteenalat"
  },
  {
    label: "Kunnat, joissa opetusta järjestetään",
    value: "toimintaalue"
  },
  {
    label: "Muut kunnat (oppilaspaikkojen varaaminen)",
    value: "muutKunnatJoistaOppilaita"
  },
  { label: "Opetuskieli", value: "opetuskielet" },
  {
    label: "Muut ehdot",
    value: "muutEhdot"
  }
];

function filterByTunniste(tunniste, maaraykset = []) {
  return filter(pathEq(["kohde", "tunniste"], tunniste), maaraykset);
}

function filterBySectionId(sectionId, maaraykset = []) {
  return filter(pathEq(["meta", "sectionId"], sectionId), maaraykset);
}

/**
 * Tämä lupanäkymä sisältää kaikki PO-lomakkeen osiot soveltuen siksi
 * erinomaisesti myös esikatselunäkymäksi.
 * @param {*} param0
 */
const LupanakymaA = memo(
  ({
    isPreviewModeOn,
    isRestrictionsModeOn,
    koulutustyyppi,
    maaraykset,
    valtakunnallinenMaarays,
    rajoitemaaraykset
  }) => {
    const intl = useIntl();

    const [rajoitepoistot] = useChangeObjectsByAnchorWithoutUnderRemoval({
      anchor: "rajoitepoistot"
    });

    const rajoitepoistoIds = map(
      rajoitepoisto => path(["properties", "rajoiteId"], rajoitepoisto),
      rajoitepoistot
    );

    const maarayksetRajoitepoistotFiltered = map(maarays => {
      /** Opiskelijamäärärajoitteen poisto poistaa koko määräyksen */
      if (
        maarays.koodisto === "kujalisamaareet" &&
        path(["maaraystyyppi", "tunniste"], maarays) === "RAJOITE" &&
        includes(path(["meta", "rajoiteId"], maarays), rajoitepoistoIds)
      ) {
        return null;
      }

      /** Muissa tapauksissa poistetaan vain alimääräykset */
      const alimaaraykset = filter(
        alimaarays =>
          !includes(path(["meta", "rajoiteId"], alimaarays), rajoitepoistoIds),
        maarays.aliMaaraykset || []
      );
      return assoc("aliMaaraykset", alimaaraykset, maarays);
    }, maaraykset || []).filter(Boolean);

    const [rajoitteetStateObj] = useLomakedata({ anchor: "rajoitteet" });

    const paattymispvm = path(
      ["properties", "value"],
      find(
        cObj => cObj.anchor === "paatoksentiedot.paattymispaivamaara.A",
        path(
          ["0"],
          useChangeObjectsByAnchorWithoutUnderRemoval({
            anchor: "paatoksentiedot"
          })
        ) || []
      )
    );

    const rajoitteetListausChangeObj = find(
      propEq("anchor", "rajoitteet.listaus.A"),
      rajoitteetStateObj
    );

    const rajoiteChangeObjsByRajoiteId = path(
      ["properties", "rajoitteet"],
      rajoitteetListausChangeObj
    );

    const luvanTaiteenalamaaraykset = filterByTunniste(
      "luvantaiteenalat",
      maarayksetRajoitepoistotFiltered
    );

    const oppilaitosmaaraykset = filterByTunniste(
      "oppilaitokset",
      maarayksetRajoitepoistotFiltered
    );

    const toimintaaaluemaaraykset = filterByTunniste(
      "kunnatjoissaopetustajarjestetaan",
      maarayksetRajoitepoistotFiltered
    );

    const luvanTaiteenalatRajoitteet = getRajoitteetBySection(
      "luvanTaiteenalat",
      rajoiteChangeObjsByRajoiteId
    );

    const opetuskieletRajoitteet = getRajoitteetBySection(
      "opetuskielet",
      rajoiteChangeObjsByRajoiteId
    );

    const toimintaalueRajoitteet = getRajoitteetBySection(
      "toimintaalue",
      rajoiteChangeObjsByRajoiteId
    );

    const muutKunnatRajoitteet = getRajoitteetBySection(
      "muutKunnatJoistaOppilaita",
      rajoiteChangeObjsByRajoiteId
    );

    const muutEhdotRajoitteet = getRajoitteetBySection(
      "muutEhdot",
      rajoiteChangeObjsByRajoiteId
    );

    const asianumeroYmsClasses = isPreviewModeOn
      ? "md:w-1/2 xxl:w-1/3 pr-6 mb-6 mt-3"
      : "md:w-1/2 xxl:w-1/3 my-12";

    return (
      <form className={`bg-white ${isPreviewModeOn ? "" : ""}`}>
        {!(isPreviewModeOn && !paattymispvm) && (
          <div className={asianumeroYmsClasses}>
            <AsianumeroYmsKentat isPreviewModeOn={isPreviewModeOn} />
          </div>
        )}

        {!isPreviewModeOn && (
          <Oppilaitokset
            isPreviewModeOn={isPreviewModeOn}
            maaraykset={oppilaitosmaaraykset}
          />
        )}

        <Rajoitteet
          maaraykset={filter(
            maarays =>
              maarays.aliMaaraykset ||
              (maarays.koodisto === "kujalisamaareet" &&
                path(["maaraystyyppi", "tunniste"], maarays) === "RAJOITE"),
            maaraykset || []
          )}
          rajoitemaaraykset={rajoitemaaraykset}
          isPreviewModeOn={isPreviewModeOn}
          isRestrictionsModeOn={isRestrictionsModeOn}
          kohdevaihtoehdot={rajoitteidenKohdevaihtoehdot}
          koulutustyyppi={koulutustyyppi}
          sectionId="rajoitteet"
          render={() => {
            return (
              <Fragment>
                <Taiteenalat
                  code="1"
                  isPreviewModeOn={isPreviewModeOn}
                  maaraykset={luvanTaiteenalamaaraykset}
                  sectionId={"luvanTaiteenalat"}
                  rajoitteet={luvanTaiteenalatRajoitteet}
                />

                <OpetustaAntavatKunnat
                  code="2"
                  isPreviewModeOn={isPreviewModeOn}
                  maaraykset={filterBySectionId(
                    "toimintaalue",
                    toimintaaaluemaaraykset
                  )}
                  rajoitteet={toimintaalueRajoitteet}
                  sectionId={"toimintaalue"}
                  title={intl.formatMessage(education.opetustaAntavatKunnat)}
                  valtakunnallinenMaarays={valtakunnallinenMaarays}
                />

                <MuutKunnatJoistaOppilaita
                  isPreviewModeOn={isPreviewModeOn}
                  maaraykset={filterBySectionId(
                    "muutKunnatJoistaOppilaita",
                    toimintaaaluemaaraykset
                  )}
                  rajoitteet={muutKunnatRajoitteet}
                  sectionId={"muutKunnatJoistaOppilaita"}
                  title={intl.formatMessage(
                    wizard.muutKunnatOppilaspaikkojenVaraaminen
                  )}
                  valtakunnallinenMaarays={valtakunnallinenMaarays}
                />

                <Opetuskieli
                  code="3"
                  isPreviewModeOn={isPreviewModeOn}
                  maaraykset={filterByTunniste(
                    "opetuskieli",
                    maarayksetRajoitepoistotFiltered
                  )}
                  rajoitteet={opetuskieletRajoitteet}
                  sectionId={"opetuskielet"}
                  title={intl.formatMessage(common.opetuskieli)}
                />

                <MuutEhdot
                  code="4"
                  isPreviewModeOn={isPreviewModeOn}
                  maaraykset={filterByTunniste(
                    "muutehdot",
                    maarayksetRajoitepoistotFiltered
                  )}
                  rajoitteet={muutEhdotRajoitteet}
                  sectionId={"muutEhdot"}
                  title={intl.formatMessage(education.muutEhdotTitle)}
                />
              </Fragment>
            );
          }}
        />
      </form>
    );
  },
  (cp, np) => {
    return equal(cp, np);
  }
);

LupanakymaA.propTypes = {
  isPreviewModeOn: PropTypes.bool,
  isRestrictionsModeOn: PropTypes.bool,
  koulutustyyppi: PropTypes.string,
  maaraykset: PropTypes.array,
  OpetustaAntavatKunnatJSX: PropTypes.func,
  rajoitemaaraykset: PropTypes.array,
  valtakunnallinenMaarays: PropTypes.object
};

LupanakymaA.displayName = "Lupanäkymä (Taiteen perusopetus).";

export default LupanakymaA;
