import { GraphStoreFunctions } from "graphHandling/graphTypes";
import { equals, path } from "ramda";
import { CellId } from "processes/CellId";
import { koulutustyypitMap } from "utils/constants";
import { Component } from "utils/lomakkeet";

export function voidaankoAvoinAsiaNoutaa(
  storeFunctions: GraphStoreFunctions
): boolean {
  const components = storeFunctions.readPath(["components"]);

  const koulutustyyppi = path(
    [CellId.INITIALIZE_KOULUTUSMUOTOVALIKKO, "properties", "value"],
    components
  );

  const onkoKoulutusmuotoValittu = koulutustyyppi !== undefined;

  const oid = path(
    [CellId.INITIALIZE_ORGANISAATIOVALIKKO, "properties", "values", 0, "value"],
    components
  );

  const onkoOrganisaatioValittu = !!oid;

  const onkoValittuLukiokoulutus = koulutustyyppi === koulutustyypitMap.LUKIO;

  const locale = path(
    [CellId.INITIALIZE_LOKALISAATIOVALIKKO, "properties", "value"],
    components
  );

  // Avoinasia voidaan noutaa, kun kaikki seuraavat ehdot ovat tosia.
  // * Koulutusmuoto ja organisaatio on valittu
  const ehto1 = onkoKoulutusmuotoValittu && onkoOrganisaatioValittu;
  // * Jos koulutusmuoto on lukiokoulutus, on kieli (fi / sv) valittu.
  const ehto2 = !onkoValittuLukiokoulutus || !!locale;

  return ehto1 && ehto2;
}

export function onkoOrganisaatioluetteloVanhentunut(
  storeFunctions: GraphStoreFunctions
): boolean {
  const currentListSource = storeFunctions.readPath([
    "components",
    CellId.INITIALIZE_ORGANISAATIOVALIKKO,
    "properties",
    "listSource"
  ]);
  const validListSource = storeFunctions.readPath([
    "custom",
    "validOrganisationListSource"
  ]);
  return !equals(currentListSource, validListSource);
}

export function onkoPaalupailmoitusNakyvilla(
  storeFunctions: GraphStoreFunctions
): boolean {
  const ilmoitusPaaluvanPuuttumisesta = storeFunctions.readPath([
    "components",
    CellId.SHOW_PAALUPAILMOITUS
  ]) as Component;
  return !!(
    ilmoitusPaaluvanPuuttumisesta &&
    ilmoitusPaaluvanPuuttumisesta.properties.isVisible !== false
  );
}

export function onkoAvoinAsiailmoitusNakyvilla(
  storeFunctions: GraphStoreFunctions
): boolean {
  const ilmoitusAvoimenAsianOlemassaOlosta = storeFunctions.readPath([
    "components",
    CellId.SHOW_AVOIN_ASIAILMOITUS
  ]) as Component;
  return !!(
    ilmoitusAvoimenAsianOlemassaOlosta &&
    ilmoitusAvoimenAsianOlemassaOlosta.properties.isVisible !== false
  );
}
