import { ComponentEvent, Option, Options } from "graphHandling/graphTypes";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from "@mui/material";
import { ReactElement } from "react";
import { map } from "ramda";

export interface RadioButtonGroupProps {
  id?: string;
  groupTitle?: string;
  options: Options;
  row?: boolean;
  value?: number | string;
}

interface RadioButtonGroupPropsAndEvents extends RadioButtonGroupProps {
  onChange: ComponentEvent;
}

const RadioButtonGroup = (
  props: RadioButtonGroupPropsAndEvents
): ReactElement => {
  return (
    <FormControl>
      {props.groupTitle ? (
        <FormLabel id={props.id}>{props.groupTitle}</FormLabel>
      ) : null}
      <RadioGroup
        value={props.value}
        aria-labelledby={props.id}
        row={props.row}
        name={props.id}
        onChange={e => {
          props.onChange({
            changeProps: {
              value: e.target.value
            }
          });
        }}>
        {props.options
          ? map((option: Option) => {
              return (
                <FormControlLabel
                  control={<Radio />}
                  disabled={option.isDisabled}
                  key={option.value}
                  label={option.label}
                  value={option.value}
                />
              );
            }, props.options)
          : null}
      </RadioGroup>
    </FormControl>
  );
};

RadioButtonGroup.defaultProps = {
  id: `radioButtonGroup-${Math.random()}`,
  options: [],
  row: false,
  value: null
};

export default RadioButtonGroup;
