import {
  GENERIC_LUPA_SECTIONS,
  VST_LUPA_STRUCTURE
} from "../modules/constants";
import {
  resolveKoodiLocalization,
  resolveLocalizedOrganizerName
} from "../modules/helpers";
import common from "../../../../i18n/definitions/common";
import { assoc, find, path, prop, propEq, sortBy } from "ramda";
import { sortByLocale } from "../../../../helpers/html";

/**
 * Return an object with lupamääräys data categorized into sections matching VST template in
 * kuja-template/lupahistoria/liikunnankoulutuskeskukset/paatos
 *
 * Depends on VST_LUPA_STRUCTURE to guide the order and logic of parsing määräys data and
 * producting formatted section data, based on kohde or koodisto identifier. Uses react-intl
 * utilities for primary localization and that based on koodisto data.
 *
 * @param {Object} lupa lupa with määräys and järjestäjä as given by backend
 * @param {Object} intl react-intl utility object
 * @returns {{heading: String, values: String[]}[]} sectionDataList
 */
export const parseVSTLupa = (lupa, formatMessage, locale) => {
  if (lupa) {
    const organizerSectionData = generateOrganizerSectionData(lupa, locale);
    organizerSectionData.heading = formatMessage(
      common.VSTLupaSectionTitleOrganizer
    );
    const sectionDataList = [organizerSectionData];
    for (const metaDataObject of VST_LUPA_STRUCTURE) {
      let maaraykset = [];
      let generateSectionData = () => ({});
      if (metaDataObject.kohdeTunniste) {
        maaraykset = lupa.maaraykset.filter(maarays => {
          return (
            path(["kohde", "tunniste"], maarays) ===
            metaDataObject.kohdeTunniste
          );
        });
        generateSectionData = getSectionDataGeneratorForVST(
          metaDataObject.kohdeTunniste
        );
      } else if (metaDataObject.koodisto) {
        generateSectionData = getSectionDataGeneratorForVST(
          metaDataObject.koodisto
        );
        maaraykset = lupa.maaraykset.filter(
          maarays => maarays.koodisto === metaDataObject.koodisto
        );
      }

      let sectionData = {};
      if (maaraykset.length > 0) {
        sectionData = generateSectionData(
          maaraykset,
          locale,
          lupa.diaarinumero
        );
        const lisatietoMaarays = find(
          propEq("koodisto", "lisatietoja"),
          maaraykset
        );
        sectionData = assoc(
          "lisatiedot",
          path(["meta", "arvo"], lisatietoMaarays),
          sectionData
        );
      }

      sectionData.heading = formatMessage(metaDataObject.titleMessageKey);
      sectionDataList.push(sectionData);
    }
    return sectionDataList;
  }
};

const generateOrganizerSectionData = (lupa, locale) => {
  const kunta = resolveKoodiLocalization(
    lupa.jarjestaja.kuntaKoodi.metadata,
    locale
  );
  // Exception sourced from kuja-template/lupahistoria/liikunnankoulutuskeskukset/paatos/content_paatos_fi.html:35
  // TODO: localization of this exception case content
  const value =
    lupa.jarjestaja.oid === "1.2.246.562.10.13451568789"
      ? `${resolveLocalizedOrganizerName(
          lupa,
          locale
        )}, ${kunta} sekä Humppilan ja Ypäjän kunnat`
      : `${resolveLocalizedOrganizerName(lupa, locale)}, ${kunta}`;

  const retval = {
    values: [value]
  };
  return retval;
};

/**
 * Return an object with lupamääräys data categorized into sections matching generic Kuja template in
 * kuja-template/default/paatos
 * @param lupa
 * @returns {{}}
 */
export const parseGenericKujaLupa = (lupa, locale) => {
  if (lupa) {
    let sectionDataList = [];

    for (const sectionKey of GENERIC_LUPA_SECTIONS) {
      const maarayksetByTunniste = lupa.maaraykset.filter(
        maarays => maarays.kohde.tunniste === sectionKey
      );
      let sectionData = {};

      if (maarayksetByTunniste.length > 0) {
        const generateSectionData =
          getSectionDataGeneratorForGeneric(sectionKey);
        sectionData = generateSectionData(maarayksetByTunniste, locale);
      }

      sectionDataList.push(sectionData);
    }
    return sectionDataList;
  }
};

const generateIteratedKoodiData = (maaraykset, locale) => {
  const retval = {};
  retval.values = [];
  retval.lisatiedot = null;

  const sortedMaaraykset = sortByLocale(locale, maaraykset);
  for (const maarays of sortedMaaraykset) {
    if (maarays.koodisto !== "lisatietoja") {
      retval.values.push(
        resolveKoodiLocalization(maarays.koodi.metadata, locale)
      );
    }
  }
  const lisatietoja = find(propEq("koodisto", "lisatietoja"), maaraykset);
  if (lisatietoja) {
    retval.lisatiedot = resolveKoodiLocalization(
      lisatietoja.koodi.metadata,
      locale
    );
  }

  return retval;
};

/**
 * Get meta-attribute values from maaraykset based on meta-attribute names.
 *
 * @param maaraykset
 * @param attributes
 * @param sortNaturally
 * @return {{}}
 */
const generateMetaAttributeBasedData = (
  maaraykset,
  attributes,
  sortNaturally = true
) => {
  const retval = {};
  if (maaraykset.length === 0) {
    return retval;
  }

  retval.values = [];
  for (const maarays of maaraykset) {
    for (const attribute of attributes) {
      if (
        maarays.meta &&
        maarays.meta[attribute] &&
        maarays.meta[attribute].length > 0
      )
        retval.values.push(maarays.meta[attribute]);
    }
  }
  if (sortNaturally) {
    retval.values.sort();
  }
  return retval;
};

const generateKoulutustehtavaData = maaraykset => {
  const attributes = ["kuvaus"];

  return generateMetaAttributeBasedData(maaraykset, attributes);
};

const generateErityinenKoulutustehtavaData = maaraykset => {
  const attributes = ["kuvaus"];

  return generateMetaAttributeBasedData(
    sortBy(prop("koodiarvo"), maaraykset || []),
    attributes,
    false
  );
};

const generateOppilaitoksetData = maaraykset => {
  const attributes = [
    "oppilaitosmääräys-0",
    "oppilaitosmääräys-1",
    "oppilaitosmääräys-2"
  ];

  return generateMetaAttributeBasedData(maaraykset, attributes);
};

const generateMuutData = maaraykset => {
  const attributes = [
    "urn:muumääräys-1",
    "urn:muumääräys-2",
    "urn:oppilaitosmääräys-1"
  ];

  return generateMetaAttributeBasedData(maaraykset, attributes);
};

const generateTarkoitusData = maaraykset => {
  const attributes = ["kuvaus"];

  return generateMetaAttributeBasedData(maaraykset, attributes);
};

const getSectionDataGeneratorForGeneric = tunniste => {
  switch (tunniste) {
    case "kunnat":
      return generateIteratedKoodiData;
    case "kielet":
      return generateIteratedKoodiData;
    case "tarkoitus":
      return generateTarkoitusData;
    case "koulutustehtava":
      return generateKoulutustehtavaData;
    case "erityinenkoulutustehtava":
      return generateErityinenKoulutustehtavaData;
    case "oppilaitos":
      return generateOppilaitoksetData;
    case "opiskelijamaara":
      //TODO: data source is ambiguous and doesn't appear needed for VST
      return () => ({});
    case "muut":
      return generateMuutData;
    default:
      return () => ({});
  }
};

const generateSopimuskunnatDataForVST = (maaraykset, locale) => {
  const targetMaaraykset = maaraykset.filter(
    item => item.meta && !!item.meta.sectionId
  );

  const data = generateIteratedKoodiData(targetMaaraykset, locale, true);

  if (data.values.length > 1) {
    data.values = [data.values.join(", ")];
  }

  return data;
};

const generateOppilaitoksetDataForVST = (maaraykset, locale, diaarinumero) => {
  let values = [];
  for (const maarays of maaraykset) {
    if (maarays.organisaatio) {
      values.push(
        createMunicipalityLocationDescriptionForVST(
          maarays.organisaatio,
          locale
        )
      );
    } else if (diaarinumero === "27/532/2011") {
      //Special case for a school that doesn't have organisaatio information
      values.push(
        `${maarays.meta["oppilaitosmääräys-0"]}, ${
          locale === "fi" ? "Kokkola" : "Karleby"
        }`
      );
    }
  }
  return { values };
};

export const createMunicipalityLocationDescriptionForVST = (
  oppilaitosOrganisaatio,
  locale
) => {
  if (oppilaitosOrganisaatio) {
    const schoolName = oppilaitosOrganisaatio.nimi[locale];
    const municipalities = [];
    if (oppilaitosOrganisaatio.muutKuntaKoodit) {
      for (const other of oppilaitosOrganisaatio.muutKuntaKoodit) {
        municipalities.push(resolveKoodiLocalization(other.metadata, locale));
      }
    }
    municipalities.sort();
    // Prepend school name and its location
    municipalities.unshift(
      schoolName,
      resolveKoodiLocalization(
        oppilaitosOrganisaatio.kuntaKoodi.metadata,
        locale
      )
    );
    return municipalities.join(", ");
  } else {
    return "";
  }
};

const generateRegionalDataForVST = (maaraykset, locale) => {
  if (!maaraykset || maaraykset.length === 0) {
    return {};
  }
  const maarays = find(
    propEq("koodisto", "vstoppilaitoksenalueellisuusjavaltakunnallisuus"),
    maaraykset
  );

  let values = [];
  values.push(resolveKoodiLocalization(maarays.koodi.metadata, locale));
  if (maarays.meta && maarays.koodi.koodiArvo === "2") {
    values.push(maarays.meta.kuvaus);
  }
  return { values };
};

const generateOtherDataForVST = maaraykset => {
  let values = [];
  for (const maarays of maaraykset) {
    if (maarays.meta && maarays.meta.kuvaus && maarays.meta.kuvaus.length > 0)
      values.push(maarays.meta.kuvaus);
  }
  return { values };
};

const getSectionDataGeneratorForVST = key => {
  switch (key) {
    case "kunnat":
      return generateSopimuskunnatDataForVST;
    case "oppilaitos":
      return generateOppilaitoksetDataForVST;
    case "kielet":
      return generateIteratedKoodiData;
    case "oppilaitoksentoimintaalue":
      return generateRegionalDataForVST;
    case "tarkoitus":
      return generateTarkoitusData;
    case "koulutustehtava":
      return generateKoulutustehtavaData;
    case "erityinenkoulutustehtava":
      return generateErityinenKoulutustehtavaData;
    case "muut":
      return generateOtherDataForVST;
    default:
      return () => ({});
  }
};
