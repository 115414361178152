import { Fragment } from "react";
import { useIntl } from "react-intl";
import { find, head, last, prop, values } from "ramda";
import { Typography } from "@mui/material";
import { PropTypes } from "prop-types";

const OrganisationInfo = ({ isPreviewModeOn, organisation }) => {
  const intl = useIntl();
  const organisationPhoneNumber = head(
    values(find(prop("numero"), organisation.yhteystiedot))
  );

  const organisationEmail = head(
    values(find(prop("email"), organisation.yhteystiedot))
  );

  const organisationWebsite = head(
    values(find(prop("www"), organisation.yhteystiedot))
  );

  return (
    <div className="px-16 pt-4 bg-gray-100 w-full mx-auto border-b border-xs border-gray-200">
      <div
        className={`pt-4 pb-12 px-8 ${
          isPreviewModeOn ? "" : "1440:px-0 max-w-8xl m-auto"
        }`}>
        <Typography component="h1" variant="h1">
          {organisation.nimi[intl.locale] || last(values(organisation.nimi))}
        </Typography>
        <p>
          {organisation.kayntiosoite.osoite}, {organisation.postiosoite.osoite}{" "}
          {organisation.kayntiosoite.postitoimipaikka}
        </p>
        <p>
          {organisationPhoneNumber && (
            <Fragment>
              <a href={`tel:${organisationPhoneNumber}`} className="underline">
                {organisationPhoneNumber}
              </a>{" "}
              |{" "}
            </Fragment>
          )}
          {organisationPhoneNumber && (
            <Fragment>
              <a href={`mailto:${organisationEmail}`} className="underline">
                {organisationEmail}
              </a>{" "}
              |{" "}
            </Fragment>
          )}
          {organisation.ytunnus} |{" "}
          {organisationWebsite && (
            <a href={organisationWebsite} className="underline">
              {organisationWebsite}
            </a>
          )}
        </p>
      </div>
    </div>
  );
};

OrganisationInfo.propTypes = {
  isPreviewModeOn: PropTypes.bool,
  organisation: PropTypes.object
};

OrganisationInfo.displayName = "OrganisationInfo";

export default OrganisationInfo;
