import { Koodisto, Toiminto } from "enums";
import { defineLabeledCheckbox } from "graphHandling/components/labeledCheckbox/unopinionated";
import { CellFn, Modifications } from "graphHandling/graphTypes";
import { Lupa } from "Lupa";
import {
  assoc,
  equals,
  find,
  isEmpty,
  isNil,
  map,
  propEq,
  reject
} from "ramda";
import { Muutos, Muutospyynto } from "types";
import { Properties } from "utils/lomakkeet";

export const initialize_suomiCheckbox: CellFn = storeFunctions => {
  const custom = storeFunctions.readPath(["custom"]) as Properties;

  let FI1Maarays = null;

  let luvanKunnatJaMaakunnat: Array<string> = [];

  const viimeisinLupa = custom.viimeisinLupa as Lupa;

  if (viimeisinLupa) {
    const { maaraykset } = viimeisinLupa;
    luvanKunnatJaMaakunnat = reject(
      isNil,
      map(maarays => {
        return (maarays.koodisto === Koodisto.MAAKUNTA ||
          (maarays.koodisto === Koodisto.KUNTA &&
            maarays.koodiarvo !== "200")) &&
          (equals(maarays.tila, Toiminto.LISAYS) || !maarays.tila)
          ? maarays.koodiarvo
          : null;
      }, maaraykset)
    );

    FI1Maarays = find(propEq("koodiarvo", "FI1"), maaraykset);
  }

  const defaultValues = {
    // Suomi-checkbox on oletuksena ruksattu, mikäli luvalta
    // löytyy FI1-määräys tai mikäli lupa sisältää kunta- ja/tai
    // maakuntamääräyksiä (väh. yksi kappale).
    isChecked: !!FI1Maarays || !isEmpty(luvanKunnatJaMaakunnat)
  };

  let muutokset: Array<Muutos> = [];
  const muutospyynto = custom.muutospyynto as Muutospyynto;

  let modifications: Modifications = {};

  if (muutospyynto) {
    muutokset = muutospyynto.muutokset as Array<Muutos>;

    const poistot: Array<Muutos> = reject(
      isNil,
      map(koodiarvo => {
        return find(propEq("koodiarvo", koodiarvo), muutokset);
      }, luvanKunnatJaMaakunnat)
    );

    const lisaykset: Array<string> = reject(
      isNil,
      map(muutos => {
        return (muutos.koodisto === Koodisto.MAAKUNTA ||
          (muutos.koodisto === Koodisto.KUNTA && muutos.koodiarvo !== "200")) &&
          equals(muutos.tila, Toiminto.LISAYS)
          ? muutos.koodiarvo
          : null;
      }, muutokset)
    );

    const FI1Muutos = find(propEq("koodiarvo", "FI1"), muutokset);

    const isFI1Lisays = !!FI1Muutos && FI1Muutos.tila === Toiminto.LISAYS;

    const onkoVahintaanYksiMaakuntaTaiKuntaValittu = lisaykset.length > 0;

    if (muutokset.length) {
      if (isFI1Lisays || onkoVahintaanYksiMaakuntaTaiKuntaValittu) {
        if (!defaultValues.isChecked) {
          modifications = assoc("backend", { isChecked: true }, modifications);
        }
      } else if (
        defaultValues.isChecked &&
        poistot.length === luvanKunnatJaMaakunnat.length &&
        poistot.length > 0
      ) {
        modifications = assoc("backend", { isChecked: false }, modifications);
      }
    }
  }

  return defineLabeledCheckbox(
    {
      id: "checkbox0",
      isChecked: defaultValues.isChecked,
      label: "Opetusta järjestetään Suomessa",
      name: "toiminta-alue",
      value: "suomessa"
    },
    {
      onChange: payload => {
        return payload;
      }
    },
    isEmpty(modifications) ? undefined : modifications
  );
};
