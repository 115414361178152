import { defineAlert } from "graphHandling/components/alert/definitions/customizable";
import { CellFn } from "graphHandling/graphTypes";
import tuva from "i18n/definitions/tuva";

export const ilmoitusPaaluvanPuuttumisesta: CellFn = (
  storeFunctions,
  intl
): boolean => {
  const ilmoitus = defineAlert({
    isVisible: true,
    message: intl?.formatMessage(tuva.ilmoitusPaaluvanPuuttumisesta),
    title: intl?.formatMessage(tuva.ilmoitusPaaluvanPuuttumisestaOtsikko)
  });

  storeFunctions.updateGraph(
    ["components", "ilmoitusPaaluvanPuuttumisesta"],
    ilmoitus
  );

  return true;
};
