import { Variant } from "components/00-atoms/SimpleButton/SimpleButton";
import { defineSimpleButton } from "graphHandling/components/simpleButton/definitions/customizable";
import { CellFn } from "graphHandling/graphTypes";
import common from "i18n/definitions/common";

export const initialize_lisaaPainike: CellFn = (storeFunctions, intl) => {
  if (!intl) {
    console.warn("Parametri intl puuttuu.");
    return false;
  }
  return defineSimpleButton(
    {
      ariaLabel: "Lisää-painike",
      icon: "FaPlus",
      id: "muut-07-lisaa",
      text: intl.formatMessage(common.lisaaMaarays),
      variant: Variant.TEXT
    },
    async (): Promise<boolean> => {
      return true;
    }
  );
};
