import { filter, includes, map, pathEq, uniq } from "ramda";
import { ChangeObjects } from "utils/muutokset";

/**
 * Poistaa vanhentuneet muutokset parametrina annetusta muutosten joukosta.
 * @param changeObjects Taulukollinen muutosobjekteja.
 * @returns Taulukollinen muutosobjekteja.
 */
export function removeDeprecatedChanges(
  changeObjects: ChangeObjects
): ChangeObjects {
  const isDeprecated = pathEq(["properties", "isDeprecated"], true);
  const deprecatedAnchors = map(changeObj => {
    return isDeprecated(changeObj) ? changeObj.anchor : null;
  }, changeObjects).filter(Boolean);
  const allButDeprecated = uniq(
    filter(changeObj => {
      return !includes(changeObj.anchor, deprecatedAnchors);
    }, changeObjects)
  );
  return allButDeprecated;
}
