import {
  getKohdistuvatRajoitteet,
  getPaatasonMaaraaikaRajoite,
  getRajoitteet,
  getRajoitteetFromMaarays,
  getVoimassaOlevatRajoitteet,
  isRajoiteVoimassa
} from "utils/rajoitteetUtils";
import { __ } from "i18n-for-browser";
import { sortEsikatseluRajoitteet } from "../helpers/html";
import { getVoimassaOlevatMaaraykset, onkoMaaraysVoimassa } from "helpers/muut";

/**
 * Palauttaa html-muotoisen merkkijonon osion tietylle koodiarvolle esikatselua varten
 * @param maarays
 * @param koodiarvo - Koodistopalvelusta tuleva arvo, johon voi kohdistua määräyksiä ja muutoksia
 * @param rajoiteChangeObjs
 * @param locale
 * @param naytettavaArvo
 * @param parentMaaraysArvo -
 * @returns {string}
 */
export const createEsikatseluHTML = (
  maarays,
  koodiarvo,
  rajoiteChangeObjs,
  locale,
  naytettavaArvo,
  parentMaaraysArvo
) => {
  const koodiarvoonKohdistuvatRajoitemuutosobjektit = sortEsikatseluRajoitteet(
    getRajoitteet(koodiarvo, rajoiteChangeObjs)
  );

  /** Luodaan html-muotoinen listaesitys rajoitteita sisältäviltä määräyksiltä */
  const rajoitteetFromMaaraysHtml = maarays
    ? getRajoitteetFromMaarays(
        getVoimassaOlevatMaaraykset(maarays.aliMaaraykset),
        locale,
        __("rajoitteet.ajalla"),
        naytettavaArvo,
        true
      )
    : null;

  const paatasonMaaraaikaRajoite = getPaatasonMaaraaikaRajoite(
    koodiarvoonKohdistuvatRajoitemuutosobjektit
  );

  if (!isRajoiteVoimassa(paatasonMaaraaikaRajoite)) {
    return "";
  } else if (!onkoMaaraysVoimassa(maarays)) {
    return "";
  }

  const voimassaOlevatRajoitteet = getVoimassaOlevatRajoitteet(
    koodiarvoonKohdistuvatRajoitemuutosobjektit
  );

  /** Luodaan html-muotoinen listaesitys rajoitemuutosobjekteista */
  let rajoitteetFromChangeObjectsHtml = getKohdistuvatRajoitteet(
    voimassaOlevatRajoitteet,
    locale
  );

  /** getKohdistuvatRajoitteet - funktion luoma html sisältää parent-määräyksen. Otetaan tämä pois. */
  rajoitteetFromChangeObjectsHtml = rajoitteetFromChangeObjectsHtml.substring(
    rajoitteetFromChangeObjectsHtml.indexOf("<ul>", 8)
  );

  /** Parent-määräys */
  const titlehtml = `<ul ${
    rajoitteetFromChangeObjectsHtml || rajoitteetFromMaaraysHtml
      ? "class='mb-2'"
      : ""
  }><li>${parentMaaraysArvo}`;

  /** Yhdistetty html: parent-määräys, rajoite muutosobjektit sekä rajoitemääräykset */
  return `${titlehtml}${
    rajoitteetFromChangeObjectsHtml ? rajoitteetFromChangeObjectsHtml : ""
  }${rajoitteetFromMaaraysHtml ? rajoitteetFromMaaraysHtml : ""}`;
};
