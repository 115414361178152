import { SimpleButtonProps } from "components/00-atoms/SimpleButton/SimpleButton";
import { RadioButtonGroupProps } from "graphHandling/components/radioButtonGroup/RadioButtonGroup";
import { AutocompleteProps } from "components/02-organisms/Autocomplete/ac";
import { DatepickerProps } from "components/00-atoms/Datepicker/Datepicker";
import { IUndoProps } from "graphHandling/components/undo";
import { InputProps } from "components/00-atoms/Input/Input";
import { ComponentEvent } from "graphHandling/graphTypes";
import {
  append,
  assocPath,
  concat,
  dissocPath,
  equals,
  findIndex,
  flatten,
  init,
  join,
  length,
  map,
  nth,
  Path,
  path,
  prepend,
  propEq,
  split,
  uniq
} from "ramda";
import {
  Anchor,
  getAnchorBaseParts,
  getAnchorTailParts,
  isAnchorBase,
  isAnchor
} from "./anchor";
import { ComponentType } from "graphHandling/components/componentType";
import { ILabeledCheckboxProps } from "graphHandling/components/labeledCheckbox/LabeledCheckbox";
import { ITextBoxProps } from "components/00-atoms/TextBox/TextBox";
import { IRadioButtonProps } from "graphHandling/components/radioButton";
import { IMultiTextBoxProps } from "graphHandling/components/multiTextBox/MultiTextBox";
import { IChipProps } from "graphHandling/components/chip/Chip";

export type State = {
  lomakkeet: Record<string, unknown>;
};

export type Properties = Record<string, unknown>;

export interface GraphComponent {
  isNotInitial?: boolean;
  isUnsaved?: boolean;
  isValid?: boolean;
  modifications?: {
    backend?: Properties;
    frontend?: Array<Properties>;
  };
  name: ComponentType;
  onChange?: ComponentEvent;
  onInputChange?: ComponentEvent;
  onClick?: ComponentEvent;
  properties: any;
}

export interface Alert extends GraphComponent {
  initialProperties: Properties;
  properties: Properties;
}

export interface IChip extends GraphComponent {
  onClick: ComponentEvent;
  properties: IChipProps;
}

export interface Input extends GraphComponent {
  initialProperties: InputProps;
  onChange: ComponentEvent;
  properties: InputProps;
}

export interface Autocomplete extends GraphComponent {
  onChange: ComponentEvent;
  onInputChange?: ComponentEvent;
  properties: AutocompleteProps;
}

export interface Datepicker extends GraphComponent {
  initialProperties: DatepickerProps;
  onChange: ComponentEvent;
  onInputChange?: ComponentEvent;
  properties: DatepickerProps;
}

export interface ILabeledCheckbox extends GraphComponent {
  onChange: ComponentEvent;
  properties: ILabeledCheckboxProps;
}

export interface IRadioButton extends GraphComponent {
  onChange?: ComponentEvent;
  properties: IRadioButtonProps;
}

export interface ITextBox extends GraphComponent {
  onChange: ComponentEvent;
  properties: ITextBoxProps;
}

export interface IMultiTextBox extends GraphComponent {
  onChange: ComponentEvent;
  properties: IMultiTextBoxProps;
}

export interface IChipCollection extends GraphComponent {
  onClick?: ComponentEvent;
  properties: {
    chips: Array<IChip>;
  };
}

export interface ICheckboxCollection extends GraphComponent {
  onChange: ComponentEvent;
  properties: {
    checkboxes: Array<ILabeledCheckbox>;
  };
}

export interface IGroupedCheckboxCollection extends GraphComponent {
  onChange: ComponentEvent;
  properties: {
    parentCheckbox: ILabeledCheckbox;
    checkboxCollection: ICheckboxCollection;
  };
}

export interface IGroupedChipCollection extends GraphComponent {
  onClick: ComponentEvent;
  properties: {
    parentChip: IChip;
    chipCollection: IChipCollection;
  };
}

export interface IGroupedChipCollections extends GraphComponent {
  onClick: ComponentEvent;
  properties: {
    gChipCs: Record<string, IGroupedChipCollection>;
  };
}

export interface IRadioButtonCollection extends GraphComponent {
  onChange?: ComponentEvent;
  properties: {
    radioButtons: Array<IRadioButton>;
    value?: string;
  };
}

export interface RadioButtonGroup extends GraphComponent {
  initialProperties: RadioButtonGroupProps;
  onChange: ComponentEvent;
  properties: RadioButtonGroupProps;
}

export interface SimpleButton extends GraphComponent {
  onClick: ComponentEvent;
  properties: SimpleButtonProps;
}

export interface IUndo extends GraphComponent {
  onClick: ComponentEvent;
  properties: IUndoProps;
}

export type Component = {
  anchor: string;
  initialProperties?: Properties;
  name: string;
  onClick?: ComponentEvent;
  onChange?: ComponentEvent;
  onInputChange?: ComponentEvent;
  properties: Properties;
  styleClasses?: Array<string>;
};

export type ComponentWithMetadata = {
  anchor: string;
  formId?: string;
  hasDescendants?: boolean;
  name: string;
  properties: Properties;
  anchorParts: Array<number | string>;
  fullAnchor: Anchor;
  level: number;
  columnIndex: number;
  path: Array<number | string>;
};

export type Category = {
  anchor: string;
  formId?: string;
  isAddingEnabled?: boolean;
  isRemovable?: boolean;
  layout?: unknown;
  onCategoryAdd?: () => Record<string, unknown>;
  onCategoryRemove?: () => Record<string, unknown>;
  styleClasses?: Array<string>;
  title?: string;
  categories?: Categories;
  components?: Components;
};

export type Categories = Array<Category>;
export type Components = Array<Component>;

/**
 * Lisää kategorian toisen kategorian alle, jolloin siitä tulee
 * alakategoria.
 * @param Kategoria, joka on määrä lisätä alakategoriaksi.
 * @param Sen kategorian ankkuri-tieto, jonka alle
 * parametrina annettu kategoria on tarkoitus lisätä.
 * @param {object} state Tilaobjekti, joka sisältää lomakkeet-tiedon.
 */
export function addSubCategory(
  category: Category,
  parentCategoryAnchor: Anchor,
  state: State
): State {
  const currentCategories = getCategories(parentCategoryAnchor, state);
  const updatedCategories = append(category, currentCategories);
  return setCategories(updatedCategories, parentCategoryAnchor, state);
}

/**
 * Luo ja palauttaa tyhjän lomakerakenteen.
 */
export function createFormStructure(): Categories {
  const emptyFormStructure: Categories = [];
  return emptyFormStructure;
}

/**
 * Funktio etsii ja palauttaa annetun kategorian kaikki komponentit metadatalla höystettyinä
 * käyden läpi myös kategorian mahdolliset alakategoriat ja niiden komponentit
 * alenevassa polvessa.
 * @param category Kategoria, jonka komponentit etsitään ja palautetaan metatietojen kera.
 * @param anchor Läpikäytävän kategorian ankkuri.
 * @param componentsWithMetadata Palautettava arvo. Parametri kannattaa jättää antamatta.
 * Oletusarvo [].
 * @param level Kategoriatasoa ilmentävä numero. Oletuksena 0. Mikäli parametrina annettu
 * kategoria sijaitsee muulla kuin tasolla 0, tulee tämä parametri antaa.
 * @param pathToComponent Taulukollinen merkkijonoja ja numeroita, joista käy ilmi
 * komponentin sijainti annetussa kategoriassa.
 * @returns Moniulotteinen taulukko metadatalla höystettyjä komponentteja. Huom! Sama
 * komponentti saattaa esiintyä taulukossa useampaan kertaan. Siksi kannattaa käyttää
 * tämän funktion sijaan getReducedStructure-funktiota, joka poistaa duplikaatit ja
 * tekee taulukosta yksiulotteisen.
 */
export const getComponentsWithMetadata = (
  category: Category,
  anchor: Anchor,
  componentsWithMetadata: Array<ComponentWithMetadata> = [],
  level = 0,
  pathToComponent: Array<number | string> = []
): Array<unknown> => {
  if (category.components) {
    category.components.forEach((component: Component, index) => {
      const fullAnchor = join(".", [anchor, component.anchor]);
      const componentWithMetadata: ComponentWithMetadata = {
        ...component,
        anchorParts: split(".", fullAnchor),
        formId: category.formId,
        fullAnchor,
        hasDescendants: category.categories && category.categories.length > 0,
        level,
        columnIndex: index,
        path: concat(pathToComponent, ["components", index])
      };
      componentsWithMetadata = append(
        componentWithMetadata,
        componentsWithMetadata
      );
    });
  }

  if (category.categories && category.categories.length) {
    return category.categories.map((_category, index: number) => {
      return getComponentsWithMetadata(
        _category,
        join(".", [anchor, _category.anchor]),
        componentsWithMetadata,
        level + 1,
        concat(["categories", index], pathToComponent)
      );
    }, (category.categories || []).filter(Boolean));
  }

  return componentsWithMetadata;
};

/**
 * Funktio palauttaa kaikki categories-rakenteen komponentit metadatalla
 * höystettyinä yksiulotteisessa taulukossa.
 * @param categories Taulukollinen kategorioita.
 * @returns Taulukollinen ComponentWithMetadata-objekteja.
 */
export const getReducedStructure = (
  categories: Categories
): Array<ComponentWithMetadata> => {
  return uniq(
    flatten(
      map(category => {
        return getComponentsWithMetadata(category, category.anchor);
      }, categories)
    )
  ) as Array<ComponentWithMetadata>;
};

/**
 * Yhdistää muutosobjektin sisältämään Properties-objektin ja parametrina
 * saamansa Properties-objektin tiedot.
 * @param changeObj Muutosobjekti.
 * @param properties Properties-objekti.
 * @returns Yhdistämisen tuloksena syntynyt Properties-objekti.
 */
export function getPropertiesObject(
  properties1: Properties = {},
  properties2: Properties = {}
): Properties {
  return Object.assign({}, properties1, properties2);
}

/**
 * Selvittää, millaisessa polussa annettu kategoria sijaitsee.
 * @param parentCategoryAnchor
 * @param anchorTailParts
 * @param form
 * @param index
 * @param indexPath
 */
function getCategoryIndexPath(
  anchorTailParts: Array<string>,
  form: Categories,
  indexPath: Array<number | string> = [],
  index = 0
): Path {
  if (index < length(anchorTailParts)) {
    const foundIndex = findIndex(propEq("anchor", nth(index, anchorTailParts)))(
      form
    );
    if (foundIndex !== -1) {
      return getCategoryIndexPath(
        anchorTailParts,
        form[foundIndex].categories || [],
        concat(indexPath, [foundIndex, "categories"]),
        index + 1
      );
    }
  }
  return init(indexPath);
}

/**
 * Selvittää, millaisessa polussa annettu kategoria sijaitsee.
 * @param categoryAnchor
 * @param state
 */
export function getCategoryLocation(
  categoryAnchor: string,
  state: State
): Path {
  if (!isAnchor(categoryAnchor)) {
    return [];
  }

  const indexPath = getAnchorBaseParts(categoryAnchor);
  const anchorTailParts = getAnchorTailParts(categoryAnchor);
  const categories = path(indexPath, state.lomakkeet) as Categories;

  return categories
    ? getCategoryIndexPath(anchorTailParts, categories, indexPath)
    : [];
}

/**
 * Poistaa annetun ankkurin mukaisen kategorian.
 * @param categoryAnchor
 * @param state
 */
export function removeCategory(categoryAnchor: string, state: State): State {
  const categoryLocation = getCategoryLocation(categoryAnchor, state);
  if (categoryLocation.length > 0) {
    return dissocPath(prepend("lomakkeet", categoryLocation), state);
  }
  return state;
}

/**
 * Asettaa annetut tiedot kategoriaan.
 * @param categoryAnchor
 * @param properties
 */
export function setCategoryProperties(
  categoryAnchor: string,
  properties: Properties,
  state: State
): State {
  const categoryLocation = getCategoryLocation(categoryAnchor, state);
  const currentCategory = path(categoryLocation, state.lomakkeet);
  const updatedCategory = Object.assign({}, currentCategory, properties);
  if (!equals(currentCategory, updatedCategory)) {
    return assocPath(
      prepend("lomakkeet", categoryLocation),
      updatedCategory,
      state
    );
  }
  return state;
}
/**
 * Etsii ja palauttaa kategorian polun perusteella.
 * @param categoryAnchor
 * @param state
 * @returns Kategoria.
 */
export function getCategory(
  categoryAnchor: string,
  state: State
): Category | undefined {
  const categoryLocation = getCategoryLocation(categoryAnchor, state);
  return path(prepend("lomakkeet", categoryLocation), state);
}

/**
 * Etsii ja palauttaa kategoriat polun perusteella.
 * @param categoryAnchor
 * @param state
 */
export function getCategories(
  anchorOrAnchorBase: string,
  state: State
): Categories {
  let categoryLocation: Array<string | number> = [];
  let fullPath: Array<string | number> = [];
  if (isAnchor(anchorOrAnchorBase)) {
    categoryLocation = getCategoryLocation(anchorOrAnchorBase, state);
    fullPath = flatten(["lomakkeet", categoryLocation, "categories"]);
  } else if (isAnchorBase(anchorOrAnchorBase)) {
    categoryLocation = getAnchorBaseParts(anchorOrAnchorBase);
    fullPath = flatten(["lomakkeet", categoryLocation]);
  }
  const categories: Array<Category> = path(fullPath, state) || [];
  return categories;
}

/**
 * Asettaa categoriat annetun ankkurin kohdalle.
 * @param categories Lisättävät kategoriat
 * @param anchorOrAnchorBase
 * @param state
 * @returns Päivitetty tilaobjekti.
 */
export function setCategories(
  categories: Array<Category>,
  parentCategoryAnchor: string,
  state: State
): State {
  if (isAnchor(parentCategoryAnchor) || isAnchorBase(parentCategoryAnchor)) {
    const parentCategoryLocation = getCategoryLocation(
      parentCategoryAnchor,
      state
    );
    return assocPath(
      flatten(["lomakkeet", parentCategoryLocation, "categories"]),
      categories,
      state
    );
  }
  return state;
}
