import Table from "../../02-organisms/Table";
import { generateKorjauksessaOlevatAsiatTableStructure } from "../../../utils/asiatUtils";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { PropTypes } from "prop-types";

const KorjattavatAsiat = ({
  korjauksessaOlevatAsiat,
  koulutusmuoto,
  vstTyypit = []
}) => {
  const history = useHistory();
  const intl = useIntl();

  const tableStructure = korjauksessaOlevatAsiat
    ? generateKorjauksessaOlevatAsiatTableStructure(
        korjauksessaOlevatAsiat.data,
        intl,
        history,
        koulutusmuoto,
        vstTyypit
      )
    : [];

  return (
    <Table
      structure={tableStructure}
      sortedBy={{ columnIndex: 5, order: "desc" }}
    />
  );
};

KorjattavatAsiat.propTypes = {
  korjauksessaOlevatAsiat: PropTypes.object,
  koulutusmuoto: PropTypes.object,
  vstTyypit: PropTypes.array
};

export default KorjattavatAsiat;
