import { PaikallisenTietovarastonAvain, Toiminto } from "enums";
import { defineAutocomplete } from "graphHandling/components/autocomplete/definitions/customizable";
import { CellFn, Options } from "graphHandling/graphTypes";
import education from "i18n/definitions/education";
import localForage from "localforage";
import { Lupa } from "Lupa";
import {
  append,
  concat,
  equals,
  filter,
  find,
  forEach,
  isEmpty,
  isNil,
  map,
  path,
  pathEq,
  propEq,
  reject
} from "ramda";
import { getLocalizedProperty } from "services/lomakkeet/utils";
import { Kielet, Muutos, Muutospyynto } from "types";
import { KOODISTOT } from "utils/constants";
import { Properties } from "utils/lomakkeet";

export const initialize_autocomplete2: CellFn = async (
  storeFunctions,
  intl
) => {
  if (!intl) {
    console.warn("Parametri intl puuttuu.");
    return false;
  }

  const opetuskieletOPH = (await localForage.getItem(
    PaikallisenTietovarastonAvain.TOISSIJAISET_OPETUSKIELET_OPH
  )) as Kielet;

  const custom = storeFunctions.readPath(["custom"]) as Properties;

  const muutospyynto = custom.muutospyynto as Muutospyynto;
  const viimeisinLupa = custom.viimeisinLupa as Lupa;

  const defaultValues: Options = viimeisinLupa
    ? reject(
        isNil,
        map(maarays => {
          return maarays.koodisto === KOODISTOT.KIELIKOODISTO_OPH &&
            maarays.meta.valikko === "toissijaiset"
            ? {
                label: getLocalizedProperty(
                  maarays.koodi.metadata,
                  intl.locale,
                  "nimi"
                ),
                value: maarays.koodi.koodiArvo
              }
            : null;
        }, viimeisinLupa.maaraykset)
      )
    : [];

  type Modifications = {
    backend: {
      values: Options;
    };
  };

  let changedOptions: Options = [];

  if (muutospyynto) {
    forEach((muutos: Muutos) => {
      if (
        muutos.koodisto === KOODISTOT.KIELIKOODISTO_OPH &&
        pathEq(["meta", "valikko"], "toissijaiset", muutos)
      ) {
        if (equals(muutos.tila, Toiminto.POISTO)) {
          changedOptions = append(
            {
              isRemoved: true,
              label: getLocalizedProperty(
                path(["koodi", "metadata"], muutos),
                intl.locale,
                "nimi"
              ) as string,
              value: path(["koodi", "koodiArvo"], muutos) || ""
            },
            changedOptions
          );
        } else if (equals(muutos.tila, Toiminto.LISAYS)) {
          changedOptions = append(
            {
              label: getLocalizedProperty(
                path(["koodi", "metadata"], muutos),
                intl.locale,
                "nimi"
              ) as string,
              value: path(["koodi", "koodiArvo"], muutos) || ""
            },
            changedOptions
          );
        }
      }
    }, muutospyynto.muutokset);
  }

  const options = map(kieli => {
    return {
      label: getLocalizedProperty(kieli.metadata, intl.locale, "nimi"),
      value: kieli.koodiarvo
    };
  }, opetuskieletOPH);

  const modifications: Modifications | undefined = !isEmpty(changedOptions)
    ? {
        backend: {
          values: concat(
            changedOptions,
            filter(option => {
              return !find(propEq("value", option.value), changedOptions);
            }, defaultValues)
          )
        }
      }
    : undefined;

  return defineAutocomplete(
    {
      hideSelectedOptions: true,
      inputId: "opetuskielet.opetuskieli.toissijaiset",
      isSearchable: true,
      isMulti: true,
      options,
      placeholder: "",
      searchStr: "",
      values: defaultValues,
      title: intl.formatMessage(education.voidaanAntaaMyosSeuraavillaKielilla)
    },
    {
      onChange: payload => {
        return payload;
      }
    },
    modifications
  );
};
