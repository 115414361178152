import { findParent } from "./findParent";
import { uncheckRadioButtonsOnSameLevel } from "./uncheckRadioButtonsOnSameLevel";
import { modifyNode } from "./modifyNode";
import { ChangeObjects } from "utils/muutokset";
import { ComponentWithMetadata } from "utils/lomakkeet";

/**
 * Ruksaa parametrina annetun komponentin kanssa samassa polussa olevat komponentit
 * taso kerrallaan kohti rakenteen juuritasoa edeten.
 * @param componentWithMetadata - Komponenttimääritys, johon aktivointitoimenpiteen on määrä kohdistua.
 * @param componentsWithMetadata Taulukollinen metadatalla höystettyjä komponenttimäärityksiä.
 * @param changeObjects Taulukollinen muutosobjekteja.
 * @returns Taulukollinen muutosobjekteja.
 */
export function activatePredecessors(
  componentWithMetadata: ComponentWithMetadata,
  componentsWithMetadata: Array<ComponentWithMetadata>,
  changeObjects: ChangeObjects = []
): ChangeObjects {
  /**
   * Let's find out if the componentWithMetadata has a parent. The parent must be checked too
   * and if it exists its isIndeterminate property must be calculated. The
   * logic goes like this: If the parent has child componentWithMetadatas and NOT all of them
   * are checked then it's isIndeterminate property must be true. Let's find
   * the parent componentWithMetadata first. Checking / activating is relevant only if the
   * parent is a checkbox or a radio button.
   */
  const parentcomponentWithMetadata = findParent(
    componentWithMetadata,
    componentsWithMetadata,
    ["CheckboxWithLabel", "RadioButtonWithLabel"]
  );

  changeObjects = modifyNode(
    componentWithMetadata,
    componentsWithMetadata,
    changeObjects
  );

  if (parentcomponentWithMetadata) {
    if (parentcomponentWithMetadata.name === "RadioButtonWithLabel") {
      changeObjects = uncheckRadioButtonsOnSameLevel(
        parentcomponentWithMetadata,
        componentsWithMetadata,
        changeObjects
      );
    }

    // The parent componentWithMetadata might have a parent. Let's handle the parent componentWithMetadata next.
    return activatePredecessors(
      parentcomponentWithMetadata,
      componentsWithMetadata,
      changeObjects
    );
  }

  return changeObjects;
}
