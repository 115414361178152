import { Fragment } from "react";
import Jarjestajaluettelo from "./Jarjestajaluettelo";
import { Helmet } from "react-helmet";
import education from "../../../i18n/definitions/education";
import { useIntl } from "react-intl";
import { PropTypes } from "prop-types";

const JarjestajaluetteloFiltteroinnilla = ({
  koulutusmuoto,
  luvat,
  pageNo,
  setPageNo,
  searchBy,
  setSearchBy,
  vstTyypit,
  vstOppilaitostyyppiFilter,
  setVstOppilaitostyyppiFilter,
  vstTypeOptions
}) => {
  const intl = useIntl();

  return (
    <Fragment>
      <Helmet htmlAttributes={{ lang: intl.locale }}>
        <title>{intl.formatMessage(education.vstEducation)} - Oiva</title>
      </Helmet>
      {vstTypeOptions.length > 0 ? (
        <Jarjestajaluettelo
          koulutusmuoto={koulutusmuoto}
          luvat={luvat}
          vstTyypit={vstTyypit}
          pageNo={pageNo}
          setPageNo={setPageNo}
          searchBy={searchBy}
          setSearchBy={setSearchBy}
          vstOppilaitostyyppiFilter={vstOppilaitostyyppiFilter}
          setVstOppilaitostyyppiFilter={setVstOppilaitostyyppiFilter}
          vstTypeOptions={vstTypeOptions}
        />
      ) : null}
    </Fragment>
  );
};

JarjestajaluetteloFiltteroinnilla.propTypes = {
  koulutusmuoto: PropTypes.object,
  luvat: PropTypes.array,
  pageNo: PropTypes.number,
  setPageNo: PropTypes.func,
  searchBy: PropTypes.object,
  setSearchBy: PropTypes.func,
  vstTyypit: PropTypes.array,
  vstOppilaitostyyppiFilter: PropTypes.string,
  setVstOppilaitostyyppiFilter: PropTypes.func,
  vstTypeOptions: PropTypes.array
};

export default JarjestajaluetteloFiltteroinnilla;
