import CircularProgress from "@mui/material/CircularProgress";
import { useIntl } from "react-intl";
import common from "../i18n/definitions/common";
import Typography from "@mui/material/Typography";
import { ReactElement } from "react";
import { isNil } from "ramda";

const Loading = ({
  fadeOut,
  readyList = [],
  percentage,
  text
}: {
  fadeOut?: number; // in seconds
  readyList?: Array<string>;
  percentage?: number;
  text?: string;
}): ReactElement => {
  const intl = useIntl();
  const loadingtext = text || intl.formatMessage(common.loading);
  return (
    <div
      className={`container flex items-center justify-center mx-auto py-24 easy-in-out duration-1000 ${
        fadeOut ? `transition-opacity opacity-50` : "opacity-100"
      }`}>
      <div className="flex flex-col items-center">
        <div className="flex h-20 w-20 items-center justify-center">
          {!isNil(percentage) && (
            <span className="absolute">{Math.round(percentage)}%</span>
          )}
          <CircularProgress size={80} value={percentage} />
        </div>

        <div>
          <Typography component="h3" variant="h3">
            {loadingtext}...
          </Typography>
        </div>
      </div>

      {readyList.length ? (
        <div className="flex flex-col ml-10 border-l-2 pl-6">
          <Typography component="h4" variant="h4">
            Prosessin läpi käydyt vaiheet:
          </Typography>
          <ul className="ml-5">{readyList}</ul>
        </div>
      ) : null}
    </div>
  );
};

export default Loading;
