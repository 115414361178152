import { Fragment, useEffect, useState } from "react";
import Table from "components/02-organisms/Table/separatedTableSections";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { path } from "ramda";
import * as PropTypes from "prop-types";
import PageRowIndicator from "components/01-molecules/PageRowIndicator";
import PageNavigator from "components/01-molecules/PageNavigator";
import {
  asiatTableColumnSetup,
  generateAsiatTableHeaderStructure,
  generatePaatetytAsiatTableStructure
} from "utils/asiatUtils";

const PaatetytAsiat = ({
  koulutusmuoto,
  vstTyypit = [],
  muutospyynnot,
  pageNo,
  pageSize,
  searchBy,
  sortedBy,
  setPageNo,
  setPageSize,
  setSortedBy,
  setSearchBy
}) => {
  const history = useHistory();
  const intl = useIntl();
  const { formatMessage, locale } = intl;

  const tableColumnSetup = asiatTableColumnSetup(false, koulutusmuoto, locale);

  const [tableHeader] = useState(
    generateAsiatTableHeaderStructure(formatMessage, tableColumnSetup)
  );

  const [tableBody, setTableBody] = useState({});

  useEffect(() => {
    setTableBody(
      generatePaatetytAsiatTableStructure(
        muutospyynnot.paatetyt?.data,
        intl,
        history,
        koulutusmuoto,
        vstTyypit
      )
    );
  }, [muutospyynnot.paatetyt, searchBy, sortedBy]);

  const totalCount = parseInt(
    path(["paatetyt", "paging", "totalCount"], muutospyynnot) || "0",
    10
  );

  const totalPages = parseInt(
    path(["paatetyt", "paging", "totalPages"], muutospyynnot) || "0",
    10
  );

  return (
    <Fragment>
      <PageRowIndicator
        totalCount={totalCount}
        pageNo={pageNo}
        pageSize={pageSize}
      />

      {tableHeader && tableBody && (
        <Table
          tableBody={tableBody}
          tableHeader={tableHeader}
          sortedBy={sortedBy}
          setSortedBy={setSortedBy}
          sortEnabled={true}
          searchBy={searchBy}
          setSearchBy={setSearchBy}
        />
      )}
      <PageNavigator
        pageNo={pageNo}
        pageSize={pageSize}
        totalPages={totalPages}
        setPageNo={setPageNo}
        setPageSize={setPageSize}
        history={history}
      />
    </Fragment>
  );
};

PaatetytAsiat.propTypes = {
  koulutusmuoto: PropTypes.object,
  vstTyypit: PropTypes.array,
  muutospyynnot: PropTypes.object,
  pageNo: PropTypes.number,
  pageSize: PropTypes.number,
  searchBy: PropTypes.object,
  sortedBy: PropTypes.object,
  setPageNo: PropTypes.func,
  setPageSize: PropTypes.func,
  setSortedBy: PropTypes.func,
  setSearchBy: PropTypes.func
};

export default PaatetytAsiat;
