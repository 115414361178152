import { CellFn } from "graphHandling/graphTypes";
import { CellId } from "processes/CellId";

const currentCellId = CellId.INITIALIZE_RADIO_BUTTON_COLLECTION;

export const action_kumoaMuutokset: CellFn = storeFunctions => {
  storeFunctions.updateComponent(currentCellId, {
    modifications: undefined
  });
};
