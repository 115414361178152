import { ReactElement, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { Route, useHistory, useLocation, useParams } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import common from "i18n/definitions/common";
import BaseData from "basedata";
import { Helmet } from "react-helmet";
import { Tab, Tabs, Typography } from "@mui/material";
import { localizeRouteKey } from "utils/common";
import { AppRoute } from "routes/index";
import { LocalizedSwitch } from "modules/i18n/index";
import kebabCase from "i18n/definitions/kebabCase";
import JarjestajaBasicInfo from "./Jarjestaja/JarjestajaBasicInfo";
import { koulutustyypitMap } from "utils/constants";
import OmatTiedot from "./Jarjestaja/OmatTiedot";
import JulkisetTiedot from "./Jarjestaja/JulkisetTiedot";
import { Koulutusmuoto, Organisation } from "types";
import { IProcessCollection } from "graphHandling/graphProcessHandling";
import { CellId } from "processes/CellId";
import {
  cellImplementations,
  processDefinition
} from "graphs/jarjestaja/processDefinition";
import { trackingFunction } from "scenes/Koulutusmuodot/EsiJaPerusopetus";
import {
  addIndex,
  concat,
  equals,
  flatten,
  head,
  includes,
  insert,
  isEmpty,
  last,
  map,
  prop,
  split,
  values
} from "ramda";
import { Lupa } from "Lupa";
import { Locale } from "enums";
import { Kohde } from "koodistodatanTyypit";
import JarjestamislupaAsiatList from "./Jarjestaja/JarjestamislupaAsiatList";
import Loading from "scenes/Loading";

type NavRoute = {
  authenticated: boolean;
  exact: boolean;
  id?: string;
  path: string;
  text: string;
};

const fadeOutTime = 0;

type InputProps = {
  JarjestamislupaJSX: ({
    lupa
  }: {
    lupa: Lupa;
    pc: IProcessCollection;
  }) => ReactElement;
  koulutusmuoto: Koulutusmuoto;
  pc: IProcessCollection;
  user: { oid: string | null };
};

const Jarjestaja = ({
  JarjestamislupaJSX,
  koulutusmuoto,
  pc,
  user
}: InputProps): null | ReactElement => {
  const { id } = useParams<{ id: string }>();
  const [readyList, setReadyList] = useState<Array<any>>([]);
  const [results, setResults] = useState();
  const [fadeOut, setFadeOut] = useState<number>(0);
  const history = useHistory();
  const intl = useIntl();
  const location = useLocation();
  const tabKey = last(split("/", location.pathname));

  useEffect(() => {
    pc.addProcess(
      {
        actions: {},
        CellId,
        cellImplementations,
        customParams: {
          koulutustyyppi: koulutusmuoto.koulutustyyppi,
          uuid: id
        },
        processDefinition,
        trackingFunction: cellFnResults => {
          const reactElement = trackingFunction(
            cellFnResults,
            processDefinition
          );
          setReadyList(prevState => concat(prevState, reactElement));
        }
      },
      "Jarjestaja"
    );

    const runProcess = async () => {
      const outcome = await pc.handleIncomingProcessToken(
        CellId.INITIALIZE_PROCESS_JARJESTAJA,
        "Jarjestaja"
      );

      setFadeOut(fadeOutTime);

      setTimeout(() => {
        setResults(outcome);
      }, fadeOutTime);
    };

    runProcess();
  }, [pc, id]);

  if (!results) {
    return <Loading fadeOut={fadeOut} readyList={readyList} />;
  }

  const kohteet = results[CellId.FETCH_KOHTEET] as Array<Kohde>;
  const lupa = results[CellId.FETCH_LUPA_BY_UUID] as Lupa;
  const organisation = results[CellId.FETCH_ORGANISATION] as Organisation;
  const tulevatLuvat = results[CellId.FETCH_TULEVAT_LUVAT] as Array<Lupa>;

  const jarjestaja =
    lupa && lupa.jarjestaja
      ? {
          ...lupa.jarjestaja,
          nimi:
            prop(
              intl.locale == "fi" ? Locale.FI : Locale.SV,
              lupa.jarjestaja.nimi
            ) || head(values(lupa.jarjestaja.nimi))
        }
      : null;

  if (!jarjestaja || !kohteet || !lupa || !organisation) {
    return null;
  }

  const breadcrumb = localizeRouteKey(
    intl.locale,
    AppRoute.Jarjestamislupa,
    intl,
    {
      id: jarjestaja.oid,
      koulutusmuoto: koulutusmuoto.kebabCase
    }
  );

  // Basic routes (no authentication needed)
  const basicRoutes = [
    {
      authenticated: true,
      exact: true,
      path: intl.formatMessage(kebabCase.jarjestamislupa),
      text: intl.formatMessage(
        koulutusmuoto.koulutustyyppi === koulutustyypitMap.VAPAASIVISTYSTYO
          ? common.yllapitamisLupaTitle
          : common.lupaTitle
      )
    },
    {
      authenticated: true,
      exact: true,
      path: intl.formatMessage(kebabCase.paatokset),
      text: intl.formatMessage(common.lupaPaatokset)
    }
  ];
  // If user is logged in we are going to show her/him these additional routes.
  const additionalRoutes: Array<NavRoute> =
    user && equals(user.oid, prop("oid", lupa.jarjestaja))
      ? [
          {
            authenticated: !!user,
            exact: true,
            path: intl.formatMessage(kebabCase.omatTiedot),
            text: intl.formatMessage(common.omatTiedotTitle)
          },
          {
            authenticated: !!user,
            exact: true,
            id: "jarjestamislupa-asia",
            path: intl.formatMessage(kebabCase.jarjestamislupaasiat),
            text: intl.formatMessage(common.asiatTitle)
          }
        ]
      : [];

  const tabNavRoutes: Array<NavRoute> = flatten(
    insert(1, basicRoutes, additionalRoutes as any)
  );

  const newApplicationRouteItem = {
    path: localizeRouteKey(intl.locale, AppRoute.UusiHakemus, intl, {
      id: organisation.oid,
      koulutusmuoto: koulutusmuoto.kebabCase,
      page: 1,
      language: "fi"
    }),
    text: intl.formatMessage(common.newHakemus),
    authenticated: !!user
  };

  return (
    <article className="flex flex-1 flex-col">
      <Helmet htmlAttributes={{ lang: intl.locale }}>
        <title>
          {jarjestaja.nimi}, {koulutusmuoto.paasivunOtsikko} - Oiva
        </title>
      </Helmet>
      <BreadcrumbsItem to={breadcrumb}>{jarjestaja.nimi}</BreadcrumbsItem>
      <div className="sm:w-4/5 mx-auto max-w-8xl px-8 sm:px-0">
        <section className="my-8">
          <Typography component="h1" variant="h1">
            {jarjestaja.nimi}
          </Typography>

          <JarjestajaBasicInfo jarjestaja={jarjestaja} />
        </section>

        <Tabs
          value={tabKey}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, value) => {
            history.push(value);
          }}>
          {tabNavRoutes
            ? addIndex(map)((_route, index) => {
                const route = _route as NavRoute;
                return (
                  <Tab
                    aria-label={route.text}
                    key={`tab-${index}`}
                    value={route.path}
                    label={route.text}
                  />
                );
              }, tabNavRoutes)
            : null}
        </Tabs>
      </div>
      <div className="flex-1 bg-gray-100 border-t border-solid border-gray-300">
        <LocalizedSwitch>
          {!!user && (
            <Route
              exact
              path={AppRoute.OmatTiedot}
              render={() => (
                <BaseData
                  keys={["kunnat", "lupa", "maakunnat"]}
                  locale={intl.locale}
                  render={_props =>
                    !isEmpty(organisation) ? (
                      <div className="border my-8 p-8 sm:p-12 bg-white mx-8 sm:w-4/5 sm:mx-auto max-w-8xl">
                        <div className="max-w-5xl m-auto">
                          <OmatTiedot organisation={organisation} {..._props} />
                        </div>
                      </div>
                    ) : null
                  }
                />
              )}
            />
          )}
          <Route
            path={AppRoute.Jarjestamislupa}
            render={() => (
              <div className="border my-8 p-8 sm:p-12 bg-white mx-8 sm:w-4/5 sm:mx-auto max-w-8xl">
                {JarjestamislupaJSX ? (
                  <div className="max-w-5xl m-auto">
                    <JarjestamislupaJSX lupa={lupa} pc={pc} />
                  </div>
                ) : null}
              </div>
            )}
          />
          <Route
            path={AppRoute.Paatokset}
            exact
            render={() => (
              <JulkisetTiedot
                koulutusmuoto={koulutusmuoto}
                jarjestaja={jarjestaja}
                tulevatLuvat={tulevatLuvat}
                voimassaOlevaLupa={results[CellId.FETCH_LUPA_BY_UUID]}
              />
            )}
          />
          {!!user && (
            <Route path={AppRoute.Jarjestamislupaasiat} exact>
              <div className="m-12 mx-auto w-4/5 max-w-8xl">
                <JarjestamislupaAsiatList
                  history={history}
                  isForceReloadRequested={includes(
                    "force=true",
                    location.search
                  )}
                  koulutusmuoto={koulutusmuoto}
                  lupa={lupa}
                  match={{ url: location.pathname }}
                  newApplicationRouteItem={newApplicationRouteItem}
                  organisation={organisation}
                />
              </div>
            </Route>
          )}
        </LocalizedSwitch>
      </div>
    </article>
  );
};

Jarjestaja.propTypes = {
  JarjestamislupaJSX: PropTypes.func,
  koulutusmuoto: PropTypes.object,
  user: PropTypes.object
};

export default Jarjestaja;
