import { Helmet } from "react-helmet";
import common from "i18n/definitions/common";
import { useIntl } from "react-intl";
import { Typography } from "@mui/material";
import { localizeRouteKey } from "utils/common";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { AppRoute, tilastotRoutes } from "../routes";
import { ReactElement } from "react";
import SitemapLink from "components/00-atoms/SitemapLink";
import tilastot from "../i18n/definitions/tilastot";

const Tilastot = (): ReactElement => {
  const intl = useIntl();
  const { formatMessage, locale } = intl;

  const pageTitle = formatMessage(common.statistics);

  const title = `${pageTitle} - Oiva`;
  const categories = tilastotRoutes.categories[0].categories;

  return (
    <div className="flex-1 bg-gray-100">
      <div className="mx-auto max-w-8xl mt-24 md:mt-12 mb-16 px-12 md:px-32 md:py-20 bg-white border border-gray-200">
        <Helmet title={title}></Helmet>
        <BreadcrumbsItem to={localizeRouteKey(locale, AppRoute.Tilastot, intl)}>
          {formatMessage(common.statistics)}
        </BreadcrumbsItem>
        <Typography component="h1" variant="h1">
          {pageTitle}
        </Typography>
        <p className="mb-6">{formatMessage(tilastot.koulutusOhje)}</p>
        <div className="grid gap-4 grid-cols-1">
          <div>
            <ul className="ml-12">
              <li>
                <SitemapLink
                  route={categories[0].components[0].properties.route}
                  text={categories[0].components[0].properties.text}
                />
              </li>
              <li>
                <SitemapLink
                  route={categories[1].components[0].properties.route}
                  text={categories[1].components[0].properties.text}
                />
              </li>
              <li>
                <SitemapLink
                  route={categories[2].components[0].properties.route}
                  text={categories[2].components[0].properties.text}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tilastot;
