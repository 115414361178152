import { Fragment, ReactElement } from "react";
import {
  filter,
  find,
  length,
  map,
  toUpper,
  isEmpty,
  propEq,
  path,
  addIndex,
  pathEq
} from "ramda";
import { useIntl } from "react-intl";
import Typography from "@mui/material/Typography";
import { getRajoitteetFromMaarays } from "../../../../utils/rajoitteetUtils";
import { sortArticlesByFixedMap } from "../../../../services/lomakkeet/utils";
import LisatiedotHtmlLupa from "components/03-templates/HtmlLupanakyma/Lisatiedot";
import rajoitteet from "i18n/definitions/rajoitteet";
import common from "i18n/definitions/common";
import { Maarays, Opetustehtava } from "koodistodatanTyypit";

const defaultProps = {
  maaraykset: [],
  opetustehtavat: []
};

type Props = {
  maaraykset: Array<Maarays>;
  opetustehtavat: Array<Opetustehtava>;
  opetustehtavatKoodisto: unknown;
};

export default function PoOpetusJotaLupaKoskeeHtml({
  maaraykset = defaultProps.maaraykset,
  opetustehtavat = defaultProps.opetustehtavat,
  opetustehtavatKoodisto
}: Props): null | ReactElement {
  const intl = useIntl();
  const locale = toUpper(intl.locale);
  // Koodiarvo:order
  const koodiarvoOrderMap = new Map();
  koodiarvoOrderMap.set(6, 1);
  koodiarvoOrderMap.set(10, 2);
  koodiarvoOrderMap.set(3, 3);
  koodiarvoOrderMap.set(4, 4);
  koodiarvoOrderMap.set(2, 5);
  koodiarvoOrderMap.set(7, 6);
  koodiarvoOrderMap.set(27, 7);
  koodiarvoOrderMap.set(28, 8);
  koodiarvoOrderMap.set(17, 9);
  koodiarvoOrderMap.set(18, 10);
  koodiarvoOrderMap.set(23, 11);
  koodiarvoOrderMap.set(25, 12);
  koodiarvoOrderMap.set(24, 13);
  koodiarvoOrderMap.set(20, 14);
  koodiarvoOrderMap.set(19, 15);
  koodiarvoOrderMap.set(16, 16);
  koodiarvoOrderMap.set(21, 17);
  koodiarvoOrderMap.set(22, 18);
  koodiarvoOrderMap.set(11, 19);
  koodiarvoOrderMap.set(12, 20);
  koodiarvoOrderMap.set(29, 21);
  koodiarvoOrderMap.set(30, 22);
  koodiarvoOrderMap.set(8, 23);
  koodiarvoOrderMap.set(5, 24);
  koodiarvoOrderMap.set(9, 25);
  koodiarvoOrderMap.set(1, 26);
  koodiarvoOrderMap.set(26, 27);
  const opetustehtaviaKoskevatMaaraykset: Array<Maarays> =
    sortArticlesByFixedMap(
      filter(
        maarays =>
          pathEq(["kohde", "tunniste"], "opetusjotalupakoskee", maarays) &&
          maarays.koodisto === "opetustehtava",
        maaraykset
      ),
      koodiarvoOrderMap
    );

  const lisatietomaarays = find(
    maarays =>
      pathEq(["kohde", "tunniste"], "opetusjotalupakoskee", maarays) &&
      maarays.koodisto === "lisatietoja",
    maaraykset
  );

  return !isEmpty(opetustehtaviaKoskevatMaaraykset) &&
    !isEmpty(opetustehtavatKoodisto) &&
    !isEmpty(opetustehtavat) ? (
    <div className="mt-4">
      <Typography component="h3" variant="h3">
        {intl.formatMessage(common.opetusJotaLupaKoskee)}
      </Typography>
      <ul className="ml-8 list-disc mb-4">
        {addIndex(map)((_maarays, index) => {
          const maarays = _maarays as Maarays;
          const result = (
            <Fragment key={`${maarays.koodiarvo}-${index}`}>
              <li
                className={`leading-bulletList ${
                  length(maarays.aliMaaraykset) ? "mb-2" : ""
                }`}>
                {path(
                  ["metadata", locale, "nimi"],
                  find(propEq("koodiarvo", maarays.koodiarvo), opetustehtavat)
                )}
                {length(maarays.aliMaaraykset)
                  ? getRajoitteetFromMaarays(
                      maarays.aliMaaraykset,
                      locale,
                      intl.formatMessage(rajoitteet.ajalla)
                    )
                  : ""}
              </li>
            </Fragment>
          );
          return result;
        }, opetustehtaviaKoskevatMaaraykset)}
      </ul>
      <LisatiedotHtmlLupa lisatietomaarays={lisatietomaarays} />
    </div>
  ) : null;
}
