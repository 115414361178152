import {
  append,
  compose,
  addIndex,
  concat,
  endsWith,
  filter,
  find,
  flatten,
  head,
  includes,
  isNil,
  last,
  length,
  map,
  path,
  pipe,
  prop,
  propEq,
  reject,
  split,
  test,
  toLower,
  uniqBy,
  pathEq,
  isEmpty,
  values
} from "ramda";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { getAnchorPart } from "../utils/anchor";
import { koulutustyypitMap } from "../utils/constants";

const koodistoMappingLukio = {
  maaraaika: "kujalisamaareet",
  oppilaitokset: "oppilaitos",
  toimintaalue: "kunta",
  opetuskielet: "kielikoodistoopetushallinto",
  oikeusSisaoppilaitosmuotoiseenKoulutukseen:
    "lukiooikeussisaoppilaitosmuotoiseenkoulutukseen",
  erityisetKoulutustehtavat: "lukioerityinenkoulutustehtavauusi",
  muutEhdot: "lukiomuutkoulutuksenjarjestamiseenliittyvatehdot"
};

const koodistoMappingPo = {
  maaraaika: "kujalisamaareet",
  opetustehtavat: "opetustehtava",
  oppilaitokset: "oppilaitos",
  toimintaalue: "kunta",
  opetuskielet: "kielikoodistoopetushallinto",
  opetuksenJarjestamismuodot: "opetuksenjarjestamismuoto",
  erityisetKoulutustehtavat: "poerityinenkoulutustehtava",
  muutEhdot: "pomuutkoulutuksenjarjestamiseenliittyvatehdot"
};

const koodistoMappingTPO = {
  luvanTaiteenalat: "tpoluvantaiteenalat",
  maaraaika: "kujalisamaareet",
  opetustehtavat: "opetustehtava",
  oppilaitokset: "oppilaitos",
  toimintaalue: "kunta",
  opetuskielet: "kielikoodistoopetushallinto",
  muutKunnatJoistaOppilaita: "kunta"
};

export const createAlimaarayksetBEObjects = (
  kohteet,
  maaraystyypit,
  paalomakkeenBEMuutos, // myöhemmin lomakedata käyttöön
  rajoite,
  muutosobjektit = [],
  asetuksenIndex = 0,
  kohdennuksenKohdeNumber = -1,
  insideMulti = false
) => {
  let asetuksetHakuRegexp = `^rajoitteet_[^.]+\\.kohdennukset\\.0\\.rajoite\\.asetukset\\.${asetuksenIndex}\\.`;

  if (kohdennuksenKohdeNumber >= 0) {
    // Kohdennuksen tapauksessa asetuksen indeksiä ei voi käyttää suoraan,
    // koska ankkurit ei ole samassa muodossa kuin kohdennuksen ulkopuolella.
    // Tämä käsittely koskee käytännössä vain opiskelijamäärä rajoitteita.
    asetuksetHakuRegexp = `^.+kohdennukset\\.\\d\\.kohdennukset\\.${kohdennuksenKohdeNumber}\\.`;
    asetuksetHakuRegexp +=
      asetuksenIndex === 0
        ? "((\\bkohde\\b)|(\\btarkennin\\b))"
        : asetuksenIndex === 1
        ? "rajoite\\.kohde."
        : `rajoite\\.asetukset\\.${asetuksenIndex - 2}`;
  }

  const asetukset = filter(
    pipe(prop("anchor"), test(new RegExp(asetuksetHakuRegexp))),
    rajoite
  );

  if (isEmpty(asetukset)) {
    // Kaikki kohdennuksen asetukset on käyty läpi.
    asetuksenIndex = 0;
    const nextKohdennuksenKohdennus = find(asetus => {
      return test(
        new RegExp(
          `^.+kohdennukset\\.\\d\\.kohdennukset\\.${
            kohdennuksenKohdeNumber + 1
          }\\.kohde`
        ),
        asetus.anchor
      );
    })(rajoite);
    if (nextKohdennuksenKohdennus && !insideMulti) {
      // Käsitellään seuraava kohdennus
      return createAlimaarayksetBEObjects(
        kohteet,
        maaraystyypit,
        paalomakkeenBEMuutos,
        rajoite,
        muutosobjektit,
        asetuksenIndex,
        ++kohdennuksenKohdeNumber,
        insideMulti
      );
    }
    return muutosobjektit;
  }

  const isMaarays = prop("isMaarays", paalomakkeenBEMuutos) || false;

  const koulutustyyppi = path(["0", "koulutustyyppi"], kohteet);
  /** Haetaan kohteista koulutustyyppi. Tämän perusteella käytetään oikeata koodistoMappingia */
  const koodistoMapping =
    koulutustyyppi === koulutustyypitMap.ESI_JA_PERUSOPETUS
      ? koodistoMappingPo
      : koulutustyyppi === koulutustyypitMap.TAITEEN_PERUSOPETUS
      ? koodistoMappingTPO
      : koodistoMappingLukio;

  const rajoiteId = compose(
    last,
    split("_"),
    cObj => getAnchorPart(cObj.anchor, 0),
    head
  )(asetukset);

  const asetusChangeObj = find(
    pipe(prop("anchor"), asetus => {
      return endsWith("kohde", asetus) || endsWith("kohde.valikko", asetus);
    }),
    asetukset
  );
  const valueChangeObj = find(
    pipe(prop("anchor"), anchor => {
      return (
        endsWith("tarkennin.komponentti", anchor) ||
        endsWith("tarkennin.lukumaara", anchor)
      );
    }),
    asetukset
  );
  const valueOfValueChangeObj = path(["properties", "value"], valueChangeObj);
  const valueValueOfAsetusChangeObj = path(
    ["properties", "value", "value"],
    asetusChangeObj
  );
  const sectionOfAsetusChangeObj =
    path(["properties", "metadata", "section"], asetusChangeObj) ||
    valueValueOfAsetusChangeObj;

  let koodiarvo =
    path(["properties", "value", "value"], valueChangeObj) ||
    path(["properties", "metadata", "koodiarvo"], asetusChangeObj);

  // Käsittele aikamääre rajoite
  let alkupvm = null;
  let loppupvm = null;
  if (
    valueValueOfAsetusChangeObj &&
    includes("kujalisamaareetlisaksiajalla", valueValueOfAsetusChangeObj)
  ) {
    alkupvm = path(
      ["properties", "value"],
      find(compose(endsWith(".alkamispaiva"), prop("anchor")), asetukset)
    );

    loppupvm = path(
      ["properties", "value"],
      find(compose(endsWith(".paattymispaiva"), prop("anchor")), asetukset)
    );
  }

  let koodisto = "";

  if (valueValueOfAsetusChangeObj) {
    // Kyseessä voi olla pudotusvalikko, jolloin koodiston arvo löytyy
    // pudotusvalikosta valitun arvon perusteella { label: ..., value: X }
    koodisto =
      prop(valueValueOfAsetusChangeObj, koodistoMapping) ||
      head(split("_", valueValueOfAsetusChangeObj));

    if (koodisto === "oppilaitos") {
      koodiarvo = "1";
    } else {
      koodiarvo = koodiarvo || last(split("_", valueValueOfAsetusChangeObj));
    }
  } else if (sectionOfAsetusChangeObj) {
    // Jossain tapauksessa elementti ei ole pudotusvalikko, joten
    // koodistoarvo tulee etsiä toisella tavalla. Tällaisissa tapauksissa
    // voidaan hyödyntää muutosobjektin metadataa, jonne tieto on kenties
    // laitettu talteen.
    koodisto = prop(sectionOfAsetusChangeObj, koodistoMapping);
  }

  const tunniste = path(["kohde", "tunniste"], paalomakkeenBEMuutos);

  const alimaarayksenParent =
    asetuksenIndex === 0 && kohdennuksenKohdeNumber < 0
      ? prop("generatedId", paalomakkeenBEMuutos)
      : asetuksenIndex === 0 && kohdennuksenKohdeNumber >= 0
      ? prop("generatedId", head(muutosobjektit))
      : prop("generatedId", last(muutosobjektit));

  let arvo =
    valueChangeObj && endsWith("lukumaara", path(["anchor"], valueChangeObj))
      ? valueOfValueChangeObj
      : null;

  const multiSelectValues = Array.isArray(valueOfValueChangeObj)
    ? valueOfValueChangeObj
    : [valueOfValueChangeObj];
  const mapIndex = addIndex(map);

  const multiSelectUuid = length(multiSelectValues) > 1 ? uuidv4() : null;
  const parent = isMaarays
    ? asetuksenIndex !== 0
      ? alimaarayksenParent
      : null
    : alimaarayksenParent;
  const parentMaaraysUuid = isMaarays
    ? asetuksenIndex === 0
      ? alimaarayksenParent
      : null
    : null;

  asetuksenIndex++;

  const result = pipe(
    mapIndex((multiselectValue, multiIndex) => {
      let codeValue = "";
      if (koodisto === "oppilaitos") {
        codeValue = koodiarvo;
      } else {
        if (prop("value", multiselectValue)) {
          /** Dynaamisilla tekstikentillä multiselectValue->value on muotoa koodiarvo-kuvausnumero.
           * Muutokselle halutaan tallentaa pelkkä koodiarvo, joten otetaan se talteen */
          codeValue = includes("-", prop("value", multiselectValue))
            ? head(split("-", prop("value", multiselectValue)))
            : prop("value", multiselectValue);
        } else {
          codeValue = koodiarvo;
        }
      }
      const alimaarays = reject(isNil, {
        generatedId: `alimaarays-${Math.random()}`,
        parent: parent,
        parentMaaraysUuid: parentMaaraysUuid,
        kohde: find(propEq("tunniste", tunniste), kohteet),
        koodiarvo:
          koodisto === "kielikoodistoopetushallinto"
            ? toLower(codeValue)
            : codeValue,
        koodisto,
        tila: "LISAYS",
        arvo,
        maaraystyyppi: find(propEq("tunniste", "RAJOITE"), maaraystyypit),
        meta: {
          ...(alkupvm
            ? { alkupvm: moment(alkupvm).format("YYYY-MM-DD") }
            : null),
          ...(loppupvm
            ? { loppupvm: moment(loppupvm).format("YYYY-MM-DD") }
            : null),
          ...(prop("isValtakunnallinenKehittamistehtava", paalomakkeenBEMuutos)
            ? { valtakunnallinenKehittamistehtava: true }
            : null),
          ...(multiSelectUuid ? { multiselectUuid: multiSelectUuid } : null),
          changeObjects: isMaarays
            ? values(rajoite)
            : asetukset.filter(Boolean),
          kuvaus: prop("label", multiselectValue),
          rajoiteId
        },
        orgOid:
          koodisto === "oppilaitos"
            ? prop("value", multiselectValue)
            : undefined
      });

      const updatedMuutosobjektit = append(alimaarays, muutosobjektit);

      return createAlimaarayksetBEObjects(
        kohteet,
        maaraystyypit,
        paalomakkeenBEMuutos,
        rajoite,
        updatedMuutosobjektit,
        asetuksenIndex,
        kohdennuksenKohdeNumber,
        insideMulti || multiIndex > 0
      );
    }),
    flatten,
    uniqBy(prop("generatedId"))
  )(multiSelectValues);
  insideMulti = false;
  return result;
};

export const createBeObjsForRajoitepoistot = (
  rajoitepoistot = [],
  maaraykset,
  kohteet,
  maaraystyypit
) => {
  /** Haetaan opiskelijamäärärajoitteet erikseen, koska niiltä pitää poistaa parent määräys */
  const opiskelijamaararajoitteet = filter(
    maarays =>
      maarays.koodisto === "kujalisamaareet" &&
      path(["maaraystyyppi", "tunniste"], maarays) === "RAJOITE",
    maaraykset || []
  );

  /** Haetaan loput rajoitemääräykset */
  const muutRajoitemaaraykset = filter(
    maarays =>
      length(prop("aliMaaraykset", maarays) || []) &&
      maarays.koodisto !== "kujalisamaareet",
    maaraykset || []
  );

  /** Luodaan poisto muutos-objektit opiskelijamäärärajoitteille */
  const poistoCobjsOpiskelijamaararajoitteet = map(
    maarays =>
      createRajoitepoistoBEObjs(
        [maarays],
        rajoitepoistot,
        kohteet,
        maaraystyypit
      ),
    opiskelijamaararajoitteet
  ).filter(Boolean);

  /** Luodaan poisto muutos-objektit muille rajoitteille */
  const poistoCobjsMuutrajoitteet = map(
    maarays =>
      createRajoitepoistoBEObjs(
        maarays.aliMaaraykset,
        rajoitepoistot,
        kohteet,
        maaraystyypit
      ),
    muutRajoitemaaraykset
  ).filter(Boolean);

  return filter(
    arr => length(arr),
    concat(poistoCobjsMuutrajoitteet, poistoCobjsOpiskelijamaararajoitteet)
  );
};

export const createRajoitepoistoBEObjs = (
  alimaaraykset,
  rajoitepoistot,
  kohteet,
  maaraystyypit
) => {
  return length(alimaaraykset)
    ? map(alimaarays => {
        if (
          find(
            poisto =>
              pathEq(
                ["properties", "rajoiteId"],
                path(["meta", "rajoiteId"], alimaarays),
                poisto
              ),
            rajoitepoistot
          )
        ) {
          return {
            kohde: find(
              propEq("tunniste", path(["kohde", "tunniste"], alimaarays)),
              kohteet
            ),
            koodiarvo: alimaarays.koodiarvo,
            koodisto: alimaarays.koodisto,
            maaraystyyppi: find(propEq("tunniste", "RAJOITE"), maaraystyypit),
            tila: "POISTO",
            maaraysUuid: alimaarays.uuid,
            meta: {
              changeObjects: [
                {
                  anchor: `rajoitepoistot.${alimaarays.uuid}`,
                  properties: {
                    rajoiteId: path(["meta", "rajoiteId"], alimaarays)
                  }
                }
              ]
            }
          };
        }
        return null;
      }, alimaaraykset).filter(Boolean)
    : null;
};
