import { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { toUpper, map, groupBy, prop } from "ramda";
import Koulutusala from "./Koulutusala";
import { Typography } from "@mui/material";
import common from "i18n/definitions/common";
import FormTitle from "components/00-atoms/FormTitle";
import { PaikallisenTietovarastonAvain } from "enums";
import localForage from "localforage";
import TutkintoLisatiedot from "./TutkintoLisatiedot.tsx";

const defaultProps = {
  isReadOnly: false
};

const Tutkinnot = ({
  code,
  isPreviewModeOn,
  isReadOnly = defaultProps.isReadOnly,
  koulutusalat,
  koulutustyypit,
  mode,
  title,
  maaraykset
}) => {
  const intl = useIntl();
  const sectionId = "tutkinnot";
  const localeUpper = toUpper(intl.locale);
  const [tutkinnot, setTutkinnot] = useState([]);

  useEffect(() => {
    localForage
      .getItem(PaikallisenTietovarastonAvain.TUTKINNOT)
      .then(tutkinnot => {
        setTutkinnot(tutkinnot);
      });
  }, []);

  const tutkinnotByKoulutusala = groupBy(
    prop("koulutusalakoodiarvo"),
    tutkinnot
  );

  return (
    <Fragment>
      <div className="pt-16">
        <div className="p-none">
          <FormTitle
            code={String(code)}
            level={isReadOnly ? 3 : 2}
            title={title}
          />
        </div>
      </div>

      <Typography component="h3" variant="h3">
        {intl.formatMessage(common.tutkinnot)}
      </Typography>

      {map(koulutusala => {
        if (tutkinnotByKoulutusala[koulutusala.koodiarvo]) {
          const title = koulutusala.metadata[localeUpper].nimi;
          const tutkinnotByKoulutustyyppi = groupBy(
            prop("koulutustyyppikoodiarvo"),
            tutkinnotByKoulutusala[koulutusala.koodiarvo]
          );
          const lomakedata = {
            koulutusala,
            koulutustyypit: koulutustyypit,
            title,
            tutkinnotByKoulutustyyppi
          };
          return (
            <Koulutusala
              data={lomakedata}
              isPreviewModeOn={isPreviewModeOn}
              isReadOnly={isReadOnly}
              key={koulutusala.koodiarvo}
              mode={mode}
              sectionId={`${sectionId}_${koulutusala.koodiarvo}`}
              title={title}
              tutkinnot={
                tutkinnotByKoulutusala[koulutusala.koodiarvo]
              }></Koulutusala>
          );
        }
        return null;
      }, koulutusalat)}
      <TutkintoLisatiedot
        isPreviewModeOn={isPreviewModeOn}
        title={""}
        maaraykset={maaraykset}
        isReadOnly={isReadOnly}
        key={`lisatiedot_tutkinnot`}
        mode={mode}
        sectionId={`lisatiedot_tutkinnot`}
      />
    </Fragment>
  );
};

Tutkinnot.propTypes = {
  code: PropTypes.string,
  isPreviewModeOn: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  koulutusalat: PropTypes.array,
  koulutustyypit: PropTypes.array,
  mode: PropTypes.string,
  title: PropTypes.string,
  maaraykset: PropTypes.array
};

export default Tutkinnot;
