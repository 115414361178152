import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { PropTypes } from "prop-types";
import { useIntl } from "react-intl";
import DialogTitle from "components/02-organisms/DialogTitle";
import Autocomplete from "components/02-organisms/Autocomplete";
import common from "i18n/definitions/common";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import { map, prop, sortBy, trim, propEq, find } from "ramda";
import { resolveLocalizedOrganizationName } from "modules/helpers";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles, withStyles } from "@mui/styles";
import { fetchJSON } from "basedata";
import { backendRoutes } from "stores/utils/backendRoutes";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import { AppRoute } from "routes";
import { koulutustyypitMap } from "utils/constants";

const StyledButton = withStyles({
  root: {
    color: "#4C7A61",
    fontWeight: 400,
    fontSize: "0.9375rem",
    textTransform: "none"
  }
})(Button);

const StyledErrorIcon = withStyles({
  root: {
    color: "#E5C418"
  }
})(ErrorIcon);

const useStyles = makeStyles({
  fakeDisabled: {
    backgroundColor: "#B7CAC0",
    "&:hover": {
      backgroundColor: "#B7CAC0"
    }
  }
});

const defaultProps = {
  organisations: []
};

const UusiAsiaEsidialog = ({
  isVisible,
  onClose,
  onSelect,
  organisations = defaultProps.organisations,
  baseDataContent
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [viimeisinLupa, setViimeisinLupa] = useState();

  /* Oppilaitoksen ylläpitäjä, oppilaitostyyppi ja oppilaitos valikkojen tilat */
  const [selectedYllapitaja, setSelectedYllapitaja] = useState();
  const [selectedOppilaitostyyppi, setSelectedOppilaitostyyppi] = useState();
  const [selectedOppilaitos, setSelectedOppilaitos] = useState();

  /* Y-tunnushaun tuloksena haettu oppilaitoksen yllapitaja */
  const [yllapitajaSearchResult, setYllapitajaSearchResult] = useState();

  const [isSearchFieldVisible, setIsSearchFieldVisible] = useState(false);
  const [organisationStatus, setOrganisationStatus] = useState();
  const inputEl = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [existingMuutospyynto, setExistingMuutospyynto] = useState(false);
  const [oppilaitokset, setOppilaitokset] = useState([]);
  const [isYllapitajaMissing, setIsYllapitajaMissing] = useState(false);
  const [isOppilaitosTyyppiMissing, setIsOppilaitosTyyppiMissing] =
    useState(false);
  const [isOppilaitosMissing, setIsOppilaitosMissing] = useState(false);
  const [muutospyyntoKorjauksessa, setMuutospyyntoKorjauksessa] =
    useState(false);

  /* Päätellään oppilaitoksen ylläpitäjän oid joko valikon tai y-tunnus hakutuloksen perusteella */
  const currentYllapitajaOid = useMemo(() => {
    if (isSearchFieldVisible && yllapitajaSearchResult) {
      return yllapitajaSearchResult.oid;
    }

    if (!isSearchFieldVisible && selectedYllapitaja) {
      return selectedYllapitaja.value;
    }

    return null;
  }, [isSearchFieldVisible, yllapitajaSearchResult, selectedYllapitaja]);

  /**
   * Päätellään, tuleeko esidialogin lähetys disabloida
   */
  const isAcceptDisabled = useMemo(
    () =>
      !(currentYllapitajaOid && selectedOppilaitos && selectedOppilaitostyyppi),
    [currentYllapitajaOid, selectedOppilaitos, selectedOppilaitostyyppi]
  );

  /**
   * Suoritetaan opetuksen ylläpitäjän haku y-tunnuksella tai oidilla
   */
  const searchYllapitajaById = useCallback(async () => {
    const { value: id } = inputEl.current;
    setIsLoading(true);
    const result = await fetchJSON(
      `${backendRoutes.organisaatio.path}/${trim(id)}`
    );
    setIsLoading(false);
    setYllapitajaSearchResult(result);
    if (result) {
      if (result.status === "PASSIIVINEN") {
        setOrganisationStatus("passive");
      } else {
        setOrganisationStatus("ok");
      }
    } else {
      setYllapitajaSearchResult(null);
      setOrganisationStatus("notfound");
    }
  }, [organisations]);

  /**
   * Päivitetään oppilaitoslista, kun oppilaitoksen ylläpitäjää muutetaan
   */
  useEffect(() => {
    async function fetchOppilaitokset() {
      const oppilaitoksetByOidURI = `${backendRoutes.oppilaitoksetByOid.path}${currentYllapitajaOid}${backendRoutes.oppilaitoksetByOid.postfix}`;
      const oppilaitokset = await fetchJSON(oppilaitoksetByOidURI);
      setOppilaitokset(oppilaitokset);
    }

    if (currentYllapitajaOid) {
      // Tyhjennetään oppilaitosvalikko ja poistetaan oppilaitosvalinta
      setSelectedOppilaitos(null);
      setOppilaitokset([]);
      fetchOppilaitokset();
    }
  }, [currentYllapitajaOid]);

  // Käyttäjän tehdessä organisaatiohaun tai vaihtaessa oppilaitostyyppiä
  // tarkistetaan onko KJ:llä jo kyseisellä oppilaitostyypillä lupaa.
  async function fetchViimeisinLupa() {
    if (
      currentYllapitajaOid &&
      selectedOppilaitostyyppi &&
      selectedOppilaitos
    ) {
      const { value: oppilaitostyyppiKoodi } = selectedOppilaitostyyppi;
      const { value: oppilaitosOid } = selectedOppilaitos;
      const result = await fetchJSON(
        `${backendRoutes.viimeisinLupa.path}${currentYllapitajaOid}${backendRoutes.viimeisinLupa.postfix}?koulutustyyppi=${koulutustyypitMap.VAPAASIVISTYSTYO}&oppilaitostyyppi=${oppilaitostyyppiKoodi}&oppilaitosOid=${oppilaitosOid}`
      );
      setViimeisinLupa(result);
    }
  }

  useEffect(() => {
    fetchViimeisinLupa();
  }, [currentYllapitajaOid, selectedOppilaitostyyppi, selectedOppilaitos]);

  /* Tarkistetaan olemassa oleva muutospyyntö */
  useEffect(() => {
    async function checkExisting() {
      if (
        currentYllapitajaOid &&
        selectedOppilaitostyyppi &&
        selectedOppilaitos
      ) {
        setMuutospyyntoKorjauksessa(false);
        const { value: oppilaitosOid } = selectedOppilaitos;
        const result = await fetchJSON(
          `${backendRoutes.muutospyynnot.path}?koulutustyyppi=${koulutustyypitMap.VAPAASIVISTYSTYO}&oppilaitostyyppi=${selectedOppilaitostyyppi.value}&oppilaitosOid=${oppilaitosOid}&oid=${currentYllapitajaOid}&tilat=VALMISTELUSSA&tilat=ESITTELYSSA&tilat=KORJAUKSESSA&vainOmat=false`
        );
        if (result) {
          const korjauksessa = find(propEq("tila", "KORJAUKSESSA"), result);
          if (korjauksessa) {
            setMuutospyyntoKorjauksessa(true);
          } else {
            setExistingMuutospyynto(result.length > 0);
          }
        }
      }
    }

    checkExisting();
  }, [selectedYllapitaja, selectedOppilaitostyyppi, selectedOppilaitos]);

  return organisations ? (
    <Dialog open={isVisible} PaperProps={{ style: { overflowY: "visible" } }}>
      <DialogTitle onClose={onClose}>
        {intl.formatMessage(common.luoUusiAsia)}
      </DialogTitle>
      <DialogContent style={{ overflowY: "visible" }}>
        <div className="px-8 py-4 relative">
          <Fragment>
            <p className="mb-6">
              {intl.formatMessage(
                isSearchFieldVisible
                  ? common.luoUusiAsiaEsidialogiInfo3VST
                  : common.luoUusiAsiaInstructionsVST
              )}
            </p>
            <div className="mb-4">
              {isSearchFieldVisible ? (
                <Fragment>
                  <Typography component="h4" variant="h4">
                    {intl.formatMessage(common.haeKJVST)}
                  </Typography>
                  <div className="flex items-center">
                    <FormControl style={{ flexGrow: "1" }}>
                      <TextField
                        id="search-field"
                        label={intl.formatMessage(common.syotaHaettavaTunniste)}
                        InputProps={{
                          endAdornment: isLoading ? (
                            <CircularProgress style={{ height: "auto" }} />
                          ) : (
                            <IconButton
                              type="button"
                              aria-label={intl.formatMessage(
                                common.suoritaYtunnushaku
                              )}
                              onClick={searchYllapitajaById}
                              size="large">
                              <SearchIcon />
                            </IconButton>
                          ),
                          inputRef: inputEl,
                          onKeyUp: e => {
                            return e.key === "Enter"
                              ? searchYllapitajaById()
                              : null;
                          }
                        }}
                        variant="outlined"
                      />
                    </FormControl>
                    <StyledButton
                      onClick={() => {
                        setYllapitajaSearchResult(null);
                        setSelectedOppilaitostyyppi(null);
                        setSelectedOppilaitos(null);
                        setIsSearchFieldVisible(false);
                      }}
                      style={{ marginLeft: "auto" }}>
                      {intl.formatMessage(common.suljeHaku)}
                    </StyledButton>
                  </div>
                  <Fragment>
                    {yllapitajaSearchResult &&
                    organisationStatus === "ok" &&
                    !viimeisinLupa ? (
                      <div>
                        <p className="my-4 text-gray-500 text-xs">
                          {intl.formatMessage(common.haullaLoytyiKJVST)}
                        </p>
                        <p className="mb-2">
                          <CheckIcon color="primary" />{" "}
                          {yllapitajaSearchResult.nimi.fi ||
                            yllapitajaSearchResult.nimi.sv}
                        </p>
                      </div>
                    ) : null}
                    {organisationStatus === "notfound" ? (
                      <div>
                        <p className="my-4 text-gray-500 text-xs">
                          {intl.formatMessage(common.KJHakuEpaonnistui)}
                        </p>
                        <p className="mb-2">
                          {intl.formatMessage(common.KJHakuEpaonnistuiLisainfo)}{" "}
                          <a
                            href={`mailto:${intl.formatMessage(
                              common.yhteisetpalvelutEmailAddress
                            )}`}>
                            {intl.formatMessage(
                              common.yhteisetpalvelutEmailAddress
                            )}
                          </a>
                        </p>
                      </div>
                    ) : null}
                    {yllapitajaSearchResult &&
                    organisationStatus === "ok" &&
                    viimeisinLupa ? (
                      <div>
                        <p className="my-4 text-gray-500 text-xs">
                          {intl.formatMessage(common.haullaLoytyiKJVST)}
                        </p>
                        <p className="mb-2 text-xl">
                          <StyledErrorIcon />{" "}
                          {yllapitajaSearchResult.nimi.fi ||
                            yllapitajaSearchResult.nimi.sv}
                        </p>
                        <p className="mb-2">
                          {intl.formatMessage(common.loytyyjoVoimassaOlevaLupa)}{" "}
                        </p>
                      </div>
                    ) : null}
                    {yllapitajaSearchResult &&
                    organisationStatus === "passive" ? (
                      <div>
                        <p className="my-4 text-gray-500 text-xs">
                          {intl.formatMessage(common.haullaLoytyiKJVST)}
                        </p>
                        <p className="mb-2 text-xl">
                          <StyledErrorIcon />{" "}
                          {yllapitajaSearchResult.nimi.fi ||
                            yllapitajaSearchResult.nimi.sv}
                        </p>
                        <p className="mb-2">
                          {intl.formatMessage(common.KJPassiivinen)}{" "}
                        </p>
                      </div>
                    ) : null}
                  </Fragment>
                </Fragment>
              ) : (
                <Autocomplete
                  id="list-of-koulutuksen-jarjestaja"
                  isMulti={false}
                  name="koulutuksen-jarjestaja"
                  placeholder={intl.formatMessage(
                    common.valitseOppilaitoksenYllapitaja
                  )}
                  options={sortBy(
                    prop("label"),
                    map(organisation => {
                      return organisation
                        ? {
                            label: resolveLocalizedOrganizationName(
                              organisation,
                              intl.locale
                            ),
                            value: organisation.oid
                          }
                        : null;
                    }, organisations)
                  ).filter(Boolean)}
                  callback={(payload, value) => {
                    setIsYllapitajaMissing(false);
                    setSelectedYllapitaja(value.value);
                  }}
                  title={""}
                  value={[selectedYllapitaja]}
                />
              )}
            </div>
            {(selectedYllapitaja || yllapitajaSearchResult) && (
              <div className="mb-4">
                <Autocomplete
                  id="list-of-vsttyypit"
                  isMulti={false}
                  name="vst-tyypit"
                  placeholder="Valitse oppilaitostyyppi"
                  options={sortBy(
                    prop("label"),
                    map(vstTyyppi => {
                      return vstTyyppi
                        ? {
                            label:
                              vstTyyppi.metadata[intl.locale.toUpperCase()]
                                .nimi,
                            value: vstTyyppi.koodiarvo
                          }
                        : null;
                    }, baseDataContent.vstTyypit)
                  ).filter(Boolean)}
                  callback={(payload, values) => {
                    setIsOppilaitosTyyppiMissing(false);
                    setSelectedOppilaitostyyppi(values.value);
                  }}
                  title={""}
                  value={[selectedOppilaitostyyppi]}
                  isDisabled={
                    isSearchFieldVisible
                      ? !yllapitajaSearchResult
                      : !selectedYllapitaja
                  }
                />
              </div>
            )}
            {selectedOppilaitostyyppi && (
              <Autocomplete
                id="list-of-oppilaitokset"
                isMulti={false}
                placeholder="Valitse oppilaitos"
                name="koulutuksen-jarjestaja"
                options={sortBy(
                  prop("label"),
                  map(organisation => {
                    return organisation
                      ? {
                          label: resolveLocalizedOrganizationName(
                            organisation,
                            intl.locale
                          ),
                          value: organisation.oid
                        }
                      : null;
                  }, oppilaitokset)
                ).filter(Boolean)}
                callback={(payload, values) => {
                  setIsOppilaitosMissing(false);
                  setSelectedOppilaitos(values.value);
                }}
                isDisabled={!selectedOppilaitostyyppi}
                title={""}
                value={[selectedOppilaitos]}
              />
            )}
            {!isSearchFieldVisible && (
              <Fragment>
                <p className="my-4">
                  {intl.formatMessage(common.luoUusiAsiaEsidialogiInfo3VST)}
                </p>
                <StyledButton
                  onClick={() => {
                    setSelectedYllapitaja(null);
                    setSelectedOppilaitostyyppi(null);
                    setSelectedOppilaitos(null);
                    setIsSearchFieldVisible(true);
                  }}
                  startIcon={<SearchIcon />}>
                  {intl.formatMessage(common.haeKJVST)}
                </StyledButton>
              </Fragment>
            )}
            {currentYllapitajaOid && existingMuutospyynto ? (
              <p className="mt-2">
                <StyledErrorIcon />{" "}
                {intl.formatMessage(common.existingMuutospyynto)}
              </p>
            ) : null}
            {!isAcceptDisabled &&
            !existingMuutospyynto &&
            !muutospyyntoKorjauksessa &&
            viimeisinLupa === null ? (
              <p className="mt-4 mb-12">
                <StyledErrorIcon />{" "}
                {intl.formatMessage(common.VSTOpetuksenYllapitajallaEiLupaa)}
              </p>
            ) : null}
            {isYllapitajaMissing ? (
              <p className="mt-4 mb-12">
                <StyledErrorIcon />{" "}
                {intl.formatMessage(common.oneYllapitajaMustBeSelected)}
              </p>
            ) : null}
            {isOppilaitosTyyppiMissing ? (
              <p className="mt-4 mb-12">
                <StyledErrorIcon />{" "}
                {intl.formatMessage(common.oneOppilaitosTyyppiMustBeSelected)}
              </p>
            ) : null}
            {isOppilaitosMissing ? (
              <p className="mt-4 mb-12">
                <StyledErrorIcon />{" "}
                {intl.formatMessage(common.oneOppilaitosMustBeSelected)}
              </p>
            ) : null}
            {muutospyyntoKorjauksessa ? (
              <p className="mt-4 mb-12">
                <StyledErrorIcon />{" "}
                {intl.formatMessage(common.lupaOnKorjauksessa)}
              </p>
            ) : null}
          </Fragment>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="flex pr-6 pb-4">
          <div className="mr-4">
            <Button onClick={onClose} color="primary" variant="outlined">
              {intl.formatMessage(common.cancel)}
            </Button>
          </div>
          <Button
            is-fake-disabled={isAcceptDisabled ? "true" : "false"}
            className={isAcceptDisabled ? classes.fakeDisabled : ""}
            onClick={() => {
              const yllapitaja =
                isSearchFieldVisible && yllapitajaSearchResult
                  ? { value: yllapitajaSearchResult.oid }
                  : selectedYllapitaja;
              if (!yllapitaja) {
                setIsYllapitajaMissing(true);
              } else if (
                !selectedOppilaitostyyppi ||
                !selectedOppilaitostyyppi.value
              ) {
                setIsOppilaitosTyyppiMissing(true);
              } else if (!selectedOppilaitos || !selectedOppilaitos.value) {
                setIsOppilaitosMissing(true);
              } else {
                return onSelect(
                  yllapitaja,
                  { value: "fi" },
                  AppRoute.UusiHakemusVST,
                  {
                    oppilaitosId: selectedOppilaitos.value,
                    oppilaitostyyppi: selectedOppilaitostyyppi.value
                  }
                );
              }
            }}
            color="primary"
            disabled={existingMuutospyynto || muutospyyntoKorjauksessa}
            variant="contained">
            {intl.formatMessage(common.accept)}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  ) : null;
};

UusiAsiaEsidialog.propTypes = {
  // Boolean that tells if the dialog is open or closed.
  isVisible: PropTypes.bool,
  // Function that will be called when the dialog is going to be closed / hided.
  onClose: PropTypes.func.isRequired,
  // Function that will be called when user selects a koulutuksen järjestäjä
  onSelect: PropTypes.func.isRequired,
  // List of organisations
  organisations: PropTypes.array,
  // Haettu base data kokonaisuudessaan
  baseDataContent: PropTypes.object
};

export default UusiAsiaEsidialog;
