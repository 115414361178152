import { getRaw } from "basedata";
import { CellFn } from "graphHandling/graphTypes";
import { backendRoutes } from "stores/utils/backendRoutes";

export const fetch_kieletOPH: CellFn = async () => {
  try {
    return await getRaw("kieletOPH", backendRoutes.kieletOPH.path);
  } catch (err) {
    throw new Error(err as string);
  }
};
