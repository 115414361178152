import {
  addIndex,
  find,
  flatten,
  forEach,
  includes,
  is,
  isEmpty,
  isNil,
  join,
  mapObjIndexed,
  path,
  pathEq,
  prop,
  reject,
  split,
  values
} from "ramda";
import { IntlShape } from "react-intl";
import {
  CellImplementations,
  GraphDefinition,
  CellDefinition,
  GraphStoreFunctions,
  GraphFlow,
  EventPayload
} from "./graphTypes";
import { Out } from "graphHandling/graphTypes";
import cytoscape from "cytoscape";
import cola from "cytoscape-cola";
// import avsdf from "cytoscape-avsdf";

let timeoutId: number | undefined = undefined;

export function drawGraph(
  graphCanvasId: string,
  graphDefinition: GraphDefinition
): unknown {
  const canvas = document.getElementById(graphCanvasId);

  if (canvas) {
    // Muodostetaan cytoscapelle oikeamuotoinen JSON.
    const nodes = values(
      addIndex(mapObjIndexed)((value, cellId) => {
        const cellDefinition = value as CellDefinition;
        return {
          classes: cellDefinition.isFirstCell ? "start" : "",
          data: { id: cellId, label: cellDefinition.name || cellId }
        };
      }, graphDefinition.cells)
    );

    const edges = reject(
      isNil,
      flatten(
        values(
          mapObjIndexed((value, key) => {
            return value.out
              ? flatten([
                  values(
                    mapObjIndexed((outValue, outKey) => {
                      const edgeTarget = find(
                        pathEq(["data", "id"], outKey),
                        nodes
                      );
                      const { edge, events } = outValue;

                      return edgeTarget
                        ? {
                            classes: events ? "dashed" : "solid",
                            data: {
                              id: `${key}-${outKey}`,
                              source: key,
                              target: outKey,
                              label: edge
                                ? edge.name
                                : events
                                ? join(" / ", events)
                                : ""
                            }
                          }
                        : null;
                    }, value.out)
                  )
                ])
              : null;
          }, graphDefinition.cells)
        )
      )
    );

    cytoscape.use(cola);

    const cy = cytoscape({
      autounselectify: true,
      boxSelectionEnabled: false,
      container: canvas,
      elements: {
        nodes,
        edges
      },
      style: [
        {
          selector: "edge",
          style: {
            "arrow-scale": 0.5,
            "curve-style": "bezier",
            "font-size": "6px",
            label: "data(label)",
            "line-color": "#ddd",
            opacity: 0.5,
            "target-arrow-color": "#00D0DD",
            "target-arrow-shape": "triangle",
            width: 1
          }
        },
        {
          selector: "node",
          style: {
            height: "5rem",
            width: "5rem",
            "font-size": "6px",
            label: "data(label)"
          }
        },
        {
          selector: ".start",
          style: {
            height: "10rem",
            "border-style": "solid",
            "border-color": "green",
            "border-width": "2px",
            "background-color": "#39ff14",
            width: "10rem",
            "font-size": "6px",
            label: "data(label)"
          }
        },
        {
          selector: ".dashed",
          style: {
            opacity: 0.5,
            "line-color": "#000000",
            "line-style": "dashed",
            "target-arrow-color": "#000000"
          }
        },
        {
          selector: ".highlighted",
          style: {
            "background-color": "#61bffc",
            "line-color": "#61bffc",
            "target-arrow-color": "#61bffc",
            "transition-property":
              "background-color, line-color, target-arrow-color",
            "transition-duration": 1000,
            width: 4
          }
        },
        {
          selector: ".solid",
          style: {
            opacity: 0.8,
            "line-color": "#4c7a61",
            "target-arrow-color": "#000000"
          }
        },
        {
          selector: ".hasToken",
          style: {
            opacity: 1,
            "border-style": "solid",
            "border-color": "blue",
            "border-width": "1px",
            width: 10
          }
        }
      ]
    });

    const layout = cy.layout({
      name: "cola"
    });

    layout.run();

    return cy;
  }
}

export async function handleIncomingGraphToken(
  cellId: string,
  graphDefinition: GraphDefinition,
  storeFunctions: GraphStoreFunctions,
  intl: IntlShape,
  cellImplementations: CellImplementations,
  eventPayload?: EventPayload
): Promise<GraphDefinition> {
  storeFunctions.updateFlow(cellId);

  // Suoritetaan solun tehtävä.
  const cellFn = prop(cellId, cellImplementations);

  if (cellFn) {
    let result = await cellFn(storeFunctions, intl, eventPayload);

    if (is(Object, result)) {
      result = mapObjIndexed((value, key) => {
        if (typeof value === "function") {
          return async (payload: EventPayload): Promise<boolean> => {
            const eventPayload = await value(payload);

            // Käsitellään solusta ulos johtavat reitit.
            handleOutgoingRoutes(
              cellId,
              graphDefinition,
              storeFunctions,
              intl,
              cellImplementations,
              key, // e.g. key = onChange, onClick
              eventPayload
            );

            return true;
          };
        }
        return value;
      }, result as any) as any;

      storeFunctions.updateGraph(
        flatten(["components", split("_", cellId)]),
        result
      );
    }
  }

  // Käsitellään solusta ulos johtavat reitit
  handleOutgoingRoutes(
    cellId,
    graphDefinition,
    storeFunctions,
    intl,
    cellImplementations
  );

  const recentlyVisitedCells = storeFunctions.readPath(["flow"]) as GraphFlow;

  if (graphDefinition.cy) {
    graphDefinition.cy.nodes().removeClass("hasToken");

    forEach(flowItem => {
      const nodes = graphDefinition.cy.nodes(`[id = "${flowItem.cellId}"]`);

      if (nodes[0]) {
        nodes[0].addClass("hasToken");
      }
    }, recentlyVisitedCells);
  }

  return graphDefinition;
}

export async function handleOutgoingRoutes(
  cellId: string,
  graphDefinition: GraphDefinition,
  storeFunctions: GraphStoreFunctions,
  intl: IntlShape,
  cellImplementations: CellImplementations,
  eventName?: string,
  eventPayload?: EventPayload
): Promise<boolean> {
  const out: Out | undefined = path(["cells", cellId, "out"], graphDefinition);

  // Käsitellään solusta ulos johtavat reitit.
  if (out) {
    mapObjIndexed(async (route, _cellId: string) => {
      const exitRule1 =
        route.isConnected === undefined || route.isConnected(storeFunctions);
      const exitRule2 = eventName
        ? includes(eventName, route.events || [])
        : !route.events ||
          isEmpty(route.events) ||
          includes("onInit", route.events);
      if (exitRule1 && exitRule2) {
        // Cytoscape-graafin päivitys tehdään vain canvaksen ollessa käytössä.
        if (graphDefinition.cy) {
          const edges = graphDefinition.cy.edges(
            `[id = "${cellId}-${_cellId}"]`
          );
          if (edges[0]) {
            edges[0].addClass("highlighted");
          }

          if (timeoutId) {
            clearTimeout(timeoutId);
          }

          timeoutId = window.setTimeout(() => {
            graphDefinition.cy.edges().removeClass("highlighted");
          }, 3000);
        }
        return handleIncomingGraphToken(
          _cellId,
          graphDefinition,
          storeFunctions,
          intl,
          cellImplementations,
          eventPayload
        );
      }
    }, out);
  }

  return true;
}
