import {
  append,
  lensIndex,
  over,
  values,
  mapObjIndexed,
  equals,
  includes,
  remove,
  isNil,
  reject
} from "ramda";
import { ComponentWithMetadata, Properties } from "utils/lomakkeet";
import { ChangeObjects, getChangeObjIndexByAnchor } from "utils/muutokset";

/**
 * Päivittää muutosobjekteista koostuvan taulukon päivittämällä parametrina
 * annetun muutosobjektin ominaisuuksia parametrina annetun properties-
 * objektin sisällöllä.
 * @param componentWithMetadata - Object with an anchor and properties.
 * @param properties Ominaisuudet, jotka päivitetään parametrina annettuun
 * komponenttimääritykseen pohjautuvaan muutosobjektiin.
 * @param changeObjects Taulukollinen muutosobjekteja.
 * @returns Taulukollinen muutosobjekteja.
 */
export function updateChangeObjectsArray(
  componentWithMetadata: ComponentWithMetadata,
  properties: Properties,
  changeObjects: ChangeObjects
): ChangeObjects {
  // Etsitään indeksi, jossa muokattava muutosobjekti sijaitsee muutosobjektien
  // taulukossa.
  const changeObjIndex = getChangeObjIndexByAnchor(
    componentWithMetadata.fullAnchor,
    changeObjects
  );

  // Voi olla, että muutosobjekti on tarpeeton. Tarpeeton se on tilanteessa, jossa
  // komponenttimäärityksen ominaisuudet vastaavat täysin parametrina annetun
  // properties-objektin määrityksiä.
  const isChangeObjectNeeded = includes(
    false,
    values(
      mapObjIndexed((value, key) => {
        return equals(componentWithMetadata.properties[key], value);
      }, properties)
    )
  );

  // Jos muutosobjektia yhä tarvitaan...
  if (isChangeObjectNeeded) {
    // Jos muutosobjekti löytyi parametrina annetusta taulukosta...
    if (changeObjIndex > -1) {
      // Päivitetään muutosobjektiin parametrina saadut tiedot (properties)
      // ja päivitetään samalla muutosobjektien taulukko.
      changeObjects = over(
        lensIndex(changeObjIndex),
        changeObject => {
          return {
            ...changeObject,
            properties: Object.assign({}, changeObject.properties, properties)
          };
        },
        changeObjects
      );
    } else {
      // Jos muutosobjektia ei ole olemassa ennestään, on se luotava
      // properties-objektin sisältämillä tiedoilla. Tässä vaiheessa on
      // muistettava asettaa muutosobjektiin mukaan myös komponenttimäärityksen
      // metadata.
      changeObjects = append(
        {
          anchor: componentWithMetadata.fullAnchor,
          properties: reject(isNil)(
            Object.assign(
              {},
              {
                ...properties,
                metadata: componentWithMetadata.properties.forChangeObject
              }
            )
          )
        },
        changeObjects
      );
    }
  } else if (changeObjIndex > -1) {
    // Jos muutosobjekti on olemassa, mutta sille ei ole enää tarvetta,
    // poistetaan se.
    changeObjects = remove(changeObjIndex, 1, changeObjects);
  }

  return changeObjects;
}
