import { ReactElement } from "react";
import {
  filter,
  find,
  map,
  toUpper,
  isEmpty,
  propEq,
  path,
  length,
  addIndex,
  pathEq
} from "ramda";
import { IntlShape, useIntl } from "react-intl";
import common from "i18n/definitions/common";
import education from "i18n/definitions/education";
import Typography from "@mui/material/Typography";
import { getRajoitteetFromMaarays } from "utils/rajoitteetUtils";
import rajoitteet from "i18n/definitions/rajoitteet";
import { sortByLocale } from "helpers/html";
import { Lupamaarays } from "Lupa";
import LisatiedotHtmlLupa from "components/03-templates/HtmlLupanakyma/Lisatiedot";
import { Kieli } from "types";

type Props = {
  kieletOPH: Array<Kieli>;
  maaraykset: Array<Lupamaarays>;
};

export default function Opetuskielet({
  kieletOPH,
  maaraykset
}: Props): null | ReactElement {
  const intl = useIntl();
  const locale = toUpper(intl.locale);

  const ensisijaisetOpetuskielet = sortByLocale(
    locale,
    filter(
      maarays =>
        pathEq(["kohde", "tunniste"], "opetuskieli", maarays) &&
        maarays.koodisto === "kielikoodistoopetushallinto" &&
        pathEq(["meta", "valikko"], "ensisijaiset", maarays),
      maaraykset
    )
  );

  const toissijaisetOpetuskielet = sortByLocale(
    locale,
    filter(
      maarays =>
        pathEq(["kohde", "tunniste"], "opetuskieli", maarays) &&
        maarays.koodisto === "kielikoodistoopetushallinto" &&
        pathEq(["meta", "valikko"], "toissijaiset", maarays),
      maaraykset
    )
  );

  const lisatietomaarays = find(
    maarays =>
      pathEq(["kohde", "tunniste"], "opetuskieli", maarays) &&
      maarays.koodisto === "lisatietoja",
    maaraykset
  );

  return (!isEmpty(ensisijaisetOpetuskielet) ||
    !isEmpty(toissijaisetOpetuskielet)) &&
    !isEmpty(kieletOPH) ? (
    <div className="mt-4">
      <Typography component="h3" variant="h3">
        {intl.formatMessage(common.opetuskieli)}
      </Typography>
      {getOpetuskieletHtml(ensisijaisetOpetuskielet, kieletOPH, locale, intl)}
      {!isEmpty(toissijaisetOpetuskielet) && (
        <Typography component="h4" variant="h4">
          {intl.formatMessage(education.voidaanAntaaMyosSeuraavillaKielilla)}
        </Typography>
      )}
      {getOpetuskieletHtml(toissijaisetOpetuskielet, kieletOPH, locale, intl)}
      <LisatiedotHtmlLupa lisatietomaarays={lisatietomaarays} />
    </div>
  ) : null;
}

const getOpetuskieletHtml = (
  opetuskielet: Array<Lupamaarays>,
  kieletOPH: Array<Kieli>,
  locale: string,
  intl: IntlShape
): ReactElement => {
  return (
    <ul className="ml-8 list-disc mb-4">
      {addIndex(map)((_opetuskieli, index) => {
        const opetuskieli = _opetuskieli as Lupamaarays;
        return [
          <li
            key={opetuskieli.koodiarvo}
            className={`leading-bulletList ${
              length(opetuskieli.aliMaaraykset) ? "mb-2" : ""
            }`}>
            {path(
              ["metadata", locale, "nimi"],
              find(
                propEq("koodiarvo", toUpper(opetuskieli.koodiarvo)),
                kieletOPH
              )
            )}
            <div key={"div-" + index}>
              {length(opetuskieli.aliMaaraykset)
                ? getRajoitteetFromMaarays(
                    opetuskieli.aliMaaraykset,
                    locale,
                    intl.formatMessage(rajoitteet.ajalla)
                  )
                : ""}
            </div>
          </li>
        ];
      }, opetuskielet || [])}
    </ul>
  );
};
