export enum CellId {
  ACTION_KUMOA_MUUTOKSET = "action_kumoaMuutokset",
  ACTION_KUMOA_MUUTOKSET_SUOMI = "action_kumoaMuutoksetSuomi",

  ADD_CHIP = "add_chip",

  // Tarkistukset
  CHECK_ASIANUMERON_MUOTO = "check_asianumeron_muoto",
  CHECK_AVOIN_ASIA_FETCH = "voidaankoAvoinAsiaNoutaa",
  CHECK_AVOIN_ASIA_OLEMASSA = "onkoAvoinAsiaOlemassa",
  CHECK_OPETUSKIELET_RADIOS = "check_opetuskielet_radios",
  CHECK_ORGANISAATIOT_FETCH = "onkoOrganisaatiolistausVanhentunut",
  CHECK_PAALUPA = "onkoPaalupaOlemassa",
  CHECK_SEURAAVA_PAINIKE_UPDATE = "paivitetaankoSeuraavaPainikkeenTila",

  CREATE_TEXTBOX = "create_textbox",

  ETSI_OPPILAITOS = "etsi_oppilaitos",

  // Datahaut
  FETCH_ATV_KOULUTUKSET = "fetch_atvKoulutukset",
  FETCH_KOODISTO_AJALLA = "fetch_ajalla",
  FETCH_ASIANUMEROHAKU = "asianumerohaku",
  FETCH_AVOIN_ASIA = "avoimenAsianNoutaminen",
  FETCH_KOODISTO_JOISTA_LISAKSI = "fetch_joistaLisaksi",
  FETCH_KIELET = "fetch_kielet",
  FETCH_KIELET_OPH = "fetch_kieletOPH",
  FETCH_KOHTEET = "fetch_kohteet",
  FETCH_KOODISTO_LISATIETOJA = "koodisto_lisatietoja",
  FETCH_KOODISTO_OPETUKSEN_JARJESTAMISMUODOT = "fetch_opetuksenJarjestamismuodot",
  FETCH_KOODISTO_OPETUSKIELET_OPH = "fetch_koodistoOpetuskieletOPH",
  FETCH_KOODISTO_OPETUSTEHTAVAT = "fetch_koodistoOpetustehtavat",
  FETCH_KOODISTO_PO_ERITYISET_KOULUTUSTEHTAVAT = "koodisto_poErityisetKoulutustehtavat",
  FETCH_KOODISTO_PO_MUUT_EHDOT = "koodisto_poMuutEhdot",
  FETCH_KOULUTUSALAT = "fetch_koulutusalat",
  FETCH_KOULUTUSMUODOT = "koulutusmuotojenNoutaminen",
  FETCH_KOULUTUSTYYPIT = "fetch_koulutustyypit",
  FETCH_KOODISTO_KUJALISAMAAREET = "fetch_kujaLisamaareet",
  FETCH_KULJETTAJAKOULUTUKSET = "fetch_kuljettajakoulutukset",
  FETCH_KUNNAT = "fetch_kunnat",
  FETCH_LISATIEDOT = "fetch_lisatiedot",
  FETCH_LUKIO_MUUT_EHDOT = "fetch_lukioMuutEhdot",
  FETCH_LUKIO_ERITYINEN_KOULUTUSTEHTAVA_UUSI = "fetch_lukioErityinenKoulutustehtavaUusi",
  FETCH_LUPA_BY_OID = "fetch_lupaByOid",
  FETCH_LUPA_BY_UUID = "fetch_lupaByUuid",
  FETCH_LUPA_JA_MUUTOSPYYNTO = "fetch_lupaJaMuutospyynto",
  FETCH_LUVAN_TAITEENALAT = "fetch_luvanTaiteenalat",
  FETCH_LUVAN_TAITEENALAT_KOODISTO = "fetch_luvanTaiteenalatKoodisto",
  FETCH_LUVAT = "fetch_luvat",
  FETCH_MAAKUNNAT = "fetch_maakunnat",
  FETCH_MAAKUNTAKUNNAT = "fetch_maakuntakunnat",
  FETCH_MAARAYSTYYPIT = "fetch_maaraystyypit",
  FETCH_MUUT = "fetch_muut",
  FETCH_MUUT_EHDOT_TPO = "fetch_tpoMuutEhdot",
  FETCH_MUUTOSPYYNTO = "fetch_muutospyynto",
  FETCH_MUUTOSPYYNTO_CUSTOM = "custom.muutospyynto",
  FETCH_OIKEUS_SISAOPPILAITOSMUOTOISEEN_KOULUTUKSEEN = "fetch_oikeusSisaoppilaitosmuotoiseenKoulutukseen",
  FETCH_OIVAPERUSTELUT = "fetch_oivaperustelut",
  FETCH_OIVA_TYOVOIMAKOULUTUKSET = "fetch_oivaTyovoimakoulutus",
  FETCH_OPETUSKIELET = "fetch_opetuskielet",
  FETCH_OPETUSTEHTAVAT = "fetch_opetustehtavat",
  FETCH_OPPILAITOKSEN_VAIKUTUSALUE = "fetch_oppilaitoksenVaikutusalue",
  FETCH_OPPILAITOKSET_BY_OID = "fetch_oppilaitoksetByOid",
  FETCH_ORGANISATION = "fetch_organisaatio",
  FETCH_ORGANISATIONS = "fetch_organisations",
  FETCH_PAALUPA = "viimeisimmanPaaluvanNoutaminen",
  FETCH_POIKKEUS_999901 = "fetch_poikkeus_999901",
  FETCH_POIKKEUS_999903 = "fetch_poikkeus_999903",
  FETCH_TULEVAT_LUVAT = "fetch_tulevatLuvat",
  FETCH_TUTKINNOT = "fetch_tutkinnot",
  FETCH_VIIMEISIN_LUPA = "fetch_viimeisinLupa",
  FETCH_VST_ERITYISET_KOULUTUSTEHTAVAT = "fetch_vstErityisetKoulutustehtavat",
  FETCH_VST_MUUT = "fetch_vstMuut",
  FETCH_VST_TYYPIT = "fetch_vstTyypit",

  // Komponenttien alustaminen
  INITIALIZE_000 = "000_start",
  INITIALIZE_ASIANUMEROKENTTA = "asianumerokentta",
  INITIALIZE_AUTOCOMPLETE = "autocomplete",
  INITIALIZE_AUTOCOMPLETE_1 = "ensisijaiset",
  INITIALIZE_AUTOCOMPLETE_2 = "toissijaiset",
  INITIALIZE_EI_MAARITELTY_KUVAUS = "eiMaariteltyKuvaus",
  INITIALIZE_GRAPH = "initialize_graph",
  INITIALIZE_OPETUSKIELET_PROCESS = "startOfOpetuskielet",
  INITIALIZE_PROCESS = "process",
  INITIALIZE_PROCESS_ESI_JA_PERUSOPETUS = "initialize_esiJaPerusopetus",
  INITIALIZE_PROCESS_JARJESTAJA = "initialize_jarjestaja",
  INITIALIZE_PROCESS_JARJESTAMISLUPA_HTML = "initialize_jarjestamislupaHtml",
  INITIALIZE_PROCESS_MAAKUNNAT_JA_KUNNAT = "initialize_maakunnatJaKunnat",
  INITIALIZE_PROCESS_MUUTOSPYYNTOLOMAKE = "initialize_muutospyyntolomake",
  INITIALIZE_PROCESS_OPETUS_ULKOMAILLA = "initialize_opetusUlkomailla",
  INITIALIZE_PROCESS_TOIMINTAALUE = "initialize_toimintaalue",
  INITIALIZE_SUOMI_PROCESS = "initialize_suomiProcess",
  INITIALIZE_GROUPED_CHIP_COLLECTIONS = "groupedChipCollections",
  INITIALIZE_KOULUTUSMUOTOVALIKKO = "koulutusmuotovalikko",
  INITIALIZE_KUMOA_MUUTOKSET = "kumoaMuutokset",
  INITIALIZE_LISAA_PAINIKE = "lisaaPainike",
  INITIALIZE_LISATIEDOT = "lisatiedot",
  INITIALIZE_LISAYSPAINIKE = "lisayspainike",
  INITIALIZE_LOKALISAATIOVALIKKO = "lokalisaatiovalikko",
  INITIALIZE_LOMAKE = "initialize_lomake",
  INITIALIZE_MAARAT_VUODET_LISATIEDOT = "maarat_vuodet_lisatiedot",
  INITIALIZE_MAARAT_VUODET_RADIOS = "maarat_vuodet",
  INITIALIZE_MULTI_TEXTBOX = "multiTextBox",
  INITIALIZE_OPETUSKIELET_LISATIEDOT = "opetuskielet_lisatiedot",
  INITIALIZE_OPETUSKIELET_RADIOS = "opetuskielet_radios",
  INITIALIZE_OPETUSKIELIVALIKKO1 = "opetuskielivalikko1",
  INITIALIZE_OPETUSKIELIVALIKKO2 = "opetuskielivalikko2",
  INITIALIZE_ORGANISAATIOVALIKKO = "organisaatiovalikko",
  INITIALIZE_PAATOSPAIVAKENTTA = "paatospaivakentta",
  INITIALIZE_PAATTYMISPVMKENTTA = "paattymispvmkentta",
  INITIALIZE_PERUUTA_PAINIKE = "peruutaPainike",
  INITIALIZE_RADIO_BUTTON_COLLECTION = "rBC",
  INITIALIZE_RESTRICTIONS = "rajoitteet",
  INITIALIZE_SEURAAVA_PAINIKE = "seuraavaPainike",
  INITIALIZE_SUOMI_CHECKBOX = "suomiCheckbox",
  INITIALIZE_TALLENNUSPAINIKE = "tallennuspainike",
  INITIALIZE_TAYDENNA_ASIAA_PAINIKE = "taydennaAsiaaPainike",
  INITIALIZE_TOIMINTAALUE_LISATIEDOT = "toimintaalue_lisatiedot",
  INITIALIZE_TOIMINTAALUE_RADIOS = "toiminta-alue_radios",
  INITIALIZE_TUVA_ASIA_ESIVALINNAT = "uusi-tuva-asia-esivalinnat",
  INITIALIZE_ULKOMAAT_CHECKBOX = "ulkomaatCheckbox",
  INITIALIZE_VOIMAANTULOPAIVAKENTTA = "voimaantulopaivakentta",

  // Muokkaaminen muodosta toiseen
  KASITTELE_ATV_KOULUTUKSET = "kasittele_atvKoulutukset",
  KASITTELE_KIELET = "kasittele_kielet",
  KASITTELE_KIELET_OPH = "kasittele_kieletOPH",
  KASITTELE_KOODISTO_AJALLA = "kasittele_ajalla",
  KASITTELE_KOODISTO_ENSIJAISET_OPETUSKIELET_OPH = "kasittele_ensisijaiset_opetuskielet_oph",
  KASITTELE_KOODISTO_JOISTA_LISAKSI = "kasittele_joistaLisaksi",
  KASITTELE_KOODISTO_LISATIETOJA = "kasittele_koodisto_lisatietoja",
  KASITTELE_KOODISTO_OPETUSTEHTAVAT = "kasittele_koodisto_opetustehtavat",
  KASITTELE_KOODISTO_PO_ERITYISET_KOULUTUSTEHTAVAT = "kasittele_koodisto_poErityisetKoulutustehtavat",
  KASITTELE_KOODISTO_PO_MUUT_EHDOT = "kasittele_koodisto_poMuutEhdot",
  KASITTELE_KOODISTO_TOISSIJAISET_OPETUSKIELET_OPH = "kasittele_toissijaiset_opetuskielet_oph",
  KASITTELE_KOULUTUSALAT = "kasittele_koulutusalat",
  KASITTELE_KOULUTUSTYYPIT = "kasittele_koulutustyypit",
  KASITTELE_KOODISTO_KUJALISAMAAREET = "kasittele_kujaLisamaareet",
  KASITTELE_KULJETTAJAKOULUTUKSET = "kasittele_kuljettajakoulutukset",
  KASITTELE_KUNNAT = "kasittele_kunnat",
  KASITTELE_LISATIEDOT = "kasittele_lisatiedot",
  KASITTELE_LUKIO_ERITYINEN_KOULUTUSTEHTAVA_UUSI = "kasittele_lukioErityinenKoulutustehtavaUusi",
  KASITTELE_LUKIO_MUUT_EHDOT = "kasittele_lukioMuutEhdot",
  KASITTELE_LUVAN_TAITEENALAT = "kasittele_luvanTaiteenalat",
  KASITTELE_LUVAN_TAITEENALAT_KOODISTO = "kasittele_luvanTaiteenalatKoodisto",
  KASITTELE_MAAKUNNAT = "kasittele_maakunnat",
  KASITTELE_MAAKUNTAKUNNAT = "kasittele_maakuntakunnat",
  KASITTELE_MUUT = "kasittele_muut",
  KASITTELE_MUUT_EHDOT_TPO = "kasittele_muutEhdotTPO",
  KASITTELE_OIKEUS_SISAOPPILAITOSMUOTOISEEN_KOULUTUKSEEN = "kasittele_oikeusSisaoppilaitosmuotoiseenKoulutukseen",
  KASITTELE_OIVA_TYOVOIMAKOULUTUKSET = "kasittele_oivaTyovoimakoulutukset",
  KASITTELE_OIVAPERUSTELUT = "kasittele_oivaPerustelut",
  KASITTELE_KOODISTO_OPETUKSEN_JARJESTAMISMUODOT = "kasittele_opetuksenJarjestamismuodot",
  KASITTELE_OPETUSKIELET = "kasittele_opetuskielet",
  KASITTELE_OPETUSTEHTAVAT = "kasittele_opetustehtavat",
  KASITTELE_OPPILAITOKSEN_VAIKUTUSALUE = "kasittele_oppilaitoksenVaikutusalue",
  KASITTELE_OPPILAITOKSET_BY_OID = "kasittele_oppilaitoksetByOid",
  KASITTELE_POIKKEUS_999901 = "kasittele_poikkeus999901",
  KASITTELE_POIKKEUS_999903 = "kasittele_poikkeus999903",
  KASITTELE_TUTKINNOT = "kasittele_tutkinnot",
  KASITTELE_VIIMEISIN_LUPA = "kasittele_viimeisinLupa",
  KASITTELE_VST_ERITYISET_KOULUTUSTEHTAVAT = "kasittele_vstErityisetKoulutustehtavat",
  KASITTELE_VST_MUUT = "kasittele_vst_muut",
  KASITTELE_VST_TYYPIT = "kasittele_vstTyypit",

  KUMOA_MUUTOKSET = "action_kumoaMuutokset",

  MUODOSTA_ENSISIJAISET_OPETUSKIELET_OPH = "muodosta_ensisijaisetOpetuskieletOPH",
  MUODOSTA_OPETUSKIELET_OPH = "muodosta_opetuskieletOPH",
  MUODOSTA_TOISSIJAISET_OPETUSKIELET_OPH = "muodosta_toissijaisetOpetuskieletOPH",

  SET_PAALUPAILMOITUS_AS_HIDDEN = "paalupailmoituksenPoistaminen",
  SET_AVOIN_ASIAILMOITUS_AS_HIDDEN = "avoinAsiailmoituksenPoistaminen",

  SHOW_AVOIN_ASIAILMOITUS = "ilmoitusAvoimenAsianOlemassaolosta",
  SHOW_PAALUPAILMOITUS = "ilmoitusPaaluvanPuuttumisesta",

  // Tallentaminen paikalliseen tietovarastoon
  TALLENNA_AJALLA_KOODISTO_PAIKALLISEEN_TIETOVARASTOON = "tallenna_ajallaPaikalliseenTietovarastoon",
  TALLENNA_ENSISIJAISET_OPETUSKIELET_OPH_PAIKALLISEEN_TIETOVARASTOON = "tallenna_ensisijaisetOpetuskieletPaikalliseenTietovarastoon",
  TALLENNA_JOISTALISAKSI_KOODISTO_PAIKALLISEEN_TIETOVARASTOON = "tallenna_joistaLisaksiPaikalliseenTietovarastoon",
  TALLENNA_KIELET_OPH_PAIKALLISEEN_TIETOVARASTOON = "tallenna_kieletOPHPaikalliseenTietovarastoon",
  TALLENNA_KIELET_PAIKALLISEEN_TIETOVARASTOON = "tallenna_kieletPaikalliseenTietovarastoon",
  TALLENNA_KOHTEET_PAIKALLISEEN_TIETOVARASTOON = "tallenna_kohteetPaikalliseenTietovarastoon",
  TALLENNA_KOULUTUSALAT_PAIKALLISEEN_TIETOVARASTOON = "tallenna_koulutusalatPaikalliseenTietovarastoon",
  TALLENNA_KOULUTUSTYYPIT_PAIKALLISEEN_TIETOVARASTOON = "tallenna_koulutustyypitPaikalliseenTietovarastoon",
  TALLENNA_KUJALISAMAAREET_KOODISTO_PAIKALLISEEN_TIETOVARASTOON = "tallenna_kujaLisamaareetPaikalliseenTietovarastoon",
  TALLENNA_KUNNAT_PAIKALLISEEN_TIETOVARASTOON = "tallenna_kunnatPaikalliseenTietovarastoon",
  TALLENNA_LISATIEDOT_PAIKALLISEEN_TIETOVARASTOON = "tallenna_lisatiedotPaikalliseenTietovarastoon",
  TALLENNA_LISATIETOJA_KOODISTO_PAIKALLISEEN_TIETOVARASTOON = "tallenna_LisatietojaKoodistoPaikalliseenTietovarastoon",
  TALLENNA_LUKIO_ERITYINEN_KOULUTUSTEHTAVA_UUSI_PAIKALLISEEN_TIETOVARASTOON = "tallenna_lukioErityinenKoulutustehtavaUusiPaikalliseenTietovarastoon",
  TALLENNA_LUKIO_MUUT_EHDOT_PAIKALLISEEN_TIETOVARASTOON = "tallenna_lukioMuutEhdotPaikalliseenTietovarastoon",
  TALLENNA_LUVAN_TAITEENALAT_PAIKALLISEEN_TIETOVARASTOON = "tallenna_luvanTaiteenalatPaikalliseenTietovarastoon",
  TALLENNA_LUVAN_TAITEENALAT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON = "tallenna_luvanTaiteenalatKoodistoPaikalliseenTietovarastoon",
  TALLENNA_MAAKUNNAT_PAIKALLISEEN_TIETOVARASTOON = "tallenna_maakunnatPaikalliseenTietovarastoon",
  TALLENNA_MAAKUNTAKUNNAT_PAIKALLISEEN_TIETOVARASTOON = "tallenna_maakuntakunnatPaikalliseenTietovarastoon",
  TALLENNA_MAARAYKSET_PAIKALLISEEN_TIETOVARASTOON = "tallenna_maarayksetPaikalliseenTietovarastoon",
  TALLENNA_MAARAYSTYYPIT_PAIKALLISEEN_TIETOVARASTOON = "tallenna_maaraystyypitPaikalliseenTietovarastoon",
  TALLENNA_MUUT_PAIKALLISEEN_TIETOVARASTOON = "tallenna_muutPaikalliseenTietovarastoon",
  TALLENNA_MUUT_EHDOT_TPO_PAIKALLISEEN_TIETOVARASTOON = "tallenna_muutEhdotTPOPaikalliseenTietovarastoon",
  TALLENNA_OIKEUS_SISAOPPILAITOSMUOTOISEEN_KOULUTUKSEEN_PAIKALLISEEN_TIETOVARASTOON = "tallenna_oikeusSisaoppilaitosmuotoiseenKoulutukseenPaikalliseenTietovarastoon",
  TALLENNA_OIVAPERUSTELUT_PAIKALLISEEN_TIETOVARASTOON = "tallenna_oivaPerustelutPaikalliseenTietovarastoon",
  TALLENNA_OPETUKSEN_JARJESTAMISMUODOT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON = "tallenna_opetuksenJarjestamismuodotPaikalliseenTietovarastoon",
  TALLENNA_OPETUSKIELET_PAIKALLISEEN_TIETOVARASTOON = "tallenna_opetuskieletPaikalliseenTietovarastoon",
  TALLENNA_OPETUSTEHTAVAT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON = "tallenna_opetustehtavatKoodistoPaikalliseenTietovarastoon",
  TALLENNA_OPETUSTEHTAVAT_PAIKALLISEEN_TIETOVARASTOON = "tallenna_opetustehtavatPaikalliseenTietovarastoon",
  TALLENNA_OPPILAITOKSEN_VAIKUTUSALUE_PAIKALLISEEN_TIETOVARASTOON = "tallenna_oppilaitoksenVaikutusaluePaikalliseenTietovarastoon",
  TALLENNA_OPPILAITOKSET_BY_OID_PAIKALLISEEN_TIETOVARASTOON = "tallenna_oppilaitoksetByOidPaikalliseenTietovarastoon",
  TALLENNA_OPPILAITOS_PAIKALLISEEN_TIETOVARASTOON = "tallenna_oppilaitosPaikalliseenTietovarastoon",
  TALLENNA_ORGANISAATIO_PAIKALLISEEN_TIETOVARASTOON = "tallenna_organisaatioPaikalliseenTietovarastoon",
  TALLENNA_ORGANISAATIOT_PAIKALLISEEN_TIETOVARASTOON = "tallenna_organisaatiotPaikalliseenTietovarastoon",
  TALLENNA_PO_ERITYISET_KOULUTUSTEHTAVAT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON = "tallenna_poErityisetKoulutustehtavatKoodistoPaikalliseenTietovarastoon",
  TALLENNA_PO_MUUT_EHDOT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON = "tallenna_poMuutEhdotKoodistoPaikalliseenTietovarastoon",
  TALLENNA_TOISSIJAISET_OPETUSKIELET_OPH_PAIKALLISEEN_TIETOVARASTOON = "tallenna_toissijaisetOpetuskieletPaikalliseenTietovarastoon",
  TALLENNA_TUTKINNOT_PAIKALLISEEN_TIETOVARASTOON = "tallenna_tutkinnotPaikalliseenTietovarastoon",
  TALLENNA_VST_ERITYISET_KOULUTUSTEHTAVAT_PAIKALLISEEN_TIETOVARASTOON = "tallenna_vstErityisetKoulutustehtavatPaikalliseenTietovarastoon",
  TALLENNA_VST_MUUT_PAIKALLISEEN_TIETOVARASTOON = "tallenna_vstMuutPaikalliseenTietovarastoon",

  UDPATE_AUTOCOMPLETE_1 = "update_autocomplete_1",
  UDPATE_AUTOCOMPLETE_2 = "update_autocomplete_2",
  UPDATE_EI_MAARITELTY_KUVAUS = "update_eiMaariteltyKuvaus",
  UPDATE_LISAA_PAINIKE = "update_lisaaPainike",
  UPDATE_LISATIEDOT = "update_lisatiedot",
  UPDATE_LOKALISAATIOVALIKKO = "lokalisaatiovalikonTilanpaivitys",
  UPDATE_MAIN_PROCESS = "update_mainProcess",
  UPDATE_MAIN_PROCESS_ON_DELETE = "update_mainProcessOnDelete",
  UPDATE_MULTI_TEXTBOX = "update_multiTextBox",
  UPDATE_MULTI_TEXTBOX_VISIBILITY = "update_multiTextBoxVisibility",
  UPDATE_ORGANISAATIOVALIKKO = "organisaatiovalikonTilanpaivitys",
  UPDATE_RADIO_BUTTONS = "update_radioButtonCollection",
  UPDATE_SEURAAVA_PAINIKE = "seuraavaPainikkeenTilanpaivitys",
  UPDATE_SUOMI_CHECKBOX = "update_suomiCheckbox",
  UPDATE_TALLENNUSPAINIKE = "udpate_tallennuspainike",
  UPDATE_ULKOMAAT_CHECKBOX = "update_ulkomaatCheckbox"
}
