import { Fragment, useEffect, useState } from "react";
import Select, { components } from "react-select";
import PropTypes from "prop-types";
import chroma from "chroma-js";
import {
  heights,
  autocompleteShortStyles,
  autocompleteWidthStyles
} from "../../../css/autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import InputLabel from "@mui/material/InputLabel";
import { map } from "ramda";
import { useIntl } from "react-intl";
import commonMessages from "i18n/definitions/common";

/**
 * Autocomplete wraps a Select
 * Sends value to callback.
 * Two versions:
 *  [isSearch = false] autocomplete with arrow (list can be opened anytime)
 *  [isSearch = true] autocomplete with search icon (list opens when typed character >= minChars)
 * @param props
 * @returns {*}
 * @constructor
 */

const Autocomplete = props => {
  const [options, setOptions] = useState([]);
  const [, setMinCharacters] = useState(3);
  const [isOptionsShown, setIsOptionsShown] = useState(false);
  const intl = useIntl();

  const orderOptions = values => {
    return values.filter(v => v.isFixed).concat(values.filter(v => !v.isFixed));
  };

  const optionStyles = Object.assign(
    {},
    {
      ...(props.height === heights.SHORT ? autocompleteShortStyles : null),

      control:
        props.height === heights.SHORT
          ? autocompleteShortStyles.control
          : styles => ({
              ...styles,
              backgroundColor: "white",
              border: "1px solid #008A07"
            }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        let backgroundColor = "#ffffff";
        let color = "#000000";

        if (isFocused) {
          backgroundColor = "rgba(0,138,7,0.14)";
        } else if (isSelected) {
          backgroundColor = "rgba(0,138,7,0.07)";
        }

        if (isDisabled) {
          color = "#cccccc";
        } else if (isSelected) {
          if (chroma.contrast(color, "white") > 2) {
            color = "#000000";
          }
        }

        return {
          ...styles,
          backgroundColor,
          color,
          cursor: isDisabled ? "not-allowed" : "default",
          ":active": {
            ...styles[":active"],
            backgroundColor:
              !isDisabled && (isSelected ? data.color : "#f4f4f4")
          }
        };
      },
      indicatorSeparator: () => ({ display: "none" }),
      menu: styles => ({ ...styles, zIndex: 999 }),
      multiValue: styles => {
        const color = chroma("white");
        return {
          ...styles,
          backgroundColor: "#ffffff",
          border: "1px solid #C4C4C4",
          borderRadius: "1rem",
          color,
          padding: "0.1rem"
        };
      },
      multiValueLabel: styles => ({
        ...styles,
        backgroundColor: "#ffffff",
        borderRadius: "1rem",
        lineHeight: "1.2",
        padding: "3px",
        paddingRight: "0.5rem",
        paddingTop: "4px",
        whiteSpace: "normal",
        color: "#333333"
      }),
      multiValueRemove: styles => ({
        ...styles,
        backgroundColor: "#666666",
        borderRadius: "100%",
        color: "#ffffff",
        height: "22px",
        margin: "0.1rem",
        marginRight: "0.5rem",
        width: "22px",
        ":hover": {
          backgroundColor: "rgba(102,102,102,0.7)",
          color: "#fcfcfc"
        }
      })
    },
    props.short ? autocompleteWidthStyles : {}
  );

  const handleSelectChange = (value, { action, removedValue }) => {
    switch (action) {
      case "remove-value":
      case "pop-value":
        if (removedValue && removedValue.isFixed) {
          return;
        }
        break;
      case "clear":
        value = options.filter(v => v.isFixed);
        break;
      default:
        break;
    }
    props.callback(
      {
        forChangeObject: props.forChangeObject,
        fullAnchor: props.fullAnchor
      },
      {
        value: Array.isArray(value) ? orderOptions(value) : value || []
      }
    );
  };

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  useEffect(() => {
    setMinCharacters(3);
    props.minChars <= 0
      ? setMinCharacters(props.minChars)
      : props.isSearch && setMinCharacters(3);
  }, [props.isSearch, props.minChars]);

  const searchFilter = (option, searchText) => {
    return option.data.label.toLowerCase().includes(searchText.toLowerCase());
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <SearchIcon />
      </components.DropdownIndicator>
    );
  };

  const onInputChange = value => {
    if (value.length >= props.minChars) {
      setIsOptionsShown(true);
    } else {
      setIsOptionsShown(false);
    }
    if (props.isDynamic) {
      props.callback(
        {
          forChangeObject: props.forChangeObject,
          fullAnchor: props.fullAnchor
        },
        {
          searchStr: value,
          value: Array.isArray(props.value)
            ? orderOptions(props.value)
            : props.value || []
        }
      );
    }
  };

  return props.isVisible ? (
    <Fragment>
      {props.isPreviewModeOn || props.isReadOnly ? (
        <ul className="ml-8 list-disc mb-4">
          {map(
            value => (value ? <li key={value.value}>{value.label}</li> : null),
            props.value
          ).filter(Boolean)}
        </ul>
      ) : (
        <Fragment>
          {props.title && (
            <InputLabel
              required={props.isRequired}
              style={{ marginBottom: "0.2em" }}>
              {props.title}
            </InputLabel>
          )}
          {props.isSearch ? (
            <Select
              autosize={props.autosize}
              closeMenuOnSelect={props.closeMenuOnSelect || !props.isMulti}
              components={props.isSearch && { DropdownIndicator }}
              getOptionLabel={option => `${option.label}`}
              getOptionValue={option => `${option.value}`}
              name={props.name}
              hideSelectedOptions={false} // props.isSearch
              inputId={props.inputId}
              isDisabled={props.isDisabled}
              isMulti={props.isMulti}
              isSearchable={true}
              menuIsOpen={isOptionsShown}
              noOptionsMessage={() =>
                intl.formatMessage(commonMessages.eiValittaviaKohteita)
              }
              onChange={handleSelectChange}
              onInputChange={onInputChange}
              options={isOptionsShown ? options : []}
              placeholder={
                props.placeholder
                  ? props.placeholder
                  : intl.formatMessage(commonMessages.autocompleteValitse)
              }
              required={props.isRequired}
              searchFilter={searchFilter}
              styles={optionStyles}
              value={props.value}
              width={props.width}
            />
          ) : (
            <Select
              autosize={props.autosize}
              closeMenuOnSelect={props.closeMenuOnSelect || !props.isMulti}
              hideSelectedOptions={props.hideSelectedOptions}
              inputId={props.inputId}
              isDisabled={props.isDisabled}
              isMulti={props.isMulti}
              name={props.name}
              noOptionsMessage={() =>
                intl.formatMessage(commonMessages.eiValittaviaKohteita)
              }
              onChange={handleSelectChange}
              onInputChange={onInputChange}
              placeholder={
                props.placeholder
                  ? props.placeholder
                  : intl.formatMessage(commonMessages.autocompleteValitse)
              }
              options={options}
              getOptionLabel={option => `${option.label}`}
              getOptionValue={option => `${option.value}`}
              isSearchable={props.isDynamic}
              searchFilter={searchFilter}
              styles={optionStyles}
              required={props.isRequired}
              value={props.value}
              width={props.width}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  ) : null;
};

Autocomplete.defaultProps = {
  closeMenuOnSelect: false,
  isDisabled: false,
  isMulti: true,
  isReadOnly: false,
  isRequired: false,
  isValid: true,
  isVisible: true,
  placeholder: null,
  value: [],
  isSearch: false,
  minChars: 3,
  width: "100%",
  autoSize: false,
  hideSelectedOptions: true
};

Autocomplete.propTypes = {
  closeMenuOnSelect: PropTypes.bool,
  forChangeObject: PropTypes.object,
  fullAnchor: PropTypes.string,
  inputId: PropTypes.string,
  isDisabled: PropTypes.bool,
  isDynamic: PropTypes.bool,
  isMulti: PropTypes.bool,
  isPreviewModeOn: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  isValid: PropTypes.bool,
  isVisible: PropTypes.bool,
  name: PropTypes.string,
  callback: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.array,
  height: PropTypes.string,
  isSearch: PropTypes.bool,
  minChars: PropTypes.number,
  width: PropTypes.string,
  autosize: PropTypes.bool,
  title: PropTypes.string,
  short: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool
};

Autocomplete.displayName = "Autocomplete";

export default Autocomplete;
