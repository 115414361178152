import { Fragment, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "./TableRow/index";
import TableCell from "./TableCell/index";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import MaterialUITable from "@mui/material/Table";
import makeStyles from "@mui/styles/makeStyles";
import {
  addIndex,
  find,
  isNil,
  map,
  prop,
  propEq,
  reject,
  values
} from "ramda";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const defaultValues = {
  level: 0,
  sortedBy: { columnIndex: 0, order: "desc" },
  searchBy: {},
  structure: [],
  sortEnabled: true
};

/**
 *
 * @param {object} props - Properties object.
 * @param {object} props.structure - Structure of the table.
 * @param {level} props.level - Level of unnested table is 0.
 * @param {object} props.sortedBy - Default sorting configuration.
 * @param {object} props.searchBy - Searching configuration.
 * @param {function} props.setSearchBy - Default search configuration.
 */
const Table = ({
  tableHeader,
  tableBody,
  level = defaultValues.level,
  sortedBy = defaultValues.sortedBy,
  setSortedBy,
  searchBy = defaultValues.searchBy,
  setSearchBy,
  sortEnabled = defaultValues.sortEnabled
}) => {
  const classes = useStyles();

  // This is for sorting the rows.
  const orderSwap = {
    asc: "desc",
    desc: "asc"
  };

  /**
   * There are rows in the table's structure object. One of those rows are
   * passed to this function as a parameter and its callback function will
   * be called.
   * @param {string} action - Identifier for the executed action.
   * @param {object} row - Includes cells and other row related data.
   * @param {MouseEvent} event
   */
  function onRowClick(action, row, tableLevel, event) {
    if (row.onClick) {
      /**
       * User can define actions in table's structure object. Actions are
       * used later to run the correct operations for the action related row.
       * Handling actions happens outside of the Table component.
       **/
      row.onClick(row, action, event);
    }
  }

  /**
   *
   * @param {string} action - Custom text string.
   * @param {object} properties - Contains cell related properties.
   * @param {number} properties.columnIndex - Index of the clicked column.
   * @param {object} properties.row - Row that contains the clicked cell.
   */
  function onCellClick(action, { columnIndex, columnKey, row }) {
    // Sort action is handled inside the Table component.
    if (action === "sort") {
      if (!sortEnabled) {
        return;
      }
      setSortedBy(prevState => {
        let order = prop(prevState.order, orderSwap);
        if (prevState.columnIndex !== columnIndex) {
          order = "asc";
        }
        return { columnIndex, order, sortKey: columnKey };
      });
    } else {
      /**
       * Cell related actions are expanded to row level. This can change
       * later if cell click related callbacks are needed.
       **/
      onRowClick(action, row);
    }
  }

  const onSearchChange = useCallback(
    (action, { columnIndex, columnKey, searchValue }) => {
      if (action !== "search") {
        return;
      }
      searchBy[columnKey] =
        columnKey && searchValue
          ? { columnIndex, searchKey: columnKey, searchValue }
          : null;

      return setSearchBy(reject(isNil, searchBy));
    },
    [searchBy]
  );

  /**
   * Forms the table rows and nested Table components.
   * @param {object} part - Object of the structure array.
   * @param {string} part.role - E.g. thead, tbody...
   * @param {array} part.rowGroups - Array of rowgroup objects.
   * @param {array} rows - Array of row objects.
   */
  const getRowsToRender = (part, rows = []) => {
    const ParentOfRow = part.role === "thead" ? TableHead : TableBody;
    const jsx = (
      <ParentOfRow key={Math.random()}>
        {addIndex(map)((row, iii) => {
          return (
            <TableRow
              key={`row-${iii}`}
              row={row}
              onClick={onRowClick}
              tableLevel={level}>
              {addIndex(map)((cell, iiii) => {
                const searchByItem = find(
                  propEq("columnIndex", iiii),
                  values(searchBy)
                );
                return (
                  <TableCell
                    columnIndex={iiii}
                    isHeaderCell={part.role === "thead"}
                    key={`cell-${iiii}`}
                    onClick={onCellClick}
                    searchBy={searchByItem}
                    onSearchChange={onSearchChange}
                    orderOfBodyRows={sortedBy}
                    properties={cell}
                    row={row}
                  />
                );
              }, row.cells || [])}
            </TableRow>
          );
        }, rows)}
      </ParentOfRow>
    );
    return jsx;
  };

  /**
   * Starting point of table creation. Structure will be walked through and table's
   * different parts will be created.
   */
  const tableBodyToRender = addIndex(map)(
    (part, i) => {
      return part ? (
        <Fragment key={i}>
          {map(rowGroup => {
            return getRowsToRender(part, rowGroup.rows);
          }, part.rowGroups || [])}
        </Fragment>
      ) : (
        []
      );
    },
    [tableBody]
  );

  const [tableHeaderToRender, setTableHeaderToRender] = useState();

  function getTableHeader(tableHeader) {
    return addIndex(map)(
      (part, i) => {
        return part ? (
          <Fragment key={i}>
            {map(rowGroup => {
              return getRowsToRender(part, rowGroup.rows);
            }, part.rowGroups || [])}
          </Fragment>
        ) : (
          []
        );
      },
      [tableHeader]
    );
  }

  useEffect(() => {
    const reRender = find(propEq("reRender", true), values(searchBy));
    if (!tableHeaderToRender || reRender) {
      setTableHeaderToRender(getTableHeader(tableHeader));
    }
  }, [searchBy, tableHeader]);

  useEffect(() => {
    setTableHeaderToRender(getTableHeader(tableHeader));
  }, [sortedBy, tableHeader]);

  // The table will is rendered.
  return (
    <TableContainer
      component={Paper}
      variant="outlined"
      style={{ borderBottom: 0 }}>
      <MaterialUITable className={classes.table} aria-label="simple table">
        {tableHeaderToRender}
        {tableBodyToRender}
      </MaterialUITable>
    </TableContainer>
  );
};

Table.propTypes = {
  level: PropTypes.number,
  sortedBy: PropTypes.object,
  setSortedBy: PropTypes.func,
  searchBy: PropTypes.object,
  setSearchBy: PropTypes.func,
  // Defines the structure of table.
  tableHeader: PropTypes.object,
  tableBody: PropTypes.object,
  sortEnabled: PropTypes.bool
};

export default Table;
