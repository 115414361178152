import {
  find,
  isEmpty,
  isNil,
  lensPath,
  map,
  propEq,
  reject,
  sortBy,
  view
} from "ramda";
import { defineChip } from "graphHandling/components/chip/unopinionated";
import { IChipCollection, IChip } from "utils/lomakkeet";
import { ComponentType } from "graphHandling/components/componentType";
import { getLocalizedProperty } from "services/lomakkeet/utils";
import { IntlShape } from "react-intl";
import { MaakuntaRaw, Maarays } from "koodistodatanTyypit";
import { Muutos } from "types";
import { Koodisto } from "enums";

export const getKuntaChipCollection = (
  maakuntakunta: MaakuntaRaw,
  intl: IntlShape,
  isMaakuntaChecked: boolean,
  kuntamaaraykset: Array<Maarays> = [],
  backendMuutoksillaPoistetutKunnat: Array<Muutos> = [],
  backendMuutoksillaValitutKunnat: Array<Muutos> = []
): IChipCollection => {
  const chips: Array<IChip> = sortBy(
    view(lensPath(["properties", "label"])),
    reject(
      isNil,
      map(kunta => {
        const isKuntaCheckedByKuntamaarays = !!find(
          maarays =>
            maarays.koodisto === Koodisto.KUNTA &&
            maarays.koodiarvo === kunta.koodiArvo &&
            kunta.koodiArvo !== "200",
          kuntamaaraykset
        );

        const isKuntaCheckedByKuntamuutos = !!find(
          propEq("koodiarvo", kunta.koodiArvo),
          backendMuutoksillaValitutKunnat
        );

        const isKuntaUncheckedByKuntamuutos = !!find(
          propEq("koodiarvo", kunta.koodiArvo),
          backendMuutoksillaPoistetutKunnat
        );

        const defaultValues = {
          id: `${kunta.koodiArvo}-${maakuntakunta.koodiArvo}`,
          isChecked: isKuntaCheckedByKuntamaarays || isMaakuntaChecked,
          isRemovable: isMaakuntaChecked,
          isLineThrough: false,
          isLineThroughable: isKuntaCheckedByKuntamaarays,
          label: getLocalizedProperty(kunta.metadata, intl.locale, "nimi"),
          metadata: {
            maakunnanKoodiarvo: maakuntakunta.koodiArvo
          },
          value: kunta.koodiArvo
        };

        const modifications: { backend: Record<string, unknown> } = {
          backend: {}
        };

        if (isKuntaCheckedByKuntamuutos && !defaultValues.isChecked) {
          modifications.backend.isChecked = true;
          modifications.backend.isRemovable = true;
        }
        if (isKuntaUncheckedByKuntamuutos && !defaultValues.isLineThrough) {
          if (!defaultValues.isLineThroughable) {
            modifications.backend.isLineThroughable = true;
          }
          modifications.backend.isLineThrough = true;
        }

        return defineChip(
          defaultValues,
          {
            onDelete: payload => {
              return payload;
            }
          },
          isEmpty(modifications.backend) ? undefined : modifications
        );
      }, maakuntakunta.kunta)
    )
  );

  return {
    name: ComponentType.CHIP_COLLECTION,
    properties: {
      chips
    }
  };
};
