import { Lupa } from "Lupa";
import { path } from "ramda";
import { ReactElement } from "react";

type Props = {
  lupa: Lupa;
};

const Lisatiedot = ({ lupa }: Props): ReactElement => {
  const lisatiedot = path(["meta", "lisatiedot", "arvo"], lupa) as string;
  return <div className="mt-8">{lisatiedot ? lisatiedot : ""}</div>;
};

export default Lisatiedot;
