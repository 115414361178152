import { Fragment, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { AppRoute } from "routes/index";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { localizeRouteKey } from "utils/common";
import { ToastContainer } from "react-toastify";
import { useGlobalSettings } from "stores/appStore";
import SessionDialog from "scenes/SessionDialog";
import { useHistory } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { sessionTimeoutInMinutes } from "modules/constants";
import { App } from "App.tsx";

import "react-toastify/dist/ReactToastify.css";
import { PropTypes } from "prop-types";
import { getRaw } from "basedata";
import { backendRoutes } from "stores/utils/backendRoutes";
import { localizeRoutePath } from "modules/i18n/components/LocalizedSwitchUtils";

export const AppLayout = ({ localesByLang, children, organisation, user }) => {
  const history = useHistory();
  const intl = useIntl();
  const { locale } = intl;

  const [{ isDebugModeOn }] = useGlobalSettings();
  const [isSessionDialogVisible, setSessionDialogVisible] = useState(false);

  const onSessionDialogLogout = useCallback(() => {
    history.push(
      `${localizeRouteKey(
        locale,
        AppRoute.CasLogOut,
        intl
      )}?sessionTimeout=true`
    );
  }, [intl, history, locale]);

  const onSessionDialogOK = useCallback(() => {
    setSessionDialogVisible(false);
    getRaw(
      "kayttaja",
      backendRoutes.kayttaja.path,
      [],
      backendRoutes.kayttaja.minimumTimeBetweenFetchingInMinutes
    ).then(kayttaja => {
      if (!kayttaja) {
        // Sessio on vanhentunut backendissa, joten ohjataan käyttäjä uudelleen kirjautumaan.
        history.push(localizeRoutePath(AppRoute.CasAuth, locale, intl));
      }
    });
  }, [history, intl, locale]);

  const handleOnIdle = () => {
    setSessionDialogVisible(true);
  };

  useIdleTimer({
    timeout: (sessionTimeoutInMinutes / 2) * 60 * 1000, // unit: ms
    onIdle: handleOnIdle,
    debounce: 500
  });

  return (
    <Fragment>
      <BreadcrumbsItem to={localizeRouteKey(locale, AppRoute.Home, intl)}>
        Oiva
      </BreadcrumbsItem>

      <ToastContainer />

      {isSessionDialogVisible && !!user ? (
        <SessionDialog
          isVisible={isSessionDialogVisible}
          onLogout={onSessionDialogLogout}
          onOK={onSessionDialogOK}
        />
      ) : null}

      {isDebugModeOn ? (
        <div className="flex">
          {/* Cytoscape canvas */}
          <div
            id="cy"
            className="z-50 r-0 t-0 bg-gray-100 w-1/3 h-auto border border-black"
            style={{ zIndex: 9000 }}></div>
          <div className="w-2/3 relative">
            {
              <App
                isSessionDialogVisible={isSessionDialogVisible}
                localesByLang={localesByLang}
                onLogout={onSessionDialogOK}
                onSessionDialogOK={onSessionDialogOK}
                organisation={organisation}
                user={user}>
                {children}
              </App>
            }
          </div>
        </div>
      ) : (
        <App
          isSessionDialogVisible={isSessionDialogVisible}
          localesByLang={localesByLang}
          onLogout={onSessionDialogOK}
          onSessionDialogOK={onSessionDialogOK}
          organisation={organisation}
          user={user}>
          {children}
        </App>
      )}
    </Fragment>
  );
};

AppLayout.propTypes = {
  localesByLang: PropTypes.object,
  children: PropTypes.object,
  organisation: PropTypes.object,
  user: PropTypes.object
};
