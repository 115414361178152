import {
  Action,
  ContainerComponent,
  createContainer,
  createHook,
  createStore
} from "react-sweet-state";
import { GraphFlow } from "graphHandling/graphTypes";
import {
  append,
  assoc,
  assocPath,
  concat,
  equals,
  isEmpty,
  isNil,
  last,
  length,
  lensIndex,
  mapObjIndexed,
  mergeAll,
  mergeDeepRight,
  nth,
  omit,
  path,
  prepend,
  reject,
  set,
  split,
  uniq,
  without
} from "ramda";
import { Properties } from "utils/lomakkeet";
import { GraphComponent } from "utils/lomakkeet";
import {
  getCurrentProps,
  getCurrentValue,
  recursiveTreeShake
} from "graphHandling/graphUtils";

export const notInitialPath = ["custom", "notInitial"];
export const unsavedPath = ["custom", "unsaved"];
export const invalidCellIdsPath = ["custom", "invalidCellIds"];

export type GraphState = {
  flow: GraphFlow;
  components: Record<string, any>;
  custom: {
    isEditModeOn?: boolean;
    notInitial: Array<string>;
    goToRoute?: {
      value: string;
    };
    invalidCellIds: Array<string>;
  };
};

export const initialState: GraphState = {
  flow: [],
  components: {},
  custom: {
    notInitial: [],
    invalidCellIds: []
  }
};

export const isEquivalent = (obj1: Properties, obj2: Properties): boolean => {
  if (!obj1) {
    return false;
  }
  const aProps = Object.getOwnPropertyNames(obj1);
  const bProps = Object.getOwnPropertyNames(obj2);

  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i += 1) {
    const propName = aProps[i];

    if (!equals(obj1[propName], obj2[propName])) {
      return false;
    }
  }

  return true;
};

const updateComponent =
  (cellId: string, properties: Properties): Action<GraphState> =>
  ({ getState, setState }) => {
    const state = getState();
    const cellIdParts = split("_", cellId);
    const componentDefinition = path(
      cellIdParts,
      state.components
    ) as GraphComponent;

    const nextComponentDefinition = reject(
      isNil,
      recursiveTreeShake(
        ["modifications", "frontend"],
        mergeDeepRight(componentDefinition, properties)
      ) as unknown as GraphComponent
    ) as GraphComponent;

    const isNotInitial =
      nextComponentDefinition.modifications &&
      !isEmpty(nextComponentDefinition.modifications);

    const isUnsaved = !equals(
      getCurrentProps(componentDefinition),
      getCurrentProps(nextComponentDefinition)
    );

    let nextState = assocPath(
      prepend("components", cellIdParts),
      nextComponentDefinition,
      state
    );

    let nextNotInitial =
      (path(notInitialPath, nextState) as Array<string>) || [];

    if (isNotInitial) {
      nextNotInitial = uniq(append(cellId, nextNotInitial));
    } else {
      nextNotInitial = without([cellId], nextNotInitial);
    }

    nextComponentDefinition.isNotInitial = isNotInitial;

    let nextUnsaved = (path(unsavedPath, nextState) as Array<string>) || [];

    if (isUnsaved) {
      nextUnsaved = uniq(append(cellId, nextUnsaved));
    } else {
      nextUnsaved = without([cellId], nextUnsaved);
    }

    nextComponentDefinition.isUnsaved = isUnsaved;

    nextState = assocPath(notInitialPath, nextNotInitial, nextState);
    nextState = assocPath(unsavedPath, nextUnsaved, nextState);

    let nextInvalidCellIds = path(
      invalidCellIdsPath,
      nextState
    ) as Array<string>;

    if (properties.isValid === false) {
      nextInvalidCellIds = uniq(append(cellId, nextInvalidCellIds));
    } else {
      nextInvalidCellIds = without([cellId], nextInvalidCellIds);
    }

    nextState = assocPath(invalidCellIdsPath, nextInvalidCellIds, nextState);

    setState(nextState);
  };

export const graphStoreActions = {
  addModification:
    (
      cellId: string,
      properties: Properties,
      modIndex = 0
    ): Action<GraphState> =>
    ({ getState, dispatch }) => {
      const state = getState();
      const cellIdPath = split("_", cellId);
      const componentDefinition = path(
        cellIdPath,
        state.components
      ) as GraphComponent;

      const propKeysToBeRemoved: Array<string> = [];

      // Ei turhaan lisätä muutoksiin sellaisia määreitä, joiden
      // arvo on jo ennestään sama, kuin muutoksen sisältämä arvo.
      const newProperties = reject(
        isNil,
        mapObjIndexed((value, key) => {
          const currentValue = getCurrentValue(key, componentDefinition, {
            bMods: true,
            fMods: false
          });
          if (currentValue === value) {
            propKeysToBeRemoved.push(key);
          }
          return currentValue === value ? null : value;
        }, properties)
      );

      const propsInIndex = nth(
        modIndex,
        componentDefinition?.modifications?.frontend || []
      );

      const nextPropsInIndex = omit(
        propKeysToBeRemoved,
        Object.assign({}, propsInIndex, newProperties)
      );

      const mods = componentDefinition.modifications?.frontend || [];
      const modsAmount = length(mods);
      const sourceArr =
        modsAmount <= modIndex
          ? concat(mods, new Array(modIndex + 1 - modsAmount))
          : mods;

      const frontendModifications = set(
        lensIndex(modIndex),
        nextPropsInIndex,
        sourceArr
      );

      const shouldModificationsBeRemoved = equals(
        mergeAll([
          componentDefinition.properties,
          componentDefinition.modifications?.backend,
          omit(propKeysToBeRemoved, mergeAll(frontendModifications))
        ]),
        mergeAll([
          componentDefinition.properties,
          componentDefinition.modifications?.backend
        ])
      );

      const nextFrontendModifications = shouldModificationsBeRemoved
        ? undefined
        : frontendModifications;

      dispatch(
        updateComponent(cellId, {
          modifications: {
            frontend: nextFrontendModifications
          }
        })
      );
    },
  initialize:
    (): Action<GraphState> =>
    ({ setState }) => {
      setState(initialState);
    },
  onInit:
    (): Action<GraphState, { graph: GraphState }> =>
    ({ setState }, { graph }) => {
      setState(graph);
    },
  readPath:
    (pathToRead?: Array<number | string>): Action<GraphState> =>
    ({ getState }): any => {
      return pathToRead ? path(pathToRead, getState()) : getState();
    },
  updateComponent:
    (cellId: string, properties: Properties): Action<GraphState> =>
    ({ dispatch }) => {
      dispatch(updateComponent(cellId, properties));
    },
  updateCurrentProperties:
    (cellId: string, properties: Properties): Action<GraphState> =>
    ({ getState, dispatch }) => {
      const state = getState();
      const componentDefinition = state.components[cellId];
      const nextProperties = assoc(
        "current",
        Object.assign({}, componentDefinition.properties.current, properties),
        componentDefinition.properties
      );
      dispatch(updateComponent(cellId, nextProperties));
    },
  updateFlow:
    (cellId: string): Action<GraphState> =>
    ({ getState, setState }) => {
      const state = getState();
      const previousFlowItem = last(state.flow);
      const newFlowItem = {
        cellId,
        time: new Date().getTime()
      };

      let updatedFlow;

      if (
        previousFlowItem &&
        new Date().getTime() - previousFlowItem.time > 2000
      ) {
        updatedFlow = [newFlowItem];
      } else {
        updatedFlow = append(newFlowItem, state.flow);
      }

      const nextState = assoc("flow", updatedFlow, state);

      setState(nextState);
    },
  updateGraph:
    (
      pathToWrite: Array<string> | string,
      properties: any
    ): Action<GraphState> =>
    ({ getState, setState }) => {
      const state = getState();
      const _path = Array.isArray(pathToWrite)
        ? pathToWrite
        : split(".", pathToWrite);

      const nextState = assocPath(
        _path,
        properties
          ? Object.assign({}, path(_path, state), properties)
          : properties,
        state
      );

      setState(nextState);
    }
};

export type Actions = typeof graphStoreActions;

const ForType = createStore<GraphState, Actions>({
  initialState,
  actions: graphStoreActions
});

export const useGraphDataStore = createHook(ForType);

export const GraphDataStoreContainer = createContainer(ForType, {
  onInit:
    () =>
    ({ setState }, initialState: Partial<GraphState>) => {
      setState(initialState);
    },
  onCleanup:
    () =>
    ({ setState }, initialState) => {
      setState(initialState);
    }
});

export type StoreType = typeof ForType;

export const createGraphStoreContainer = (
  store: StoreType
): ContainerComponent<any> => {
  return createContainer(store, {
    onInit:
      () =>
      ({ setState }, initialState) => {
        setState(initialState);
      },
    onCleanup:
      () =>
      ({ setState }, initialState) => {
        setState(initialState);
      }
  });
};
