/* eslint-disable @typescript-eslint/ban-types */
import { useCallback } from "react";
import Header from "components/02-organisms/Header/index";
import { useIntl } from "react-intl";
import authMessages from "i18n/definitions/auth";
import langMessages from "i18n/definitions/languages";
import { assoc, head, includes, or, prop, tail, toPairs } from "ramda";
import common from "i18n/definitions/common";
import { AppRoute } from "routes/index";
import { localizeRoutePath } from "modules/i18n/components/LocalizedSwitchUtils";
import { localizeRouteKey } from "utils/common";
import ammatillinenKoulutus from "i18n/definitions/ammatillinenKoulutus";
import Footer from "components/03-templates/Footer";
import { LocalesByLang, Organisation, OrganisationName, User } from "types";
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav";
import AlertMessage from "./components/00-atoms/Alert";

export const App = ({
  localesByLang,
  children,
  organisation,
  user
}: {
  children: Object;
  localesByLang: LocalesByLang;
  organisation: Organisation;
  user: User;
}): Object => {
  const intl = useIntl();
  const { formatMessage, locale } = intl;

  const authenticationLink = {
    text: !user
      ? [formatMessage(authMessages.logIn)]
      : [formatMessage(authMessages.logOut), user.username],
    path: !user
      ? localizeRoutePath(AppRoute.CasAuth, locale, intl)
      : localizeRoutePath(AppRoute.CasLogOut, locale, intl)
  };

  const getOrganisationLink = useCallback(() => {
    let result = {};

    if (user && user.oid && organisation) {
      const orgNimi: OrganisationName =
        user && organisation ? prop("nimi", organisation) : {};
      const isEsittelija = user
        ? includes("OIVA_APP_ESITTELIJA", user.roles)
        : false;
      result = assoc(
        "text",
        // Select name by locale or first in nimi object
        or(prop(locale, orgNimi), tail(head(toPairs(orgNimi)) || [])),
        result
      );

      if (!isEsittelija) {
        result = assoc(
          "path",
          localizeRouteKey(locale, AppRoute.Jarjestamislupa, intl, {
            id: organisation.oid,
            koulutusmuoto: formatMessage(ammatillinenKoulutus.kebabCase)
          }),
          result
        );
      }
    }
    return result;
  }, [formatMessage, locale, organisation, user]);

  const shortDescription = {
    text: formatMessage(common.siteShortDescription),
    path: "/"
  };

  const getHeader = useCallback(
    (template?) => {
      const organisationLink = getOrganisationLink();
      return (
        <Header
          inFinnish={"FI"}
          inSwedish={"SV"}
          isAuthenticated={!!user}
          locale={locale}
          localesByLang={localesByLang}
          logIn={formatMessage(authMessages.logIn)}
          authenticationLink={authenticationLink}
          organisationLink={organisationLink}
          shortDescription={shortDescription}
          template={template}
          languageSelectionAriaLabel={formatMessage(
            langMessages.selection
          )}></Header>
      );
    },
    [
      authenticationLink,
      locale,
      localesByLang,
      formatMessage,
      getOrganisationLink,
      shortDescription,
      user
    ]
  );

  const siteInfoBannerMessage = intl.formatMessage(
    common.siteInfoBannerMessage
  );

  return (
    <div className="flex flex-col min-h-screen">
      <SkipNavLink>{formatMessage(common.jumpToContent)}</SkipNavLink>
      {getHeader()}
      {siteInfoBannerMessage !== "common.siteInfoBannerMessage" && (
        <AlertMessage
          id="siteInfoBanner"
          isClosable={false}
          ariaLabel="Site info banner"
          message={siteInfoBannerMessage}
          variant={"filled"}
        />
      )}
      <main className="flex-1 flex flex-col">
        <SkipNavContent />
        {children}
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};
