import moment from "moment";
import {
  assocPath,
  compose,
  dissoc,
  filter,
  find,
  flatten,
  includes,
  map,
  path,
  prop,
  propEq
} from "ramda";
import { koulutustyypitMap } from "../../../utils/constants";
import * as opetuskieletHelper from "helpers/opetuskielet";
import * as muutEhdotHelper from "helpers/muutEhdotVST";
import * as vstErityisetKoulutustehtavatHelper from "helpers/vstErityinenKoulutustehtava";
import * as vstToimintaalueHelper from "helpers/vstOppilaitoksenAlueellisuusJaValtakunnallisuus";
import * as opetustaAntavatKunnatHelper from "helpers/opetustaAntavatKunnat";
import { createOppilaitoksenKuntaMuutokset } from "helpers/vstKunnat";
import { createOppilaitosmuutokset } from "helpers/vstOppilaitos";

export async function createObjectToSave(
  locale,
  organisation,
  lupa,
  changeObjects,
  uuid,
  kohteet,
  maaraystyypit,
  alkupera = "KJ",
  muutospyynnonTila,
  lisaksi,
  oppilaitostyyppi
) {
  const allAttachmentsRaw = [];
  const koulutustyyppi = koulutustyypitMap.VAPAASIVISTYSTYO;
  const lupaMaaraykset = prop("maaraykset", lupa) || [];

  // OPPILAITOKSEN TALLENTAMINEN OMANA MUUTOKSENAAN
  const oppilaitosMaarays = find(
    propEq("koodisto", "oppilaitos"),
    lupaMaaraykset
  );
  const oppilaitosMuutokset = createOppilaitosmuutokset(
    prop("oppilaitos", lisaksi),
    find(propEq("tunniste", "oppilaitos"), kohteet),
    maaraystyypit,
    oppilaitosMaarays
  );

  // OPPILAITOKSEN KUNNAT OMANA MUUTOKSENAAN
  const kunnatKohde = find(propEq("tunniste", "kunnat"), kohteet);
  const oppilaitoksenKuntaMuutokset = createOppilaitoksenKuntaMuutokset(
    kunnatKohde,
    maaraystyypit,
    prop("oppilaitos", lisaksi),
    lupaMaaraykset
  );

  // ... without tiedosto-property
  const allAttachments = map(attachment => {
    return dissoc("tiedosto", attachment);
  }, allAttachmentsRaw);

  // 1. OPETUSKIELET
  const opetuskielet = await opetuskieletHelper.defineBackendChangeObjects(
    {
      opetuskielet: changeObjects.opetuskielet
    },
    find(propEq("tunniste", "kielet"), kohteet),
    maaraystyypit,
    lupaMaaraykset,
    kohteet
  );

  const toimintaalue = await vstToimintaalueHelper.defineBackendChangeObjects(
    changeObjects.oppilaitoksenAlueellisuusJaValtakunnallisuus,
    maaraystyypit,
    kohteet,
    lupaMaaraykset
  );

  const erityisetKoulutustehtavat =
    await vstErityisetKoulutustehtavatHelper.defineBackendChangeObjects(
      changeObjects.erityinenKoulutustehtava,
      find(propEq("tunniste", "erityinenkoulutustehtava"), kohteet),
      maaraystyypit,
      lupaMaaraykset,
      locale,
      kohteet
    );

  // Luodaan backend-muotoinen muutosobjekti vain, jos frontend-muotoinen
  // muutosobjekti on olemassa.
  const oppilaitoksenTarkoitusBackendChangeObject = changeObjects
    .oppilaitoksenTarkoitus.length
    ? {
        generatedId: `oppilaitoksenTarkoitus-${Math.random()}`,
        kohde: find(propEq("tunniste", "tarkoitus"), kohteet),
        koodiarvo: "1",
        koodisto: "vsttarkoitus",
        kuvaus: "",
        maaraystyyppi: find(propEq("tunniste", "OIKEUS"), maaraystyypit),
        meta: {
          changeObjects: changeObjects.oppilaitoksenTarkoitus,
          // Koska osio sisältää vain yhden muokattavan arvon, on arvo
          // ensimmäisessä muutosobjektissa. Noudetaan se siitä.
          kuvaus: path(
            ["0", "properties", "value"],
            changeObjects.oppilaitoksenTarkoitus
          )
        },
        tila: "LISAYS"
      }
    : null;

  let oppilatosKoulutustehtavaBackendChangeObjects = null;
  const oppilaitoksenKoulutustehtavaBackendLisays = changeObjects
    .oppilaitoksenKoulutustehtava.length
    ? {
        generatedId: `oppilaitoksenKoulutustehtava-${Math.random()}`,
        kohde: find(propEq("tunniste", "koulutustehtava"), kohteet),
        koodiarvo: "1",
        koodisto: "koulutustehtava",
        kuvaus: "",
        maaraystyyppi: find(propEq("tunniste", "OIKEUS"), maaraystyypit),
        meta: {
          changeObjects: changeObjects.oppilaitoksenKoulutustehtava,
          // Koska osio sisältää vain yhden muokattavan arvon, on arvo
          // ensimmäisessä muutosobjektissa. Noudetaan se siitä.
          kuvaus: path(
            ["0", "properties", "value"],
            changeObjects.oppilaitoksenKoulutustehtava
          )
        },
        tila: "LISAYS"
      }
    : null;

  if (oppilaitoksenKoulutustehtavaBackendLisays) {
    oppilatosKoulutustehtavaBackendChangeObjects = [];
    oppilatosKoulutustehtavaBackendChangeObjects.push(
      oppilaitoksenKoulutustehtavaBackendLisays
    );

    const edellisenLuvanOppilaitoksenkoulutustehtavaChangeObject = filter(
      propEq("koodisto", "koulutustehtava"),
      lupaMaaraykset
    );
    if (edellisenLuvanOppilaitoksenkoulutustehtavaChangeObject.length > 0) {
      map(maarays => {
        oppilatosKoulutustehtavaBackendChangeObjects.push({
          maaraysUuid: maarays.uuid,
          generatedId: `oppilaitoksenKoulutustehtava-${Math.random()}`,
          kohde: maarays.kohde,
          koodiarvo: "1",
          koodisto: "koulutustehtava",
          kuvaus: "",
          maaraystyyppi: find(propEq("tunniste", "OIKEUS"), maaraystyypit),
          meta: {},
          tila: "POISTO"
        });
      }, edellisenLuvanOppilaitoksenkoulutustehtavaChangeObject || []).filter(
        Boolean
      );
    }
  }

  // 4. MUUT EHDOT
  const muutEhdot = await muutEhdotHelper.defineBackendChangeObjects(
    {
      muutEhdot: changeObjects.muutEhdot
    },
    maaraystyypit,
    lupa.maaraykset,
    kohteet,
    locale,
    find(propEq("tunniste", "muutehdot"), kohteet)
  );

  // 2. SOPIMUSKUNNAT
  let categoryFilterChangeObj =
    find(
      propEq("anchor", "toimintaalue.categoryFilter"),
      changeObjects.toimintaalue || []
    ) || {};

  const opetustaAntavatKunnat =
    await opetustaAntavatKunnatHelper.defineBackendChangeObjects(
      {
        quickFilterChanges: path(
          ["properties", "quickFilterChanges"],
          categoryFilterChangeObj
        ),
        changesByProvince: path(
          ["properties", "changesByProvince"],
          categoryFilterChangeObj
        ),
        lisatiedot: find(
          compose(includes(".lisatiedot."), prop("anchor")),
          changeObjects.toimintaalue || []
        )
      },
      kunnatKohde,
      maaraystyypit,
      lupa.maaraykset,
      kohteet,
      "kunnat"
    );
  const sopimuskunnat = map(beChangeObject => {
    return assocPath(
      ["meta", "oppilaitosmaarays"],
      "Sopimuskunta",
      beChangeObject
    );
  }, opetustaAntavatKunnat);

  let objectToSave = {
    alkupera,
    koulutustyyppi,
    jarjestajaOid: organisation.oid,
    jarjestajaYtunnus: organisation.ytunnus,
    luoja: sessionStorage.getItem("username"),
    // luontipvm: moment().valueOf(),
    luontipvm: moment().format("YYYY-MM-DD"),
    lupaUuid: prop("uuid", lupa),
    // uuid: lupa.asiatyyppi.uuid,
    tila:
      muutospyynnonTila ||
      (alkupera === "ESITTELIJA" && uuid ? "VALMISTELUSSA" : "LUONNOS"),
    paivittaja: "string",
    paivityspvm: null,
    voimassaalkupvm: null,
    voimassaloppupvm: null, // TODO: find the correct value somehow,
    liitteet: allAttachments,
    meta: {},
    muutokset: flatten([
      erityisetKoulutustehtavat,
      opetuskielet,
      oppilaitoksenTarkoitusBackendChangeObject,
      oppilatosKoulutustehtavaBackendChangeObjects,
      muutEhdot,
      sopimuskunnat,
      oppilaitoksenKuntaMuutokset,
      oppilaitosMuutokset,
      toimintaalue
    ]).filter(Boolean),
    uuid
  };

  const asianumeroObj = find(
    propEq("anchor", "paatoksentiedot.asianumero.A"),
    changeObjects.paatoksentiedot || []
  );

  objectToSave.asianumero = asianumeroObj ? asianumeroObj.properties.value : "";

  const diaarinumeroObj = find(
    propEq("anchor", "paatoksentiedot.diaarinumero.A"),
    changeObjects.paatoksentiedot || []
  );

  objectToSave.diaarinumero = diaarinumeroObj
    ? diaarinumeroObj.properties.value
    : "";

  const paatospaivaObj = find(
    propEq("anchor", "paatoksentiedot.paatospaiva.A"),
    changeObjects.paatoksentiedot || []
  );

  objectToSave.paatospvm =
    paatospaivaObj && paatospaivaObj.properties.value
      ? moment(paatospaivaObj.properties.value).format("YYYY-MM-DD")
      : "";
  const voimaantulopaivaObj = find(
    propEq("anchor", "paatoksentiedot.voimaantulopaiva.A"),
    changeObjects.paatoksentiedot || []
  );
  objectToSave.voimassaalkupvm =
    voimaantulopaivaObj && voimaantulopaivaObj.properties.value
      ? moment(voimaantulopaivaObj.properties.value).format("YYYY-MM-DD")
      : "";
  const paattymispaivamaaraObj = find(
    propEq("anchor", "paatoksentiedot.paattymispaivamaara.A"),
    changeObjects.paatoksentiedot || []
  );
  objectToSave.voimassaloppupvm =
    paattymispaivamaaraObj && paattymispaivamaaraObj.properties.value
      ? moment(paattymispaivamaaraObj.properties.value).format("YYYY-MM-DD")
      : "";

  // This helps the frontend to initialize the first four fields on form load.
  objectToSave.meta.paatoksentiedot = changeObjects.paatoksentiedot;

  // Adds yllapitaja and oppilaitos to meta on new permit.
  // Meta is used when saved permit is loaded.
  if (lisaksi && lisaksi.yllapitaja && lisaksi.oppilaitos) {
    objectToSave.meta.yllapitaja = lisaksi.yllapitaja;
  }

  objectToSave.oppilaitostyyppi = oppilaitostyyppi;

  return objectToSave;
}
