import { CellImplementations } from "graphHandling/graphTypes";
import { CellId } from "processes/CellId";
import { CellId as CellId_000 } from "processes/CellId";

// Funktiot, jotka määrittävät graafin solmujen sisällön.
import { fetch_lupaJaMuutospyynto } from "../fetch_lupaJaMuutospyynto";
import initialize_kumoaMuutokset from "./cellImplementations/initialize_kumoaMuutokset";
import { initialize_autocomplete1 } from "./cellImplementations/initialize_autocomplete1";
import { initialize_autocomplete2 } from "./cellImplementations/initialize_autocomplete2";
import { update_autocomplete1 } from "./cellImplementations/update_autocomplete1";
import { update_autocomplete2 } from "./cellImplementations/update_autocomplete2";
import { initialize_lisatiedot } from "./cellImplementations/initialize_lisatiedot";
import { update_lisatiedot } from "./cellImplementations/update_lisatiedot";
import initialize_restrictions from "./cellImplementations/initialize_restrictions";

const mainProcessId = "000";

export const graphDefinition = {
  cells: {
    [CellId.INITIALIZE_OPETUSKIELET_PROCESS]: {
      isFirstCell: true,
      name: "Start",
      out: {
        [CellId.FETCH_LUPA_JA_MUUTOSPYYNTO]: {}
      }
    },
    [CellId.FETCH_LUPA_JA_MUUTOSPYYNTO]: {
      name: "Fetch: Lupa",
      out: {
        [CellId.INITIALIZE_RESTRICTIONS]: {},
        [CellId.INITIALIZE_AUTOCOMPLETE_1]: {},
        [CellId.INITIALIZE_AUTOCOMPLETE_2]: {},
        [CellId.INITIALIZE_LISATIEDOT]: {},
        [CellId.INITIALIZE_KUMOA_MUUTOKSET]: {}
      }
    },
    [CellId.INITIALIZE_AUTOCOMPLETE_1]: {
      name: "Initialize: Ensisijaiset",
      out: {
        [CellId.UDPATE_AUTOCOMPLETE_1]: {
          events: ["onChange"]
        }
      }
    },
    [CellId.INITIALIZE_AUTOCOMPLETE_2]: {
      name: "Initialize: Toissijaiset",
      out: {
        [CellId.UDPATE_AUTOCOMPLETE_2]: {
          events: ["onChange"]
        }
      }
    },
    [CellId.INITIALIZE_KUMOA_MUUTOKSET]: {
      name: "Initialize: Kumoa muutokset",
      out: {
        [CellId_000.UPDATE_MAIN_PROCESS]: {
          events: ["onClick"],
          processId: mainProcessId
        }
      }
    },
    [CellId.INITIALIZE_LISATIEDOT]: {
      name: "Initialize: Lisätiedot",
      out: {
        [CellId.UPDATE_LISATIEDOT]: {
          events: ["onChange"]
        }
      }
    },
    [CellId.INITIALIZE_RESTRICTIONS]: {
      name: "Nouda: Rajoitteet"
    },
    [CellId.UDPATE_AUTOCOMPLETE_1]: {
      name: "Update: Autocomplete 1",
      out: {
        [CellId_000.UPDATE_MAIN_PROCESS]: {
          processId: mainProcessId
        }
      }
    },
    [CellId.UDPATE_AUTOCOMPLETE_2]: {
      name: "Update: Autocomplete 2",
      out: {
        [CellId_000.UPDATE_MAIN_PROCESS]: {
          processId: mainProcessId
        }
      }
    },
    [CellId.UPDATE_LISATIEDOT]: {
      name: "Update: Lisätiedot",
      out: {
        [CellId_000.UPDATE_MAIN_PROCESS]: {
          processId: mainProcessId
        }
      }
    }
  }
};

export const cellImplementations: CellImplementations = {
  [CellId.FETCH_LUPA_JA_MUUTOSPYYNTO]: fetch_lupaJaMuutospyynto,

  [CellId.INITIALIZE_AUTOCOMPLETE_1]: initialize_autocomplete1,
  [CellId.INITIALIZE_AUTOCOMPLETE_2]: initialize_autocomplete2,
  [CellId.INITIALIZE_KUMOA_MUUTOKSET]: initialize_kumoaMuutokset,
  [CellId.INITIALIZE_LISATIEDOT]: initialize_lisatiedot,
  [CellId.INITIALIZE_RESTRICTIONS]: initialize_restrictions,
  [CellId.UDPATE_AUTOCOMPLETE_1]: update_autocomplete1,
  [CellId.UDPATE_AUTOCOMPLETE_2]: update_autocomplete2,
  [CellId.UPDATE_LISATIEDOT]: update_lisatiedot
};
