import { Maarays, MaaraysWithGroupedRajoitteet } from "koodistodatanTyypit";
import {
  assoc,
  concat,
  groupBy,
  isEmpty,
  length,
  map,
  path,
  filter,
  reject,
  isNil
} from "ramda";

export const initializeRajoitteet = (
  maaraykset: Array<Maarays> = []
): Array<MaaraysWithGroupedRajoitteet> => {
  /** Groupataan alimääräykset rajoiteId:n mukaan ja asetetaan grouppauksen tulos aliMaaraykset propertyyn */

  const rajoitemaaraykset = reject(
    isNil,
    map((maarays: Maarays) => {
      const alimaaraykset: Array<Maarays> = maarays.aliMaaraykset || [];
      const groupedAlimaaraykset = reject(
        isNil,
        groupBy(
          alimaarays => path(["meta", "rajoiteId"], alimaarays) || "",
          alimaaraykset
        )
      );

      return !isEmpty(groupedAlimaaraykset)
        ? assoc("aliMaaraykset", groupedAlimaaraykset, {
            ...maarays
          })
        : null;
    }, maaraykset)
  ) as unknown as Array<MaaraysWithGroupedRajoitteet>;

  /** Lisätään vielä opiskelijamäärämääräykset, joilla ei ole alimääräyksiä */
  const opiskelijamaaramaaraykset = filter(
    maarays =>
      !length(maarays.aliMaaraykset) &&
      maarays.koodisto === "kujalisamaareet" &&
      path(["maaraystyyppi", "tunniste"], maarays) === "RAJOITE",
    maaraykset
  );

  /** Opiskelijamäärämääräys, jolla ei alimääräyksiä samaan muotoon kuin muut rajoitemääräykset */
  const opiskelijamaaramaarayksetModified = map(maarays => {
    const rajoiteId = path(["meta", "rajoiteId"], maarays);
    return {
      ...maarays,
      aliMaaraykset: rajoiteId ? { [rajoiteId as string]: [maarays] } : {}
    };
  }, opiskelijamaaramaaraykset);

  return length(opiskelijamaaramaaraykset)
    ? concat(rajoitemaaraykset, opiskelijamaaramaarayksetModified)
    : rajoitemaaraykset;
};
