import {
  CellImplementations,
  GraphStoreFunctions
} from "graphHandling/graphTypes";
import { CellId } from "processes/CellId";
import { fetch_kohteet } from "graphs/fetch/fetch_kohteet";
import {
  fetch_ajalla,
  fetch_opetuskieletOPH,
  fetch_joistalisaksi,
  fetch_kujalisamaareet,
  fetch_lisatietojaKoodisto,
  fetch_opetuksenJarjestamismuodot,
  fetch_opetustehtavatKoodisto,
  fetch_poErityisetKoulutustehtavatKoodisto,
  fetch_poMuutEhdotKoodisto
} from "graphs/fetch/fetch_koodisto";
import { fetch_maaraystyypit } from "graphs/fetch/fetch_maaraystyypit";
import { fetch_muutospyynto } from "graphs/fetch/fetch_muutospyynto";
import { fetch_opetustehtavat } from "graphs/fetch/fetch_opetustehtavat";
import { fetch_organisation } from "./cellImplementations/fetch_organisation";
import { fetch_viimeisinLupa } from "./cellImplementations/fetch_viimeisinLupa";
import {
  kasittele_poErityisetKoulutustehtavatKoodisto,
  kasittele_lisatietojaKoodisto,
  kasittele_poMuutEhdotKoodisto,
  kasittele_opetustehtavatKoodisto,
  kasittele_opetustehtavat,
  kasittele_ajallaKoodisto,
  kasittele_joistalisaksiKoodisto,
  kasittele_kujalisamaareetKoodisto,
  kasittele_opetuksenJarjestamismuodot,
  kasittele_ensisijaisetOpetuskieletOPH,
  kasittele_toissijaisetOpetuskieletOPH
} from "graphs/kasittele_koodisto";
import {
  tallenna_ajallaKoodistoPaikalliseenTietovarastoon,
  tallenna_ensisijaisetOpetuskieletOPHKoodistoPaikalliseenTietovarastoon,
  tallenna_joistalisaksiKoodistoPaikalliseenTietovarastoon,
  tallenna_kujalisamaareetKoodistoPaikalliseenTietovarastoon,
  tallenna_lisatietojaKoodistoPaikalliseenTietovarastoon,
  tallenna_opetuksenJarjestamisMuodotKoodistoPaikalliseenTietovarastoon,
  tallenna_opetustehtavatKoodistoPaikalliseenTietovarastoon,
  tallenna_opetustehtavatPaikalliseenTietovarastoon,
  tallenna_poErityisetKoulutustehtavatKoodistoPaikalliseenTietovarastoon,
  tallenna_poMuutEhdotKoodistoPaikalliseenTietovarastoon,
  tallenna_toissijaisetOpetuskieletOPHKoodistoPaikalliseenTietovarastoon,
  tallennaMaaraystyypitPaikalliseenTietovarastoon
} from "./cellImplementations/tallenna_paikalliseenTietovarastoon";
import { update_mainProcess } from "./cellImplementations/update_mainProcess";
import { fetch_lupaByUuid } from "./cellImplementations/fetch_lupaByUuid";

export const processDefinition = {
  cells: {
    [CellId.INITIALIZE_GRAPH]: {
      isFirstCell: true,
      name: "Muutospyyntö",
      out: {
        [CellId.FETCH_KOHTEET]: {},
        [CellId.FETCH_MAARAYSTYYPIT]: {},
        [CellId.FETCH_MUUTOSPYYNTO]: {
          edge: {
            name: "Muutospyynnön uuid on käytettävissä"
          },
          isConnected: (
            storeFunctions: GraphStoreFunctions,
            customParams: Record<string, unknown>
          ): boolean => !!customParams.muutospyyntoUuid
        },
        [CellId.FETCH_KOODISTO_LISATIETOJA]: {},
        [CellId.FETCH_KOODISTO_OPETUSTEHTAVAT]: {},
        [CellId.FETCH_KOODISTO_PO_ERITYISET_KOULUTUSTEHTAVAT]: {},
        [CellId.FETCH_KOODISTO_PO_MUUT_EHDOT]: {},
        [CellId.FETCH_KOODISTO_AJALLA]: {},
        [CellId.FETCH_KOODISTO_JOISTA_LISAKSI]: {},
        [CellId.FETCH_KOODISTO_KUJALISAMAAREET]: {},
        [CellId.FETCH_KOODISTO_OPETUKSEN_JARJESTAMISMUODOT]: {},
        [CellId.FETCH_KOODISTO_OPETUSKIELET_OPH]: {},
        [CellId.FETCH_OPETUSTEHTAVAT]: {},
        [CellId.FETCH_ORGANISATION]: {},
        [CellId.FETCH_VIIMEISIN_LUPA]: {
          edge: {
            name: "Muutospyynnön uuid ei ole käytettävissä"
          },
          isConnected: (
            storeFunctions: GraphStoreFunctions,
            customParams: Record<string, unknown>
          ): boolean => !customParams.muutospyyntoUuid
        }
      }
    },
    [CellId.FETCH_KOODISTO_LISATIETOJA]: {
      name: "Nouda: Lisatietoja-koodisto",
      out: {
        [CellId.KASITTELE_KOODISTO_LISATIETOJA]: {}
      }
    },
    [CellId.FETCH_KOODISTO_OPETUSTEHTAVAT]: {
      name: "Nouda: Opetustehtavat-koodisto",
      out: {
        [CellId.KASITTELE_KOODISTO_OPETUSTEHTAVAT]: {}
      }
    },
    [CellId.FETCH_KOODISTO_PO_ERITYISET_KOULUTUSTEHTAVAT]: {
      name: "Nouda: PO - Erityiset koulutustehtävät -koodisto",
      out: {
        [CellId.KASITTELE_KOODISTO_PO_ERITYISET_KOULUTUSTEHTAVAT]: {}
      }
    },
    [CellId.FETCH_KOODISTO_PO_MUUT_EHDOT]: {
      name: "Nouda: PO - Muut ehdot -koodisto",
      out: {
        [CellId.KASITTELE_KOODISTO_PO_MUUT_EHDOT]: {}
      }
    },
    [CellId.FETCH_KOODISTO_AJALLA]: {
      name: "Nouda: Ajalla -koodisto",
      out: {
        [CellId.KASITTELE_KOODISTO_AJALLA]: {}
      }
    },
    [CellId.FETCH_KOODISTO_JOISTA_LISAKSI]: {
      name: "Nouda: Joistalisaksi -koodisto",
      out: {
        [CellId.KASITTELE_KOODISTO_JOISTA_LISAKSI]: {}
      }
    },
    [CellId.FETCH_KOODISTO_KUJALISAMAAREET]: {
      name: "Nouda: Kujalisamaareet -koodisto",
      out: {
        [CellId.KASITTELE_KOODISTO_KUJALISAMAAREET]: {}
      }
    },
    [CellId.FETCH_KOODISTO_OPETUKSEN_JARJESTAMISMUODOT]: {
      name: "Nouda: Opetuksen järjestämismuodot - koodisto",
      out: {
        [CellId.KASITTELE_KOODISTO_OPETUKSEN_JARJESTAMISMUODOT]: {}
      }
    },
    [CellId.FETCH_KOODISTO_OPETUSKIELET_OPH]: {
      name: "Nouda: Ensisijaiset opetuskielet OPH - koodisto",
      out: {
        [CellId.KASITTELE_KOODISTO_ENSIJAISET_OPETUSKIELET_OPH]: {},
        [CellId.KASITTELE_KOODISTO_TOISSIJAISET_OPETUSKIELET_OPH]: {}
      }
    },
    [CellId.FETCH_KOHTEET]: {
      name: "Nouda: Kohteet",
      out: {
        [CellId.TALLENNA_KOHTEET_PAIKALLISEEN_TIETOVARASTOON]: {}
      }
    },
    [CellId.FETCH_MAARAYSTYYPIT]: {
      name: "Nouda: Määräystyypit",
      out: {
        [CellId.TALLENNA_MAARAYSTYYPIT_PAIKALLISEEN_TIETOVARASTOON]: {}
      }
    },
    [CellId.FETCH_MUUTOSPYYNTO]: {
      name: "Nouda: Muutospyyntö",
      out: {
        [CellId.FETCH_LUPA_BY_UUID]: {}
      }
    },
    [CellId.FETCH_OPETUSTEHTAVAT]: {
      name: "Nouda: Opetustehtavat",
      out: {
        [CellId.KASITTELE_OPETUSTEHTAVAT]: {}
      }
    },
    [CellId.FETCH_ORGANISATION]: {
      name: "Nouda: Organisaation tiedot"
    },
    [CellId.FETCH_VIIMEISIN_LUPA]: {
      name: "Nouda: Viimeisin lupa"
    },
    [CellId.FETCH_LUPA_BY_UUID]: {
      name: "Nouda: Lupa uuid arvolla"
    },
    [CellId.KASITTELE_KOODISTO_LISATIETOJA]: {
      name: "Koodisto: Muunnetaan helpommin käsiteltävään muotoon",
      out: {
        [CellId.TALLENNA_LISATIETOJA_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]: {}
      }
    },
    [CellId.KASITTELE_KOODISTO_OPETUSTEHTAVAT]: {
      name: "Koodisto: Muunnetaan helpommin käsiteltävään muotoon",
      out: {
        [CellId.TALLENNA_OPETUSTEHTAVAT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]:
          {}
      }
    },
    [CellId.KASITTELE_KOODISTO_PO_ERITYISET_KOULUTUSTEHTAVAT]: {
      name: "Koodisto: Muunnetaan helpommin käsiteltävään muotoon",
      out: {
        [CellId.TALLENNA_PO_ERITYISET_KOULUTUSTEHTAVAT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]:
          {}
      }
    },
    [CellId.KASITTELE_KOODISTO_PO_MUUT_EHDOT]: {
      name: "Koodisto: Muunnetaan helpommin käsiteltävään muotoon",
      out: {
        [CellId.TALLENNA_PO_MUUT_EHDOT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]: {}
      }
    },
    [CellId.KASITTELE_KOODISTO_AJALLA]: {
      name: "Koodisto: Muunnetaan helpommin käsiteltävään muotoon",
      out: {
        [CellId.TALLENNA_AJALLA_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]: {}
      }
    },
    [CellId.KASITTELE_KOODISTO_JOISTA_LISAKSI]: {
      name: "Koodisto: Muunnetaan helpommin käsiteltävään muotoon",
      out: {
        [CellId.TALLENNA_JOISTALISAKSI_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]: {}
      }
    },
    [CellId.KASITTELE_KOODISTO_KUJALISAMAAREET]: {
      name: "Koodisto: Muunnetaan helpommin käsiteltävään muotoon",
      out: {
        [CellId.TALLENNA_KUJALISAMAAREET_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]:
          {}
      }
    },
    [CellId.KASITTELE_KOODISTO_OPETUKSEN_JARJESTAMISMUODOT]: {
      name: "Koodisto: Muunnetaan helpommin käsiteltävään muotoon",
      out: {
        [CellId.TALLENNA_OPETUKSEN_JARJESTAMISMUODOT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]:
          {}
      }
    },
    [CellId.KASITTELE_KOODISTO_ENSIJAISET_OPETUSKIELET_OPH]: {
      name: "Koodisto: Muunnetaan helpommin käsiteltävään muotoon",
      out: {
        [CellId.TALLENNA_ENSISIJAISET_OPETUSKIELET_OPH_PAIKALLISEEN_TIETOVARASTOON]:
          {}
      }
    },
    [CellId.KASITTELE_KOODISTO_TOISSIJAISET_OPETUSKIELET_OPH]: {
      name: "Koodisto: Muunnetaan helpommin käsiteltävään muotoon",
      out: {
        [CellId.TALLENNA_TOISSIJAISET_OPETUSKIELET_OPH_PAIKALLISEEN_TIETOVARASTOON]:
          {}
      }
    },
    [CellId.KASITTELE_OPETUSTEHTAVAT]: {
      name: "Koodisto: Muunnetaan helpommin käsiteltävään muotoon",
      out: {
        [CellId.TALLENNA_OPETUSTEHTAVAT_PAIKALLISEEN_TIETOVARASTOON]: {}
      }
    },
    [CellId.TALLENNA_LISATIETOJA_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]: {
      name: "Tallenna: Lisatietoja-koodisto paikalliseen tietovarastoon"
    },
    [CellId.TALLENNA_OPETUSTEHTAVAT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]: {
      name: "Tallenna: Opetustehtavat-koodisto paikalliseen tietovarastoon"
    },
    [CellId.TALLENNA_OPETUSTEHTAVAT_PAIKALLISEEN_TIETOVARASTOON]: {
      name: "Tallenna: Opetustehtavat paikalliseen tietovarastoon"
    },
    [CellId.TALLENNA_PO_ERITYISET_KOULUTUSTEHTAVAT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]:
      {
        name: "Tallenna: poErityisetKoulutustehtavat-koodisto paikalliseen tietovarastoon"
      },
    [CellId.TALLENNA_PO_MUUT_EHDOT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]: {
      name: "Tallenna: poMuutEhdot-koodisto paikalliseen tietovarastoon"
    },
    [CellId.TALLENNA_AJALLA_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]: {
      name: "Tallenna: ajalla-koodisto paikalliseen tietovarastoon"
    },
    [CellId.TALLENNA_JOISTALISAKSI_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]: {
      name: "Tallenna: joistalisaksi-koodisto paikalliseen tietovarastoon"
    },
    [CellId.TALLENNA_KUJALISAMAAREET_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]: {
      name: "Tallenna: kujalisamaareet-koodisto paikalliseen tietovarastoon"
    },
    [CellId.TALLENNA_OPETUKSEN_JARJESTAMISMUODOT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]:
      {
        name: "Tallenna: opetuksenjarjestamismuoto-koodisto paikalliseen tietovarastoon"
      },
    [CellId.TALLENNA_ENSISIJAISET_OPETUSKIELET_OPH_PAIKALLISEEN_TIETOVARASTOON]:
      {
        name: "Tallenna: ensisijaiset opetuskielet oph-koodisto paikalliseen tietovarastoon"
      },
    [CellId.TALLENNA_TOISSIJAISET_OPETUSKIELET_OPH_PAIKALLISEEN_TIETOVARASTOON]:
      {
        name: "Tallenna: toissijaiset opetuskielet oph-koodisto paikalliseen tietovarastoon"
      },
    [CellId.TALLENNA_MAARAYSTYYPIT_PAIKALLISEEN_TIETOVARASTOON]: {
      name: "Tallenna: maaraystyypit paikalliseen tietovarastoon"
    },
    [CellId.UPDATE_MAIN_PROCESS]: {
      name: "Päivitä: Pääprosessi"
    }
  }
};

export const cellImplementations: CellImplementations = {
  [CellId.FETCH_KOODISTO_LISATIETOJA]: fetch_lisatietojaKoodisto,
  [CellId.FETCH_KOODISTO_OPETUSTEHTAVAT]: fetch_opetustehtavatKoodisto,
  [CellId.FETCH_KOODISTO_PO_ERITYISET_KOULUTUSTEHTAVAT]:
    fetch_poErityisetKoulutustehtavatKoodisto,
  [CellId.FETCH_KOODISTO_PO_MUUT_EHDOT]: fetch_poMuutEhdotKoodisto,
  [CellId.FETCH_KOODISTO_AJALLA]: fetch_ajalla,
  [CellId.FETCH_KOODISTO_JOISTA_LISAKSI]: fetch_joistalisaksi,
  [CellId.FETCH_KOODISTO_KUJALISAMAAREET]: fetch_kujalisamaareet,
  [CellId.FETCH_KOHTEET]: fetch_kohteet,
  [CellId.FETCH_MAARAYSTYYPIT]: fetch_maaraystyypit,
  [CellId.FETCH_MUUTOSPYYNTO]: fetch_muutospyynto,
  [CellId.FETCH_OPETUSTEHTAVAT]: fetch_opetustehtavat,
  [CellId.FETCH_ORGANISATION]: fetch_organisation,
  [CellId.FETCH_VIIMEISIN_LUPA]: fetch_viimeisinLupa,
  [CellId.FETCH_LUPA_BY_UUID]: fetch_lupaByUuid,
  [CellId.FETCH_KOODISTO_OPETUKSEN_JARJESTAMISMUODOT]:
    fetch_opetuksenJarjestamismuodot,
  [CellId.FETCH_KOODISTO_OPETUSKIELET_OPH]: fetch_opetuskieletOPH,

  [CellId.KASITTELE_KOODISTO_LISATIETOJA]: kasittele_lisatietojaKoodisto,
  [CellId.KASITTELE_KOODISTO_OPETUSTEHTAVAT]: kasittele_opetustehtavatKoodisto,
  [CellId.KASITTELE_KOODISTO_PO_ERITYISET_KOULUTUSTEHTAVAT]:
    kasittele_poErityisetKoulutustehtavatKoodisto,
  [CellId.KASITTELE_KOODISTO_PO_MUUT_EHDOT]: kasittele_poMuutEhdotKoodisto,
  [CellId.KASITTELE_KOODISTO_PO_MUUT_EHDOT]: kasittele_poMuutEhdotKoodisto,
  [CellId.KASITTELE_KOODISTO_PO_MUUT_EHDOT]: kasittele_poMuutEhdotKoodisto,
  [CellId.KASITTELE_KOODISTO_AJALLA]: kasittele_ajallaKoodisto,
  [CellId.KASITTELE_KOODISTO_JOISTA_LISAKSI]: kasittele_joistalisaksiKoodisto,
  [CellId.KASITTELE_KOODISTO_KUJALISAMAAREET]:
    kasittele_kujalisamaareetKoodisto,
  [CellId.KASITTELE_OPETUSTEHTAVAT]: kasittele_opetustehtavat,
  [CellId.KASITTELE_KOODISTO_OPETUKSEN_JARJESTAMISMUODOT]:
    kasittele_opetuksenJarjestamismuodot,
  [CellId.KASITTELE_KOODISTO_ENSIJAISET_OPETUSKIELET_OPH]:
    kasittele_ensisijaisetOpetuskieletOPH,
  [CellId.KASITTELE_KOODISTO_TOISSIJAISET_OPETUSKIELET_OPH]:
    kasittele_toissijaisetOpetuskieletOPH,

  [CellId.TALLENNA_LISATIETOJA_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]:
    tallenna_lisatietojaKoodistoPaikalliseenTietovarastoon,
  [CellId.TALLENNA_OPETUSTEHTAVAT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]:
    tallenna_opetustehtavatKoodistoPaikalliseenTietovarastoon,
  [CellId.TALLENNA_OPETUSTEHTAVAT_PAIKALLISEEN_TIETOVARASTOON]:
    tallenna_opetustehtavatPaikalliseenTietovarastoon,
  [CellId.TALLENNA_PO_ERITYISET_KOULUTUSTEHTAVAT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]:
    tallenna_poErityisetKoulutustehtavatKoodistoPaikalliseenTietovarastoon,
  [CellId.TALLENNA_PO_MUUT_EHDOT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]:
    tallenna_poMuutEhdotKoodistoPaikalliseenTietovarastoon,
  [CellId.TALLENNA_AJALLA_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]:
    tallenna_ajallaKoodistoPaikalliseenTietovarastoon,
  [CellId.TALLENNA_JOISTALISAKSI_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]:
    tallenna_joistalisaksiKoodistoPaikalliseenTietovarastoon,
  [CellId.TALLENNA_KUJALISAMAAREET_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]:
    tallenna_kujalisamaareetKoodistoPaikalliseenTietovarastoon,
  [CellId.TALLENNA_OPETUKSEN_JARJESTAMISMUODOT_KOODISTO_PAIKALLISEEN_TIETOVARASTOON]:
    tallenna_opetuksenJarjestamisMuodotKoodistoPaikalliseenTietovarastoon,
  [CellId.TALLENNA_ENSISIJAISET_OPETUSKIELET_OPH_PAIKALLISEEN_TIETOVARASTOON]:
    tallenna_ensisijaisetOpetuskieletOPHKoodistoPaikalliseenTietovarastoon,
  [CellId.TALLENNA_TOISSIJAISET_OPETUSKIELET_OPH_PAIKALLISEEN_TIETOVARASTOON]:
    tallenna_toissijaisetOpetuskieletOPHKoodistoPaikalliseenTietovarastoon,
  [CellId.TALLENNA_MAARAYSTYYPIT_PAIKALLISEEN_TIETOVARASTOON]:
    tallennaMaaraystyypitPaikalliseenTietovarastoon,

  [CellId.UPDATE_MAIN_PROCESS]: update_mainProcess
};
