import { getKoulutusmuodot } from "utils/common";
import { Options } from "../graphTypes";
import { IntlShape } from "react-intl";
import { isNil, mapObjIndexed, prop, reject, sortBy, values } from "ramda";
import { koulutustyypitMap } from "utils/constants";

export function getKoulutusmuotovaihtoehdot(intl: IntlShape): Options {
  const koulutusmuodot = getKoulutusmuodot(intl);
  const koulutusmuotovaihtoehdot = sortBy(
    prop("label"),
    reject(
      isNil,
      values(
        mapObjIndexed(koulutusmuoto => {
          return koulutusmuoto.koulutustyyppi !== koulutustyypitMap.TUVA &&
            koulutusmuoto.koulutustyyppi !==
              koulutustyypitMap.TAITEEN_PERUSOPETUS &&
            koulutusmuoto.koulutustyyppi !== koulutustyypitMap.VAPAASIVISTYSTYO
            ? {
                label: koulutusmuoto.paasivunOtsikko,
                value: koulutusmuoto.koulutustyyppi || ""
              }
            : null;
        }, koulutusmuodot)
      )
    )
  );

  return koulutusmuotovaihtoehdot;
}
