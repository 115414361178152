import { COLORS } from "modules/styles";
export const isInLupa = {
  fontWeight: 600
};

export const isChanged = {
  color: COLORS.OIVA_PURPLE
};

export const isRemoved = {
  color: isChanged.color,
  textDecoration: "line-through"
};

export const isAdded = {
  color: isChanged.color
};
