import {
  addIndex,
  any,
  append,
  defaultTo,
  endsWith,
  filter,
  find,
  forEachObjIndexed,
  groupBy,
  head,
  includes,
  isNil,
  last,
  length,
  map,
  mapObjIndexed,
  match,
  nth,
  path,
  pipe,
  prop,
  reject,
  split,
  values
} from "ramda";
import Lisatiedot from "../../lisatiedot";
import { __ } from "i18n-for-browser";
import {
  getRajoiteListamuodossa,
  getRajoitteetFromMaarays,
  isRajoiteVoimassa
} from "../../../../utils/rajoitteetUtils";
import { getAnchorPart } from "../../../../utils/anchor";
import { getVoimassaOlevatOppilasmaaraMaaraykset } from "helpers/muut";

function isKokonaismaaraRajoite(rajoite) {
  return !!find(
    rajoite =>
      path(["properties", "metadata", "section"], rajoite) ===
        "opiskelijamaarat" &&
      path(["properties", "value", "value"], rajoite) === "kokonaismaara",
    rajoite.changeObjects
  );
}

export const previewOfOpiskelijamaarat = (
  { lomakedata, rajoitteet, maaraykset },
  booleans,
  locale
) => {
  let structure = [];

  /** Kokonaismäärä rajoitteessa määräaika voi kohdistua koko rajoitteeseen tai vain sen alta
   * löytyvään kohdennukseen, joten käydään läpi nämä vaihtoehdot ja poistetaan ei voimassaolevat
   * rajoitteen osat tai koko rajoite **/
  const voimassaOlevatRajoitteet = reject(
    isNil,
    map(rajoite => {
      if (isKokonaismaaraRajoite(rajoite)) {
        const groups = groupBy(
          pipe(
            prop("anchor"),
            match(/^rajoitteet_[^.]+.kohdennukset.0.kohdennukset.[0-9]+/),
            head,
            defaultTo("-"),
            last
          ),
          rajoite?.changeObjects
        );

        // Tarkistetaan ensiksi päätaso, jos se ei ole voimassa ei tarvitse käsitellä rajoitetta pidemmälle
        if (!isRajoiteVoimassa({ changeObjects: groups["-"] })) {
          return null;
        }
        delete groups["-"];

        const voimassaOlevaRajoite = { ...rajoite };
        forEachObjIndexed((value, key) => {
          if (!isRajoiteVoimassa({ changeObjects: value })) {
            voimassaOlevaRajoite.changeObjects = filter(ch => {
              return !includes(
                `.kohdennukset.0.kohdennukset.${key}`,
                prop("anchor", ch)
              );
            }, voimassaOlevaRajoite?.changeObjects || []);
          }
        }, groups);
        return voimassaOlevaRajoite;
      }
      // Rajoite on yksittäinen opiskelijamäärä rajoite ja se ei sisällä kohdennuksia
      return isRajoiteVoimassa(rajoite) ? rajoite : null;
    }, rajoitteet || {})
  );

  const opiskelijamaararajoiteChangeObjsHtml = values(
    mapObjIndexed(rajoite => {
      return getRajoiteListamuodossa(
        rajoite.changeObjects,
        locale,
        nth(
          1,
          split(
            "_",
            getAnchorPart(
              path(["changeObjects", "0", "anchor"], rajoite || []),
              0
            )
          )
        )
      );
    }, voimassaOlevatRajoitteet || {})
  );

  /** Suodatetaan lisätietomääräys pois */
  const voimassaOlevatMaaraykset = getVoimassaOlevatOppilasmaaraMaaraykset(
    filter(maarays => maarays.koodisto === "kujalisamaareet", maaraykset)
  );

  const opiskelijamaararajoiteMaarayksetHtml = map(
    maarays =>
      getRajoitteetFromMaarays(
        maarays.aliMaaraykset,
        locale,
        __("rajoitteet.ajalla"),
        "tyyppi",
        true,
        maarays
      ),
    voimassaOlevatMaaraykset
  );

  const hasKokonaisopiskelijamaararajoitus =
    any(isKokonaismaaraRajoite, values(voimassaOlevatRajoitteet)) ||
    !!find(
      maarays => path(["meta", "tyyppi"], maarays) === "kokonaismaara",
      maaraykset
    );

  if (length(values(voimassaOlevatRajoitteet)) > 0 || length(maaraykset) > 0) {
    if (!hasKokonaisopiskelijamaararajoitus) {
      const eiKokonaisoppilasmaararajoitustaContent = {
        components: [
          {
            name: "HtmlContent",
            properties: {
              content: __("opiskelijamaara.kokonaismaaraEiRajattu")
            }
          }
        ]
      };
      structure = append(eiKokonaisoppilasmaararajoitustaContent, structure);
    }

    /** Lisätään määräyksistä luotu html struktuuriin */
    addIndex(map)((html, index) => {
      structure = append(
        {
          anchor: index,
          components: [
            {
              anchor: "opiskelijamaara",
              name: "HtmlContent",
              properties: {
                content: html
              }
            }
          ]
        },
        structure
      );
    }, opiskelijamaararajoiteMaarayksetHtml);

    /** Lisätään muutos-objekteista luotu html struktuuriin */
    addIndex(map)((html, index) => {
      structure = append(
        {
          anchor: index,
          components: [
            {
              anchor: "opiskelijamaara",
              name: "HtmlContent",
              properties: {
                content: html
              }
            }
          ]
        },
        structure
      );
    }, opiskelijamaararajoiteChangeObjsHtml);
  }

  const lisatiedotNode = find(
    // 1 = koodiston koodiarvo
    node => endsWith(".lisatiedot.1", node.anchor),
    lomakedata
  );

  if (lisatiedotNode && lisatiedotNode.properties.value) {
    structure = append(Lisatiedot(lisatiedotNode.properties.value), structure);
  }

  return structure;
};
