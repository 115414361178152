import { isAdded, isInLupa, isRemoved } from "css/label";
import { find, flatten, map, pathEq, propEq } from "ramda";
import { __ } from "i18n-for-browser";
import { getLocalizedProperty } from "../utils";
import { PaikallisenTietovarastonAvain } from "enums";
import localForage from "localforage";

export async function getLuvanTaiteenalatForm(
  { maaraykset },
  { isPreviewModeOn, isReadOnly },
  locale
) {
  const _isReadOnly = isReadOnly || isPreviewModeOn;
  const lisatiedot = await localForage.getItem(
    PaikallisenTietovarastonAvain.LISATIEDOT
  );
  const taiteenalat = await localForage.getItem("luvanTaiteenalatTPO");

  const lisatiedotObj = find(
    pathEq(["koodisto", "koodistoUri"], "lisatietoja"),
    lisatiedot || []
  );

  const lisatietomaarays = find(propEq("koodisto", "lisatietoja"), maaraykset);

  const lomakerakenne = flatten([
    map(taiteenala => {
      const maarays = find(
        m =>
          propEq("koodiarvo", taiteenala.koodiarvo, m) &&
          propEq("koodisto", "tpoluvantaiteenalat", m),
        maaraykset
      );
      const isLaajaOppimaaraCheckedByDefault =
        maarays &&
        pathEq(["meta", "opetusAnnetaanLaajanOppimaaranMukaan"], true, maarays);
      return {
        anchor: taiteenala.koodiarvo,
        categories: [
          {
            anchor: "laajaoppimaara",
            components: [
              {
                anchor: "A",
                name: "CheckboxWithLabel",
                properties: {
                  labelStyles: {
                    addition: isAdded,
                    removal: isRemoved,
                    custom: Object.assign(
                      {},
                      isLaajaOppimaaraCheckedByDefault ? isInLupa : {}
                    )
                  },
                  isChecked: isLaajaOppimaaraCheckedByDefault,
                  isIndeterminate: false,
                  isReadOnly: _isReadOnly,
                  // Näytetään checkbox vain silloin, kun kyseinen taiteenala on valittu.
                  showForParentOnly: true,
                  title: __("education.laajanOppimaaranMukaan")
                }
              }
            ]
          }
        ],
        components: [
          {
            anchor: "ala",
            name: "CheckboxWithLabel",
            styleClasses: isPreviewModeOn ? ["pl-4"] : [],
            properties: {
              title: getLocalizedProperty(taiteenala.metadata, locale, "nimi"),
              labelStyles: {
                addition: isAdded,
                removal: isRemoved,
                custom: Object.assign({}, maarays ? isInLupa : {})
              },
              isChecked: !!maarays,
              isIndeterminate: !isLaajaOppimaaraCheckedByDefault,
              isPreviewModeOn,
              isReadOnly: _isReadOnly
            }
          }
        ]
      };
    }, taiteenalat),
    lisatiedotObj
      ? [
          {
            anchor: "opetus",
            layout: { margins: { top: "large" } },
            styleClasses: ["mt-10", "pt-10", "border-t"],
            components: [
              {
                anchor: "lisatiedot-info",
                name: "StatusTextRow",
                properties: {
                  title: __("common.lisatiedotInfo")
                }
              }
            ]
          },
          {
            anchor: "lisatiedot",
            components: [
              {
                anchor: lisatiedotObj.koodiarvo,
                name: "TextBox",
                properties: {
                  isPreviewModeOn,
                  isReadOnly: _isReadOnly,
                  title: __("common.lisatiedot"),
                  value: lisatietomaarays ? lisatietomaarays.meta.arvo : ""
                }
              }
            ]
          }
        ]
      : null
  ]).filter(Boolean);

  return lomakerakenne;
}
