import { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Link from "@mui/material/Link";
import { Alert, AlertTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
const PREFIX = "AlertMessage";

const classes = {
  root: `${PREFIX}-root`
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    },
    "& .MuiTypography-gutterBottom": {
      margin: 0 // removes extra margin especially if no message but only title
    },
    "& .MuiAlert-icon": {
      paddingTop: "8px" // overrides default 7px to center icon better with margin 0 of text
    },
    "& .MuiAlert-root": {
      display: "flex"
    },
    "& .MuiAlert-action": {
      marginLeft: 0,
      paddingLeft: "0.5em",
      flexGrow: "1"
    },
    "& .MuiButtonBase-root": {
      marginLeft: "auto !important"
    },
    "& .MuiLink-root": {
      color: "green",
      padding: "2px 0.5em 0 0"
    }
  }
}));

const AlertMessage = props => {
  const [isVisible, setVisible] = useState(true);

  const clickCallback = e => {
    e.preventDefault();
    return props.handleLinkClick();
  };

  return (
    <Root className={`${isVisible ? "" : "hidden"}`}>
      <Collapse in={props.isVisible}>
        <Alert
          id={props.id}
          aria-label={props.ariaLabel}
          severity={props.type}
          variant="outlined"
          icon={props.icon}
          sx={props.sx}
          action={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%"
              }}>
              {props.linkUrl && props.linkText && (
                <Link
                  href={props.linkUrl}
                  style={{ cursor: "pointer", textDecoration: "underline" }}>
                  {props.linkText}
                </Link>
              )}
              {!props.linkUrl && props.handleLinkClick && props.linkText && (
                <Link
                  onClick={clickCallback}
                  style={{ cursor: "pointer", textDecoration: "underline" }}>
                  {props.linkText}
                </Link>
              )}
              {props.isClosable && (
                <IconButton
                  style={{ marginLeft: "1em" }}
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setVisible(false)}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              )}
            </div>
          }>
          {props.title && <AlertTitle>{props.title}</AlertTitle>}
          <p className="p-0">{props.message && props.message}</p>
          {props.children}
        </Alert>
      </Collapse>
    </Root>
  );
};

AlertMessage.defaultProps = {
  id: "Alert",
  isClosable: true,
  type: "info",
  ariaLabel: "alert",
  linkText: "<missing linkText prop>",
  isVisible: true
};

AlertMessage.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  /** can alert be closed */
  isClosable: PropTypes.bool,
  /** is alert visible */
  isVisible: PropTypes.bool,
  /** aria-label as string */
  ariaLabel: PropTypes.string,
  /** title as string */
  title: PropTypes.string,
  /** message as string */
  message: PropTypes.string,
  /** type of alert info (default), warning, error, success */
  type: PropTypes.string,
  /** link url as string (optional) */
  linkUrl: PropTypes.string,
  /** link text as string (optional) */
  linkText: PropTypes.string,
  /** link clicking call back function (if no linkUrl given) */
  handleLinkClick: PropTypes.func,
  sx: PropTypes.object,
  icon: PropTypes.node
};

export default AlertMessage;
