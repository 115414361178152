import { memo } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import StepLabel from "@mui/material/StepLabel";
import Completed from "@mui/icons-material/CheckCircleRounded";
import Incomplete from "@mui/icons-material/ErrorOutlined";
import Normal from "@mui/icons-material/Lens";

const PREFIX = "StepperNavigation";

const classes = {
  root: `${PREFIX}-root`
};

const Root = styled("div")(() => ({
  [`&.${classes.root}`]: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    width: "100%",
    "& .MuiStepLabel-label ": {
      fontFamily: "inherit",
      fontWeight: "500",
      color: "#1d804c",
      letterSpacing: "0.15px" // to prevent text move when bolding
    },
    "& .MuiStepLabel-label:hover": {
      color: "#104e2d !important"
    },
    "& .Mui-error": {
      color: "#1d804c !important"
    },
    "& .Mui-error:hover": {
      color: "#104e2d !important"
    },
    "& .MuiStepLabel-active": {
      color: "#333333 !important",
      fontWeight: "600",
      letterSpacing: "0" // to prevent text move when bolding
    },
    "& .MuiButtonBase-root": {
      marginTop: "-28px"
    },
    "& .MuiButtonBase-root:hover": {
      "& span.MuiStepLabel-label": {
        color: "#104e2d !important"
      }
    },
    "& .MuiStepper-vertical ": {
      margin: "12px 12px 0 12px"
    }
  }
}));

const PREFIX2 = "StepIcons";

const classes2 = {
  root: `${PREFIX2}-root`
};

const StepIcon = styled("div")(() => ({
  [`&.${classes2.root}`]: {
    root: {
      display: "flex",
      height: 22,
      alignItems: "center",
      color: "#a2a4a3"
    },
    active: {
      color: "#1d804c"
    },
    completed: {
      color: "#1d804c",
      zIndex: 1,
      fontSize: 30
    },
    error: {
      color: "#e5c317",
      zIndex: 1,
      fontSize: 30
    }
  }
}));

const styles0 = { marginRight: "1.8em", marginBottom: "1.8em" };
const styles1 = { position: "absolute", fontSize: 30 };
const styles2 = {
  position: "absolute",
  marginLeft: "0.55em",
  marginTop: "0.25em",
  color: "#fff",
  font: "1.1em Inconsolata, monospace"
};
const styles4 = { fontSize: 30 };

const StepIcons = memo(({ active, completed, error, icon }) => {
  return (
    <StepIcon
      className={clsx(classes2.root, {
        [classes2.active]: active,
        [classes2.error]: error,
        [classes2.completed]: completed
      })}>
      {error && <Incomplete style={styles4} className={classes2.error} />}
      {!!completed && <Completed className={classes2.completed} />}
      {!error && !completed && (
        <div style={styles0}>
          <Normal style={styles1} />
          <span style={styles2}>{icon}</span>
        </div>
      )}
    </StepIcon>
  );
});

StepIcons.displayName = "StepIcons";

StepIcons.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  error: PropTypes.bool,
  icon: PropTypes.number
};

const StepperNavigation = memo(
  ({ activeStep, handleStepChange, stepProps }) => {
    return (
      <Root className={classes.root}>
        <Stepper
          nonLinear
          activeStep={activeStep}
          orientation={window.innerWidth >= 768 ? "horizontal" : "vertical"}
          style={{
            backgroundColor: "transparent",
            paddingLeft: 0,
            paddingRight: 0
          }}>
          {stepProps.map((item, index) => {
            const labelProps = {};

            if (item.isFailed === true) {
              labelProps.error = true;
            }
            if (item.isCompleted === true) {
              labelProps.completed = "1";
            }

            return (
              <Step key={item.title} completed={item.isCompleted}>
                <StepButton
                  onClick={() => handleStepChange(index + 1)}
                  disabled={index === activeStep}>
                  <StepLabel
                    style={{ marginBottom: "0.1em" }}
                    StepIconComponent={StepIcons}
                    {...labelProps}>
                    {item.title}
                  </StepLabel>
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
      </Root>
    );
  }
);

StepperNavigation.propTypes = {
  stepProps: PropTypes.array,
  activeStep: PropTypes.number,
  handleStepChange: PropTypes.func
};

StepperNavigation.displayName = "StepperNavigation";

export default StepperNavigation;
