import {
  compose,
  endsWith,
  filter,
  find,
  flatten,
  map,
  path,
  pathEq,
  prop,
  propEq,
  length
} from "ramda";
import { getMaarayksetByTunniste } from "helpers/lupa";
import { luoMuutosobjektitLisatietokentasta } from "helpers/lisatiedot";
import {
  createBECheckboxChangeObjectsForDynamicTextBoxes,
  createDynamicTextBoxBeChangeObjects
} from "services/lomakkeet/dynamic";
import { PaikallisenTietovarastonAvain } from "enums";
import localForage from "localforage";

export const defineBackendChangeObjects = async (
  changeObjects = [],
  kohde,
  maaraystyypit,
  lupaMaaraykset,
  locale,
  kohteet
) => {
  const maaraykset = await getMaarayksetByTunniste(
    kohde.tunniste,
    lupaMaaraykset
  );
  const maaraystyyppi = find(propEq("tunniste", "OIKEUS"), maaraystyypit);
  const erityisetKoulutustehtavat = await localForage.getItem(
    PaikallisenTietovarastonAvain.VST_ERITYISET_KOULUTUSTEHTAVAT
  );

  const muutokset = map(koulutustehtava => {
    // Checkbox-kenttien muutokset
    const checkboxChangeObj = find(
      compose(
        endsWith(`.${koulutustehtava.koodiarvo}.valintaelementti`),
        prop("anchor")
      ),
      changeObjects
    );

    const tehtavaanLiittyvatMaaraykset = filter(
      m =>
        propEq("koodiarvo", koulutustehtava.koodiarvo, m) &&
        propEq("koodisto", "vsterityinenkoulutustehtava", m),
      maaraykset
    );

    const isCheckboxChecked =
      (!!tehtavaanLiittyvatMaaraykset.length && !checkboxChangeObj) ||
      (checkboxChangeObj &&
        pathEq(["properties", "isChecked"], true, checkboxChangeObj));

    // Kuvauskenttien muutokset kohdassa (muu ehto)
    const kuvausChangeObjects = filter(changeObj => {
      return (
        koulutustehtava.koodiarvo ===
          path(["metadata", "koodiarvo"], changeObj.properties) &&
        endsWith(".kuvaus", changeObj.anchor)
      );
    }, changeObjects);

    let kuvausBEchangeObjects = [];

    if (length(kuvausChangeObjects) && isCheckboxChecked) {
      kuvausBEchangeObjects = createDynamicTextBoxBeChangeObjects(
        kuvausChangeObjects,
        tehtavaanLiittyvatMaaraykset,
        isCheckboxChecked,
        koulutustehtava,
        maaraystyyppi,
        maaraystyypit,
        [],
        checkboxChangeObj,
        kohde,
        kohteet
      );
    } else {
      return createBECheckboxChangeObjectsForDynamicTextBoxes(
        checkboxChangeObj,
        koulutustehtava,
        [],
        kohteet,
        kohde,
        maaraystyypit,
        maaraystyyppi,
        tehtavaanLiittyvatMaaraykset,
        isCheckboxChecked,
        locale,
        "vsterityinenkoulutustehtava"
      );
    }

    return [kuvausBEchangeObjects];
  }, erityisetKoulutustehtavat);

  const lisatietomuutokset = await luoMuutosobjektitLisatietokentasta(
    kohde,
    changeObjects,
    maaraystyyppi
  );

  return flatten([muutokset, lisatietomuutokset]).filter(Boolean);
};
