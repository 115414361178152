import { Fragment, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PropTypes from "prop-types";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import GetApp from "@mui/icons-material/GetApp";
import InsertInvitation from "@mui/icons-material/InsertInvitation";
import * as R from "ramda";

/**
 * Simple menus open over the anchor element by default (this option can be
 * changed via props). When close to a screen edge, simple menus vertically
 * realign to make sure that all menu items are completely visible. For more
 * information see Material-UI's SimpleMenu component.
 * @param {object} props - properties object.
 */
function SimpleMenu({
  actions = [],
  id,
  isExpanded,
  size = "medium",
  anchorOrigin = {},
  transformOrigin = {},
  menuClasses = {},
  menuItemClasses = {}
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  /**
   * This is called to get the menu open. When it's open the list of actions
   * are visible.
   * @param {object} event - Native browser event.
   */
  const handleClick = event => {
    /**
     * Stopping is written for using the SimpleMenu in a cell of the Table
     * component. If propagation is allowed clicking the menu icon
     * also triggers click events of SimpleMenu's parent elements.
     */
    event.stopPropagation();
    // This opens the menu and shows its actions.
    setAnchorEl(event.currentTarget);
  };

  /**
   * Calls the callback function of the selected action (if available) and
   * closes the menu.
   * @param {object} event - Native browser event.
   * @param {object} action - Action that user has selected (clicked).
   * @param {string} action.id  - Identifies the action.
   * @param {string} action.text - Text that is shown on UI to make selecting easier.
   * @param {function} action.onClick - Callback function. Is called when user selects the action.
   */
  const handleClose = (event, action) => {
    /**
     * Stopping is written for using the SimpleMenu in a cell of the Table
     * component. If propagation is allowed clicking the menu icon
     * also triggers click events of SimpleMenu's parent elements.
     */
    event.stopPropagation();
    if (action && action.onClick) {
      // Action's callback function is called here.
      action.onClick(action);
    }
    // Closes the menu.
    setAnchorEl(null);
  };

  const getMenuitemIcon = action => {
    return action.name === "delete" ? (
      <Delete
        fontSize={size}
        color={action.isDisabled ? "disabled" : "action"}
      />
    ) : action.name === "edit" ? (
      <Edit fontSize={size} color={action.isDisabled ? "disabled" : "action"} />
    ) : action.name === "download" ? (
      <GetApp
        fontSize={size}
        color={action.isDisabled ? "disabled" : "action"}
      />
    ) : action.name === "date" ? (
      <InsertInvitation
        fontSize={size}
        color={action.isDisabled ? "disabled" : "action"}
      />
    ) : null;
  };

  return isExpanded ? (
    <Fragment>
      {R.addIndex(R.map)((action, i) => {
        return (
          <label key={i}>
            <IconButton
              aria-label={action.text}
              className={action.isHidden ? "invisible" : ""}
              disabled={action.isDisabled}
              onClick={e => {
                handleClose(e, action);
                return false;
              }}
              size={size}>
              {getMenuitemIcon(action)}
            </IconButton>
          </label>
        );
      }, actions)}
    </Fragment>
  ) : (
    <Fragment>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size={size}>
        <MoreVertIcon fontSize={size} />
      </IconButton>

      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        classes={menuClasses}>
        {R.addIndex(R.map)((action, i) => {
          return (
            <MenuItem
              key={`action-${i}`}
              disabled={!!action.disabled}
              onClick={e => {
                handleClose(e, action);
                return false;
              }}
              classes={menuItemClasses}>
              {getMenuitemIcon(action)}
              <span className={"ml-2"}>{action.text}</span>
            </MenuItem>
          );
        }, actions)}
      </Menu>
    </Fragment>
  );
}

SimpleMenu.propTypes = {
  // Identifier of menu instance
  id: PropTypes.string.isRequired,
  // Is the menu expanded in the beginning
  isExpanded: PropTypes.bool,
  // List of menu actions
  actions: PropTypes.array,
  // Size of menu icon buttons (large, medium, etc.)
  size: PropTypes.string,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  menuClasses: PropTypes.object,
  menuItemClasses: PropTypes.object
};

export default SimpleMenu;
