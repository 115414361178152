import moment from "moment";
import {
  compose,
  dissoc,
  find,
  flatten,
  groupBy,
  last,
  map,
  nth,
  prop,
  propEq,
  split
} from "ramda";
import * as muutEhdotHelper from "helpers/poMuutEhdot";
import * as opetuksenJarjestamismuodotHelper from "helpers/opetuksenJarjestamismuodot";
import * as opetusHelper from "helpers/opetustehtavat";
import * as opiskelijamaaratHelper from "helpers/opiskelijamaarat";
import * as opetuskieletHelper from "helpers/opetuskieletBE";
import * as erityinenKoulutustehtavaHelper from "helpers/poErityisetKoulutustehtavat";
import { koulutustyypitMap } from "utils/constants";
import { createBeObjsForRajoitepoistot } from "helpers/rajoitteetHelper";
import { generoiToimintaalueenMuutosobjektit } from "./toiminta-alueenMuutosobektienGenerointi";
import { getRajoitteetByKohde } from "utils/rajoitteetUtils";

export async function createObjectToSave(
  locale,
  organisation,
  lupa,
  changeObjects,
  uuid,
  kohteet,
  maaraystyypit,
  alkupera = "KJ",
  muutospyynnonTila
) {
  const allAttachmentsRaw = [];
  const koulutustyyppi = koulutustyypitMap.ESI_JA_PERUSOPETUS;

  // ... without tiedosto-property
  const allAttachments = map(attachment => {
    return dissoc("tiedosto", attachment);
  }, allAttachmentsRaw);

  const rajoitteetByRajoiteId = groupBy(
    compose(last, split("_"), nth(0), split("."), prop("anchor")),
    changeObjects.rajoitteet
  );

  // 1. OPETUS, JOTA LUPA KOSKEE
  const opetus = await opetusHelper.defineBackendChangeObjects(
    changeObjects.opetustehtavat,
    getRajoitteetByKohde("opetustehtavat", rajoitteetByRajoiteId),
    maaraystyypit,
    lupa.maaraykset,
    locale,
    kohteet
  );

  // 2. TOIMINTA-ALUE
  const opetustaAntavatKunnat = await generoiToimintaalueenMuutosobjektit(
    find(propEq("tunniste", "kunnatjoissaopetustajarjestetaan"), kohteet),
    kohteet,
    changeObjects.toimintaalue,
    lupa.maaraykset,
    maaraystyypit,
    getRajoitteetByKohde("toimintaalue", rajoitteetByRajoiteId)
  );

  // 3. OPETUSKIELET
  const opetuskielet = await opetuskieletHelper.defineBackendChangeObjects(
    changeObjects.opetuskielet,
    getRajoitteetByKohde("opetuskielet", rajoitteetByRajoiteId),
    find(propEq("tunniste", "opetuskieli"), kohteet),
    maaraystyypit,
    lupa.maaraykset,
    kohteet
  );

  // 4. OPETUKSEN JÄRJESTÄMISMUOTO
  const opetuksenJarjestamismuodot =
    await opetuksenJarjestamismuodotHelper.defineBackendChangeObjects(
      {
        opetuksenJarjestamismuodot: changeObjects.opetuksenJarjestamismuodot,
        rajoitteetByRajoiteId: getRajoitteetByKohde(
          "opetuksenJarjestamismuodot",
          rajoitteetByRajoiteId
        )
      },
      maaraystyypit,
      lupa.maaraykset,
      kohteet
    );

  // 5. ERITYINEN KOULUTUSTEHTÄVÄ
  const erityisetKoulutustehtavat =
    await erityinenKoulutustehtavaHelper.defineBackendChangeObjects(
      {
        erityisetKoulutustehtavat: changeObjects.erityisetKoulutustehtavat,
        rajoitteetByRajoiteId: getRajoitteetByKohde(
          "erityisetKoulutustehtavat",
          rajoitteetByRajoiteId
        )
      },
      maaraystyypit,
      locale,
      lupa.maaraykset,
      kohteet
    );

  // 6. OPPILAS-/OPISKELIJAMÄÄRÄT
  const opiskelijamaarat =
    await opiskelijamaaratHelper.defineBackendChangeObjects(
      {
        opiskelijamaarat: changeObjects.opiskelijamaarat,
        rajoitteetByRajoiteId: getRajoitteetByKohde(
          "opiskelijamaarat",
          rajoitteetByRajoiteId
        )
      },
      maaraystyypit,
      kohteet,
      koulutustyyppi
    );

  // 7. MUUT KOULUTUKSEN JÄRJESTÄMISEEN LIITTYVÄT EHDOT
  const muutEhdot = await muutEhdotHelper.defineBackendChangeObjects(
    {
      muutEhdot: changeObjects.muutEhdot,
      rajoitteetByRajoiteId: getRajoitteetByKohde(
        "muutEhdot",
        rajoitteetByRajoiteId
      )
    },
    maaraystyypit,
    lupa.maaraykset,
    kohteet,
    locale
  );

  /** Luodaan rajoitepoistoihin liittyvät Backend muutosobjektit */
  const rajoitepoistot = createBeObjsForRajoitepoistot(
    changeObjects.rajoitepoistot,
    lupa.maaraykset,
    kohteet,
    maaraystyypit
  );

  let objectToSave = {
    alkupera,
    koulutustyyppi,
    jarjestajaOid: organisation.oid,
    jarjestajaYtunnus: organisation.ytunnus,
    luoja: sessionStorage.getItem("username"),
    // luontipvm: moment().valueOf(),
    luontipvm: moment().format("YYYY-MM-DD"),
    lupaUuid: lupa.uuid,
    // uuid: lupa.asiatyyppi.uuid,
    tila:
      muutospyynnonTila ||
      (alkupera === "ESITTELIJA" && uuid ? "VALMISTELUSSA" : "LUONNOS"),
    paivittaja: "string",
    paivityspvm: null,
    voimassaalkupvm: null,
    voimassaloppupvm: null, // TODO: find the correct value somehow,
    liitteet: allAttachments,
    meta: {},
    muutokset: flatten([
      erityisetKoulutustehtavat,
      muutEhdot,
      opetuksenJarjestamismuodot,
      opetus,
      opetuskielet,
      opetustaAntavatKunnat,
      opiskelijamaarat,
      rajoitepoistot
    ]),
    uuid
  };

  const asianumeroObj = find(
    propEq("anchor", "paatoksentiedot.asianumero.A"),
    changeObjects.paatoksentiedot || []
  );

  objectToSave.asianumero = asianumeroObj ? asianumeroObj.properties.value : "";

  const diaarinumeroObj = find(
    propEq("anchor", "paatoksentiedot.diaarinumero.A"),
    changeObjects.paatoksentiedot || []
  );

  objectToSave.diaarinumero = diaarinumeroObj
    ? diaarinumeroObj.properties.value
    : "";

  const paatospaivaObj = find(
    propEq("anchor", "paatoksentiedot.paatospaiva.A"),
    changeObjects.paatoksentiedot || []
  );

  objectToSave.paatospvm =
    paatospaivaObj && paatospaivaObj.properties.value
      ? moment(paatospaivaObj.properties.value).format("YYYY-MM-DD")
      : "";
  const voimaantulopaivaObj = find(
    propEq("anchor", "paatoksentiedot.voimaantulopaiva.A"),
    changeObjects.paatoksentiedot || []
  );
  objectToSave.voimassaalkupvm =
    voimaantulopaivaObj && voimaantulopaivaObj.properties.value
      ? moment(voimaantulopaivaObj.properties.value).format("YYYY-MM-DD")
      : "";
  const paattymispaivamaaraObj = find(
    propEq("anchor", "paatoksentiedot.paattymispaivamaara.A"),
    changeObjects.paatoksentiedot || []
  );
  objectToSave.voimassaloppupvm =
    paattymispaivamaaraObj && paattymispaivamaaraObj.properties.value
      ? moment(paattymispaivamaaraObj.properties.value).format("YYYY-MM-DD")
      : "";

  // This helps the frontend to initialize the first four fields on form load.
  objectToSave.meta.paatoksentiedot = changeObjects.paatoksentiedot;

  return objectToSave;
}
