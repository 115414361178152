import {
  useChangeObjects,
  useChangeObjectsByAnchorWithoutUnderRemoval
} from "stores/muutokset";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { API_BASE_URL } from "modules/constants";
import { backendRoutes } from "stores/utils/backendRoutes";
import { getSavedChangeObjects } from "utils/frontendMuutoksetMuutospyynnolla";
import { createMuutospyyntoOutput } from "utils/muutospyyntoUtil";
import { createObjectToSave } from "./saving";
import { prop, propEq, toUpper, find } from "ramda";
import { localizeRouteKey } from "utils/common";
import { AppRoute } from "routes/index";
import { FIELDS } from "modules/constants";
import { getUrlOnClose } from "components/03-templates/Wizard/wizardUtils";
import { PropTypes } from "prop-types";
import { getLisaksiNaytettavatKentat } from "helpers/vstAsianumero";
import { resolveLocalizedOrganizationName } from "modules/helpers";
import { PaikallisenTietovarastonAvain } from "../../../enums";
import { createMunicipalityLocationDescriptionForVST } from "../VapaaSivistystyo/utils/lupaParser";

import ProcedureHandler from "components/02-organisms/procedureHandler";
import Wizard from "components/03-templates/Wizard/index";
import wizard from "i18n/definitions/wizard";
import LupanakymaA from "./lupanakymat/LupanakymaA";
import localForage from "localforage";

/**
 * Container component of UusiaAsiaDialog.
 *
 * @param {Object} props - Props object.
 */
const WizardContainer = ({
  kohteet,
  koulutusmuoto,
  maaraystyypit,
  organisaatio,
  role,
  viimeisinLupa
}) => {
  let history = useHistory();
  const intl = useIntl();
  const { formatMessage, locale } = intl;
  const { id, muutospyyntoUuid, oppilaitostyyppi } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [muutospyynnonTila, setMuutospyynnonTila] = useState();
  const [{ isPreviewModeOn }, { initializeChanges, setPreviewMode }] =
    useChangeObjects();
  const [muutospyynto, setMuutospyynto] = useState();
  const [savedOppilaitostyyppi, setSavedOppilaitostyyppi] = useState();

  const [erityinenKoulutustehtavaCO] =
    useChangeObjectsByAnchorWithoutUnderRemoval({
      anchor: "erityinenKoulutustehtava"
    });
  const [paatoksentiedotCo] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "paatoksentiedot"
  });
  const [toimintaalueCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "toimintaalue"
  });
  const [muutKunnatJoistaOppilaitaCo] =
    useChangeObjectsByAnchorWithoutUnderRemoval({
      anchor: "muutKunnatJoistaOppilaita"
    });
  const [opetuskieletCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "opetuskielet"
  });
  const [oppilaitoksenAlueellisuusJaValtakunnallisuusCO] =
    useChangeObjectsByAnchorWithoutUnderRemoval({
      anchor: "oppilaitoksenAlueellisuusJaValtakunnallisuus"
    });
  const [oppilaitoksenTarkoitusCO] =
    useChangeObjectsByAnchorWithoutUnderRemoval({
      anchor: "oppilaitoksenTarkoitus"
    });
  const [oppilaitoksenKoulutustehtavaCO] =
    useChangeObjectsByAnchorWithoutUnderRemoval({
      anchor: "oppilaitoksenKoulutustehtava"
    });
  const [oppilaitoksetCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "oppilaitokset"
  });
  const [muutEhdotCo] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "muutEhdot"
  });
  const [rajoitteetCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "rajoitteet"
  });
  const [rajoitepoistotCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "rajoitepoistot"
  });

  useEffect(() => {
    if (muutospyynto) {
      const changeObjectsFromBackend = getSavedChangeObjects(muutospyynto);
      initializeChanges(changeObjectsFromBackend);
      setMuutospyynnonTila(prop("tila", muutospyynto));
      setSavedOppilaitostyyppi(prop("oppilaitostyyppi", muutospyynto));
    }
  }, [muutospyynto, initializeChanges]);

  useEffect(() => {
    async function fetchMuutospyynto() {
      const response = await fetch(
        `${API_BASE_URL}/${backendRoutes.muutospyynto.path}${muutospyyntoUuid}`
      );
      if (response && response.ok) {
        setMuutospyynto(await response.json());
      }
      return muutospyynto;
    }

    if (!muutospyynto && muutospyyntoUuid) {
      fetchMuutospyynto();
    }
  }, [muutospyynto, muutospyyntoUuid]);

  const [lisaksi, setLisaksi] = useState({});
  useEffect(() => {
    (async function () {
      // get yllapitaja and oppilaitos from localforage if exists
      // this is only used when creating new permits
      let [yllapitaja, oppilaitos] = await getLisaksiNaytettavatKentat();

      if (muutospyynto) {
        const oppilaitosFind = find(
          propEq("koodisto", "oppilaitos"),
          muutospyynto.muutokset
        );
        if (oppilaitosFind && oppilaitosFind.orgOid) {
          const oppilaitokset = await localForage.getItem(
            PaikallisenTietovarastonAvain.OPPILAITOKSET_BY_OID
          );
          oppilaitos = find(
            propEq("oid", oppilaitosFind.orgOid),
            oppilaitokset
          );
        }
      }

      if (oppilaitos) {
        yllapitaja.value = `${resolveLocalizedOrganizationName(
          yllapitaja,
          locale
        )}, ${yllapitaja.postiosoite.postitoimipaikka}`;
        const response = await fetch(
          `${API_BASE_URL}/${backendRoutes.organisaatio.path}${oppilaitos.oid}`
        );
        let oppilaitosOrganisaatio = null;
        if (response && response.ok) {
          oppilaitosOrganisaatio = await response.json();
        }

        oppilaitos.value = createMunicipalityLocationDescriptionForVST(
          oppilaitosOrganisaatio,
          locale
        );
      }
      setLisaksi({
        yllapitaja,
        oppilaitos
      });
    })();
  }, [muutospyynnonTila, locale, muutospyynto, muutospyyntoUuid]);

  const steps = null;

  const title =
    muutospyynnonTila === FIELDS.TILA.VALUES.KORJAUKSESSA
      ? formatMessage(wizard.luvanKorjaustilaJarjestamisluvanMuutos)
      : formatMessage(wizard.esittelijatMuutospyyntoDialogTitle);

  const onNewDocSave = useCallback(
    muutospyyntoUuid => {
      /**
       * User is redirected to the url of the saved document.
       */
      const url = localizeRouteKey(locale, AppRoute.Hakemus, intl, {
        id,
        koulutusmuoto: koulutusmuoto.kebabCase,
        language: "fi",
        page: 1,
        muutospyyntoUuid
      });
      history.push(url);
    },
    [formatMessage, history, id, koulutusmuoto.kebabCase, locale]
  );

  /**
   * Opens the preview.
   * @param {object} formData
   */
  const onPreview = useCallback(async () => {
    return setPreviewMode(!isPreviewModeOn);
  }, [isPreviewModeOn, setPreviewMode]);

  /**
   * Saves the form.
   * @param {object} formData
   * @returns {object} - Muutospyyntö
   */
  const onSave = useCallback(
    async formData => {
      setIsSaving(true);
      const procedureHandler = new ProcedureHandler(formatMessage);
      const outputs = await procedureHandler.run(
        "muutospyynto.tallennus.tallennaEsittelijanToimesta",
        [formData]
      );
      setIsSaving(false);
      return outputs.muutospyynto.tallennus.tallennaEsittelijanToimesta.output
        .result;
    },
    [formatMessage]
  );

  const onAction = useCallback(
    async (action, fromDialog = false, muutospyynnonTila) => {
      const formData = createMuutospyyntoOutput(
        await createObjectToSave(
          toUpper(locale),
          organisaatio,
          viimeisinLupa || {},
          {
            erityinenKoulutustehtava: erityinenKoulutustehtavaCO,
            muutEhdot: muutEhdotCo,
            muutKunnatJoistaOppilaita: muutKunnatJoistaOppilaitaCo,
            opetuskielet: opetuskieletCO,
            oppilaitoksenAlueellisuusJaValtakunnallisuus:
              oppilaitoksenAlueellisuusJaValtakunnallisuusCO,
            oppilaitoksenTarkoitus: oppilaitoksenTarkoitusCO,
            oppilaitoksenKoulutustehtava: oppilaitoksenKoulutustehtavaCO,
            oppilaitokset: oppilaitoksetCO,
            paatoksentiedot: paatoksentiedotCo,
            rajoitepoistot: rajoitepoistotCO,
            rajoitteet: rajoitteetCO,
            toimintaalue: toimintaalueCO
          },
          muutospyyntoUuid,
          kohteet,
          maaraystyypit,
          "ESITTELIJA",
          muutospyynnonTila,
          lisaksi,
          oppilaitostyyppi || savedOppilaitostyyppi
        )
      );

      let muutospyynto = null;

      if (action === "save") {
        muutospyynto = await onSave(formData);
      } else if (action === "preview") {
        muutospyynto = await onPreview(formData);
      }

      if (action === "save") {
        if (!!muutospyynto && prop("uuid", muutospyynto)) {
          if (!muutospyyntoUuid && !fromDialog) {
            // Jos kyseessä on ensimmäinen tallennus...
            onNewDocSave(muutospyynto.uuid);
          } else {
            /**
             * Kun muutospyyntolomakkeen tilaa muokataan tässä vaiheessa,
             * vältytään tarpeelta tehdä sivun täydellistä uudelleen latausta.
             **/
            const changeObjectsFromBackend =
              getSavedChangeObjects(muutospyynto);
            initializeChanges(changeObjectsFromBackend);
          }
        }
      }
    },
    [
      erityinenKoulutustehtavaCO,
      initializeChanges,
      kohteet,
      locale,
      maaraystyypit,
      muutEhdotCo,
      muutKunnatJoistaOppilaitaCo,
      onNewDocSave,
      onPreview,
      onSave,
      opetuskieletCO,
      oppilaitoksenAlueellisuusJaValtakunnallisuusCO,
      oppilaitoksenTarkoitusCO,
      oppilaitostyyppi,
      oppilaitoksenKoulutustehtavaCO,
      oppilaitoksetCO,
      organisaatio,
      paatoksentiedotCo,
      rajoitteetCO,
      rajoitepoistotCO,
      toimintaalueCO,
      muutospyyntoUuid,
      savedOppilaitostyyppi,
      viimeisinLupa
    ]
  );

  const urlOnClose = getUrlOnClose(
    role,
    locale,
    intl,
    organisaatio,
    koulutusmuoto,
    muutospyyntoUuid
  );

  return lisaksi && (!muutospyyntoUuid || muutospyynto) ? (
    <Wizard
      page1={
        <LupanakymaA
          isPreviewModeOn={false}
          koulutustyyppi={koulutusmuoto.koulutustyyppi}
          lisaksi={lisaksi}
          maaraykset={viimeisinLupa ? viimeisinLupa.maaraykset : []}
          oppilaitostyyppi={oppilaitostyyppi || savedOppilaitostyyppi}
        />
      }
      isSaving={isSaving}
      koulutusmuoto={koulutusmuoto}
      onAction={onAction}
      organisation={organisaatio}
      steps={steps}
      tila={muutospyynnonTila}
      title={title}
      urlOnClose={urlOnClose}
    />
  ) : null;
};

WizardContainer.propTypes = {
  kohteet: PropTypes.array,
  koulutusmuoto: PropTypes.object,
  maaraystyypit: PropTypes.array,
  organisaatio: PropTypes.object,
  role: PropTypes.string,
  viimeisinLupa: PropTypes.object
};

export default WizardContainer;
