import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { localizeRouteKey } from "utils/common";
import { AppRoute } from "routes";
import { ReactElement } from "react";
import tilastot from "../i18n/definitions/tilastot";

export default function TilastotCard(): ReactElement {
  const intl = useIntl();
  const { formatMessage, locale } = intl;
  const history = useHistory();

  return (
    <Card>
      <CardActionArea
        onClick={() => {
          history.push(localizeRouteKey(locale, AppRoute.Tilastot, intl));
        }}>
        <CardContent>
          <Typography gutterBottom component="p">
            {formatMessage(tilastot.lyhytKuvaus)}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
