export default [
  {
    kuntaKoodiarvo: "035",
    maakuntaKey: "FI-01"
  },
  {
    kuntaKoodiarvo: "043",
    maakuntaKey: "FI-01"
  },
  {
    kuntaKoodiarvo: "060",
    maakuntaKey: "FI-01"
  },
  {
    kuntaKoodiarvo: "062",
    maakuntaKey: "FI-01"
  },
  {
    kuntaKoodiarvo: "065",
    maakuntaKey: "FI-01"
  },
  {
    kuntaKoodiarvo: "076",
    maakuntaKey: "FI-01"
  },
  {
    kuntaKoodiarvo: "170",
    maakuntaKey: "FI-01"
  },
  {
    kuntaKoodiarvo: "295",
    maakuntaKey: "FI-01"
  },
  {
    kuntaKoodiarvo: "318",
    maakuntaKey: "FI-01"
  },
  {
    kuntaKoodiarvo: "417",
    maakuntaKey: "FI-01"
  },
  {
    kuntaKoodiarvo: "438",
    maakuntaKey: "FI-01"
  },
  {
    kuntaKoodiarvo: "478",
    maakuntaKey: "FI-01"
  },
  {
    kuntaKoodiarvo: "736",
    maakuntaKey: "FI-01"
  },
  {
    kuntaKoodiarvo: "766",
    maakuntaKey: "FI-01"
  },
  {
    kuntaKoodiarvo: "771",
    maakuntaKey: "FI-01"
  },
  {
    kuntaKoodiarvo: "941",
    maakuntaKey: "FI-01"
  },
  {
    kuntaKoodiarvo: "153",
    maakuntaKey: "FI-02"
  },
  {
    kuntaKoodiarvo: "173",
    maakuntaKey: "FI-02"
  },
  {
    kuntaKoodiarvo: "405",
    maakuntaKey: "FI-02"
  },
  {
    kuntaKoodiarvo: "416",
    maakuntaKey: "FI-02"
  },
  {
    kuntaKoodiarvo: "441",
    maakuntaKey: "FI-02"
  },
  {
    kuntaKoodiarvo: "580",
    maakuntaKey: "FI-02"
  },
  {
    kuntaKoodiarvo: "689",
    maakuntaKey: "FI-02"
  },
  {
    kuntaKoodiarvo: "700",
    maakuntaKey: "FI-02"
  },
  {
    kuntaKoodiarvo: "728",
    maakuntaKey: "FI-02"
  },
  {
    kuntaKoodiarvo: "739",
    maakuntaKey: "FI-02"
  },
  {
    kuntaKoodiarvo: "775",
    maakuntaKey: "FI-02"
  },
  {
    kuntaKoodiarvo: "831",
    maakuntaKey: "FI-02"
  },
  {
    kuntaKoodiarvo: "891",
    maakuntaKey: "FI-02"
  },
  {
    kuntaKoodiarvo: "978",
    maakuntaKey: "FI-02"
  },
  {
    kuntaKoodiarvo: "004",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "005",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "010",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "052",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "145",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "151",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "164",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "175",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "218",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "232",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "233",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "281",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "300",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "301",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "403",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "408",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "414",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "544",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "589",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "743",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "759",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "846",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "863",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "934",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "971",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "975",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "989",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "014",
    maakuntaKey: "FI-04"
  },
  {
    kuntaKoodiarvo: "046",
    maakuntaKey: "FI-04"
  },
  {
    kuntaKoodiarvo: "085",
    maakuntaKey: "FI-04"
  },
  {
    kuntaKoodiarvo: "090",
    maakuntaKey: "FI-13"
  },
  {
    kuntaKoodiarvo: "097",
    maakuntaKey: "FI-04"
  },
  {
    kuntaKoodiarvo: "171",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "178",
    maakuntaKey: "FI-04"
  },
  {
    kuntaKoodiarvo: "184",
    maakuntaKey: "FI-04"
  },
  {
    kuntaKoodiarvo: "213",
    maakuntaKey: "FI-04"
  },
  {
    kuntaKoodiarvo: "246",
    maakuntaKey: "FI-04"
  },
  {
    kuntaKoodiarvo: "491",
    maakuntaKey: "FI-04"
  },
  {
    kuntaKoodiarvo: "492",
    maakuntaKey: "FI-04"
  },
  {
    kuntaKoodiarvo: "507",
    maakuntaKey: "FI-04"
  },
  {
    kuntaKoodiarvo: "588",
    maakuntaKey: "FI-04"
  },
  {
    kuntaKoodiarvo: "593",
    maakuntaKey: "FI-04"
  },
  {
    kuntaKoodiarvo: "594",
    maakuntaKey: "FI-04"
  },
  {
    kuntaKoodiarvo: "640",
    maakuntaKey: "FI-04"
  },
  {
    kuntaKoodiarvo: "618",
    maakuntaKey: "FI-04"
  },
  {
    kuntaKoodiarvo: "623",
    maakuntaKey: "FI-04"
  },
  {
    kuntaKoodiarvo: "681",
    maakuntaKey: "FI-04"
  },
  {
    kuntaKoodiarvo: "696",
    maakuntaKey: "FI-04"
  },
  {
    kuntaKoodiarvo: "740",
    maakuntaKey: "FI-04"
  },
  {
    kuntaKoodiarvo: "741",
    maakuntaKey: "FI-04"
  },
  {
    kuntaKoodiarvo: "768",
    maakuntaKey: "FI-04"
  },
  {
    kuntaKoodiarvo: "937",
    maakuntaKey: "FI-04"
  },
  {
    kuntaKoodiarvo: "105",
    maakuntaKey: "FI-05"
  },
  {
    kuntaKoodiarvo: "205",
    maakuntaKey: "FI-05"
  },
  {
    kuntaKoodiarvo: "290",
    maakuntaKey: "FI-05"
  },
  {
    kuntaKoodiarvo: "578",
    maakuntaKey: "FI-05"
  },
  {
    kuntaKoodiarvo: "620",
    maakuntaKey: "FI-05"
  },
  {
    kuntaKoodiarvo: "697",
    maakuntaKey: "FI-05"
  },
  {
    kuntaKoodiarvo: "765",
    maakuntaKey: "FI-05"
  },
  {
    kuntaKoodiarvo: "777",
    maakuntaKey: "FI-05"
  },
  {
    kuntaKoodiarvo: "940",
    maakuntaKey: "FI-05"
  },
  {
    kuntaKoodiarvo: "061",
    maakuntaKey: "FI-06"
  },
  {
    kuntaKoodiarvo: "082",
    maakuntaKey: "FI-06"
  },
  {
    kuntaKoodiarvo: "083",
    maakuntaKey: "FI-06"
  },
  {
    kuntaKoodiarvo: "086",
    maakuntaKey: "FI-06"
  },
  {
    kuntaKoodiarvo: "103",
    maakuntaKey: "FI-06"
  },
  {
    kuntaKoodiarvo: "109",
    maakuntaKey: "FI-06"
  },
  {
    kuntaKoodiarvo: "165",
    maakuntaKey: "FI-06"
  },
  {
    kuntaKoodiarvo: "169",
    maakuntaKey: "FI-06"
  },
  {
    kuntaKoodiarvo: "210",
    maakuntaKey: "FI-06"
  },
  {
    kuntaKoodiarvo: "401",
    maakuntaKey: "FI-06"
  },
  {
    kuntaKoodiarvo: "433",
    maakuntaKey: "FI-06"
  },
  {
    kuntaKoodiarvo: "692",
    maakuntaKey: "FI-06"
  },
  {
    kuntaKoodiarvo: "694",
    maakuntaKey: "FI-06"
  },
  {
    kuntaKoodiarvo: "834",
    maakuntaKey: "FI-06"
  },
  {
    kuntaKoodiarvo: "855",
    maakuntaKey: "FI-06"
  },
  {
    kuntaKoodiarvo: "981",
    maakuntaKey: "FI-06"
  },
  {
    kuntaKoodiarvo: "074",
    maakuntaKey: "FI-07"
  },
  {
    kuntaKoodiarvo: "217",
    maakuntaKey: "FI-07"
  },
  {
    kuntaKoodiarvo: "236",
    maakuntaKey: "FI-07"
  },
  {
    kuntaKoodiarvo: "272",
    maakuntaKey: "FI-07"
  },
  {
    kuntaKoodiarvo: "315",
    maakuntaKey: "FI-07"
  },
  {
    kuntaKoodiarvo: "421",
    maakuntaKey: "FI-07"
  },
  {
    kuntaKoodiarvo: "429",
    maakuntaKey: "FI-07"
  },
  {
    kuntaKoodiarvo: "584",
    maakuntaKey: "FI-07"
  },
  {
    kuntaKoodiarvo: "849",
    maakuntaKey: "FI-07"
  },
  {
    kuntaKoodiarvo: "885",
    maakuntaKey: "FI-07"
  },
  {
    kuntaKoodiarvo: "924",
    maakuntaKey: "FI-07"
  },
  {
    kuntaKoodiarvo: "077",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "172",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "179",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "180",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "182",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "183",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "216",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "226",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "249",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "256",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "265",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "275",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "277",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "291",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "312",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "410",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "415",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "435",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "495",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "500",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "592",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "601",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "633",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "729",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "770",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "774",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "850",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "892",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "931",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "992",
    maakuntaKey: "FI-08"
  },
  {
    kuntaKoodiarvo: "754",
    maakuntaKey: "FI-09"
  },
  {
    kuntaKoodiarvo: "044",
    maakuntaKey: "FI-09"
  },
  {
    kuntaKoodiarvo: "075",
    maakuntaKey: "FI-09"
  },
  {
    kuntaKoodiarvo: "142",
    maakuntaKey: "FI-16"
  },
  {
    kuntaKoodiarvo: "163",
    maakuntaKey: "FI-09"
  },
  {
    kuntaKoodiarvo: "285",
    maakuntaKey: "FI-09"
  },
  {
    kuntaKoodiarvo: "286",
    maakuntaKey: "FI-09"
  },
  {
    kuntaKoodiarvo: "306",
    maakuntaKey: "FI-09"
  },
  {
    kuntaKoodiarvo: "489",
    maakuntaKey: "FI-09"
  },
  {
    kuntaKoodiarvo: "624",
    maakuntaKey: "FI-09"
  },
  {
    kuntaKoodiarvo: "909",
    maakuntaKey: "FI-09"
  },
  {
    kuntaKoodiarvo: "917",
    maakuntaKey: "FI-09"
  },
  {
    kuntaKoodiarvo: "935",
    maakuntaKey: "FI-09"
  },
  {
    kuntaKoodiarvo: "047",
    maakuntaKey: "FI-10"
  },
  {
    kuntaKoodiarvo: "148",
    maakuntaKey: "FI-10"
  },
  {
    kuntaKoodiarvo: "240",
    maakuntaKey: "FI-10"
  },
  {
    kuntaKoodiarvo: "320",
    maakuntaKey: "FI-10"
  },
  {
    kuntaKoodiarvo: "241",
    maakuntaKey: "FI-10"
  },
  {
    kuntaKoodiarvo: "261",
    maakuntaKey: "FI-10"
  },
  {
    kuntaKoodiarvo: "273",
    maakuntaKey: "FI-10"
  },
  {
    kuntaKoodiarvo: "498",
    maakuntaKey: "FI-10"
  },
  {
    kuntaKoodiarvo: "583",
    maakuntaKey: "FI-10"
  },
  {
    kuntaKoodiarvo: "854",
    maakuntaKey: "FI-10"
  },
  {
    kuntaKoodiarvo: "614",
    maakuntaKey: "FI-10"
  },
  {
    kuntaKoodiarvo: "683",
    maakuntaKey: "FI-10"
  },
  {
    kuntaKoodiarvo: "698",
    maakuntaKey: "FI-10"
  },
  {
    kuntaKoodiarvo: "699",
    maakuntaKey: "FI-10"
  },
  {
    kuntaKoodiarvo: "732",
    maakuntaKey: "FI-10"
  },
  {
    kuntaKoodiarvo: "742",
    maakuntaKey: "FI-10"
  },
  {
    kuntaKoodiarvo: "751",
    maakuntaKey: "FI-10"
  },
  {
    kuntaKoodiarvo: "758",
    maakuntaKey: "FI-10"
  },
  {
    kuntaKoodiarvo: "845",
    maakuntaKey: "FI-10"
  },
  {
    kuntaKoodiarvo: "851",
    maakuntaKey: "FI-10"
  },
  {
    kuntaKoodiarvo: "890",
    maakuntaKey: "FI-10"
  },
  {
    kuntaKoodiarvo: "976",
    maakuntaKey: "FI-10"
  },
  {
    kuntaKoodiarvo: "020",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "108",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "143",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "177",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "211",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "250",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "289",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "299",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "303",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "310",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "418",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "439",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "443",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "493",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "506",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "508",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "536",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "562",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "581",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "604",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "994",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "619",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "635",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "702",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "730",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "790",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "772",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "837",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "864",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "887",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "908",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "912",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "922",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "928",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "932",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "933",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "936",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "980",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "988",
    maakuntaKey: "FI-11"
  },
  {
    kuntaKoodiarvo: "152",
    maakuntaKey: "FI-03"
  },
  {
    kuntaKoodiarvo: "231",
    maakuntaKey: "FI-12"
  },
  {
    kuntaKoodiarvo: "280",
    maakuntaKey: "FI-12"
  },
  {
    kuntaKoodiarvo: "287",
    maakuntaKey: "FI-12"
  },
  {
    kuntaKoodiarvo: "288",
    maakuntaKey: "FI-12"
  },
  {
    kuntaKoodiarvo: "399",
    maakuntaKey: "FI-12"
  },
  {
    kuntaKoodiarvo: "440",
    maakuntaKey: "FI-12"
  },
  {
    kuntaKoodiarvo: "475",
    maakuntaKey: "FI-12"
  },
  {
    kuntaKoodiarvo: "479",
    maakuntaKey: "FI-12"
  },
  {
    kuntaKoodiarvo: "499",
    maakuntaKey: "FI-12"
  },
  {
    kuntaKoodiarvo: "545",
    maakuntaKey: "FI-12"
  },
  {
    kuntaKoodiarvo: "559",
    maakuntaKey: "FI-12"
  },
  {
    kuntaKoodiarvo: "599",
    maakuntaKey: "FI-12"
  },
  {
    kuntaKoodiarvo: "598",
    maakuntaKey: "FI-12"
  },
  {
    kuntaKoodiarvo: "893",
    maakuntaKey: "FI-12"
  },
  {
    kuntaKoodiarvo: "905",
    maakuntaKey: "FI-12"
  },
  {
    kuntaKoodiarvo: "942",
    maakuntaKey: "FI-12"
  },
  {
    kuntaKoodiarvo: "946",
    maakuntaKey: "FI-12"
  },
  {
    kuntaKoodiarvo: "944",
    maakuntaKey: "FI-12"
  },
  {
    kuntaKoodiarvo: "945",
    maakuntaKey: "FI-12"
  },
  {
    kuntaKoodiarvo: "045",
    maakuntaKey: "FI-13"
  },
  {
    kuntaKoodiarvo: "146",
    maakuntaKey: "FI-13"
  },
  {
    kuntaKoodiarvo: "167",
    maakuntaKey: "FI-13"
  },
  {
    kuntaKoodiarvo: "176",
    maakuntaKey: "FI-13"
  },
  {
    kuntaKoodiarvo: "248",
    maakuntaKey: "FI-13"
  },
  {
    kuntaKoodiarvo: "251",
    maakuntaKey: "FI-13"
  },
  {
    kuntaKoodiarvo: "260",
    maakuntaKey: "FI-13"
  },
  {
    kuntaKoodiarvo: "276",
    maakuntaKey: "FI-13"
  },
  {
    kuntaKoodiarvo: "422",
    maakuntaKey: "FI-13"
  },
  {
    kuntaKoodiarvo: "426",
    maakuntaKey: "FI-13"
  },
  {
    kuntaKoodiarvo: "541",
    maakuntaKey: "FI-13"
  },
  {
    kuntaKoodiarvo: "309",
    maakuntaKey: "FI-13"
  },
  {
    kuntaKoodiarvo: "607",
    maakuntaKey: "FI-13"
  },
  {
    kuntaKoodiarvo: "632",
    maakuntaKey: "FI-13"
  },
  {
    kuntaKoodiarvo: "707",
    maakuntaKey: "FI-13"
  },
  {
    kuntaKoodiarvo: "848",
    maakuntaKey: "FI-13"
  },
  {
    kuntaKoodiarvo: "856",
    maakuntaKey: "FI-13"
  },
  {
    kuntaKoodiarvo: "911",
    maakuntaKey: "FI-13"
  },
  {
    kuntaKoodiarvo: "943",
    maakuntaKey: "FI-13"
  },
  {
    kuntaKoodiarvo: "009",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "069",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "071",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "072",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "084",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "095",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "139",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "208",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "244",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "247",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "255",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "292",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "305",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "317",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "425",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "436",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "483",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "494",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "535",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "563",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "564",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "567",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "582",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "603",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "615",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "617",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "625",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "626",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "630",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "678",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "682",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "691",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "708",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "746",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "748",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "791",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "832",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "841",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "859",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "889",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "785",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "926",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "972",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "973",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "977",
    maakuntaKey: "FI-14"
  },
  {
    kuntaKoodiarvo: "140",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "174",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "204",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "212",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "227",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "239",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "263",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "297",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "402",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "420",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "476",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "534",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "595",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "686",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "687",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "749",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "762",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "778",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "844",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "857",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "915",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "916",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "919",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "921",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "925",
    maakuntaKey: "FI-15"
  },
  {
    kuntaKoodiarvo: "015",
    maakuntaKey: "FI-16"
  },
  {
    kuntaKoodiarvo: "016",
    maakuntaKey: "FI-16"
  },
  {
    kuntaKoodiarvo: "081",
    maakuntaKey: "FI-16"
  },
  {
    kuntaKoodiarvo: "111",
    maakuntaKey: "FI-16"
  },
  {
    kuntaKoodiarvo: "088",
    maakuntaKey: "FI-16"
  },
  {
    kuntaKoodiarvo: "089",
    maakuntaKey: "FI-16"
  },
  {
    kuntaKoodiarvo: "098",
    maakuntaKey: "FI-16"
  },
  {
    kuntaKoodiarvo: "283",
    maakuntaKey: "FI-16"
  },
  {
    kuntaKoodiarvo: "316",
    maakuntaKey: "FI-16"
  },
  {
    kuntaKoodiarvo: "398",
    maakuntaKey: "FI-16"
  },
  {
    kuntaKoodiarvo: "532",
    maakuntaKey: "FI-16"
  },
  {
    kuntaKoodiarvo: "560",
    maakuntaKey: "FI-16"
  },
  {
    kuntaKoodiarvo: "576",
    maakuntaKey: "FI-16"
  },
  {
    kuntaKoodiarvo: "781",
    maakuntaKey: "FI-16"
  },
  {
    kuntaKoodiarvo: "001",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "050",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "051",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "079",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "099",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "102",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "181",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "214",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "230",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "254",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "262",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "266",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "271",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "293",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "319",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "406",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "413",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "442",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "484",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "531",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "537",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "608",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "609",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "684",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "747",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "783",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "886",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "913",
    maakuntaKey: "FI-17"
  },
  {
    kuntaKoodiarvo: "018",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "049",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "078",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "091",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "106",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "149",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "186",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "220",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "223",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "224",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "235",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "245",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "257",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "407",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "424",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "427",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "444",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "428",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "434",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "504",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "505",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "540",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "543",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "585",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "606",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "611",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "638",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "612",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "613",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "616",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "710",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "701",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "737",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "753",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "755",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "835",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "858",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "200",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "092",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "927",
    maakuntaKey: "FI-18"
  },
  {
    kuntaKoodiarvo: "006",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "017",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "019",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "040",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "073",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "101",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "150",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "202",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "219",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "243",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "322",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "252",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "259",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "279",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "284",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "304",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "308",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "400",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "419",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "423",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "430",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "431",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "480",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "481",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "482",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "485",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "490",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "501",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "503",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "529",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "533",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "538",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "561",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "577",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "573",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "445",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "586",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "587",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "602",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "631",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "636",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "680",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "704",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "705",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "734",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "738",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "761",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "776",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "784",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "833",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "838",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "853",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "895",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "906",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "918",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "920",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "923",
    maakuntaKey: "FI-19"
  },
  {
    kuntaKoodiarvo: "979",
    maakuntaKey: "FI-19"
  }
];
