import { Fragment, ReactElement } from "react";
import { useIntl } from "react-intl";
import Typography from "@mui/material/Typography";
import education from "i18n/definitions/education";
import tuva from "i18n/definitions/tuva";

export default function TuvaKoulutusmuoto({
  parentKoulutusmuoto
}: {
  parentKoulutusmuoto: any;
}): ReactElement {
  const { formatMessage } = useIntl();

  return (
    <Fragment>
      <Typography component="h3" variant="h3">
        {formatMessage(education.koulutusmuoto)}
      </Typography>
      <p>
        {formatMessage(tuva.koulutusmuotoJonkaYhteyteen, {
          koulutusmuoto: parentKoulutusmuoto.paasivunOtsikko
        })}
      </p>
    </Fragment>
  );
}
