import { filter } from "ramda";
import { isNodeChecked } from "./nodePropertiesCalculation";
import { getChildNodes } from "./getChildNodes";
import { ComponentWithMetadata } from "utils/lomakkeet";
import { ChangeObjects } from "utils/muutokset";

/**
 * Returns true if every child node is unchecked.
 * @param {object} node - Target node.
 * @param {array} reducedStructure - One dimensional presentation of the fields of the current form.
 * @param {array} changeObjects - Array of change objects.
 */
export function isEveryChildNodeUnchecked(
  node: ComponentWithMetadata,
  ComponentsWithMetadata: Array<ComponentWithMetadata>,
  changeObjects: ChangeObjects = []
): boolean {
  const childNodes = getChildNodes(node, ComponentsWithMetadata);
  const checkedChildNodes = filter(childNode => {
    return isNodeChecked(childNode, changeObjects);
  }, childNodes);
  return checkedChildNodes.length === 0;
}
