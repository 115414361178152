import { ReactElement, useEffect } from "react";
import { IProcessCollection } from "graphHandling/graphProcessHandling";
import { renderAutocomplete } from "graphHandling/components/autocomplete/rendering";
import GroupedChipCollections from "graphHandling/components/GroupedChipCollections";
import { CellId } from "processes/CellId";
import { graphDefinition } from "graphs/toiminta-alue/maakunnatJaKunnat/processDefinition";
import { cellImplementations } from "graphs/toiminta-alue/maakunnatJaKunnat/processDefinition";
import { useProcess_maakunnatJaKunnat } from "graphs/storeHandling";
import SimpleListFromGroupedChipCollections from "graphHandling/components/SimpleListFromGroupedChipCollections";
import { ChangeObjects } from "utils/muutokset";
import { useChangeObjectsByAnchorWithoutUnderRemoval } from "stores/muutokset";
import { map, path } from "ramda";

type ReceivedProps = {
  isPreviewModeOn: boolean;
  pc: IProcessCollection;
  rajoitteet?: Record<string, { changeObjects: ChangeObjects }>;
};

const MaakunnatJaKunnat = (props: ReceivedProps): null | ReactElement => {
  const { pc } = props;
  const [, actions] = useProcess_maakunnatJaKunnat();
  const [changeObjects] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "rajoitepoistot"
  });

  if (!pc) {
    return null;
  }

  useEffect(() => {
    if (props.pc) {
      props.pc.addProcess(
        {
          actions,
          CellId: CellId,
          cellImplementations: cellImplementations,
          processDefinition: graphDefinition
        },
        "maakunnatJaKunnat"
      );

      props.pc.handleIncomingProcessToken(
        CellId.INITIALIZE_GRAPH,
        "maakunnatJaKunnat"
      );
    }
  }, []);

  const process = pc.getProcess("maakunnatJaKunnat");

  useEffect(() => {
    if (process) {
      process.actions.updateGraph(["custom"], {
        idsOfRemovedRestrictions: map(
          path(["properties", "rajoiteId"]),
          changeObjects
        )
      });
    }
    pc.handleIncomingProcessToken(
      CellId.INITIALIZE_PROCESS_MAAKUNNAT_JA_KUNNAT,
      "maakunnatJaKunnat"
    );
  }, [changeObjects]);

  if (!process) {
    return null;
  }
  const components = process.actions.readPath(["components"]);

  const luvanRajoitteet = process.actions.readPath(["custom", "restrictions"]);

  if (!components) {
    return null;
  }

  if (props.isPreviewModeOn) {
    return (
      <SimpleListFromGroupedChipCollections
        gChipCs={components[CellId.INITIALIZE_GROUPED_CHIP_COLLECTIONS]}
        luvanRajoitteet={luvanRajoitteet}
        rajoitteet={props.rajoitteet}
      />
    );
  } else if (
    components[CellId.INITIALIZE_AUTOCOMPLETE] &&
    components[CellId.INITIALIZE_GROUPED_CHIP_COLLECTIONS]
  ) {
    return (
      <div className="pt-4 pl-8 pr-16">
        {renderAutocomplete(components[CellId.INITIALIZE_AUTOCOMPLETE])}
        <div className="mt-12">
          <GroupedChipCollections
            gChipCs={components[CellId.INITIALIZE_GROUPED_CHIP_COLLECTIONS]}
          />
        </div>
      </div>
    );
  }

  return null;
};

export default MaakunnatJaKunnat;
