import { filter } from "ramda";
import { isNodeChecked } from "./nodePropertiesCalculation";
import { getChildNodes } from "./getChildNodes";
import { ComponentWithMetadata } from "utils/lomakkeet";
import { ChangeObjects } from "utils/muutokset";

/**
 * Palauttaa arvon true, jos jokainen komponentin lapsikomponenteista on ruksattu.
 * @param componentWithMetadata Metadatalla höystetty komponentin kuvaus.
 * @param reducedStructure Taulukollinen metadatalla höystettyjä komponenttimäärityksiä.
 * @param changeObjects Taulukollinen muutosobjekteja.
 */
export function isEveryChildNodeChecked(
  componentWithMetadata: ComponentWithMetadata,
  componentsWithMetadata: Array<ComponentWithMetadata>,
  changeObjects: ChangeObjects
): boolean {
  const childNodes = getChildNodes(
    componentWithMetadata,
    componentsWithMetadata
  );
  const checkedChildNodes = filter(childNode => {
    return isNodeChecked(childNode, changeObjects);
  }, childNodes);
  return childNodes.length === checkedChildNodes.length;
}
