import { MEDIA_QUERIES } from "components/02-organisms/Header";
import { localizeRouteKey } from "utils/common";
import { AppLanguage, AppRoute } from "routes";
import { useMediaQuery } from "@mui/material";
import { NavLink } from "react-router-dom";
import { PropTypes } from "prop-types";
import { useIntl } from "react-intl";

export const LanguageSwitcher = ({ ulClasses = "" }) => {
  const intl = useIntl();
  const { locale } = intl;
  const langMap = {
    Finnish: "fi",
    Swedish: "sv"
  };

  const baseUlClasses = "flex ml-5 pl-5";
  const allUlClasses = `${baseUlClasses} ${ulClasses}`;

  const selectedLangStyles = "border bg-white text-green-500";
  const commonLangStyles =
    "font-medium rounded-full text-sm hover:bg-white hover:text-green-500";

  const breakpointDesktopLargeMin = useMediaQuery(MEDIA_QUERIES.DESKTOP_LARGE);

  return (
    <ul className={allUlClasses}>
      {Object.keys(AppLanguage).map((lang, index) => {
        return (
          <li
            key={lang}
            className={
              index === 0 && !breakpointDesktopLargeMin ? "mr-3" : "mr-1"
            }>
            <NavLink
              to={localizeRouteKey(langMap[lang], AppRoute.Home, intl)}
              className={`${
                langMap[lang] === locale ? selectedLangStyles : "text-white"
              } ${commonLangStyles} uppercase flex justify-center items-center`}
              style={{
                width: "1.625rem",
                height: "1.625rem",
                borderColor: "white"
              }}>
              <span>{langMap[lang]}</span>
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
};

LanguageSwitcher.propTypes = {
  ulClasses: PropTypes.string
};
