import { defineOrganisaatiovalikko } from "graphHandling/components/autocomplete/definitions/organisaatiovalikko";
import { CellFn, GraphStoreFunctions } from "graphHandling/graphTypes";
import { CellId } from "processes/CellId";
import { backendRoutes } from "stores/utils/backendRoutes";
import { IntlShape } from "react-intl";
import { AutocompleteProps } from "components/02-organisms/Autocomplete/ac";
import {
  assocPath,
  head,
  isEmpty,
  join,
  length,
  path,
  split,
  tail,
  trim
} from "ramda";

const currentCellId = CellId.INITIALIZE_ORGANISAATIOVALIKKO;

const handleOnChange =
  (eventName: string) =>
  (
    intl: IntlShape,
    changeProps: Record<string, unknown>,
    storeFunctions: GraphStoreFunctions
  ) => {
    const searchStr = changeProps.searchStr as string;

    // Tarkistetaan onko valitun arvon label-määreessä
    // y-tunnus tai oid. Jos on, poistetaan se jotta
    // tieto ei turhaan näkyisi Autocomplete-kentässä.
    let label = path(["values", 0, "label"], changeProps) as string;
    if (label) {
      const labelParts = split(" ", label);
      const stringToTest = head(labelParts) as string;

      if (isValidOrganisationId(stringToTest) || isValidYTunnus(stringToTest)) {
        label = join(" ", tail(labelParts));
        changeProps = assocPath(["values", 0, "label"], label, changeProps);
      }
    }

    const koulutustyyppi = storeFunctions.readPath([
      "components",
      CellId.INITIALIZE_KOULUTUSMUOTOVALIKKO,
      "properties",
      "value"
    ]);

    const listSourcePath1 = `${backendRoutes.organisaatio.path}${trim(
      searchStr as string
    )}`;

    const listSourcePath2 =
      koulutustyyppi && !isEmpty(koulutustyyppi)
        ? `${backendRoutes.organisaatiot.path}?koulutustyyppi=${koulutustyyppi}`
        : backendRoutes.organisaatiot.path;

    let validOrganisationListSource = { id: 2, path: listSourcePath2 };

    if (eventName === "onInputChange") {
      validOrganisationListSource =
        length(trim(searchStr)) > 3 &&
        (isValidOrganisationId(searchStr) || isValidYTunnus(searchStr))
          ? { id: 1, path: listSourcePath1 }
          : { id: 2, path: listSourcePath2 };
    }

    storeFunctions.updateGraph(
      ["components", currentCellId, "properties"],
      changeProps
    );

    storeFunctions.updateGraph(
      ["custom", "validOrganisationListSource"],
      validOrganisationListSource
    );

    return true;
  };

const handleOnInputChange = handleOnChange;

export const defineCellOrganisaatiovalikko: CellFn = (storeFunctions, intl) => {
  if (!intl) {
    console.warn(
      "Organisaatiovalikkoa ei voida renderöidä, koska parametrejä puuttuu."
    );
    return false;
  }

  return defineOrganisaatiovalikko(
    intl,
    {
      onChange: payload => {
        return handleOnChange("onChange")(
          intl,
          payload.changeProps,
          storeFunctions
        );
      },
      onInputChange: payload => {
        return handleOnInputChange("onInputChange")(
          intl,
          payload.changeProps,
          storeFunctions
        );
      }
    },
    storeFunctions.readPath([
      "components",
      currentCellId,
      "properties"
    ]) as AutocompleteProps
  );
};

export function isValidOrganisationId(oidCandidateStr: string): boolean {
  return new RegExp(/^(\d{1}\.){2}(\d{2,3}\.){3}\d{11}$/).test(oidCandidateStr);
}

export function isValidYTunnus(yTunnusCandidate: string): boolean {
  return new RegExp(/^\d{7}-\d{1}$/).test(yTunnusCandidate);
}
