import { defineMessages } from "react-intl";

export default defineMessages({
  esittelyteksti: {
    id: "tuva.esittelyteksti",
    defaultMessage:
      "Tälle sivulle on koottu opetuksen järjestäjän järjestämisluvan perustiedot. Jos järjestäjällä on useampia voimassaolevia esi- ja perusopetuksen järjestämislupia, on niiden tiedot yhdistetty tälle sivulle. Järjestäjän luvat löytyvät alkuperäisinä kokonaisuudessaan Päätökset-välilehdeltä."
  },
  esivalinnatLomakeohje: {
    id: "tuva.esivalinnatLomakeohje",
    defaultMessage:
      "Valitse ensin koulutusmuoto. Tämän jälkeen valitse se organisaatio, jonka järjestämislupaa uusi TUVA-lupa koskee. Koulutusmuodon ollessa lukio valitse lopuksi luvan kieli."
  },
  esivalinnatOrganisaatiohaunOhje: {
    id: "tuva.esivalinnatOrganisaatiohaunOhje",
    defaultMessage: "Voit hakea seuraavilla tunnuksilla: Y-tunnus, OID"
  },
  esivalinnatOtsikko1: {
    id: "tuva.esivalinnatOtsikko1",
    defaultMessage: "1. Valitse koulutusmuoto"
  },
  esivalinnatOtsikko2: {
    id: "tuva.esivalinnatOtsikko2",
    defaultMessage: "2. Valitse organisaatio"
  },
  esivalinnatOtsikko3: {
    id: "tuva.esivalinnatOtsikko3",
    defaultMessage: "3. Valitse luvan kieli"
  },
  genetiivi: {
    id: "tuva.genetiivi",
    defaultMessage: "TUVA:n"
  },
  ilmoitusPaaluvanPuuttumisesta: {
    id: "tuva.ilmoitusPaaluvanPuuttumisesta",
    defaultMessage:
      "Tällä koulutuksen järjestäjällä ei ole voimassa olevaa järjestämislupaa, jota TUVA-järjestämisluvan luominen edellyttää."
  },
  ilmoitusPaaluvanPuuttumisestaOtsikko: {
    id: "tuva.ilmoitusPaaluvanPuuttumisestaOtsikko",
    defaultMessage: "Päälupa puuttuu"
  },
  ilmoitusTuvaLuvanOlemassaOlosta: {
    id: "tuva.ilmoitusTuvaLuvanOlemassaOlosta",
    defaultMessage:
      "Voit siirtyä täyttämään asian loput tiedot tai tehdä uudet valinnat yllä olevilla kentillä."
  },
  ilmoitusTuvaLuvanOlemassaOlostaOtsikko: {
    id: "tuva.ilmoitusTuvaLuvanOlemassaOlostaOtsikko",
    defaultMessage: "Löytyi keskeneräinen asia"
  },
  kebabCase: {
    id: "tuva.kebabCase",
    defaultMessage: "tuva"
  },
  koulutuksenJarjestamislupa: {
    id: "tuva.koulutuksenJarjestamislupa",
    defaultMessage: "TUVA-koulutuksen järjestämislupa"
  },
  koulutuksenJarjestamisluvanMuutos: {
    id: "tuva.koulutuksenJarjestamisluvanMuutos",
    defaultMessage: "TUVA-koulutuksen järjestämisluvan muutos"
  },
  kuvausteksti: {
    id: "tuva.kuvausteksti",
    defaultMessage: "[TUVA:n kuvausteksti tähän]"
  },
  lyhytKuvaus: {
    id: "tuva.lyhytKuvaus",
    defaultMessage:
      "Tutkintokoulutukseen valmentavan koulutuksen järjestäminen edellyttää opetus- ja kulttuuriministeriön myöntämää koulutuksen järjestämislupaa. Tästä pääset tarkastelemaan koulutuksen järjestäjiä ja heidän järjestämislupiaan."
  },
  maaratyllaOpetuskielella: {
    id: "tuva.maaratyllaOpetuskielella",
    defaultMessage:
      "Koulutuksen järjestäjän on annettava opetusta {koulutusmuoto} koulutuksen järjestämisluvassa määrätyllä opetuskielellä."
  },
  maaratyllaToimintaalueella: {
    id: "tuva.maaratyllaToimintaalueella",
    defaultMessage:
      "Koulutuksen järjestäjällä on velvollisuus järjestää koulutusta koulutustarpeen mukaisesti {koulutusmuoto} koulutuksen järjestämisluvassa määrätyllä toiminta-alueella."
  },
  maaratVuodetMaaritetaanErikseen: {
    id: "tuva.maaratVuodetMaaritetaanErikseen",
    defaultMessage:
      "Oppilas-/opiskelijamäärät tai opiskelijavuodet määritetään erikseen"
  },
  maaratVuodetOtsikko: {
    id: "tuva.maaratVuodetOtsikko",
    defaultMessage:
      "Oppilas-/opiskelijamäärät tai opiskelijavuodet ja niitä koskevat rajoitukset"
  },
  maaratVuodetOtsikko2: {
    id: "tuva.maaratVuodetOtsikko2",
    defaultMessage: "Oppilas-/opiskelijamäärät tai opiskelijavuodet"
  },
  noudatetaanMaaratVuodet: {
    id: "tuva.noudatetaanMaaratVuodet",
    defaultMessage:
      "Noudatetaan järjestämisluvassa määrättyjä opiskelija-/oppilasmääriä tai opiskelijavuosia."
  },
  noudatetaanOpetuskielia: {
    id: "tuva.noudatetaanOpetuskielia",
    defaultMessage: "Noudatetaan järjestämisluvassa määrättyjä opetuskieliä"
  },
  noudatetaanToimintaAluetta: {
    id: "tuva.noudatetaanToimintaAluetta",
    defaultMessage: "Noudatetaan järjestämisluvassa määrättyä toiminta-aluetta"
  },
  opetuskieletMaaritetaanErikseen: {
    id: "tuva.opetuskieletMaaritetaanErikseen",
    defaultMessage: "Opetuskielet määritetään erikseen"
  },
  oppilasOpiskelijamaaratYmsMaaratysti: {
    id: "tuva.oppilasOpiskelijamaaratYmsMaaratysti",
    defaultMessage:
      "Koulutuksen järjestäjä voi järjestää koulutusta {koulutusmuoto} koulutuksen järjestämisluvassa määrätyn opiskelijamäärän enimmäis-/vähimmäismäärän tai opiskelijavuosien vähimmäismäärän puitteissa."
  },
  oppilasOpiskelijamaaratYmsMaaratystiTitle: {
    id: "tuva.oppilasOpiskelijamaaratYmsMaaratystiTitle",
    defaultMessage:
      "Oppilas-/opiskelijamäärät tai opiskelijavuodet ja niitä koskevat rajoitukset"
  },
  paasivunOtsikko: {
    id: "tuva.paasivunOtsikko",
    defaultMessage: "TUVA-koulutuksen järjestäjät"
  },
  pitkaGenetiivi: {
    id: "tuva.pitkaGenetiivi",
    defaultMessage: "tutkintokoulutukseen valmentavan koulutuksen"
  },
  toimintaAlueMaaritetaanErikseen: {
    id: "tuva.toimintaAlueMaaritetaanErikseen",
    defaultMessage: "Toiminta-alue määritetään erikseen"
  },
  koulutusmuotoJonkaYhteyteen: {
    id: "tuva.koulutusmuotoJonkaYhteyteen",
    defaultMessage:
      "Koulutusmuoto, jonka yhteyteen tutkintokoulutuksen valmentavan koulutuksen järjestämislupa on myönnetty: {koulutusmuoto}"
  }
});
