import { useEffect } from "react";
import { useIntl } from "react-intl";
import { CAS_LOGOUT_URL } from "modules/constants";
import localForage from "localforage";
import { useLocation } from "react-router-dom";

const LogOutWithLocale = (): null => {
  const { locale } = useIntl();
  const location = useLocation();

  localForage.clear();

  useEffect(() => {
    const redirectUrl = locale === "fi" ? "/fi/logout" : "/sv/logga-ut";
    window.location.href = `${CAS_LOGOUT_URL}?redirect=${
      location.search ? `${redirectUrl}${location.search}` : redirectUrl
    }`;
  }, [locale]);

  return null;
};

export default LogOutWithLocale;
