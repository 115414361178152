import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { PropTypes } from "prop-types";
import KorjattavatAsiat from "../KorjattavatAsiat/index";
import PaatetytAsiat from "../PaatetytAsiat";
import { Route, useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import common from "i18n/definitions/common";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import withStyles from "@mui/styles/withStyles";
import SimpleButton from "components/00-atoms/SimpleButton";
import UusiAsiaEsidialog from "../UusiAsiaEsidialog";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import commonMessages from "../../../i18n/definitions/common";
import Typography from "@mui/material/Typography";
import BaseData from "basedata";
import { getKoulutusmuotoByPredicate, localizeRouteKey } from "utils/common";
import { AppRoute } from "routes/index";
import { LocalizedSwitch } from "modules/i18n/index";
import AvoimetAsiat from "../AvoimetAsiat/index";
import { prop, propEq, startsWith } from "ramda";
import Asiakirjat from "components/02-organisms/Asiakirjat/index";
import { useMuutospyynnot } from "stores/muutospyynnot";
import Loading from "scenes/Loading";
import { koulutustyypitMap } from "utils/constants";
import localForage from "localforage";

const OivaTab = withStyles(theme => ({
  root: {
    minWidth: 0,
    textTransform: "none",
    color: "#333 !important",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    padding: 0,
    marginRight: "2rem",
    marginLeft: "0.3em",
    marginTop: "0.3em"
  }
}))(props => <Tab {...props} />);

const OivaTabs = withStyles(() => ({
  root: {},
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: "0.3rem !important",
    "& > div": {
      width: "100%",
      backgroundColor: "#4C7A61"
    }
  }
}))(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const Asiat = props => {
  const {
    koulutusmuoto,
    user,
    UusiAsiaEsidialog: ParametrinaAnnettuEsidialog,
    esidialoginHakuavaimet = ["organisaatiot"],
    dataFetchTrigger,
    setDataFetchTrigger
  } = props;
  const history = useHistory();
  const intl = useIntl();
  const { formatMessage, locale } = intl;
  const location = useLocation();
  const [muutospyynnot, muutospyynnotActions] = useMuutospyynnot();

  const avoimetPath = localizeRouteKey(
    locale,
    AppRoute.AsianhallintaAvoimet,
    intl,
    {
      koulutusmuoto: koulutusmuoto.kebabCase
    }
  );
  const paatetytPath = localizeRouteKey(
    locale,
    AppRoute.AsianhallintaPaatetyt,
    intl,
    {
      koulutusmuoto: koulutusmuoto.kebabCase
    }
  );
  const korjauksessaPath = localizeRouteKey(
    locale,
    AppRoute.AsianhallintaKorjattavat,
    intl,
    {
      koulutusmuoto: koulutusmuoto.kebabCase
    }
  );

  const tabKey = startsWith(avoimetPath, location.pathname)
    ? avoimetPath
    : startsWith(paatetytPath, location.pathname)
    ? paatetytPath
    : startsWith(korjauksessaPath, location.pathname)
    ? korjauksessaPath
    : null;

  const tilatArg = startsWith(avoimetPath, location.pathname)
    ? ["AVOIN", "VALMISTELUSSA", "ESITTELYSSA"]
    : startsWith(paatetytPath, location.pathname)
    ? ["PAATETTY"]
    : ["KORJAUKSESSA"];

  const pathArg = startsWith(avoimetPath, location.pathname)
    ? ["avoimet"]
    : startsWith(paatetytPath, location.pathname)
    ? ["paatetyt"]
    : ["korjauksessa"];

  // Avoimet asiat tab states
  const [update, updateState] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [pageNo, setPageNo] = useState(1);
  const [searchBy, setSearchBy] = useState({});
  const [sortedBy, setSortedBy] = useState({ columnIndex: 5, order: "desc" });

  // Paatety asiat tab states
  const [update2, updateState2] = useState(false);
  const [pageSize2, setPageSize2] = useState(20);
  const [pageNo2, setPageNo2] = useState(1);
  const [searchBy2, setSearchBy2] = useState({});
  const [sortedBy2, setSortedBy2] = useState({ columnIndex: 5, order: "desc" });

  // Avoimet asiat
  useEffect(() => {
    if (pageNo !== 1) {
      setPageNo(1);
    }
  }, [searchBy, sortedBy]);

  // Paatetty
  useEffect(() => {
    if (pageNo2 !== 1) {
      setPageNo2(1);
    }
  }, [searchBy2, sortedBy2]);

  // Avoimet asiat
  useEffect(() => {
    muutospyynnotActions.loadByStates(
      tilatArg,
      pathArg,
      false,
      true,
      koulutusmuoto.koulutustyyppi,
      pageSize,
      pageNo,
      searchBy,
      sortedBy
    );

    muutospyynnotActions.loadByStates(
      ["KORJAUKSESSA"],
      ["korjauksessa"],
      false,
      true,
      koulutusmuoto.koulutustyyppi,
      pageSize,
      pageNo,
      searchBy,
      sortedBy
    );
  }, [
    searchBy,
    sortedBy,
    location.search,
    koulutusmuoto.koulutustyyppi,
    pageSize,
    pageNo,
    update
  ]);

  // Paatetty
  useEffect(() => {
    muutospyynnotActions.loadByStates(
      tilatArg,
      pathArg,
      false,
      true,
      koulutusmuoto.koulutustyyppi,
      pageSize2,
      pageNo2,
      searchBy2,
      sortedBy2
    );
  }, [
    searchBy2,
    sortedBy2,
    location.search,
    koulutusmuoto.koulutustyyppi,
    pageSize2,
    pageNo2,
    update2
  ]);

  const [isEsidialogVisible, setIsEsidialogVisible] = useState(false);
  const t = formatMessage;

  const asianhallintaUrl = localizeRouteKey(
    locale,
    AppRoute.Asianhallinta,
    intl,
    { koulutusmuoto: koulutusmuoto.kebabCase }
  );

  const korjauksessaOlevatAsiat = prop("korjauksessa", muutospyynnot);
  const paatetytAsiat = prop("paatetyt", muutospyynnot);
  const avoimetAsiat = prop("avoimet", muutospyynnot);

  if (
    prop("fetchedAt", korjauksessaOlevatAsiat) ||
    prop("fetchedAt", paatetytAsiat) ||
    prop("fetchedAt", avoimetAsiat)
  ) {
    return (
      <Fragment>
        <BreadcrumbsItem to={asianhallintaUrl}>
          {formatMessage(commonMessages.asianhallinta)}
        </BreadcrumbsItem>
        <Helmet htmlAttributes={{ lang: locale }}>
          <title>{`Oiva | ${t(common.asiat)}`}</title>
        </Helmet>
        {isEsidialogVisible && (
          <BaseData
            keys={esidialoginHakuavaimet}
            locale={locale}
            koulutustyyppi={koulutusmuoto.koulutustyyppi}
            render={_props => {
              // Voi olla, että esidialogi on annettu koulutusmuodon
              // tiedoissa (src/scenes/Koulutusmuodot/[koulutusmuoto]/index),
              // jolloin käytetään annettua esidialogia. Muutoin käytetään
              // koulutusmuodoille yhteistä esidialogia.
              return ParametrinaAnnettuEsidialog ? (
                <ParametrinaAnnettuEsidialog
                  isVisible={isEsidialogVisible}
                  koulutustyyppi={koulutusmuoto.koulutustyyppi}
                  onClose={() => setIsEsidialogVisible(false)}
                  organisations={_props.organisaatiot}
                  baseDataContent={_props}
                  onSelect={(
                    selectedItem,
                    selectedLanguage,
                    customPath = null,
                    additionalPathParams = null
                  ) => {
                    const url = localizeRouteKey(
                      locale,
                      customPath ? customPath : AppRoute.UusiHakemus,
                      intl,
                      {
                        id: selectedItem.value,
                        koulutusmuoto: koulutusmuoto.kebabCase,
                        page: 1,
                        language: prop("value", selectedLanguage),
                        ...additionalPathParams
                      }
                    );
                    history.push(url);
                  }}></ParametrinaAnnettuEsidialog>
              ) : (
                <UusiAsiaEsidialog
                  isVisible={isEsidialogVisible}
                  koulutustyyppi={koulutusmuoto.koulutustyyppi}
                  onClose={() => setIsEsidialogVisible(false)}
                  organisations={_props.organisaatiot}
                  onSelect={(selectedItem, language, route, extraProps) => {
                    let koulutusmuotoKebabCase = koulutusmuoto.kebabCase;
                    if (
                      koulutusmuoto.koulutustyyppi === koulutustyypitMap.TUVA
                    ) {
                      const koulutusmuoto = getKoulutusmuotoByPredicate(
                        propEq("koulutustyyppi", extraProps.koulutustyyppi),
                        intl
                      );
                      if (koulutusmuoto) {
                        koulutusmuotoKebabCase = koulutusmuoto.kebabCase;
                      } else {
                        return false;
                      }
                    }
                    const nextRoute = localizeRouteKey(
                      locale,
                      route || AppRoute.UusiHakemus,
                      intl,
                      {
                        id: selectedItem.value,
                        koulutusmuoto: koulutusmuotoKebabCase,
                        page: 1,
                        language: language ? language.value : "fi"
                      }
                    );
                    history.push(nextRoute);
                  }}></UusiAsiaEsidialog>
              );
            }}
          />
        )}
        <LocalizedSwitch>
          {!tabKey && (
            <Route
              path={AppRoute.Asia}
              render={() => (
                <Asiakirjat
                  koulutusmuoto={koulutusmuoto}
                  dataFetchTrigger={dataFetchTrigger}
                  setDataFetchTrigger={setDataFetchTrigger}
                />
              )}
            />
          )}
          <Route path="*">
            <div className="flex flex-col justify-end mx-auto w-4/5 max-w-8xl mt-12">
              <div className="flex items-center">
                <div className="flex-1">
                  <Typography component="h1" variant="h1">
                    {t(common.asianhallinta)}
                  </Typography>
                  <div className="w-full flex flex-row justify-between">
                    <Typography
                      component="h2"
                      variant="h2"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: 400,
                        lineHeight: "1.5rem",
                        padding: 0
                      }}>
                      {koulutusmuoto.paasivunOtsikko}
                    </Typography>
                    <div>
                      <SimpleButton
                        aria-label={t(common.luoUusiAsia)}
                        color="primary"
                        variant="contained"
                        text={t(common.luoUusiAsia)}
                        size="large"
                        onClick={() => {
                          if (
                            koulutusmuoto.koulutustyyppi ===
                            koulutustyypitMap.TUVA
                          ) {
                            localForage.setItem(
                              "luoUusiAsiaPainikkeenSijainti",
                              location.pathname
                            );
                            const nextRoute = localizeRouteKey(
                              locale,
                              AppRoute.UusiAsiaEsivalinnat,
                              intl,
                              {
                                koulutusmuoto: koulutusmuoto.kebabCase
                              }
                            );
                            history.push(nextRoute);
                          } else {
                            setIsEsidialogVisible(true);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <OivaTabs
                    value={tabKey}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(e, val) => {
                      history.push(val);
                      updateState(!update);
                      updateState2(!update2);
                    }}>
                    <OivaTab
                      label={t(common.asiatOpen)}
                      aria-label={t(common.asiatReady)}
                      to={avoimetPath}
                      value={avoimetPath}
                    />
                    <OivaTab
                      label={t(common.asiatReady)}
                      aria-label={t(common.asiatReady)}
                      to={paatetytPath}
                      value={paatetytPath}
                    />
                    {korjauksessaOlevatAsiat && (
                      <OivaTab
                        label={t(common.asiatKorjauksessa)}
                        aria-label={t(common.asiatKorjauksessa)}
                        to={korjauksessaPath}
                        value={korjauksessaPath}
                      />
                    )}
                  </OivaTabs>
                </div>
              </div>
            </div>

            <div className="flex-1 flex bg-gray-100 border-t border-solid border-gray-300">
              <div className="flex mx-auto w-4/5 max-w-8xl py-12">
                <div className="flex-1">
                  <LocalizedSwitch>
                    <Route
                      authenticated={!!user.oid}
                      path={AppRoute.AsianhallintaAvoimet}
                      render={() => (
                        <AvoimetAsiat
                          koulutusmuoto={koulutusmuoto}
                          vstTyypit={props.vstTyypit}
                          muutospyynnot={muutospyynnot}
                          pageNo={pageNo}
                          pageSize={pageSize}
                          searchBy={searchBy}
                          sortedBy={sortedBy}
                          setPageNo={setPageNo}
                          setPageSize={setPageSize}
                          setSortedBy={setSortedBy}
                          setSearchBy={setSearchBy}
                        />
                      )}
                    />
                    <Route
                      authenticated={!!user.oid}
                      path={AppRoute.AsianhallintaPaatetyt}
                      render={() => (
                        <PaatetytAsiat
                          koulutusmuoto={koulutusmuoto}
                          vstTyypit={props.vstTyypit}
                          muutospyynnot={muutospyynnot}
                          pageNo={pageNo2}
                          pageSize={pageSize2}
                          searchBy={searchBy2}
                          sortedBy={sortedBy2}
                          setPageNo={setPageNo2}
                          setPageSize={setPageSize2}
                          setSortedBy={setSortedBy2}
                          setSearchBy={setSearchBy2}
                        />
                      )}
                    />
                    <Route
                      authenticated={!!user.oid}
                      path={AppRoute.AsianhallintaKorjattavat}
                      render={() => (
                        <KorjattavatAsiat
                          korjauksessaOlevatAsiat={korjauksessaOlevatAsiat}
                          koulutusmuoto={koulutusmuoto}
                          vstTyypit={props.vstTyypit}
                        />
                      )}
                    />
                  </LocalizedSwitch>
                </div>
              </div>
            </div>
          </Route>
        </LocalizedSwitch>
      </Fragment>
    );
  } else {
    return <Loading />;
  }
};

Asiat.propTypes = {
  esidialoginHakuavaimet: PropTypes.array,
  koulutusmuoto: PropTypes.object,
  user: PropTypes.object,
  UusiAsiaEsidialog: PropTypes.func,
  vstTyypit: PropTypes.array,
  dataFetchTrigger: PropTypes.bool,
  setDataFetchTrigger: PropTypes.func
};

export default Asiat;
