import { getRaw } from "basedata";
import PropTypes from "prop-types";
import { Fragment, ReactElement, useEffect, useState } from "react";
import { backendRoutes } from "stores/utils/backendRoutes";
import { useIntl } from "react-intl";
import common from "i18n/definitions/common";
import Link from "@mui/material/Link";
import { getKoulutusmuotoByPredicate, localizeRouteKey } from "utils/common";
import { useHistory } from "react-router-dom";
import { Lupa } from "Lupa";
import { propEq, toLower } from "ramda";
import { AppRoute } from "routes";
import { Koulutusmuoto } from "types";
import { koulutustyypitMap } from "utils/constants";

type Props = {
  maaraavaLupa: Lupa;
  tyyppi: string;
};

export const LupaLinkkiTyyppi = {
  ALILUPA: "aliluvat",
  PAALUPA: "paaluvat"
};

const LupaLinkkiHTML = ({ maaraavaLupa, tyyppi }: Props): ReactElement => {
  const history = useHistory();
  const intl = useIntl();
  const [aliLupa, setAliLupa] = useState<Lupa | undefined>(undefined);
  const [kebabCase, setKebabCase] = useState<string>("");
  const [linkText, setLinkText] = useState<string>("");

  useEffect(() => {
    if (maaraavaLupa && tyyppi) {
      (async () => {
        const backendRoute =
          tyyppi === LupaLinkkiTyyppi.ALILUPA
            ? `${backendRoutes.aliLuvat.path}${maaraavaLupa.uuid}${backendRoutes.aliLuvat.postfix}`
            : `${backendRoutes.paaLuvat.path}${maaraavaLupa.uuid}${backendRoutes.paaLuvat.postfix}`;

        const lupa = await getRaw(tyyppi, backendRoute, [], 0);
        const koulutusmuoto = getKoulutusmuotoByPredicate(
          propEq(
            "koulutustyyppi",
            lupa?.koulutustyyppi || koulutustyypitMap.AMMATILLINEN_KOULUTUS
          ),
          intl
        ) as Koulutusmuoto;

        let tarkasteleTuvaLupaanLiittyvaaPaalupaa = intl.formatMessage(
          common.tarkasteleTuvaLupaanLiittyvaaPaalupaa
        );

        const baseValues = ["{koulutusmuoto}", "{utbildningsform}"];

        baseValues.map(value => {
          tarkasteleTuvaLupaanLiittyvaaPaalupaa =
            tarkasteleTuvaLupaanLiittyvaaPaalupaa.replace(
              value,
              toLower(koulutusmuoto.genetiivi)
            );
        });

        const _linkText =
          tyyppi === LupaLinkkiTyyppi.ALILUPA
            ? intl.formatMessage(
                common.tarkasteleTutkintokoulutukseenValmentavanKoulutuksenJarjestamisLupaa
              )
            : tarkasteleTuvaLupaanLiittyvaaPaalupaa;

        setLinkText(_linkText);
        setAliLupa(lupa);
        setKebabCase(koulutusmuoto?.kebabCase);
      })();
    }
  }, [tyyppi, maaraavaLupa]);
  return (
    <Fragment>
      {aliLupa && kebabCase && (
        <div className="mt-4 mb-4">
          {linkText}{" "}
          <Link
            className="cursor-pointer"
            style={{ textDecoration: "underline" }}
            onClick={() => {
              history.push(
                `${localizeRouteKey(
                  intl.locale,
                  AppRoute.Jarjestamislupa,
                  intl,
                  {
                    koulutusmuoto: kebabCase,
                    id: aliLupa.uuid
                  } as never
                )}`
              );
            }}>
            {intl.formatMessage(common.lupaTitle)}
          </Link>
        </div>
      )}
    </Fragment>
  );
};

LupaLinkkiHTML.propTypes = {
  maaraavaLupa: PropTypes.object,
  type: PropTypes.string
};

export default LupaLinkkiHTML;
