import { memo, useMemo, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import common from "i18n/definitions/common";
import wizard from "i18n/definitions/wizard";
import Lomake from "components/02-organisms/Lomake";
import {
  useChangeObjects,
  useChangeObjectsByAnchorWithoutUnderRemoval
} from "stores/muutokset";
import equal from "react-fast-compare";
import * as R from "ramda";
import { getAnchorPart } from "utils/anchor";

const constants = {
  formLocation: ["taiteenPerusopetus", "opetustaAntavatKunnat"],
  mode: "modification"
};

const Sopimuskunnat = memo(
  ({
    code,
    isPreviewModeOn,
    maaraykset,
    rajoitteet,
    sectionId,
    title,
    valtakunnallinenMaarays
  }) => {
    const intl = useIntl();
    const [changeObjects, { setChanges }] =
      useChangeObjectsByAnchorWithoutUnderRemoval({
        anchor: sectionId
      });
    const [, { createTextBoxChangeObject }] = useChangeObjects();

    const maakuntamaaraykset = R.filter(
      maarays => maarays.koodisto === "maakunta",
      maaraykset
    );

    const kuntamaaraykset = R.filter(maarays => {
      return maarays.koodisto === "kunta";
    }, maaraykset);

    const [isEditViewActive, toggleEditView] = useState(false);

    const fiCode = R.prop("koodiarvo", valtakunnallinenMaarays);

    const whenChanges = useCallback(
      changes => {
        const withoutCategoryFilterChangeObj = R.filter(
          R.compose(R.not, R.propEq("anchor", `${sectionId}.categoryFilter`)),
          changeObjects
        );

        const amountOfChanges = R.flatten(
          R.values(changes.changesByProvince)
        ).length;

        const changesToSet = R.concat(
          withoutCategoryFilterChangeObj,
          amountOfChanges
            ? [
                {
                  anchor: `${sectionId}.categoryFilter`,
                  properties: {
                    changesByProvince: changes.changesByProvince
                  }
                }
              ]
            : []
        );

        return setChanges(changesToSet, sectionId);
      },
      [changeObjects, sectionId, setChanges]
    );

    const provinceChanges = useMemo(() => {
      const changeObj = R.find(
        R.propEq("anchor", `${sectionId}.categoryFilter`),
        changeObjects
      );
      return changeObj ? changeObj.properties.changesByProvince : {};
    }, [changeObjects, sectionId]);

    const noSelectionsInLupa =
      R.isEmpty(maakuntamaaraykset) &&
      R.isEmpty(kuntamaaraykset) &&
      fiCode !== "FI1";

    const onAddButtonClick = useCallback(
      (fromComponent, index) => {
        createTextBoxChangeObject(
          sectionId,
          getAnchorPart(fromComponent.fullAnchor, 1),
          index
        );
      },
      [createTextBoxChangeObject, sectionId]
    );

    return (
      <Lomake
        mode={constants.mode}
        anchor={sectionId}
        changeObjects={changeObjects}
        code={code}
        data={{
          changeObjectsByProvince: Object.assign({}, provinceChanges),
          fiCode,
          isEditViewActive,
          isEiMaariteltyaToimintaaluettaChecked: fiCode === "FI2",
          isValtakunnallinenChecked: fiCode === "FI1",
          localizations: {
            accept: intl.formatMessage(common.accept),
            areaOfActionIsUndefined: intl.formatMessage(
              wizard.noMunicipalitiesSelected
            ),
            cancel: intl.formatMessage(common.cancel),
            currentAreaOfAction: intl.formatMessage(
              wizard.municipalitiesInPresentLupa
            ),
            newAreaOfAction: noSelectionsInLupa
              ? intl.formatMessage(wizard.municipalities)
              : intl.formatMessage(wizard.municipalitiesInNewLupa),
            ofMunicipalities: intl.formatMessage(wizard.ofMunicipalities),
            editButtonText: intl.formatMessage(wizard.selectMunicipalities),
            sameAsTheCurrentAreaOfAction: intl.formatMessage(
              wizard.sameAsTheCurrentAreaOfAction
            ),
            wholeCountryWithoutAhvenanmaa: intl.formatMessage(
              wizard.wholeCountryWithoutAhvenanmaa
            )
          },
          kuntamaaraykset,
          maakuntamaaraykset,
          maaraykset,
          rajoitteet,
          sectionId,
          valtakunnallinenMaarays
        }}
        functions={{
          onChanges: whenChanges,
          toggleEditView,
          onAddButtonClick
        }}
        isPreviewModeOn={isPreviewModeOn}
        isRowExpanded={true}
        path={constants.formLocation}
        rowTitle={intl.formatMessage(wizard.municipalities)}
        showCategoryTitles={true}
        formTitle={title}
      />
    );
  },
  (cp, np) => {
    return equal(R.omit(["functions"], cp), R.omit(["functions"], np));
  }
);

Sopimuskunnat.propTypes = {
  code: PropTypes.string,
  isPreviewModeOn: PropTypes.bool,
  kuntamaaraykset: PropTypes.array,
  maaraykset: PropTypes.array,
  rajoitteet: PropTypes.object,
  sectionId: PropTypes.string,
  title: PropTypes.string,
  valtakunnallinenMaarays: PropTypes.object
};

Sopimuskunnat.displayName = "Sopimuskunnat";

export default Sopimuskunnat;
