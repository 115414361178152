import { filter, sortBy, mergeAll } from "ramda";
import { LocaleUpper } from "enums";
import { Kunta, MaakuntaRaw } from "koodistodatanTyypit";
import { Maakunta } from "koodistodatanTyypitFrontille";

/**
 * Luo yksittäisen maakunnan raakadatasta käsitellyn version front-endin
 * käyttöön.
 * @param maakuntaRaw Maakunta backendiltä tulevassa muodossa sisältäen
 * kunta-taulukon.
 * @param localeUpper FI | SV
 * @returns Maakunta frontendille suotuisassa muodossa.
 */
export function initializeMaakunta(
  maakuntaRaw: MaakuntaRaw,
  localeUpper: LocaleUpper
): Maakunta | null {
  const currentDate = new Date();
  if (
    currentDate >= new Date(maakuntaRaw.voimassaAlkuPvm) &&
    currentDate <= new Date(maakuntaRaw.voimassaLoppuPvm || currentDate) &&
    // 21 = Ahvenanmaa
    maakuntaRaw.koodiArvo !== "21" &&
    // 99 = Ei tiedossa
    maakuntaRaw.koodiArvo !== "99"
  ) {
    // Filter out ulkomaat
    const kunnatRaw: Array<Kunta> = filter(
      kunta => kunta.koodiArvo !== "200",
      maakuntaRaw.kunta
    );

    const kunnat = kunnatRaw.map(kuntaRaw => ({
      koodiarvo: kuntaRaw.koodiArvo,
      metadata: mergeAll(
        kuntaRaw.metadata.map(item => ({ [item.kieli]: item }))
      ),
      versio: kuntaRaw.versio,
      voimassaAlkuPvm: kuntaRaw.voimassaAlkuPvm
    }));

    return {
      koodiarvo: maakuntaRaw.koodiArvo,
      kunnat: sortBy(k => k.metadata[localeUpper].nimi, kunnat),
      metadata: mergeAll(
        maakuntaRaw.metadata.map(item => ({ [item.kieli]: item }))
      ),
      voimassaAlkuPvm: maakuntaRaw.voimassaAlkuPvm,
      voimassaLoppuPvm: maakuntaRaw.voimassaLoppuPvm
    };
  } else {
    return null;
  }
}
