import { PaikallisenTietovarastonAvain } from "enums";
import { Organisation } from "koodistodatanTyypit";
import localForage from "localforage";

export async function getLisaksiNaytettavatKentat(): Promise<
  Array<Organisation | unknown>
> {
  const yllapitaja = await localForage.getItem(
    PaikallisenTietovarastonAvain.ORGANISAATIO
  );
  const oppilaitos = await localForage.getItem(
    PaikallisenTietovarastonAvain.OPPILAITOS
  );

  return [yllapitaja, oppilaitos];
}
