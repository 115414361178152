import PropTypes from "prop-types";
import Lomake from "components/02-organisms/Lomake";
import { useChangeObjectsByAnchorWithoutUnderRemoval } from "stores/muutokset";
import { useIntl } from "react-intl";
import common from "i18n/definitions/common";

const constants = {
  formLocations: {
    oppilaitoksenKoulutustehtava: [
      "vapaaSivistystyo",
      "oppilaitoksenAlueellisuusJaValtakunnallisuus"
    ]
  }
};

const OppilaitoksenAlueellisuusJaValtakunnallisuus = ({
  isPreviewModeOn,
  mode = constants.mode,
  maaraykset
}) => {
  const { formatMessage } = useIntl();
  const sectionId = "oppilaitoksenAlueellisuusJaValtakunnallisuus";
  const [changeObjects] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: sectionId
  });

  return (
    <Lomake
      anchor={sectionId}
      changeObjects={changeObjects}
      formTitle={formatMessage(common.VSTLupaSectionTitleOperationalArea)}
      isPreviewModeOn={isPreviewModeOn}
      isReadOnly={isPreviewModeOn}
      isRowExpanded={true}
      mode={mode}
      path={constants.formLocations.oppilaitoksenKoulutustehtava}
      data={{ maaraykset }}
      rowTitle={formatMessage(
        common.VSTLupaSectionTitleOperationalArea
      )}></Lomake>
  );
};

OppilaitoksenAlueellisuusJaValtakunnallisuus.propTypes = {
  isPreviewModeOn: PropTypes.bool,
  mode: PropTypes.string,
  maaraykset: PropTypes.array
};

export default OppilaitoksenAlueellisuusJaValtakunnallisuus;
