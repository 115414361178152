import { AcceptTypes, getRaw } from "basedata";
import { Fragment, ReactElement, useEffect, useState } from "react";
import { backendRoutes } from "stores/utils/backendRoutes";
import Loading from "scenes/Loading";
import { useParams } from "react-router-dom";
import { match, find, propEq, path } from "ramda";
import { useIntl } from "react-intl";
import common from "i18n/definitions/common";
import { useChangeObjectsByAnchorWithoutUnderRemoval } from "stores/muutokset";
import { ChangeObject, Organisation } from "types";
import moment from "moment";
import { DATE_FORMAT } from "utils/constants";

const toolTipElement = {
  lisaysTextFi: '"Lisäys"',
  poistoTextFi: '"Poisto"',
  awayFromText: "-10px",
  paddingTop: "10px",
  heightTop: "1px",
  heightBottom: "2px",
  width: "5px",
  backgroundColor: "#9b26b62b",
  color: "#9B26B6",
  radius: "2px"
};

const muutoksetStylesDefault = `
<style>
  .poisto {
    display: none;
  }
`;

const muutoksetStyles = `
<style>

.lisays, .poisto {
  display: block;
  width: 100%;
  color: ${toolTipElement.color};
}

.lisays span.cell-wider, span.lisays, .poisto span.cell-wider, span.poisto {
  padding-top: 10px;
}

span.lisays::after, .lisays span.cell-wider::after, .lisays span.cell-wide::after, .lisays.oivamuutoikeudetvelvollisuudetehdotjatehtavat_2 span.cell::after {
  content: ${toolTipElement.lisaysTextFi};
}

span.poisto::after, .poisto span.cell-wider::after, .poisto span.cell-wide::after, .poisto.oivamuutoikeudetvelvollisuudetehdotjatehtavat_2 span.cell::after{
  content: ${toolTipElement.poistoTextFi};
}

span.lisays::after, .lisays span.cell-wider::after, .lisays span.cell-wide::after, .lisays.oivamuutoikeudetvelvollisuudetehdotjatehtavat_2 span.cell::after,
span.poisto::after, .poisto span.cell-wider::after, .poisto span.cell-wide::after, .poisto.oivamuutoikeudetvelvollisuudetehdotjatehtavat_2 span.cell::after
{
  display:inline-block;
  background-color: ${toolTipElement.backgroundColor};
  color: ${toolTipElement.color};
  padding-top: ${toolTipElement.heightTop};
  padding-bottom: ${toolTipElement.heightBottom};
  padding-left: ${toolTipElement.width};
  padding-right: ${toolTipElement.width};
  border-radius: ${toolTipElement.radius};
  position: relative;
  right: ${toolTipElement.awayFromText};
  font-size: .6rem;
  letter-spacing: 0.02rem;
  font-weight: 600;
  text-transform: uppercase;
}

.poisto {
  padding-top: 5px;
  text-decoration: line-through;
}
`;

type Props = {
  naytaMuutokset: boolean;
  setMuutostenMaara: (x: number) => void;
  organisation: Organisation;
};

const IntegroituEsikatselu = ({
  naytaMuutokset,
  setMuutostenMaara,
  organisation
}: Props): ReactElement => {
  const intl = useIntl();
  const { muutospyyntoUuid }: { muutospyyntoUuid: string } = useParams();
  const [renderedHtml, setRenderedHtml] = useState<string>("");
  const [originalHtml, setOriginalHtml] = useState<string>("");
  const [isHtmlLoaded, setIsHtmlLoaded] = useState<boolean>(false);

  const [topThreeCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "topthree"
  });

  const asianumeroObj = find(
    propEq("anchor", "topthree.asianumero.A"),
    topThreeCO || []
  ) as ChangeObject;

  const paatosPaivaObj = find(
    propEq("anchor", "topthree.paatospaiva.A"),
    topThreeCO || []
  ) as ChangeObject;

  const paatosPaivaDate = path(
    ["properties", "value"],
    paatosPaivaObj
  ) as string;

  useEffect(() => {
    if (muutospyyntoUuid) {
      (async () => {
        setIsHtmlLoaded(false);

        const backendRoute = `${backendRoutes.esikatseluHtml.path}${muutospyyntoUuid}${backendRoutes.esikatseluHtml.postfix}`;
        const result = await getRaw(
          "esikatseluHtml",
          backendRoute,
          [],
          0,
          AcceptTypes.HTML
        );

        const countOfMuutokset = (match(/lisays|poisto/g, result) || []).length;

        const stylesSplitted = result.split("<style>");
        const defaultHtml = `${stylesSplitted[0]}${muutoksetStylesDefault}${stylesSplitted[1]}`;

        setMuutostenMaara(countOfMuutokset);
        setOriginalHtml(result);
        setRenderedHtml(defaultHtml);
        setIsHtmlLoaded(true);
      })();
    }
  }, [muutospyyntoUuid]);

  useEffect(() => {
    if (originalHtml) {
      const stylesSplitted = originalHtml.split("<style>");
      const htmlMuutokset = naytaMuutokset
        ? `${stylesSplitted[0]}${muutoksetStyles}${stylesSplitted[1]}`
        : `${stylesSplitted[0]}${muutoksetStylesDefault}${stylesSplitted[1]}`;
      setRenderedHtml(htmlMuutokset);
    }
  }, [naytaMuutokset, renderedHtml]);

  return (
    <Fragment>
      {isHtmlLoaded ? (
        <div
          className="border-gray-300 shadow-xl pl-24 pr-24 pb-24 pt-12"
          style={{
            backgroundColor: "#ffffff",
            maxWidth: "1000px",
            marginLeft: "auto",
            marginRight: "auto",
            border: "1px solid rgb(233 233 233)"
          }}>
          <div className="flex">
            <div className="basis-1/3">
              <p style={{ color: "#666666" }}>{organisation?.nimi?.fi}</p>
              <p style={{ color: "#666666" }}>{organisation?.ytunnus}</p>
            </div>
            <div
              className="basis-1/3"
              style={{
                justifyContent: "center",
                alignItems: "center"
              }}>
              <p style={{ textAlign: "center", color: "#666666" }}>
                {intl.formatMessage(common.paatosLuonnos)}
              </p>
            </div>
            <div className="basis-1/3">
              <div
                style={{
                  position: "relative",
                  float: "right",
                  color: "#666666"
                }}>
                <p>{asianumeroObj?.properties?.value as string}</p>
                {paatosPaivaDate
                  ? moment(paatosPaivaDate).format(DATE_FORMAT)
                  : null}
              </div>
            </div>
          </div>
          <div
            style={{ marginTop: "58px" }}
            dangerouslySetInnerHTML={{ __html: renderedHtml }}></div>
        </div>
      ) : (
        <Loading />
      )}
    </Fragment>
  );
};

IntegroituEsikatselu.propTypes = {};

export default IntegroituEsikatselu;
