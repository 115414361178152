import { CellFn } from "graphHandling/graphTypes";

export const paalupailmoituksenPoistaminen: CellFn = storeFunctions => {
  storeFunctions.updateGraph(
    ["components", "ilmoitusPaaluvanPuuttumisesta", "properties"],
    {
      isVisible: false
    }
  );
  return true;
};
