import { ReactElement } from "react";
import { ILabeledCheckbox } from "utils/lomakkeet";
import { ComponentType } from "graphHandling/components/componentType";
import LabeledCheckbox, { ILabeledCheckboxProps } from "./LabeledCheckbox";
import { getCurrentProps } from "graphHandling/graphUtils";

export function renderLabeledCheckbox(
  componentDefinition: ILabeledCheckbox
): ReactElement | null {
  if (componentDefinition) {
    const properties = getCurrentProps(
      componentDefinition
    ) as unknown as ILabeledCheckboxProps;

    if (
      componentDefinition.name === ComponentType.LABELED_CHECKBOX &&
      componentDefinition.onChange
    ) {
      return (
        <LabeledCheckbox
          {...properties}
          onChange={componentDefinition.onChange}
        />
      );
    }
  }
  return null;
}
