import PropTypes from "prop-types";
import ExpandableRowRoot from "components/02-organisms/ExpandableRowRoot";
import Tutkinto from "./Tutkinto";

import {
  filter,
  values,
  prop,
  map,
  length,
  compose,
  sum,
  path,
  isEmpty,
  mapObjIndexed,
  pipe
} from "ramda";
import moment from "moment";
import { useIntl } from "react-intl";

const SubAla = props => {
  const intl = useIntl();
  const { nimi, koulutukset, renderCheckbox, lupaAlkuPvm } = props;
  const tutkinnot = filter(isTutkintoVoimassa, koulutukset);
  return (
    tutkinnot &&
    !isEmpty(tutkinnot) && (
      <div className="p-4">
        <div>{nimi}</div>
        {map(tutkinto => {
          return (
            <Tutkinto
              {...tutkinto}
              intl={intl}
              key={prop("koodi", tutkinto)}
              renderCheckbox={renderCheckbox}
              lupaAlkuPvm={lupaAlkuPvm}
            />
          );
        }, tutkinnot)}
      </div>
    )
  );
};

SubAla.propTypes = {
  lupaAlkuPvm: PropTypes.string,
  nimi: PropTypes.string,
  koulutukset: PropTypes.array,
  renderCheckbox: PropTypes.bool,
  intl: PropTypes.object
};

const Koulutusala = ({
  koodi,
  koulutusalat,
  lupaAlkuPvm,
  nimi,
  renderCheckbox
}) => {
  const amountOfTutkinnot = sum(
    map(
      compose(length, filter(isTutkintoVoimassa), prop("koulutukset")),
      values(koulutusalat)
    )
  );
  return (
    amountOfTutkinnot > 0 && (
      <ExpandableRowRoot
        code={koodi}
        title={`${nimi} (${amountOfTutkinnot} kpl)`}>
        {pipe(
          mapObjIndexed((ala, i) => {
            return (
              <SubAla
                {...ala}
                key={i}
                renderCheckbox={renderCheckbox}
                lupaAlkuPvm={lupaAlkuPvm}
              />
            );
          }),
          values
        )(koulutusalat)}
      </ExpandableRowRoot>
    )
  );
};

const isTutkintoVoimassa = tutkinto => {
  const alkupvm = path(["meta", "alkupvm"], tutkinto);
  const loppupvm = path(["meta", "loppupvm"], tutkinto);
  if (!alkupvm || !loppupvm) {
    return true;
  }
  return moment().isBetween(moment(alkupvm), moment(loppupvm), "days", "[)");
};

Koulutusala.propTypes = {
  koodi: PropTypes.string,
  koulutusalat: PropTypes.object,
  lupaAlkuPvm: PropTypes.string,
  nimi: PropTypes.string,
  renderCheckbox: PropTypes.bool
};

export default Koulutusala;
