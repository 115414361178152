import { defineLabeledCheckbox } from "graphHandling/components/labeledCheckbox/unopinionated";
import { CellFn, Modifications } from "graphHandling/graphTypes";
import { Lupa } from "Lupa";
import { assoc, filter, isEmpty } from "ramda";
import { Muutos, Muutospyynto } from "types";
import { Properties } from "utils/lomakkeet";
import { Koodisto, Toiminto } from "enums";
import { Maarays } from "koodistodatanTyypit";

export const initialize_ulkomaatCheckbox: CellFn = storeFunctions => {
  let muutokset: Array<Muutos> = [];
  let luvanUlkomaalokaatiot: Array<Maarays> = [];

  const custom = storeFunctions.readPath(["custom"]) as Properties;

  const viimeisinLupa = custom.viimeisinLupa as Lupa;

  const muutospyynto = custom.muutospyynto as Muutospyynto;

  if (viimeisinLupa) {
    const { maaraykset } = viimeisinLupa;
    luvanUlkomaalokaatiot = filter(maarays => {
      return (
        maarays.koodisto === Koodisto.KUNTA &&
        maarays.koodiarvo === "200" &&
        maarays.meta?.arvo !== undefined
      );
    }, maaraykset);
  }

  let modifications: Modifications = {};

  if (muutospyynto) {
    muutokset = muutospyynto.muutokset as Array<Muutos>;

    const lisaykset = filter(
      muutos =>
        muutos.koodisto === Koodisto.KUNTA &&
        muutos.koodiarvo === "200" &&
        muutos.tila === Toiminto.LISAYS,
      muutokset
    );

    const poistot = filter(
      muutos =>
        muutos.koodisto === Koodisto.KUNTA &&
        muutos.koodiarvo === "200" &&
        muutos.tila === Toiminto.POISTO,
      muutokset
    );

    if (
      poistot.length > 0 &&
      poistot.length === luvanUlkomaalokaatiot.length &&
      lisaykset.length === 0
    ) {
      modifications = assoc("backend", { isChecked: false }, modifications);
    } else if (isEmpty(luvanUlkomaalokaatiot) && lisaykset.length > 0) {
      modifications = assoc("backend", { isChecked: true }, modifications);
    }
  }

  return defineLabeledCheckbox(
    {
      id: "checkbox1",
      isChecked: !isEmpty(luvanUlkomaalokaatiot),
      label: "Opetusta järjestetään Ulkomailla",
      name: "toiminta-alue",
      value: "ulkomailla"
    },
    {
      onChange: payload => {
        return payload;
      }
    },
    isEmpty(modifications) ? undefined : modifications
  );
};
