import { defineMultiTextBox } from "graphHandling/components/multiTextBox/definition";
import { TietueProps } from "graphHandling/components/multiTextBox/MultiTextBox";
import { CellFn } from "graphHandling/graphTypes";
import { Lupa } from "Lupa";
import { filter, isEmpty, isNil, map, mergeAll, path, reject } from "ramda";
import { Muutos, Muutospyynto } from "types";
import { Properties } from "utils/lomakkeet";
import { Koodisto, Toiminto } from "enums";
import common from "i18n/definitions/common";

export const initialize_multiTextBox: CellFn = (storeFunctions, intl) => {
  if (!intl) {
    console.warn("Parametri intl puuttuu.");
    return false;
  }

  let muutokset: Array<Muutos> = [];
  let multiTextBoxMuutokset: Array<Muutos> = [];
  let luvanTietueet: Record<string, TietueProps> = {};

  const custom = storeFunctions.readPath(["custom"]) as Properties;

  const viimeisinLupa = custom.viimeisinLupa as Lupa;

  const muutospyynto = custom.muutospyynto as Muutospyynto;

  if (muutospyynto) {
    muutokset = muutospyynto.muutokset;
    multiTextBoxMuutokset = filter(muutos => {
      return muutos.koodisto === Koodisto.KUNTA && muutos.koodiarvo === "200";
    }, muutokset);
  }

  let tietueet = {};

  if (viimeisinLupa) {
    luvanTietueet = mergeAll(
      reject(
        isNil,
        map(maarays => {
          if (maarays.koodiarvo === "200" && maarays.meta?.arvo !== undefined) {
            const createdAt = new Date();
            const id = maarays.uuid;
            return {
              [id]: {
                createdAt,
                id,
                placeholder: intl.formatMessage(common.kuvausPlaceholder),
                title: intl.formatMessage(common.kuvaus),
                value: maarays.meta?.arvo as string
              }
            };
          }
          return null;
        }, viimeisinLupa.maaraykset)
      )
    );
  }

  let modifications = {};

  if (!isEmpty(multiTextBoxMuutokset)) {
    const modifiedTietueet = mergeAll(
      reject(
        isNil,
        map(muutos => {
          const createdAt = new Date();
          const id = path(["meta", "ankkuri"], muutos) as string;
          return {
            [id]: {
              createdAt,
              id,
              isDeleted: muutos.tila === Toiminto.POISTO,
              placeholder: intl.formatMessage(common.kuvausPlaceholder),
              title: intl.formatMessage(common.kuvaus),
              value: muutos.meta?.arvo
            }
          };
        }, multiTextBoxMuutokset)
      )
    );

    modifications = {
      backend: {
        tietueet: Object.assign({}, luvanTietueet, modifiedTietueet)
      }
    };
  }

  if (isEmpty(tietueet)) {
    tietueet = mergeAll(
      map(muutos => {
        const createdAt = new Date();
        const id = `multiTextBox-first`;
        return {
          [id]: {
            createdAt,
            id,
            placeholder: intl.formatMessage(common.kuvausPlaceholder),
            title: intl.formatMessage(common.kuvaus),
            value: muutos.meta?.arvo
          }
        };
      }, multiTextBoxMuutokset)
    );
  }

  const createdAt = new Date();
  const oletustietueenId = `multiTextBox-${Math.random()}`;

  // Jos lupa ei sisällä yhtään tietuetta, luodaan yksi tietue, jotta
  // käyttäjän valitessa ulkomaacheckboxin, hänen ei tarvitsisi heti
  // painaa Lisää-painiketta.
  const oletustietueet = {
    [oletustietueenId]: {
      createdAt,
      id: oletustietueenId,
      placeholder: intl.formatMessage(common.kuvausPlaceholder),
      title: intl.formatMessage(common.kuvaus),
      value: ""
    }
  };

  return defineMultiTextBox(
    {
      tietueet: isEmpty(luvanTietueet) ? oletustietueet : luvanTietueet,
      isVisible: true
    },
    {
      onChange: payload => {
        return payload;
      }
    },
    modifications
  );
};
