import { ReactElement, useCallback, useEffect, useRef } from "react";
import { ComponentEvent } from "graphHandling/graphTypes";
import { Chip as MuiChip } from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import { Properties } from "utils/lomakkeet";

export interface IChipProps {
  id: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  isIndeterminate?: boolean;
  isLineThrough?: boolean;
  isLineThroughable?: boolean;
  isReadOnly?: boolean;
  isRemovable?: boolean;
  label: string;
  metadata?: Properties;
  name?: string;
  shouldHaveFocusAt?: number;
  value: string;
}

interface IChipPropsAndEvents extends IChipProps {
  onDelete: ComponentEvent | undefined;
}

const Chip = ({
  id,
  isLineThrough,
  isLineThroughable,
  isRemovable,
  label,
  onDelete,
  shouldHaveFocusAt
}: IChipPropsAndEvents): null | ReactElement => {
  const chipRef = useRef<HTMLDivElement | null>(null);

  const handleDelete = useCallback(() => {
    if (onDelete) {
      onDelete({
        changeProps: {
          isChecked: false,
          id
        }
      });
    }
  }, [id]);

  const setLineThrough = useCallback(
    event => {
      if (onDelete) {
        onDelete({
          changeProps: {
            id,
            isChecked: true,
            isLineThrough: true,
            shouldHaveFocusAt: new Date().getTime()
          },
          mouseEvent: event
        });
      }
    },
    [id]
  );

  const undo = useCallback(() => {
    if (onDelete) {
      onDelete({
        changeProps: {
          id,
          isChecked: true,
          isLineThrough: false,
          isLineThroughable: true,
          shouldHaveFocusAt: new Date().getTime()
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if (shouldHaveFocusAt && chipRef && chipRef.current) {
      chipRef.current.focus();
    }
  }, [shouldHaveFocusAt, chipRef]);

  if (isRemovable) {
    return (
      <MuiChip
        label={label}
        onDelete={handleDelete}
        ref={chipRef}
        style={{ color: "purple ", fontSize: "1rem" }}
        variant="outlined"
      />
    );
  } else if (isLineThroughable && !isLineThrough) {
    return (
      <MuiChip
        label={label}
        onDelete={event => setLineThrough(event)}
        ref={chipRef}
        style={{ fontSize: "1rem" }}
        variant="outlined"
      />
    );
  } else if (isLineThrough) {
    return (
      <div className="line-through text-[purple]">
        <MuiChip
          deleteIcon={<UndoIcon />}
          label={label}
          onDelete={undo}
          ref={chipRef}
          style={{ color: "purple ", border: "none", fontSize: "1rem" }}
        />
      </div>
    );
  } else {
    return (
      <MuiChip
        label={label}
        variant="outlined"
        ref={chipRef}
        style={{ border: "none", fontSize: "1rem" }}
      />
    );
  }
};

Chip.displayName = "Chip";

export default Chip;
