import { DialogContent, Dialog, DialogActions, Button } from "@mui/material";
import { useState, useEffect, useCallback, ReactElement } from "react";
import DialogTitle from "../components/02-organisms/DialogTitle";
import { sessionTimeoutInMinutes } from "modules/constants";
import auth from "../i18n/definitions/auth";
import localforage from "localforage";
import { useIntl } from "react-intl";

const timeLeftInitial = (sessionTimeoutInMinutes / 2) * 60; // unit: seconds

const SessionDialog = ({
  isVisible,
  onLogout,
  onOK
}: {
  isVisible: boolean;
  onLogout: () => unknown;
  onOK: () => unknown;
}): ReactElement => {
  const { formatMessage } = useIntl();
  const [startTime] = useState(Date.now()); // EPOC millis
  const [timeLeft, setTimeLeft] = useState(timeLeftInitial); // In seconds
  const [timerHandle, setTimerHandle] = useState<any>();

  const countDown = useCallback(() => {
    setTimeLeft(Math.max(timeLeft - (Date.now() / 1000 - startTime / 1000), 0));
  }, [startTime, timeLeft]);

  function continueAsLoggedIn() {
    clearInterval(timerHandle);
    return onOK();
  }

  const logout = useCallback(
    isAutomatic => {
      clearInterval(timerHandle);
      if (isAutomatic) {
        localforage.setItem("sessionTimeout", true).then(onLogout);
      } else {
        onLogout();
      }
    },
    [onLogout, timerHandle]
  );

  useEffect(() => {
    if (timeLeft === 0) {
      logout(true);
    }
  }, [logout, timeLeft]);

  useEffect(() => {
    if (isVisible && !timerHandle) {
      const timerHandle = setInterval(() => {
        return countDown();
      }, 1000);
      setTimerHandle(timerHandle);
    }
  }, [countDown, isVisible, timerHandle]);

  return (
    <Dialog
      open={true}
      aria-labelledby="simple-dialog-title"
      PaperProps={{ style: { overflowY: "visible" } }}>
      <DialogTitle id="customized-dialog-title" onClose={continueAsLoggedIn}>
        {formatMessage(auth.sessionDialogTitle)}
      </DialogTitle>
      <DialogContent style={{ overflowY: "visible" }}>
        <p className="mx-8 my-6">
          {formatMessage(auth.sessionDialogCountdown, {
            time: `${Math.floor(timeLeft / 60)} m ${Math.floor(timeLeft % 60)
              .toString()
              .padStart(2, "0")} s`
          })}
        </p>
      </DialogContent>
      <DialogActions>
        <div className="flex pr-6 pb-4">
          <div className="mr-4">
            <Button
              onClick={() => logout(false)}
              color="primary"
              variant="outlined">
              {formatMessage(auth.logOut)}
            </Button>
          </div>
          <Button
            onClick={() => {
              return continueAsLoggedIn();
            }}
            color="primary"
            variant="contained">
            {formatMessage(auth.jatkaKirjautuneena)}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default SessionDialog;
