import { ReactElement } from "react";
import { SimpleButton } from "utils/lomakkeet";
import SButton, {
  SimpleButtonProps
} from "components/00-atoms/SimpleButton/SimpleButton";
import { ComponentType } from "graphHandling/components/componentType";
import { getCurrentProps } from "graphHandling/graphUtils";

export function renderSimpleButton(
  componentDefinition: SimpleButton
): ReactElement | null {
  if (componentDefinition?.name === ComponentType.SIMPLE_BUTTON) {
    const properties = getCurrentProps(
      componentDefinition
    ) as unknown as SimpleButtonProps;

    return componentDefinition.onClick ? (
      <SButton {...properties} onClick={componentDefinition.onClick} />
    ) : null;
  }

  return null;
}
