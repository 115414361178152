import { ReactElement } from "react";
import { ITextBox } from "utils/lomakkeet";
import { ComponentType } from "graphHandling/components/componentType";
import TextBox from "components/00-atoms/TextBox/TextBox";
import { getCurrentProps } from "graphHandling/graphUtils";
import { ITextBoxProps } from "components/00-atoms/TextBox/TextBox";

export function renderTextBox(
  componentDefinition: ITextBox
): ReactElement | null {
  if (componentDefinition?.name === ComponentType.TEXT_BOX) {
    if (componentDefinition.onChange) {
      const properties = getCurrentProps(
        componentDefinition
      ) as unknown as ITextBoxProps;

      return (
        <TextBox {...properties} onChange={componentDefinition.onChange} />
      );
    }
  }

  return null;
}
