import { Fragment, useEffect, useState } from "react";
import {
  find,
  toUpper,
  isEmpty,
  filter,
  map,
  concat,
  includes,
  path,
  pipe,
  groupBy,
  mergeDeepWithKey,
  pathEq,
  addIndex,
  length,
  prop
} from "ramda";
import { useIntl } from "react-intl";
import education from "../../../../i18n/definitions/education";
import { getRajoitteetFromMaarays } from "../../../../utils/rajoitteetUtils";
import Typography from "@mui/material/Typography";
import LisatiedotHtmlLupa from "components/03-templates/HtmlLupanakyma/Lisatiedot";
import rajoitteet from "i18n/definitions/rajoitteet";
import { PropTypes } from "prop-types";
import { PaikallisenTietovarastonAvain } from "enums";
import localForage from "localforage";
import { sortByLocale } from "../../../../helpers/html";
export default function OpetustaAntavatKunnatHtml({
  maaraykset,
  title,
  titleTag
}) {
  const intl = useIntl();
  const locale = toUpper(intl.locale);
  const [kunnat, setKunnat] = useState([]);
  const [maakuntaKunnat, setMaakuntaKunnat] = useState([]);
  const _titleTag = titleTag || "h3";
  /** Fetch kunnat and maaKuntaKunnat from storage */
  useEffect(() => {
    localForage
      .getItem(PaikallisenTietovarastonAvain.KUNNAT)
      .then(kunnat => setKunnat(kunnat))
      .catch(err => {
        console.error(err);
      });
    localForage
      .getItem(PaikallisenTietovarastonAvain.MAAKUNTAKUNNAT)
      .then(maakuntakunnat => setMaakuntaKunnat(maakuntakunnat))
      .catch(err => {
        console.error(err);
      });
  }, []);

  let kuntaMaaraykset = [];
  pipe(
    groupBy(x => x.koodiarvo),
    map(x => {
      let kuntaWithCombinedAliMaaraykset = {};
      map(kunta => {
        kuntaWithCombinedAliMaaraykset = mergeDeepWithKey(
          (k, l, r) => (k === "aliMaaraykset" ? concat(l, r) : r),
          kunta,
          kuntaWithCombinedAliMaaraykset
        );
      }, x);
      kuntaMaaraykset.push(kuntaWithCombinedAliMaaraykset);
    })
  )(
    filter(maarays => {
      return (
        pathEq(
          ["kohde", "tunniste"],
          "kunnatjoissaopetustajarjestetaan",
          maarays
        ) &&
        maarays.koodisto === "kunta" &&
        !includes("200", path(["koodiarvo"], maarays) || "")
      );
    }, maaraykset)
  );
  kuntaMaaraykset = sortByLocale(locale, kuntaMaaraykset);
  const lisatietomaarays = find(
    maarays =>
      pathEq(
        ["kohde", "tunniste"],
        "kunnatjoissaopetustajarjestetaan",
        maarays
      ) && maarays.koodisto === "lisatietoja",
    maaraykset
  );

  const opetustaJarjestetaanUlkomaillaLisatiedotMaaraykset = filter(
    maarays =>
      pathEq(
        ["kohde", "tunniste"],
        "kunnatjoissaopetustajarjestetaan",
        maarays
      ) &&
      includes("200", path(["koodiarvo"], maarays) || "") &&
      maarays.meta.arvo,
    maaraykset
  );

  return !isEmpty(kunnat) &&
    !isEmpty(maakuntaKunnat) &&
    (!isEmpty(kuntaMaaraykset) ||
      !isEmpty(opetustaJarjestetaanUlkomaillaLisatiedotMaaraykset)) ? (
    <div className="mt-4">
      <Typography component={_titleTag} variant={_titleTag}>
        {title || intl.formatMessage(education.opetustaAntavatKunnat)}
      </Typography>
      <ul className="list-disc mb-4 ml-8">
        {addIndex(map)((maarays, index) => {
          return (
            <Fragment key={`${maarays.koodiarvo}-${index}`}>
              <li className="leading-bulletList">
                {/* Ulkomaat */}
                {maarays.koodiarvo === "200"
                  ? path(["meta", "arvo"], maarays)
                  : // Muut kunnat
                    prop(
                      "nimi",
                      find(
                        maarays => prop("kieli", maarays) === toUpper(locale),
                        path(["koodi", "metadata"], maarays) || []
                      )
                    )}
              </li>
              {length(maarays.aliMaaraykset)
                ? getRajoitteetFromMaarays(
                    maarays.aliMaaraykset,
                    locale,
                    intl.formatMessage(rajoitteet.ajalla)
                  )
                : ""}
            </Fragment>
          );
        }, concat(kuntaMaaraykset, opetustaJarjestetaanUlkomaillaLisatiedotMaaraykset).filter(Boolean))}
      </ul>
      <LisatiedotHtmlLupa lisatietomaarays={lisatietomaarays} />
    </div>
  ) : null;
}

OpetustaAntavatKunnatHtml.propTypes = {
  maaraykset: PropTypes.array,
  title: PropTypes.string,
  titleTag: PropTypes.string
};
