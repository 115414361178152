import { CellFn } from "graphHandling/graphTypes";
import { koulutustyypitMap } from "utils/constants";
import { CellId } from "processes/CellId";
import { path } from "ramda";
import wizard from "i18n/definitions/wizard";

export const paivitaSeuraavaPainikkeenTila: CellFn = (
  storeFunctions,
  intl
): boolean => {
  const components = storeFunctions.readPath(["components"]);

  const koulutustyyppi = path(
    [CellId.INITIALIZE_KOULUTUSMUOTOVALIKKO, "properties", "value"],
    components
  );

  const onkoKoulutusmuotoValittu = koulutustyyppi !== undefined;

  const oid = path(
    [CellId.INITIALIZE_ORGANISAATIOVALIKKO, "properties", "values", 0, "value"],
    components
  );

  const onkoOrganisaatioValittu = !!oid;

  const onkoValittuLukiokoulutus = koulutustyyppi === koulutustyypitMap.LUKIO;

  const locale = path(
    [CellId.INITIALIZE_LOKALISAATIOVALIKKO, "properties", "value"],
    components
  );

  const viimeisinPaalupa = storeFunctions.readPath([
    "custom",
    "viimeisinPaalupa"
  ]);
  const avoinMuutospyynto = storeFunctions.readPath([
    "custom",
    "avoinMuutospyynto"
  ]);

  // Painike on disabloitu, kun jokin seuraavista ehdoista on tosi.
  // * Vähintään joko koulutusmuoto tai organisaatio on valitsematta.
  const ehto1 = !(onkoKoulutusmuotoValittu && onkoOrganisaatioValittu);
  // * Koulutusmuodoksi on valittu lukiokoulutus, mutta asian kieli (fi / sv)
  // on valitsematta.
  const ehto2 = onkoValittuLukiokoulutus && !locale;
  // * TUVA-lupaa ei ole olemassa, kuten ei myöskään päälupaa, johon TUVA-luvan
  // tulisi pohjautua.
  const ehto3 = !avoinMuutospyynto && !viimeisinPaalupa;
  // * TUVA-lupa on olemassa ja sitä on mahdollista täydentää. Tällöin
  // näytetään erillinen painike.
  const ehto4 = !!avoinMuutospyynto;

  const isButtonDisabled = ehto1 || ehto2 || ehto3 || ehto4;

  storeFunctions.updateGraph(
    ["components", CellId.INITIALIZE_SEURAAVA_PAINIKE, "properties"],
    {
      isDisabled: isButtonDisabled,
      // Jos painike ei ole disabloitu, varmistetaan että siinä on oikea teksti.
      text: intl?.formatMessage(wizard.next)
    }
  );

  return true;
};
