import { Fragment, ReactElement } from "react";
import { FaPlus } from "react-icons/fa";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import { ComponentEvent } from "graphHandling/graphTypes";
import { IconButton } from "@mui/material";

export enum Color {
  PRIMARY = "primary"
}

enum Size {
  LARGE = "large"
}

const defaultProps = {
  buttonStyles: {},
  disabled: false,
  icon: null,
  iconStyles: {},
  isDisabled: false,
  isHidden: false,
  isReadOnly: false,
  styleAsLink: false,
  text: "",
  shouldHaveFocusAt: false
};

export interface IconButtonProps {
  ariaLabel: string;
  fullAnchor: string;
  buttonStyles?: Record<string, number | string>;
  color?: Color;
  icon?: string | null;
  iconStyles?: object;
  id: string;
  isDisabled?: boolean;
  isHidden?: boolean;
  isReadOnly?: boolean;
  size?: Size;
}

interface IconButtonPropsAndEvents extends IconButtonProps {
  onClick: ComponentEvent;
}

const SimpleIconButton = ({
  id,
  ariaLabel,
  color = Color.PRIMARY,
  icon = defaultProps.icon,
  fullAnchor,
  isDisabled = defaultProps.isDisabled,
  isHidden = defaultProps.isHidden,
  isReadOnly = defaultProps.isReadOnly,
  onClick,
  size = Size.LARGE,
  buttonStyles = defaultProps.buttonStyles,
  iconStyles = defaultProps.iconStyles
}: IconButtonPropsAndEvents): ReactElement => {
  return (
    <Fragment>
      {!isReadOnly && !isHidden && (
        <IconButton
          id={id}
          aria-label={ariaLabel}
          color={color}
          disabled={isDisabled}
          onClick={() => {
            onClick({ changeProps: { fullAnchor } });
          }}
          size={size}
          style={buttonStyles}>
          {icon === "FaPlus" && <FaPlus style={iconStyles} />}
          {icon === "ClearIcon" && <ClearIcon style={iconStyles} />}
          {icon === "Delete" && <DeleteIcon style={iconStyles} />}
        </IconButton>
      )}
    </Fragment>
  );
};

export default SimpleIconButton;
