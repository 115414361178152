import { ChangeObject } from "koodistodatanTyypit";
import { Lupamaarays } from "Lupa";
import { path, sortBy, compose, toLower, clone } from "ramda";
import { getLocalizedProperty } from "services/lomakkeet/utils";

interface LupamaaraysSort extends Lupamaarays {
  kaytettavakaannos?: string;
}

interface KohdistuvatRajoitteet {
  changeObjects: Array<ChangeObject>;
}

const TEMPORARY_SORTER = "kaytettavakaannos";
/**
 * Sorts lupamaarays objects into alphabetical order
 * @param locale - string of locale "FI,SE,EN"
 * @param maaraykset - Array of backend change objects
 * @returns - cloned version of change objects sorted by translated text in ascending order
 */
export function sortByLocale(
  locale: string,
  maaraykset: Array<Lupamaarays>
): Array<Lupamaarays> {
  let kohdistuvatMaaraykset = clone(maaraykset) as Array<LupamaaraysSort>;
  let canBeSorted = true;
  if (kohdistuvatMaaraykset) {
    kohdistuvatMaaraykset.every(m => {
      if (m.koodi && m.koodisto !== "opetustehtava") {
        m.kaytettavakaannos = getLocalizedProperty(
          m.koodi.metadata,
          locale,
          "nimi"
        );
        return true;
      } else {
        canBeSorted = false;
        return false;
      }
    });

    if (!canBeSorted) {
      return kohdistuvatMaaraykset;
    }
    const tempPath = path([TEMPORARY_SORTER]);
    const sortFunction = compose(
      toLower,
      tempPath as unknown as (...args: unknown[]) => string
    );
    kohdistuvatMaaraykset = sortBy(sortFunction)(
      kohdistuvatMaaraykset || []
    ) as Array<LupamaaraysSort>;

    kohdistuvatMaaraykset.forEach((obj: LupamaaraysSort) => {
      delete obj[TEMPORARY_SORTER];
    }) as unknown as Array<LupamaaraysSort>;
  }
  return kohdistuvatMaaraykset;
}

/**
 * Sorts restriction related change objects into alphabetical order
 * @param locale - string of locale "FI,SE,EN"
 * @param rajoitteet - Array of backend change objects representing restrictions
 * @returns - cloned version of backend change objects sorted by translated text in ascending order
 */

export function sortEsikatseluRajoitteet(
  rajoitteet: Array<never>
): Array<unknown> {
  const kohdistuvatRajoitemuutosobjektit = clone(rajoitteet) as Array<unknown>;
  if (kohdistuvatRajoitemuutosobjektit) {
    const pAvain = Object.keys(kohdistuvatRajoitemuutosobjektit)[0];

    const arr = path(
      [pAvain],
      kohdistuvatRajoitemuutosobjektit
    ) as KohdistuvatRajoitteet;

    if (arr && Array.isArray(arr.changeObjects)) {
      arr.changeObjects.forEach((rajoite: ChangeObject) => {
        if (Array.isArray(rajoite.properties.value)) {
          const tempPath = path(["label"]);
          const sortFunction = compose(
            toLower,
            tempPath as unknown as (...args: unknown[]) => string
          );
          const rajoitePath = path(["properties", "value"]);
          rajoite.properties.value = sortBy(sortFunction)(
            (rajoitePath(rajoite) as readonly unknown[]) || []
          );
        }
      });
    }
  }
  return kohdistuvatRajoitemuutosobjektit;
}
