import { memo } from "react";
import PropTypes from "prop-types";
import { filter, find, path, pathEq, prop, includes } from "ramda";
import equal from "react-fast-compare";
import AsianumeroYmsKentat from "../lomakeosiot/0-AsianumeroYmsKentat";
import { useChangeObjectsByAnchorWithoutUnderRemoval } from "stores/muutokset";
import { useIntl } from "react-intl";
import Opetuskieli from "../lomakeosiot/1-Opetuskieli";
import common from "i18n/definitions/common";
import OppilaitoksenKoulutustehtava from "../lomakeosiot/OppilaitoksenKoulutustehtava";
import ErityinenKoulutustehtava from "../lomakeosiot/ErityinenKoulutustehtava";
import education from "../../../../i18n/definitions/education";
import MuutEhdot from "../lomakeosiot/4-MuutEhdot";
import OppilaitoksenTarkoitus from "../lomakeosiot/OppilaitoksenTarkoitus";
import FormTitle from "components/00-atoms/FormTitle";
import vapaaSivistystyo from "i18n/definitions/vapaaSivistystyo";
import Sopimuskunnat from "../lomakeosiot/Sopimuskunnat";
import Tooltip from "components/02-organisms/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import OppilaitoksenAlueellisuusJaValtakunnallisuus from "../lomakeosiot/OppilaitoksenAlueellisuusJaValtakunnallisuus";
import { oppilaitostyypit } from "../../../../utils/constants";

function filterBySectionId(sectionId, maaraykset = []) {
  return filter(pathEq(["meta", "sectionId"], sectionId), maaraykset);
}

function filterByTunniste(tunniste, maaraykset = []) {
  return filter(pathEq(["kohde", "tunniste"], tunniste), maaraykset);
}

/**
 * Tämä lupanäkymä sisältää kaikki VST-lomakkeen osiot soveltuen siksi
 * erinomaisesti myös esikatselunäkymäksi.
 * @param {*} param0
 */
const LupanakymaA = memo(
  ({ isPreviewModeOn, lisaksi, maaraykset, oppilaitostyyppi }) => {
    const intl = useIntl();
    const paattymispvm = path(
      ["properties", "value"],
      find(
        cObj => cObj.anchor === "paatoksentiedot.paattymispaivamaara.A",
        path(
          ["0"],
          useChangeObjectsByAnchorWithoutUnderRemoval({
            anchor: "paatoksentiedot"
          })
        ) || []
      )
    );

    const asianumeroYmsClasses = isPreviewModeOn
      ? "md:w-1/2 xxl:w-1/3 pr-6 mb-6 mt-3"
      : "md:w-1/2 xxl:w-1/3 my-12";

    const toimintaaaluemaaraykset = filterByTunniste("kunnat", maaraykset);
    const toolTipTranslation = includes(
      "yhteisetpalvelut@opintopolku.fi",
      intl.formatMessage(vapaaSivistystyo.oppilaitoksenNimiJaSijaintiInfo)
    ) ? (
      <div>
        {
          intl
            .formatMessage(vapaaSivistystyo.oppilaitoksenNimiJaSijaintiInfo)
            .split("yhteisetpalvelut@opintopolku.fi")[0]
        }
        <p className="text-green-500">yhteisetpalvelut@opintopolku.fi</p>
      </div>
    ) : (
      intl.formatMessage(vapaaSivistystyo.oppilaitoksenNimiJaSijaintiInfo)
    );

    return (
      <form className={`bg-white ${isPreviewModeOn ? "" : ""}`}>
        {!(isPreviewModeOn && !paattymispvm) && (
          <div className={asianumeroYmsClasses}>
            <AsianumeroYmsKentat isPreviewModeOn={isPreviewModeOn} />
          </div>
        )}

        <div className="mb-8 mt-4">
          <FormTitle
            level={isPreviewModeOn ? 3 : 2}
            title={intl.formatMessage(
              vapaaSivistystyo.yllapitajanNimiJaKotipaikka
            )}
          />
          {prop("value", lisaksi.yllapitaja)}
        </div>

        <div className="mb-8 mt-8">
          <FormTitle
            level={isPreviewModeOn ? 3 : 2}
            title={intl.formatMessage(
              vapaaSivistystyo.oppilaitoksenNimiJaSijainti
            )}
          />
          <div className="flex items-center">
            {prop("value", lisaksi.oppilaitos)}{" "}
            <div className="ml-4">
              <Tooltip
                tooltip={toolTipTranslation}
                trigger="click"
                placement="right">
                <HelpIcon color="action" className="blue-theme-tooltip-icon" />
              </Tooltip>
            </div>
          </div>
        </div>

        {/* Osio näytetään vain liikunnan koulutuskeskuksille. */}
        {oppilaitostyyppi === "5" && (
          <OppilaitoksenAlueellisuusJaValtakunnallisuus
            isPreviewModeOn={isPreviewModeOn}
            maaraykset={filterByTunniste(
              "oppilaitoksentoimintaalue",
              maaraykset
            )}
          />
        )}

        {/* Sopimuskunnat tulee näyttää vain kansalaisopistoille (2).  */}
        {oppilaitostyyppi === "2" && (
          <Sopimuskunnat
            isPreviewModeOn={isPreviewModeOn}
            maaraykset={filterBySectionId(
              "toimintaalue",
              toimintaaaluemaaraykset
            )}
            sectionId={"toimintaalue"}
            title={intl.formatMessage(common.VSTLupaSectionTitleMunicipality)}
          />
        )}

        <Opetuskieli
          isPreviewModeOn={isPreviewModeOn}
          maaraykset={filterByTunniste("kielet", maaraykset)}
          sectionId={"opetuskielet"}
          title={intl.formatMessage(common.opetuskieli)}
        />

        {/* Näytetään Oppilaitoksen tarkoitus -osio vain muille oppilaitoksille (6). */}
        {oppilaitostyyppi === "6" && (
          <OppilaitoksenTarkoitus isPreviewModeOn={isPreviewModeOn} />
        )}

        <OppilaitoksenKoulutustehtava
          maaraykset={filterByTunniste("koulutustehtava", maaraykset)}
          isPreviewModeOn={isPreviewModeOn}
        />

        {/* Erityinen koulutustehtävät tulee näyttää muille kuin Kansalaisopistoille, kesäyliopistoille tai opintokeskuksille.  */}
        {oppilaitostyyppi &&
          ![
            oppilaitostyypit.KANSALAISOPISTO,
            oppilaitostyypit.OPINTOKESKUS,
            oppilaitostyypit.KESAYLIOPISTO
          ].includes(oppilaitostyyppi) && (
            <ErityinenKoulutustehtava
              maaraykset={filterByTunniste(
                "erityinenkoulutustehtava",
                maaraykset
              )}
              isPreviewModeOn={isPreviewModeOn}
              oppilaitostyyppi={oppilaitostyyppi}
            />
          )}

        <MuutEhdot
          code="4"
          isPreviewModeOn={isPreviewModeOn}
          maaraykset={filterByTunniste("muut", maaraykset)}
          sectionId={"muutEhdot"}
          title={intl.formatMessage(education.muutEhdotTitle)}
        />
      </form>
    );
  },
  (cp, np) => {
    return equal(cp, np);
  }
);

LupanakymaA.displayName = "LupanakymaA";

LupanakymaA.propTypes = {
  isPreviewModeOn: PropTypes.bool,
  koulutustyyppi: PropTypes.string,
  maaraykset: PropTypes.array,
  OpetustaAntavatKunnatJSX: PropTypes.func,
  oppilaitostyyppi: PropTypes.string,
  lisaksi: PropTypes.object
};

export default LupanakymaA;
