import { Fragment } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import Loading from "scenes/Loading";
import { useIntl } from "react-intl";
import { localizeRouteKey } from "utils/common";
import { AppRoute } from "routes/index";
import { PropTypes } from "prop-types";

const Jarjestajat = ({
  luvat,
  koulutusmuoto,
  Jarjestajaluettelo,
  paasivunOtsikko,
  pageNo,
  setPageNo,
  searchBy,
  setSearchBy,
  vstTyypit,
  vstOppilaitostyyppiFilter,
  setVstOppilaitostyyppiFilter,
  vstTypeOptions
}) => {
  const intl = useIntl();
  const { locale } = intl;
  const koulutusmuotoUrl = localizeRouteKey(
    locale,
    AppRoute[koulutusmuoto.pascalCase],
    intl
  );

  return (
    <Fragment>
      <Helmet htmlAttributes={{ lang: locale }}>
        <title>{paasivunOtsikko} - Oiva</title>
      </Helmet>

      <BreadcrumbsItem to={koulutusmuotoUrl}>{paasivunOtsikko}</BreadcrumbsItem>

      {luvat.isLoading === false && !luvat.isErroneous && (
        <Jarjestajaluettelo
          koulutusmuoto={koulutusmuoto}
          luvat={luvat.data}
          pageNo={pageNo}
          setPageNo={setPageNo}
          searchBy={searchBy}
          setSearchBy={setSearchBy}
          vstTyypit={vstTyypit}
          vstOppilaitostyyppiFilter={vstOppilaitostyyppiFilter}
          setVstOppilaitostyyppiFilter={setVstOppilaitostyyppiFilter}
          vstTypeOptions={vstTypeOptions}
        />
      )}

      {luvat.isLoading && <Loading />}
    </Fragment>
  );
};

Jarjestajat.propTypes = {
  luvat: PropTypes.object,
  Jarjestajaluettelo: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  koulutusmuoto: PropTypes.object,
  paasivunOtsikko: PropTypes.string,
  pageNo: PropTypes.number,
  setPageNo: PropTypes.func,
  searchBy: PropTypes.object,
  setSearchBy: PropTypes.func,
  vstTyypit: PropTypes.array,
  vstOppilaitostyyppiFilter: PropTypes.any,
  setVstOppilaitostyyppiFilter: PropTypes.any,
  vstTypeOptions: PropTypes.any
};

export default Jarjestajat;
