import {
  append,
  drop,
  mapObjIndexed,
  prop,
  map,
  find,
  compose,
  endsWith,
  propEq,
  flatten,
  values,
  reject,
  isNil
} from "ramda";
import localForage from "localforage";
import { __ } from "i18n-for-browser";
import { createAlimaarayksetBEObjects } from "helpers/rajoitteetHelper";
import {
  createMaarayksiaVastenLuodutRajoitteetBEObjects,
  getRajoitteetByValueStartsWith
} from "utils/rajoitteetUtils";
import { getMaarayksetByTunniste } from "helpers/lupa";
import { luoMuutosobjektitLisatietokentasta } from "helpers/lisatiedot";
import { PaikallisenTietovarastonAvain } from "enums";

export const defineBackendChangeObjects = async (
  changeObjects = {},
  maaraystyypit,
  lupaMaaraykset,
  kohteet
) => {
  const { rajoitteetByRajoiteId } = changeObjects;
  const opetuksenJarjestamismuodot = await localForage.getItem(
    PaikallisenTietovarastonAvain.OPETUKSEN_JARJESTAMISMUODOT
  );
  const kohde = find(propEq("tunniste", "opetuksenjarjestamismuoto"), kohteet);
  const maaraystyyppi = find(propEq("tunniste", "OIKEUS"), maaraystyypit);
  const maaraykset = await getMaarayksetByTunniste(
    kohde.tunniste,
    lupaMaaraykset
  );
  const opetuksenJarjestamismuotoChangeObjs = map(
    jarjestamismuoto => {
      const rajoitteetByRajoiteIdAndKoodiarvo = getRajoitteetByValueStartsWith(
        `${jarjestamismuoto.koodiarvo}`,
        rajoitteetByRajoiteId
      );
      const changeObj = find(
        compose(
          endsWith(`${jarjestamismuoto.koodiarvo}.valinta`),
          prop("anchor")
        ),
        changeObjects.opetuksenJarjestamismuodot
      );
      const kuvausChangeObj = find(
        compose(
          endsWith(`${jarjestamismuoto.koodiarvo}.kuvaus.A`),
          prop("anchor")
        ),
        changeObjects.opetuksenJarjestamismuodot
      );

      const muutosobjekti = changeObj
        ? {
            generatedId: `opetuksenJarjestamismuoto-${Math.random()}`,
            kohde,
            koodiarvo: jarjestamismuoto.koodiarvo,
            koodisto: jarjestamismuoto.koodisto.koodistoUri,
            maaraystyyppi,
            meta: reject(isNil, {
              changeObjects: [
                changeObj,
                kuvausChangeObj,
                values(rajoitteetByRajoiteIdAndKoodiarvo)
              ].filter(Boolean),
              kuvaus: kuvausChangeObj ? kuvausChangeObj.properties.value : null
            }),
            tila: changeObj.properties.isChecked ? "LISAYS" : "POISTO"
          }
        : null;

      // Muodostetaan tehdyistä rajoittuksista objektit backendiä varten.
      // Linkitetään ensimmäinen rajoitteen osa yllä luotuun muutokseen ja
      // loput toisiinsa "alenevassa polvessa".
      const alimaaraykset = muutosobjekti
        ? values(
            mapObjIndexed(asetukset => {
              return createAlimaarayksetBEObjects(
                kohteet,
                maaraystyypit,
                muutosobjekti,
                drop(2, asetukset)
              );
            }, rajoitteetByRajoiteIdAndKoodiarvo)
          )
        : [];

      return [muutosobjekti, alimaaraykset];
    },
    append(
      {
        koodiarvo: 0,
        koodisto: { koodistoUri: "opetuksenjarjestamismuoto" },
        kuvaus: __("education.eiSisaOppilaitosTaiKotikoulumuotoinen")
      },
      opetuksenJarjestamismuodot
    )
  ).filter(Boolean);

  // Lisätietokentän muutosten käsittely
  const lisatietomuutokset = await luoMuutosobjektitLisatietokentasta(
    kohde,
    changeObjects.opetuksenJarjestamismuodot
  );

  const maarayksiaVastenLuodutRajoitteet =
    createMaarayksiaVastenLuodutRajoitteetBEObjects(
      maaraykset,
      rajoitteetByRajoiteId,
      kohteet,
      maaraystyypit,
      kohde
    );

  return flatten([
    opetuksenJarjestamismuotoChangeObjs,
    lisatietomuutokset,
    maarayksiaVastenLuodutRajoitteet
  ]).filter(Boolean);
};
