import { createAlimaarayksetBEObjects } from "helpers/rajoitteetHelper";
import {
  append,
  assocPath,
  compose,
  concat,
  endsWith,
  filter,
  find,
  findIndex,
  flatten,
  head,
  includes,
  isNil,
  length,
  map,
  mapObjIndexed,
  max,
  not,
  path,
  pathEq,
  prop,
  propEq,
  reject,
  uniq,
  values
} from "ramda";
import { getMaarayksetByTunniste } from "helpers/lupa";
import { luoMuutosobjektitLisatietokentasta } from "helpers/lisatiedot";
import { PaikallisenTietovarastonAvain } from "enums";
import localForage from "localforage";
import { getRajoitteetByValue, rajoiteHasValue } from "utils/rajoitteetUtils";

/**
 * Palauttaa taulukollisen backend-muotoisia muutosobjekteja.
 * @param {object} changeObjects
 * @param {object} kohde
 * @param {array} maaraystyypit
 * @param {array} lupaMaaraykset
 */
export async function defineBackendChangeObjects(
  changeObjects = {},
  kohde,
  maaraystyypit,
  lupaMaaraykset,
  kohteet,
  tunniste,
  sectionId = "toimintaalue"
) {
  const {
    quickFilterChanges = [],
    changesByProvince,
    perustelut,
    rajoitteetByRajoiteId
  } = changeObjects;

  const maaraystyyppi = find(propEq("tunniste", "VELVOITE"), maaraystyypit);

  /**
   * Noudetaan toiminta-alueeseen liittyvät määräykset. Määräysten uuid-arvoja
   * tarvitaan lupaan kuuluvien alueiden poistamisen yhteydessä.
   */
  const maaraykset = await getMaarayksetByTunniste(tunniste, lupaMaaraykset);
  const maakuntakunnat = await localForage.getItem(
    PaikallisenTietovarastonAvain.MAAKUNTAKUNNAT
  );

  /**
   * PIKAVALINTOJEN LÄPIKÄYNTI
   *
   * Käydään läpi pikavalinnat eli nuts1-koodiston koodiarvoja FI1 ja FI2
   * vastaavat muutosobjektit muodostaen niistä backend-muotoiset muutos-
   * objektit.
   */
  let quickFilterBEchangeObjects =
    map(changeObj => {
      /**
       * Jos kaikki maakunnat ja kunnat on valittuna, on backendille lähetettävä
       * muutosobjekti nuts1-koodiston arvolla FI1. Mikäli yksikään maakunnista
       * ei ole valittuna eli toiminta-aluetta ei ole määritelty, on backendille
       * lähetettävä muutosobjekti nuts1-koodiston arvolla FI2.
       */
      const { isChecked } = changeObj.properties;
      const { koodiarvo } = changeObj.properties.metadata;

      let muutos = {
        tila: isChecked ? "LISAYS" : "POISTO",
        meta: {
          changeObjects: perustelut,
          perusteluteksti: [
            {
              value:
                perustelut && perustelut.length > 0
                  ? perustelut[0].properties.value
                  : ""
            }
          ],
          sectionId
        },
        kohde,
        maaraystyyppi,
        koodisto: "nuts1",
        koodiarvo
      };

      if (!isChecked) {
        /**
         * Mikäli kyseessä on poisto, lisätään muutosobjektiin määräyksen uuid.
         */
        const maarays = find(propEq("koodiarvo", koodiarvo), maaraykset);
        // Varmistetaan vielä, että määräys on olemassa.
        if (maarays) {
          muutos.maaraysUuid = maarays.uuid;
        } else {
          console.warn("Unable to find maaraysUuid for ", koodiarvo);
        }
      }

      return muutos;
    }, quickFilterChanges) || [];

  /**
   * YKSITTÄISTEN MAAKUNTIEN JA KUNTIEN LÄPIKÄYNTI
   *
   * Käyttäjä on voinut tehdä monenlaisia muutoksia toiminta-alueeseen. Käydään
   * läpi pikavalintojen - eli nuts1-koodiston - ulkopuolelle jäävät muutokset.
   */
  const muutosFI1 = find(
    propEq("koodiarvo", "FI1"),
    quickFilterBEchangeObjects
  );

  const provinceChangeObjects = flatten(values(changesByProvince));

  const provinceBEchangeObjects = {};

  /**
   * Etsitään ne maakunnat, joihin on kohdistunut muutos.
   */
  const provinces = filter(maakunta => {
    return !!find(
      pathEq(["properties", "metadata", "koodiarvo"], maakunta.koodiarvo),
      provinceChangeObjects
    );
  }, maakuntakunnat);

  // YKSITTÄISTEN MAAKUNTIEN JA KUNTIEN POISTAMINEN
  const yksittaisetMaaraykset = filter(
    compose(not, propEq("koodisto", "nuts1")),
    maaraykset
  );

  provinceBEchangeObjects.poistot = map(maarays => {
    // Selvitetään, ollaanko alue aikeissa poistaa luvan piiristä.
    const isMaakunta = maarays.koodiarvo.length === 2;
    const maakunta = !isMaakunta
      ? head(
          filter(province => {
            return find(
              propEq("koodiarvo", maarays.koodiarvo),
              province.kunnat
            );
          }, provinces)
        )
      : null;
    const changeObj = find(
      pathEq(["properties", "metadata", "koodiarvo"], maarays.koodiarvo),
      provinceChangeObjects
    );
    const provinceChangeObj = maakunta
      ? find(
          pathEq(["properties", "metadata", "koodiarvo"], maakunta.koodiarvo),
          provinceChangeObjects
        )
      : null;
    const isProvinceGoingToBeFullyActive = provinceChangeObj
      ? provinceChangeObj.properties.isChecked &&
        !provinceChangeObj.properties.isIndeterminate
      : false;

    const isGoingToBeRemoved =
      isProvinceGoingToBeFullyActive ||
      (changeObj &&
        ((isMaakunta &&
          (changeObj.properties.isIndeterminate ||
            !changeObj.properties.isChecked)) ||
          (!isMaakunta && !changeObj.properties.isChecked))) ||
      (muutosFI1 && muutosFI1.tila === "LISAYS");

    if (isGoingToBeRemoved) {
      // Alue täytyy poistaa. Luodaan backend-muotoinen muutosobjekti.
      return {
        tila: "POISTO",
        meta: {
          changeObjects: perustelut,
          perusteluteksti: [
            {
              value:
                perustelut && perustelut.length > 0
                  ? perustelut[0].properties.value
                  : ""
            }
          ]
        },
        kohde,
        koodisto: maarays.koodisto,
        koodiarvo: maarays.koodiarvo,
        maaraystyyppi,
        maaraysUuid: maarays.uuid
      };
    }
    return null;
  }, yksittaisetMaaraykset).filter(Boolean);

  const getMuutosObjektit = (rajoitteetByRajoiteId, changeObj) => {
    const rajoitteetByRajoiteIdAndKoodiarvo = getRajoitteetByValue(
      changeObj.properties.metadata.koodiarvo,
      rajoitteetByRajoiteId
    );

    const kuntamuutosobjekti = {
      generatedId: `kunta-${Math.random()}`,
      tila: "LISAYS",
      meta: {
        changeObjects: concat(
          perustelut || [],
          values(rajoitteetByRajoiteIdAndKoodiarvo)
        ),
        perusteluteksti: [
          {
            value:
              perustelut && perustelut.length > 0
                ? perustelut[0].properties.value
                : ""
          }
        ],
        sectionId
      },
      kohde,
      koodisto: "kunta",
      koodiarvo: changeObj.properties.metadata.koodiarvo,
      maaraystyyppi
    };
    // Muodostetaan tehdyistä rajoituksista objektit backendiä varten.
    // Linkitetään ensimmäinen rajoitteen osa yllä luotuun muutokseen ja
    // loput toisiinsa "alenevassa polvessa".
    const alimaaraykset = values(
      mapObjIndexed(asetukset => {
        return createAlimaarayksetBEObjects(
          kohteet,
          maaraystyypit,
          kuntamuutosobjekti,
          asetukset
        );
      }, rajoitteetByRajoiteIdAndKoodiarvo)
    );
    return [kuntamuutosobjekti, alimaaraykset];
  };

  /**
   * YKSITTÄISTEN MAAKUNTIEN JA KUNTIEN LISÄÄMINEN
   *
   * Yksittäiset maakunnat lisätään vain siinä tapauksessa, jos nust1-koodiston
   * koodiarvon FI1-mukaista muutosobjektia ei olla lisäämässä tai poistamassa. FI1 kattaa
   * koko maan kaikki maakunnat - pois lukien Ahvenanmaan maakunta - ja niinpä
   * kyseisen koodiarvon mukaisen muutosobjektin ollessa backendille
   * lähetettävien muutosobjektien joukossa eivät yksittäiset maakunta- ja
   * kuntalisäykset ole tarpeellisia.
   **/

  // Jos nuts-1 koodiston koodiarvon FI1-mukainen muutosobjekti ollaan poistamassa
  // luodaan lisäysobjektit kaikista maakunnista ja kunnista joita ei ole poistettu
  if (muutosFI1 && muutosFI1.tila === "POISTO") {
    const filteredMaakuntaKunnat = filter(
      m => m.koodiarvo !== "99" && m.koodiarvo !== "21",
      maakuntakunnat
    );
    // MAAKUNTIEN LISÄYSOBJEKTIEN LUOMINEN
    const addedProvinceChangeObjects = reject(isNil)(
      map(maakunta => {
        return includes(
          maakunta.koodiarvo,
          map(province => province.koodiarvo, provinces)
        )
          ? null
          : {
              tila: "LISAYS",
              kohde,
              koodisto: "maakunta",
              koodiarvo: maakunta.koodiarvo,
              maaraystyyppi
            };
      }, filteredMaakuntaKunnat)
    );

    // KUNTIEN LISÄYSOBJEKTIEN LUOMINEN
    const provincesNotAdded = filter(
      maakuntakunta =>
        !includes(
          maakuntakunta.koodiarvo,
          map(province => province.koodiarvo, addedProvinceChangeObjects)
        ),
      filteredMaakuntaKunnat
    );

    const addedMunicipalityChangeObjects = flatten(
      map(province => {
        return reject(isNil)(
          map(kunta => {
            return includes(
              kunta.koodiarvo,
              map(
                co => path(["properties", "metadata", "koodiarvo"], co),
                provinceChangeObjects
              )
            )
              ? null
              : {
                  tila: "LISAYS",
                  kohde,
                  koodisto: "kunta",
                  koodiarvo: kunta.koodiarvo,
                  maaraystyyppi
                };
          }, province.kunnat)
        );
      }, provincesNotAdded)
    );
    provinceBEchangeObjects.lisaykset = append(
      concat(addedProvinceChangeObjects, addedMunicipalityChangeObjects),
      provinceBEchangeObjects.lisaykset
    );
  } else if (!muutosFI1 || (muutosFI1 && muutosFI1.tila !== "LISAYS")) {
    /**
     * Käydään muutoksia sisältävät maakunnat ja niiden kunnat läpi
     * tarkoituksena löytää kunnat, jotka on lisättävä lupaan.
     */
    provinceBEchangeObjects.lisaykset = flatten(
      uniq(
        map(changeObj => {
          const isMaakunta =
            changeObj.properties.metadata.koodiarvo.length === 2;
          const maakunta = !isMaakunta
            ? head(
                filter(province => {
                  return find(
                    propEq(
                      "koodiarvo",
                      changeObj.properties.metadata.koodiarvo
                    ),
                    province.kunnat
                  );
                }, maakuntakunnat)
              )
            : find(
                propEq("koodiarvo", changeObj.properties.metadata.koodiarvo),
                maakuntakunnat
              );
          const maakuntaMaarays = maakunta
            ? find(
                maarays =>
                  maarays.koodisto === "maakunta" &&
                  maarays.koodiarvo === maakunta.koodiarvo,
                maaraykset
              )
            : null;

          const maakuntaChangeObj = find(
            pathEq(["properties", "metadata", "koodiarvo"], maakunta.koodiarvo),
            provinceChangeObjects
          );
          let muutosobjektit = null;

          if (maakuntaChangeObj && maakuntaChangeObj.properties.isChecked) {
            if (!maakuntaChangeObj.properties.isIndeterminate) {
              /**
               * Jos Maakunnan kaikkia kuntia ollaan lisäämässä lupaan, muodostetaan
               * vain yksi backend-muotoinen muutosobjekti. Se kertoo, että koko
               * maakunta ollaan lisäämässä luvan piiriin.
               */
              muutosobjektit = append(
                {
                  tila: "LISAYS",
                  meta: {
                    changeObjects: perustelut,
                    perusteluteksti: [
                      {
                        value:
                          perustelut && perustelut.length > 0
                            ? perustelut[0].properties.value
                            : ""
                      }
                    ]
                  },
                  kohde,
                  koodisto: "maakunta",
                  koodiarvo: maakunta.koodiarvo,
                  maaraystyyppi
                },
                muutosobjektit
              );
            } else {
              muutosobjektit = [];
              if (
                changeObj.anchor.indexOf(
                  ".kunnat." + changeObj.properties.metadata.koodiarvo
                ) !== -1
              ) {
                const kuntaChangeObj = find(
                  pathEq(
                    ["properties", "metadata", "koodiarvo"],
                    changeObj.properties.metadata.koodiarvo
                  ),
                  provinceChangeObjects
                );
                const kuntaMaarays = find(
                  maarays =>
                    maarays.koodisto === "kunta" &&
                    maarays.koodiarvo ===
                      changeObj.properties.metadata.koodiarvo,
                  maaraykset
                );

                if (
                  !kuntaMaarays &&
                  ((kuntaChangeObj && kuntaChangeObj.properties.isChecked) ||
                    (!!maakuntaMaarays && !kuntaChangeObj))
                ) {
                  muutosobjektit = getMuutosObjektit(
                    rajoitteetByRajoiteId,
                    changeObj
                  );
                }
              }
            }
          } else if (!isMaakunta && changeObj.properties.isChecked) {
            /**
             * Muodostetaan muutosobjektit tilanteessa, jossa maakuntavalintaan
             * ei ole kohdistunut muutosta.
             */
            muutosobjektit = getMuutosObjektit(
              rajoitteetByRajoiteId,
              changeObj
            );
          }
          return muutosobjektit;
        }, provinceChangeObjects).filter(Boolean)
      )
    ).filter(Boolean);

    provinceBEchangeObjects.lisaykset = map(beChangeObj => {
      if (beChangeObj.koodisto == "maakunta") {
        const rajoitteetByRajoiteIdAndKoodiarvo = getRajoitteetByValue(
          beChangeObj.koodiarvo,
          rajoitteetByRajoiteId
        );

        if (Object.keys(rajoitteetByRajoiteIdAndKoodiarvo).length) {
          beChangeObj.generatedId = `maakunta-${Math.random()}`;
          beChangeObj.meta.changeObjects = concat(
            perustelut || [],
            values(rajoitteetByRajoiteIdAndKoodiarvo) || []
          );

          let alimaaraykset = values(
            mapObjIndexed(asetukset => {
              return createAlimaarayksetBEObjects(
                kohteet,
                maaraystyypit,
                beChangeObj,
                asetukset
              );
            }, rajoitteetByRajoiteIdAndKoodiarvo)
          );

          if (alimaaraykset.length) {
            alimaaraykset = filter(n => {
              if (n.koodiarvo != beChangeObj.koodiarvo) return true;
            }, alimaaraykset[0]);
            return [beChangeObj, alimaaraykset];
          }
        } else {
          return beChangeObj;
        }
      } else {
        return beChangeObj;
      }
    }, provinceBEchangeObjects.lisaykset);
  }

  /**
   * Jos opetusta järjestetään Suomen ulkopuolella, on backendille lähetettävä
   * tiedot siitä.
   */
  const changeObjUlkomaaCheckbox = find(
    compose(endsWith(".200.valintaelementti"), prop("anchor")),
    changeObjects.ulkomaa || []
  );

  const changeObjUlkomaaTextBoxes = filter(
    compose(endsWith(".kuvaus"), prop("anchor")),
    changeObjects.ulkomaa || []
  );

  const ulkomaihinLiittyvatMaaraykset = filter(
    m =>
      propEq("koodisto", "kunta", m) &&
      propEq("koodiarvo", "200", m) &&
      path(["meta", "arvo"], m),
    maaraykset
  );

  const kuntamaaraykset = filter(
    m => propEq("koodisto", "kunta", m) && !propEq("koodiarvo", "200", m),
    maaraykset
  );

  const maakuntamaaraukset = filter(
    m => propEq("koodisto", "maakunta", m) && !propEq("koodiarvo", "200", m),
    maaraykset
  );

  const checkboxMaarays = find(
    maarays =>
      propEq("koodiarvo", "200", maarays) && !path(["meta", "arvo"], maarays),
    maaraykset
  );
  const isCheckboxChecked =
    pathEq(["properties", "isChecked"], true, changeObjUlkomaaCheckbox) ||
    (length(ulkomaihinLiittyvatMaaraykset) > 0 && !changeObjUlkomaaCheckbox);

  let ulkomaaTekstikenttamuutokset;

  const tila = path(["properties", "isChecked"], changeObjUlkomaaCheckbox)
    ? "LISAYS"
    : "POISTO";
  const ulkomaaBEchangeObjectCheckbox = changeObjUlkomaaCheckbox
    ? [
        {
          meta: {
            changeObjects: [changeObjUlkomaaCheckbox]
          },
          kohde,
          koodiarvo: path(
            ["properties", "metadata", "koodiarvo"],
            changeObjUlkomaaCheckbox
          ),
          koodisto: path(
            ["properties", "metadata", "koodisto", "koodistoUri"],
            changeObjUlkomaaCheckbox
          ),
          maaraystyyppi,
          tila,
          maaraysUuid: tila === "POISTO" ? prop("uuid", checkboxMaarays) : null
        }
      ]
    : null;
  /** Käsitellään muutosobjektit vain jos opetusta järjestetään suomen ulkopuolella -checkbox on checkattu */
  if (isCheckboxChecked) {
    ulkomaaTekstikenttamuutokset = map(cObj => {
      const muutokseenLiittyvatRajoitteetByRajoiteIdAndKoodiarvo =
        getRajoitteetByValue(
          cObj.properties.metadata.ankkuri,
          rajoitteetByRajoiteId,
          "index"
        );

      const muutokseenLiittyvaMaarays = find(
        pathEq(
          ["meta", "ankkuri"],
          path(["properties", "metadata", "ankkuri"], cObj)
        ),
        maaraykset
      );

      const ankkuri = path(["properties", "metadata", "ankkuri"], cObj);
      const arvo = path(["properties", "value"], cObj);
      const isDeleted = path(["properties", "isDeleted"], cObj);
      const isMuokattu = muutokseenLiittyvaMaarays && !isDeleted;
      const changeObjectDeleted = isDeleted && !muutokseenLiittyvaMaarays;
      const tila = isDeleted ? "POISTO" : "LISAYS";

      const ulkomaaBEChangeObject =
        changeObjectDeleted && !muutokseenLiittyvaMaarays
          ? null
          : Object.assign(
              {},
              {
                generatedId: `ulkomaa-${Math.random()}`,
                kohde,
                koodiarvo: "200",
                koodisto: "kunta",
                maaraystyyppi,
                meta: {
                  ankkuri,
                  arvo,
                  changeObjects: flatten(
                    concat(
                      values(
                        muutokseenLiittyvatRajoitteetByRajoiteIdAndKoodiarvo
                      ),
                      [cObj]
                    )
                  ).filter(Boolean)
                },
                tila,
                maaraysUuid:
                  tila === "POISTO" ? muutokseenLiittyvaMaarays.uuid : null
              }
            );

      /** Jos ulkomaamääräystä on muokattu, pitää luoda poisto-objekti määräykselle */
      const muokkausPoistoObjekti = isMuokattu
        ? {
            kohde,
            koodiarvo: "200",
            koodisto: "kunta",
            tila: "POISTO",
            maaraysUuid: muutokseenLiittyvaMaarays.uuid,
            maaraystyyppi
          }
        : null;

      let alimaaraykset;

      alimaaraykset = values(
        mapObjIndexed(asetukset => {
          return createAlimaarayksetBEObjects(
            kohteet,
            maaraystyypit,
            ulkomaaBEChangeObject,
            asetukset
          );
        }, muutokseenLiittyvatRajoitteetByRajoiteIdAndKoodiarvo)
      );
      return [ulkomaaBEChangeObject, muokkausPoistoObjekti, alimaaraykset];
    }, changeObjUlkomaaTextBoxes);
  } else {
    ulkomaaTekstikenttamuutokset = !isCheckboxChecked
      ? map(maarays => {
          return {
            kohde,
            koodiarvo: "200",
            koodisto: "kunta",
            tila: "POISTO",
            maaraysUuid: maarays.uuid,
            maaraystyyppi
          };
        }, ulkomaihinLiittyvatMaaraykset)
      : null;
  }

  // Lisätietokentän muutosten käsittely
  let lisatietomuutokset = await luoMuutosobjektitLisatietokentasta(
    kohde,
    [changeObjects.lisatiedot].filter(Boolean)
  );
  lisatietomuutokset = map(
    assocPath(["meta", "sectionId"], sectionId),
    lisatietomuutokset
  );

  /** Kuntamaarayksia vasten luodut rajoitteet. Ei sisällä ulkomaita */
  const maarayksiaVastenLuodutRajoitteet = flatten(
    map(maarays => {
      const maaraystaKoskevatRajoitteet = mapObjIndexed(rajoite => {
        if (rajoiteHasValue(rajoite, maarays.koodiarvo)) {
          return createAlimaarayksetBEObjects(
            kohteet,
            maaraystyypit,
            {
              isMaarays: true,
              generatedId: maarays.uuid,
              kohde
            },
            rajoite
          );
        }
      }, rajoitteetByRajoiteId);
      return values(maaraystaKoskevatRajoitteet);
    }, concat(kuntamaaraykset, maakuntamaaraukset))
  ).filter(Boolean);

  /** Ulkomaamääräyksiä vasten luodut rajoitteet */
  const ulkomaamaarayksiaVastenLuodutRajoitteet = flatten(
    map(maarays => {
      const maaraystaKoskevatRajoitteet = mapObjIndexed(rajoite => {
        if (rajoiteHasValue(rajoite, maarays.meta.ankkuri, "index")) {
          return createAlimaarayksetBEObjects(
            kohteet,
            maaraystyypit,
            {
              isMaarays: true,
              generatedId: maarays.uuid,
              kohde
            },
            rajoite
          );
        }
      }, rajoitteetByRajoiteId);
      return values(maaraystaKoskevatRajoitteet);
    }, ulkomaihinLiittyvatMaaraykset)
  ).filter(Boolean);

  let allBEobjects = flatten([
    quickFilterBEchangeObjects,
    provinceBEchangeObjects.lisaykset,
    provinceBEchangeObjects.poistot,
    ulkomaaTekstikenttamuutokset,
    lisatietomuutokset,
    maarayksiaVastenLuodutRajoitteet,
    ulkomaaBEchangeObjectCheckbox,
    ulkomaamaarayksiaVastenLuodutRajoitteet
  ]).filter(Boolean);

  /**
   * Lisätään vielä frontin muutokset ensimmäiselle backend-muutosobjektille,
   * jos muutosobjekteja on olemassa vähintään yksi kappale.
   **/
  if (allBEobjects.length > 0) {
    const index = max(findIndex(propEq("koodiarvo", "FI1"), allBEobjects), 0);
    allBEobjects = assocPath(
      [index, "meta", "changeObjects"],
      append(
        {
          anchor: `${sectionId}.categoryFilter`,
          properties: {
            quickFilterChanges,
            changesByProvince
          }
        },
        path([index, "meta", "changeObjects"], allBEobjects)
      ),
      allBEobjects
    );
  }

  return allBEobjects;
}
