import { Redirect } from "react-router-dom";
import { useIntl } from "react-intl";
import { ROLE_ESITTELIJA } from "modules/constants";
import { Typography } from "@mui/material";
import { localizeRouteKey } from "utils/common";
import { AppRoute } from "routes";
import { ReactElement } from "react";
import { Organisation, User } from "koodistodatanTyypit";

import ammatillinenKoulutus from "i18n/definitions/ammatillinenKoulutus";
import common from "i18n/definitions/common";

const CasAuthenticated = ({
  organisation,
  user
}: {
  organisation: Organisation;
  user: User;
}): ReactElement => {
  const intl = useIntl();
  const { formatMessage, locale } = intl;

  const { oid } = organisation;

  if (!user) {
    return <p>{intl.formatMessage(common.loginError)}</p>;
  } else if (user.roles && oid) {
    const role = user.roles[1];
    switch (role) {
      case ROLE_ESITTELIJA: {
        return <Redirect to="/" />;
      }
      default: {
        return (
          <Redirect
            to={localizeRouteKey(locale, AppRoute.OmatTiedot, intl, {
              id: oid,
              koulutusmuoto: formatMessage(ammatillinenKoulutus.kebabCase)
            })}
          />
        );
      }
    }
  }
  return (
    <div className="pl-12 m-auto max-w-8xl">
      <Typography component="h2" variant="h2">
        {formatMessage(common.welcome)}
        {", "}
        {sessionStorage.getItem("username")}
      </Typography>
    </div>
  );
};

export default CasAuthenticated;
