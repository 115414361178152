import { toast } from "react-toastify";
import informUser from "../../../../../../i18n/definitions/informUser";
import common from "i18n/definitions/common";
import { map, prop } from "ramda";

// muutospyynto.tallennus.epaonnistui
export const epaonnistui = formatMessage => ({
  label: "Ilmoitus epäonnistumisesta",
  input: ["output"],
  run: async ({ output }) => {
    if (output.result.errors && output.result.errors.length > 0) {
      toast.error(
        `${formatMessage(informUser.jarjestamisluvanValidointiVirhe)}\n` +
          map(reason => {
            return `- ${formatMessage(informUser[reason])}`;
          }, map(prop("reason"), output.result.errors)).join("\n"),
        {
          autoClose: 10000,
          position: toast.POSITION.TOP_RIGHT
        }
      );
    } else if (output.status === 400 && output.result.code === "repair") {
      toast.error(formatMessage(common.lupaOnKorjauksessa), {
        autoClose: 5000,
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      toast.error(formatMessage(informUser.tallennusEpaonnistui), {
        autoClose: 5000,
        position: toast.POSITION.TOP_RIGHT
      });
    }
    return true;
  }
});
