import { CellFn } from "graphHandling/graphTypes";
import { getCurrentValue } from "graphHandling/graphUtils";
import { CellId } from "processes/CellId";
import { ILabeledCheckbox } from "utils/lomakkeet";

export const update_eiMaariteltyKuvaus: CellFn = storeFunctions => {
  const suomiCheckbox = storeFunctions.readPath([
    "components",
    CellId.INITIALIZE_SUOMI_CHECKBOX
  ]) as ILabeledCheckbox;

  const ulkomaatCheckbox = storeFunctions.readPath([
    "components",
    CellId.INITIALIZE_ULKOMAAT_CHECKBOX
  ]) as ILabeledCheckbox;

  const isSuomiCheckboxChecked = getCurrentValue("isChecked", suomiCheckbox);

  const isUlkomaatCheckboxChecked = getCurrentValue(
    "isChecked",
    ulkomaatCheckbox
  );

  storeFunctions.addModification(CellId.INITIALIZE_EI_MAARITELTY_KUVAUS, {
    isHidden: isSuomiCheckboxChecked || isUlkomaatCheckboxChecked,
    shouldHaveFocusAt: !isSuomiCheckboxChecked && !isUlkomaatCheckboxChecked
  });
  return true;
};
