import { ReactElement } from "react";
import { Alert, SimpleButton } from "utils/lomakkeet";
import { ComponentType } from "graphHandling/components/componentType";
import AlertMessage from "components/00-atoms/Alert/alert";
import { renderSimpleButton } from "graphHandling/components/simpleButton/rendering";
import { Variant } from "../../../components/00-atoms/SimpleButton/SimpleButton";
import ErrorIcon from "@mui/icons-material/Error";

export function renderAlert(
  componentDefinition: Alert,
  buttonDefinition: SimpleButton
): ReactElement | null {
  if (componentDefinition?.name === ComponentType.ALERT) {
    const { properties } = componentDefinition;

    if (buttonDefinition?.properties.variant === Variant.INFO) {
      properties.icon = <ErrorIcon />;
    }

    return (
      <AlertMessage {...properties}>
        {buttonDefinition ? (
          <div className={getClassNameBy(buttonDefinition.properties.variant)}>
            {renderSimpleButton(buttonDefinition)}
          </div>
        ) : null}
      </AlertMessage>
    );
  }

  return null;
}

function getClassNameBy(variant: Variant | undefined): string {
  return variant === Variant.INFO ? "float-left mt-4" : "float-right mt-4";
}
