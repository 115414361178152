import { ITextBoxProps } from "components/00-atoms/TextBox/TextBox";
import { ComponentType } from "graphHandling/components/componentType";
import { ComponentEvent } from "graphHandling/graphTypes";
import { ITextBox, Properties } from "utils/lomakkeet";

export function defineTextBox(
  properties: ITextBoxProps,
  functions: { onChange: ComponentEvent },
  modifications?: { backend?: Properties }
): ITextBox {
  const definition = {
    name: ComponentType.TEXT_BOX,
    onChange: functions.onChange,
    properties
  };

  if (modifications) {
    return { ...definition, modifications };
  }
  return definition;
}
