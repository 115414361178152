import { LocaleUpper } from "enums";
import { CellFn } from "graphHandling/graphTypes";
import { arrangeOpetuskieletOPH, sortLanguages } from "helpers/kielet";
import {
  jarjestaFronttiystavallisetTietueet,
  luoFronttiystavallinenVersioKoodistotietueesta,
  luoFronttiystavallisetVersiotKoodistodatanTietueista
} from "helpers/koodistodatanKasittely";
import { MetadataOfKoodistoObject } from "koodistodatanTyypit";
import { groupBy, head, map, mapObjIndexed, prop } from "ramda";
import { CellId } from "processes/CellId";

export const kasittele_kieletOPH: CellFn = async (
  storeFunctions,
  intl,
  eventPayload,
  result
) => {
  return sortLanguages(
    map(kieli => {
      return luoFronttiystavallinenVersioKoodistotietueesta(kieli);
    }, result[CellId.FETCH_KIELET_OPH]),
    intl.locale === "fi" ? LocaleUpper.FI : LocaleUpper.SV
  );
};

export const kasittele_lisatietojaKoodisto: CellFn = async (
  storeFunctions,
  intl,
  eventPayload,
  result
) => {
  const jarjestetytFKoodistotietueet = jarjestaFronttiystavallisetTietueet(
    luoFronttiystavallisetVersiotKoodistodatanTietueista(
      result[CellId.FETCH_KOODISTO_LISATIETOJA]
    )
  );

  return jarjestetytFKoodistotietueet;
};

export const kasittele_opetustehtavat: CellFn = async (
  storeFunctions,
  intl,
  eventPayload,
  result
) => {
  const jarjestetytFKoodistotietueet = jarjestaFronttiystavallisetTietueet(
    luoFronttiystavallisetVersiotKoodistodatanTietueista(
      result[CellId.FETCH_OPETUSTEHTAVAT]
    )
  );

  return jarjestetytFKoodistotietueet;
};

export const kasittele_opetustehtavatKoodisto: CellFn = async (
  storeFunctions,
  intl,
  eventPayload,
  result
) => {
  const metadata = prop(
    "metadata",
    result[CellId.FETCH_KOODISTO_OPETUSTEHTAVAT]
  ) as Array<MetadataOfKoodistoObject>;
  return {
    ...result[CellId.FETCH_KOODISTO_OPETUSTEHTAVAT],
    metadata: mapObjIndexed(head, groupBy(prop("kieli"), metadata))
  };
};

export const kasittele_poErityisetKoulutustehtavatKoodisto: CellFn = async (
  storeFunctions,
  intl,
  eventPayload,
  result
) => {
  const jarjestetytFKoodistotietueet = jarjestaFronttiystavallisetTietueet(
    luoFronttiystavallisetVersiotKoodistodatanTietueista(
      result[CellId.FETCH_KOODISTO_PO_ERITYISET_KOULUTUSTEHTAVAT]
    )
  );

  return jarjestetytFKoodistotietueet;
};

export const kasittele_poMuutEhdotKoodisto: CellFn = async (
  storeFunctions,
  intl,
  eventPayload,
  result
) => {
  const jarjestetytFKoodistotietueet = jarjestaFronttiystavallisetTietueet(
    luoFronttiystavallisetVersiotKoodistodatanTietueista(
      result[CellId.FETCH_KOODISTO_PO_MUUT_EHDOT]
    )
  );

  return jarjestetytFKoodistotietueet;
};

export const kasittele_ajallaKoodisto: CellFn = async (
  storeFunctions,
  intl,
  eventPayload,
  result
) => {
  return jarjestaFronttiystavallisetTietueet(
    luoFronttiystavallisetVersiotKoodistodatanTietueista(
      result[CellId.FETCH_KOODISTO_AJALLA]
    )
  );
};

export const kasittele_joistalisaksiKoodisto: CellFn = async (
  storeFunctions,
  intl,
  eventPayload,
  result
) => {
  return jarjestaFronttiystavallisetTietueet(
    luoFronttiystavallisetVersiotKoodistodatanTietueista(
      result[CellId.FETCH_KOODISTO_JOISTA_LISAKSI]
    )
  );
};

export const kasittele_kujalisamaareetKoodisto: CellFn = async (
  storeFunctions,
  intl,
  eventPayload,
  result
) => {
  return jarjestaFronttiystavallisetTietueet(
    luoFronttiystavallisetVersiotKoodistodatanTietueista(
      result[CellId.FETCH_KOODISTO_KUJALISAMAAREET]
    )
  );
};

export const kasittele_opetuksenJarjestamismuodot: CellFn = async (
  storeFunctions,
  intl,
  eventPayload,
  result
) => {
  return jarjestaFronttiystavallisetTietueet(
    luoFronttiystavallisetVersiotKoodistodatanTietueista(
      result[CellId.FETCH_KOODISTO_OPETUKSEN_JARJESTAMISMUODOT]
    )
  );
};

export const kasittele_ensisijaisetOpetuskieletOPH: CellFn = async (
  storeFunctions,
  intl,
  eventPayload,
  result
) => {
  return arrangeOpetuskieletOPH(
    ["FI", "SV", "SE", "RI", "VK"],
    map(kieli => {
      return luoFronttiystavallinenVersioKoodistotietueesta(kieli);
    }, result[CellId.FETCH_KOODISTO_OPETUSKIELET_OPH]),
    intl.locale === "fi" ? LocaleUpper.FI : LocaleUpper.SV
  );
};

export const kasittele_toissijaisetOpetuskieletOPH: CellFn = async (
  storeFunctions,
  intl,
  eventPayload,
  result
) => {
  return arrangeOpetuskieletOPH(
    ["EN", "FR", "DE", "RU"],
    map(kieli => {
      return luoFronttiystavallinenVersioKoodistotietueesta(kieli);
    }, result[CellId.FETCH_KOODISTO_OPETUSKIELET_OPH]),
    intl.locale === "fi" ? LocaleUpper.FI : LocaleUpper.SV
  );
};
