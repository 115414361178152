import { CellFn, Option, Options } from "graphHandling/graphTypes";
import { getCurrentValue } from "graphHandling/graphUtils";
import {
  assocPath,
  endsWith,
  equals,
  filter,
  find,
  isEmpty,
  map,
  mapObjIndexed,
  propEq
} from "ramda";
import { Autocomplete, IGroupedChipCollection } from "utils/lomakkeet";
import { CellId } from "processes/CellId";

export const add_chip: CellFn = (storeFunctions, intl, payload) => {
  const autocomplete = storeFunctions.readPath([
    "components",
    "autocomplete"
  ]) as Autocomplete;
  if (autocomplete) {
    const lisattavatMaakunnatJaKunnat = payload?.changeProps.values as Options;
    if (!isEmpty(lisattavatMaakunnatJaKunnat)) {
      const gChipCs = storeFunctions.readPath([
        "components",
        "groupedChipCollections",
        "properties",
        "gChipCs"
      ]) as Record<string, IGroupedChipCollection>;
      if (gChipCs) {
        const updatedGChipCs = mapObjIndexed(gChipC => {
          const maakunnanKoodiarvo =
            gChipC.properties.parentChip.properties.value;
          const isMaakuntaAmongSelected = !!find(
            propEq("value", maakunnanKoodiarvo),
            lisattavatMaakunnatJaKunnat
          );
          let muokkaaMaakuntaa = isMaakuntaAmongSelected;
          const kunnat = map(chip => {
            const isAmongOfSelected =
              isMaakuntaAmongSelected ||
              !!find(option => {
                return option.value === chip.properties.id;
              }, lisattavatMaakunnatJaKunnat);

            if (isAmongOfSelected) {
              muokkaaMaakuntaa = true;
              // Varmistetaan vielä checkaamattomuus, vaikka
              // pudotusvalikossa ei pitäisi checkattuja olla.
              if (!getCurrentValue("isChecked", chip)) {
                return assocPath(
                  ["modifications", "frontend"],
                  [
                    {
                      isChecked: true,
                      isRemovable: true
                    }
                  ],
                  chip
                );
              }
            }
            return chip;
          }, gChipC.properties.chipCollection.properties.chips);

          let updatedGChipC = assocPath(
            ["properties", "chipCollection", "properties", "chips"],
            kunnat,
            gChipC
          );

          if (muokkaaMaakuntaa) {
            updatedGChipC = assocPath(
              ["properties", "parentChip", "modifications", "frontend"],
              [
                {
                  isChecked: true,
                  isIndeterminate: !isMaakuntaAmongSelected,
                  isRemovable: isMaakuntaAmongSelected
                }
              ],
              updatedGChipC
            );
          }

          return updatedGChipC;
        }, gChipCs);

        storeFunctions.updateGraph(
          ["components", "groupedChipCollections", "properties", "gChipCs"],
          updatedGChipCs
        );

        const autocomplete = storeFunctions.readPath([
          "components",
          CellId.INITIALIZE_AUTOCOMPLETE
        ]) as Autocomplete;
        const options = getCurrentValue("options", autocomplete) as Options;

        storeFunctions.updateComponent(CellId.INITIALIZE_AUTOCOMPLETE, {
          modifications: {
            frontend: {
              options: filter(option => {
                return !find((lisattava: Option) => {
                  return (
                    equals(lisattava.value, option.value) ||
                    endsWith(
                      `-${lisattava.value as string}`,
                      option.value as string
                    )
                  );
                }, lisattavatMaakunnatJaKunnat);
              }, options),
              values: []
            }
          },
          properties: {
            values: []
          }
        });
      }
    }
  }

  return true;
};
