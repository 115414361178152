import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { API_BASE_URL } from "modules/constants";
import Loading from "scenes/Loading";
import { initializeTutkinnot } from "helpers/tutkinnot";
import { backendRoutes } from "stores/utils/backendRoutes";
import { useLocation, useParams } from "react-router-dom";
import { initializeMaakunta } from "helpers/maakunnat";
import { arrangeOpetuskieletOPH, sortLanguages } from "helpers/kielet/index";
import { initializeOpetuskielet } from "helpers/opetuskielet/initialization";
import { initializeKunta } from "helpers/kunnat";
import { initializeRajoitteet } from "helpers/alimaaraykset";
import { PaikallisenTietovarastonAvain } from "enums";
import {
  jarjestaFronttiystavallisetTietueet,
  kasitteleKoodistotietueetJaTallennaNePaikalliseenTietovarastoon,
  luoFronttiystavallinenVersioKoodistotietueesta,
  luoFronttiystavallisetVersiotKoodistodatanTietueista
} from "helpers/koodistodatanKasittely";
import localForage from "localforage";
import {
  assoc,
  filter,
  find,
  groupBy,
  head,
  includes,
  isEmpty,
  map,
  mapObjIndexed,
  omit,
  path,
  prop,
  propEq,
  sortBy,
  test,
  toUpper
} from "ramda";
import { getKoulutusmuotoByPredicate } from "utils/common";
import { useIntl } from "react-intl";
import { koulutustyypitMap } from "utils/constants";

export const AcceptTypes = {
  JSON: 0,
  HTML: 1
};

const acceptJSON = {
  headers: { Accept: "application/json", "Content-Type": "application/json" }
};

const acceptHTML = {
  headers: { Accept: "text/html", "Content-Type": "text/html" }
};

/**
 * Erittäin harvoin muuttuvat tiedot noudetaan backendistä, miiäli tietoja
 * ei ole noudettu tai jos edellisestä noutokerrasta on kulunut muuttujan
 * minimumTimeBetweenFetchingInSeconds ilmaisema aika.
 */
const minimumTimeBetweenFetchingInMinutes = 60;

export const fetchJSON = async path => {
  const response = await fetch(`${API_BASE_URL}/${path}`, acceptJSON);
  let result = {
    fetchedAt: new Date(),
    data: response && response.ok ? await response.json() : null
  };
  result =
    response && !response.ok ? assoc("error", !response.ok, result) : result;
  await localForage.setItem(path, result);
  return result.data;
};

export const fetchHTML = async path => {
  const response = await fetch(`${API_BASE_URL}/${path}`, acceptHTML);
  let result = {
    fetchedAt: new Date(),
    data: response.ok ? await response.text() : null
  };
  result = !response.ok ? assoc("error", !response.ok, result) : result;
  return result.data;
};

export const getRaw = async (
  key,
  path,
  keys = [],
  _minimumTimeBetweenFetchingInMinutes = minimumTimeBetweenFetchingInMinutes,
  acceptType = AcceptTypes.JSON
) => {
  if (includes(key, keys) || isEmpty(keys)) {
    if (acceptType === AcceptTypes.JSON) {
      const stored = await localForage.getItem(path);
      return stored &&
        (new Date() - stored.fetchedAt) / 1000 / 60 <
          _minimumTimeBetweenFetchingInMinutes
        ? stored.data
        : await fetchJSON(path);
    } else if (acceptType === AcceptTypes.HTML) {
      return await fetchHTML(path);
    }
  }
};

/**
 * Funktio noutaa sovelluksen tarvitseman pohjadatan, kuten kunnat, maakunnat,
 * kielet ja määräystyypit. Nämä ovat yleistä dataa, jota käytetään mm.
 * lomakkeita ja listauksia muodostettaessa. Lupa noudetaan järjestäjän oid:a
 * käyttäen ja sen sisältämiä määräyksiä hyödynnetään parsittaessa pohjadataa
 * tarvittaessa paremmin sovellusta palvelevaan muotoon.
 *
 * @param keys
 * @param {string} locale - fi / sv
 * @param lupaUuid
 * @param {string} oid
 * @param koulutustyyppi
 * @param oppilaitostyyppi
 * @param language
 * @param isMuutospyynnonMuokkaus
 * @param muutospyyntoUuid
 * @param oppilaitosId
 * @param parentKoulutusmuoto
 */
const fetchBaseData = async (
  keys,
  locale,
  lupaUuid,
  oid,
  koulutustyyppi,
  oppilaitostyyppi,
  language,
  isMuutospyynnonMuokkaus,
  muutospyyntoUuid,
  oppilaitosId,
  parentKoulutustyyppi
) => {
  const localeUpper = toUpper(locale);
  /**
   * Raw-objekti sisältää backendiltä tulevan datan muokkaamattomana.
   */
  const raw = {
    ajalla: await getRaw("ajalla", backendRoutes.ajalla.path, keys),
    elykeskukset: await getRaw(
      "elykeskukset",
      backendRoutes.elykeskukset.path,
      keys
    ),
    kayttaja: await getRaw(
      "kayttaja",
      backendRoutes.kayttaja.path,
      keys,
      backendRoutes.kayttaja.minimumTimeBetweenFetchingInMinutes
    ),
    kielet: await getRaw("kielet", backendRoutes.kielet.path, keys),
    kohteet: await getRaw(
      "kohteet",
      `${backendRoutes.kohteet.path}${
        koulutustyyppi ? "?koulutustyyppi=" + koulutustyyppi : ""
      }`,
      keys
    ),
    lisatiedot: await getRaw(
      "lisatietoja",
      backendRoutes.lisatietoja.path,
      keys,
      backendRoutes.lisatietoja.minimumTimeBetweenFetchingInMinutes
    ),
    lupaByUuid: lupaUuid
      ? await getRaw(
          "lupaByUuid",
          `${backendRoutes.lupaByUuid.path}${lupaUuid}?with=all&useKoodistoVersions=false`,
          keys,
          backendRoutes.lupaByUuid.minimumTimeBetweenFetchingInMinutes
        )
      : null,
    lupaByOid: oid
      ? await getRaw(
          "lupaByOid",
          `${
            backendRoutes.lupaByOid.path
          }${oid}?with=all&useKoodistoVersions=false${
            koulutustyyppi ? "&koulutustyyppi=" + koulutustyyppi : ""
          }${oppilaitostyyppi ? "&oppilaitostyyppi=" + oppilaitostyyppi : ""}`,
          keys,
          backendRoutes.lupaByUuid.minimumTimeBetweenFetchingInMinutes
        )
      : null,
    luvanTaiteenalat: await getRaw(
      "tpoluvantaiteenalat",
      backendRoutes.tpoluvantaiteenalat.path,
      keys
    ),
    luvantaiteenalatKoodistoTPO: await getRaw(
      "luvantaiteenalatKoodistoTPO",
      backendRoutes.luvantaiteenalatKoodistoTPO.path,
      keys
    ),
    oppilaitoksetByOid: oid
      ? await getRaw(
          "oppilaitoksetByOid",
          `${backendRoutes.oppilaitoksetByOid.path}${oid}${backendRoutes.oppilaitoksetByOid.postfix}`,
          keys,
          backendRoutes.oppilaitoksetByOid.minimumTimeBetweenFetchingInMinutes
        )
      : null,
    vstTyypit: await getRaw(
      "vstTyypit",
      `${backendRoutes.vstTyypit.path}`,
      keys
    ),
    lukioErityinenKoulutustehtavaUusi: await getRaw(
      "lukioErityinenKoulutustehtavaUusi",
      backendRoutes.lukioErityinenKoulutustehtavaUusi.path,
      keys
    ),
    lukioMuutKoulutuksenJarjestamiseenLiittyvatEhdot: await getRaw(
      "lukioMuutKoulutuksenJarjestamiseenLiittyvatEhdot",
      backendRoutes.lukioMuutKoulutuksenJarjestamiseenLiittyvatEhdot.path,
      keys
    ),
    oikeusSisaoppilaitosmuotoiseenKoulutukseen: await getRaw(
      "oikeusSisaoppilaitosmuotoiseenKoulutukseen",
      backendRoutes.oikeusSisaoppilaitosmuotoiseenKoulutukseen.path,
      keys
    ),
    // Koulutukset (muut)
    ammatilliseentehtavaanvalmistavakoulutus: await getRaw(
      "ammatilliseentehtavaanvalmistavakoulutus",
      `${backendRoutes.koulutuksetMuut.path}ammatilliseentehtavaanvalmistavakoulutus`,
      keys
    ),
    kuljettajakoulutus: await getRaw(
      "kuljettajakoulutus",
      `${backendRoutes.koulutuksetMuut.path}kuljettajakoulutus`,
      keys
    ),
    oivatyovoimakoulutus: await getRaw(
      "oivatyovoimakoulutus",
      `${backendRoutes.koulutuksetMuut.path}oivatyovoimakoulutus`,
      keys
    ),
    // Koulutukset (poikkeukset)
    poikkeus999901: await getRaw(
      "poikkeus999901",
      `${backendRoutes.koulutus.path}999901`,
      keys
    ),
    poikkeus999903: await getRaw(
      "poikkeus999903",
      `${backendRoutes.koulutus.path}999903`,
      keys
    ),
    kieletOPH: await getRaw("kieletOPH", backendRoutes.kieletOPH.path, keys),
    koulutusalat: await getRaw(
      "koulutusalat",
      backendRoutes.koulutusalat.path,
      keys
    ),
    koulutustyypit: await getRaw(
      PaikallisenTietovarastonAvain.KOULUTUSTYYPIT,
      backendRoutes.koulutustyypit.path,
      keys
    ),
    kujalisamaareet: await getRaw(
      "kujalisamaareet",
      backendRoutes.kujalisamaareet.path,
      keys
    ),
    joistalisaksi: await getRaw(
      PaikallisenTietovarastonAvain.JOISTA_LISAKSI,
      backendRoutes.joistalisaksi.path,
      keys
    ),
    kunnat: await getRaw("kunnat", backendRoutes.kunnat.path, keys),
    maakunnat: await getRaw("maakunnat", backendRoutes.maakunnat.path, keys),
    maakuntakunnat: await getRaw(
      "maakuntakunnat",
      backendRoutes.maakuntakunnat.path,
      keys
    ),
    maaraystyypit: await getRaw(
      "maaraystyypit",
      backendRoutes.maaraystyypit.path,
      keys
    ),
    muut: await getRaw("muut", backendRoutes.muut.path, keys),
    oivaperustelut: await getRaw(
      "oivaperustelut",
      backendRoutes.oivaperustelut.path,
      keys
    ),
    opetuksenJarjestamismuodot: await getRaw(
      "opetuksenJarjestamismuodot",
      backendRoutes.opetuksenJarjestamismuodot.path,
      keys
    ),
    opetuskielet: await getRaw(
      "opetuskielet",
      backendRoutes.opetuskielet.path,
      keys
    ),
    opetustehtavakoodisto: await getRaw(
      "opetustehtavakoodisto",
      backendRoutes.opetustehtavakoodisto.path,
      keys
    ),
    opetustehtavat: await getRaw(
      "opetustehtavat",
      backendRoutes.opetustehtavat.path,
      keys
    ),
    organisaatio: await getRaw(
      PaikallisenTietovarastonAvain.ORGANISAATIO,
      `${backendRoutes.organisaatio.path}${oid}`,
      keys
    ),
    organisaatiot: await getRaw(
      "organisaatiot",
      koulutustyyppi
        ? `${backendRoutes.organisaatiot.path}?koulutustyyppi=${koulutustyyppi}`
        : backendRoutes.organisaatiot.path,
      keys,
      backendRoutes.organisaatiot.minimumTimeBetweenFetchingInMinutes
    ),
    poErityisetKoulutustehtavat: await getRaw(
      "poErityisetKoulutustehtavat",
      backendRoutes.poErityisetKoulutustehtavat.path,
      keys
    ),
    poMuutEhdot: await getRaw(
      "poMuutEhdot",
      backendRoutes.poMuutEhdot.path,
      keys
    ),
    muutEhdotTPO: await getRaw(
      "muutEhdotTPO",
      backendRoutes.muutEhdotTPO.path,
      keys
    ),
    muutEhdotVST: await getRaw(
      "muutEhdotVST",
      backendRoutes.muutEhdotVST.path,
      keys
    ),
    tutkinnot: await getRaw("tutkinnot", backendRoutes.tutkinnot.path, keys),
    tulevatLuvat: await getRaw(
      "tulevatLuvat",
      `${backendRoutes.tulevatLuvat.path}${oid}${
        backendRoutes.tulevatLuvat.postfix
      }?with=all&useKoodistoVersions=false${
        koulutustyyppi ? "&koulutustyyppi=" + koulutustyyppi : ""
      }${language ? `&kieli=${language}` : ""}${
        oppilaitostyyppi ? "&oppilaitostyyppi=" + oppilaitostyyppi : ""
      }${oppilaitosId ? "&oppilaitosOid=" + oppilaitosId : ""}`,
      keys,
      backendRoutes.tulevatLuvat.minimumTimeBetweenFetchingInMinutes
    ),
    vankilat: await getRaw("vankilat", backendRoutes.vankilat.path, keys),
    viimeisinLupa: await getRaw(
      "viimeisinLupa",
      `${backendRoutes.viimeisinLupa.path}${oid}${
        backendRoutes.viimeisinLupa.postfix
      }?with=all&useKoodistoVersions=false${
        koulutustyyppi ? `&koulutustyyppi=${koulutustyyppi}` : ""
      }${language ? `&kieli=${language}` : ""}${
        oppilaitostyyppi ? "&oppilaitostyyppi=" + oppilaitostyyppi : ""
      }${oppilaitosId ? "&oppilaitosOid=" + oppilaitosId : ""}${
        parentKoulutustyyppi
          ? "&parentKoulutustyyppi=" + parentKoulutustyyppi
          : ""
      }`,
      keys,
      backendRoutes.viimeisinLupa.minimumTimeBetweenFetchingInMinutes
    ),
    vstErityisetKoulutustehtavat: await getRaw(
      "vstErityisetKoulutustehtavat",
      backendRoutes.vstErityisetKoulutustehtavat.path,
      keys
    ),
    vstOppilaitoksenAlueellisuusJaValtakunnallisuus: await getRaw(
      "vstOppilaitoksenAlueellisuusJaValtakunnallisuus",
      backendRoutes.vstOppilaitoksenAlueellisuusJaValtakunnallisuus.path,
      keys
    ),
    muutospyynto: muutospyyntoUuid
      ? await getRaw(
          "muutospyynto",
          `${backendRoutes.muutospyynto.path + muutospyyntoUuid}`,
          keys,
          0
        )
      : undefined
  };
  const lupa = isMuutospyynnonMuokkaus
    ? raw.lupaByUuid
    : raw.viimeisinLupa ||
      (lupaUuid ? raw.lupaByUuid : oid ? raw.lupaByOid : null) || {
        maaraykset: []
      };

  /**
   * Varsinainen palautusarvo sisältää sekä muokkaamatonta että muokattua
   * dataa. Samalla noudettu data tallennetaan lokaaliin tietovarastoon
   * (indexedDB / WebSQL / localStorage) myöhempää käyttöä varten.
   */
  const maakuntakunnat = raw.maakuntakunnat
    ? await localForage.setItem(
        "maakuntakunnat",
        sortBy(
          path(["metadata", localeUpper, "nimi"]),
          map(maakunta => {
            return initializeMaakunta(maakunta, localeUpper);
          }, raw.maakuntakunnat || []).filter(Boolean)
        )
      )
    : undefined;

  const ahvenanmaanKunnat =
    (find(propEq("koodiarvo", "21"), maakuntakunnat || []) || {}).kunnat || [];

  const result = {
    elykeskukset: raw.elykeskukset
  };

  result.ajalla = raw.ajalla
    ? await localForage.setItem(
        "ajalla",
        sortBy(
          path(["metadata", localeUpper, "nimi"]),
          map(maare => {
            return omit(["koodiArvo"], {
              ...maare,
              koodiarvo: maare.koodiArvo,
              metadata: mapObjIndexed(
                head,
                groupBy(prop("kieli"), maare.metadata)
              )
            });
          }, raw.ajalla || []).filter(Boolean)
        )
      )
    : undefined;

  result.kayttaja = raw.kayttaja
    ? await localForage.setItem("kayttaja", raw.kayttaja)
    : undefined;

  result.kielet = raw.kielet
    ? await localForage.setItem(
        "kielet",
        sortLanguages(
          map(kieli => {
            return luoFronttiystavallinenVersioKoodistotietueesta(kieli);
          }, raw.kielet),
          localeUpper
        )
      )
    : undefined;

  result.kieletOPH = raw.kieletOPH
    ? await localForage.setItem(
        "kieletOPH",
        sortLanguages(
          map(kieli => {
            return luoFronttiystavallinenVersioKoodistotietueesta(kieli);
          }, raw.kieletOPH),
          localeUpper
        )
      )
    : undefined;

  result.ensisijaisetOpetuskieletOPH = raw.kieletOPH
    ? await localForage.setItem(
        "ensisijaisetOpetuskieletOPH",
        arrangeOpetuskieletOPH(
          ["FI", "SV", "SE", "RI", "VK"],
          map(kieli => {
            return luoFronttiystavallinenVersioKoodistotietueesta(kieli);
          }, raw.kieletOPH),
          localeUpper
        )
      )
    : undefined;

  result.kohteet = raw.kohteet
    ? await localForage.setItem("kohteet", raw.kohteet)
    : [];

  result.lukioErityinenKoulutustehtavaUusi =
    raw.lukioErityinenKoulutustehtavaUusi
      ? await localForage.setItem(
          PaikallisenTietovarastonAvain.LUKIO_ERITYINEN_KOULUTUSTEHTAVA_UUSI,
          map(
            omit(["koodiarvoInt"]),
            sortBy(
              prop("koodiarvoInt"),
              map(koulutustehtava => {
                return omit(["koodiArvo"], {
                  ...koulutustehtava,
                  koodiarvo: koulutustehtava.koodiArvo,
                  koodiarvoInt: parseInt(koulutustehtava.koodiArvo, 10),
                  metadata: mapObjIndexed(
                    head,
                    groupBy(prop("kieli"), koulutustehtava.metadata)
                  )
                });
              }, raw.lukioErityinenKoulutustehtavaUusi)
            )
          )
        )
      : null;

  result.lukioMuutKoulutuksenJarjestamiseenLiittyvatEhdot =
    raw.lukioMuutKoulutuksenJarjestamiseenLiittyvatEhdot
      ? await localForage.setItem(
          PaikallisenTietovarastonAvain.LUKIO_MUUT_EHDOT,
          sortBy(
            prop("koodiarvo"),
            map(muuData => {
              return omit(["koodiArvo"], {
                ...muuData,
                koodiarvo: muuData.koodiArvo,
                metadata: mapObjIndexed(
                  head,
                  groupBy(prop("kieli"), muuData.metadata)
                )
              });
            }, raw.lukioMuutKoulutuksenJarjestamiseenLiittyvatEhdot)
          )
        )
      : null;

  result.oikeusSisaoppilaitosmuotoiseenKoulutukseen =
    raw.oikeusSisaoppilaitosmuotoiseenKoulutukseen
      ? await kasitteleKoodistotietueetJaTallennaNePaikalliseenTietovarastoon(
          raw.oikeusSisaoppilaitosmuotoiseenKoulutukseen,
          PaikallisenTietovarastonAvain.OIKEUS_SISAOPPILAITOSMUOTOISEEN_KOULUTUKSEEN
        )
      : undefined;

  result.oppilaitoksetByOid = raw.oppilaitoksetByOid
    ? await localForage.setItem(
        PaikallisenTietovarastonAvain.OPPILAITOKSET_BY_OID,
        sortBy(path(["nimi", locale]), raw.oppilaitoksetByOid)
      )
    : await localForage.setItem("oppilaitoksetByOid", []);

  result.oppilaitos =
    oppilaitosId && raw.oppilaitoksetByOid
      ? await localForage.setItem(
          PaikallisenTietovarastonAvain.OPPILAITOS,
          find(
            propEq("oid", oppilaitosId),
            await localForage.getItem("oppilaitoksetByOid")
          )
        )
      : null;

  result.koulutukset =
    raw.ammatilliseentehtavaanvalmistavakoulutus ||
    raw.kuljettajakoulutus ||
    raw.oivatyovoimakoulutus ||
    raw.poikkeus999901 ||
    raw.poikkeus999903
      ? await localForage.setItem("koulutukset", {
          muut: {
            ammatilliseentehtavaanvalmistavakoulutus:
              raw.ammatilliseentehtavaanvalmistavakoulutus
                ? jarjestaFronttiystavallisetTietueet(
                    luoFronttiystavallisetVersiotKoodistodatanTietueista(
                      raw.ammatilliseentehtavaanvalmistavakoulutus
                    )
                  )
                : undefined,
            kuljettajakoulutus: raw.kuljettajakoulutus
              ? jarjestaFronttiystavallisetTietueet(
                  luoFronttiystavallisetVersiotKoodistodatanTietueista(
                    raw.kuljettajakoulutus
                  )
                )
              : undefined,
            oivatyovoimakoulutus: raw.oivatyovoimakoulutus
              ? jarjestaFronttiystavallisetTietueet(
                  luoFronttiystavallisetVersiotKoodistodatanTietueista(
                    raw.oivatyovoimakoulutus
                  )
                )
              : undefined
          },
          poikkeukset: {
            999901: raw.poikkeus999901
              ? luoFronttiystavallinenVersioKoodistotietueesta(
                  raw.poikkeus999901
                )
              : undefined,
            999903: raw.poikkeus999903
              ? luoFronttiystavallinenVersioKoodistotietueesta(
                  raw.poikkeus999903
                )
              : undefined
          }
        })
      : undefined;

  result.koulutusalat = raw.koulutusalat
    ? await kasitteleKoodistotietueetJaTallennaNePaikalliseenTietovarastoon(
        raw.koulutusalat,
        PaikallisenTietovarastonAvain.KOULUTUSALAT
      )
    : undefined;

  result.koulutustyypit = raw.koulutustyypit
    ? await kasitteleKoodistotietueetJaTallennaNePaikalliseenTietovarastoon(
        raw.koulutustyypit,
        PaikallisenTietovarastonAvain.KOULUTUSTYYPIT
      )
    : undefined;

  result.joistaLisaksi = raw.joistalisaksi
    ? await localForage.setItem(
        "joistaLisaksi",
        sortBy(
          path(["metadata", localeUpper, "nimi"]),
          map(maare => {
            return omit(["koodiArvo"], {
              ...maare,
              koodiarvo: maare.koodiArvo,
              metadata: mapObjIndexed(
                head,
                groupBy(prop("kieli"), maare.metadata)
              )
            });
          }, raw.joistalisaksi || []).filter(Boolean)
        )
      )
    : undefined;

  result.lisamaareet = raw.kujalisamaareet
    ? await kasitteleKoodistotietueetJaTallennaNePaikalliseenTietovarastoon(
        raw.kujalisamaareet,
        PaikallisenTietovarastonAvain.KUJALISAMAAREET
      )
    : undefined;

  result.kunnat = raw.kunnat
    ? await localForage.setItem(
        "kunnat",
        sortBy(
          path(["metadata", localeUpper, "nimi"]),
          map(kunta => {
            return initializeKunta(kunta, localeUpper, ahvenanmaanKunnat);
          }, raw.kunnat || []).filter(Boolean)
        )
      )
    : undefined;

  result.lisatiedot = raw.lisatiedot
    ? await kasitteleKoodistotietueetJaTallennaNePaikalliseenTietovarastoon(
        raw.lisatiedot,
        PaikallisenTietovarastonAvain.LISATIEDOT
      )
    : undefined;

  result.lupa = lupa ? await localForage.setItem("lupa", lupa) : undefined;

  result.luvanTaiteenalat = raw.luvanTaiteenalat
    ? await localForage.setItem(
        "luvanTaiteenalatTPO",
        sortBy(
          prop("koodiarvo"),
          map(
            taiteenala =>
              omit(["koodiArvo"], {
                ...taiteenala,
                koodiarvo: taiteenala.koodiArvo,
                metadata: mapObjIndexed(
                  head,
                  groupBy(prop("kieli"), taiteenala.metadata)
                )
              }),
            raw.luvanTaiteenalat
          )
        )
      )
    : undefined;

  result.vstLuvat = raw.vstLuvat;

  result.vstOppilaitoksenAlueellisuusJaValtakunnallisuus =
    raw.vstOppilaitoksenAlueellisuusJaValtakunnallisuus
      ? await kasitteleKoodistotietueetJaTallennaNePaikalliseenTietovarastoon(
          raw.vstOppilaitoksenAlueellisuusJaValtakunnallisuus,
          PaikallisenTietovarastonAvain.VST_OPPILAITOKSEN_VAIKUTUSALUE
        )
      : undefined;

  result.vstTyypit = raw.vstTyypit
    ? await localForage.setItem(
        "vsttyypit",
        map(
          vstTyyppi =>
            omit(["koodiArvo"], {
              ...vstTyyppi,
              koodiarvo: vstTyyppi.koodiArvo,
              metadata: mapObjIndexed(
                head,
                groupBy(prop("kieli"), vstTyyppi.metadata)
              )
            }),
          raw.vstTyypit
        )
      )
    : undefined;

  result.luvantaiteenalatKoodistoTPO = raw.luvantaiteenalatKoodistoTPO
    ? await localForage.setItem("luvantaiteenalatKoodistoTPO", {
        ...raw.luvantaiteenalatKoodistoTPO,
        metadata: mapObjIndexed(
          head,
          groupBy(
            prop("kieli"),
            prop("metadata", raw.luvantaiteenalatKoodistoTPO)
          )
        )
      })
    : undefined;

  result.maakunnat = raw.maakunnat
    ? await localForage.setItem(
        "maakunnat",
        sortBy(
          path(["metadata", localeUpper, "nimi"]),
          map(maakunta => {
            return omit(["koodiArvo"], {
              ...maakunta,
              koodiarvo: maakunta.koodiArvo,
              metadata: mapObjIndexed(
                head,
                groupBy(prop("kieli"), maakunta.metadata)
              )
            });
          }, raw.maakunnat || []).filter(Boolean)
        )
      )
    : undefined;

  result.maakuntakunnat = maakuntakunnat;

  result.maaraystyypit = raw.maaraystyypit
    ? await localForage.setItem(
        PaikallisenTietovarastonAvain.MAARAYSTYYPIT,
        raw.maaraystyypit
      )
    : undefined;

  result.muut = raw.muut
    ? await kasitteleKoodistotietueetJaTallennaNePaikalliseenTietovarastoon(
        raw.muut,
        PaikallisenTietovarastonAvain.MUUT
      )
    : undefined;

  result.oivaperustelut = raw.oivaperustelut
    ? await localForage.setItem(
        "oivaperustelut",
        sortBy(
          prop("koodiarvo"),
          map(perustelu => {
            return omit(["koodiArvo"], {
              ...perustelu,
              koodiarvo: perustelu.koodiArvo,
              metadata: mapObjIndexed(
                head,
                groupBy(prop("kieli"), perustelu.metadata)
              )
            });
          }, raw.oivaperustelut)
        )
      )
    : undefined;

  result.opetuksenJarjestamismuodot = raw.opetuksenJarjestamismuodot
    ? await kasitteleKoodistotietueetJaTallennaNePaikalliseenTietovarastoon(
        raw.opetuksenJarjestamismuodot,
        PaikallisenTietovarastonAvain.OPETUKSEN_JARJESTAMISMUODOT
      )
    : undefined;

  result.opetuskielet = raw.opetuskielet
    ? await localForage.setItem(
        "opetuskielet",
        sortBy(
          prop("koodiarvo"),
          initializeOpetuskielet(
            raw.opetuskielet,
            filter(
              maarays => maarays.koodisto === "oppilaitoksenopetuskieli",
              prop("maaraykset", lupa) || []
            ) || []
          )
        )
      )
    : undefined;

  result.opetustehtavakoodisto = raw.opetustehtavakoodisto
    ? await localForage.setItem("opetustehtavakoodisto", {
        ...raw.opetustehtavakoodisto,
        metadata: mapObjIndexed(
          head,
          groupBy(prop("kieli"), prop("metadata", raw.opetustehtavakoodisto))
        )
      })
    : undefined;

  result.opetustehtavat = raw.opetustehtavat
    ? await kasitteleKoodistotietueetJaTallennaNePaikalliseenTietovarastoon(
        raw.opetustehtavat,
        PaikallisenTietovarastonAvain.OPETUSTEHTAVAT
      )
    : undefined;

  result.organisaatio = raw.organisaatio
    ? await localForage.setItem(
        PaikallisenTietovarastonAvain.ORGANISAATIO,
        raw.organisaatio
      )
    : undefined;

  result.organisaatiot = raw.organisaatiot
    ? await localForage.setItem("organisaatiot", raw.organisaatiot)
    : undefined;

  result.poErityisetKoulutustehtavat = raw.poErityisetKoulutustehtavat
    ? await kasitteleKoodistotietueetJaTallennaNePaikalliseenTietovarastoon(
        raw.poErityisetKoulutustehtavat,
        PaikallisenTietovarastonAvain.PO_ERITYISET_KOULUTUSTEHTAVAT
      )
    : undefined;

  result.poMuutEhdot = raw.poMuutEhdot
    ? await kasitteleKoodistotietueetJaTallennaNePaikalliseenTietovarastoon(
        raw.poMuutEhdot,
        PaikallisenTietovarastonAvain.PO_MUUT_EHDOT
      )
    : undefined;

  result.toissijaisetOpetuskieletOPH = raw.kieletOPH
    ? await localForage.setItem(
        "toissijaisetOpetuskieletOPH",
        arrangeOpetuskieletOPH(
          ["EN", "FR", "DE", "RU"],
          map(kieli => {
            return luoFronttiystavallinenVersioKoodistotietueesta(kieli);
          }, raw.kieletOPH),
          localeUpper
        )
      )
    : undefined;

  result.poMuutEhdot = raw.poMuutEhdot
    ? await kasitteleKoodistotietueetJaTallennaNePaikalliseenTietovarastoon(
        raw.poMuutEhdot,
        PaikallisenTietovarastonAvain.PO_MUUT_EHDOT
      )
    : undefined;

  result.muutEhdotTPO = raw.muutEhdotTPO
    ? await kasitteleKoodistotietueetJaTallennaNePaikalliseenTietovarastoon(
        raw.muutEhdotTPO,
        PaikallisenTietovarastonAvain.TPO_MUUT_EHDOT
      )
    : undefined;

  result.muutEhdotVST = raw.muutEhdotVST
    ? await kasitteleKoodistotietueetJaTallennaNePaikalliseenTietovarastoon(
        raw.muutEhdotVST,
        PaikallisenTietovarastonAvain.VST_MUUT_EHDOT
      )
    : undefined;

  result.tutkinnot = raw.tutkinnot
    ? await localForage.setItem(
        "tutkinnot",
        sortBy(
          prop("koodiarvo"),
          initializeTutkinnot(
            raw.tutkinnot,
            filter(
              maarays => maarays.koodisto === "koulutus",
              prop("maaraykset", lupa) || []
            ) || []
          )
        )
      )
    : undefined;

  result.tulevatLuvat = raw.tulevatLuvat || [];

  result.vankilat = raw.vankilat
    ? await kasitteleKoodistotietueetJaTallennaNePaikalliseenTietovarastoon(
        raw.vankilat,
        PaikallisenTietovarastonAvain.VANKILAT,
        localForage
      )
    : undefined;

  result.viimeisinLupa = assoc(
    "rajoitteet",
    initializeRajoitteet(prop("maaraykset", raw.viimeisinLupa)),
    raw.viimeisinLupa
  );

  result.voimassaOlevaLupa = raw.lupaByUuid || raw.lupaByOid;

  result.vstErityisetKoulutustehtavat = raw.vstErityisetKoulutustehtavat
    ? await localForage.setItem(
        PaikallisenTietovarastonAvain.VST_ERITYISET_KOULUTUSTEHTAVAT,
        sortBy(
          prop("koodiarvo"),
          map(perustelu => {
            return omit(["koodiArvo"], {
              ...perustelu,
              koodiarvo: perustelu.koodiArvo,
              metadata: mapObjIndexed(
                head,
                groupBy(prop("kieli"), perustelu.metadata)
              )
            });
          }, raw.vstErityisetKoulutustehtavat)
        )
      )
    : undefined;

  result.lupaByUuid = raw.lupaByUuid
    ? await localForage.setItem(
        "lupa",
        assoc(
          "rajoitteet",
          initializeRajoitteet(prop("maaraykset", raw.lupaByUuid)),
          raw.lupaByUuid
        )
      )
    : undefined;

  result.muutospyynto = raw.muutospyynto
    ? await localForage.setItem("muutospyynto", raw.muutospyynto)
    : undefined;

  return result;
};

const defaultProps = {
  keys: []
};

const BaseData = ({
  keys = defaultProps.keys,
  locale,
  render,
  koulutustyyppi,
  oppilaitostyyppi,
  lupaId,
  isMuutospyynnonMuokkaus,
  oid,
  oppilaitosOid
}) => {
  const {
    id,
    language,
    muutospyyntoUuid,
    oppilaitosId,
    koulutusmuoto: koulutusmuotoKebabCase
  } = useParams();
  const intl = useIntl();
  const oppilaitostyyppiFromParams = useParams().oppilaitostyyppi;
  const [baseData, setBaseData] = useState({});
  const location = useLocation();
  const lupaUuid = lupaId
    ? lupaId
    : id &&
      test(/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/, id)
    ? id
    : null;
  oppilaitosOid = oppilaitosId || oppilaitosOid;
  const parentKoulutusmuoto = getKoulutusmuotoByPredicate(
    propEq("kebabCase", koulutusmuotoKebabCase),
    intl
  );
  const parentKoulutustyyppi =
    parentKoulutusmuoto && !isEmpty(parentKoulutusmuoto.koulutustyyppi)
      ? parentKoulutusmuoto.koulutustyyppi
      : null;

  /**
   * Lupa: datan noutaminen backendistä ja sen tallentaminen
   * paikalliseen tietovarastoon jäsenneltynä.
   */
  useEffect(() => {
    let isSubscribed = true;
    fetchBaseData(
      keys,
      locale,
      lupaUuid,
      lupaUuid ? oid : id,
      koulutustyyppi,
      oppilaitostyyppi || oppilaitostyyppiFromParams,
      (koulutustyyppi === koulutustyypitMap.LUKIO ||
        (koulutustyyppi === koulutustyypitMap.TUVA &&
          parentKoulutustyyppi === koulutustyypitMap.LUKIO)) &&
        language,
      isMuutospyynnonMuokkaus,
      muutospyyntoUuid,
      koulutustyyppi === koulutustyypitMap.VAPAASIVISTYSTYO && oppilaitosOid,
      parentKoulutustyyppi
    ).then(result => {
      if (isSubscribed) {
        setBaseData(result);
      }
    });
    return () => (isSubscribed = false);
  }, [
    keys,
    locale,
    lupaUuid,
    id,
    language,
    location.pathname,
    koulutustyyppi,
    oppilaitostyyppi,
    oid,
    isMuutospyynnonMuokkaus,
    muutospyyntoUuid,
    oppilaitosId
  ]);

  if (!isEmpty(baseData)) {
    return (
      <Fragment>
        {render ? render({ ...baseData, lupaUuid, oid: id }) : null}
      </Fragment>
    );
  }
  return <Loading />;
};

BaseData.propTypes = {
  keys: PropTypes.array,
  locale: PropTypes.string,
  render: PropTypes.func,
  koulutustyyppi: PropTypes.string,
  oid: PropTypes.string,
  oppilaitostyyppi: PropTypes.string,
  lupaId: PropTypes.string,
  isMuutospyynnonMuokkaus: PropTypes.bool,
  oppilaitosOid: PropTypes.string
};

export default BaseData;
