// import "./wdyr";
import { render } from "react-dom";
import { ThroughProvider } from "react-through";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider
} from "@mui/material/styles";
import "./css/index.css";
import "./css/fontface.css";
import localforage from "localforage";
import { COLORS } from "modules/styles";
import Oiva from "./Oiva";
import { backendRoutes } from "stores/utils/backendRoutes";
import { getRaw } from "basedata";
import { indexOf, mergeDeepRight } from "ramda";
import translations from "i18n/locales";
import { setLocalizations } from "services/lomakkeet/i18n-config";
import { PaikallisenTietovarastonAvain } from "enums";
import {
  ROLE_ESITTELIJA,
  ROLE_KATSELIJA,
  ROLE_MUOKKAAJA,
  ROLE_NIMENKIRJOITTAJA,
  ROLE_YLLAPITAJA
} from "modules/constants";
import { Variant } from "./components/00-atoms/SimpleButton/SimpleButton";

// import * as serviceWorker from "./registerServiceWorker";

/**
 * By default, localForage selects backend drivers for the datastore in this
 * order:
 *
 * 1. IndexedDB
 * 2. WebSQL
 * 3. localStorage
 *
 * The storage created below will be used to store data from backend so that
 * it can be easily accessed later. One example is saving the degrees
 * (tutkinnot) with related languages (tutkintokielet).
 */
localforage.config({
  name: "Oiva App"
});

const isBackendTheSourceOfLocalizations = !process.env.USE_LOCAL_TRANSLATIONS;

const theme = createTheme({
  breakpoints: {
    values: {
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1600,
      kk: 2160, // 2k
      kkk: 3240 // 3k
    }
  },
  palette: {
    primary: {
      dark: COLORS.OIVA_DARK_GREEN,
      main: COLORS.OIVA_GREEN
    },
    secondary: {
      main: COLORS.DARK_GRAY
    },
    info: {
      main: COLORS.OIVA_ORANGE
    }
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: Variant.INFO },
          style: {
            // Tailwind ylikirjoittaa backgroundColor arvon,
            // siksi ei määritellä tässä
            color: COLORS.OIVA_GREEN,
            padding: "6px 16px 6px 0"
          }
        }
      ]
    }
  }
});

// MUI Button tarvitsee ainakin väliaikaisesti tämän
// määrityksen. Siksi tämä määritys on tehty.
theme.palette.default = {
  dark: COLORS.WHITE
};

theme.typography.useNextVariants = true;

theme.typography.h1 = {
  fontSize: "2rem",
  fontWeight: 500,
  lineHeight: "1.2",
  paddingBottom: "1rem",
  paddingTop: "1rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.875rem"
  }
};

theme.typography.h2 = {
  fontSize: "1.5rem",
  fontWeight: 500,
  lineHeight: "1.275",
  paddingBottom: "1rem",
  paddingTop: "1rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.375rem"
  }
};

theme.typography.h3 = {
  fontSize: "1.2rem",
  fontWeight: 500,
  lineHeight: "1.3",
  paddingBottom: "1rem",
  paddingTop: "1rem"
};

theme.typography.h4 = {
  fontSize: "1rem",
  fontWeight: 500,
  lineHeight: "1.5",
  paddingBottom: "1rem",
  paddingTop: "1rem"
};

theme.typography.h5 = {
  fontSize: ".875rem",
  fontWeight: 500,
  lineHeight: "1.5",
  paddingBottom: "0.875rem",
  paddingTop: "0.875rem"
};

theme.typography.p = {
  marginBottom: "1.5rem"
};

let messages = translations;

const translationsPromise = isBackendTheSourceOfLocalizations
  ? getRaw("lokalisaatio", backendRoutes.kaannokset.path, [])
  : undefined;

const userPromise = getRaw(
  "kayttaja",
  backendRoutes.kayttaja.path,
  [],
  backendRoutes.kayttaja.minimumTimeBetweenFetchingInMinutes
);

Promise.all([userPromise, translationsPromise].filter(Boolean)).then(
  async ([user, externalLocalizations]) => {
    /**
     * If user has authenticated save some of his/her information into the
     * session storage.
     */
    if (user && user.username !== sessionStorage.getItem("username")) {
      sessionStorage.setItem("username", user.username);
      sessionStorage.setItem("oid", user.oid);
      const role = [
        ROLE_YLLAPITAJA,
        ROLE_ESITTELIJA,
        ROLE_MUOKKAAJA,
        ROLE_NIMENKIRJOITTAJA,
        ROLE_KATSELIJA
      ].find(role => indexOf(role, user.roles) > -1);
      sessionStorage.setItem("role", role || "");
    }

    messages = mergeDeepRight(translations, externalLocalizations || {});

    setLocalizations(messages);

    const organisation = user
      ? await getRaw(
          PaikallisenTietovarastonAvain.ORGANISAATIO,
          `${backendRoutes.organisaatio.path}${user.oid}`,
          [],
          backendRoutes.organisaatio.minimumTimeBetweenFetchingInMinutes
        )
      : undefined;

    render(
      <ThroughProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Oiva messages={messages} organisation={organisation} user={user} />
          </ThemeProvider>
        </StyledEngineProvider>
      </ThroughProvider>,
      document.getElementById("root")
    );
  }
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
