import { defineTextBox } from "graphHandling/components/textBox/definitions/customizable";
import { CellFn, EventPayload, Modifications } from "graphHandling/graphTypes";
import common from "i18n/definitions/common";
import { CellId } from "processes/CellId";
import { Muutospyynto } from "types";
import { find, isEmpty, isNil, path, propEq, reject } from "ramda";
import { ITextBox } from "utils/lomakkeet";
import { Lupa } from "Lupa";

export const initialize_eiMaariteltyKuvaus: CellFn = (storeFunctions, intl) => {
  const onChange = (payload: EventPayload) => {
    storeFunctions.addModification(CellId.INITIALIZE_EI_MAARITELTY_KUVAUS, {
      value: payload.changeProps.value
    });
    return true;
  };

  const lupa = storeFunctions.readPath(["custom", "viimeisinLupa"]) as Lupa;

  let FI1Maarays = null;
  let FI2Maarays = null;

  if (lupa) {
    const { maaraykset } = lupa;
    FI1Maarays = find(propEq("koodiarvo", "FI1"), maaraykset);
    FI2Maarays = find(propEq("koodiarvo", "FI2"), maaraykset);
  }

  const muutospyynto = storeFunctions.readPath([
    "custom",
    "muutospyynto"
  ]) as Muutospyynto;

  let FI2Muutos = null;

  if (muutospyynto) {
    const { muutokset } = muutospyynto;
    FI2Muutos = find(propEq("koodiarvo", "FI2"), muutokset);
  }

  const label = intl.formatMessage(common.kuvaus);
  const placeholder = intl.formatMessage(common.kuvausPlaceholder);
  const title = intl.formatMessage(common.kuvaus);

  const defaultProps = {
    isHidden: !!FI1Maarays || !!FI2Maarays,
    label,
    placeholder,
    title,
    value: FI2Maarays ? (FI2Maarays.meta.arvo as string) : ""
  };

  const modifications: Modifications = {};

  if (FI2Muutos) {
    const backendMods = reject(isNil, {
      isHidden: !FI2Muutos,
      value:
        FI2Muutos && path(["meta", "arvo"], FI2Muutos) !== defaultProps.value
          ? path(["meta", "arvo"], FI2Muutos)
          : undefined
    });
    if (!isEmpty(backendMods)) {
      modifications.backend = backendMods;
    }
  }

  return defineTextBox(
    defaultProps,
    {
      onChange
    },
    isEmpty(modifications) ? undefined : modifications
  ) as ITextBox;
};
