import { API_BASE_URL } from "modules/constants";
import { is, startsWith } from "ramda";

/**
 * Open file using generated and hidden <a> element
 * @param obj containing properties filename and tiedosto or property url. Has optional parameter openInNewWindow
 */
export const downloadFileFn = ({
  filename,
  tiedosto,
  url,
  openInNewWindow
}: {
  filename: string;
  tiedosto: typeof Blob;
  url: string;
  openInNewWindow: boolean;
}) => {
  return (): boolean => {
    const a = document.createElement("a");
    a.setAttribute("type", "hidden");
    if (openInNewWindow) {
      a.setAttribute("target", "_blank");
      a.setAttribute("rel", "noopener noreferer");
    }

    document.body.appendChild(a); // Needed for Firefox
    if (tiedosto && tiedosto instanceof Blob) {
      const reader = new FileReader();
      reader.readAsDataURL(tiedosto);
      reader.onload = function () {
        if (is(String, reader.result)) {
          a.href = reader.result;
        }
        a.download = filename;
        a.click();
        a.remove();
      };
    } else if (url) {
      a.href = startsWith("/api", url) ? url : API_BASE_URL + url;
      a.click();
      a.remove();
    } else {
      console.warn("Cannot open file: No octet stream nor file url");
      return false;
    }
    return true;
  };
};

export const downloadFile = (url: string): boolean => {
  if (url) {
    const a = document.createElement("a");
    a.setAttribute("type", "hidden");
    a.href = startsWith("/api", url) ? url : API_BASE_URL + url;
    a.download = "[Tiedoston nimi tähän]";
    a.click();
    a.remove();
    return true;
  }
  return false;
};
