import { find, propEq } from "ramda";
import { Koodistotietue } from "koodistodatanTyypit";
import { FKoodistotietue } from "koodistodatanTyypitFrontille";
import { luoFronttiystavallinenVersioKoodistotietueesta } from "helpers/koodistodatanKasittely";

/**
 * Luo kunnan tiedoista frontille sopivan muodon.
 * @param kuntaRaw Kunnan tiedot, joiden pohjalta uusi objekti luodaan.
 * @param kunnatJoitaEiTarvitaSovelluksessa Taulukollinen kuntia, joita
 * ei tarvita ja joita ei näytetä käyttäjille sovelluksessa. Todennäköisesti
 * nämä ovat yhtä kuin Ahvenanmaan kunnat.
 * @returns
 */
export function initializeKunta(
  koodistotietue: Koodistotietue,
  kunnatJoitaEiTarvitaSovelluksessa: Array<FKoodistotietue> = []
): FKoodistotietue | null {
  const currentDate = new Date();
  return currentDate >= new Date(koodistotietue.voimassaAlkuPvm) &&
    currentDate <= new Date(koodistotietue.voimassaLoppuPvm || currentDate) &&
    !find(
      propEq("koodiarvo", koodistotietue.koodiArvo),
      kunnatJoitaEiTarvitaSovelluksessa
    )
    ? luoFronttiystavallinenVersioKoodistotietueesta(koodistotietue)
    : null;
}
