import { CellFn } from "graphHandling/graphTypes";
import localforage from "localforage";
import { CellId } from "processes/CellId";
import { PaikallisenTietovarastonAvain } from "enums";

const tallennaPaikalliseenTietovarastoon = async (
  avain: string,
  data: unknown
): Promise<boolean> => {
  try {
    await localforage.setItem(avain, data);
    return true;
  } catch (err) {
    console.warn(err);
    return false;
  }
};

export const tallenna_lisatietojaKoodistoPaikalliseenTietovarastoon: CellFn =
  async (storeFunctions, intl, eventPayload, result) => {
    return tallennaPaikalliseenTietovarastoon(
      PaikallisenTietovarastonAvain.LISATIEDOT,
      result[CellId.KASITTELE_KOODISTO_LISATIETOJA]
    );
  };

export const tallenna_opetustehtavatKoodistoPaikalliseenTietovarastoon: CellFn =
  async (storeFunctions, intl, eventPayload, result) => {
    return tallennaPaikalliseenTietovarastoon(
      PaikallisenTietovarastonAvain.OPETUSTEHTAVAKOODISTO,
      result[CellId.KASITTELE_KOODISTO_OPETUSTEHTAVAT]
    );
  };

export const tallenna_opetustehtavatPaikalliseenTietovarastoon: CellFn = async (
  storeFunctions,
  intl,
  eventPayload,
  result
) => {
  return tallennaPaikalliseenTietovarastoon(
    PaikallisenTietovarastonAvain.OPETUSTEHTAVAT,
    result[CellId.KASITTELE_OPETUSTEHTAVAT]
  );
};

export const tallenna_poErityisetKoulutustehtavatKoodistoPaikalliseenTietovarastoon: CellFn =
  async (storeFunctions, intl, eventPayload, result) => {
    return tallennaPaikalliseenTietovarastoon(
      PaikallisenTietovarastonAvain.PO_ERITYISET_KOULUTUSTEHTAVAT,
      result[CellId.KASITTELE_KOODISTO_PO_ERITYISET_KOULUTUSTEHTAVAT]
    );
  };

export const tallenna_poMuutEhdotKoodistoPaikalliseenTietovarastoon: CellFn =
  async (storeFunctions, intl, eventPayload, result) => {
    return tallennaPaikalliseenTietovarastoon(
      PaikallisenTietovarastonAvain.PO_MUUT_EHDOT,
      result[CellId.KASITTELE_KOODISTO_PO_MUUT_EHDOT]
    );
  };

export const tallenna_ajallaKoodistoPaikalliseenTietovarastoon: CellFn = async (
  storeFunctions,
  intl,
  eventPayload,
  result
) => {
  return tallennaPaikalliseenTietovarastoon(
    PaikallisenTietovarastonAvain.AJALLA,
    result[CellId.KASITTELE_KOODISTO_AJALLA]
  );
};

export const tallenna_joistalisaksiKoodistoPaikalliseenTietovarastoon: CellFn =
  async (storeFunctions, intl, eventPayload, result) => {
    return tallennaPaikalliseenTietovarastoon(
      PaikallisenTietovarastonAvain.JOISTA_LISAKSI,
      result[CellId.KASITTELE_KOODISTO_JOISTA_LISAKSI]
    );
  };

export const tallenna_kujalisamaareetKoodistoPaikalliseenTietovarastoon: CellFn =
  async (storeFunctions, intl, eventPayload, result) => {
    return tallennaPaikalliseenTietovarastoon(
      PaikallisenTietovarastonAvain.KUJALISAMAAREET,
      result[CellId.KASITTELE_KOODISTO_KUJALISAMAAREET]
    );
  };

export const tallenna_opetuksenJarjestamisMuodotKoodistoPaikalliseenTietovarastoon: CellFn =
  async (storeFunctions, intl, eventPayload, result) => {
    return tallennaPaikalliseenTietovarastoon(
      PaikallisenTietovarastonAvain.OPETUKSEN_JARJESTAMISMUODOT,
      result[CellId.KASITTELE_KOODISTO_OPETUKSEN_JARJESTAMISMUODOT]
    );
  };

export const tallenna_ensisijaisetOpetuskieletOPHKoodistoPaikalliseenTietovarastoon: CellFn =
  async (storeFunctions, intl, eventPayload, result) => {
    return tallennaPaikalliseenTietovarastoon(
      PaikallisenTietovarastonAvain.ENSISIJAISET_OPETUSKIELET_OPH,
      result[CellId.KASITTELE_KOODISTO_ENSIJAISET_OPETUSKIELET_OPH]
    );
  };

export const tallenna_toissijaisetOpetuskieletOPHKoodistoPaikalliseenTietovarastoon: CellFn =
  async (storeFunctions, intl, eventPayload, result) => {
    return tallennaPaikalliseenTietovarastoon(
      PaikallisenTietovarastonAvain.TOISSIJAISET_OPETUSKIELET_OPH,
      result[CellId.KASITTELE_KOODISTO_TOISSIJAISET_OPETUSKIELET_OPH]
    );
  };

export const tallennaKohteetPaikalliseenTietovarastoon: CellFn = async (
  storeFunctions,
  intl,
  eventPayload,
  result
) => {
  return tallennaPaikalliseenTietovarastoon(
    PaikallisenTietovarastonAvain.KOHTEET,
    result[CellId.FETCH_KOHTEET]
  );
};

export const tallennaMaaraystyypitPaikalliseenTietovarastoon: CellFn = async (
  storeFunctions,
  intl,
  eventPayload,
  result
) => {
  return tallennaPaikalliseenTietovarastoon(
    PaikallisenTietovarastonAvain.MAARAYSTYYPIT,
    result[CellId.FETCH_MAARAYSTYYPIT]
  );
};
