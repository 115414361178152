import { Toiminto } from "enums";
import { defineTextBox } from "graphHandling/components/textBox/definitions/customizable";
import { CellFn, Modifications } from "graphHandling/graphTypes";
import common from "i18n/definitions/common";
import { Lupa } from "Lupa";
import { find, path } from "ramda";
import { Muutospyynto } from "types";
import { KOODISTOT } from "utils/constants";
import { Properties } from "utils/lomakkeet";

export const initialize_lisatiedot: CellFn = async (storeFunctions, intl) => {
  if (!intl) {
    console.warn("Parametri intl puuttuu.");
    return false;
  }

  const custom = storeFunctions.readPath(["custom"]) as Properties;

  const muutospyynto = custom.muutospyynto as Muutospyynto;
  const viimeisinLupa = custom.viimeisinLupa as Lupa;

  const maarays = viimeisinLupa
    ? find(maarays => {
        return (
          maarays.koodisto === KOODISTOT.LISATIETOJA &&
          maarays.kohde.tunniste === "kunnatjoissaopetustajarjestetaan"
        );
      }, viimeisinLupa.maaraykset)
    : null;

  const defaultValue = maarays ? (maarays.meta.arvo as string) : "";

  let modifications: Modifications | undefined = undefined;

  if (muutospyynto) {
    const muutos = find(muutos => {
      return (
        muutos.koodisto === KOODISTOT.LISATIETOJA &&
        muutos.kohde.tunniste === "kunnatjoissaopetustajarjestetaan"
      );
    }, muutospyynto.muutokset);

    if (muutos && muutos.tila === Toiminto.LISAYS) {
      modifications = {
        backend: { value: path(["meta", "arvo"], muutos) as string }
      };
    }
  }

  return defineTextBox(
    {
      id: "toimintaalue.lisatiedot.1",
      label: intl.formatMessage(common.lisatiedot),
      placeholder: "",
      title: intl.formatMessage(common.lisatiedot),
      value: defaultValue
    },
    {
      onChange: payload => {
        return payload;
      }
    },
    modifications
  );
};
