import { CellFn } from "graphHandling/graphTypes";
import { getKoulutusmuotovaihtoehdot } from "graphHandling/options/koulutusmuodovaihtoehdot";
import { CellId } from "processes/CellId";

export const koulutusmuotojenNoutaminen: CellFn = (storeFunctions, intl) => {
  if (!intl) {
    console.warn("Parametri intl puuttuu.");
    return false;
  }
  storeFunctions.updateGraph(
    ["components", CellId.INITIALIZE_KOULUTUSMUOTOVALIKKO, "properties"],
    {
      options: getKoulutusmuotovaihtoehdot(intl)
    }
  );

  return true;
};
