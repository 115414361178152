import { Typography } from "@mui/material";
import common from "i18n/definitions/common";
import { useIntl } from "react-intl";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { NavLink, Redirect, Route, Router, useHistory } from "react-router-dom";
import { find, prop, propEq, toUpper } from "ramda";
import { LocalizedSwitch } from "modules/i18n/index";
import { AppRoute } from "routes/index";
import { localizeRouteKey } from "utils/common";
import { PropTypes } from "prop-types";
import { PaikallisenTietovarastonAvain } from "enums";
import { getRouteUrl } from "../../../helpers/routes";
import BaseData from "basedata";
import JarjestajaSwitch from "../JarjestajaSwitch";
import Asianhallinta from "components/03-templates/Asianhallinta/index";
import Jarjestajat from "../Jarjestajat/index";
import { useEffect, useState } from "react";
import { useLuvat } from "../../../stores/luvat";

const keys2 = [PaikallisenTietovarastonAvain.ORGANISAATIO, "tulevatLuvat"];

export default function KoulutusmuodonEtusivu({
  hakuavaimet,
  Jarjestajaluettelo,
  jarjestajatOtsikko,
  JarjestamislupaJSX,
  koulutusmuoto,
  kuvausteksti,
  paasivunOtsikko,
  UusiAsiaEsidialog,
  WizardContainer,
  esidialoginHakuavaimet,
  vstTyypit
}) {
  const history = useHistory();
  const intl = useIntl();
  const { formatMessage, locale } = intl;
  const user = {
    oid: sessionStorage.getItem("oid")
  };
  const [pageNo, setPageNo] = useState(1);
  const [searchBy, setSearchBy] = useState({});

  const koulutusmuotoUrl = localizeRouteKey(
    locale,
    getRouteUrl(koulutusmuoto.route),
    intl
  );

  useEffect(() => {
    setPageNo(1);
  }, [searchBy]);

  const [luvat, luvatActions] = useLuvat();

  // Let's fetch LUVAT
  useEffect(() => {
    const abortController = luvatActions.load(
      [
        koulutusmuoto.koulutustyyppi
          ? { key: "koulutustyyppi", value: koulutusmuoto.koulutustyyppi }
          : null
      ].filter(Boolean)
    );
    return function cancel() {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [koulutusmuoto, luvatActions, hakuavaimet]);

  const [vstTypeOptions, setVstTypeOptions] = useState([]);
  const [vstOppilaitostyyppiFilter, setVstOppilaitostyyppiFilter] =
    useState("");

  useEffect(() => {
    if (vstTyypit) {
      const vstOptions = [];
      vstOptions.push({
        value: "",
        label: intl.formatMessage(common.noSelection)
      });
      vstTyypit.forEach(item => {
        vstOptions.push({
          value: item.metadata[toUpper(intl.locale)].nimi,
          label: item.metadata[toUpper(intl.locale)].nimi
        });
      });
      setVstTypeOptions(vstOptions);
    }
  }, [vstTyypit, intl]);

  return (
    <div className="flex-1 flex flex-col bg-white">
      <div className="flex-1 flex flex-col">
        <BreadcrumbsItem to={koulutusmuotoUrl}>
          {koulutusmuoto.paasivunOtsikko}
        </BreadcrumbsItem>
        <Router history={history}>
          <LocalizedSwitch>
            {!!user.oid && (
              <Route
                path={AppRoute.Asianhallinta}
                render={() => (
                  <Asianhallinta
                    esidialoginHakuavaimet={esidialoginHakuavaimet}
                    hakuavaimet={hakuavaimet}
                    koulutusmuoto={koulutusmuoto}
                    user={user}
                    UusiAsiaEsidialog={UusiAsiaEsidialog}
                    WizardContainer={WizardContainer}
                  />
                )}
              />
            )}
            <Route
              path={AppRoute.Koulutustoimijat}
              render={props => {
                return (
                  <BaseData
                    keys={hakuavaimet}
                    locale={locale}
                    koulutustyyppi={koulutusmuoto.koulutustyyppi}
                    render={_props1 => {
                      //  Tämä toteutus olisi paljon yksinkertaisempi, jos
                      //  kaikkien opetusmuotojen lupatietojen noutamisen
                      //  voisi tehdä samalla tavalla. Vapaa sivistystyo
                      //  on kuitenkin poikkeus, koska VST-luvat noudetaan
                      //  lupaUuid:n avulla. Muiden koulutusmuotojen luvat
                      //  voidaan noutaa y-tunnusta käyttämällä.
                      //
                      //  Y-tunnuksen ollessa tiedossa, saadaan luvan
                      //  lisäksi noudettua myös organisaation tiedot.
                      //  VST:n tapauksessa täytyy noutaa ensin lupa
                      //  ja käyttää luvalta löytyvää y-tunnusta
                      //  organisaatiotietojen hakemiseen.
                      if (_props1.organisaatio) {
                        return (
                          <JarjestajaSwitch
                            JarjestamislupaJSX={JarjestamislupaJSX}
                            kohteet={_props1.kohteet}
                            koulutusmuoto={koulutusmuoto}
                            lupa={_props1.lupa}
                            organisation={_props1.organisaatio}
                            path={props.match.path}
                            user={user}
                            tulevatLuvat={_props1.tulevatLuvat}
                            voimassaOlevaLupa={_props1.voimassaOlevaLupa}
                            WizardContainer={WizardContainer}
                            ytunnus={_props1.ytunnus}
                          />
                        );
                      } else if (_props1.lupa && _props1.lupa.jarjestajaOid) {
                        return (
                          <BaseData
                            keys={keys2}
                            locale={locale}
                            koulutustyyppi={koulutusmuoto.koulutustyyppi}
                            render={_props2 => {
                              if (_props2.organisaatio) {
                                return (
                                  <JarjestajaSwitch
                                    JarjestamislupaJSX={JarjestamislupaJSX}
                                    kohteet={_props1.kohteet}
                                    koulutusmuoto={koulutusmuoto}
                                    lupa={_props1.lupa}
                                    lupaUuid={_props1.lupaUuid}
                                    organisation={_props2.organisaatio}
                                    path={props.match.path}
                                    tulevatLuvat={_props2.tulevatLuvat}
                                    voimassaOlevaLupa={
                                      _props1.voimassaOlevaLupa
                                    }
                                    WizardContainer={WizardContainer}
                                    user={user}
                                  />
                                );
                              }
                            }}
                            oppilaitostyyppi={
                              _props1.voimassaOlevaLupa
                                ? _props1.voimassaOlevaLupa.oppilaitostyyppi
                                : null
                            }
                            oid={_props1.lupa.jarjestajaOid}
                            oppilaitosOid={prop(
                              "orgOid",
                              find(
                                propEq("koodisto", "oppilaitos"),
                                prop("maaraykset", _props1.lupa)
                              )
                            )}
                          />
                        );
                      }
                    }}
                  />
                );
              }}
            />
            <Route exact={true} path={AppRoute[koulutusmuoto.pascalCase]}>
              <article className="mx-auto w-4/5 mt-12 max-w-8xl">
                <Typography component="h1" variant="h1">
                  {paasivunOtsikko}
                </Typography>
                <p className="max-w-213 mb-6">{kuvausteksti}</p>
                {user.oid ? (
                  <p className="mb-6">
                    <NavLink
                      to={localizeRouteKey(
                        locale,
                        AppRoute.AsianhallintaAvoimet,
                        intl,
                        {
                          koulutusmuoto: koulutusmuoto.kebabCase
                        }
                      )}
                      className="block underline">
                      {formatMessage(common.asianhallinta)}
                    </NavLink>
                  </p>
                ) : null}
                <Typography component="h2" variant="h2">
                  {jarjestajatOtsikko}
                </Typography>
                <section>
                  {Jarjestajaluettelo ? (
                    <Jarjestajat
                      luvat={luvat}
                      koulutusmuoto={koulutusmuoto}
                      Jarjestajaluettelo={Jarjestajaluettelo}
                      paasivunOtsikko={paasivunOtsikko}
                      pageNo={pageNo}
                      setPageNo={setPageNo}
                      searchBy={searchBy}
                      setSearchBy={setSearchBy}
                      vstTyypit={vstTyypit}
                      vstOppilaitostyyppiFilter={vstOppilaitostyyppiFilter}
                      setVstOppilaitostyyppiFilter={
                        setVstOppilaitostyyppiFilter
                      }
                      vstTypeOptions={vstTypeOptions}
                    />
                  ) : null}
                </section>
              </article>
            </Route>
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </LocalizedSwitch>
        </Router>
      </div>
    </div>
  );
}

KoulutusmuodonEtusivu.propTypes = {
  hakuavaimet: PropTypes.array,
  Jarjestajaluettelo: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  jarjestajatOtsikko: PropTypes.string,
  JarjestamislupaJSX: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  koulutusmuoto: PropTypes.object,
  kuvausteksti: PropTypes.string,
  match: PropTypes.object,
  paasivunOtsikko: PropTypes.string,
  UusiAsiaEsidialog: PropTypes.func,
  WizardContainer: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  esidialoginHakuavaimet: PropTypes.array,
  vstTyypit: PropTypes.array
};
