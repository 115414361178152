import {
  compose,
  concat,
  endsWith,
  filter,
  find,
  flatten,
  includes,
  map,
  path,
  pathEq,
  prop,
  toUpper,
  values,
  without
} from "ramda";
import { PaikallisenTietovarastonAvain } from "enums";
import localForage from "localforage";

export default async function getKunnat(
  isReadOnly,
  osionData = [],
  locale,
  isMulti,
  inputId
) {
  const localeUpper = toUpper(locale);
  const kunnat = await localForage.getItem(
    PaikallisenTietovarastonAvain.KUNNAT
  );

  const areaOfAction = find(
    compose(endsWith(".maakunnatjakunnat"), prop("anchor")),
    osionData
  );

  if (kunnat) {
    // Koska osion data ei ole ajantasalla johtuen kuntaosion monimutkaisesta
    // rakenteesta, on osion muutoksia tarkkailtava, jotta käyttäjälle osataan
    // näyttää vain ja ainoastaan kunnat, jotka ovat valittuina päälomakkeella.
    const muutoksillaValitutKunnat = areaOfAction
      ? map(
          path(["properties", "metadata", "koodiarvo"]),
          filter(changeObj => {
            return pathEq(["properties", "isChecked"], true, changeObj);
          }, flatten(values(areaOfAction.properties.changeObjectsByProvince)))
        )
      : [];

    const oletusarvoinValitutKunnat = areaOfAction
      ? map(prop("koodiarvo"), areaOfAction.properties.currentMunicipalities)
      : [];

    const muutoksillaPoistetutKunnat = areaOfAction
      ? map(
          path(["properties", "metadata", "koodiarvo"]),
          filter(changeObj => {
            return pathEq(["properties", "isChecked"], false, changeObj);
          }, flatten(values(areaOfAction.properties.changeObjectsByProvince)))
        )
      : [];

    const valitutKunnat = without(
      concat(["200"], muutoksillaPoistetutKunnat),
      concat(oletusarvoinValitutKunnat, muutoksillaValitutKunnat)
    );

    return [
      {
        anchor: "komponentti",
        name: "Autocomplete",
        styleClasses: ["w-4/5", "xl:w-2/3", "mb-6"],
        properties: {
          forChangeObject: {
            section: "muutKunnatJoistaOppilaita"
          },
          inputId,
          isMulti,
          isReadOnly,
          options: map(kunta => {
            const { koodiarvo, metadata } = kunta;
            return includes(koodiarvo, valitutKunnat)
              ? { label: metadata[localeUpper].nimi, value: koodiarvo }
              : null;
          }, kunnat).filter(Boolean),
          value: ""
        }
      }
    ];
  } else {
    return [
      {
        anchor: "ei-kuntia",
        name: "StatusTextRow",
        properties: {
          title: "Ei valintamahdollisuutta."
        }
      }
    ];
  }
}
