import { useMemo, useCallback, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { parseLupa } from "utils/lupaParser";
import { append, flatten, isEmpty, isNil, prop, reject, toUpper } from "ramda";
import Wizard from "components/03-templates/Wizard/index";
import wizard from "i18n/definitions/wizard";
import common from "i18n/definitions/common";
import EsittelijatMuutospyynto from "./EsittelijatMuutospyynto";
import MuutospyyntoWizardTaloudelliset from "./Jarjestajat/Jarjestaja/Hakemukset/Muutospyynto/components/MuutospyyntoWizardTaloudelliset";
import MuutospyyntoWizardYhteenveto from "./Jarjestajat/Jarjestaja/Hakemukset/Muutospyynto/components/MuutospyyntoWizardYhteenveto";
import { createMuutospyyntoOutput } from "utils/muutospyyntoUtil";
import { createObjectToSave } from "scenes/Koulutusmuodot/AmmatillinenKoulutus/saving";
import {
  useChangeObjects,
  useChangeObjectsByAnchorWithoutUnderRemoval
} from "stores/muutokset";
import { useAllSections } from "stores/lomakedata";
import ProcedureHandler from "components/02-organisms/procedureHandler/index";
import { getSavedChangeObjects } from "utils/frontendMuutoksetMuutospyynnolla";
import { localizeRouteKey } from "utils/common";
import { AppRoute } from "routes/index";
import { getUrlOnClose } from "components/03-templates/Wizard/wizardUtils";
import { PropTypes } from "prop-types";
import { useProcess000 } from "graphs/storeHandling";
import {
  cellImplementations,
  processDefinition
} from "graphs/muutospyynto/processDefinition";
import { createProcessCollection } from "graphHandling/graphProcessHandling";
import { CellId } from "processes/CellId";

const processId = "000";

/**
 * Container component of Wizard.
 *
 * @param {Object} props - Props object.
 * @param {Object} props.intl - Object of react-intl library.
 */
const WizardContainer = ({
  kohteet,
  koulutukset,
  koulutusalat,
  koulutusmuoto,
  koulutustyypit,
  maaraystyypit,
  muut,
  organisaatio,
  role,
  viimeisinLupa,
  muutospyynto
}) => {
  const [pc, setPC] = useState();
  const [stateOfProcess_000, actions] = useProcess000();
  const intl = useIntl();
  const { formatMessage, locale } = intl;

  let { id, language, muutospyyntoUuid } = useParams();
  let history = useHistory();

  const [lomakedata] = useAllSections();
  const [isSaving, setIsSaving] = useState();
  const [muutospyynnonTila, setMuutospyynnonTila] = useState();
  const [naytaMuutokset, setNaytaMuutokset] = useState(false);
  const [muutostenMaara, setMuutostenMaara] = useState(0);
  // Relevantit muutosobjektit osioittain (tarvitaan tallennettaessa)
  const [topThreeCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "topthree"
  });
  const [tutkinnotCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "tutkinnot"
  });
  const [tutkinnotLisatiedotCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "lisatiedot_tutkinnot"
  });
  const [koulutuksetCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "koulutukset"
  });
  const [koulutuksetLisatiedotCO] = useChangeObjectsByAnchorWithoutUnderRemoval(
    {
      anchor: "lisatiedot_koulutukset"
    }
  );
  const [opetuskieletCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "kielet_opetuskielet"
  });
  const [tutkintokieletCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "kielet_tutkintokielet"
  });
  const [opetuskieletLisatiedotCO] =
    useChangeObjectsByAnchorWithoutUnderRemoval({
      anchor: "lisatiedot_opetuskielet"
    });
  const [toimintaalueCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "toimintaalue"
  });
  const [toimintaalueLisatiedotCO] =
    useChangeObjectsByAnchorWithoutUnderRemoval({
      anchor: "lisatiedot_toimintaalue"
    });
  const [opiskelijavuodetCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "opiskelijavuodet"
  });
  const [opiskelijavuodetLisatiedotCO] =
    useChangeObjectsByAnchorWithoutUnderRemoval({
      anchor: "lisatiedot_opiskelijavuodet"
    });
  const [muutCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "muut"
  });

  const [muutMuuMaarays, setMuutMuuMaarays] = useState([]);

  const [unsaved, setUnsaved] = useState([]);

  useEffect(() => {
    if (pc) {
      const process_muutMuuMaarays = pc.getProcess("muutMuuMaarays");
      const components_muutMuuMaarays = process_muutMuuMaarays
        ? process_muutMuuMaarays.actions.readPath(["components"])
        : [];
      let unsaved = [];

      if (process_muutMuuMaarays) {
        unsaved = append(
          process_muutMuuMaarays.actions.readPath(["custom", "unsaved"]),
          unsaved
        );
      }

      const nextUnsaved = reject(isNil, flatten(unsaved));

      setUnsaved(nextUnsaved);

      setMuutMuuMaarays(components_muutMuuMaarays);
    }
  }, [stateOfProcess_000]);

  // Luodaan muutospyynnön eri osioita varten ProcessCollection.
  useEffect(() => {
    const pc = createProcessCollection("Muutospyyntö", intl);
    pc.addProcess(
      {
        actions,
        CellId,
        cellImplementations,
        customParams: {
          muutospyyntoUuid,
          orgnisaationOid: id
        },
        processDefinition
      },
      processId
    );
    pc.handleIncomingProcessToken(CellId.INITIALIZE_000, processId);
    setPC(pc);
  }, []);

  const [{ isPreviewModeOn }, { initializeChanges, setPreviewMode }] =
    useChangeObjects();

  const lupaKohteet = useMemo(() => {
    const result = !isEmpty(viimeisinLupa)
      ? parseLupa({ ...viimeisinLupa }, formatMessage, locale.toUpperCase())
      : {};
    return result;
  }, [formatMessage, locale, viimeisinLupa]);

  useEffect(() => {
    const changeObjectsFromBackend = getSavedChangeObjects(muutospyynto);
    initializeChanges(changeObjectsFromBackend);
    setMuutospyynnonTila(prop("tila", muutospyynto));
  }, [muutospyynto, initializeChanges]);

  const onNewDocSave = useCallback(
    uuid => {
      /**
       * User is redirected to the url of the saved document.
       */
      history.push(
        localizeRouteKey(locale, AppRoute.Hakemus, intl, {
          id,
          koulutusmuoto: koulutusmuoto.kebabCase,
          language,
          page: 1,
          muutospyyntoUuid: uuid
        })
      );
    },
    [history, id, koulutusmuoto, language, locale, formatMessage]
  );

  const onPreview = useCallback(async () => {
    setNaytaMuutokset(false);
    return setPreviewMode(!isPreviewModeOn);
  }, [isPreviewModeOn, setPreviewMode]);

  /**
   * Saves the form.
   * @param {object} formData
   * @returns {object} - Muutospyyntö
   */
  const onSave = useCallback(
    async formData => {
      setIsSaving(true);
      const procedureHandler = new ProcedureHandler(formatMessage);
      const outputs = await procedureHandler.run(
        "muutospyynto.tallennus.tallennaEsittelijanToimesta",
        [formData]
      );
      setIsSaving(false);
      return outputs.muutospyynto.tallennus.tallennaEsittelijanToimesta.output
        .result;
    },
    [formatMessage]
  );

  // KJ eli koulutuksen järjestäjä on velvolliinen perustelemaan hakemansa
  // muutokset, esittelijä ei ole. Siksi esittelijän tarvitsee täyttää vain
  // lomakewizardin ensimmäinen sivu.
  const [steps] = useState(
    role === "KJ"
      ? [
          {
            title: formatMessage(wizard.pageTitle_1)
          },
          {
            title: formatMessage(wizard.pageTitle_2)
          },
          {
            title: formatMessage(wizard.pageTitle_3)
          },
          {
            title: formatMessage(wizard.pageTitle_4)
          }
        ]
      : null
  );

  const onAction = useCallback(
    async (action, fromDialog = false, muutospyynnonTila) => {
      const formData = createMuutospyyntoOutput(
        await createObjectToSave(
          muutMuuMaarays,
          toUpper(locale),
          organisaatio,
          viimeisinLupa,
          {
            koulutukset: koulutuksetCO,
            muut: muutCO,
            opetuskielet: opetuskieletCO,
            opiskelijavuodet: opiskelijavuodetCO,
            toimintaalue: toimintaalueCO,
            topthree: topThreeCO,
            tutkinnot: tutkinnotCO,
            tutkinnotLisatiedot: tutkinnotLisatiedotCO,
            koulutuksetLisatiedot: koulutuksetLisatiedotCO,
            tutkintokielet: tutkintokieletCO,
            opetuskieletLisatiedot: opetuskieletLisatiedotCO,
            toimintaalueLisatiedot: toimintaalueLisatiedotCO,
            opiskelijavuodetLisatiedot: opiskelijavuodetLisatiedotCO
          },
          muutospyyntoUuid,
          kohteet,
          maaraystyypit,
          muut,
          lupaKohteet,
          lomakedata,
          muutospyynnonTila,
          koulutukset
        )
      );

      let muutospyynto = null;

      if (action === "save") {
        muutospyynto = await onSave(formData);
      } else if (action === "preview") {
        muutospyynto = await onPreview(formData);
      } else if (action == "showChanges") {
        setNaytaMuutokset(!naytaMuutokset);
      }

      if (!!muutospyynto && prop("uuid", muutospyynto)) {
        if (!muutospyyntoUuid && !fromDialog && !!onNewDocSave) {
          // Jos kyseessä on ensimmäinen tallennus...
          onNewDocSave(muutospyynto.uuid);
        } else {
          window.location.reload();
        }
      }
    },
    [
      muutMuuMaarays,
      kohteet,
      initializeChanges,
      locale,
      koulutuksetCO,
      lomakedata,
      viimeisinLupa,
      lupaKohteet,
      maaraystyypit,
      muut,
      muutCO,
      onNewDocSave,
      onPreview,
      onSave,
      opetuskieletCO,
      opiskelijavuodetCO,
      organisaatio,
      toimintaalueCO,
      topThreeCO,
      tutkinnotCO,
      tutkintokieletCO,
      muutospyyntoUuid,
      naytaMuutokset
    ]
  );

  const urlOnClose = getUrlOnClose(
    role,
    locale,
    intl,
    organisaatio,
    koulutusmuoto,
    muutospyyntoUuid
  );

  return (
    <Wizard
      page1={
        <EsittelijatMuutospyynto
          kohteet={kohteet}
          koulutukset={koulutukset}
          koulutusalat={koulutusalat}
          koulutustyypit={koulutustyypit}
          maaraykset={viimeisinLupa ? viimeisinLupa.maaraykset : []}
          lupaKohteet={lupaKohteet}
          maaraystyypit={maaraystyypit}
          mode={"modification"}
          muut={muut}
          pc={pc}
          role={role}
          title={role === "KJ" ? formatMessage(common.changesText) : null}
          naytaMuutokset={naytaMuutokset}
          setMuutostenMaara={setMuutostenMaara}
          organisation={organisaatio}
        />
      }
      page2={
        role === "KJ" ? (
          <EsittelijatMuutospyynto
            kohteet={kohteet}
            koulutukset={koulutukset}
            koulutusalat={koulutusalat}
            koulutustyypit={koulutustyypit}
            maaraykset={viimeisinLupa ? viimeisinLupa.maaraykset : []}
            lupaKohteet={lupaKohteet}
            maaraystyypit={maaraystyypit}
            mode={"reasoning"}
            muut={muut}
            pc={pc}
            role={role}
            title={formatMessage(wizard.pageTitle_2)}
          />
        ) : null
      }
      page3={
        role === "KJ" ? (
          <MuutospyyntoWizardTaloudelliset
            isReadOnly={false}
            // isFirstVisit={visitsPerPage[3] === 1}
          />
        ) : null
      }
      page4={
        role === "KJ" ? (
          <MuutospyyntoWizardYhteenveto
            history={history}
            kohteet={kohteet}
            koulutukset={koulutukset}
            koulutusalat={koulutusalat}
            koulutustyypit={koulutustyypit}
            lupa={viimeisinLupa}
            lupaKohteet={lupaKohteet}
            maaraykset={viimeisinLupa ? viimeisinLupa.maaraykset : []}
            maaraystyypit={maaraystyypit}
            mode="reasoning"
            muut={muut}
            // isFirstVisit={visitsPerPage[4] === 1}
          />
        ) : null
      }
      isSaving={isSaving}
      koulutusmuoto={koulutusmuoto}
      onAction={onAction}
      organisation={organisaatio}
      steps={steps}
      tila={muutospyynnonTila}
      title={formatMessage(wizard.esittelijatMuutospyyntoDialogTitle)}
      urlOnClose={urlOnClose}
      naytaMuutokset={naytaMuutokset}
      muutostenMaara={muutostenMaara}
      unsaved={unsaved}
    />
  );
};

WizardContainer.propTypes = {
  kohteet: PropTypes.array,
  koulutukset: PropTypes.object,
  koulutusalat: PropTypes.array,
  koulutusmuoto: PropTypes.object,
  koulutustyypit: PropTypes.array,
  maaraystyypit: PropTypes.array,
  muut: PropTypes.array,
  organisaatio: PropTypes.object,
  role: PropTypes.string,
  viimeisinLupa: PropTypes.object,
  muutospyynto: PropTypes.object
};

export default WizardContainer;
