import { defineSimpleButton } from "graphHandling/components/simpleButton/definitions/customizable";
import { CellFn } from "graphHandling/graphTypes";
import wizardMessages from "i18n/definitions/wizard";
import { getKoulutusmuotoByPredicate, localizeRouteKey } from "utils/common";
import { CellId } from "processes/CellId";
import { path, prop, propEq } from "ramda";
import { AppRoute } from "routes/app-routes";
import { Properties } from "utils/lomakkeet";

export const seuraavaPainike: CellFn = (storeFunctions, intl) => {
  if (!intl) {
    console.warn("Parametri intl puuttuu.");
    return false;
  }
  const seuraavaPainikeDefinition = defineSimpleButton(
    {
      ariaLabel: "Seuraava-painike",
      id: CellId.INITIALIZE_SEURAAVA_PAINIKE,
      isDisabled: true,
      text: intl.formatMessage(wizardMessages.next)
    },
    () => {
      const valittuKoulutusmuoto = prop(
        "value",
        storeFunctions.readPath([
          "components",
          CellId.INITIALIZE_KOULUTUSMUOTOVALIKKO,
          "properties"
        ]) as Properties
      );

      const koulutusmuoto = getKoulutusmuotoByPredicate(
        propEq("koulutustyyppi", valittuKoulutusmuoto),
        intl
      );

      if (koulutusmuoto) {
        // Lisätään polun muodostamiseen myöhemmin myös se vaihtoehto,
        // että TUVA-lupa on jo olemassa ja sitä halutaan täydentää.
        // Tällöin polku on AppRoute.Tuvahakemus.
        const routeToUusiTUVAHakemus = localizeRouteKey(
          intl.locale,
          AppRoute.UusiHakemusTUVA,
          intl,
          {
            id:
              (path(
                ["values", 0, "value"],
                storeFunctions.readPath([
                  "components",
                  CellId.INITIALIZE_ORGANISAATIOVALIKKO,
                  "properties"
                ]) as Properties
              ) as string) || "",
            koulutusmuoto: koulutusmuoto.kebabCase,
            language:
              (prop(
                "value",
                storeFunctions.readPath([
                  "components",
                  CellId.INITIALIZE_LOKALISAATIOVALIKKO,
                  "properties"
                ]) as Properties
              ) as string) || intl.locale,
            page: 1
          }
        );

        storeFunctions.updateGraph(["custom", "goToRoute"], {
          value: routeToUusiTUVAHakemus
        });
      }
      return true;
    }
  );

  storeFunctions.updateGraph(
    ["components", CellId.INITIALIZE_SEURAAVA_PAINIKE],
    seuraavaPainikeDefinition
  );

  return true;
};
