import { CellFn, Options } from "graphHandling/graphTypes";
import { assoc, map, path, prop } from "ramda";
import { Properties } from "utils/lomakkeet";
import { CellId } from "processes/CellId";

export const paivitaLokalisaatiovalikonTila: CellFn = storeFunctions => {
  const organisaatiovalikonTila = storeFunctions.readPath([
    "components",
    CellId.INITIALIZE_ORGANISAATIOVALIKKO,
    "properties"
  ]) as Properties;

  const lokalisaatiovalikonTila = storeFunctions.readPath([
    "components",
    CellId.INITIALIZE_LOKALISAATIOVALIKKO,
    "properties"
  ]) as Properties;

  const options = prop("options", lokalisaatiovalikonTila) as Options;

  const isOrganisaatioValittu = !!path(["values", 0], organisaatiovalikonTila);

  if (options) {
    storeFunctions.updateGraph(
      ["components", CellId.INITIALIZE_LOKALISAATIOVALIKKO, "properties"],
      {
        isDisabled: !isOrganisaatioValittu,
        options: map(assoc("isDisabled", !isOrganisaatioValittu), options),
        value: undefined
      }
    );
  }

  return true;
};
