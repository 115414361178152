import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { getSavedChangeObjects } from "utils/frontendMuutoksetMuutospyynnolla";
import wizard from "i18n/definitions/wizard";
import {
  useChangeObjects,
  useChangeObjectsByAnchorWithoutUnderRemoval
} from "stores/muutokset";
import Wizard from "components/03-templates/Wizard/index";
import LupanakymaA from "./lupanakymat/LupanakymaA";
import ProcedureHandler from "components/02-organisms/procedureHandler";
import { createMuutospyyntoOutput } from "utils/muutospyyntoUtil";
import { createObjectToSave } from "./saving";
import { find, prop, propEq, toUpper } from "ramda";
import { localizeRouteKey } from "utils/common";
import { AppRoute } from "routes/index";
import { FIELDS } from "modules/constants";
import { getUrlOnClose } from "components/03-templates/Wizard/wizardUtils";
import { PropTypes } from "prop-types";

/**
 * Container component of UusiaAsiaDialog.
 *
 * @param {Object} props - Props object.
 */
const WizardContainer = ({
  kohteet,
  koulutusmuoto,
  maaraystyypit,
  organisaatio,
  role,
  viimeisinLupa,
  muutospyynto
}) => {
  let history = useHistory();
  const intl = useIntl();
  const { formatMessage, locale } = intl;
  const { id, language, muutospyyntoUuid } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [muutospyynnonTila, setMuutospyynnonTila] = useState();
  const [{ isPreviewModeOn }, { initializeChanges, setPreviewMode }] =
    useChangeObjects();

  const [paatoksentiedotCo] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "paatoksentiedot"
  });
  const [toimintaalueCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "toimintaalue"
  });
  const [opetuskieletCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "opetuskielet"
  });
  const [opetustehtavatCo] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "opetustehtavat"
  });
  const [oikeusSisaoppilaitosmuotoiseenKoulutukseenCo] =
    useChangeObjectsByAnchorWithoutUnderRemoval({
      anchor: "oikeusSisaoppilaitosmuotoiseenKoulutukseen"
    });
  const [erityisetKoulutustehtavatCO] =
    useChangeObjectsByAnchorWithoutUnderRemoval({
      anchor: "erityisetKoulutustehtavat"
    });
  const [erityisenKoulutustehtavanRahoitusCO] =
    useChangeObjectsByAnchorWithoutUnderRemoval({
      anchor: "erityisenKoulutustehtavanRahoitus"
    });
  const [opiskelijamaaratCo] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "opiskelijamaarat"
  });
  const [muutEhdotCo] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "muutEhdot"
  });
  const [rajoitteetCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "rajoitteet"
  });
  const [valtakunnallisetKehittamistehtavatCO] =
    useChangeObjectsByAnchorWithoutUnderRemoval({
      anchor: "valtakunnallisetKehittamistehtavat"
    });
  const [rajoitepoistotCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: "rajoitepoistot"
  });

  useEffect(() => {
    if (muutospyynto) {
      const changeObjectsFromBackend = getSavedChangeObjects(muutospyynto);
      initializeChanges(changeObjectsFromBackend);
      setMuutospyynnonTila(prop("tila", muutospyynto));
    }
  }, [muutospyynto, initializeChanges]);

  const valtakunnallinenMaarays = find(
    propEq("koodisto", "nuts1"),
    prop("maaraykset", viimeisinLupa) || []
  );

  const steps = null;

  const title =
    muutospyynnonTila === FIELDS.TILA.VALUES.KORJAUKSESSA
      ? formatMessage(wizard.luvanKorjaustilaJarjestamisluvanMuutos)
      : formatMessage(wizard.esittelijatMuutospyyntoDialogTitle);

  const onNewDocSave = useCallback(
    (uuid, language) => {
      /**
       * User is redirected to the url of the saved document.
       */
      const url = localizeRouteKey(locale, AppRoute.Hakemus, intl, {
        id,
        koulutusmuoto: koulutusmuoto.kebabCase,
        language,
        page: 1,
        muutospyyntoUuid: uuid
      });
      history.push(url);
    },
    [formatMessage, history, id, koulutusmuoto.kebabCase, locale]
  );

  /**
   * Opens the preview.
   * @param {object} formData
   */
  const onPreview = useCallback(async () => {
    return setPreviewMode(!isPreviewModeOn);
  }, [isPreviewModeOn, setPreviewMode]);

  /**
   * Saves the form.
   * @param {object} formData
   * @returns {object} - Muutospyyntö
   */
  const onSave = useCallback(
    async formData => {
      setIsSaving(true);
      const procedureHandler = new ProcedureHandler(formatMessage);
      const outputs = await procedureHandler.run(
        "muutospyynto.tallennus.tallennaEsittelijanToimesta",
        [formData]
      );
      setIsSaving(false);
      return outputs.muutospyynto.tallennus.tallennaEsittelijanToimesta.output
        .result;
    },
    [formatMessage]
  );

  const onAction = useCallback(
    async (action, fromDialog = false, muutospyynnonTila) => {
      const formData = createMuutospyyntoOutput(
        await createObjectToSave(
          toUpper(locale),
          organisaatio,
          viimeisinLupa || {},
          {
            erityisetKoulutustehtavat: erityisetKoulutustehtavatCO,
            erityisenKoulutustehtavanRahoitus:
              erityisenKoulutustehtavanRahoitusCO,
            muutEhdot: muutEhdotCo,
            oikeusSisaoppilaitosmuotoiseenKoulutukseen:
              oikeusSisaoppilaitosmuotoiseenKoulutukseenCo,
            opetuskielet: opetuskieletCO,
            opetustehtavat: opetustehtavatCo,
            opiskelijamaarat: opiskelijamaaratCo,
            paatoksentiedot: paatoksentiedotCo,
            rajoitteet: rajoitteetCO,
            rajoitepoistot: rajoitepoistotCO,
            toimintaalue: toimintaalueCO,
            valtakunnallisetKehittamistehtavat:
              valtakunnallisetKehittamistehtavatCO
          },
          muutospyyntoUuid,
          kohteet,
          maaraystyypit,
          language,
          "ESITTELIJA",
          muutospyynnonTila
        )
      );

      let muutospyynto = null;

      if (action === "save") {
        muutospyynto = await onSave(formData);
      } else if (action === "preview") {
        muutospyynto = await onPreview(formData);
      }

      if (action === "save") {
        if (!!muutospyynto && prop("uuid", muutospyynto)) {
          if (!muutospyyntoUuid && !fromDialog) {
            // Jos kyseessä on ensimmäinen tallennus...
            onNewDocSave(muutospyynto.uuid, language);
          } else {
            /**
             * Kun muutospyyntolomakkeen tilaa muokataan tässä vaiheessa,
             * vältytään tarpeelta tehdä sivun täydellistä uudelleen latausta.
             **/
            const changeObjectsFromBackend =
              getSavedChangeObjects(muutospyynto);
            initializeChanges(changeObjectsFromBackend);
          }
        }
      }
    },
    [
      erityisetKoulutustehtavatCO,
      initializeChanges,
      language,
      locale,
      kohteet,
      viimeisinLupa,
      maaraystyypit,
      muutEhdotCo,
      onNewDocSave,
      onPreview,
      onSave,
      oikeusSisaoppilaitosmuotoiseenKoulutukseenCo,
      opetuskieletCO,
      opetustehtavatCo,
      opiskelijamaaratCo,
      organisaatio,
      paatoksentiedotCo,
      rajoitteetCO,
      rajoitepoistotCO,
      toimintaalueCO,
      valtakunnallisetKehittamistehtavatCO,
      muutospyyntoUuid
    ]
  );

  const urlOnClose = getUrlOnClose(
    role,
    locale,
    intl,
    organisaatio,
    koulutusmuoto,
    muutospyyntoUuid
  );

  return (
    <Wizard
      page1={
        <LupanakymaA
          isPreviewModeOn={false}
          isRestrictionsModeOn={true}
          koulutustyyppi={koulutusmuoto.koulutustyyppi}
          maaraykset={viimeisinLupa ? viimeisinLupa.maaraykset : []}
          rajoitemaaraykset={viimeisinLupa ? viimeisinLupa.rajoitteet : []}
          valtakunnallinenMaarays={valtakunnallinenMaarays || {}}
        />
      }
      isSaving={isSaving}
      koulutusmuoto={koulutusmuoto}
      onAction={onAction}
      organisation={organisaatio}
      steps={steps}
      title={title}
      tila={muutospyynnonTila}
      urlOnClose={urlOnClose}
    />
  );
};

WizardContainer.propTypes = {
  kohteet: PropTypes.array,
  koulutusmuoto: PropTypes.object,
  maaraystyypit: PropTypes.array,
  organisaatio: PropTypes.object,
  role: PropTypes.string,
  viimeisinLupa: PropTypes.object,
  muutospyynto: PropTypes.object
};

export default WizardContainer;
