import { IChip, Properties } from "utils/lomakkeet";
import { ComponentType } from "graphHandling/components/componentType";
import { ComponentEvent } from "graphHandling/graphTypes";
import { IChipProps } from "./Chip";
import { isEmpty } from "ramda";

export function defineChip(
  properties: IChipProps,
  functions: {
    onDelete: ComponentEvent;
  },
  modifications?: {
    backend?: Properties;
    frontend?: Array<Properties>;
  }
): IChip {
  const definition = {
    name: ComponentType.CHIP,
    onClick: functions.onDelete,
    properties
  };

  if (modifications && !isEmpty(modifications)) {
    return { ...definition, modifications };
  }

  return definition;
}
