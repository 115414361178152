import { memo, Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  assoc,
  filter,
  find,
  includes,
  map,
  path,
  pathEq,
  propEq,
  prop,
  length
} from "ramda";
import equal from "react-fast-compare";
import Rajoitteet from "components/02-organisms/Rajoitteet/index";
import { useChangeObjectsByAnchorWithoutUnderRemoval } from "stores/muutokset";
import Opetuskieli from "../lomakeosiot/Opetuskieli";
import OpetustaAntavatKunnat from "../lomakeosiot/OpetustaAntavatKunnat";
import { useIntl } from "react-intl";
import { useLomakedata } from "stores/lomakedata";
import common from "i18n/definitions/common";
import Lomake from "components/02-organisms/Lomake";
import AsianumeroYmsKentat from "../lomakeosiot/AsianumeroYmsKentat";
import tuva from "i18n/definitions/tuva";
import MaaratVuodet from "../lomakeosiot/MaaratVuodet";
import { getMaarayksetByTunniste } from "helpers/lupa";
import Lisatiedot from "../lomakeosiot/Lisatiedot";
import TuvaKoulutusmuoto from "scenes/Koulutusmuodot/TUVA/JarjestamislupaHTML/koulutusmuoto";
import { getRajoitteetBySection } from "utils/rajoitteetUtils";

function filterByTunniste(tunniste, maaraykset = []) {
  return filter(pathEq(["kohde", "tunniste"], tunniste), maaraykset);
}

/**
 * Tämä lupanäkymä sisältää kaikki lomakkeen osiot soveltuen siksi
 * erinomaisesti myös esikatselunäkymäksi.
 * @param {*} param0
 */
const LupanakymaA = memo(
  ({
    isPreviewModeOn,
    isRestrictionsModeOn,
    koulutustyyppi,
    maaraykset,
    valtakunnallinenMaarays,
    rajoitemaaraykset,
    viimeisinLupa,
    parentKoulutusmuoto
  }) => {
    const intl = useIntl();
    const [isOpetuskieletMaaraykset, setIsOpetuskieletMaaraykset] =
      useState(null);
    const [isToimintaAlueMaaraykset, setIsToimintaAlueMaaraykset] =
      useState(null);
    const [isOpiskelijaMaaratMaaraykset, setIsOpiskelijaMaaratMaaraykset] =
      useState(null);

    const [rajoitteidenKohdevaihtoehdot, setRajoitteidenKohdevaihtoehdot] =
      useState([]);
    // Oppilas-/opiskelijamäärät tai opiskelijavuodet na niitä koskevat rajoitukset
    const maaratVuodetEsivalinnatAnchorBase = "maaratVuodetEsivalinnat";
    const [maaratVuodetLomakedata] = useLomakedata({
      anchor: maaratVuodetEsivalinnatAnchorBase
    });
    const eiNoudatetaMaaratVuodet = path(
      ["properties", "isChecked"],
      find(
        propEq(
          "anchor",
          `${maaratVuodetEsivalinnatAnchorBase}.esivalinnat.ei-noudateta.A`
        ),
        maaratVuodetLomakedata
      )
    );
    const [maaratVuodetEsivalinnatCO] =
      useChangeObjectsByAnchorWithoutUnderRemoval({
        anchor: maaratVuodetEsivalinnatAnchorBase
      });

    // Opetuskielet
    const opetuskieletEsivalinnatAnchorBase = "opetuskieletEsivalinnat";
    const [opetuskieletLomakedata] = useLomakedata({
      anchor: opetuskieletEsivalinnatAnchorBase
    });
    const [opetuskieletCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
      anchor: "opetuskielet"
    });
    const eiNoudatetaOpetuskielet = path(
      ["properties", "isChecked"],
      find(
        propEq(
          "anchor",
          `${opetuskieletEsivalinnatAnchorBase}.esivalinnat.ei-noudateta.A`
        ),
        opetuskieletLomakedata
      )
    );
    const [opetuskieletEsivalinnatCO] =
      useChangeObjectsByAnchorWithoutUnderRemoval({
        anchor: opetuskieletEsivalinnatAnchorBase
      });

    // Toiminta-alue
    const toimintaAlueEsivalinnatAnchorBase = "toimintaAlueEsivalinnat";
    const [toimintaAlueLomakedata] = useLomakedata({
      anchor: toimintaAlueEsivalinnatAnchorBase
    });

    const [toimintaalueCO] = useChangeObjectsByAnchorWithoutUnderRemoval({
      anchor: "toimintaalue"
    });
    const eiNoudatetaToimintaAlue = path(
      ["properties", "isChecked"],
      find(
        propEq(
          "anchor",
          `${toimintaAlueEsivalinnatAnchorBase}.esivalinnat.ei-noudateta.A`
        ),
        toimintaAlueLomakedata
      )
    );
    const [toimintaAlueenEsivalinnatCO] =
      useChangeObjectsByAnchorWithoutUnderRemoval({
        anchor: toimintaAlueEsivalinnatAnchorBase
      });

    // Rajoitteet
    const [rajoitepoistot] = useChangeObjectsByAnchorWithoutUnderRemoval({
      anchor: "rajoitepoistot"
    });
    const rajoitepoistoIds = map(
      rajoitepoisto => path(["properties", "rajoiteId"], rajoitepoisto),
      rajoitepoistot
    );

    const [rajoitteetStateObj] = useLomakedata({ anchor: "rajoitteet" });

    const paattymispvm = path(
      ["properties", "value"],
      find(
        cObj => cObj.anchor === "paatoksentiedot.paattymispaivamaara.A",
        path(
          ["0"],
          useChangeObjectsByAnchorWithoutUnderRemoval({
            anchor: "paatoksentiedot"
          })
        ) || []
      )
    );

    const maarayksetRajoitepoistotFiltered = map(maarays => {
      /** Opiskelijamäärärajoitteen poisto poistaa koko määräyksen */
      if (
        maarays.koodisto === "kujalisamaareet" &&
        path(["maaraystyyppi", "tunniste"], maarays) === "RAJOITE" &&
        includes(path(["meta", "rajoiteId"], maarays), rajoitepoistoIds)
      ) {
        return null;
      }

      /** Muissa tapauksissa poistetaan vain alimääräykset */
      const alimaaraykset = filter(
        alimaarays =>
          !includes(path(["meta", "rajoiteId"], alimaarays), rajoitepoistoIds),
        maarays.aliMaaraykset || []
      );
      return assoc("aliMaaraykset", alimaaraykset, maarays);
    }, maaraykset || []).filter(Boolean);

    const rajoitteetListausChangeObj = find(
      propEq("anchor", "rajoitteet.listaus.A"),
      rajoitteetStateObj
    );

    const rajoiteChangeObjsByRajoiteId = path(
      ["properties", "rajoitteet"],
      rajoitteetListausChangeObj
    );

    const opetuskieletRajoitteet = getRajoitteetBySection(
      "opetuskielet",
      rajoiteChangeObjsByRajoiteId
    );

    const toimintaalueRajoitteet = getRajoitteetBySection(
      "toimintaalue",
      rajoiteChangeObjsByRajoiteId
    );

    const opiskelijamaaraRajoitteet = getRajoitteetBySection(
      "opiskelijamaarat",
      rajoiteChangeObjsByRajoiteId
    );

    const opetuskieletmaaraykset = filterByTunniste(
      "opetuskieli",
      maarayksetRajoitepoistotFiltered
    );

    const toimintaAlueMaaraykset = filterByTunniste(
      "toimintaalue",
      maarayksetRajoitepoistotFiltered
    );

    const maaratVuodetMaaraykset = filterByTunniste(
      "opiskelijamaarat",
      maarayksetRajoitepoistotFiltered
    );

    const asianumeroYmsClasses = isPreviewModeOn
      ? "md:w-1/2 xxl:w-1/3 pr-6 mb-6 mt-3"
      : "md:w-1/2 xxl:w-1/3 my-12";

    // Näytetään rajoitedialogissa valinta vaihtoehdot sen mukaan, määritetäänkö ne erikseen Tuva luvalla
    useEffect(() => {
      let aktiivisetRajoiteKohdeVaihdoehdot = [];
      if (eiNoudatetaOpetuskielet) {
        aktiivisetRajoiteKohdeVaihdoehdot.push({
          label: "Opetuskieli",
          value: "opetuskielet"
        });
      }

      if (eiNoudatetaToimintaAlue) {
        aktiivisetRajoiteKohdeVaihdoehdot.push({
          label: "Toiminta-alue",
          value: "toimintaalue"
        });
      }

      if (eiNoudatetaMaaratVuodet) {
        aktiivisetRajoiteKohdeVaihdoehdot.push({
          label: "Opiskelijamäärät tai opiskelijavuodet",
          value: "opiskelijamaarat"
        });
      }

      setRajoitteidenKohdevaihtoehdot(aktiivisetRajoiteKohdeVaihdoehdot);
    }, [
      eiNoudatetaOpetuskielet,
      toimintaAlueenEsivalinnatCO,
      eiNoudatetaMaaratVuodet
    ]);
    const fiCode = prop("koodiarvo", valtakunnallinenMaarays);
    // Tarkastetaan pitäisikö esivalinnat olla asetettuna noudatetaan tilaan riippuen löytyykö määräyksiä
    useEffect(async () => {
      if (maaraykset) {
        setIsOpetuskieletMaaraykset(
          !!length(await getMaarayksetByTunniste("opetuskieli", maaraykset))
        );

        setIsToimintaAlueMaaraykset(
          !!length(await getMaarayksetByTunniste("toimintaalue", maaraykset)) ||
            fiCode === "FI1"
        );

        setIsOpiskelijaMaaratMaaraykset(
          !!length(
            await getMaarayksetByTunniste("opiskelijamaarat", maaraykset)
          )
        );
      }
    }, [maaraykset]);

    return (
      <Fragment>
        <form className={`bg-white ${isPreviewModeOn ? "" : ""}`}>
          {!(isPreviewModeOn && !paattymispvm) && (
            <div className={asianumeroYmsClasses}>
              <AsianumeroYmsKentat isPreviewModeOn={isPreviewModeOn} />
            </div>
          )}
          {isPreviewModeOn && (
            <TuvaKoulutusmuoto parentKoulutusmuoto={parentKoulutusmuoto} />
          )}
          <Rajoitteet
            maaraykset={filter(
              maarays =>
                maarays.aliMaaraykset ||
                (maarays.koodisto === "kujalisamaareet" &&
                  path(["maaraystyyppi", "tunniste"], maarays) === "RAJOITE"),
              maaraykset || []
            )}
            rajoitemaaraykset={rajoitemaaraykset}
            isPreviewModeOn={isPreviewModeOn}
            isRestrictionsModeOn={isRestrictionsModeOn}
            kohdevaihtoehdot={rajoitteidenKohdevaihtoehdot}
            koulutustyyppi={koulutustyyppi}
            sectionId="rajoitteet"
            render={() => {
              return (
                <Fragment>
                  <div className="mb-4">
                    <Lomake
                      anchor={opetuskieletEsivalinnatAnchorBase}
                      data={{ opetuskieletCO, isOpetuskieletMaaraykset }}
                      changeObjects={opetuskieletEsivalinnatCO}
                      formTitle={intl.formatMessage(common.opetuskieli)}
                      isInExpandableRow={false}
                      isPreviewModeOn={isPreviewModeOn}
                      isRowExpanded={true}
                      mode={"modification"}
                      path={["tuva", opetuskieletEsivalinnatAnchorBase]}
                      showCategoryTitles={true}
                      titleLevel={3}></Lomake>
                  </div>
                  <Opetuskieli
                    isPreviewModeOn={isPreviewModeOn}
                    maaraykset={opetuskieletmaaraykset}
                    rajoitteet={opetuskieletRajoitteet}
                    sectionId={"opetuskielet"}
                    title={intl.formatMessage(common.opetuskieli)}
                    eiNoudatetaOpetuskielet={eiNoudatetaOpetuskielet}
                    parentKoulutusmuoto={parentKoulutusmuoto}
                  />
                  <div className="mb-4">
                    <Lomake
                      data={{ toimintaalueCO, isToimintaAlueMaaraykset }}
                      anchor={toimintaAlueEsivalinnatAnchorBase}
                      changeObjects={toimintaAlueenEsivalinnatCO}
                      formTitle={intl.formatMessage(
                        common.lupaSectionToimintaAlueMainTitle
                      )}
                      isInExpandableRow={false}
                      isPreviewModeOn={isPreviewModeOn}
                      isRowExpanded={true}
                      mode={"modification"}
                      path={["tuva", toimintaAlueEsivalinnatAnchorBase]}
                      showCategoryTitles={true}
                      titleLevel={3}></Lomake>
                  </div>
                  <OpetustaAntavatKunnat
                    isPreviewModeOn={isPreviewModeOn}
                    maaraykset={toimintaAlueMaaraykset}
                    rajoitteet={toimintaalueRajoitteet}
                    sectionId="toimintaalue"
                    valtakunnallinenMaarays={valtakunnallinenMaarays}
                    title={intl.formatMessage(
                      common.lupaSectionToimintaAlueMainTitle
                    )}
                    eiNoudatetaToimintaAlue={eiNoudatetaToimintaAlue}
                    parentKoulutusmuoto={parentKoulutusmuoto}
                  />
                  <div className="mb-4">
                    <Lomake
                      data={{ isOpiskelijaMaaratMaaraykset }}
                      anchor={maaratVuodetEsivalinnatAnchorBase}
                      changeObjects={maaratVuodetEsivalinnatCO}
                      formTitle={intl.formatMessage(tuva.maaratVuodetOtsikko)}
                      isInExpandableRow={false}
                      isPreviewModeOn={isPreviewModeOn}
                      isRowExpanded={true}
                      mode={"modification"}
                      path={["tuva", maaratVuodetEsivalinnatAnchorBase]}
                      showCategoryTitles={true}
                      titleLevel={3}></Lomake>
                  </div>
                  <div className="mb-4">
                    <MaaratVuodet
                      eiNoudatetaMaaratVuodet={eiNoudatetaMaaratVuodet}
                      isPreviewModeOn={isPreviewModeOn}
                      maaraykset={maaratVuodetMaaraykset}
                      rajoitteet={opiskelijamaaraRajoitteet}
                      sectionId={"maaratVuodet"}
                      title={intl.formatMessage(tuva.maaratVuodetOtsikko)}
                      parentKoulutusmuoto={parentKoulutusmuoto}
                    />
                  </div>
                  <Lisatiedot
                    mode={"modification"}
                    isPreviewModeOn={isPreviewModeOn}
                    key={`lisatiedot`}
                    sectionId={`lisatiedot`}
                    viimeisinLupa={viimeisinLupa}
                    title={intl.formatMessage(common.lisatiedot)}
                  />
                </Fragment>
              );
            }}
          />
        </form>
      </Fragment>
    );
  },
  (cp, np) => {
    return equal(cp, np);
  }
);

LupanakymaA.propTypes = {
  isPreviewModeOn: PropTypes.bool,
  isRestrictionsModeOn: PropTypes.bool,
  koulutustyyppi: PropTypes.string,
  maaraykset: PropTypes.array,
  rajoitemaaraykset: PropTypes.array,
  valtakunnallinenMaarays: PropTypes.object,
  validationErrors: PropTypes.array,
  muutospyynto: PropTypes.object,
  viimeisinLupa: PropTypes.object,
  parentKoulutusmuoto: PropTypes.object
};

LupanakymaA.displayName = "TUVA - Lupanäkymä A";

export default LupanakymaA;
