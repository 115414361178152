import { ReactElement } from "react";
import { IMultiTextBox } from "utils/lomakkeet";
import { ComponentType } from "graphHandling/components/componentType";
import MTextBox, { IMultiTextBoxProps } from "./MultiTextBox";
import { getCurrentProps } from "graphHandling/graphUtils";

export function renderMultiTextBox(
  componentDefinition: IMultiTextBox
): ReactElement | null {
  if (componentDefinition?.name === ComponentType.MULTI_TEXTBOX) {
    const properties = getCurrentProps(
      componentDefinition
    ) as IMultiTextBoxProps;

    return <MTextBox onChange={componentDefinition.onChange} {...properties} />;
  }

  return null;
}
