import {
  map,
  find,
  pathEq,
  not,
  includes,
  prop,
  path,
  filter,
  propEq,
  toUpper
} from "ramda";
import { __ } from "i18n-for-browser";
import { getLocalizedProperty } from "../utils";
import { PaikallisenTietovarastonAvain } from "enums";
import localForage from "localforage";

export async function getOpetuskieletOPHLomakeTPO(
  { maaraykset },
  { isPreviewModeOn, isReadOnly },
  locale,
  changeObjects
) {
  const _isReadOnly = isPreviewModeOn || isReadOnly;

  const ensisijaisetOpetuskieletOPH = await localForage.getItem(
    PaikallisenTietovarastonAvain.ENSISIJAISET_OPETUSKIELET_OPH
  );

  const lisatiedot = await localForage.getItem(
    PaikallisenTietovarastonAvain.LISATIEDOT
  );

  const lisatietomaarays = find(propEq("koodisto", "lisatietoja"), maaraykset);

  const valitutEnsisijaisetKoodiarvot = map(
    prop("value"),
    path([0, "properties", "value"], changeObjects) || []
  );

  const valittavanaOlevatEnsisisijaisetOpetuskielet = filter(
    kieli => not(includes(kieli.koodiarvo, valitutEnsisijaisetKoodiarvot)),
    ensisijaisetOpetuskieletOPH || []
  );

  const lisatiedotObj = find(
    pathEq(["koodisto", "koodistoUri"], "lisatietoja"),
    lisatiedot || []
  );

  const ensisijaisetAnchor = "ensisijaiset";

  const ensisijaisetOpetuskieletOptions = map(kieli => {
    return {
      label: getLocalizedProperty(kieli.metadata, locale, "nimi"),
      value: kieli.koodiarvo
    };
  }, valittavanaOlevatEnsisisijaisetOpetuskielet);

  const lomakerakenne = [
    {
      anchor: "opetuskieli",
      title: "Opetuskieli",
      components: [
        {
          anchor: ensisijaisetAnchor,
          name: "Autocomplete",
          short: true,
          properties: {
            forChangeObject: {
              valikko: ensisijaisetAnchor
            },
            isPreviewModeOn,
            isReadOnly: _isReadOnly,
            options: ensisijaisetOpetuskieletOptions,
            title: __("common.valitseYksiTaiUseampi"),
            value: map(maarays => {
              const maarayksenKoodiarvoUpper = toUpper(maarays.koodiarvo);
              const option = find(
                propEq("value", maarayksenKoodiarvoUpper),
                ensisijaisetOpetuskieletOptions
              );
              return option;
            }, filter(pathEq(["meta", "valikko"], ensisijaisetAnchor), maaraykset))
          }
        }
      ]
    },
    {
      anchor: "lisatiedot",
      layout: { margins: { top: "large" } },
      styleClasses: ["mt-10", "pt-10", "border-t"],
      components: [
        {
          anchor: "lisatiedot-info",
          name: "StatusTextRow",
          properties: {
            title: __("common.lisatiedotInfo")
          }
        }
      ]
    },
    lisatiedotObj
      ? {
          anchor: "lisatiedot",
          components: [
            {
              anchor: lisatiedotObj.koodiarvo,
              name: "TextBox",
              properties: {
                forChangeObject: {
                  koodiarvo: lisatiedotObj.koodiarvo,
                  koodisto: lisatiedotObj.koodisto,
                  versio: lisatiedotObj.versio,
                  voimassaAlkuPvm: lisatiedotObj.voimassaAlkuPvm
                },
                isPreviewModeOn,
                isReadOnly: _isReadOnly,
                title: __("common.lisatiedot"),
                value: lisatietomaarays ? lisatietomaarays.meta.arvo : ""
              }
            }
          ]
        }
      : null
  ].filter(Boolean);

  return lomakerakenne;
}
