import { __ } from "i18n-for-browser";
import { PaikallisenTietovarastonAvain } from "enums";
import localForage from "localforage";
import { find, path, pathEq } from "ramda";
import { Category } from "utils/lomakkeet";

export async function getKoulutusLisatiedot(
  mode: string,
  data: object
): Promise<Array<Category>> {
  const lisatiedot = await localForage.getItem(
    PaikallisenTietovarastonAvain.LISATIEDOT
  );

  const lisatiedotObj = find(
    pathEq(["koodisto", "koodistoUri"], "lisatietoja"),
    lisatiedot || ([] as any)
  );

  const lisatietoMaarays = path(["maarays"], data);

  return [
    {
      title: __("common.lisatietojaKoulutuksista"),
      anchor: "lisatiedot",
      styleClasses: ["mt-6", "p-10", "bg-green-50/25"],
      layout: { indentation: "large" },
      components: [
        {
          anchor: lisatiedotObj.koodiarvo,
          name: "TextBox",
          properties: {
            placeholder: __("common.lisatiedot"),
            title: "",
            value: path(["meta", "arvo"], lisatietoMaarays),
            forChangeObject: {
              maaraysUuid: path(["uuid"], lisatietoMaarays)
            }
          }
        }
      ]
    }
  ];
}
