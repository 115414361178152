import { find, includes, length, map, path, pathEq, prop, propEq } from "ramda";
import { getMaarayksetByTunniste } from "helpers/lupa";
import localforage from "localforage";

/**
 * Määrittää oppilaitoksia koskevat backendille lähetettävät muutosobjektit.
 * @param {*} changeObjects
 * @param {*} kohde
 * @param {*} maaraystyypit
 * @param {*} lupaMaaraykset
 * @returns Taulukollinen backend-muotoisia muutosobjekteja.
 */
export const defineBackendChangeObjects = async (
  changeObjects = [],
  kohde,
  maaraystyypit,
  lupaMaaraykset
) => {
  const maaraykset = await getMaarayksetByTunniste(
    kohde.tunniste,
    lupaMaaraykset
  );

  const maaraystyyppi = find(propEq("tunniste", "OIKEUS"), maaraystyypit);
  const oppilaitokset = await localforage.getItem("oppilaitoksetByOid");

  // Koska oppilaitokset valitaan Autocomplete-komponentilla,
  // valinnoista muodostuu vain yksi muutosobjekti. Siksi tässä kohtaa
  // muodostetaan lista valittujen oppilaitosten OID-arvoista käyttäen
  // indeksin 0 kohdalla olevaa frontend-muotoista muutosobjektia.
  const valittujenOppilaitostenOIDt = map(
    prop("value"),
    path(["0", "properties", "value"], changeObjects.oppilaitokset) || []
  );

  return map(oppilaitos => {
    const oppilaitokseenLiittyvaMaarays = find(
      pathEq(["orgOid"], oppilaitos.oid),
      maaraykset
    );

    // Kyseessä on oppilaitoksen lisääminen, mikäli iteraation kohteena
    // olevan oppilaitoksen OID sisältyy juuri muodostettuun listaukseen
    // ja kyseiselle oppilaitokselle ei ole vielä määräystä.
    const isLisays =
      !oppilaitokseenLiittyvaMaarays &&
      includes(oppilaitos.oid, valittujenOppilaitostenOIDt);

    // Jos oppilaitokseen kytkeytyy määräys,
    // mutta se ei ole enää valittujen oppilaitosten
    // joukossa, on kyse oppilaitoksen poistamisesta.
    const isPoisto =
      oppilaitokseenLiittyvaMaarays &&
      changeObjects.oppilaitokset &&
      length(changeObjects.oppilaitokset) > 0 &&
      !includes(oppilaitos.oid, valittujenOppilaitostenOIDt);

    return isLisays || isPoisto
      ? {
          generatedId: Math.random(),
          kohde,
          koodiarvo: "1",
          koodisto: "oppilaitos",
          kuvaus: "",
          maaraystyyppi,
          meta: {
            changeObjects: changeObjects.oppilaitokset
          },
          orgOid: oppilaitos.oid,
          tila: isLisays ? "LISAYS" : "POISTO",
          maaraysUuid: prop("uuid", oppilaitokseenLiittyvaMaarays)
        }
      : null;
  }, oppilaitokset).filter(Boolean);
};
