import { ReactElement, useEffect } from "react";
import { useIntl } from "react-intl";
import { sessionTimeoutInMinutes } from "modules/constants";
import { useHistory, useLocation } from "react-router-dom";
import { Typography } from "@mui/material";

import SimpleButton from "components/00-atoms/SimpleButton";
import auth from "i18n/definitions/auth";

const LogOut = (): ReactElement => {
  const history = useHistory();
  const { formatMessage, locale } = useIntl();
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  useEffect(() => {
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("oid");
    sessionStorage.removeItem("role");
  }, []);

  const localizationKeyPrefix = query.get("sessionTimeout")
    ? "sessionTimeout"
    : "endOfSession";

  return (
    <div className="mx-4 sm:mx-24">
      <div className="flex flex-col m-auto border bg-gray-100 px-40 py-24">
        <Typography component="h1" variant="h1" className="mb-4">
          {formatMessage(auth[`${localizationKeyPrefix}Title`])}
        </Typography>
        <p className="mb-4">
          {formatMessage(auth[`${localizationKeyPrefix}Info`], {
            time: sessionTimeoutInMinutes
          })}
        </p>
        <div>
          <SimpleButton
            text={formatMessage(auth.logIn)}
            onClick={() => history.push(`/${locale}/cas-auth`)}></SimpleButton>
        </div>
      </div>
    </div>
  );
};

export default LogOut;
