import { memo, Fragment } from "react";
import PropTypes from "prop-types";
import Radio from "@mui/material/Radio";
import makeStyles from "@mui/styles/makeStyles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import withStyles from "@mui/styles/withStyles";
import Check from "@mui/icons-material/CheckBoxOutlined";
import { equals } from "ramda";

const RadioButtonWithLabel = memo(
  props => {
    const styles = makeStyles({
      label: props.labelStyles
    })();
    const GreenRadio = withStyles({
      root: {
        color: "#666666",
        "&$checked": {
          color: "#42A047"
        }
      },
      checked: {}
    })(props => <Radio color="default" {...props} />);

    const handleChanges = () => {
      props.onChanges(
        {
          forChangeObject: props.forChangeObject,
          fullAnchor: props.fullAnchor
        },
        { isChecked: !props.isChecked }
      );
    };

    return (
      <Fragment>
        {!props.isReadOnly ? (
          <FormGroup row>
            <FormControlLabel
              classes={{
                label: styles.label
              }}
              disabled={props.isDisabled}
              control={
                <GreenRadio
                  id={props.id}
                  data-anchor={props.id}
                  checked={props.isChecked}
                  value={props.value}
                  onChange={handleChanges}
                />
              }
              label={props.children}
            />
          </FormGroup>
        ) : (
          props.isChecked && (
            <div className="flex flex-row text-base mb-2">
              {props.isPreviewModeOn ? (
                <ul className="list-disc">
                  <li>{props.children}</li>
                </ul>
              ) : (
                <Fragment>
                  <Check />
                  <span className="my-auto">{props.children}</span>
                </Fragment>
              )}
            </div>
          )
        )}
      </Fragment>
    );
  },
  (cp, np) => {
    return (
      equals(cp.isChecked, np.isChecked) &&
      equals(cp.isPreviewModeOn, np.isPreviewModeOn) &&
      equals(cp.isReadOnly, np.isReadOnly) &&
      equals(cp.labelStyles, np.labelStyles)
    );
  }
);

RadioButtonWithLabel.propTypes = {
  children: PropTypes.object,
  forChangeObject: PropTypes.object,
  fullAnchor: PropTypes.string,
  id: PropTypes.string,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isPreviewModeOn: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  name: PropTypes.string,
  onChanges: PropTypes.func,
  labelStyles: PropTypes.object,
  value: PropTypes.string
};

RadioButtonWithLabel.displayName = "RadioButtonWithLabel";

export default RadioButtonWithLabel;
