import PropTypes from "prop-types";
import Lomake from "components/02-organisms/Lomake";
import {
  useChangeObjects,
  useChangeObjectsByAnchorWithoutUnderRemoval
} from "stores/muutokset";
import { useCallback } from "react";

const constants = {
  formLocation: ["tutkinnot"]
};

const Koulutusala = ({
  data,
  isPreviewModeOn,
  isReadOnly,
  mode,
  sectionId,
  title
}) => {
  const [changeObjects] = useChangeObjectsByAnchorWithoutUnderRemoval({
    anchor: sectionId
  });
  const [, { addChangeObjectWithAnchor, setChanges }] = useChangeObjects();

  const onAddButtonClick = useCallback(
    (fromComponent, tutkinto, koulutustyyppi) => {
      addChangeObjectWithAnchor(
        sectionId,
        `${koulutustyyppi.koodiarvo}.${tutkinto.koodiarvo}.voimassaoloaika`
      );
    },
    [addChangeObjectWithAnchor, sectionId]
  );
  const onRemoveButtonClick = useCallback(
    (fromComponent, tutkinto, koulutustyyppi) => {
      addChangeObjectWithAnchor(
        sectionId,
        `${koulutustyyppi.koodiarvo}.${tutkinto.koodiarvo}.voimassaoloaika`,
        { removed: true }
      );
    },
    [addChangeObjectWithAnchor, sectionId]
  );
  const onChanges = useCallback(
    (changes, anchor, targetNode) => {
      setChanges(changes, anchor, targetNode.original.fullAnchor);
    },
    [sectionId, setChanges]
  );

  return (
    <Lomake
      anchor={sectionId}
      runInsteadOfSettingChanges={onChanges}
      changeObjects={changeObjects}
      data={data}
      functions={{
        onAddButtonClick,
        onRemoveButtonClick
      }}
      isPreviewModeOn={isPreviewModeOn}
      isReadOnly={isReadOnly}
      isRowExpanded={mode === "reasoning"}
      key={sectionId}
      mode={mode}
      path={constants.formLocation}
      rowTitle={title}
      showCategoryTitles={true}
    />
  );
};

Koulutusala.propTypes = {
  data: PropTypes.object,
  isPreviewModeOn: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  mode: PropTypes.string,
  sectionId: PropTypes.string,
  title: PropTypes.string,
  tutkinnot: PropTypes.array
};

export default Koulutusala;
