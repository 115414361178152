import { defineMessages } from "react-intl";

export default defineMessages({
  koulutusOhje: {
    id: "tilastot.koulutusOhje",
    defaultMessage: "Linkit vievät opetushallinnon tilastopalvelu Vipuseen."
  },
  lyhytKuvaus: {
    id: "tilastot.lyhytKuvaus",
    defaultMessage:
      "Tilastot-osiossa pääset tarkastelemaan opetushallinnon tilastopalvelu Vipuseen laadittuja raportteja eri koulutusasteiden koulutustoimijoiden järjestämis- ja ylläpitämisluvista."
  }
});
