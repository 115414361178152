import { CellFn } from "graphHandling/graphTypes";
import { CellId } from "processes/CellId";

export const update_radioButtonCollection: CellFn = (
  storeFunctions,
  intl,
  eventPayload
) => {
  if (!eventPayload) return false;

  storeFunctions.addModification(CellId.INITIALIZE_RADIO_BUTTON_COLLECTION, {
    value: eventPayload.changeProps.value
  });

  return true;
};
