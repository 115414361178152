import { CellFn, EventPayload } from "graphHandling/graphTypes";
import { CellId } from "processes/CellId";
import { Locale } from "enums";
import languages from "i18n/definitions/languages";
import { defineRadioButtonGroup } from "graphHandling/components/radioButtonGroup/definitions/unopinionated";

export const defineCellLokalisaatiovalikko: CellFn = (storeFunctions, intl) => {
  if (!intl) {
    return false;
  }

  const currentCellId = CellId.INITIALIZE_LOKALISAATIOVALIKKO;

  return defineRadioButtonGroup(
    {
      options: [
        {
          label: intl.formatMessage(languages.finnish),
          value: Locale.FI
        },
        {
          label: intl.formatMessage(languages.swedish),
          value: Locale.SV
        }
      ]
    },
    intl,
    {
      onChange: (payload: EventPayload) => {
        const selectedLocale = payload.changeProps.value;

        storeFunctions.updateGraph(
          ["components", currentCellId, "properties"],
          { value: selectedLocale }
        );

        return true;
      }
    }
  );
};
